import { AxiosError } from 'axios';
import * as CONSTANT from '../api.constant';
import { get } from '../apiCalls';

export const fetchEmployeeByAuth = async (authId) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_SURFACE_V2}/emp/get-employee-fields-data-by-auth-id/${authId}`,
    );
    const resultData = response.data;
    return resultData;
  } catch (err) {
    throw new AxiosError(err?.message);
  }
};
