import { rbacFlags } from 'config/rbacFlags';
import React from 'react';

const ManageLetterGeneration = React.lazy(() =>
  import('pages/letterGeneration/listing/ManageLetterGeneration'),
);
const EditLetter = React.lazy(() =>
  import('pages/letterGeneration/edit/EditLetter'),
);

const LettersRoutes = [
  {
    type: 'collapse',
    name: 'manageLetterGeneration',
    key: 'manageLetterGeneration',
    route: 'admin/letter-generation',
    flagName: rbacFlags.ManageLetterTemplate,
    parentId: 'Letters',
    module: 'Letters',
    component: <ManageLetterGeneration />,
  },
  {
    type: 'collapse',
    name: 'editLetterGeneration',
    key: 'editLetterGeneration',
    route: 'admin/letter-generation/edit/:id',
    flagName: rbacFlags.ManageLetterTemplate,
    parentId: 'Letters',
    module: 'Letters',
    component: <EditLetter />,
  },
];
export default LettersRoutes;
