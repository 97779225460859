import ColorPicker from 'components/atoms/FormElements/colorPicker/ColorPicker';
import { Input } from 'components/atoms/FormElements/input/Input';
import Select from 'components/atoms/FormElements/select/Select';
import TextBox from 'components/atoms/FormElements/textBox/TextBox';
import Toggle from 'components/atoms/FormElements/toggle/Toggle';
import {
  useAddMeasures,
  useGetDimensions,
  useGetMeasures,
  useUpdateChart,
} from 'query/dashboard/ManageDashboard';
import { useParams } from 'react-router-dom';
import SearchIconInput from 'assets/svg/searchIconInput';
import { useEffect, useState } from 'react';
import Button from 'components/atoms/button/Button';
import { onResponse } from 'utils/toastMessages';
import { getChartTable } from 'apiClient/dashboardBuilder/DashboardBuilder';
import { set } from 'react-hook-form';
import { messages } from 'react-querybuilder';

export default function DashboardChartData({
  configFor,
  layout,
  setLayout,
  selectedPage,
  setConfigFor,
  showLegend,
  setShowLegend,
}) {
  const dashboardId = layout?.dashboardId;
  const chartId = configFor?.cardData?.id;
  const [selectedDimensions, setSelectedDimensions] = useState([]);
  const [selectedMeasures, setSelectedMeasures] = useState([]);
  const [disconnectedDimenisons, setDisconnectedDimensions] = useState([]);
  const [disconnectedMeasures, setDisconnectedMeasures] = useState([]);
  const [errorMessageDimension, setErrorMessageDimension] = useState('');
  const [errorMessageMeasure, setErrorMessageMeasure] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [datasets, setDatasets] = useState({});

  useEffect(() => {
    setSelectedDimensions(configFor?.cardData?.dimensions || []);
    setSelectedMeasures(configFor?.cardData?.measures || []);
  }, [configFor]);

  const {
    data: dimensionsData,
    isLoading: dimensionsLoading,
    error: errorDimension,
  } = useGetDimensions(dashboardId);

  const {
    data: measureOptions,
    isLoading: measureLoading,
    isError,
  } = useGetMeasures(dashboardId);

  const { mutateAsync: updateChart } = useUpdateChart(chartId);

  const validateChartSelections = () => {
    const type = configFor?.cardData?.type;
    if (type === 'Scatter Plot') {
      if (selectedMeasures?.length !== 2) {
        setErrorMessageMeasure('Please select two measures for x and y axis');
        return false;
      }
      if (selectedDimensions?.length < 1) {
        setErrorMessageDimension('Please select at least one dimension');
        return false;
      }
      setErrorMessageDimension('');
      setErrorMessageMeasure('');
      return true;
    } else if (type === 'Bubble Chart') {
      if (selectedDimensions?.length !== 1) {
        setErrorMessageDimension('Please select only one diemension');
        return false;
      }
      if (selectedMeasures?.length !== 3) {
        setErrorMessageMeasure(
          'Please select three measures for x, y and radius',
        );
        return false;
      }
      setErrorMessageDimension('');
      setErrorMessageMeasure('');
      return true;
    } else if (type === 'Heatmap') {
      if (selectedDimensions?.length !== 2) {
        setErrorMessageDimension(
          'Please select two dimensions for x and y axis',
        );
        return false;
      }
      if (selectedMeasures?.length < 1) {
        setErrorMessageMeasure('Please select at least one measure');
        return false;
      }
      setErrorMessageDimension('');
      setErrorMessageMeasure('');
      return true;
    } else if (type === 'Pie Chart' || type === 'Donut Chart') {
      if (selectedDimensions?.length < 1) {
        setErrorMessageDimension('Please select at least one dimension');
        return false;
      }
      if (selectedMeasures?.length !== 1) {
        setErrorMessageMeasure('Please select only one measure');
        return false;
      }
      setErrorMessageDimension('');
      setErrorMessageMeasure('');
      return true;
    } else {
      if (selectedDimensions?.length < 1) {
        setErrorMessageDimension('Please select at least one dimension');
        return false;
      }
      if (selectedMeasures?.length < 1) {
        setErrorMessageMeasure('Please select at least one measure');
        return false;
      }
      setErrorMessageDimension('');
      setErrorMessageMeasure('');
      return true;
    }
  };

  const handleDimensionSelectChange = async (selectedOptions) => {
    const selectedDimensions = selectedOptions?.map((option) => option?.value);

    const previouslySelectedDimensions = configFor?.cardData?.dimensions || [];
    const deletedDimensions = previouslySelectedDimensions?.filter(
      (dim) => !selectedDimensions.includes(dim),
    );

    setDisconnectedDimensions((prev) => [...prev, ...deletedDimensions]);

    setSelectedDimensions(selectedDimensions);
    handleChartEdit('dimensions', selectedDimensions);
  };

  const handleMeasureChange = async (selectedOptions) => {
    const selectedMeasures = selectedOptions?.map((option) => option?.value);

    const previouslySelectedMeasures = configFor?.cardData?.measures || [];
    const deletedMeasures = previouslySelectedMeasures.filter(
      (measure) => !selectedMeasures.includes(measure),
    );

    setDisconnectedMeasures((prev) => [...prev, ...deletedMeasures]);

    setSelectedMeasures(selectedMeasures);
    handleChartEdit('measures', selectedMeasures);
  };

  const getFormattedList = (list) => {
    return (
      list &&
      list?.map((option) => {
        return {
          ...option,
          value: option?.columnId,
          label: option?.value,
        };
      })
    );
  };

  const handleChartEdit = (key, value) => {
    const updatedChartList = layout?.charts?.map((item) => {
      if (item?.cardData?.id === chartId) {
        return {
          ...item,
          cardData: {
            ...item?.cardData,
            [key]: value,
          },
        };
      }
      return item; // Add this line to return the item if the condition is not met
    });
    setLayout((prev) => ({ ...prev, charts: updatedChartList }));
    setConfigFor((prev) => ({
      ...prev,
      cardData: {
        ...prev?.cardData,
        [key]: value,
      },
    }));
  };

  const pixelOptions = [
    { value: '12', label: '12px' },
    { value: '14', label: '14px' },
    { value: '16', label: '16px' },
    { value: '18', label: '18px' },
    { value: '20', label: '20px' },
    { value: '24', label: '24px' },
    { value: '28', label: '28px' },
    { value: '32', label: '32px' },
    { value: '40', label: '40px' },
    { value: '56', label: '56px' },
  ];

  const fontOptions = [
    { value: 'Figtree', label: 'Figtree' },
    { value: 'Roboto', label: 'Roboto' },
    { value: 'Open Sans', label: 'Open Sans' },
    { value: 'Lato', label: 'Lato' },
    { value: 'Montserrat', label: 'Montserrat' },
    { value: 'Poppins', label: 'Poppins' },
  ];

  const aggregateOptions = [
    { value: 'Sum', label: 'Sum' },
    { value: 'Average', label: 'Average' },
    { value: 'Count', label: 'Count' },
    { value: 'Max', label: 'Max' },
    { value: 'Min', label: 'Min' },
  ];

  const fetchChartData = async () => {
    try {
      const res = await getChartTable(chartId, dashboardId, {});
      setDatasets(res);
      handleChartEdit('datasets', res);
      return res;
    } catch (err) {
      console.error(err);
    }
  };

  const selectedValues = selectedDimensions?.map((dimension) => {
    return [
      ...getFormattedList(dimensionsData || []),
      ...getFormattedList(measureOptions || []),
    ]?.find((option) => option?.value === dimension);
  });

  const selectedMeasuresValues = selectedMeasures?.map((measure) => {
    return getFormattedList(measureOptions)?.find(
      (option) => option?.value === measure,
    );
  });

  const handleSave = async () => {
    setErrorMessageDimension('');
    setErrorMessageMeasure('');
    if (!validateChartSelections()) {
      return;
    }
    setIsSaving(true);
    const newDisconnectedDimenisons = disconnectedDimenisons?.filter(
      (dimension) => !selectedDimensions.includes(dimension),
    );

    const newDisconnectedMeasures = disconnectedMeasures?.filter(
      (measure) => !selectedMeasures.includes(measure),
    );

    const payload = {
      name: configFor?.cardData?.name,
      description: configFor?.cardData?.description,
      dimensions: selectedDimensions,
      measures: selectedMeasures,
      aggregation: configFor?.cardData?.aggregation,
      dashboardId: dashboardId,
      disconnectDimensions: newDisconnectedDimenisons,
      disconnectMeasures: newDisconnectedMeasures,
    };

    try {
      await updateChart(payload);
      const res = await fetchChartData();
      const newPayload = {
        data: {
          datasets: res,
          i: configFor?.i,
          type: configFor?.cardData?.type,
          x: configFor?.x,
          y: configFor?.y,
          w: configFor?.w,
          h: configFor?.h,
          minW: configFor?.minW,
          minH: configFor?.minH,
        },
        dashboardId: dashboardId,
      };
      try {
        await updateChart(newPayload);
      } catch (err) {
        console.error(err);
      }
    } catch (err) {
      setIsSaving(false);
      onResponse(err?.message);
    }
    setIsSaving(false);
  };

  return (
    <div className="relative h-full flex flex-col">
      <div className="h-full flex flex-col justify-between">
        <div className="h-4/5 overflow-y-auto overflow-x-hidden">
          <div className="flex flex-col h-full pb-6">
            {/* {configFor?.data?.type === 'text' ? (
              <div className="flex flex-col gap-4">
                <div>
                  <TextBox
                    label="Description"
                    placeholder="Enter description"
                    rows={4}
                    onChange={(e) =>
                      handleTextEdit('description', e.target.value)
                    }
                    value={configFor?.data?.description}
                  />
                </div>
                <div>
                  <Select
                    label="Font Size"
                    options={pixelOptions}
                    value={{
                      value: configFor?.data?.fontSize || '16',
                      label: `${configFor?.data?.fontSize || '16'}px`,
                    }}
                    onChange={(e) => {
                      handleTextEdit('fontSize', Number(e?.value));
                    }}
                  />
                </div>
                <div>
                  <ColorPicker
                    label="Font Color"
                    value={configFor?.data?.fontColor || '#000000'}
                    onChange={(e) => {
                      handleTextEdit('fontColor', e.target.value);
                    }}
                  />
                </div>
                <div>
                  <Select
                    label="Font Family"
                    options={fontOptions}
                    value={{
                      value: configFor?.data?.fontFamily || 'Lato',
                      label: configFor?.data?.fontFamily || 'Lato',
                    }}
                    onChange={(e) => {
                      handleTextEdit('fontFamily', e.value);
                    }}
                  />
                </div>
              </div>
            ) : configFor?.data?.type === 'table' ? ( */}
            {/* <div className="flex flex-col gap-4">
                <div>
                  <Input
                    label="Title"
                    placeholder="Enter Title"
                    onChange={(e) => {
                      handleTextEdit('title', e.target.value);
                    }}
                    value={configFor?.data?.title}
                  />
                </div>
                <div>
                  <TextBox
                    label="Description"
                    placeholder="Enter description"
                    rows={4}
                    onChange={(e) =>
                      handleTextEdit('description', e.target.value)
                    }
                    value={configFor?.data?.description}
                  />
                </div>
              </div> */}
            {/* ) : ( */}
            <div className="flex flex-col gap-4">
              <div className="w-full">
                <Select
                  label="Dimensions"
                  className="w-15 border-none"
                  placeholder={'Select'}
                  isMulti={true}
                  value={selectedValues}
                  // options={getFormattedList([...dimensionsData, ...measureOptions] || [])}
                  options={[
                    ...getFormattedList(dimensionsData || []),
                    ...getFormattedList(measureOptions || []),
                  ]}
                  onChange={handleDimensionSelectChange}
                  error={
                    errorMessageDimension?.length > 0
                      ? { message: errorMessageDimension }
                      : false
                  }
                  isLoading={dimensionsLoading}
                />
              </div>
              <div className="w-full">
                <Select
                  label="Measure"
                  options={getFormattedList(measureOptions || [])}
                  onChange={handleMeasureChange}
                  value={selectedMeasuresValues}
                  isMulti={true}
                  error={
                    errorMessageMeasure?.length > 0
                      ? { message: errorMessageMeasure }
                      : false
                  }
                  isLoading={measureLoading}
                />
              </div>
              <div className="w-full">
                <Select
                  label="Aggregation"
                  options={aggregateOptions}
                  onChange={(e) => handleChartEdit('aggregation', e?.value)}
                  value={aggregateOptions?.find(
                    (option) =>
                      option?.value === configFor?.cardData?.aggregation,
                  )}
                />
              </div>
              <div className="flex justify-between w-full">
                <span className="text-sm font-medium">Show Legends</span>
                <div className="-mr-2">
                  <Toggle
                    checked={showLegend[chartId] ?? true}
                    onChange={(e) => {
                      const isChecked = e.target.checked;
                      setShowLegend((prev) => {
                        const updatedState = {
                          ...prev,
                          [chartId]: isChecked,
                        };
                        return updatedState;
                      });
                    }}
                  />
                </div>
              </div>
              <Input
                label="Title"
                placeholder="Enter Title"
                onChange={(e) => {
                  handleChartEdit('name', e.target.value);
                }}
                value={configFor?.cardData?.name}
                maxLength={60}
              />
              <div className="w-full">
                <TextBox
                  label="Description"
                  placeholder="Enter description ( only up to 70 characters)"
                  rows={4}
                  onChange={(e) =>
                    handleChartEdit('description', e.target.value)
                  }
                  value={configFor?.cardData?.description}
                  maxLength={180}
                />
              </div>
            </div>
            {/* )} */}
          </div>
        </div>
        <div className=" h-20 absolute w-full bottom-0 flex flex-col pt-3 gap-4 bg-white ">
          <hr className="bg-gray-200" />
          <div className="text-right">
            {isSaving ? (
              <Button>Saving...</Button>
            ) : (
              <Button onClick={handleSave}>Save</Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
