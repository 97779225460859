const MyClaimsIcon = (props) => (
  <svg
    width={props?.width ? props?.width : '20'}
    height={props?.height ? props?.height : '20'}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_80_16825)">
      <path
        d="M3.33334 18.1813C3.83549 18.3334 4.51373 18.3334 5.66666 18.3334H14.3333C15.4862 18.3334 16.1645 18.3334 16.6666 18.1813M3.33334 18.1813C3.22567 18.1487 3.1261 18.109 3.03168 18.0609C2.56128 17.8212 2.17882 17.4388 1.93914 16.9684C1.66666 16.4336 1.66666 15.7335 1.66666 14.3334V5.66675C1.66666 4.26662 1.66666 3.56655 1.93914 3.03177C2.17882 2.56137 2.56128 2.17892 3.03168 1.93923C3.56646 1.66675 4.26653 1.66675 5.66666 1.66675H14.3333C15.7335 1.66675 16.4335 1.66675 16.9683 1.93923C17.4387 2.17892 17.8212 2.56137 18.0608 3.03177C18.3333 3.56655 18.3333 4.26662 18.3333 5.66675V14.3334C18.3333 15.7335 18.3333 16.4336 18.0608 16.9684C17.8212 17.4388 17.4387 17.8212 16.9683 18.0609C16.8739 18.109 16.7743 18.1487 16.6666 18.1813M3.33334 18.1813C3.33362 17.5068 3.33766 17.15 3.39737 16.8498C3.66039 15.5275 4.69405 14.4938 6.01636 14.2308C6.33835 14.1667 6.72556 14.1667 7.49999 14.1667H12.5C13.2744 14.1667 13.6616 14.1667 13.9836 14.2308C15.3059 14.4938 16.3396 15.5275 16.6026 16.8498C16.6623 17.15 16.6664 17.5068 16.6666 18.1813M13.3333 7.91675C13.3333 9.7577 11.8409 11.2501 9.99999 11.2501C8.15904 11.2501 6.66666 9.7577 6.66666 7.91675C6.66666 6.0758 8.15904 4.58341 9.99999 4.58341C11.8409 4.58341 13.3333 6.0758 13.3333 7.91675Z"
        stroke={
          props.isActive
            ? props.activeColor
            : props.isHovered
            ? props.hoveredColor
            : '#475467'
        }
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_80_16825">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default MyClaimsIcon;
