import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';

export const initialState = {
  columns: [
    // {
    // },
  ],
  loader: [],
  deleteColumns: [],
};

export const updateColumnAsync = createAsyncThunk(
  'reports/updateColumnAsync',
  async ({ id, newData }, { dispatch }) => {
    // Simulate an API call to update the column
    await new Promise((resolve) => setTimeout(resolve, 1000));
    dispatch(updateColumn({ id, newData }));
    return { id, newData };
  },
);

const reportSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    addColumn(state, action) {
      const newColumn = {
        id: uuidv4(),
        ...action.payload,
        rows: action.payload.rows || [],
      };
      state.columns.push(newColumn);
    },
    removeColumn(state, action) {
      state.columns = state.columns.filter(
        (column) => column.id !== action.payload,
      );
    },
    updateColumn(state, action) {
      const { id, displayName, newData } = action.payload;

      const indexById =
        id !== undefined
          ? state.columns.findIndex((column) => column.id === id)
          : -1;
      const indexByDisplayName =
        displayName !== undefined
          ? state.columns.findIndex(
              (column) => column.displayName === displayName,
            )
          : -1;

      const index = indexById !== -1 ? indexById : indexByDisplayName;

      if (index !== -1) {
        state.columns[index] = { ...state.columns[index], ...newData };
      }
    },
    addRowToColumn(state, action) {
      const { columnId, row } = action.payload;
      const index = state.columns.findIndex((column) => column.id === columnId);
      if (index !== -1) {
        state.columns[index].rows.push(row);
      }
    },
    toggleColumnFilterable(state, action) {
      const { id } = action.payload;
      const index = state.columns.findIndex((column) => column.id === id);
      if (index !== -1) {
        state.columns[index].filterable = !state.columns[index].filterable;
      }
    },
    toggleColumnHidden(state, action) {
      const { id } = action.payload;
      const index = state.columns.findIndex((column) => column.id === id);
      if (index !== -1) {
        state.columns[index].hidden = !state.columns[index].hidden;
      }
    },
    addColumnToDelete(state, action) {
      const columnToDelete = {
        ...action.payload,
      };
      state.deleteColumns.push(columnToDelete);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateColumnAsync.pending, (state) => {
        state.loader = [...state.loader, 'UPDATE_COLUMN_LOADING'];
      })
      .addCase(updateColumnAsync.fulfilled, (state, action) => {
        state.loader = state.loader.filter(
          (ele) => ele !== 'UPDATE_COLUMN_LOADING',
        );
      })
      .addCase(updateColumnAsync.rejected, (state) => {
        state.loader = state.loader.filter(
          (ele) => ele !== 'UPDATE_COLUMN_LOADING',
        );
      });
  },
});

export const {
  addColumn,
  removeColumn,
  updateColumn,
  addRowToColumn,
  toggleColumnFilterable,
  toggleColumnHidden,
  addColumnToDelete,
} = reportSlice.actions;

export default reportSlice.reducer;
