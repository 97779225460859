export const FilterTabs = ({
  selectedFilterTab,
  filterTabs,
  setSelectedFilterTab,
}) => {
  return (
    <div className="mb-3">
      <div className="flex items-center justify-end">
        {filterTabs?.map((filterTab, index) => {
          return (
            <div
              key={filterTab}
              className={`px-4 py-2 text-sm font-semibold text-center items-center ${
                index === 0
                  ? 'rounded-l-lg'
                  : index === filterTabs?.length - 1
                  ? 'rounded-r-lg'
                  : 'border-r-0 border-l-0'
              }  cursor-pointer border border-gray-300 ${
                selectedFilterTab === filterTab
                  ? 'bg-white text-gray-900'
                  : 'bg-gray-50 font-medium text-gray-500'
              }`}
              onClick={() => setSelectedFilterTab(filterTab)}
            >
              {filterTab}
            </div>
          );
        })}
      </div>
    </div>
  );
};
