import { useFormContext } from 'react-hook-form';
import ButtonAtom from '../../atoms/buttonAtom/ButtonAtom';

const ButtonComponent = (props) => {
  const { data } = props;
  const { path } = data;
  const { watch } = useFormContext();
  const justifyContent = watch(`${path}.flex.justify`);

  return (
    <div
      style={{ justifyContent: justifyContent ?? 'center' }}
      className={`h-full  flex flex-col items-start cursor-pointer w-full p-4`}
    >
      <ButtonAtom data={data} />
    </div>
  );
};

export default ButtonComponent;
