import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getAllTask } from 'apiClient/userTask/task';

const initialState = {
  taskCount: 0,
  status: 'idle',
  error: null,
};

export const fetchAndUpdatePendingTask = createAsyncThunk(
  'tasks/fetchAndUpdatePendingTask',
  async (taskParams, { dispatch }) => {
    const data = await getAllTask(...taskParams);
    return data.count;
  },
);

const taskSlice = createSlice({
  name: 'tasks',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAndUpdatePendingTask.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAndUpdatePendingTask.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.taskCount = action.payload;
      })
      .addCase(fetchAndUpdatePendingTask.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});
export default taskSlice.reducer;
