import {
  ArrowLeftCircleIcon,
  ArrowRightCircleIcon,
} from '@heroicons/react/24/outline';
import FlexIcon from 'assets/svg/FlexIcon';
import { get } from 'lodash';
import React from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { templateBuilderSelector } from 'store/reducers/templateBuilderSlice';

const BuilderColumnActions = (props) => {
  const { id, parentIndex, index, path } = props;
  const { control, setValue, watch, getValues } = useFormContext();
  const { swap } = useFieldArray({
    control,
    name: `data.${parentIndex}.columns`,
  });

  const Columns = useWatch({
    control,
    name: `data.${parentIndex}.columns`,
  });

  const { modeLabel } = useSelector(templateBuilderSelector);

  const onSwapLeft = (index) => {
    if (index - 1 >= 0) {
      swap(index, index - 1);
    }
  };
  const onSwapRight = (index) => {
    if (index + 1 < Columns.length) {
      swap(index, index + 1);
    }
  };

  const deleteElement = (i) => {
    const media = watch(`media`);
    if (media[id]) {
      delete media[id];
    }
    setValue(`media`, media);

    // const mediaId = watch(`${path}.mediaId`);
    // if (mediaId) {
    //   const mediasToDelete = watch(`mediasToDelete`);
    //   setValue(`mediasToDelete`, [...mediasToDelete, mediaId]);
    // }
    // console.log(getValues(`data.${parentIndex}.columns`));
    setValue(`data.${parentIndex}.columns.${i}`, { columnId: id });
    // console.log(getValues(`data.${parentIndex}.columns`));
    // const columns = watch(`data.${parentIndex}.columns`);
    // const updatedColumns = columns.filter((_, index) => index !== i);
    // setValue(`data.${parentIndex}.columns`, updatedColumns);
  };

  return (
    <div className="hidden group-hover:block absolute bottom-0 bg-white rounded-xl w-full">
      <div className="p-4 h-14 w-full relative  rounded-lg flex justify-between mt-3">
        {/* {modeLabel !== 'Edit' && ( */}
        <h2
          onClick={() => deleteElement(index)}
          className="cursor-pointer text-sm text-red-600 font-medium"
        >
          Delete Element
        </h2>
        {/* )} */}
        <div className="flex gap-2 text-gray-500">
          <ArrowLeftCircleIcon
            className="cursor-pointer"
            onClick={() => onSwapLeft(index)}
          />
          <ArrowRightCircleIcon
            className="cursor-pointer"
            onClick={() => onSwapRight(index)}
          />
          <div className="h-5 bg-gray-300 w-[1px]"></div>
          <FlexIcon
            name={'flexStart'}
            onClick={() => setValue(`${path}.flex.justify`, 'start')}
          />
          <FlexIcon
            name={'flexCenter'}
            onClick={() => setValue(`${path}.flex.justify`, 'center')}
          />
          <FlexIcon
            name={'flexEnd'}
            onClick={() => setValue(`${path}.flex.justify`, 'end')}
          />
        </div>
      </div>
    </div>
  );
};

export default BuilderColumnActions;
