export const RefreshGradientIcon = (props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18.453 10.8932C18.1752 13.5031 16.6964 15.9488 14.2494 17.3616C10.1839 19.7088 4.98539 18.3158 2.63818 14.2503L2.38818 13.8173M1.54613 9.10713C1.82393 6.49723 3.30272 4.05151 5.74971 2.63874C9.8152 0.291528 15.0137 1.68447 17.3609 5.74995L17.6109 6.18297M1.49316 16.0662L2.22521 13.3341L4.95727 14.0662M15.0424 5.93413L17.7744 6.66618L18.5065 3.93413"
        stroke="url(#paint0_linear_749_26181)"
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_749_26181"
          x1="1.49316"
          y1="18.5018"
          x2="18.5065"
          y2="18.5018"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="var(--primaryLeft)" />
          <stop offset="1" stopColor="var(--primaryRight)" />
        </linearGradient>
      </defs>
    </svg>
  );
};
