import {
  ArrowDownTrayIcon,
  ArrowPathIcon,
  ChevronDoubleRightIcon,
  ChevronLeftIcon,
} from '@heroicons/react/24/outline';
import NoCommunityIcon from 'assets/svg/noCommunityIcon';
import SearchIconLogo from 'assets/svg/searchIconLogo';
import Pagination from 'components/atoms/pagination/Pagination';
import TableSkeleton from 'components/atoms/skeletonLoaderTypes/TableSkeleton';
import AvatarWithUsernameAndDescription from 'components/staticComponents/AvatarWithUsernameAndDescription/AvatarWithUsernameAndDescription';
import { useDebounce } from 'hooks/useDebounce';
import { useEffect, useState } from 'react';
import { onResponse } from 'utils/toastMessages';

export default function EligibleUsers({
  hideSummary,
  setShowSummary,
  isNonStickyButton,
  fetchEligibleUser,
  downloadEligibleGroupUsers,
  savedData,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState('');
  const [list, setList] = useState([]);
  const [totalListItems, setTotalListItems] = useState(null);
  const debouncedQuery = useDebounce(search, 1000);

  const fetchUserData = (query = '', pageCount) => {
    setIsLoading(true);
    fetchEligibleUser(query, pageCount, 10)
      .then((res) => {
        setList(res?.data);
        setTotalListItems(res?.total);
      })
      .catch((err) => {
        onResponse(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  useEffect(() => {
    setPage(0);
    setSearch('');
    setList([]);
    setTotalListItems(null);
    fetchUserData('', 0);
  }, [savedData]);
  useEffect(() => {
    fetchUserData(debouncedQuery, 0);
  }, [debouncedQuery]);
  const handlePageClick = async (event) => {
    setPage(event.selected);

    fetchUserData(debouncedQuery, event.selected);
  };
  function renderUsers(data) {
    if (!data) {
      return <div>Something went wrong!</div>;
    }

    if (data.length > 0) {
      return (
        <div className="w-full max-h-fit overflow-y-auto custom-scrollbar p-2 pl-0">
          {data.map((item) => {
            return (
              <div
                key={item?.id}
                className="border-2 rounded-lg p-4 mt-4 border-gray-300"
              >
                <AvatarWithUsernameAndDescription
                  userData={item}
                  descriptionText={
                    <div className="flex min-w-32 w-max gap-2 w-auto">
                      <p className="w-auto"> {item?.employeeId?.value} </p>
                      <p className="h-6 w-0.5 bg-gray-400"></p>
                      <p className=" w-auto">
                        {' '}
                        {item?.jobTitle?.sourceValue?.value}{' '}
                      </p>
                    </div>
                  }
                />
                {/* <div className="flex gap-3 items-center">
                  <div className=" text-base font-medium ">
                    {item?.fullName?.value}
                  </div>
                  <div className=" text-sm text-gray-600 font-normal ">
                    {item?.employeeId?.value}
                  </div>
                </div> */}
                {/* <div className=" text-sm text-gray-600 font-normal ">
                  {item?.jobTitle?.sourceValue?.value}
                </div> */}
              </div>
            );
          })}
        </div>
      );
    } else {
      return <div> No Users found! </div>;
    }
  }
  return (
    <div>
      <div
        className={` ${
          hideSummary
            ? `w-max  ${
                isNonStickyButton
                  ? 'flex flex-col items-start'
                  : 'fixed -mt-16 mr-2 bg-gradient'
              }  right-4  text-white p-2`
            : 'relative  p-4'
        } bg-gray-50 rounded-lg cursor-pointer ${
          isNonStickyButton ? '' : 'border-gray-200 border'
        } `}
      >
        {hideSummary ? (
          <div
            className={`flex  items-center ${
              isNonStickyButton
                ? 'justify-center bg-gradient rounded-lg break-words p-1 cursor-pointer text-xs'
                : ''
            }`}
            onClick={() => setShowSummary(!hideSummary)}
          >
            <ChevronLeftIcon className="w-6 h-6" /> <p>Show Users</p>
          </div>
        ) : (
          <div>
            <div className="">
              <div
                className="cursor-pointer flex justify-end mb-4"
                onClick={() => setShowSummary(!hideSummary)}
              >
                {' '}
                <div
                  className="flex items-center hover:underline "
                  onClick={() => setShowSummary(!hideSummary)}
                >
                  <ChevronDoubleRightIcon className="w-6 h-6" /> Hide Users
                </div>
              </div>
              <div className=" min-w-[370px] w-full flex justify-between items-center">
                <div>
                  <h3 className="font-semibold text-xl">Summary</h3>
                  <p className="text-sm">
                    {!isLoading && totalListItems > 0 && (
                      <p>Total {totalListItems} Users</p>
                    )}
                  </p>
                </div>
                <div className="flex gap-2">
                  <div
                    onClick={() => fetchUserData(debouncedQuery, 0)}
                    className="p-2 bg-white rounded-lg border-2 cursor-pointer border-gray-200"
                  >
                    <ArrowPathIcon className="w-5 h-5" />
                  </div>

                  <div
                    className={`p-2 bg-white rounded-lg border-2 cursor-pointer border-gray-200 ${
                      totalListItems > 0
                        ? 'border-gray-200'
                        : 'border-gray-50 hover:cursor-not-allowed opacity-40'
                    } `}
                    onClick={() => {
                      if (totalListItems > 0) {
                        downloadEligibleGroupUsers();
                      }
                    }}
                  >
                    <ArrowDownTrayIcon className="w-5 h-5" />
                  </div>
                </div>
              </div>
            </div>

            {isLoading ? (
              <div className="w-[370px] pr-2 mt-8">
                <TableSkeleton />
              </div>
            ) : (
              <div className="w-full">
                <div
                  className={` my-6 flex flex-row items-center justify-center  border-2 gradient-border rounded-lg 2xl:px-[14px] 2xl:py-[10px] py-2 bg-white mb-2`}
                >
                  <SearchIconLogo styles="w-5 h-5 ml-1 text-gray-500" />
                  <input
                    type="text"
                    className="bg-white focus:outline-none w-11/12 pl-2 placeholder:text-sm text-gray-500 mx-1 text-sm sm:block"
                    value={search}
                    placeholder="Search Employee"
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
                {list?.length > 0 ? (
                  <>{renderUsers(list)} </>
                ) : (
                  <div className="flex flex-col items-center justify-center w-full border bg-white rounded-lg p-4">
                    <NoCommunityIcon />
                    <p className="text-base font-semibold pt-4">
                      User will appear here
                    </p>
                    {totalListItems === 0 ? (
                      <p className="text-gray-600 flex items-center gap-1">
                        No user found for the selected filters.
                      </p>
                    ) : (
                      <p className="text-gray-600 flex items-center gap-1">
                        Click <ArrowPathIcon className="w-5 h-5 " /> on the top
                        after adding filters
                      </p>
                    )}
                  </div>
                )}
                <div>
                  <Pagination
                    onPageChanged={handlePageClick}
                    pageCount={Math.ceil(totalListItems / 10)}
                    currentPage={page}
                    showNavigationIconsOnly={true}
                    // hidePrevNext={true}
                    isFixed={false}
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
