const { createSlice } = require('@reduxjs/toolkit');

const initialState = {
  selectedNomination: {},
};

const nominationReducer = createSlice({
  name: 'nomination',
  initialState,
  reducers: {
    setSelectedNomination(state, action) {
      state.selectedNomination = action.payload;
    },
  },
});

export const { setSelectedNomination } = nominationReducer.actions;
export default nominationReducer.reducer;
