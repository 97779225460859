export const enrolmentFrequencyOptions = [
  // {
  //   label: "Daily",
  //   value: "DAILY",
  // },
  {
    label: 'Weekly',
    value: 'WEEKLY',
  },
  {
    label: 'Monthly',
    value: 'MONTHLY',
  },
  {
    label: 'Quarterly',
    value: 'QUARTERLY',
  },
  {
    label: 'Half-yearly',
    value: 'HALF_YEARLY',
  },
  {
    label: 'Annually',
    value: 'ANNUALLY',
  },
  {
    label: 'Life Cycle',
    value: 'LIFE_CYCLE',
  },
];

export const numberOfProofsOptions = [
  {
    label: '0',
    value: 0,
  },
  {
    label: '1',
    value: 1,
  },
  {
    label: '2',
    value: 2,
  },
  {
    label: '3',
    value: 3,
  },
  {
    label: '4',
    value: 4,
  },
  {
    label: '5',
    value: 5,
  },
];

export const weeklyEnrolmentOptions = [
  { value: 'Monday', label: 'Monday' },
  { value: 'Tuesday', label: 'Tuesday' },
  { value: 'Wednesday', label: 'Wednesday' },
  { value: 'Thursday', label: 'Thursday' },
  { value: 'Friday', label: 'Friday' },
  { value: 'Saturday', label: 'Saturday' },
  { value: 'Sunday', label: 'Sunday' },
];

export const monthlyEnrolmentOptions = [
  { value: '6-13', label: '06 - 13 every month' },
  { value: 'last-14-days', label: 'Last 14 Days' },
  { value: 'last-7-days', label: 'Last 7 Days' },
  { value: 'last-5-days', label: 'Last 5 Days' },
  { value: 'last-3-days', label: 'Last 3 Days' },
  { value: 'last-day', label: 'Last Day' },
  { value: 'entire-month', label: 'Entire Month' },
];

export const quarterlyEnrolmentOptions = [
  { value: 'first-7-days', label: 'First 7 Days' },
  { value: 'first-14-days', label: 'First 14 Days' },
  { value: 'first-month', label: 'First Month' },
  { value: 'last-month', label: 'Last Month' },
  { value: 'last-14-days', label: 'Last 14 Days' },
  { value: 'last-7-days', label: 'Last 7 Days' },
  { value: 'last-day', label: 'Last Day' },
  { value: 'anytime-during-period', label: 'Anytime During Period' },
];

export const halfYearlyEnrolmentOptions = [
  { value: 'first-14-days', label: 'First 14 Days' },
  { value: 'first-month', label: 'First Month' },
  { value: 'first-two-months', label: 'First Two Months' },
  { value: 'first-three-months', label: 'First Three Months' },
  { value: 'last-three-months', label: 'Last Three Months' },
  { value: 'last-two-months', label: 'Last Two Months' },
  { value: 'last-month', label: 'Last Month' },
  { value: 'last-14-days', label: 'Last 14 Days' },
  { value: 'anytime-during-period', label: 'Anytime During Period' },
];

export const annuallyEnrolementOptions = [
  { value: 'first-month', label: 'First Month' },
  { value: 'first-quarter', label: 'First Quarter' },
  { value: 'first-half-of-year', label: 'First half of year' },
  { value: 'second-half-of-year', label: 'Second half of year' },
  { value: 'last-quarter', label: 'Last Quarter' },
  { value: 'last-month', label: 'Last Month' },
  { value: 'anytime-during-period', label: 'Anytime During Period' },
];

export const allowedLifeEventsOptions = [
  { label: 'Marriage', value: 'MARRIAGE' },
  { label: 'Child birth', value: 'CHILD_BIRTH' },
  { label: 'Adoption', value: 'ADOPTION' },
  { label: 'Divorce', value: 'DIVORCE' },
  { label: 'Beneficiary Change', value: 'BENEFICIARY_CHANGE' },
  { label: 'Promotion', value: 'PROMOTION' },
  { label: 'Transfer', value: 'TRANSFER' },
  { label: 'Role change', value: 'ROLE_CHANGE' },
  { label: 'Others', value: 'OTHERS' },
];

export const enrolmentApplicableFromOptions = [
  { label: 'Enrolment Date', value: 'Enrollment_Date' },
  { label: 'Start of the next Month', value: 'Start_Of_Next_Month' },
  { label: 'Start of the current month', value: 'Start_Of_Current_Month' },
];
