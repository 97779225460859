import React from 'react';
import ReactSelect from 'react-select';
import { AsyncPaginate } from 'react-select-async-paginate';

const Select = React.forwardRef((props, ref) => {
  const {
    label,
    error,
    async,
    required,
    menuPosition,
    customStyling,
    ...rest
  } = props;
  const customStyles = {
    control: (base, state) => {
      const isSelectNewProgram =
        state.selectProps.id === 'react-select-new-program';
      const isBudgetAllocationField =
        state.selectProps.id === 'budget-allocation-field';
      const shouldRemoveBorder =
        state.selectProps.id === 'remove-border-select' ||
        state.selectProps.id === 'react-select-letter-header';
      //  const transparentBg = state.selectProps.id === 'react-select-letter-header';

      return {
        ...base,
        '&:hover': {
          // borderImage: "linear-gradient(to right, var(--primaryLeft), var(--primaryRight)) 1",
          // borderImageSlice: 1,
          // borderColor: "transparent",
          // borderRadius: "0.5rem",
        },
        boxShadow: state.isFocused ? null : null, // borderImage: state.isFocused // ? "linear-gradient(to right, var(--primaryLeft), var(--primaryRight)) 1" //  : "rgb(229 231 235);",
        borderImageSlice: 1,
        borderColor: shouldRemoveBorder
          ? 'transparent'
          : error
          ? '#e53e3e'
          : state.isFocused
          ? '#888'
          : 'rgb(209 213 219);',
        borderWidth: shouldRemoveBorder ? 0 : 1,
        borderRadius: '0.5rem',
        fontSize: '14px',
        fontWeight: 500,
        color: '#101828',
        fontFamily: 'Figtree', // borderBottomRightRadius: state.isFocused ? 0 : "none", // borderBottomLeftRadius: state.isFocused ? 0 : "none",
        outline: state.isFocused ? null : null,
        backgroundClip: 'padding-box, border-box',
        height:
          isSelectNewProgram || isBudgetAllocationField ? '40px' : base.height,
        marginTop: isSelectNewProgram ? '0.5rem' : base.marginTop,
        width: isSelectNewProgram ? '100%' : base.width,
        // backgroundColor: transparentBg && 'transparent',
        // padding: transparentBg && '0px',
      };
    },
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isDisabled
        ? '#F9F9F9'
        : state.isSelected
        ? '#F9F9F9'
        : state.isFocused
        ? '#eee'
        : undefined,

      color: state.isDisabled ? '#98A2B3' : '#101828',
      fontWeight: 500,
      fontSize: '14px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      fontFamily: 'Figtree',
      position: 'relative',
      cursor: state.isDisabled ? 'not-allowed' : 'pointer',
      // "&:hover": { backgroundColor: "#white" },
      '&::after': {
        width: '10px',
        content: state?.isSelected ? '"\u2713"' : '""', // Unicode checkmark symbol
        position: 'absolute',
        top: '0.5rem',
        right: '1rem',
      },
    }),

    menu: (provided, state) => {
      const isSelectNewProgram =
        state.selectProps.id === 'react-select-new-program';
      return {
        ...provided,
        boxShadow:
          '0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)',
        width: isSelectNewProgram ? '100%' : provided.width,
        zIndex: 999,
      };
    },
    menuList: (provided, state) => ({
      ...provided,
      maxHeight: '300px',
      overflowY: 'scroll',
      '&::-webkit-scrollbar': {
        width: '8px',
        visibility: 'hidden',
      },
      '&:hover::-webkit-scrollbar': {
        visibility: 'visible',
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#888',
        borderRadius: '1rem',
        visibility: 'hidden',
      },
      '&:hover::-webkit-scrollbar-thumb': {
        background: '#d0d5dd',
        visibility: 'visible',
      },
    }),

    menuPortal: (provided, state) => ({
      ...provided,
      zIndex: 99999,
    }),

    indicatorSeparator: () => ({
      display: 'none',
    }), // dropdownIndicator: () => ({ //  display: "none", // }),
    multiValue: (provided, state) => ({
      ...provided,
      backgroundColor: 'white',
      borderRadius: '20px',
      display: 'inline-flex',
      margin: '2px',
      padding: '2px',
      background: state?.isDisabled
        ? 'bg-gray-700'
        : 'linear-gradient(#fff 0 0) padding-box, linear-gradient(to right, var(--primaryLeft), var(--primaryRight)) border-box',
      color: 'black',
      border: '2px solid transparent',
      '& #react-select-hide': {
        display: 'none',
      },
      '& #e-card-image': {
        height: '25px',
        width: '40px',
      },
      '& #e-card-title': {
        fontSize: '12px',
      },
      '& #e-card-description': {
        display: 'none',
      },
    }),

    multiValueLabel: (provided) => ({
      ...provided,
      color: 'black',
    }),

    multiValueRemove: (provided) => ({
      ...provided,
      color: 'black',
      paddingLeft: '4px',
      paddingRight: '4px',
      borderRadius: '50%',
      backgroundColor: 'white',
      ':hover': {
        backgroundColor: 'white',
      },
    }),

    singleValue: (provided) => ({
      ...provided,
      fontSize: '14px',
      color: '#667085',
      fontFamily: 'Figtree',
    }),
  };

  return (
    <React.Fragment>
      {label && (
        <label
          className={`text-sm font-medium ${
            props?.isStep1Program ? 'my-0' : 'my-1'
          } block text-gray-700`}
        >
          {label} {required && <span className="text-red-600">*</span>}
        </label>
      )}
      <div className={`w-full`}>
        {async ? (
          <AsyncPaginate
            ref={ref}
            styles={customStyling || customStyles}
            id={props?.id}
            className={`${props?.className}`}
            menuPosition={menuPosition ? menuPosition : 'fixed'}
            maxMenuHeight={150}
            classNamePrefix="react-select"
            menuPortalTarget={document.body}
            {...rest}
          />
        ) : (
          <ReactSelect
            ref={ref}
            styles={customStyling || customStyles}
            id={props?.id}
            {...rest}
            classNamePrefix="react-select"
            className={props?.className}
            menuPosition={menuPosition ? menuPosition : 'fixed'}
            maxMenuHeight={150}
            menuPortalTarget={document.body}
          />
        )}
      </div>
      {error && <p className="text-red-500 text-sm -mt-.5">{error?.message}</p>}
    </React.Fragment>
  );
});

export default Select;
