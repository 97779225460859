import Chart from 'components/molecules/chart/ChartComponent';
import React from 'react';
import { defaultBarGraphOptions } from '../../../pages/admin/dashboard/utils/constant';

export default function BarGraph({
  data,
  chartOptions,
  pluginOptions,
  plugins,
}) {
  const options = {
    ...defaultBarGraphOptions,
    ...chartOptions,
  };
  return (
    <React.Fragment>
      <Chart
        type={'bar'}
        data={{
          labels: data?.labels,
          datasets: data?.levels,
        }}
        chartOptions={options}
        pluginOptions={pluginOptions}
        plugins={[...(plugins ?? [])]}
      />
    </React.Fragment>
  );
}
