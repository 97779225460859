import { rbacFlags } from 'config/rbacFlags';
import React from 'react';

const ComingSoon = React.lazy(() =>
  import('pages/admin/comingSoon/ComingSoon'),
);
const ManageInsurance = React.lazy(() =>
  import('pages/insurance/manageInsurance/ManageInsurance'),
);

const EnrollInsurance = React.lazy(() =>
  import('pages/insurance/enrollInsurance/EnrollInsurance'),
);

const PolicyInsurance = React.lazy(() =>
  import('pages/insurance/policyInsurance/PolicyInsurance'),
);

const insuranceRoutes = [
  {
    type: 'collapse',
    name: 'insurance',
    key: 'insurance',
    parentId: 'Dashboard',
    route: '/insurance/manage-insurance',
    module: 'Insurance',

    component: <ManageInsurance />,
  },
  {
    type: 'collapse',
    name: 'insurance',
    key: 'insurance',
    parentId: 'Dashboard',
    route: '/insurance/enroll-insurance',
    module: 'Insurance',

    component: <EnrollInsurance />,
  },
  {
    type: 'collapse',
    name: 'insurance',
    key: 'insurance',
    parentId: 'Dashboard',
    route: '/insurance/create-policy',
    module: 'Insurance',

    component: <PolicyInsurance />,
  },
  //   {
  //     type: 'collapse',
  //     name: 'Dashboard',
  //     key: 'Dashboard',
  //     route: '/insurance/dashboard',
  //     parentId: 'Admin',
  //     flagName: 'ManageInsuranceClaim',
  //     module: 'Insurance',
  //     component: <ComingSoon />,
  //   },
  {
    type: 'collapse',
    name: 'Summary',
    key: 'Summary',
    route: '/insurance/summary',
    parentId: 'Admin',
    flagName: rbacFlags.EnrolAndClaimInsurance,
    module: 'Insurance',
    component: <ComingSoon />,
  },
  //   {
  //     type: 'collapse',
  //     name: 'Health Insurance',
  //     key: 'Health Insurance',
  //     route: '/insurance/health-insurance',
  //     parentId: 'Admin',
  //     flagName: 'EnrolAndClaimInsurance',
  //     module: 'Insurance',
  //     component: <ComingSoon />,
  //   },
  //   {
  //     type: 'collapse',
  //     name: 'Accidental',
  //     key: 'Accidental',
  //     route: '/insurance/accidental',
  //     parentId: 'Admin',
  //     flagName: 'EnrolAndClaimInsurance',
  //     module: 'Insurance',
  //     component: <ComingSoon />,
  //   },
  //   {
  //     type: 'collapse',
  //     name: 'Life Insurance',
  //     key: 'Life Insurance',
  //     route: '/insurance/life-insurance',
  //     parentId: 'Admin',
  //     flagName: 'EnrolAndClaimInsurance',
  //     module: 'Insurance',
  //     component: <ComingSoon />,
  //   },
  {
    type: 'collapse',
    name: 'My Claims',
    key: 'My Claims',
    route: '/insurance/my-claims',
    parentId: 'Admin',
    flagName: rbacFlags.EnrolAndClaimInsurance,
    module: 'Insurance',
    component: <ComingSoon />,
  },
];

export default insuranceRoutes;
