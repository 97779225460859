export const FilterSkeletonLoader = () => {
  return (
    <div className="animate-pulse w-full">
      <div className="flex items-center gap-3 mb-3">
        <div className="w-4 h-4 bg-slate-200 rounded"></div>
        <div className="h-2 bg-slate-200 w-full"></div>
      </div>
    </div>
  );
};
