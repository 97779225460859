import {
  ConfigurationKeysEnum,
  EndOfRecurrenceEnum,
  EventOccurrenceEnum,
  EventsSettings,
  PayoutAmountsEnum,
  PayoutTimingEnum,
  RangeOrSpecificValueEnum,
  SlabsInclusionEnum,
  UserGroupConditionSpecificityEnum,
  UserGroupPayoutSepecificityEnum,
} from 'pages/variablePay/VariablePay.types';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Configuration } from '../Configuration';

type PayoutConfigurationProps = {
  setEventSettings: Dispatch<SetStateAction<EventsSettings>>;
  eventSettings: EventsSettings;
  configuration: { [key: string]: any };
};

export const EventConfiguration = ({
  eventSettings,
  setEventSettings,
  configuration,
}: PayoutConfigurationProps) => {
  const [eventConfiguration, setEventConfiguration] = useState(configuration);

  useEffect(() => {
    if (
      eventSettings?.vPayPayoutAmounts?.value ===
        PayoutAmountsEnum.LOGIC_BASED_CALCULATION &&
      !eventSettings?.vPaySlabsInclusion
    ) {
      setEventSettings({
        ...eventSettings,
        vPaySlabsInclusion: {
          value: SlabsInclusionEnum.NO_SLABS,
        },
        vPayRangeSpecificity: {
          value: RangeOrSpecificValueEnum.SPECIFIC_VALUE,
        },
        vPayUGConditionSpecificity: {
          value: UserGroupConditionSpecificityEnum.SAME_FOR_ALL,
        },
        vPayUGPayoutSpecificity: {
          value: UserGroupPayoutSepecificityEnum.SAME_PAYOUT_FOR_ALL,
        },
        vPayOccurrence: { value: EventOccurrenceEnum.ONE_TIME },
        vPayPayoutTiming: { value: PayoutTimingEnum.ON_EVENT_DATE },
      });
    } else if (
      eventSettings?.vPayOccurrence?.value === EventOccurrenceEnum.RECURRING &&
      !eventSettings?.vPayEndRecurrence
    ) {
      setEventSettings({
        ...eventSettings,
        vPayEndRecurrence: { value: EndOfRecurrenceEnum.NO_END_DATE },
      });
    }
  }, [eventSettings]);

  useEffect(() => {
    if (configuration) {
      if (
        eventSettings?.vPayPayoutAmounts?.value ===
        PayoutAmountsEnum.PRECALCULATED
      ) {
        setEventConfiguration({
          ...configuration,
          children: configuration?.children?.filter(
            ({ key }: { key: ConfigurationKeysEnum }) =>
              key === ConfigurationKeysEnum.vPayEnableProration ||
              key === ConfigurationKeysEnum.vPayEnableClawback,
          ),
        });
      } else if (
        eventSettings?.vPayPayoutAmounts?.value ===
          PayoutAmountsEnum.LOGIC_BASED_CALCULATION &&
        eventSettings?.vPayOccurrence?.value === EventOccurrenceEnum.ONE_TIME
      ) {
        setEventConfiguration({
          ...configuration,
          children: configuration?.children?.filter(
            ({ key }: { key: ConfigurationKeysEnum }) =>
              key !== ConfigurationKeysEnum.vPayEndRecurrence,
          ),
        });
      } else {
        setEventConfiguration(configuration);
      }
    }
  }, [eventSettings, configuration]);

  const handleChange = (key: ConfigurationKeysEnum, value: string) => {
    const updatedSettings = eventSettings;
    if (value === PayoutAmountsEnum.PRECALCULATED) {
      delete updatedSettings?.vPayCalculationField;
      delete updatedSettings?.vPayAchievementMeasurement;
      delete updatedSettings?.vPayAttainmentInput;
      delete updatedSettings?.vPayAttainmentDependency;
      delete updatedSettings?.vPayDependentFields;
    }
    setEventSettings({ ...updatedSettings, [key]: { value } });
  };

  return (
    <Configuration
      eventSettings={eventSettings}
      setEventSettings={setEventSettings}
      configuration={eventConfiguration}
      handleChange={handleChange}
    />
  );
};
