export const customStyles = {
  control: (base, state) => ({
    ...base,
    '&:hover': {
      borderImage:
        'linear-gradient(to right, var(--primaryLeft), var(--primaryRight)) 1',
      borderImageSlice: 1,
      borderColor: 'transparent',
      borderRadius: '0.5rem',
    },
    boxShadow: state.isFocused ? null : null,
    borderImage: state.isFocused
      ? 'linear-gradient(to right, var(--primaryLeft), var(--primaryRight)) 1'
      : 'rgb(229 231 235);',
    borderImageSlice: 1,
    borderColor: state.isFocused ? 'transparent' : 'rgb(229 231 235);',
    borderWidth: 2,
    borderRadius: '0.5rem',
    // borderBottomRightRadius: state.isFocused ? 0 : "none",
    // borderBottomLeftRadius: state.isFocused ? 0 : "none",
    outline: state.isFocused ? null : null,
    backgroundClip: 'padding-box, border-box',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#fffff' : 'white',
    color: state.isSelected ? 'black' : 'inherit',
    cursor: 'pointer',
    // "&:hover": { backgroundColor: "#white" },
  }),
  menu: (provided) => ({
    ...provided,
    boxShadow:
      '0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)',
  }),
  menuList: (provided, state) => ({
    ...provided,
    height: 'auto',
    maxHeight: '300px',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      width: '8px',
      visibility: 'hidden',
    },
    '&:hover::-webkit-scrollbar': {
      visibility: 'visible',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#888',
      borderRadius: '1rem',
      visibility: 'hidden',
    },
    '&:hover::-webkit-scrollbar-thumb': {
      background: '#d0d5dd',
      visibility: 'visible',
    },
  }),
  menuPortal: (provided, state) => ({
    ...provided,
    zIndex: 9999,
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  // dropdownIndicator: () => ({
  //   display: "none",
  // }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: 'white',
    borderRadius: '20px',
    display: 'inline-flex',
    margin: '2px',
    padding: '2px',
    background:
      'linear-gradient(#fff 0 0) padding-box, linear-gradient(to right, var(--primaryLeft), var(--primaryRight)) border-box',
    color: 'black',
    border: '2px solid transparent',
    '& #react-select-hide': {
      display: 'none',
    },
  }),

  multiValueLabel: (provided) => ({
    ...provided,
    color: 'black',
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    color: 'black',
    paddingLeft: '4px',
    paddingRight: '4px',
    borderRadius: '50%',
    backgroundColor: 'white',
    ':hover': {
      backgroundColor: 'white',
    },
  }),
};
