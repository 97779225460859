export const customStyles = {
  control: (base, state) => {
    const isSelectNewProgram = state.selectProps.id === 'select-new-program';
    const isBudgetAllocationField =
      state.selectProps.id === 'budget-allocation-field';
    const shouldRemoveBorder = state.selectProps.id === 'remove-border-select';

    return {
      ...base,
      '&:hover': {
        // borderImage: "linear-gradient(to right, var(--primaryLeft), var(--primaryRight)) 1",
        // borderImageSlice: 1,
        // borderColor: "transparent",
        // borderRadius: "0.5rem",
      },
      boxShadow: state.isFocused ? null : null,
      // borderImage: state.isFocused
      //   ? 'linear-gradient(to right, var(--primaryLeft), var(--primaryRight)) 1'
      //   : 'rgb(229 231 235);',
      borderImageSlice: 1,
      borderColor: shouldRemoveBorder
        ? 'transparent'
        : state.isFocused
        ? 'rgb(255,0,0)'
        : 'rgb(209 213 219);',
      borderWidth: shouldRemoveBorder ? 0 : 1,
      borderRadius: '0.5rem',
      fontSize: '14px',
      color: '#667085',
      fontFamily: 'Figtree', // borderBottomRightRadius: state.isFocused ? 0 : "none", // borderBottomLeftRadius: state.isFocused ? 0 : "none",
      outline: state.isFocused ? null : null,
      backgroundClip: 'padding-box, border-box',
      height:
        isSelectNewProgram || isBudgetAllocationField ? '40px' : base.height,
      marginTop: isSelectNewProgram ? '0.5rem' : base.marginTop,
      width: isSelectNewProgram ? '100%' : base.width,
    };
  },
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isDisabled
      ? undefined
      : state.isFocused
      ? '#eee'
      : undefined,
    cursor: 'pointer',
    fontSize: '14px',
    color: '#667085',
    fontFamily: 'Figtree', // "&:hover": { backgroundColor: "#white" },
    display: state.isSelected && 'flex',
    justifyContent: 'space-between',
    '&::after': {
      content: state.isSelected ? '"\u2713"' : '""', // Unicode checkmark for selected option
      display: 'flex',
      // position: "absolute",
      // right: "10px", // Adjust the right offset as needed
      fontSize: '16px', // Adjust the font size as needed
      color: 'rgb(255,0,0)', // Adjust the color as needed
    },
  }),

  menu: (provided, state) => {
    const isSelectNewProgram = state.selectProps.id === 'select-new-program';
    return {
      ...provided,
      boxShadow:
        '0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)',
      width: isSelectNewProgram ? '100%' : provided.width,
      zIndex: 999,
    };
  },
  menuList: (provided, state) => ({
    ...provided,
    maxHeight: '300px',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      width: '8px',
      visibility: 'hidden',
    },
    '&:hover::-webkit-scrollbar': {
      visibility: 'visible',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#888',
      borderRadius: '1rem',
      visibility: 'hidden',
    },
    '&:hover::-webkit-scrollbar-thumb': {
      background: '#d0d5dd',
      visibility: 'visible',
    },
  }),

  menuPortal: (provided, state) => ({
    ...provided,
    zIndex: 9999,
  }),

  indicatorSeparator: () => ({
    display: 'none',
  }),
  // dropdownIndicator: () => ({ display: 'none' }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: 'white',
    borderRadius: '20px',
    display: 'inline-flex',
    margin: '2px',
    padding: '2px',
    background:
      'linear-gradient(#fff 0 0) padding-box, linear-gradient(to right, var(--primaryLeft), var(--primaryRight)) border-box',
    color: 'black',
    border: '2px solid transparent',
    '& #react-select-hide': {
      display: 'none',
    },
    '& #e-card-image': {
      height: '25px',
      width: '40px',
    },
    '& #e-card-title': {
      fontSize: '12px',
    },
    '& #e-card-description': {
      display: 'none',
    },
  }),

  multiValueLabel: (provided) => ({
    ...provided,
    color: 'black',
  }),

  multiValueRemove: (provided) => ({
    ...provided,
    color: 'black',
    paddingLeft: '4px',
    paddingRight: '4px',
    borderRadius: '50%',
    backgroundColor: 'white',
    ':hover': {
      backgroundColor: 'white',
    },
  }),

  singleValue: (provided) => ({
    ...provided,
    fontSize: '14px',
    color: '#667085',
    fontFamily: 'Figtree',
  }),
};
