import React from 'react';

const ImageUploadIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.3333 3.16675L14.8333 0.666748M14.8333 0.666748L17.3333 3.16675M14.8333 0.666748V5.66675M9.41667 1.50008H5.5C4.09987 1.50008 3.3998 1.50008 2.86502 1.77257C2.39462 2.01225 2.01217 2.3947 1.77248 2.86511C1.5 3.39988 1.5 4.09995 1.5 5.50008V12.5001C1.5 13.9002 1.5 14.6003 1.77248 15.1351C2.01217 15.6055 2.39462 15.9879 2.86502 16.2276C3.3998 16.5001 4.09987 16.5001 5.5 16.5001H13.1667C13.9416 16.5001 14.3291 16.5001 14.647 16.4149C15.5098 16.1837 16.1836 15.5099 16.4148 14.6471C16.5 14.3292 16.5 13.9417 16.5 13.1667M7.75 6.08341C7.75 7.00389 7.00381 7.75008 6.08333 7.75008C5.16286 7.75008 4.41667 7.00389 4.41667 6.08341C4.41667 5.16294 5.16286 4.41675 6.08333 4.41675C7.00381 4.41675 7.75 5.16294 7.75 6.08341ZM11.4917 8.93187L4.44262 15.3401C4.04614 15.7006 3.84789 15.8808 3.83036 16.0369C3.81516 16.1722 3.86704 16.3064 3.96932 16.3964C4.08732 16.5001 4.35523 16.5001 4.89107 16.5001H12.7133C13.9126 16.5001 14.5123 16.5001 14.9833 16.2986C15.5745 16.0457 16.0456 15.5746 16.2985 14.9833C16.5 14.5124 16.5 13.9127 16.5 12.7134C16.5 12.3099 16.5 12.1081 16.4559 11.9202C16.4005 11.6841 16.2941 11.4629 16.1444 11.2721C16.0252 11.1202 15.8677 10.9942 15.5526 10.7421L13.2215 8.8773C12.9062 8.62501 12.7485 8.49886 12.5748 8.45434C12.4218 8.4151 12.2607 8.42018 12.1104 8.46899C11.94 8.52437 11.7905 8.6602 11.4917 8.93187Z"
        stroke="#475467"
        strokeWidth="1.33"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ImageUploadIcon;
