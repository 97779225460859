const GraphLoader = () => {
  return (
    <div className="bg-white rounded-lg shadow-md p-4 flex animate-pulse gap-2 items-baseline h-full w-full">
      <div className="w-full h-1/2 bg-gray-300 rounded mb-2"></div>
      <div className="w-full h-2/3 bg-gray-300 rounded mb-2"></div>
      <div className="w-full h-3/4 bg-gray-300 rounded mb-2"></div>
      <div className="w-full h-full bg-gray-300 rounded"></div>
      <div className="w-full h-[90%] bg-gray-300 rounded"></div>
      <div className="w-full h-full bg-gray-300 rounded"></div>
      <div className="w-full h-1/2 bg-gray-300 rounded mb-2"></div>
      <div className="w-full h-2/3 bg-gray-300 rounded mb-2"></div>
      <div className="w-full h-3/4 bg-gray-300 rounded mb-2"></div>
    </div>
  );
};

export default GraphLoader;
