import ToolTipComponent from 'components/atoms/tooltip/ToolTipComponent';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Confetti from 'react-confetti';
const color = ['#F63D68', '#22CCEE', '#FAC515', '#7A5AF8'];

const ReviewHeaders = (props) => {
  const {
    offerDetailsData,
    parsedOrgDetails,
    totalCompensationValue,
    compDataList,
  } = props;
  const PERCENT_DECIMAL = 1;
  const [isConfettiVisible, setConfettiVisible] = useState(true);
  useEffect(() => {
    // Stop confetti after 10 seconds
    const timer = setTimeout(() => {
      setConfettiVisible(false);
    }, 10000);

    return () => clearTimeout(timer);
  }, []);
  return (
    <>
      {isConfettiVisible && (
        <div style={{ position: 'relative' }}>
          {' '}
          <Confetti
            numberOfPieces={200}
            width={window.innerWidth}
            height={window.innerHeight}
          />
        </div>
      )}
      <div className="w-full h-[250px] z-30 flex flex-col gap-8 items-center justify-center bg-white">
        <p className="text-xl font-semibold">
          Congratulations,{' '}
          {offerDetailsData?.candidateDetails?.applicationFullName}!
        </p>
        <p className="text-sm text-gray-600">
          Hey {offerDetailsData?.candidateDetails?.applicationFullName}! We’re
          pleased to present you this offer to join {parsedOrgDetails?.orgName},
          as a {offerDetailsData?.jobDetails?.jobCode?.name}. Here’s you will
          find your Total Paymix.
        </p>
      </div>
      <div className="flex flex-col w-full px-4 py-6 gap-4 bg-white border-b">
        <div className="flex items-center justify-between w-full  bg-white">
          <div className="flex flex-col gap-1">
            <p className="text-lg font-semibold text-gray-600">Pay-mix</p>
            <p className="text-sm text-gray-600">
              Your Total Compensation is{' '}
              <span className="font-semibold text-black">
                INR {totalCompensationValue}/yr
              </span>
            </p>
          </div>
          <div className="flex flex-col gap-1 justify-end items-end">
            {/* <p className="flex items-center text-base text-gray-700">
                <Toggle
                  checked={realizedWealth}
                  onChange={(e) => {
                    // setShowWithRealizedWealth(!showWithRealizedWealth);
                    setRealizedWealth(e?.target?.checked);
                  }}
                />
                <span className="pr-1">With realized wealth</span>{' '}
                <QuestionMarkCircleIcon className="w-4 h-4" />
              </p> */}
            <p className="text-sm text-gray-600">
              effective date{' '}
              {moment(offerDetailsData?.approvalDate).format('DD MMM, YYYY')}
            </p>
          </div>
        </div>
        <div className="flex w-full h-10 bg-white">
          {compDataList?.map((item, index) => {
            const calculatedWidth = `${(
              (parseInt(item?.total) / totalCompensationValue) *
              100
            )?.toFixed(PERCENT_DECIMAL)}%`;
            const isFirst = index === 0;
            const isLast = index === compDataList.length - 1;

            return (
              <>
                <ToolTipComponent
                  id="tooltip-value-percentage"
                  place="top"
                  type="light"
                />
                <div
                  className={
                    `h-full border flex items-center justify-start grow px-2 text-white font-semibold text-xs cursor-pointer` +
                    (isFirst ? ' rounded-l-lg' : '') +
                    (isLast ? ' rounded-r-lg' : '')
                  }
                  data-tooltip-id="tooltip-value-percentage"
                  data-tooltip-content={`${item?.name} ${parseFloat(
                    calculatedWidth,
                  )?.toFixed(PERCENT_DECIMAL)} %`}
                  key={item?.name}
                  style={{
                    width: calculatedWidth,
                    backgroundColor: color[index % color?.length], // Repeat colors from the color array
                  }}
                >
                  <span className="bg-gray-600 bg-opacity-40 py-1 px-2 rounded-3xl overflow-hidden truncate ">
                    {parseFloat(calculatedWidth)?.toFixed(PERCENT_DECIMAL)} %
                    {/* 20 % */}
                  </span>
                </div>
              </>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default ReviewHeaders;
