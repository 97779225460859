import { ZodTypeDef, z } from 'zod';

export const createServiceWithZod =
    <
        TInputOutput,
        TOutputOutput,
        TInputDef extends ZodTypeDef = ZodTypeDef,
        TInputInput = TInputOutput,
        TOutputDef extends ZodTypeDef = ZodTypeDef,
        TOutputInput = TOutputOutput
    >(
        inputSchema: z.Schema<TInputOutput, TInputDef, TInputInput>,
        outputSchema: z.Schema<TOutputOutput, TOutputDef, TOutputInput>,
        serviceCallback: (input: TInputOutput, req?: Request) => Promise<TOutputOutput>
    ) =>
    async (input: TInputInput, req?: Request): Promise<TOutputOutput> => {
        try {
            const inputData = inputSchema.parse(input);
            const outputData = await serviceCallback(inputData, req);
            return outputSchema.parse(outputData);
        } catch (error) {
            throw error;
        }
    };
