import React from 'react';
import { useFormContext } from 'react-hook-form';
import Heading from '../../atoms/heading/Heading';
import Paragraph from '../../atoms/paragraph/Paragraph';
import ButtonAtom from '../../atoms/buttonAtom/ButtonAtom';
import { htmlParser } from 'utils/jsUtils';

const HPBComponent = (props) => {
  const { data } = props;
  const { selectAtomsMolecules, type, path } = data;
  const { watch } = useFormContext();
  const [justifyContent, content] = watch([
    `${path}.flex.justify`,
    `${path}.para.value`,
  ]);
  return (
    <div
      style={{ justifyContent: justifyContent ?? 'center' }}
      onClick={() => selectAtomsMolecules(type)}
      className="w-full h-full flex flex-col cursor-pointer items-start gap-3 p-4 overflow-y-auto"
    >
      <Heading data={data} />
      {content ? (
        <div
          onClick={() => selectAtomsMolecules(type)}
          className="parent-list-default flex flex-col h-20 break-words w-full overflow-y-auto"
        >
          {htmlParser(content)}{' '}
        </div>
      ) : (
        <div className="overflow-y-auto w-full break-words">
          <Paragraph data={data} />
        </div>
      )}
      <ButtonAtom data={data} />
    </div>
  );
};

export default HPBComponent;
