export const formatDate = (date) => {
  const newDate = new Date(date);

  const month = newDate.toLocaleString('en-GB', { month: 'short' });
  const day = newDate.toLocaleString('en-GB', { day: 'numeric' });
  const year = newDate.toLocaleString('en-GB', { year: 'numeric' });

  return `${month} ${day}, ${year}`;
};

export const formatDateMMddyyyy = (inputDate) => {
  const date = new Date(inputDate);
  const day = date.getDate().toString().padStart(2, '0');

  const year = date.getFullYear().toString();
  return `${date.getMonth() + 1}-${day}-${year}`;
};
