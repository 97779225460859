import VerticalEclipse from 'assets/svg/verticalEclipse';
import Avatar from 'layouts/header/Avatar';
import { imageURLCheck } from 'utils/ImageUrlCheck';

export const VersionHistory = ({ versionHistory }) => {
  const formattedDate = (dateObject) => {
    // Extract day, month, and year components from the Date object
    const day = dateObject.getDate().toString().padStart(2, '0'); // Ensure two digits (padStart)
    const month = (dateObject.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const year = dateObject.getFullYear();

    // Format the components into "dd/mm/yyyy" format
    return `${day}/${month}/${year}`;
  };

  return (
    <div className="space-y-3">
      {versionHistory?.calculationHistory?.map((data, index) => {
        return (
          <div
            key={data?.id}
            className="flex justify-between border border-gray-200 rounded-xl p-4"
          >
            <div className="space-y-4">
              <p className="text-sm font-semibold text-gray-900">
                {formattedDate(new Date(data?.createdDate))}
              </p>
              {Object.entries(versionHistory.benchmarkMap || {}).map(
                (benchmark) => {
                  return (
                    <div key={benchmark[0]} className="space-y-1">
                      {data?.finalCalc?.[`Max_${benchmark[0]}`] !==
                        versionHistory?.calculationHistory?.[index + 1]
                          ?.finalCalc?.[`Max_${benchmark[0]}`] &&
                        data?.finalCalc?.[`Min_${benchmark[0]}`] !==
                          versionHistory?.calculationHistory?.[index + 1]
                            ?.finalCalc?.[`Min_${benchmark[0]}`] && (
                          <p className="text-sm font-semibold text-gray-900">
                            {benchmark[1]}
                          </p>
                        )}
                      {data?.finalCalc?.[`Min_${benchmark[0]}`] !==
                        versionHistory?.calculationHistory?.[index + 1]
                          ?.finalCalc?.[`Min_${benchmark[0]}`] && (
                        <p className="text-gray-500 flex items-center gap-1">
                          <span>Min Salary:</span>
                          <span className="text-gray-900">
                            {data?.finalCalc?.[
                              `Min_${benchmark[0]}`
                            ].toLocaleString()}
                          </span>
                          {index <
                            versionHistory?.calculationHistory?.length - 1 && (
                            <span className="line-through">
                              {versionHistory?.calculationHistory?.[
                                index + 1
                              ]?.finalCalc?.[
                                `Min_${benchmark[0]}`
                              ].toLocaleString()}
                            </span>
                          )}
                        </p>
                      )}
                      {data?.finalCalc?.[`Mid_${benchmark[0]}`] !==
                        versionHistory?.calculationHistory?.[index + 1]
                          ?.finalCalc?.[`Mid_${benchmark[0]}`] && (
                        <p className="text-gray-500 flex items-center gap-1">
                          <span>Mid Salary:</span>
                          <span className="text-gray-900">
                            {data?.finalCalc?.[
                              `Mid_${benchmark[0]}`
                            ].toLocaleString()}
                          </span>
                          {index <
                            versionHistory?.calculationHistory?.length - 1 && (
                            <span className="line-through">
                              {versionHistory?.calculationHistory?.[
                                index + 1
                              ]?.finalCalc?.[
                                `Mid_${benchmark[0]}`
                              ].toLocaleString()}
                            </span>
                          )}
                        </p>
                      )}
                      {data?.finalCalc?.[`Max_${benchmark[0]}`] !==
                        versionHistory?.calculationHistory?.[index + 1]
                          ?.finalCalc?.[`Max_${benchmark[0]}`] && (
                        <p className="text-gray-500 flex items-center gap-1">
                          <span>Max Salary:</span>
                          <span className="text-gray-900">
                            {data?.finalCalc?.[
                              `Max_${benchmark[0]}`
                            ].toLocaleString()}
                          </span>
                          {index <
                            versionHistory?.calculationHistory?.length - 1 && (
                            <span className="line-through">
                              {versionHistory?.calculationHistory?.[
                                index + 1
                              ]?.finalCalc?.[
                                `Max_${benchmark[0]}`
                              ].toLocaleString()}
                            </span>
                          )}
                        </p>
                      )}
                    </div>
                  );
                },
              )}
              <div className="flex items-center gap-3">
                <Avatar
                  userName={
                    versionHistory?.userData?.filter(
                      (user) => user?.userId === data?.createdBy,
                    )?.[0]?.fullName?.value
                  }
                  src={imageURLCheck(
                    versionHistory?.userData?.filter(
                      (user) => user?.userId === data?.createdBy,
                    )?.[0]?.avatar?.value,
                  )}
                />
                <div>
                  <p className="font-semibold text-sm text-gray-900">
                    {
                      versionHistory?.userData?.filter(
                        (user) => user?.userId === data?.createdBy,
                      )?.[0]?.fullName?.value
                    }
                  </p>
                  <p className="text-sm text-gray-500">
                    {formattedDate(new Date(data?.createdDate))}
                  </p>
                </div>
              </div>
            </div>
            <VerticalEclipse className="w-5 h-5" />
          </div>
        );
      })}
    </div>
  );
};
