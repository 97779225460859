import React from 'react';

const ClockCheckIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
    >
      <path
        d="M12.0834 16.3346L13.75 18.0013L17.5 14.2513M18.321 10.9595C18.3292 10.8078 18.3334 10.6551 18.3334 10.5013C18.3334 5.89893 14.6024 2.16797 10 2.16797C5.39765 2.16797 1.66669 5.89893 1.66669 10.5013C1.66669 15.0308 5.28045 18.7163 9.7821 18.8318M10 5.5013V10.5013L13.1153 12.059"
        stroke="#475467"
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ClockCheckIcon;
