import { colorArrayShade2, colorArrayShade4 } from 'utils/colorsArray';

const getColor1 = (index) => colorArrayShade2[index] || '#98A2B3';

const getColor = (selectedPalete, index) =>
  selectedPalete?.length > 0
    ? selectedPalete[index]
    : getColor1(index) || '#98A2B3';

export const formatDataSet = (selectedPalete, dataObj) => {
  if (!dataObj) return { labels: [], datasets: [] };
  const dimensionEntries =
    Object.values(dataObj?.dimensions || {})?.flat(2) || [];
  const measureEntries = Object.values(dataObj?.measures || {})?.flat(2) || [];

  let data = {
    labels: dimensionEntries,
    datasets: [
      { data: measureEntries, backgroundColor: ['#F63D68'], borderWidth: 1 },
    ],
  };
  return data;
};

export const formatDataSetForBarChartAndLineChart = (
  selectedPalete,
  dataObj,
) => {
  // return formattedData;
  if (!dataObj) return { labels: [], datasets: [] };

  // Extract dimension values
  const dimensionKeys = Object.keys(dataObj?.dimensions);

  const dimensionValues = dimensionKeys?.map(
    (key) => dataObj?.dimensions[key][0],
  );

  // Generate unique labels by combining dimension values
  const labels = dimensionValues[0]?.map((_, index) =>
    dimensionKeys
      ?.map((key) => dataObj?.dimensions[key][0][index])
      ?.join(' - '),
  );

  // Extract measure keys
  const measureKeys = Object.keys(dataObj?.measures);

  // Generate datasets for each measure
  const datasets = measureKeys?.map((key, index) => ({
    label: key, // Use measure key as label
    data: (dataObj?.measures[key] || [])?.flat(2),
    backgroundColor: getColor(selectedPalete, index), // Function to generate random color or you can use specific colors
    borderColor: getColor(selectedPalete, index), // Border color
  }));

  const formattedData = {
    labels: labels,
    datasets: datasets,
  };

  return formattedData;
};

export const formatDatasSetForAreaChart = (selectedPalete, dataObj) => {
  if (!dataObj) return { labels: [], datasets: [] };

  // Extract dimension values
  const dimensionKeys = Object.keys(dataObj?.dimensions);

  const dimensionValues = dimensionKeys?.map(
    (key) => dataObj?.dimensions[key][0],
  );

  // Generate unique labels by combining dimension values
  const labels = dimensionValues[0]?.map((_, index) =>
    dimensionKeys
      ?.map((key) => dataObj?.dimensions[key][0][index])
      ?.join(' - '),
  );

  // Extract measure keys
  const measureKeys = Object.keys(dataObj?.measures);

  function hexToRgba(hex, opacity = 0.15) {
    // Remove the leading # if present
    hex = hex.replace('#', '');

    // Convert 3-digit hex to 6-digit
    if (hex.length === 3) {
      hex = hex
        .split('')
        .map((char) => char + char)
        .join('');
    }

    // Extract red, green, and blue components
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    // Return the RGBA string
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  }
  // const getColor = (index) => colorArrayShade4[index] || '#98A2B3';
  const getColorBorder = (index) => colorArrayShade2[index] || '#98A2B3';

  // Generate datasets for each measure
  const datasets = measureKeys?.map((key, index) => ({
    label: key, // Use measure key as label
    data: (dataObj?.measures[key] || [])?.flat(2),
    //backgroundColor: getColor(selectedPalete, index), // Function to generate random color or you can use specific colors
    backgroundColor: hexToRgba(getColor(selectedPalete, index)), // Function to generate random color or you can use specific colors
    //    borderColor: getColorBorder(index), // Border color
    borderColor: getColor(selectedPalete, index), // Border color
    fill: true,
  }));

  const formattedData = {
    labels: labels,
    datasets: datasets,
  };

  return formattedData;
};

export const formatDataSetForPieDoughnutChart = (selectedPalete, dataObj) => {
  if (!dataObj) return { labels: [], datasets: [] };

  const dimensionKeys = Object.keys(dataObj?.dimensions);
  const flattenedDimensions = dimensionKeys?.map(
    (key) => dataObj?.dimensions[key][0],
  );

  const measureKey = Object.keys(dataObj?.measures)[0];
  const measureEntries = (dataObj?.measures[measureKey] || [])?.flat(2);

  const labels = flattenedDimensions[0]?.map((_, index) =>
    dimensionKeys
      ?.map((key) => dataObj?.dimensions[key][0][index])
      ?.join(' - '),
  );

  const dataset = {
    label: measureKey, // Use the measure key as the label
    data: measureEntries,
    backgroundColor: measureEntries.map((_, index) =>
      getColor(selectedPalete, index),
    ), // Apply the randomly generated colors
    borderWidth: 1,
  };

  const formattedData = {
    labels: labels,
    datasets: [dataset],
  };

  return formattedData;
};

export const formatDataSetForStackedColumnChart = (selectedPalete, dataObj) => {
  if (!dataObj) return { labels: [], datasets: [] };

  // Extract dimension values
  const dimensionKeys = Object.keys(dataObj?.dimensions);

  const dimensionValues = dimensionKeys?.map(
    (key) => dataObj?.dimensions[key][0],
  );

  // Generate unique labels by combining dimension values
  const labels = dimensionValues[0]?.map((_, index) =>
    dimensionKeys
      ?.map((key) => dataObj?.dimensions[key][0][index])
      ?.join(' - '),
  );

  // Extract measure keys
  const measureKeys = Object.keys(dataObj?.measures);

  const getColor = (index) => colorArrayShade2[index] || '#98A2B3';

  // Generate datasets for each measure
  const datasets = measureKeys?.map((key, index) => ({
    label: key, // Use measure key as label
    data: (dataObj?.measures[key] || [])?.flat(2),
    backgroundColor: selectedPalete(index), // Function to generate random color or you can use specific colors
    stack: 'stack', // Ensure stacking
  }));

  const formattedData = {
    labels: labels,
    datasets: datasets,
  };

  return formattedData;
};

export const formatDataSetForScatterChart = (
  selectedPalete,
  dataObj,
  displayLegend,
) => {
  const dimensionKey = Object.keys(dataObj?.dimensions || {})[0];
  const measureKeys = Object.keys(dataObj?.measures || {});
  const xMeasureKey = Object.keys(dataObj?.measures)[0]; // x-axis measure
  const yMeasureKey = Object.keys(dataObj?.measures)[1]; // y-axis measure
  if (!dataObj || !dimensionKey || measureKeys?.length < 2)
    return { datasets: [] };
  const dimensionValues = dataObj?.dimensions[dimensionKey]?.[0] || [];
  const xMeasureValues = dataObj?.measures[measureKeys[0]]?.[0] || [];
  const yMeasureValues = dataObj?.measures[measureKeys[1]]?.[0] || [];

  // Create dataset
  const dataset = dimensionValues?.map((dimValue, index) => ({
    label: dimValue, // Use dimension value as the label
    data: [
      {
        x: xMeasureValues[index], // x coordinate from first measure
        y: yMeasureValues[index], // y coordinate from second measure
      },
    ],
    backgroundColor: getColor(selectedPalete, index),
    borderWidth: 1,
  }));

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          color: '#EDEDED',
        },
      },
      y: {
        grid: {
          color: '#EDEDED',
        },
      },
    },
    plugins: {
      legend: {
        display: displayLegend,
        position: 'top',
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            const dataPoint = tooltipItem.raw;
            return [
              `X:${xMeasureKey}: ${dataPoint?.x}`,
              `Y:${yMeasureKey}: ${dataPoint?.y}`,
            ];
          },
        },
      },
    },
  };

  return { formattedData: { datasets: dataset }, options: options };
};

export const formatDataSetForBubbleChart = (
  selectedPalete,
  dataObj,
  displayLegend,
) => {
  const dimensionKey = Object.keys(dataObj?.dimensions || {})[0];
  const measureKeys = Object.keys(dataObj?.measures || {});
  const xMeasureKey = Object.keys(dataObj?.measures)[0]; // x-axis measure
  const yMeasureKey = Object.keys(dataObj?.measures)[1]; // y-axis measure
  const rMeasureKey = Object.keys(dataObj?.measures)[2]; // radius measure

  if (!dataObj || !dimensionKey || measureKeys?.length < 3)
    return { datasets: [] };

  // Extract and flatten dimension values
  const dimensionValues = dataObj?.dimensions[dimensionKey]?.[0] || [];

  // Get the measure values for x, y, and radius
  const xMeasureValues = dataObj?.measures[measureKeys[0]]?.[0] || [];
  const yMeasureValues = dataObj?.measures[measureKeys[1]]?.[0] || [];
  const radiusMeasureValues = dataObj?.measures[measureKeys[2]]?.[0] || [];

  const getColorBorder = (index) => colorArrayShade4[index] || '#98A2B3';

  const dataset = dimensionValues?.map((dimValue, index) => ({
    label: dimValue, // Use dimension value as the label
    data: [
      {
        x: xMeasureValues[index], // x coordinate from first measure
        y: yMeasureValues[index], // y coordinate from second measure
        r:
          radiusMeasureValues[index] <= 0
            ? 0
            : Math.log(radiusMeasureValues[index]) + 1,
        originalR: radiusMeasureValues[index],
      },
    ],
    backgroundColor: getColor(selectedPalete, index),
    borderColor: getColorBorder(index),
    borderWidth: 1,
  }));

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          color: '#EDEDED',
        },
      },
      y: {
        grid: {
          color: '#EDEDED',
        },
      },
    },
    plugins: {
      legend: {
        display: displayLegend,
        position: 'top',
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            const dataPoint = tooltipItem.raw;
            return [
              `X:${xMeasureKey}: ${dataPoint?.x}`,
              `Y:${yMeasureKey}: ${dataPoint?.y}`,
              `Radius:${rMeasureKey}: ${dataPoint?.originalR}`,
            ];
          },
        },
      },
    },
  };

  return { formattedData: { datasets: dataset }, options: options };
};

export const formatDataSetForHeatmapChart = (dataObj) => {
  // Extract measure keys and dimension keys
  const measureKey = Object.keys(dataObj?.measures || {})[0];
  const dimensionKeys = Object.keys(dataObj?.dimensions || {});

  if (!dataObj || !measureKey || dimensionKeys?.length < 2) {
    return { data: [], keys: [] };
  }

  // Extract the first and second dimension key
  const dimensionKey1 = dimensionKeys[0];
  const dimensionKey2 = dimensionKeys[1];

  // Get the values for both dimensions
  const dimensionValues1 = dataObj?.dimensions[dimensionKey1]?.flat() || [];
  const dimensionValues2 = dataObj?.dimensions[dimensionKey2]?.flat() || [];

  // Get the measure values
  const measureValues = dataObj?.measures[measureKey]?.flat() || [];

  const uniqueD2Values = [...new Set(dimensionValues2)];

  const combinedArray = dimensionValues1?.map((item, index) => ({
    id: item,
    x: dimensionValues2[index],
    y: measureValues[index],
  }));

  const grouped = {};

  combinedArray?.forEach(({ id, x, y }) => {
    if (!grouped[id]) {
      grouped[id] = {};
    }
    grouped[id][x] = y;
    // Store m value for each d2 under the corresponding d1
  });

  const formattedData = Object.keys(grouped)?.map((key) => ({
    id: key,
    data: uniqueD2Values?.map((x) => ({
      x,
      y: grouped[key][x] || 0,
      // Use 0 if the d2 value is not present for this d1
    })),
  }));

  // Create the formatted data
  // const formattedData = dimensionValues1?.map((dimValue1, index1) => {
  //   const data = dimensionValues1?.map((dimValue1, index2) => {
  //     if(dimensionValues1[index2] === dimValue1) {
  //       return {
  //         x: dimensionValues2[index2],
  //         y: measureValues[index1] || 0, // Safeguard against undefined values
  //       };
  //     }
  //     return {
  //       x: dimensionValues2[index2],
  //       y: 0,
  //     }
  //   });

  //   return {
  //     id: dimValue1,
  //     data: data,
  //   };
  // });

  return {
    data: formattedData,
    keys: dimensionValues2,
  };
};

export const formatDataSetForGeoMapChart = (dataObj) => {
  const countryNames = Object.values(dataObj?.dimensions || {})?.flat(2);
  const measureValues = Object.values(dataObj?.measures || {})?.flat(2);
  const data = countryNames?.map((country, index) => ({
    id: country,
    value: measureValues[index],
  }));
  return data;
};

export const lineOptions = (displayLegend) => ({
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: displayLegend,
      labels: {
        boxWidth: 10,
        boxHeight: 10,
      },
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
        color: '#EDEDED',
      },
    },
    y: {
      grid: {
        color: '#EDEDED',
        lineWidth: 0.75,
      },
    },
  },
});

export const barOptions = (displayLegend) => ({
  indexAxis: 'y',
  elements: {
    bar: {
      borderWidth: 0,
    },
  },
  scales: {
    x: {
      grid: {
        display: true,
        color: '#EDEDED',
        lineWidth: 0.75,
      },
    },
    y: {
      grid: {
        display: false,
        color: '#C4C4C4',
      },
    },
  },
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: displayLegend,
      labels: {
        boxWidth: 10,
        boxHeight: 10,
      },
    },
    tooltip: {
      mode: 'index',
      enabled: true,
    },
  },
});

export const pieOptions = (displayLegend) => ({
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: displayLegend,
      position: 'top',
      labels: {
        boxWidth: 10,
        boxHeight: 10,
      },
    },
  },
});

export const stackedOptions = (displayLegend) => ({
  plugins: {
    legend: {
      display: displayLegend,
      position: 'top',
      labels: {
        boxWidth: 10,
        boxHeight: 10,
      },
    },
  },
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false,
        color: '#EDEDED',
      },
    },
    y: {
      stacked: true,
      grid: {
        color: '#EDEDED',
        lineWidth: 0.75,
      },
    },
  },
});

export const bubbleOptions = (displayLegend) => ({
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: displayLegend,
      position: 'top',
      labels: {
        boxWidth: 10,
        boxHeight: 10,
      },
    },
  },
});

export const scatterOptions = (displayLegend) => ({
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: displayLegend,
      position: 'top',
      labels: {
        boxWidth: 10,
        boxHeight: 10,
      },
    },
  },
});

export const doughnutOptions = (displayLegend) => ({
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: displayLegend,
      position: 'top',
      labels: {
        boxWidth: 10,
        boxHeight: 10,
      },
    },
  },
});

export const columnOptions = (displayLegend, chartRef) => ({
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: displayLegend,
      labels: {
        boxWidth: 10,
        boxHeight: 10,
      },
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
        color: '#EDEDED',
      },
    },
    y: {
      grid: {
        color: '#EDEDED',
        lineWidth: 0.75,
      },
    },
  },
});

export const areaOptions = (displayLegend) => ({
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: displayLegend,
      labels: {
        boxWidth: 10,
        boxHeight: 10,
      },
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
        color: '#EDEDED',
      },
    },
    y: {
      grid: {
        color: '#EDEDED',
        lineWidth: 0.75,
      },
    },
  },
});
