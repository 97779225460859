import Tabs from 'components/molecules/tabs/Tabs';
import { useGetLeaderWidgets } from 'query/leaderBoard/leaderBoard';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ProfileAwards from './profileAwards/ProfileAwards';

const tabs = [
  {
    id: 1,
    name: 'Gold',
  },
  {
    id: 2,
    name: 'Silver',
  },
  {
    id: 3,
    name: 'Bronze',
  },
];

const configTab = {
  Gold: 'rank1',
  Silver: 'rank2',
  Bronze: 'rank3',
};

function RecognitionChampionCard({ isClickable = false }) {
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState('Gold');
  const { data, isLoading } = useGetLeaderWidgets({
    datatype: 'week',
  });
  const handleTab = (event) => {
    setSelectedTab(event.name);
  };

  return (
    <div className=" bg-white border border-gray-200 shadow-md rounded-xl p-4">
      <div className="flex items-center justify-between">
        <h1 className="text-gray-900 text-sm font-semibold">
          Recognition Champion
        </h1>
        <Link
          to={'/redeem/recognition-champions/individual/lastWeek'}
          className="text-sm font-semibold bg-clip-text bg-gradient-to-br text-transparent from-primaryLeft to-primaryRight"
        >
          See all
        </Link>
      </div>
      <div className="mt-5">
        <Tabs tabs={tabs} selectedTab={selectedTab} handleTab={handleTab} />
        <div className="mt-3">
          {isLoading ? (
            <div className="flex flex-col gap-2">
              {Array(5)
                .fill()
                ?.map((__, ind) => {
                  return (
                    <div
                      key={ind}
                      className="w-full h-12 bg-gray-300 rounded-lg animate-pulse"
                    ></div>
                  );
                })}
            </div>
          ) : (
            <div className="max-h-[17.5rem] overflow-y-auto">
              {data?.[configTab[selectedTab]]?.length > 0 ? (
                <>
                  {data?.[configTab[selectedTab]]?.map((user, index) => (
                    <div
                      key={user?.user?.userId?.value}
                      className={`${isClickable ? 'cursor-pointer' : ''}`}
                      onClick={() => {
                        if (isClickable) {
                          console.log(user);
                          navigate(`/profile/${user?.user?.userId?.value}`);
                        }
                      }}
                    >
                      <ProfileAwards {...user} />
                    </div>
                  ))}
                </>
              ) : (
                <div className="text-sm font-normal text-gray-700">
                  No members to show.
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default RecognitionChampionCard;
