import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  employeeData: {},
  graphDataEmployee: {},
  tableDataEmployee: [],
  paymixData: {},
};
const compensationProfileSlice = createSlice({
  name: 'compensationEmployee',
  initialState,
  reducers: {
    setCompensationEmployeeData(state, action) {
      state.employeeData = action.payload;
      return state;
    },
    setCompensationGraphDataEmployee(state, action) {
      state.graphDataEmployee = action.payload;
      return state;
    },
    setCompensationTableDataEmployee(state, action) {
      state.tableDataEmployee = action.payload;
      return state;
    },
    setCompensationPaymixData(state, action) {
      state.paymixData = action.payload;
      return state;
    },
  },
});

export default compensationProfileSlice.reducer;

export const {
  setCompensationEmployeeData,
  setCompensationGraphDataEmployee,
  setCompensationTableDataEmployee,
  setCompensationPaymixData,
} = compensationProfileSlice.actions;
