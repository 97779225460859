import { ArrowSmallLeftIcon } from '@heroicons/react/24/outline';
import { addRole } from 'apiClient/rangeBuilder/rangeBuilder';
import { Input } from 'components/atoms/FormElements/input/Input';
import Tabs from 'components/molecules/tabs/Tabs';
import { setToastMessage } from 'pages/recognition/redemption/helper';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { tabs } from '../../../../config/rangeBuilderData';
import { Employees } from '../../finalRange/finalRangePopUp/Employees';
import { Details } from './Details';
import { VersionHistory } from './VersionHistory';

export const ViewDetails = ({
  setViewDetails,
  type,
  defaultTabSelected,
  setDefaultTabSelected,
  pivotedColumns,
  pivotedData,
  detailsTableColumns,
  detailsTableValues,
  versionHistory,
  addRolePayload,
  setAddRolePayload,
  benchmarkColumns,
  getCalculations,
}) => {
  const [selectedTab, setSelectedTab] = useState(
    defaultTabSelected ? defaultTabSelected : 'Details',
  );
  const [isEditable, setIsEditable] = useState(type === 'ADD' ? true : false);
  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  const handleAddRole = async () => {
    try {
      setLoading(true);
      await addRole(id, addRolePayload);
      await getCalculations();
      setToastMessage('Role created successfully!');
      setLoading(false);
      setViewDetails(false);
      setAddRolePayload({ pivotedJson: {}, calculatedJson: {} });
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  return (
    <div className="fixed z-[53] inset-0 bg-backdrop overflow-y-auto w-full">
      <div
        className={`absolute top-14 right-0 w-3/5 bg-white min-h-screen overflow-y-auto custom-scrollbar`}
      >
        <div className="flex items-center bg-white justify-between w-full gap-4 shadow-md border-b-2 p-2 px-4 fixed top-[54px]">
          <div className="flex flex-row items-center gap-3 justify-start">
            <div
              className="p-2 rounded-lg border-2 cursor-pointer"
              onClick={() => {
                setViewDetails(false);
                setDefaultTabSelected && setDefaultTabSelected('');
              }}
            >
              <ArrowSmallLeftIcon className="w-4 h-4" />
            </div>
            <div className=" flex items-center justify center text-xl font-semibold">
              Pay Range Details
            </div>
          </div>
        </div>
        <div className="p-6 mt-[54px] flex items-center justify-between w-full">
          {!isEditable && (
            <p className="font-semibold text-gray-900 w-full">{`${
              pivotedData?.[pivotedColumns?.indexOf('Pay Range Code')]
            } | ${
              pivotedData?.[pivotedColumns?.indexOf('Pay Range Title')]
            }`}</p>
          )}
          {isEditable && (
            <div className="flex items-center">
              <Input
                className="w-1/2"
                defaultValue={
                  type ? addRolePayload?.pivotedJson?.['Pay Range Code'] : ''
                }
                disabled
                onChange={(event) =>
                  setAddRolePayload({
                    ...addRolePayload,
                    pivotedJson: {
                      ...addRolePayload.pivotedJson,
                      'Pay Range Code': event.target.value,
                    },
                  })
                }
                placeholder="Enter job code"
              />
              <p>|</p>
              <Input
                className="w-1/2"
                defaultValue={
                  type ? addRolePayload?.pivotedJson?.['Pay Range Title'] : ''
                }
                disabled
                onChange={(event) =>
                  setAddRolePayload({
                    ...addRolePayload,
                    pivotedJson: {
                      ...addRolePayload.pivotedJson,
                      'Pay Range Title': event.target.value,
                    },
                  })
                }
                placeholder="Enter job title"
              />
            </div>
          )}
          {isEditable && selectedTab === 'Details' ? (
            <div className="flex itesm-center gap-5">
              <button
                className="text-sm font-semibold text-gray-600"
                onClick={() => setViewDetails(false)}
              >
                Cancel
              </button>
              <button
                className={`text-sm font-semibold text-transparent bg-gradient bg-clip-text ${
                  loading && 'cursor-not-allowed'
                }`}
                onClick={handleAddRole}
                disabled={loading}
              >
                {loading ? 'Saving...' : 'Save Changes'}
              </button>
            </div>
          ) : (
            selectedTab === 'Details' && (
              // <PencilSquareIcon
              //   onClick={() => setIsEditable(!isEditable)}
              //   className="w-6 h-6 text-gray-500 cursor-pointer"
              // />
              <></>
            )
          )}
        </div>
        <div className="space-y-5 px-6">
          <Tabs
            tabs={tabs}
            selectedTab={selectedTab}
            handleTab={(e) => setSelectedTab(e.tabLabel)}
          />
          {selectedTab === 'Details' && (
            <Details
              pivotedColumns={pivotedColumns}
              pivotedData={pivotedData}
              isEditable={isEditable}
              type={type}
              detailsTableColumns={detailsTableColumns}
              detailsTableValues={detailsTableValues}
              addRolePayload={addRolePayload}
              setAddRolePayload={setAddRolePayload}
              benchmarkColumns={benchmarkColumns}
            />
          )}
          {selectedTab === 'Employees' && <Employees />}
          {selectedTab === 'Version History' && (
            <VersionHistory versionHistory={versionHistory} />
          )}
        </div>
      </div>
    </div>
  );
};
