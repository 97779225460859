import React from 'react';

const UploadVideo = () => {
  return (
    <svg
      width="18"
      height="14"
      viewBox="0 0 18 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.3327 4.44265C17.3327 3.9378 17.3327 3.68538 17.2329 3.56849C17.1462 3.46707 17.0163 3.41325 16.8833 3.42371C16.7301 3.43577 16.5516 3.61426 16.1946 3.97124L13.166 6.99984L16.1946 10.0284C16.5516 10.3854 16.7301 10.5639 16.8833 10.576C17.0163 10.5864 17.1462 10.5326 17.2329 10.4312C17.3327 10.3143 17.3327 10.0619 17.3327 9.55703V4.44265Z"
        stroke="#475467"
        strokeWidth="1.33"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.666016 5.1665C0.666016 3.76637 0.666016 3.06631 0.938499 2.53153C1.17818 2.06112 1.56063 1.67867 2.03104 1.43899C2.56582 1.1665 3.26588 1.1665 4.66602 1.1665H9.16602C10.5661 1.1665 11.2662 1.1665 11.801 1.43899C12.2714 1.67867 12.6538 2.06112 12.8935 2.53153C13.166 3.06631 13.166 3.76637 13.166 5.1665V8.83317C13.166 10.2333 13.166 10.9334 12.8935 11.4681C12.6538 11.9386 12.2714 12.321 11.801 12.5607C11.2662 12.8332 10.5661 12.8332 9.16602 12.8332H4.66602C3.26588 12.8332 2.56582 12.8332 2.03104 12.5607C1.56063 12.321 1.17818 11.9386 0.938499 11.4681C0.666016 10.9334 0.666016 10.2333 0.666016 8.83317V5.1665Z"
        stroke="#475467"
        strokeWidth="1.33"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default UploadVideo;
