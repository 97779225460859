import * as React from 'react';
const OffCycleIncrementsIcon = ({
  isActive,
  activeColor,
  isHovered,
  hoveredColor,
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none">
    <g clipPath="url(#a)">
      <path
        stroke={isActive ? activeColor : isHovered ? hoveredColor : '#475467'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.67}
        d="M12.917 2.917v-1.25M16.199 3.8l.884-.884m.009 4.166h1.25m-.05 3.75a8.334 8.334 0 1 1-9.125-9.125M10 6.667h3.333V10m-.317-3.333a10.82 10.82 0 0 1-8.85 4.583c-.835 0-1.649-.095-2.43-.274"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default OffCycleIncrementsIcon;
