import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  createProgram,
  editProgram,
  // getProgramMetaData,
  getPrograms,
  getProgram,
  deleteProgram,
  duplicateProgram,
  getAward,
  editAward,
} from 'apiClient/programs/programs';

export const initialState = {
  selectedField: '',
  sidePanelType: 'PROGRAM',
  selectedFieldMetaData: {},
  ProgramMetaData: {},
  loader: [],
  programSubData: {},
  programSubLoader: [],
  programs: {
    awards: [],
    list: [],
    total: 0,
  },
};

// export const getProgramMetaDataThunk = createAsyncThunk("get/programMetaData", async () => {
//   const response = await getProgramMetaData();
//   return response;
// });

export const getProgramsThunk = createAsyncThunk(
  'get/programs',
  async (payload) => {
    const response = await getPrograms(payload);
    return response;
  },
);

export const createProgramTemplateThunk = createAsyncThunk(
  'create/program',
  async (payload) => {
    const response = await createProgram(payload);
    return response;
  },
);

export const editProgramTemplateThunk = createAsyncThunk(
  'edit/program',
  async (payload) => {
    const response = await editProgram(payload);
    return response;
  },
);

export const getProgramTemplateThunk = createAsyncThunk(
  'get/program',
  async (id) => {
    const response = await getProgram(id);
    return response;
  },
);
export const getAwardsByProgramTemplateThunk = createAsyncThunk(
  'get/awards',
  async (id) => {
    const response = await getAward(id);
    return response;
  },
);
export const editAwardTemplateThunk = createAsyncThunk(
  'edit/award',
  async (payload) => {
    const response = await editAward(payload);
    return response;
  },
);

export const deleteProgramTemplateThunk = createAsyncThunk(
  'delete/program',
  async (id) => {
    const response = await deleteProgram(id);
    return response;
  },
);

export const duplicateProgramTemplateThunk = createAsyncThunk(
  'duplicate/program',
  async (payload) => {
    const response = await duplicateProgram(payload);
    return response;
  },
);

const programsReducer = createSlice({
  name: 'programs',
  initialState,
  reducers: {
    setSelectedField(state, action) {
      state.selectedField = action.payload;
    },
    setSidePanelType(state, action) {
      state.sidePanelType = action.payload;
    },
    setProgramSubData(state, action) {
      const { id, data } = action.payload;
      state.programSubData[id] = data;
      return state;
    },
    setAddSubProgramId(state, action) {
      state.programSubLoader.push(action.payload);
      return state;
    },
    setRemoveSubProgramId(state, action) {
      state.programSubLoader = state.programSubLoader.filter(
        (ele) => ele !== action.payload,
      );
      return state;
    },
  },

  extraReducers: (builder) => {
    // builder.addCase(getProgramMetaDataThunk.pending, (state, action) => {
    //   state.loader = [...state.loader, "PROGRAM_METADATA_LOADING"];
    //   return state;
    // });
    // builder.addCase(getProgramMetaDataThunk.fulfilled, (state, action) => {
    //   state.loader = state.loader.filter((ele) => ele !== "PROGRAM_METADATA_LOADING");
    //   state.programMetaData = action.payload;
    //   return state;
    // });
    builder.addCase(getProgramsThunk.pending, (state, action) => {
      state.loader = [...state.loader, 'PROGRAMS_LOADING'];
      return state;
    });
    builder.addCase(getProgramsThunk.fulfilled, (state, action) => {
      state.loader = state.loader.filter((ele) => ele !== 'PROGRAMS_LOADING');
      state.programs.list = action.payload
        ? action.payload?.data?.response
        : [];
      state.programs.total = action.payload
        ? action.payload?.data?.totalCount
        : 0;
      return state;
    });
    builder.addCase(getProgramTemplateThunk.pending, (state, action) => {
      state.loader = [...state.loader, 'AWARDS_LOADING'];
      return state;
    });
    builder.addCase(
      getAwardsByProgramTemplateThunk.fulfilled,
      (state, action) => {
        state.loader = state.loader.filter((ele) => ele !== 'AWARDS_LOADING');
        state.programs.awards = action.payload;
        return state;
      },
    );

    builder.addCase(createProgramTemplateThunk.pending, (state) => {
      state.loader = [...state.loader, 'CREATE_PROGRAM'];
      return state;
    });

    builder.addCase(createProgramTemplateThunk.fulfilled, (state) => {
      state.loader = state.loader.filter((ele) => ele !== 'CREATE_PROGRAM');
      return state;
    });
    builder.addCase(editProgramTemplateThunk.pending, (state) => {
      state.loader = [...state.loader, 'EDIT_PROGRAM'];
      return state;
    });

    builder.addCase(editProgramTemplateThunk.fulfilled, (state) => {
      state.loader = state.loader.filter((ele) => ele !== 'EDIT_PROGRAM');
      return state;
    });
    builder.addCase(editAwardTemplateThunk.pending, (state) => {
      state.loader = [...state.loader, 'EDIT_AWARD'];
      return state;
    });

    builder.addCase(editAwardTemplateThunk.fulfilled, (state) => {
      state.loader = state.loader.filter((ele) => ele !== 'EDIT_AWARD');
      return state;
    });

    builder.addCase(deleteProgramTemplateThunk.pending, (state) => {
      state.loader = [...state.loader, 'DELETE_PROGRAM'];
      return state;
    });

    builder.addCase(deleteProgramTemplateThunk.fulfilled, (state) => {
      state.loader = state.loader.filter((ele) => ele !== 'DELETE_PROGRAM');
      return state;
    });

    builder.addCase(duplicateProgramTemplateThunk.pending, (state) => {
      state.loader = [...state.loader, 'DUPLICATE_PROGRAM'];
      return state;
    });

    builder.addCase(duplicateProgramTemplateThunk.fulfilled, (state) => {
      state.loader = state.loader.filter((ele) => ele !== 'DUPLICATE_PROGRAM');
      return state;
    });
  },
});

export const {
  setSelectedField,
  setSidePanelType,
  setProgramSubData,
  setAddSubProgramId,
  setRemoveSubProgramId,
} = programsReducer.actions;
export default programsReducer.reducer;
