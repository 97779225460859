import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Switch from 'components/atoms/switch/Switch';
import { useSelector } from 'react-redux';
import { templateBuilderSelector } from 'store/reducers/templateBuilderSlice';
import VideoField from 'components/atoms/videoField/VideoField';
import { onResponse } from 'utils/toastMessages';

const VideoPropertiesSidePanel = (props) => {
  const { setValue, watch } = useFormContext();
  const { selectedColumn } = useSelector(templateBuilderSelector);
  const { path, control, subPath } = props;
  const checkSize = (file) => {
    if (file) {
      const fileSize = file.size;

      const maxFileSize = 20 * 1024 * 1024;

      if (file?.type?.startsWith('video/') && fileSize > maxFileSize) {
        onResponse("Video size can't be larger than 20 MB");
        return false;
      } else return true;
    }
  };

  const handleVideoChange = (e, onChange) => {
    if (checkSize(e.target.files[0])) {
      onChange(e.target.files[0]);
      const oldValue = watch('media');
      setValue(`media`, {
        ...oldValue,
        [selectedColumn]: e.target.files[0],
      });
      const mediaId = watch(`${path}.mediaId`);
      if (mediaId) {
        const mediasToDelete = watch(`mediasToDelete`);
        setValue(`mediasToDelete`, [...mediasToDelete, mediaId]);
        setValue(`${subPath}.id`, '');
        setValue(`${subPath}.componentMappingId`, '');
      }
      setValue(`${path}.mediaId`, null);
    }
  };
  return (
    <div>
      <label
        htmlFor={`${path}.video.src`}
        className="text-sm font-medium leading-5 text-gray-700 my-1 mb-2.5"
      >
        Upload Video
      </label>
      <Controller
        name={`${path}.video.src`}
        control={control}
        rules={{ required: 'Video is required' }}
        render={({ field }) => {
          const { onChange, ...rest } = field;
          return (
            <VideoField
              htmlFor={`${path}.video.src`}
              onChange={(e) => {
                handleVideoChange(e, onChange);
              }}
              description={'MP4 & MOV only ( Max size up to 20MB )'}
              {...rest}
            />
          );
        }}
      />

      <div className="my-2 mt-4">
        <Controller
          control={control}
          name={`${path}.video.metaData.autoPlay`}
          render={({ field }) => {
            const { onChange, value, ...rest } = field;
            return (
              <Switch
                label={'Autoplay'}
                onChange={(e) => onChange(e.target.checked)}
                checked={value}
                {...rest}
              />
            );
          }}
        />
      </div>
      <div className="my-2 mt-2">
        <Controller
          control={control}
          name={`${path}.video.metaData.mute`}
          render={({ field }) => {
            const { onChange, value, ...rest } = field;
            return (
              <Switch
                label={'Mute'}
                onChange={(e) => onChange(e.target.checked)}
                checked={value}
                {...rest}
              />
            );
          }}
        />
      </div>
    </div>
  );
};

export default VideoPropertiesSidePanel;
