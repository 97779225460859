import React from 'react';
import Paragraph from '../../atoms/paragraph/Paragraph';
import { useFormContext } from 'react-hook-form';
import { htmlParser } from 'utils/jsUtils';

const ParagraphComponent = (props) => {
  const { data } = props;
  const { path } = data;
  const { watch } = useFormContext();
  const [justifyContent, content] = watch([
    `${path}.flex.justify`,
    `${path}.para.value`,
  ]);
  return (
    <div
      style={{ justifyContent: justifyContent ?? 'center' }}
      className={`w-full cursor-pointer h-full flex flex-col items-start p-4 overflow-y-auto break-words`}
    >
      {content ? (
        <div
          onClick={() => data.selectAtomsMolecules(data?.type)}
          className="parent-list-default flex flex-col break-words overflow-none break-all w-fit pb-4"
        >
          {' '}
          {htmlParser(content)}
        </div>
      ) : (
        <Paragraph data={data} content={content} />
      )}
    </div>
  );
};

export default ParagraphComponent;
