import { DEBOUNCE_SEARCH_TIME } from 'apiClient/api.constant';
import {
  fetchFilterFields,
  fetchFilteredVouchers,
} from 'apiClient/redemption/redemption';
import TickIcon from 'assets/svg/tickIcon';
import { priceFilters } from 'config/filters';
import { useDebounce } from 'hooks/useDebounce';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  redemptionSelector,
  setFilterFields,
  setSearchedValue,
  setSelectedFilters,
  setTotalProducts,
  setVouchers,
} from 'store/reducers/redemptionSlice';
import { FilterSkeletonLoader } from './FilterSkeletonLoader';
import { Search } from './Search';
import { filterInitialState } from './constants';
import MultiRangeSlider from './multiRangeSlider/MultiRangeSlider';

export const Filter = ({
  setVouchersList,
  setVouchersLoading,
  walletTypeSelected,
}) => {
  const [priceFilterSelected, setPriceFilterSelected] =
    useState('Select Range');
  // const [filterFields, setFilterFields] = useState();
  const dispatch = useDispatch();
  const [minVal, setMinVal] = useState(0);
  const [maxVal, setMaxVal] = useState(10000);
  const [loading, setLoading] = useState(false);
  const [filterReset, setFilterReset] = useState(true);
  const { selectedFilters, searchedValue, filterFields } =
    useSelector(redemptionSelector);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [filteredCategoriesFields, setFilteredCategoriesFields] = useState(
    filterFields?.categories,
  );
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [filteredBrandsFields, setSelectedBrandsFields] = useState(
    filterFields?.brand,
  );

  const debouncedValue = useDebounce(searchedValue, DEBOUNCE_SEARCH_TIME);

  useEffect(() => {
    (async () => {
      try {
        if (walletTypeSelected) {
          setLoading(true);
          // handleResetFilters();
          const response = await fetchFilterFields(walletTypeSelected.walletId);
          if (response) {
            dispatch(setFilterFields(response));
            setSelectedCategories([]);
            setSelectedBrands([]);
            setMaxVal(10000);
            setMinVal(0);
            setFilterReset(true);
            setPriceFilterSelected('Select Range');
            setLoading(false);
            selectedFilters?.walletId !== walletTypeSelected?.walletId &&
              dispatch(
                setSelectedFilters({
                  ...filterInitialState,
                  walletId: [walletTypeSelected.walletId],
                  brandsId: [],
                  categoriesId: [],
                }),
              );
          }
        }
      } catch (error) {
        setFilterFields([]);
        setLoading(false);
      }
    })();
  }, [walletTypeSelected]);

  useEffect(() => {
    const filteredFields = filterFields?.categories?.filter((category) =>
      selectedCategories.includes(category) ? false : true,
    );
    setFilteredCategoriesFields(filteredFields);
  }, [selectedCategories, filterFields]);

  useEffect(() => {
    const filteredFields = filterFields?.brand?.filter((brand) =>
      selectedBrands.includes(brand) ? false : true,
    );
    setSelectedBrandsFields(filteredFields);
  }, [selectedBrands, filterFields]);

  useEffect(() => {
    handleApply(selectedFilters, debouncedValue);
  }, [debouncedValue, selectedFilters]);

  const handlePriceFilter = (filter) => {
    setPriceFilterSelected(filter.name);
    if (!filter.range) {
      const filter = { ...selectedFilters };
      delete filter.minPoints;
      delete filter.maxPoints;
      dispatch(setSelectedFilters({ ...filter, fitToMyBudget: true }));
    } else {
      dispatch(
        setSelectedFilters({
          ...selectedFilters,
          fitToMyBudget: false,
          minPoints: minVal,
          maxPoints: maxVal,
        }),
      );
    }
  };

  const handleApply = async (filter, search) => {
    try {
      setVouchersLoading(true);
      if (selectedFilters.walletId?.length) {
        const response = await fetchFilteredVouchers(filter, search, 0);
        setVouchersLoading(false);

        dispatch(setVouchers({ vouchers: response.vouchers }));
        dispatch(setTotalProducts(response.totalProducts));
        setVouchersList(response.vouchers);
      }
      setVouchersLoading(false);
    } catch (error) {
      dispatch(setVouchers({ vouchers: [] }));
      dispatch(setTotalProducts(0));
      setVouchersList([]);
      setVouchersLoading(false);
    }
  };

  const handleResetFilters = () => {
    setFilterReset(true);
    setVouchersLoading(true);
    dispatch(setSearchedValue(''));
    if (selectedFilters.walletId) {
      dispatch(
        setSelectedFilters({
          ...filterInitialState,
          walletId: selectedFilters.walletId,
          brandsId: [],
          categoriesId: [],
        }),
      );
    }
    setSelectedCategories([]);
    setSelectedBrands([]);
    setPriceFilterSelected('Select Range');
    setMaxVal(10000);
    setMinVal(0);
    unCheckAll();
    setVouchersLoading(false);
  };

  const filteredArray = (array1, array2) =>
    array1?.filter((categoryId) => {
      return !array2?.includes(categoryId);
    });

  const handleCategoriesFilter = (event, filter) => {
    if (event.target.checked) {
      dispatch(
        setSelectedFilters({
          ...selectedFilters,
          categoriesId: selectedFilters.categoriesId?.length
            ? [...selectedFilters.categoriesId, ...filter.id]
            : [...filter.id],
        }),
      );
      setSelectedCategories([...selectedCategories, filter]);
    } else if (!event.target.checked) {
      dispatch(
        setSelectedFilters({
          ...selectedFilters,
          categoriesId: filteredArray(selectedFilters.categoriesId, filter.id),
        }),
      );
      filterFields?.categories.filter((category) =>
        selectedCategories.includes(category) ? false : true,
      );
      setSelectedCategories(
        selectedCategories.filter((category) => category.id !== filter.id),
      );
    }
  };

  const handleBrandsFilter = (event, filter) => {
    if (event.target.checked) {
      dispatch(
        setSelectedFilters({
          ...selectedFilters,
          brandsId: [...selectedFilters.brandsId, ...filter.id],
        }),
      );
      setSelectedBrands([...selectedBrands, filter]);
    } else if (!event.target.checked) {
      dispatch(
        setSelectedFilters({
          ...selectedFilters,
          brandsId: filteredArray(selectedFilters.brandsId, filter.id),
        }),
      );
      setSelectedBrands(
        selectedBrands.filter((brand) => brand.id !== filter.id),
      );
    }
  };

  const onFilterChange = (input) => {
    let { value, checked } = input.target;
  };

  const unCheckAll = () => {
    [...document.querySelectorAll('.filter-checkbox')].map((input) => {
      if (input.checked) {
        let fakeInput = {
          target: {
            value: input.value,
            checked: false,
          },
        };
        input.checked = !input.checked;
        onFilterChange(fakeInput);
      }
      return null;
    });
  };

  return (
    <div className="relative w-1/5 h-screen flex flex-col shadow border overflow-y-auto mb-[72px] overflow-y-auto custom-scrollbar">
      <div className="p-6">
        <div className="flex items-center justify-between">
          <h2 className="text-xl font-semibold text-black-400">Filters</h2>
          <p
            className="bg-gradient bg-clip-text pt-1 text-transparent font-bold text-sm cursor-pointer"
            onClick={handleResetFilters}
          >
            Reset Filters
          </p>
        </div>
        <div className="flex items-center justify-center my-6">
          <Search setVouchersLoading={setVouchersLoading} />
        </div>
        <div>
          <h3 className="font-semibold text-black-400 mb-4">Points</h3>
          <div>
            {priceFilters.map((filter, index) => {
              return (
                <div key={filter.id} className={index === 0 ? '' : 'mt-10'}>
                  <label className="text-sm flex items-center font-medium text-gray-700 relative cursor-pointer mb-3">
                    <div className="absolute left-[3px]">
                      <TickIcon />
                    </div>
                    <input
                      className="w-4 h-4 mr-2 focus:ring-gray-300 appearance-none border border-gray-300 checked:bg-gradient outline-none rounded-full"
                      type="checkbox"
                      checked={priceFilterSelected === filter.name}
                      onChange={() => handlePriceFilter(filter)}
                    />
                    {filter.name}
                  </label>
                  {filter.range && (
                    <div>
                      <MultiRangeSlider
                        min={minVal}
                        max={maxVal}
                        setMin={setMinVal}
                        setMax={setMaxVal}
                        disabled={
                          priceFilterSelected === 'Select Range' ? false : true
                        }
                        handleApply={handleApply}
                        filterReset={filterReset}
                        setFilterReset={setFilterReset}
                      />
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
        <div>
          <h3 className="text-base font-semibold text-black-400 mb-4 mt-6">
            Categories
          </h3>
          {loading ? (
            <>
              <FilterSkeletonLoader />
              <FilterSkeletonLoader />
              <FilterSkeletonLoader />
            </>
          ) : (
            <div className="space-y-3">
              {selectedCategories?.map((filter) => {
                return (
                  <label
                    className="text-sm flex items-center font-medium text-gray-700 relative cursor-pointer"
                    key={filter.id}
                  >
                    <div className="absolute left-[3px]">
                      <TickIcon />
                    </div>
                    <input
                      className="rounded w-4 h-4 mr-2 focus:ring-gray-300 appearance-none border border-gray-300 checked:bg-gradient outline-none filter-checkbox"
                      type="checkbox"
                      defaultChecked={true}
                      onChange={(event) =>
                        handleCategoriesFilter(event, filter)
                      }
                    />
                    {filter.name}
                  </label>
                );
              })}
              {filteredCategoriesFields?.map((filter) => {
                return (
                  <label
                    className="text-sm flex items-center font-medium text-gray-700 relative cursor-pointer"
                    key={filter.id}
                  >
                    <div className="absolute left-[3px]">
                      <TickIcon />
                    </div>
                    <input
                      className="rounded w-4 h-4 mr-2 focus:ring-gray-300 appearance-none border border-gray-300 checked:bg-gradient outline-none filter-checkbox"
                      type="checkbox"
                      onChange={(event) =>
                        handleCategoriesFilter(event, filter)
                      }
                    />
                    {filter.name}
                  </label>
                );
              })}
            </div>
          )}
        </div>
        {/* <div>
          <h3 className="font-semibold text-black-400 mb-4 mt-6">Brand</h3>
          {loading ? (
            <>
              <FilterSkeletonLoader />
              <FilterSkeletonLoader />
              <FilterSkeletonLoader />
            </>
          ) : (
            <div className="space-y-3">
              {selectedBrands?.map((filter) => {
                return (
                  <label
                    className="text-sm flex items-center font-medium text-gray-700 relative cursor-pointer"
                    key={filter.id}
                  >
                    <div className="absolute left-[3px]">
                      <TickIcon />
                    </div>
                    <input
                      className="rounded w-4 h-4 mr-2 focus:ring-gray-300 appearance-none border border-gray-300 checked:bg-gradient outline-none filter-checkbox"
                      type="checkbox"
                      defaultChecked={true}
                      onChange={(event) => handleBrandsFilter(event, filter)}
                    />
                    {filter.productProvider}
                  </label>
                );
              })}
              {filteredBrandsFields?.map((filter) => {
                return (
                  <label
                    className="text-sm flex items-center font-medium text-gray-700 relative cursor-pointer"
                    key={filter.id}
                  >
                    <div className="absolute left-[3px]">
                      <TickIcon />
                    </div>
                    <input
                      className="rounded w-4 h-4 mr-2 focus:ring-gray-300 appearance-none border border-gray-300 checked:bg-gradient outline-none filter-checkbox"
                      type="checkbox"
                      onChange={(event) => handleBrandsFilter(event, filter)}
                    />
                    {filter.productProvider}
                  </label>
                );
              })}
            </div>
          )}
        </div> */}
      </div>
    </div>
  );
};
