// eslint-disable-next-line @typescript-eslint/no-var-requires
// const formulajs = require('@formulajs/formulajs');

import * as formulajs from '@formulajs/formulajs';

const formulaMap = { ...formulajs };
const formulaList = Object.keys(formulaMap) as (keyof typeof formulaMap)[];

formulaList.forEach((formula) => {
    (global as any)[formula] = formulaMap[formula];
});

(global as any)['_VLOOKUP'] = (lookup_value: string, table_array: string[][]) => {
    const result = formulajs.VLOOKUP(lookup_value, table_array, 2, false);
    if (result instanceof Error) {
        return '';
    }
    return result;
};

(global as any)['_MATCH'] = (lookup_value: string, table_array: string[][]) => {
    const result = formulajs.MATCH(
        lookup_value,
        table_array.map((x) => x[1]),
        1
    );

    if (result instanceof Error) {
        return '';
    }
    return result;
};

(global as any)['_INDEX'] = (table_array: string[][], rowIndex: number, colIndex: number) => {
    return formulajs.INDEX(table_array, rowIndex, colIndex);
};

(global as any)['CONCAT_LOOKUP'] = (
    lookup_values: string[],
    lookup_array: string[][][],
    output_array: string[][]
) => {
    const joinedTableArray: string[][] = [];
    output_array.forEach((row) => {
        joinedTableArray.push(['', row[1]]);
    });
    lookup_array.forEach((tableArray) => {
        tableArray.forEach((row, rowIndex) => {
            joinedTableArray[rowIndex][0] += row[1];
        });
    });
    const result = formulajs.VLOOKUP(lookup_values.join(''), joinedTableArray, 2, false);
    if (result instanceof Error) {
        return '';
    }
    return result;
};
