import {
  commentAnnouncement,
  createAnnouncement,
  deleteAnnouncement,
  fetchAdminAnnouncements,
  fetchAnnouncement,
  fetchAnnouncements,
  getSocialDetails,
  likeAnnouncement,
  saveAnnouncement,
  shareAnnouncement,
  updateAnnouncement,
} from 'apiClient/announcement/annoucement';
import { deleteSocial } from 'apiClient/surface/surface';
import { useMutation, useQuery, useQueryClient } from 'react-query';

export const useCreateAnnouncement = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ['create/announcement'],
    async (formData) => createAnnouncement(formData, 'multipart/form-data'),
    {
      onSuccess: (res) => {
        if (res?.data) {
          queryClient.invalidateQueries(['get/announcements']);
          queryClient.invalidateQueries(['get/adminAnnouncement']);
        }
      },
    },
  );
};

export const useUpdateAnnouncement = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ['create/announcement'],
    async ({ id, createPayload }) =>
      updateAnnouncement(id, createPayload, 'multipart/form-data'),
    {
      onSuccess: (res) => {
        if (res?.data) {
          queryClient.invalidateQueries(['get/announcements']);
          queryClient.invalidateQueries(['get/adminAnnouncement']);
        }
      },
    },
  );
};

export const useDeleteAnnouncement = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ['delete/announcement'],
    async ({ id, attachments }) => deleteAnnouncement(id, attachments),
    {
      onSuccess: (res) => {
        if (res?.data) {
          queryClient.invalidateQueries(['get/announcements']);
          queryClient.invalidateQueries(['get/adminAnnouncement']);
        }
      },
    },
  );
};

export const useGetAllAnnouncement = (pageSize, pageIndex, payload) =>
  useQuery(['get/announcements', pageSize, pageIndex, payload], async () =>
    fetchAnnouncements(pageSize, pageIndex, payload),
  );

export const useGetAllAdminAnnouncement = (pageSize, currentPage, payload) =>
  useQuery(
    ['get/adminAnnouncement', pageSize, currentPage, payload],
    async () => fetchAdminAnnouncements(pageSize, currentPage, payload),
  );

export const useGetAnnouncement = (id) =>
  useQuery(['get/announcement', id], async () => fetchAnnouncement(id), {
    enabled: !!id,
  });

export const useAddComment = () => {
  // const queryClient = useQueryClient();
  return useMutation(['add/comment'], async (payload) =>
    commentAnnouncement(payload),
  );
};

export const useAddLike = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ['add/like'],
    async (payload) => likeAnnouncement(payload),
    {
      onSuccess: (res) => {
        if (res?.data) {
          queryClient.invalidateQueries(['get/announcements']);
          queryClient.invalidateQueries(['get/announcement']);
          queryClient.invalidateQueries(['get/adminAnnouncement']);
        }
      },
    },
  );
};

export const useGetSocialDetails = (payload) =>
  useQuery(['get/announcementComment', payload], async () =>
    getSocialDetails(payload),
  );

export const useSaveAnnouncement = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ['save/announcement'],
    async ({ id, action }) => {
      console.log(id, '===>');
      return saveAnnouncement(id, action);
    },
    {
      onSuccess: (res) => {
        if (res?.data) {
          queryClient.invalidateQueries(['get/announcements']);
          queryClient.invalidateQueries(['get/announcement']);
          queryClient.invalidateQueries(['get/adminAnnouncement']);
        }
      },
    },
  );
};

export const useShareAnnouncement = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ['share/announcement'],
    async (id) => shareAnnouncement(id),
    {
      onSuccess: (res) => {
        if (res?.data) {
          queryClient.invalidateQueries(['get/announcements']);
          queryClient.invalidateQueries(['get/announcement']);
        }
      },
    },
  );
};

export const useCommentDeleteAnnouncement = () =>
  useMutation(['delete/AnnouncementComment'], async (id) => deleteSocial(id));
