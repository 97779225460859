import VideoAtom from 'assets/svg/videoAtom';
import React from 'react';
import { useFormContext } from 'react-hook-form';

const VideosAtom = (props) => {
  const { errorPath, path } = props.data;
  const { register, watch } = useFormContext();
  const [videoUrl, mute, autoPlay] = watch([
    `${path}.video.src`,
    `${path}.video.metaData.mute`,
    `${path}.video.metaData.autoPlay`,
  ]);

  const getVideoSrc = () => {
    if (typeof videoUrl === 'string') {
      return videoUrl; // If videoUrl is already a string, use it directly
    } else if (videoUrl instanceof File || videoUrl instanceof Blob) {
      return URL.createObjectURL(videoUrl); // Create object URL if videoUrl is a File or Blob
    } else {
      return ''; // Handle other cases gracefully, e.g., videoUrl is null or undefined
    }
  };

  return videoUrl?.length !== 0 && videoUrl ? (
    <video
      autoPlay={autoPlay ?? false}
      muted={mute ?? false}
      controls={true}
      // src={
      //   typeof videoUrl === 'string' ? videoUrl : URL.createObjectURL(videoUrl)
      // }
      src={getVideoSrc()}
      className={`h-full w-full object-cover rounded-lg`}
    />
  ) : (
    <div className="relative h-full w-full flex justify-center items-center">
      <VideoAtom />
      <input
        className="hidden"
        {...register(`${path}.video.src`, {
          required: 'This Field is required',
        })}
      />
      {errorPath?.video?.src && (
        <p className="absolute left-0 bottom-0 bg-clip-text bg-gradient-to-br text-transparent from-primaryLeft to-primaryRight">
          {errorPath?.video?.src?.message + '*'}
        </p>
      )}
    </div>
  );
};

export default VideosAtom;
