import { MEDIA_LINK } from 'apiClient/api.constant';

export const imageURLCheck = (urlString) => {
  const imageLink =
    typeof urlString === 'object' ? urlString?.value : urlString;
  const stringFormat = imageLink?.startsWith('/') ? imageLink : `/${imageLink}`;
  const finalUrl =
    imageLink?.startsWith('https://') || imageLink?.startsWith('blob:')
      ? imageLink
      : `${MEDIA_LINK}${stringFormat}`;
  return finalUrl;
};
