import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/24/solid';
import { DEBOUNCE_SEARCH_TIME } from 'apiClient/api.constant';
import {
  deleteInternalRangeRole,
  downloadData,
  downloadTemplate,
  fetchBenchmarkColumns,
  fetchVersionHistory,
  getCalculationInternalRange,
  getInternalFilters,
  uploadInternalData,
} from 'apiClient/rangeBuilder/rangeBuilder';
import { NoRoleIcon } from 'assets/svg/noRoleIcon';
import { RefreshIcon } from 'assets/svg/refreshIcon';
import { RadioButton } from 'components/atoms/FormElements/input/Input';
import Button from 'components/atoms/button/Button';
import { Loader } from 'components/atoms/loader/Loader';
import ModalPopup from 'components/layout/modalPopup/ModalPopup';
import FileUploadDownloadPopup from 'components/molecules/FileUpload_Download_Popup/FileUploadDownloadPopup';
import { useDebounce } from 'hooks/useDebounce';
import { Search } from 'pages/benefits/manageBenefits/Search';
import { setToastMessage } from 'pages/recognition/redemption/helper';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { downloadDataToCsv } from 'utils/downloadDataToCsv';
import { ACTION_TYPE } from '../externalData/externalDataTable/ExternalDataTable';
import { CompensationTypeFilter } from './CompensationTypeFilter';
import { RefreshConfirmModal } from './RefreshConfirmModal';
import { ViewDetails } from './internalRangePopUp/InternalRangePopUp';
import { RangeTable } from './rangeTable/RangeTable';

export const InternalRange = ({ planName, status, timeout }) => {
  const [dataAvailable, setDataAvailable] = useState(true);
  const [selectedRowId, setSelectedRowId] = useState([]);
  const [searchedValue, setSearchedValue] = useState('');
  const [uploadCsv, setUploadCsv] = useState(false);
  const [refreshModalVisible, setRefreshModalVisible] = useState(false);
  const [addRowVisible, setAddRowVisible] = useState(false);
  const [isLakhsCompUnit, setIsLakhsCompUnit] = useState(false);
  const [benchmarkColumns, setBenchmarkColumns] = useState([]);
  const [internalCalculationsData, setInternalCalculationsData] = useState([]);
  const [filters, setFilters] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [calculatedData, setCalculatedData] = useState();
  const [pivotedColumns, setPivotedColumns] = useState([]);
  const [addRolePayload, setAddRolePayload] = useState({
    pivotedJson: {},
    calculatedJson: {},
  });
  const [loading, setLoading] = useState(false);
  const [selectedType, setSelectedType] = useState([]);
  const { id } = useParams();
  const [versionHistory, setVersionHistory] = useState();
  const [actionType, setActionType] = useState(null);
  const [isAddNewPopUp, setIsAddNewPopUp] = useState(false);
  const [fileData, setFileData] = useState({});

  const handleDownloadTemplate = async () => {
    const response = await downloadTemplate(id);
    return response;
    // downloadDataToCsv(response, `Template_${planName}`);
  };

  const handleDownloadData = async () => {
    const response = await downloadData(id);
    downloadDataToCsv(response, `Data_${planName}`);
  };

  const handleUploadData = async (formData) => {
    for (let [key, value] of formData.entries()) {
      formData.append('file', value);
      formData.delete('excel_file');
    }
    actionType === ACTION_TYPE.Replace &&
      formData.append('uploadType', 'refresh');
    await uploadInternalData(formData, id);
    setToastMessage('Data uploaded successfully!');
    setUploadCsv(false);
    setDataAvailable(true);
    setTimeout(() => (async () => await getCalculations())(), [1000 * 60 * 2]);
  };

  const getFilters = async () => {
    try {
      const response = await getInternalFilters(id);
      setFilters(response?.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (dataAvailable) {
      setActionType(ACTION_TYPE.Update);
    }
  }, []);

  useEffect(
    () =>
      setSelectedType(
        benchmarkColumns?.map((benchmark) => benchmark?.columnName),
      ),
    [benchmarkColumns],
  );

  const getVersionHistory = async (roleId) => {
    try {
      return await fetchVersionHistory(roleId);
    } catch (error) {
      console.error(error);
    }
  };

  const debouncedValue = useDebounce(searchedValue, DEBOUNCE_SEARCH_TIME);

  const deleteRole = async (roleIds) => {
    try {
      await deleteInternalRangeRole({ calculationIds: roleIds });
      setToastMessage('Role deleted successfully');
      await getCalculations();
    } catch (error) {
      console.error(error);
    }
  };

  const getCalculations = async (retry = true) => {
    try {
      setLoading(true);
      const response = await getCalculationInternalRange(
        id,
        debouncedValue,
        selectedFilters,
      );

      if (response) {
        if (!response?.data?.calculations?.length && status === 'InProgress') {
          if (retry) {
            if (timeout) {
              clearTimeout(timeout);
            }
            timeout = setTimeout(() => getCalculations(), 5000);
          }
        } else {
          setInternalCalculationsData(response?.data?.calculations);
          setDataAvailable(true);
          setLoading(false);
          if (timeout) {
            clearTimeout(timeout);
            timeout = null;
          }
        }
      }
    } catch (error) {
      console.error(error);
      setDataAvailable(false);
      setLoading(false);
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
    }
  };

  const getBenchmarkColumns = async () => {
    try {
      const response = await fetchBenchmarkColumns(id);
      setBenchmarkColumns(response?.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    (async () => {
      await getBenchmarkColumns();
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (status) {
        await getCalculations();
        await getFilters();

        return () => {
          if (timeout) {
            clearTimeout(timeout);
          }
        };
      }
    })();
  }, [id, debouncedValue, selectedFilters, status]);

  const renderUploadPopUp = (showWithoutPopup) => {
    return (
      <FileUploadDownloadPopup
        title={`${actionType ?? 'Upload'} Data`}
        downloadTemplateApi={handleDownloadTemplate}
        downloadFileTemplateName={`Data_${planName}`}
        uploadApi={handleUploadData}
        onUploadSuccess={() => {
          if (actionType === ACTION_TYPE.Update) {
            setIsAddNewPopUp(false);
          } else {
            setIsAddNewPopUp(false);
          }
        }}
        showWithoutPopup={showWithoutPopup}
        setPopupVisible={setUploadCsv}
        uploadButtonLabel={`${
          actionType === ACTION_TYPE.Update ? 'Update' : 'Replace'
        }`}
        uploadingButtonLabel={`${
          actionType === ACTION_TYPE.Update ? 'Updating' : 'Replacing'
        }`}
      />
    );
  };

  return (
    <>
      <div className={`flex items-center px-8 justify-end`}>
        {!dataAvailable && <div className="1/4"></div>}
        {/* {dataAvailable && (
            <div className="flex gap-3 items-center">
              <div
                className="flex w-[73px] h-8 border py-2.5 px-3.5 rounded-md gap-1 items-center justify-center cursor-pointer"
                onClick={() => {
                  setIsGraphView((preValue) => !preValue);
                }}
              >
                <div className="flex w-3.5 h-3.5">
                  <BarChartSquareIcon />
                </div>
                <p className="text-sm font-semibold font-figtree text-gray-700">
                  View
                </p>
              </div>
              <div className="flex w-[1px] h-5 bg-gray-200 border border-gray-200"></div>
            </div>
          )} */}
        <div className="flex items-center gap-5">
          {/* <p className="text-xs font-medium text-gray-600 flex items-center gap-2">
                Data valid from Jan 2022 to Dec 2022
                <PencilSquareIcon className="cursor-pointer w-4 h-4 text-gray-600" />
              </p> */}
          <Search
            searchedValue={searchedValue}
            setSearchedValue={setSearchedValue}
          />
          <div className="flex items-center gap-[10px]">
            <ArrowDownIcon
              className="w-5 cursor-pointer h-5 text-gray-600 border-b border-gray-600"
              onClick={handleDownloadData}
            />
            <ArrowUpIcon
              className="w-5 h-5 cursor-pointer text-gray-600 border-t border-gray-600"
              onClick={() => setUploadCsv(true)}
            />

            <RefreshIcon
              className="cursor-pointer"
              onClick={() => setRefreshModalVisible(true)}
            />
          </div>
          {uploadCsv && !dataAvailable && (
            <ModalPopup
              onClose={() => setUploadCsv(false)}
              title="Upload Data"
              body={
                <div className="p-4">
                  <FileUploadDownloadPopup
                    setPopupVisible={setUploadCsv}
                    downloadFileTemplateName={`Template_${planName}`}
                    title="Upload Data"
                    // description="lorem ipsum"
                    uploadApi={(formData) => handleUploadData(formData)}
                    downloadTemplateApi={() => handleDownloadTemplate()}
                  />
                </div>
              }
            />
          )}
          {uploadCsv && actionType && dataAvailable && (
            <div>
              <ModalPopup
                title={'Upload Internal Data'}
                body={
                  <div className="flex flex-col w-full">
                    <div className="flex w-full gap-2">
                      <div className="flex w-full gap-2">
                        <RadioButton
                          id={'update'}
                          value={actionType === ACTION_TYPE.Update}
                          checked={actionType === ACTION_TYPE.Update}
                          onChange={() => {
                            setActionType(
                              actionType === ACTION_TYPE.Replace
                                ? ACTION_TYPE.Update
                                : ACTION_TYPE.Replace,
                            );
                          }}
                          label="Update Matchings"
                        />
                      </div>
                      <div className="flex w-full gap-2">
                        <RadioButton
                          id={'replace'}
                          value={actionType === ACTION_TYPE.Replace}
                          checked={actionType === ACTION_TYPE.Replace}
                          label="Replace Matchings"
                          onChange={() => {
                            setActionType(
                              actionType === ACTION_TYPE.Replace
                                ? ACTION_TYPE.Update
                                : ACTION_TYPE.Replace,
                            );
                          }}
                        />
                      </div>
                    </div>
                    {renderUploadPopUp(true)}
                  </div>
                }
                height={'416px'}
                onClose={() => setUploadCsv(false)}
                width={'w-[400px]'}
              />
            </div>
          )}
        </div>
      </div>
      {selectedFilters?.length > 0 && (
        <div className="px-8 gap-[10px] flex items-center">
          {selectedFilters?.map((filter) => {
            return (
              <div
                onClick={() =>
                  setSelectedFilters(
                    selectedFilters?.filter(
                      (item) => item?.key !== filter?.key,
                    ),
                  )
                }
                key={filter?.key}
                className="w-fit px-2 py-1 text-xs font-medium gradient-border-and-text before:rounded-2xl before:border font-semibold flex items-center leading-[18px]"
              >
                {filter?.value}
                <span className="text-xs ml-2 cursor-pointer">X</span>
              </div>
            );
          })}
          <p
            onClick={() => setSelectedFilters([])}
            className="text-xs font-semibold text-gray-600 cursor-pointer"
          >
            Clear All
          </p>
        </div>
      )}
      {dataAvailable && (
        <div className="border-y bg-gray-50 border-gray-200 px-8 py-1 flex items-center justify-between">
          <div className="flex items-center">
            <CompensationTypeFilter
              typeList={benchmarkColumns?.map((benchmark) => ({
                ...benchmark,
                value: benchmark?.columnName,
                name: benchmark?.columnName,
              }))}
              selectedType={selectedType}
              setSelectedType={setSelectedType}
            />
            {/* <p className="text-gray-300 px-4">|</p> */}
            {/* <ReferenceTypeFilter
                typeList={[{ name: 'Min' }, { name: 'Mid' }, { name: 'Max' }]}
              /> */}
          </div>
          {/* <CompensationUnitFilter
              isLakhsCompUnit={isLakhsCompUnit}
              setIsLakhsCompUnit={setIsLakhsCompUnit}
            /> */}
        </div>
      )}
      <div className="px-8">
        {loading || !status ? (
          <div className="flex items-center justify-center mt-40">
            <Loader />
          </div>
        ) : !dataAvailable ? (
          <div className="flex flex-col items-center justify-center h-full space-y-5 my-14">
            <NoRoleIcon />
            <div className="space-y-1 flex flex-col items-center justify-between">
              <p className="text-sm font-semibold text-gray-900">
                There are no roles to show
              </p>
              <p className="text-xs text-gray-600">
                If there are roles and you have access, you will the see the
                roles here
              </p>
            </div>
            <div className="flex itesm-center justify-between gap-5">
              <Button
                variant="outlined"
                className="text-sm font-semibold"
                onClick={() => setUploadCsv(true)}
              >
                Upload Data
              </Button>
              <Button
                variant="filled"
                className="text-sm font-semibold"
                onClick={() => setRefreshModalVisible(true)}
              >
                Refresh Data
              </Button>
            </div>
          </div>
        ) : (
          <div>
            <RangeTable
              setAddRowVisible={setAddRowVisible}
              addRowVisible={addRowVisible}
              selectedRowId={selectedRowId}
              setSelectedRowId={setSelectedRowId}
              benchmarkColumns={benchmarkColumns}
              internalCalculationsData={internalCalculationsData}
              handleDelete={(roleIds) => deleteRole(roleIds)}
              dropDownFilters={filters}
              selectedFilters={selectedFilters}
              setSelectedFilters={setSelectedFilters}
              getVersionHistory={getVersionHistory}
              calculatedData={calculatedData}
              setCalculatedData={setCalculatedData}
              pivotedColumns={pivotedColumns}
              setPivotedColumns={setPivotedColumns}
              selectedType={selectedType}
              versionHistory={versionHistory}
              setVersionHistory={setVersionHistory}
            />
          </div>
        )}
      </div>
      {/* <div className="px-6 py-3 fixed bottom-0">
          <Button
            className="flex items-center gap-2 text-gray-700 text-sm font-semibold"
            onClick={() => setAddRowVisible(true)}
          >
            <PlusIcon className="text-gray-700 w-5 h-5" />
            Add Row
          </Button>
        </div> */}
      {addRowVisible && (
        <ViewDetails
          type="ADD"
          setViewDetails={setAddRowVisible}
          pivotedColumns={pivotedColumns}
          setAddRolePayload={setAddRolePayload}
          addRolePayload={addRolePayload}
          detailsTableColumns={[
            'Position',
            ...Object.values(
              calculatedData?.[benchmarkColumns?.[0]?.id] || [],
            ).map((value) => Object.keys(value)?.[0] || {}),
          ]}
          benchmarkColumns={benchmarkColumns}
          getCalculations={getCalculations}
          detailsTableValues={benchmarkColumns?.map((column) => {
            return [
              column?.columnName,
              ...(Object?.values(calculatedData?.[column?.id]) || {}).map(
                (value) => Object?.values(value || {})?.[0] || '',
              ),
            ];
          })}
          versionHistory={versionHistory}
        />
      )}
      {refreshModalVisible && (
        <RefreshConfirmModal setModalVisible={setRefreshModalVisible} />
      )}
    </>
  );
};
