const TotalRewardsPhilosophyIcon = ({
  isActive,
  activeColor,
  isHovered,
  hoveredColor,
  width,
  height,
}) => (
  <svg
    width={width ? width : '20'}
    height={height ? height : '20'}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.6667 15.8333V13.3333H5.83334C4.45263 13.3333 3.33334 14.4526 3.33334 15.8333M7.33334 18.3333H14C14.9334 18.3333 15.4001 18.3333 15.7567 18.1516C16.0703 17.9918 16.3252 17.7369 16.485 17.4233C16.6667 17.0668 16.6667 16.6 16.6667 15.6666V4.33329C16.6667 3.39987 16.6667 2.93316 16.485 2.57664C16.3252 2.26304 16.0703 2.00807 15.7567 1.84828C15.4001 1.66663 14.9334 1.66663 14 1.66663H7.33334C5.93321 1.66663 5.23315 1.66663 4.69837 1.93911C4.22796 2.17879 3.84551 2.56124 3.60583 3.03165C3.33334 3.56643 3.33334 4.26649 3.33334 5.66663V14.3333C3.33334 15.7334 3.33334 16.4335 3.60583 16.9683C3.84551 17.4387 4.22796 17.8211 4.69837 18.0608C5.23315 18.3333 5.93321 18.3333 7.33334 18.3333Z"
      stroke={isActive ? activeColor : isHovered ? hoveredColor : '#475467'}
      strokeWidth="1.67"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default TotalRewardsPhilosophyIcon;
