import {
  deleteExternalRangeData,
  downloadExternalData,
  getAllExternalRanges,
  getExternalDataFilters,
  getPreviewData,
  getTemplate,
  getUpdateTemplate,
  getVersionHistory,
} from 'apiClient/rangeBuilder/rangeBuilder';
import { useMutation, useQuery } from 'react-query';

export const useGetAllExternalRanges = (planId, pageSize, pageIndex, query) =>
  useQuery(
    ['get/allExternalRanges', planId, pageSize, pageIndex, query],
    async () => {
      const res = await getAllExternalRanges(
        planId,
        pageSize,
        pageIndex,
        query,
      );
      return res?.data;
    },
  );

export const useGetPreviewData = (
  rangeFormDataId,
  pageSize,
  pageIndex,
  selectedFilters,
  query,
) =>
  useQuery(['get/previewData', rangeFormDataId], async () => {
    const res = await getPreviewData(
      rangeFormDataId,
      pageSize,
      pageIndex,
      selectedFilters,
      query,
    );
    return res;
  });

export const useDownloadExternalData = (rangeFormDataId) =>
  useQuery(
    ['download/externalData', rangeFormDataId],
    async () => {
      const res = await downloadExternalData(rangeFormDataId);
      return res;
    },
    {
      enabled: false,
    },
  );

export const useGetTemplate = (planId) =>
  useMutation(['get/Template', planId], async () => {
    const res = await getTemplate(planId);
    return res;
  });

export const useGetUpdateTemplate = () =>
  useMutation(['get/updateTemplate'], async (rangeFormTypeId) => {
    const res = await getUpdateTemplate(rangeFormTypeId);
    return res;
  });

export const useGetExternalDataFilters = (rangeFormTypeId) =>
  useQuery(['get/allExternalRanges', rangeFormTypeId], async () => {
    const res = await getExternalDataFilters(rangeFormTypeId);
    return res?.data;
  });

export const useGetVersionHistory = (rangeFormTypeId, rangeDataId) =>
  useQuery(['get/versionHistory', rangeFormTypeId, rangeDataId], async () => {
    const res = await getVersionHistory(rangeFormTypeId, rangeDataId);
    return res?.data;
  });

export const useDeleteExternalDataRow = () =>
  useMutation(['delete/externalData'], async (data) => {
    const res = await deleteExternalRangeData(
      data?.rangeFormTypeId,
      data?.payload,
    );
    return res?.data;
  });
