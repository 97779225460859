const EventsLogo = (props) => {
  const { isActive, activeColor } = props;
  return (
    <div>
      <svg
        width={props?.width ? props?.width : '18'}
        height={props?.height ? props?.height : '20'}
        viewBox="0 0 18 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.5 8.33334H1.5M12.3333 1.66667V5.00001M5.66667 1.66667V5.00001M6.5 13.3333L8.16667 15L11.9167 11.25M5.5 18.3333H12.5C13.9001 18.3333 14.6002 18.3333 15.135 18.0609C15.6054 17.8212 15.9878 17.4387 16.2275 16.9683C16.5 16.4335 16.5 15.7335 16.5 14.3333V7.33334C16.5 5.93321 16.5 5.23314 16.2275 4.69836C15.9878 4.22796 15.6054 3.84551 15.135 3.60582C14.6002 3.33334 13.9001 3.33334 12.5 3.33334H5.5C4.09987 3.33334 3.3998 3.33334 2.86502 3.60582C2.39462 3.84551 2.01217 4.22796 1.77248 4.69836C1.5 5.23314 1.5 5.93321 1.5 7.33334V14.3333C1.5 15.7335 1.5 16.4335 1.77248 16.9683C2.01217 17.4387 2.39462 17.8212 2.86502 18.0609C3.3998 18.3333 4.09987 18.3333 5.5 18.3333Z"
          stroke={isActive ? activeColor : '#475467'}
          strokeWidth="1.67"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default EventsLogo;
