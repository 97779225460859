import { boolean, z } from 'zod';
import { ZAtomicFormulaInput } from '../types';
import { createServiceWithZod } from '../utils/createService';
import { formulaConstants } from '../utils/formulaConstants';

const generateVariableReplacerFunction = (
    dependencyValueMap: z.TypeOf<typeof ZAtomicFormulaInput>['dependencyValueMap'],
    formulaTypesMap: { [key in 'null' | 'string' | 'number' | 'date' | 'boolean']: boolean }
) => {
    // Note: Do not remove subString
    return (subString: string, variable: string): string => {
        if (dependencyValueMap[variable] === undefined) {
            formulaTypesMap.null = true;
            return 'null';
        }

        const value = dependencyValueMap[variable];
        if (value === null) {
            formulaTypesMap.null = true;
            return 'null';
        }
        if (value instanceof Date) {
            formulaTypesMap.date = true;
            return `(new Date("${value}"))`;
        }
        if (typeof value === 'string') {
            formulaTypesMap.string = true;
            return `"${value.replace(/"/g, '\\"')}"`;
        }
        if (typeof value === 'number') {
            formulaTypesMap.number = true;
            return `${value}`;
        }

        if (typeof value === 'boolean') {
            formulaTypesMap.boolean = true;
            return `(!!(${value}))`;
        }

        return `${JSON.stringify(value)}`;
    };
};

export const atomicFormula = createServiceWithZod(ZAtomicFormulaInput, z.any(), async (input) => {
    try {
        const formulaTypesMap = {
            null: false,
            string: false,
            date: false,
            number: false,
            boolean: true
        };

        const replaceVariableWithValueCallback = generateVariableReplacerFunction(
            input.dependencyValueMap,
            formulaTypesMap
        );

        const formula = input.formulaString.replace(
            formulaConstants.VARIABLE_REGEX,
            replaceVariableWithValueCallback
        );

        if (formulaTypesMap.null) {
            return null;
        }
        const formulaFunction = new Function('return ' + formula);
        const result = formulaFunction();

        if (
            result != null &&
            typeof result !== 'string' &&
            typeof result !== 'number' &&
            typeof result !== 'boolean' &&
            !(result instanceof Date)
        ) {
            if (typeof result === 'object' || Array.isArray(result)) return JSON.stringify(result);
            return null;
        }
        return result;
    } catch (error) {
        return null;
    } finally {
    }
});
