import UserNameAvatar from 'components/atoms/userNameAvatar/UserNameAvatar';
import { useState } from 'react';
import { imageURLCheck } from 'utils/ImageUrlCheck';

//Need to pass 2 props mentioned below

//image={imageURLCheck(user?.userProfileData?.avatar?.value)}
//userName={user?.userProfileData?.fullName?.value}
//imageSize - provide value in px format 32 = h-8, h-10 = 40

const Avatar = ({ image, userName, imageSize = 40, ...props }) => {
  var parentClassName = props.className;
  const [errorInAvatar, setErrorInAvatar] = useState(false);
  const handleOnLoad = () => setErrorInAvatar(false);

  const handleOnError = () => setErrorInAvatar(true);
  return (
    <div className={`w-[${imageSize}px] h-[${imageSize}px]`}>
      {!errorInAvatar && (
        <img
          {...props}
          src={imageURLCheck(image)}
          alt="user"
          style={{ width: imageSize + 'px', height: imageSize + 'px' }}
          className={`border-2 rounded-full !object-cover ${parentClassName} `}
          onError={handleOnError}
          onLoad={handleOnLoad}
        />
      )}
      {errorInAvatar && (
        <UserNameAvatar imageSize={imageSize} userName={userName} {...props} />
      )}
    </div>
  );
};

export default Avatar;
