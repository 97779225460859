import React from 'react';
import PropTypes from 'prop-types';

const Badge = ({
  backgroundColor = 'gray',
  textColor = 'black',
  text,
  className = '',
}) => {
  return (
    <span
      style={{ color: textColor, backgroundColor: backgroundColor }}
      className={`bg-[#F6F6F6] text-[${textColor}]  font-medium px-2.5 py-0.5 rounded-full ${className} text-xs`}
    >
      {text}
    </span>
  );
};

Badge.propTypes = {
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string,
  text: PropTypes.string,
};

export default Badge;
