const PlusIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={13} height={14} fill="none">
    <path
      fill="#ffffff"
      fillRule="evenodd"
      d="M7.783 6.13V.913h-1.74V6.13H.827v1.74h5.218v5.217h1.739V7.87H13V6.13H7.783Z"
      clipRule="evenodd"
    />
  </svg>
);
export default PlusIcon;
