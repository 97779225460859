import {
  ArrowLeftCircleIcon,
  ArrowRightCircleIcon,
  MinusIcon,
  PlusIcon,
} from '@heroicons/react/24/solid';
import { getBlobMedia } from 'apiClient/apiCalls';
import LoadingCircle from 'components/molecules/loadingCircle/LoadingCircle';
import React, { useEffect, useState } from 'react';
import { Document, Page } from 'react-pdf';

function PdfViewer({ apiEndPoint, pdfFile, showAllPage, defaultScale, url }) {
  const [pdfData, setPdfData] = useState();
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [numPages, setNumPages] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [scale, setScale] = useState(defaultScale || 1);
  const fetchPdfContent = async () => {
    try {
      const response = await getBlobMedia(`${apiEndPoint}`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      setPdfData(url);
    } catch (error) {
      console.error('Error fetching PDF:', error);
    }
  };
  useEffect(() => {
    if (apiEndPoint) {
      setIsLoading(true);
      fetchPdfContent()
        .catch((error) => setErrorMessage(error))
        .finally(() => {
          setIsLoading(false);
          setErrorMessage('');
        });
    }
  }, []);
  useEffect(() => {
    if (url) {
      setPdfData(url);
    }
  }, [url]);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  const handleZoomIn = () => {
    setScale((prevScale) => prevScale + 0.1);
  };

  const handleZoomOut = () => {
    setScale((prevScale) => prevScale - 0.1);
  };

  return (
    <>
      {pdfFile ? (
        <>
          <div className="relative">
            <div className="flex gap-4 my-1 sticky top-0 z-50 bg-gray-300 p-3 rounded-md">
              <span
                className="rounded-full bg-gray-600 p-1 "
                onClick={handleZoomIn}
              >
                <PlusIcon className="h-3 w-3 text-white" />
              </span>
              <span
                className="rounded-full bg-gray-600 p-1"
                onClick={handleZoomOut}
              >
                <MinusIcon className="h-3 w-3 text-white" />
              </span>
            </div>
            {showAllPage ? (
              Array.apply(null, ...Array(numPages))
                .map((x, i) => i + 1)
                .map((page, index) => (
                  <Document
                    key={index}
                    file={pdfFile}
                    onLoadSuccess={onDocumentLoadSuccess}
                    className="h-full w-full"
                  >
                    <Page
                      pageNumber={page}
                      className="h-full w-full"
                      renderAnnotationLayer={false}
                      renderTextLayer={false}
                      scale={scale}
                    />
                  </Document>
                ))
            ) : (
              <React.Fragment>
                <div className="bg-gray-200 py-2 px-2 rounded-md flex justify-between my-2">
                  <p className="text-sm text-gray-600 font-semibold">
                    Page {pageNumber} of {numPages}
                  </p>
                  <div className="flex gap-2">
                    <span>
                      <ArrowLeftCircleIcon
                        className="h-5 w-5 text-gray-600 cursor-pointer"
                        onClick={() => {
                          if (pageNumber !== 1) {
                            setPageNumber((preState) => preState - 1);
                          }
                        }}
                      />
                    </span>
                    <span>
                      <ArrowRightCircleIcon
                        className="h-5 w-5 text-gray-600 cursor-pointer"
                        onClick={() => {
                          if (pageNumber !== numPages) {
                            setPageNumber((preState) => preState + 1);
                          }
                        }}
                      />
                    </span>
                  </div>
                </div>
                <Document file={pdfFile} onLoadSuccess={onDocumentLoadSuccess}>
                  <Page
                    pageNumber={pageNumber}
                    renderAnnotationLayer={false}
                    renderTextLayer={false}
                    scale={scale}
                  />
                </Document>
              </React.Fragment>
            )}
          </div>{' '}
        </>
      ) : (
        <div className="relative">
          {isLoading ? (
            <div className="w-full h-full flex items-center justify-center">
              <LoadingCircle />
            </div>
          ) : (
            pdfData && (
              <iframe
                src={pdfData}
                title="pdf"
                width="100%"
                className="rounded-lg"
                height="600px"
                scrolling="no"
                // sandbox="allow-same-origin allow-scripts allow-popups"
              ></iframe>
            )
          )}{' '}
          {errorMessage && <p>{errorMessage}</p>}
        </div>
      )}
    </>
  );
}

export default PdfViewer;
