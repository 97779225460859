/*
Components being reused are:-
    1. CheckBoxProgram
    2. Button
    3. Input
    4. DatePicker
    5. Toggle
    6. Table
    7. TableHeader
    8. Avatar
    9. Tabs
    10. PlusIcon
    11. VerticalEclipse
    12. ChipsLabels
    13. Select
    
    
*/

import { ArrowLeftIcon } from '@heroicons/react/24/solid';
import { fetchPlanById } from 'apiClient/rangeBuilder/rangeBuilder';
import Button from 'components/atoms/button/Button';
import Tabs from 'components/molecules/tabs/Tabs';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { mainTabs } from '../../config/rangeBuilderData';
import Employees from './employees/Employees';
import ExternalData from './externalData/ExternalData';
import { FinalRange } from './finalRange/FinalRange';
import { InternalRange } from './internalRange/InternalRange';
import { JobMatching } from './jobMatching/JobMatching';

export const RangeBuilder = () => {
  const [selectedTab, setSelectedTab] = useState('Internal Data');
  const [planDetails, setPlanDetails] = useState();
  const navigate = useNavigate();
  const { id } = useParams();
  let timeout = null;

  const getPlanById = async () => {
    try {
      const response = await fetchPlanById(id);
      setPlanDetails(response?.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    (async () => await getPlanById())();
  }, []);

  return (
    <div className="bg-white h-full">
      <div className="px-8 py-6 flex items-center justify-between">
        <div className="flex items-center gap-4">
          <div
            onClick={() => navigate(-1)}
            className="border border-gray-300 w-10 h-10 flex items-center justify-center rounded-lg shadow-sm cursor-pointer"
          >
            <ArrowLeftIcon className="w-5 h-5 text-gray-700" />
          </div>
          <div className="">
            <p className="font-semibold text-gray-900">{planDetails?.title}</p>
            <p className="text-gray-500 text-xs">{`ID ${planDetails?.rangeId} | Last modified by ${planDetails?.userDetails?.fullName?.value}`}</p>
          </div>
        </div>
        <Button
          variant="outlined"
          onClick={() => navigate(`/range-builder/plan/update/${id}`)}
        >
          Edit
        </Button>
      </div>
      <div className="space-y-4">
        <div className="px-8">
          <Tabs
            tabs={mainTabs}
            selectedTab={selectedTab}
            handleTab={(e) => setSelectedTab(e.tabLabel)}
            className="text-sm font-semibold"
          />
        </div>
        {selectedTab === 'External Data' && (
          <ExternalData planName={planDetails?.title} />
        )}
        {selectedTab === 'Internal Data' && (
          <InternalRange
            planName={planDetails?.title}
            status={planDetails?.status}
            timeout={timeout}
          />
        )}
        {selectedTab === 'Job Matching' && (
          <JobMatching planName={planDetails?.title} />
        )}
        {selectedTab === 'Final Range' && (
          <FinalRange planName={planDetails?.title} />
        )}
        {selectedTab === 'Employees' && <Employees />}
      </div>
    </div>
  );
};
