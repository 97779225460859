import { del, get, patch, post } from 'apiClient/apiCalls';
import * as CONSTANT from '../api.constant';

export const createAnnouncement = async (formData, type) => {
  const response = await post(
    `${CONSTANT.API_URL}/${CONSTANT.API_SURFACE_V2}/announce/create-announcement`,
    formData,
    type,
  );
  return response;
};

export const fetchAnnouncements = async (pageSize, currentPage, payload) => {
  const URL = `${CONSTANT.API_URL}/${CONSTANT.API_SURFACE_V2}/announce/get-all-announcements?pageSize=${pageSize}&pageIndex=${currentPage}`;
  const response = await post(URL, payload);
  return response;
};

export const fetchAdminAnnouncements = async (
  pageSize,
  currentPage,
  payload,
) => {
  const URL = `${CONSTANT.API_URL}/${CONSTANT.API_SURFACE_V2}/announce/get-all-admin-announcements?pageSize=${pageSize}&pageIndex=${currentPage}`;
  const response = await post(URL, payload);
  return response;
};

export const fetchAnnouncement = async (id) => {
  const resposne = await get(
    `${CONSTANT.API_URL}/${CONSTANT.API_SURFACE_V2}/announce/get-announcement/${id}`,
  );
  return resposne.data;
};

export const updateAnnouncement = async (id, payload, type) => {
  const URL = `${CONSTANT.API_URL}/${CONSTANT.API_SURFACE_V2}/announce/update-announcement/${id}`;
  const response = await patch(URL, payload, type);
  return response;
};

export const deleteAnnouncement = async (id, attachments) => {
  const response = await del(
    `${CONSTANT.API_URL}/${CONSTANT.API_SURFACE_V2}/announce/delete-announcement/${id}`,
    {
      attachments,
    },
  );
  return response;
};

export const likeAnnouncement = async (payload) => {
  const response = await post(
    `${CONSTANT.API_URL}/${CONSTANT.API_SURFACE_V2}/social/like-unlike`,
    payload,
  );
  return response;
};

export const commentAnnouncement = async (payload) => {
  const response = await post(
    `${CONSTANT.API_URL}/${CONSTANT.API_SURFACE_V2}/social/create-comment`,
    payload,
  );
  return response;
};

export const getSocialDetails = async (payload) => {
  const response = await post(
    `${CONSTANT.API_URL}/${CONSTANT.API_SURFACE_V2}/social/get-all-socials-by-source`,
    payload,
  );
  return response.data;
};

export const shareAnnouncement = async (id) => {
  const response = await post(
    `${CONSTANT.API_URL}/${CONSTANT.API_SURFACE_V2}/post/share-post/${id}?source=announcement`,
    {},
  );
  return response;
};

export const saveAnnouncement = async (id, action) => {
  const response = await post(
    `${CONSTANT.API_URL}/${CONSTANT.API_SURFACE_V2}/social/save`,
    {
      sourceId: id,
      sourceType: 'announcement',
      action,
    },
  );
  return response;
};
