export const extractYearPercents = (str) => {
  // Ensure the string is valid and contains the expected format
  if (!str || !str?.includes('-'))
    return {
      firstYearPercent: 0,
      secondYearPercent: 0,
      thirdYearPercent: 0,
      fourthYearPercent: 0,
    };
  const [
    firstYearPercent,
    secondYearPercent,
    thirdYearPercent,
    fourthYearPercent,
  ] = str.split(' ')[0].split('-').map(Number);
  return {
    firstYearPercent: isNaN(firstYearPercent) ? 0 : firstYearPercent,
    secondYearPercent: isNaN(secondYearPercent) ? 0 : secondYearPercent,
    thirdYearPercent: isNaN(thirdYearPercent) ? 0 : thirdYearPercent,
    fourthYearPercent: isNaN(fourthYearPercent) ? 0 : fourthYearPercent,
  };
};
export const calculateValue = (value, percentage) => {
  const parsedValue = parseFloat(value);
  const parsedPercentage = parseFloat(percentage);
  return parsedValue + parsedValue * (parsedPercentage / 100);
};
export const createGradient = () => {
  const ctx = document.createElement('canvas').getContext('2d');
  const gradient = ctx.createLinearGradient(0, 0, 0, 400);
  gradient.addColorStop(
    0,
    getComputedStyle(document.documentElement).getPropertyValue(
      '--primaryLeft',
    ),
  );
  gradient.addColorStop(
    1,
    getComputedStyle(document.documentElement).getPropertyValue(
      '--primaryRight',
    ),
  );
  return gradient;
};
export const calculateGrowth = (initialValue, growthRate, iterations = 4) => {
  let value = initialValue;
  const growthArray = [];

  for (let i = 0; i < iterations; i++) {
    value = calculateValue(value, growthRate);
    growthArray.push(value);
  }

  return growthArray;
};

export const calculateUnvestedAmounts = (growthArray, unvestedPercents) => {
  return growthArray?.map(
    (growth, index) => growth * (unvestedPercents[index] / 100),
  );
};

export const calculateGrantAmounts = (item, growthArray, index) => {
  const calculatedValues = growthArray?.map((growth, i) => {
    if (i < index) return 0;
    const percent = item[`year${i + 1}Percent`] / 100;
    return growth * percent;
  });

  return calculatedValues;
};

export const sumArrays = (arr1, arr2) => {
  return arr1?.map((value, idx) => value + (arr2[idx] || 0));
};
export const processCompensationProposal = (
  compensationProposal,
  offerDetailsData,
  setVestingSchedule,
) => {
  let totalValue = 0;
  const newCompData = [];

  Object.entries(compensationProposal)?.forEach(([key, item]) => {
    if (typeof item === 'object' && item?.total) {
      const headerData = offerDetailsData?.compSideNavDetails[key];
      const foundObject =
        offerDetailsData?.compSideNavDetails?.compFields?.find(
          (field) => field?.codeId === key,
        );
      if (foundObject?.name === 'LTI') {
        setVestingSchedule(item?.vestingSchedule);
      }
      totalValue += parseInt(item?.total);
      newCompData.push({ ...item, ...foundObject, header: headerData });
    }
  });

  return { totalValue, newCompData };
};
