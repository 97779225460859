import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import PropertySelect from 'components/atoms/linkSelect/PropertySelect';
import ImageInput from 'components/molecules/imageInput/ImageInput';
import { useSelector } from 'react-redux';
import { templateBuilderSelector } from 'store/reducers/templateBuilderSlice';
import { links, objectFit } from 'config/templateBuilderConfig';
import { handleImageChange } from 'utils/hanldeImageSize';

const ImagePropertiesSidePanel = (props) => {
  const { subPath, path, control } = props;
  const { setValue, watch } = useFormContext();
  const { selectedColumn } = useSelector(templateBuilderSelector);

  const handleImage = (e, onChange) => {
    if (handleImageChange(e.target.files[0], 20)) {
      onChange(e.target.files[0]);
      const oldValue = watch('media');
      setValue(`media`, {
        ...oldValue,
        [selectedColumn]: e.target.files[0],
      });
      const mediaId = watch(`${path}.mediaId`);
      if (mediaId) {
        const mediasToDelete = watch(`mediasToDelete`);
        setValue(`mediasToDelete`, [...mediasToDelete, mediaId]);
        setValue(`${subPath}.id`, '');
        setValue(`${subPath}.componentMappingId`, '');
      }
      setValue(`${path}.mediaId`, null);
    }
  };

  return (
    <div>
      <label
        htmlFor={`${path}.image.src`}
        className="text-sm font-medium leading-5 text-gray-700 my-1 mb-2.5"
      >
        Upload Image
      </label>
      <Controller
        name={`${path}.image.src`}
        control={control}
        rules={{ required: true, message: 'Please enter this field' }}
        render={({ field }) => {
          const { onChange, ...rest } = field;
          return (
            <ImageInput
              objectFit={'cover'}
              height={'40'}
              htmlFor={`${path}.image.src`}
              accept=".png,.jpg,.jpeg,.svg,.gif,.avif,.webp"
              onChange={(e) => {
                handleImage(e, onChange);
              }}
              description={'PNG,SVG,JPEG & JPG only ( Max size up to 20MB )'}
              {...rest}
            />
          );
        }}
      />
      <div className="my-2">
        <Controller
          control={control}
          name={`${path}.image.metaData.link`}
          render={({ field }) => {
            const { onChange } = field;
            return (
              <PropertySelect
                options={links}
                creatable={true}
                label={'Link'}
                onChange={(e) => onChange(e?.value)}
              />
            );
          }}
        />
      </div>
      <div className="my-2">
        <Controller
          control={control}
          name={`${path}.image.metaData.objectFit`}
          render={({ field }) => {
            const { onChange } = field;
            return (
              <PropertySelect
                options={objectFit}
                label={'Object Fit'}
                onChange={(e) => onChange(e?.value)}
              />
            );
          }}
        />
      </div>
    </div>
  );
};

export default ImagePropertiesSidePanel;
