import NoCommunityIcon from 'assets/svg/noCommunityIcon';
import Button from 'components/atoms/button/Button';
import React from 'react';

const BenchmarkingTo = () => {
  return (
    <div className="flex flex-col px-8 py-14 justify-center items-center gap-5">
      <NoCommunityIcon />
      <div className="flex flex-col justify-center text-center gap-1">
        <p className="flex w-full font-figtree text-xs font-semibold justify-center text-center">
          There are no benchmarks to show
        </p>
        <p className="flex w-[262px] font-figtree text-xs font-normal text-gray-600 text-center">
          If there are benchmarks and you have access, you will the see the data
          here
        </p>
      </div>
      {/* <Button
        variant={'outlined'}
        onClick={() => {
          //   setIsAddNewPopUp(true);
        }}
      >
        Add Benchmarks
      </Button> */}
    </div>
  );
};

export default BenchmarkingTo;
