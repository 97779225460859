import { z } from 'zod';

export const ZAtomicFormulaInput = z.object({
    dependencyValueMap: z.record(z.string(), z.any()).default({}),
    formulaString: z.string()
});

export const ZBatchFormulaInput = z.object({
    formulaMap: z.record(
        z.string(),
        z.object({
            formulaString: z.string()
        })
    ),
    dependencyValueMap: z.record(z.string(), z.any()).default({})
});

export type TBatchFormulaMap = z.TypeOf<typeof ZBatchFormulaInput>['formulaMap'];
export type TDependencyValueMap = z.TypeOf<typeof ZBatchFormulaInput>['dependencyValueMap'];
export type TDependencyArrayMap = {
    [key: string]: string[];
};
