import { useEffect, useRef, useState } from 'react';
import DashboardContent from './dashboardContent/DashboardContent';
import ViewDashboardHeader from './viewDashboardHeader/ViewDashboardHeader';
import PlusIcon from 'assets/svg/plusIcon';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import {
  useGetFilterColumnValues,
  useGetPage,
} from 'query/dashboard/ManageDashboard';
import InfinityLoader from 'assets/svg/InfinityLoader/InfinityLoader';

export default function ViewDashboard({
  dashboardSettings,
  pageList,
  hideEditButton,
  hideGoToReportButton,
  hideDownloadButton,
  hideFilterButton,
  module,
}) {
  const dashboardId = dashboardSettings?.id;
  const [selectedPageId, setSelectedPageId] = useState(null);
  const [isPagePanelOpen, setPagePanelOpen] = useState(false);
  const [isDownload, setIsDownload] = useState(false);
  const [isRefresh, setIsRefresh] = useState(false);
  const [completeRefresh, setCompleteRefresh] = useState(false);
  const dashboardRef = useRef();
  const {
    data: pageData,
    isLoading,
    isError,
    refetch: refetchPageData,
  } = useGetPage(dashboardId, selectedPageId);
  const [filters, setFilters] = useState({});

  const {
    data: filtersList,
    isLoading: filterLoading,
    isError: filterErro,
  } = useGetFilterColumnValues(dashboardId);

  useEffect(() => {
    setSelectedPageId(pageList[0]?.id);
  }, [pageList]);

  useEffect(() => {
    if (selectedPageId) {
      refetchPageData();
    }
  }, [selectedPageId]);

  return (
    <div
      className={`flex flex-col gap-4 ${
        isRefresh ? 'pointer-events-none cursor-not-allowed' : ''
      }`}
    >
      <ViewDashboardHeader
        filtersList={filtersList}
        filters={filters}
        setFilters={setFilters}
        hideEditButton={hideEditButton}
        hideGoToReportButton={hideGoToReportButton}
        selectedPageId={selectedPageId}
        hideDownloadButton={hideDownloadButton}
        dashboardRef={dashboardRef}
        hideFilterButton={hideFilterButton}
        setIsDownload={setIsDownload}
        isDownload={isDownload}
        isRefresh={isRefresh}
        setIsRefresh={setIsRefresh}
        dashboardSettings={dashboardSettings}
        module={module}
        setCompleteRefresh={setCompleteRefresh}
        completeRefresh={completeRefresh}
      />
      <div className="relative p-3">
        <div className=" flex absolute top-0 z-50 h-full left-30">
          {isPagePanelOpen && (
            <div className="bg-white shadow-lg w-72 p-6 ">
              <p className="text-base font-semibold pb-4 flex justify-between w-full items-center">
                Pages{' '}
              </p>
              <div className=" p-4  pl-0 h-[87%] overflow-y-auto">
                <div className="flex flex-col gap-6 justify-between">
                  {pageList?.map((item, index) => (
                    <div
                      key={index}
                      className={
                        selectedPageId === item?.id
                          ? 'border-b-2 border-black cursor-pointer flex justify-between'
                          : 'flex justify-between'
                      }
                    >
                      <div onClick={() => setSelectedPageId(item?.id)}>
                        <span className="cursor-pointer">{item?.name}</span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
          <div
            onClick={() => setPagePanelOpen(!isPagePanelOpen)}
            className="bg-white right-0 justify-center border border-l-0 h-8 w-8 shadow flex items-center border-gray-300 rounded-r-md cursor-pointer relative top-4"
          >
            {isPagePanelOpen ? (
              <ChevronLeftIcon className="h-6 w-6" />
            ) : (
              <ChevronRightIcon className="w-5 h-5" />
            )}
          </div>
        </div>
        <div className="h-full">
          {isLoading ? (
            <div className="h-96">
              <InfinityLoader />
            </div>
          ) : (
            <DashboardContent
              dashboardSettings={dashboardSettings}
              layout={pageData}
              filters={filters}
              dashboardRef={dashboardRef}
              isDownload={isDownload}
              completeRefresh={completeRefresh}
            />
          )}
        </div>
      </div>
    </div>
  );
}
