import {
  ChevronLeftIcon,
  ChevronRightIcon,
  PlusIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import {
  refreshFile,
  updateChart,
} from 'apiClient/dashboardBuilder/DashboardBuilder';
import CircularLoaderSmall from 'assets/svg/CircularLoaderSmall';
import EditIcon from 'assets/svg/editIcon';
import GradientCloudDownloadIcon from 'assets/svg/GradientCloudDownloadIcon';
import InfinityLoader from 'assets/svg/InfinityLoader/InfinityLoader';
import Button from 'components/atoms/button/Button';
import { Input } from 'components/atoms/FormElements/input/Input';
import Select from 'components/atoms/FormElements/select/Select';
import ModalDialogue from 'components/atoms/modalDialogue/ModalDialogue';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import {
  useCreatePage,
  useGetPage,
  useUpdateDashboardData,
  useUpdatePage,
} from 'query/dashboard/ManageDashboard';
import { useEffect, useRef, useState } from 'react';
import { colorArrayShade5 } from 'utils/colorsArray';
import { onResponse } from 'utils/toastMessages';
import Dashboard from '../Dashboard';
import ConfigureDashboard from './Configuration/ConfigureDashboard';
import DashboardCanvas from './DashboardCanvas';

export default function CreateDashboard({
  module,
  containerHeight,
  dashboardSettings,
  setDashboardSettings,
  refetchDashboardData,
  getFilterFields,
  pageList,
  mode,
  reportId,
}) {
  const [configFor, setConfigFor] = useState('default');
  const [dashboardCards, setDashboardCards] = useState({});
  const [isSidePanelOpen, setSidePanelOpen] = useState(false);
  const [isPagePanelOpen, setPagePanelOpen] = useState(false);
  const [editingPageId, setEditingPageId] = useState(null);
  const [selectedPage, setSelectedPage] = useState(null); // for selecting a page with page id
  const [selectedPageId, setSelectedPageId] = useState(null); // for update page api use
  const [showLegend, setShowLegend] = useState({});
  const [isDownload, setIsDownload] = useState(false);
  const [isDeletePage, setIsDeletePage] = useState({});
  const [isPreviewDashboard, setIsPreviewDashboard] = useState(false);
  const [isRefresh, setIsRefresh] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const chartRefs = useRef({});
  const dashboardRef = useRef();
  const dashboardId = dashboardSettings?.id;
  const pgIndex = dashboardCards?.index;
  const [selectedPalete, setSelectedPalate] = useState([]);

  //   useEffect(() => {
  //     const selectedColorPalatefromLocalStorage = JSON.parse(
  //       localStorage.getItem('selectedColorPalate'),
  //     );
  //     setSelectedPalate(selectedColorPalatefromLocalStorage);
  //   }, [localStorage.getItem('selectedColorPalate')]);
  useEffect(() => {
    if (pageList?.length > 0 && selectedPage === null) {
      setSelectedPage(pageList[0]?.id);
    }
  }, [pageList]);

  const maxPageIndex = Math.max(
    ...(pageList?.map((page) => page?.index) ?? []),
    0,
  );

  const { mutateAsync: addNewPage, isLoading: addPageLoader } = useCreatePage({
    index: `${maxPageIndex + 1}`,
    name: `Page ${maxPageIndex + 2}`,
    id: dashboardSettings?.id,
  });

  const { mutateAsync: updateDashboard } = useUpdateDashboardData(
    dashboardSettings?.id,
  );

  const { mutateAsync: updatePage } = useUpdatePage(selectedPageId);

  const {
    data: pageData,
    isLoading,
    isError,
    refetch: refetchPageData,
  } = useGetPage(dashboardId, selectedPage);

  const getFormattedData = (data) => {
    return {
      ...data,
      charts: data?.charts?.map((item) => ({
        i: item?.data?.i,
        x: item?.data?.x,
        y: item?.data?.y,
        w: item?.data?.w,
        h: item?.data?.h,
        minH: item?.data?.minH,
        minW: item?.data?.minW,
        cardData: {
          id: item?.id,
          name: item?.name,
          description: item?.description,
          type: item?.data?.type,
          measures: item?.measures,
          dimensions: item?.dimensions,
          filters: item?.filters,
          sorting: item?.sorting,
          aggregation: item?.aggregation,
          datasets: item?.data?.datasets,
          multipleMeasures: item?.data?.multipleMeasures,
          multipleDimensions: item?.data?.multipleDimensions,
        },
      })),
    };
  };

  useEffect(() => {
    const handleChange = async (pageData) => {
      await setDashboardCards(getFormattedData(pageData));
      await setSelectedPageId(pageData?.id);
    };
    if (pageData) {
      refetchPageData();
      handleChange(pageData);
    }
  }, [selectedPage, pageData]);

  const deletePage = async (pageId) => {
    setIsDeletePage({ ...isDeletePage, [pageId]: true });
    try {
      await updateDashboard({ deletedPages: [pageId] });
      refetchDashboardData();
      onResponse('Page deleted successfully');
    } catch (err) {
      onResponse(err?.message);
      setIsDeletePage({ ...isDeletePage, [pageId]: false });
    }
    setIsDeletePage({ ...isDeletePage, [pageId]: false });
  };

  // Function to handle drag end event
  // Function to handle drag end event
  const onDragEnd = (result) => {
    //console.log(result);
    // Check if the drag was completed successfully
    // if (!result.destination) {
    //   return;
    // }
    // const { source, destination } = result;
    // // Ensure source and destination are valid
    // if (!destination) {
    //   return;
    // }
    // // If dropped outside the valid drop area
    // if (
    //   source.droppableId === destination.droppableId &&
    //   source.index === destination.index
    // ) {
    //   return;
    // }
    // // Reorder the dashboardCards array based on drag and drop result
    // const items = Array.from(dashboardCards);
    // const [reorderedItem] = items.splice(source.index, 1);
    // items.splice(destination.index, 0, reorderedItem);
    // // Append a new item after the drag operation
    // setDashboardCards((prevCards) => {
    //   const newCard = {
    //     i: `chart${prevCards.length + 1}`, // Generate a unique id
    //     x: prevCards.length * 6, // Adjust x position based on the length of the array
    //     y: Infinity,
    //     w: 6,
    //     h: 4,
    //   };
    //   return [...items, newCard];
    // });
  };

  const handlePageNameChange = async (e, id) => {
    setSelectedPage(id);
    const newPageName = e.target.value;
    const tempData = {
      ...dashboardCards,
      name: newPageName,
      id: dashboardId,
      index: String(pgIndex),
    };
    setDashboardCards(tempData);
  };

  const handlePageNameSave = async (id) => {
    try {
      await updatePage(dashboardCards);
      refetchDashboardData();
      setSelectedPage(null);
      setDashboardCards({});
      setEditingPageId(null);
      setSelectedPageId(null);
    } catch (error) {
      console.error(error);
    }
  };

  const addPage = async () => {
    try {
      await addNewPage();
      refetchDashboardData();
    } catch (error) {
      console.error(error);
    }
  };

  const handleSave = async () => {
    const charts = dashboardCards?.charts ?? [];
    setIsSaving(true);
    try {
      // Create an array of promises for updating each chart
      const updatePromises = charts?.map((chart) => {
        const payload = {
          dashboardId: dashboardId,
          data: {
            i: chart?.i,
            x: chart?.x,
            y: chart?.y,
            w: chart?.w,
            h: chart?.h,
            minH: chart?.minH,
            minW: chart?.minW,
            type: chart?.cardData?.type,
            datasets: chart?.cardData?.datasets,
          },
        };
        return updateChart(payload, chart?.cardData?.id); // Call the updateChart API
      });
      await Promise.all(updatePromises);
      refetchDashboardData();
      onResponse('Dashboard saved successfully');
    } catch (error) {
      setIsSaving(false);
      onResponse(error?.message);
    }
    setIsSaving(false);
  };

  const handleDownload = async () => {
    const input = dashboardRef.current;
    await setIsDownload(true);
    html2canvas(input, {
      scale: 2,
      useCORS: true,
      height: input.scrollHeight,
      width: input.scrollWidth,
      windowHeight: input.scrollHeight,
      windowWidth: input.scrollWidth,
      backgroundColor: input.style?.backgroundColor || 'white',
    }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const canvasWidth = canvas.width;
      const canvasHeight = canvas.height + 10;
      const pdf = new jsPDF('l', 'mm', [canvasWidth, canvasHeight]);

      pdf.addImage(imgData, 'PNG', 0, 0, canvasWidth, canvasHeight);

      pdf.save('dashboard.pdf');
      setIsDownload(false);
    });
  };

  const handleRefresh = async () => {
    setIsRefresh(true);
    try {
      if (dashboardId) {
        await refreshFile(dashboardId, module);
      }
    } catch (error) {
      setIsRefresh(false);
      onResponse(error?.message);
    }
    setIsRefresh(false);
  };

  return (
    <div
      className={`relative ${
        isRefresh ? 'pointer-events-none cursor-not-allowed' : ''
      }`}
    >
      {/* <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}> */}
      <div className={`flex w-full relative h-[90vh] overflow-y-auto`}>
        <div className=" flex absolute top-0 z-50 h-full left-30">
          {isPagePanelOpen && (
            <div className="bg-white shadow-lg w-72 p-6 ">
              <p className="text-base font-semibold pb-4 flex justify-between w-full items-center">
                Pages{' '}
                {addPageLoader ? (
                  <div className="-mr-2">
                    <CircularLoaderSmall />
                  </div>
                ) : (
                  <PlusIcon
                    className="w-5 h-5 cursor-pointer"
                    onClick={addPage}
                  />
                )}
              </p>
              <div className=" p-4  pl-0 h-[87%] overflow-y-auto">
                <div className="flex flex-col gap-6 justify-between">
                  {pageList?.map((item, index) => (
                    <div
                      key={index}
                      className={
                        selectedPage === item?.id
                          ? 'border-b-2 border-black cursor-pointer flex justify-between'
                          : 'flex justify-between'
                      }
                    >
                      <div onClick={() => setSelectedPage(item?.id)}>
                        {editingPageId === item?.id ? (
                          <Input
                            value={dashboardCards?.name}
                            onChange={(e) => handlePageNameChange(e, item?.id)}
                            onBlur={() => handlePageNameSave(item?.id)}
                            autoFocus
                            className="border-none p-0 -mx-3"
                          />
                        ) : (
                          <span className="cursor-pointer">{item?.name}</span>
                        )}
                      </div>
                      <div className="flex justify-between">
                        <EditIcon
                          onClick={() => {
                            setEditingPageId(item?.id);
                            setSelectedPage(item?.id);
                            setDashboardCards(item);
                          }}
                        />
                        {isDeletePage[item?.id] ? (
                          <div className="-mr-3">
                            <CircularLoaderSmall />
                          </div>
                        ) : (
                          <div onClick={() => deletePage(item?.id)}>
                            <TrashIcon className="w-5 h-5 cursor-pointer text-gray-500" />
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
          <div
            onClick={() => setPagePanelOpen(!isPagePanelOpen)}
            className="bg-white right-0 justify-center border border-l-0 h-8 w-8 shadow flex items-center border-gray-300 rounded-r-md cursor-pointer relative top-4"
          >
            {isPagePanelOpen ? (
              <ChevronLeftIcon className="h-6 w-6" />
            ) : (
              <ChevronRightIcon className="w-5 h-5" />
            )}
          </div>
        </div>
        <div className="flex justify-between w-full">
          <div className="flex flex-col w-full">
            <div className="flex justify-end w-full px-10 py-2 gap-2 items-center">
              <div className="flex justify-end flex-wrap overflow-x-auto w-96 gap-2 hidden">
                {selectedPalete.map((e, index) => (
                  <div
                    key={index}
                    className="w-8 h-6"
                    style={{ background: e }}
                  ></div>
                ))}
              </div>
              <div className="w-40 hidden">
                <Select
                  options={Object.keys(colorArrayShade5).map((item) => ({
                    label: item,
                    value: item,
                  }))}
                  defaultValue={{
                    label: 'OfficeClassic6',
                    value: 'OfficeClassic6',
                  }}
                  onChange={(e) => {
                    const selectedValue = colorArrayShade5[e?.value];
                    setSelectedPalate(selectedValue);
                  }}
                />
              </div>
              <div className="flex justify-between items-center gap-2">
                {isRefresh ? (
                  <Button
                    variant="outlined"
                    className="flex gap-2 text-sm font-semibold"
                  >
                    Refreshing...
                  </Button>
                ) : (
                  <Button
                    onClick={handleRefresh}
                    variant="outlined"
                    className="flex gap-2 text-sm font-semibold"
                  >
                    Refresh
                  </Button>
                )}
                {isDownload ? (
                  <Button
                    variant="outlined"
                    className="flex gap-2 text-sm font-semibold"
                    icon={<GradientCloudDownloadIcon />}
                  >
                    Downloading...
                  </Button>
                ) : (
                  <Button
                    variant="outlined"
                    className="flex gap-2 text-sm font-semibold"
                    icon={<GradientCloudDownloadIcon />}
                    onClick={handleDownload}
                  >
                    Download
                  </Button>
                )}
                {isSaving ? (
                  <Button variant="filled" className="text-sm font-semibold">
                    Saving...
                  </Button>
                ) : (
                  <Button
                    variant="filled"
                    className="text-sm font-semibold"
                    onClick={handleSave}
                  >
                    Save
                  </Button>
                )}
              </div>
            </div>
            <div className="overflow-y-auto h-full">
              {isLoading ? (
                <InfinityLoader />
              ) : (
                <DashboardCanvas
                  selectedPalete={selectedPalete}
                  configFor={configFor}
                  setConfigFor={setConfigFor}
                  selectedPage={selectedPage}
                  layout={dashboardCards || {}}
                  setSidePanelOpen={setSidePanelOpen}
                  setLayout={setDashboardCards}
                  dashboardSettings={dashboardSettings || {}}
                  refetchPageData={refetchPageData}
                  showLegend={showLegend}
                  chartRefs={chartRefs}
                  dashboardRef={dashboardRef}
                  isDownload={isDownload}
                />
              )}
            </div>
          </div>
          <div className={`flex absolute top-0 h-full right-0`}>
            <div
              onClick={() => setSidePanelOpen(!isSidePanelOpen)}
              className="bg-white justify-center border border-r-0 h-8 w-8 shadow flex items-center border-gray-300 rounded-l-md cursor-pointer relative top-4"
            >
              {isSidePanelOpen ? (
                <ChevronRightIcon className="w-5 h-5" />
              ) : (
                <ChevronLeftIcon className="h-6 w-6" />
              )}
            </div>
            {isSidePanelOpen && (
              <div className=" bg-white h-full">
                <ConfigureDashboard
                  selectedPalete={selectedPalete}
                  showLegend={showLegend}
                  setShowLegend={setShowLegend}
                  dashboardCards={dashboardCards || {}}
                  setDashboardCards={setDashboardCards}
                  selectedPage={selectedPage}
                  configFor={configFor}
                  dashboardSettings={dashboardSettings || {}}
                  setDashboardSettings={setDashboardSettings}
                  selectedPageId={selectedPageId}
                  getFilterFields={getFilterFields}
                  refetchDashboardData={refetchDashboardData}
                  refetchPageData={refetchPageData}
                  setConfigFor={setConfigFor}
                  module={module}
                />
              </div>
            )}
          </div>
        </div>
        {isPreviewDashboard && (
          <ModalDialogue
            title="Preview Dashboard"
            isModalOpen={isPreviewDashboard}
            width="80%"
            height="100%"
            onClose={() => setIsPreviewDashboard(false)}
          >
            {' '}
            <Dashboard
              mode="view"
              module={module}
              hideEditButton={true}
              hideGoToReportButton={true}
              reportId={reportId}
              hideDownloadButton={true}
              hideFilterButton={true}
            />
          </ModalDialogue>
        )}
        {/* {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext> */}
      </div>
    </div>
  );
}
