import { fetchEmployeeData } from 'apiClient/header/header';
import Select from 'components/atoms/FormElements/select/Select';
import UserData from 'components/molecules/userData/UserData';
import { customStyles } from 'pages/surface/reactSelectStyle';
import { useEffect, useRef, useState } from 'react';

export default function DropDownWithEmployeSearch({
  setMembers,
  members,
  isMulti,
}) {
  const [collaborator, setCollaborator] = useState(members || []);
  const [allEmployees, setAllEmployees] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const debounceTimeout = useRef(null); // Ref to store the debounce timeout

  useEffect(() => {
    setCollaborator(members);
  }, [members]);

  const fetchSearchResult = async (searchWord) => {
    setLoading(true);
    try {
      const userArray = await fetchEmployeeData(searchWord, 0, 10);
      setAllEmployees(userArray.EmployeeDetails);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleCollaboratorsInputChange = (inputValue) => {
    setLoading(true);
    clearTimeout(debounceTimeout.current); // Clear the previous timeout
    debounceTimeout.current = setTimeout(() => {
      if (inputValue?.length > 0) {
        setLoading(false);
        fetchSearchResult(inputValue);
      }
    }, 1000); // Set a 1000ms debounce time
  };

  const getFormattedEmployeeList = (list) => {
    return (
      list &&
      list.map((option) => ({
        value: option?.userId?.value,
        label: option?.fullName?.value,
        ...option,
      }))
    );
  };

  return (
    <div className="relative w-full">
      <Select
        formatOptionLabel={(option) => (
          <div className="flex flex-row items-center py-0.5 w-full rounded-lg ">
            <div className="flex flex-col items-start">
              <UserData userData={option} />
            </div>
          </div>
        )}
        options={getFormattedEmployeeList(allEmployees)}
        className={`focus:outline-none rounded-lg min-w-60`}
        placeholder="Select Members"
        isMulti={isMulti}
        label=""
        styles={customStyles}
        onChange={(selectedOption) => {
          setCollaborator(selectedOption);
          if (setMembers) {
            setMembers(selectedOption);
          }
        }}
        onFocus={() => {
          if (allEmployees?.length < 1) {
            fetchSearchResult('');
          }
        }}
        isLoading={isLoading}
        menuPosition="absolute"
        value={collaborator}
        noOptionsMessage={() => 'No results found'}
        onInputChange={handleCollaboratorsInputChange}
      />
    </div>
  );
}
