import React, { useCallback, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import './singleRangeSlider.css';
import { useFormContext } from 'react-hook-form';

const SingleRangeSlider = ({
  min,
  max,
  project,
  defaultValue,
  setMin,
  setMax,
}) => {
  const { setValue } = useFormContext();
  const [minVal, setMinVal] = useState(min);
  const [maxVal, setMaxVal] = useState(
    defaultValue !== undefined ? defaultValue : min,
  );
  const minValRef = useRef(min);
  const maxValRef = useRef(max);
  const range = useRef(null);

  // Convert to percentage
  const getPercent = useCallback(
    (value) => Math.round(((value - min) / (max - min)) * 100),
    [min, max, minVal, maxVal],
  );

  // Set width of the range to decrease from the left side
  useEffect(() => {
    const minPercent = getPercent(minVal);
    const maxPercent = getPercent(maxValRef.current);

    if (range.current) {
      range.current.style.left = `${minPercent}%`;
      range.current.style.width = `${maxPercent - minPercent}%`;
    }
  }, [minVal, getPercent]);

  useEffect(() => {
    const minPercent = getPercent(minValRef.current);
    const maxPercent = getPercent(maxVal);

    if (range.current) {
      range.current.style.width = `${maxPercent - minPercent}%`;
    }
  }, [maxVal, getPercent]);

  // useEffect(() => {
  //   const timeoutId = setTimeout(() => {
  //     dispatch(setSelectedFilters({ ...selectedFilters, maxPoints: maxVal, fitToMyBudget: false }));
  //     // handleApply(
  //     //   {
  //     //     ...selectedFilters,
  //     //     maxPoints: filterReset ? 10000 : maxVal,
  //     //     fitToMyBudget: false,
  //     //   },
  //     //   searchedValue
  //     // );
  //   }, 300);
  //   return () => clearTimeout(timeoutId);
  // }, [maxVal]);

  // useEffect(() => {
  //   const timeoutId = setTimeout(() => {
  //     dispatch(setSelectedFilters({ ...selectedFilters, minPoints: minVal, fitToMyBudget: false }));
  //     // handleApply(
  //     //   {
  //     //     ...selectedFilters,
  //     //     minPoints: filterReset ? 100 : minVal,
  //     //     fitToMyBudget: false,
  //     //   },
  //     //   searchedValue
  //     // );
  //   }, 300);
  //   return () => clearTimeout(timeoutId);
  // }, [minVal]);

  return (
    <div className="container333">
      <input
        type="range"
        min={min}
        max={max}
        value={maxVal}
        onChange={(event) => {
          const value = Math.max(Number(event.target.value), minVal);
          setMaxVal(value);
          setValue(`${project}.rangeSlider`, value);
          maxValRef.current = value;
        }}
        className={`thumbSingleRange thumbSingleRange--right cursor-pointer`}
      />

      <div className="sliderSingleRange">
        <div className="sliderSingleRange__track" />
        <div ref={range} className={'sliderSingleRange__range'} />
        {/* <div className="slider__left-value">{minVal}</div> */}
        <div className="sliderSingleRange__right-value">{maxVal}%</div>
      </div>
    </div>
  );
};

SingleRangeSlider.propTypes = {
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
};

export default SingleRangeSlider;
