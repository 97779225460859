const LettersIcon = (props) => (
  <svg
    width={props?.width ? props?.width : '20'}
    height={props?.height ? props?.height : '20'}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5 8.33342V3.75008C5 3.05973 5.55964 2.50008 6.25 2.50008C6.94036 2.50008 7.5 3.05973 7.5 3.75008V8.33342C7.5 9.71413 6.38071 10.8334 5 10.8334C3.61929 10.8334 2.5 9.71413 2.5 8.33342V5.00008M10.4167 1.66675H12.6667C14.0668 1.66675 14.7669 1.66675 15.3016 1.93923C15.772 2.17892 16.1545 2.56137 16.3942 3.03177C16.6667 3.56655 16.6667 4.26662 16.6667 5.66675V14.3334C16.6667 15.7335 16.6667 16.4336 16.3942 16.9684C16.1545 17.4388 15.772 17.8212 15.3016 18.0609C14.7669 18.3334 14.0668 18.3334 12.6667 18.3334H7.33333C5.9332 18.3334 5.23314 18.3334 4.69836 18.0609C4.22795 17.8212 3.8455 17.4388 3.60582 16.9684C3.33333 16.4336 3.33333 15.7335 3.33333 14.3334V13.7501"
      stroke={props.isActive ? props.activeColor : '#475467'}
      strokeWidth="1.67"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default LettersIcon;
