import { Input } from 'components/atoms/FormElements/input/Input';
import Table from 'components/atoms/table/Table';
import 'components/atoms/table/Table.styles.scss';
import TableHeader from 'components/atoms/tableHeader/TableHeader';

export const PayDetailsTable = ({
  isEditable,
  type,
  detailsTableColumns,
  detailsTableValues,
  addRolePayload,
  setAddRolePayload,
  benchmarkColumns,
}) => {
  const columns = detailsTableColumns?.map((column, index) => {
    if (index === 0) {
      return {
        id: `${index}`,
        Header: <TableHeader name={column} HeaderKey={column} />,
        Cell: ({ row }) => (
          <span className="" key={column}>
            <p className="font-semibold text-sm text-gray-900 capitalize">
              {row.original?.[index]}
            </p>
          </span>
        ),
      };
    }
    return {
      id: `${index}`,
      Header: <TableHeader name={column} HeaderKey={column} sort={true} />,
      Cell: ({ row }) => {
        return (
          <span className="">
            {isEditable ? (
              <Input
                placeholder="Enter value"
                type="number"
                defaultValue={
                  type
                    ? addRolePayload?.calculatedJson?.[
                        `${column}_${
                          benchmarkColumns?.filter(
                            (benchmark) =>
                              benchmark?.columnName ===
                              row.original?.slice(0, 1)?.[0],
                          )?.[0]?.id
                        }`
                      ]
                    : ''
                }
                onChange={(event) =>
                  setAddRolePayload({
                    ...addRolePayload,
                    calculatedJson: {
                      ...addRolePayload.calculatedJson,
                      [`${column}_${
                        benchmarkColumns?.filter(
                          (benchmark) =>
                            benchmark?.columnName ===
                            row.original?.slice(0, 1)?.[0],
                        )?.[0]?.id
                      }`]: event.target.value,
                    },
                  })
                }
              />
            ) : (
              <p className="text-sm text-gray-600">
                {Number(row.original?.[index]).toLocaleString()}
              </p>
            )}
          </span>
        );
      },
    };
  });
  // const columns = [
  //   {
  //     id: '1',
  //     Header: <TableHeader name="Position" HeaderKey="position" />,
  //     Cell: ({ row }) => (
  //       <span className="">
  //         <p className="font-semibold text-sm text-gray-900">
  //           {row.original?.name}
  //         </p>
  //       </span>
  //     ),
  //   },
  //   {
  //   id: '2',
  //   Header: <TableHeader name="Min" HeaderKey="min" sort={true} />,
  //   Cell: ({ row }) => (
  //     <span className="">
  //       {isEditable ? (
  //         <Input
  //           placeholder="Enter value"
  //           defaultValue={type ? '' : row.original?.min}
  //         />
  //       ) : (
  //         <p className="text-sm text-gray-600">{row.original?.min}</p>
  //       )}
  //     </span>
  //   ),
  // },
  //   {
  //     id: '3',
  //     Header: <TableHeader name="Mid" HeaderKey="mid" sort={true} />,
  //     Cell: ({ row }) => (
  //       <span className="">
  //         {isEditable ? (
  //           <Input
  //             placeholder="Enter value"
  //             defaultValue={type ? '' : row.original?.mid}
  //           />
  //         ) : (
  //           <p className="text-sm text-gray-600">{row.original?.mid}</p>
  //         )}
  //       </span>
  //     ),
  //   },
  //   {
  //     id: '4',
  //     Header: <TableHeader name="Max" HeaderKey="max" sort={true} />,
  //     Cell: ({ row }) => (
  //       <span className="">
  //         {isEditable ? (
  //           <Input
  //             placeholder="Enter value"
  //             defaultValue={type ? '' : row.original?.max}
  //           />
  //         ) : (
  //           <p className="text-sm text-gray-600">{row.original?.max}</p>
  //         )}
  //       </span>
  //     ),
  //   },
  // ];
  return (
    <div className="payDetailsTable">
      <Table columns={columns} data={detailsTableValues} />
    </div>
  );
};
