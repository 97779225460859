const ManageOfferIcon = ({
  isActive,
  activeColor,
  isHovered,
  hoveredColor,
  width,
  height,
}) => (
  <svg
    width={width ? width : '20'}
    height={height ? height : '20'}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1769_968)">
      <path
        d="M8.4175 3.33329C9.46962 2.3023 10.9106 1.66663 12.5 1.66663C15.7217 1.66663 18.3333 4.2783 18.3333 7.49996C18.3333 9.08941 17.6976 10.5304 16.6666 11.5825M6.24999 10.8333L7.49999 9.99996V14.5833M6.24999 14.5833H8.74999M13.3333 12.5C13.3333 15.7216 10.7217 18.3333 7.49999 18.3333C4.27833 18.3333 1.66666 15.7216 1.66666 12.5C1.66666 9.2783 4.27833 6.66663 7.49999 6.66663C10.7217 6.66663 13.3333 9.2783 13.3333 12.5Z"
        stroke={isActive ? activeColor : isHovered ? hoveredColor : '#475467'}
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1769_968">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default ManageOfferIcon;
