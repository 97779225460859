export default function HistoryIconGradient() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.500244" width="39" height="39" rx="7.5" fill="white" />
      <path
        d="M28.9166 21.2502L27.2505 19.5836L25.5833 21.2502M27.5 20.0002C27.5 24.1424 24.1421 27.5002 20 27.5002C15.8579 27.5002 12.5 24.1424 12.5 20.0002C12.5 15.8581 15.8579 12.5002 20 12.5002C22.7516 12.5002 25.1571 13.982 26.4621 16.1912M20 15.8336V20.0002L22.5 21.6669"
        stroke="url(#paint0_linear_177_62993)"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="0.5"
        y="0.500244"
        width="39"
        height="39"
        rx="7.5"
        stroke="url(#paint1_linear_177_62993)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_177_62993"
          x1="12.5"
          y1="27.5002"
          x2="28.9166"
          y2="27.5002"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="var(--primaryLeft)" />
          <stop offset="1" stopColor="var(--primaryRight)" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_177_62993"
          x1="0"
          y1="40.0002"
          x2="40"
          y2="40.0002"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="var(--primaryLeft)" />
          <stop offset="1" stopColor="var(--primaryRight)" />
        </linearGradient>
      </defs>
    </svg>
  );
}
