const ViewPoliciesIcon = (props) => (
  // <svg
  //   xmlns="http://www.w3.org/2000/svg"
  //   width={20}
  //   height={20}
  //   fill="none"
  //   {...props}
  // >
  //   <path
  //     stroke={
  //       props.isActive
  //         ? props.activeColor
  //         : props.isHovered
  //         ? props.hoveredColor
  //         : '#475467'
  //     }
  //     strokeLinecap="round"
  //     strokeLinejoin="round"
  //     strokeWidth={1.67}
  //     d="M11.667 1.891v3.442c0 .467 0 .7.09.879.08.156.208.284.365.364.178.09.411.09.878.09h3.442m-3.109 8.75-1.25-1.25m-.416-12.5H7.333c-1.4 0-2.1 0-2.635.273a2.5 2.5 0 0 0-1.092 1.093c-.273.534-.273 1.234-.273 2.635v8.666c0 1.4 0 2.1.273 2.635a2.5 2.5 0 0 0 1.092 1.093c.535.272 1.235.272 2.635.272h5.334c1.4 0 2.1 0 2.635-.272a2.5 2.5 0 0 0 1.092-1.093c.273-.535.273-1.235.273-2.635V6.667l-5-5Zm1.25 10.417a2.917 2.917 0 1 1-5.834 0 2.917 2.917 0 0 1 5.834 0Z"
  //   />
  // </svg>
  <svg
    width={props?.width ? props?.width : '20'}
    height={props?.height ? props?.height : '20'}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.6667 1.89124V5.33335C11.6667 5.80006 11.6667 6.03342 11.7575 6.21168C11.8374 6.36848 11.9649 6.49596 12.1217 6.57586C12.2999 6.66669 12.5333 6.66669 13 6.66669H16.4421M13.3333 15.4166L12.0833 14.1666M11.6667 1.66663H7.33334C5.93321 1.66663 5.23315 1.66663 4.69837 1.93911C4.22796 2.17879 3.84551 2.56124 3.60583 3.03165C3.33334 3.56643 3.33334 4.26649 3.33334 5.66663V14.3333C3.33334 15.7334 3.33334 16.4335 3.60583 16.9683C3.84551 17.4387 4.22796 17.8211 4.69837 18.0608C5.23315 18.3333 5.93321 18.3333 7.33334 18.3333H12.6667C14.0668 18.3333 14.7669 18.3333 15.3017 18.0608C15.7721 17.8211 16.1545 17.4387 16.3942 16.9683C16.6667 16.4335 16.6667 15.7334 16.6667 14.3333V6.66663L11.6667 1.66663ZM12.9167 12.0833C12.9167 13.6941 11.6108 15 10 15C8.38918 15 7.08334 13.6941 7.08334 12.0833C7.08334 10.4725 8.38918 9.16663 10 9.16663C11.6108 9.16663 12.9167 10.4725 12.9167 12.0833Z"
      stroke={
        props.isActive
          ? props.activeColor
          : props.isHovered
          ? props.hoveredColor
          : '#475467'
      }
      strokeWidth="1.67"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default ViewPoliciesIcon;
