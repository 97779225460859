import VerticalEclipse from 'assets/svg/verticalEclipse';
import ChipsLabels from 'components/atoms/chips-labels/ChipsLabels';
import TableSkeleton from 'components/atoms/skeletonLoaderTypes/TableSkeleton';
import Table from 'components/atoms/table/Table';
import TableHeader from 'components/atoms/tableHeader/TableHeader';
import Avatar from 'layouts/header/Avatar';
import { formatDate } from 'pages/recognition/orderHistory/helper';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { imageURLCheck } from 'utils/ImageUrlCheck';
import { PlanPopUp } from './PlanPopUp';

export const BenchmarkPlansTable = ({ data, loading, getAllPlans }) => {
  const navigate = useNavigate();
  const [activePopUpId, setActivePopUpId] = useState('');
  const [popUpVisible, setPopUpVisible] = useState(false);

  const benchmarkPlansHeaders = [
    {
      id: '1',
      Header: <TableHeader name="Title" HeaderKey="title" sort={true} />,
      Cell: ({ row }) => (
        <div>
          <p
            className="text-sm font-medium text-gray-900 hover:underline cursor-pointer"
            onClick={() => navigate(`/range-builder/plan/${row?.original?.id}`)}
          >
            {row.original?.title}
          </p>
          <p className="text-sm text-gray-600">{`ID ${row.original?.rangeId}`}</p>
        </div>
      ),
    },
    {
      id: '2',
      Header: (
        <TableHeader name="No. of Roles" HeaderKey="noOfRoles" sort={true} />
      ),
      Cell: ({ row }) => (
        <div>
          <p className="text-sm text-gray-600">
            {row.original?.noOfRoles
              ? Number(row.original?.noOfRoles)?.toLocaleString()
              : 0}
          </p>
        </div>
      ),
    },
    {
      id: '3',
      Header: (
        <div className="text-center" style={{ textAlign: 'center' }}>
          <TableHeader
            name="Last Modified"
            HeaderKey="lastModified"
            sort={true}
          />
        </div>
      ),
      Cell: ({ row }) => (
        <div className="">
          <div className="flex items-center gap-3">
            <div className="w-8 h-8">
              <Avatar
                className="!w-8 !h-8 !rounded-full"
                image={imageURLCheck(row.original?.userDetails?.avatar?.value)}
                userName={row.original?.userDetails?.fullName?.value}
              />
            </div>
            <div>
              <p className="text-sm text-gray-900">
                {row.original?.userDetails?.fullName?.value}
              </p>
              <p className="text-sm text-gray-600">
                {formatDate(
                  new Date(row.original?.histories?.[0]?.createdDate),
                )}
              </p>
            </div>
          </div>
        </div>
      ),
    },
    {
      id: '4',
      Header: <TableHeader name="Status" HeaderKey="status" sort={true} />,
      Cell: ({ row }) => (
        <div>
          <ChipsLabels
            className="text-sm font-medium"
            label={row.original?.status}
            status={
              row.original?.status === 'Live'
                ? 'accepted'
                : row.original?.status === 'Inactive'
                ? 'rejected'
                : 'InProgress'
            }
          />
          <p></p>
        </div>
      ),
    },
    {
      id: '5',
      width: '5%',
      Header: <TableHeader name="" />,
      Cell: ({ row }) => {
        return (
          <>
            <VerticalEclipse
              onClick={() => {
                setActivePopUpId(row.original?.id);
                setPopUpVisible(true);
              }}
            />
            {popUpVisible && activePopUpId === row?.original?.id && (
              <PlanPopUp
                setPopUpVisible={setPopUpVisible}
                activePopUpId={activePopUpId}
                getAllPlans={getAllPlans}
              />
            )}
          </>
        );
      },
    },
  ];
  return (
    <div>
      {loading ? (
        <TableSkeleton count={5} />
      ) : (
        <>
          <Table
            headerBackgroundColor="bg-gray-50"
            columns={benchmarkPlansHeaders}
            data={data}
          />
          {!data?.length && (
            <p className="text-gray-400 flex items-center justify-center font-semibold">
              No plans to show!
            </p>
          )}
        </>
      )}
    </div>
  );
};
