import { createSlice } from '@reduxjs/toolkit';
import logo from '../../assets/svg/logo.svg';
import defaultSideNav from '../../config/dummy';

const activeColor = 'var(--midColor)';
const hoveredColor = 'var(--hoveredColor)';

const initialState = {
  isWindowVisible: false,
  primaryColor: 'var(--primaryLeft)',
  secoundaryColor: 'var(--primaryRight)',
  logo,
  orgId: '',
  brandName: 'Project R',
  activeFontFamily: { family: 'Open Sans', category: 'sans-serif' },
  isSidebarVisible: localStorage.getItem('sideNav')
    ? localStorage.getItem('sideNav') === 'true'
    : null,
  sidenav: defaultSideNav,
  rbac: [],
  userAccess: [],
  activeIconColor: activeColor,
  hoverIconColor: hoveredColor,
  viewingAs: ['Employee', 'Manager', 'Admin'],
};

const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    ToggleThemeWindow(state) {
      state.isWindowVisible = !state.isWindowVisible;
    },
    setFont(state, action) {
      state.activeFontFamily = action.payload;
    },
    ToggleSidebar(state, action) {
      state.isSidebarVisible = action.payload;
      localStorage.setItem('sideNav', action.payload);
    },
    SetColor(state, action) {
      state.primaryColor = action.payload;
    },
    SetSecoundaryColor(state, action) {
      state.secoundaryColor = action.payload;
    },
    setSideNav(state, action) {
      state.sidenav = action.payload;
      state.rbac = action.payload.modules;
    },
    updateUserAccess(state, action) {
      state.userAccess = action.payload;
    },
    setActiveIconColor(state, action) {
      state.activeIconColor = action.payload;
    },
    setHoverIconColor(state, action) {
      state.hoverIconColor = action.payload;
    },
    setViewingAs(state, action) {
      state.viewingAs = action.payload;
    },
    setOrgDetials(state, action) {
      state.logo = action.payload.organizationDetails.logo;
      state.orgId = action.payload.orgId;
      state.brandName = action.payload.orgName;
      // state.primaryColor = action.payload.organizationDetails.primaryColor;
    },
  },
});

export const {
  ToggleThemeWindow,
  setFont,
  ToggleSidebar,
  SetColor,
  SetSecoundaryColor,
  setOrgDetials,
  updateUserAccess,
  setSideNav,
  setActiveIconColor,
  setHoverIconColor,
  setViewingAs,
} = themeSlice.actions;
export default themeSlice.reducer;
