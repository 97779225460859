import { getFunctionsList } from 'apiClient/formula/formula';
import SearchIconInput from 'assets/svg/searchIconInput';
import TableSkeleton from 'components/atoms/skeletonLoaderTypes/TableSkeleton';

import { useEffect, useState } from 'react';

export default function Function({ addFunction, moduleName }) {
  const [search, setSearch] = useState('');
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const onChangeHandler = (e) => {
    setSearch(e.target.value.trim());
  };
  const fetchFunctionList = async (query) => {
    try {
      const data = await getFunctionsList(query);
      return data;
    } catch (err) {
      console.log(err);
      return [];
    }
  };

  useEffect(() => {
    setIsLoading(true);
    fetchFunctionList({
      module: moduleName,
    })
      .then((res) => {
        setIsLoading(false);
        setData(res);
      })
      .catch((err) => console.log(err));
  }, [moduleName]);

  return (
    <div className="p-4 max-h-full h-[395px]">
      <div className="p-4 pt-2 pb-2 border-2 rounded-lg mt-2 mb-2 flex flex-row h-[40px]">
        <SearchIconInput />
        <div className=" focus:outline-none outline-none pl-2 h-full">
          <input
            placeholder="Search"
            onChange={onChangeHandler}
            className="focus:outline-none"
            value={search}
          />
        </div>
      </div>

      {isLoading ? (
        <>
          <TableSkeleton />
        </>
      ) : data && data?.length > 0 ? (
        data
          .filter((obj) =>
            search !== ''
              ? obj.name.toLowerCase().includes(search.toLowerCase())
              : true,
          )
          .map((obj) => {
            return (
              <div
                onClick={() => {
                  addFunction(obj.syntax);
                }}
                key={obj.id}
                className="p-4 pt-2 pb-2 border-2 font-semibold rounded-lg mt-2 mb-2 text-sm h-[40px]"
              >
                {obj.name}
              </div>
            );
          })
      ) : (
        <>No Functions Available</>
      )}
    </div>
  );
}
