// DashboardChartList.js
import { ChartBarIcon, PlusIcon } from '@heroicons/react/24/outline';
import { v4 as uuidv4 } from 'uuid';
// import { chartList } from './Chartlist'; // Assuming you have a chartList imported or defined somewhere
import { useEffect, useState } from 'react';
import {
  useGetChartTypes,
  useUpdatePage,
} from 'query/dashboard/ManageDashboard';
import TableSkeleton from 'components/atoms/skeletonLoaderTypes/TableSkeleton';
import Button from 'components/atoms/button/Button';
import { onResponse } from 'utils/toastMessages';

const DashboardChartList = ({
  dashboardCards,
  setDashboardCards,
  selectedPage,
  dashboardId,
  refetchPageData,
}) => {
  const { data: chartList, isLoading, isError } = useGetChartTypes(dashboardId);
  const [savePending, setSavePending] = useState(false);

  const { mutateAsync } = useUpdatePage(dashboardCards?.id);

  const onAdd = (item) => {
    // const selectedPageData = dashboardCards?.find(
    //   (item) => item?.pageIndex === selectedPage,
    // );
    // var newSeparator = {
    //   i: v4(), // Generate a unique id
    //   x: 0, // Adjust x position based on the length of the array
    //   y: 0,
    //   w: 12,
    //   h: 2,
    //   data: { type: 'separator', name: 'Esops', id: 1335 },
    // };

    // var newText = {
    //   i: v4(), // Generate a unique id
    //   x: 0, // Adjust x position based on the length of the array
    //   y: 0,
    //   w: 8,
    //   h: 4,
    //   data: { type: 'text', description: 'Enter Description', id: 1357 },
    // };
    var newCard = {
      i: uuidv4(), // Convert the unique id to a string
      x: 0, // Adjust x position based on the length of the array
      y: 0,
      w: 4,
      h: 12,
      minH: 10,
      minW: 2,
      maxH: 26,
      cardData: {
        name: item?.name,
        type: item?.type,
        description: item?.description,
        measures: item?.measures || [],
        dimensions: item?.dimensions || [],
        aggregation: item?.aggregation || 'Sum',
        multipleMeasures: item?.multipleMeasures,
        multipleDimensions: item?.multipleDimensions,
      },
    };

    setDashboardCards((prevData) => ({
      ...prevData,
      charts: [...(prevData?.charts ?? []), newCard],
    }));
    setSavePending(true);
  };

  useEffect(() => {
    if (savePending) {
      handleSave();
      setSavePending(false);
    }
  }, [savePending]);

  const handleSave = async () => {
    const payload = {
      dashboardId: dashboardId,
      charts: dashboardCards?.charts
        ?.filter((item) => !item?.cardData?.id)
        ?.map((item) => ({
          id: item?.cardData?.id,
          name: item?.cardData?.name,
          data: {
            i: item?.i,
            x: item?.x,
            y: item?.y,
            w: item?.w,
            h: item?.h,
            minH: item?.minH,
            minW: item?.minW,
            type: item?.cardData?.type,
            datasets: item?.cardData?.datasets,
          },
          description: item?.cardData?.description,
          measures: item?.cardData?.measures || [],
          dimensions: item?.cardData?.dimensions || [],
          aggregation: item?.cardData?.aggregation || 'Sum',
        })),
    };
    try {
      await mutateAsync(payload);
      refetchPageData();
    } catch (error) {
      onResponse(error?.message);
    }
  };

  return (
    <>
      <div className="relative h-full flex flex-col overflow-y-auto">
        {isLoading ? (
          <TableSkeleton />
        ) : (
          <div className="flex flex-col gap-4 mb-12 h-full">
            <div className="border justify-start items-center gap-4 rounded-xl py-1  divide-x border-gray-200 flex">
              <PlusIcon
                className="w-5 h-5 cursor-pointer ml-3"
                onClick={() => onAdd('addSeparator')}
              />

              <div className="p-2 flex justify-between items-center gap-2">
                <ChartBarIcon className="w-5 h-5" />
                Separator
              </div>
            </div>
            <div className="border justify-start items-center gap-4 rounded-xl py-1  divide-x border-gray-200 flex">
              <PlusIcon
                className="w-5 h-5 cursor-pointer ml-3"
                onClick={() => onAdd('text')}
              />

              <div className="p-2 flex justify-between items-center gap-2">
                <ChartBarIcon className="w-5 h-5" />
                Text
              </div>
            </div>
            <div className="border justify-start items-center gap-4 rounded-xl py-1  divide-x border-gray-200 flex">
              <PlusIcon
                className="w-5 h-5 cursor-pointer ml-3"
                onClick={() => onAdd('table')}
              />

              <div className="p-2 flex justify-between items-center gap-2">
                <ChartBarIcon className="w-5 h-5" />
                Table
              </div>
            </div>
            {chartList?.map((item, index) => (
              // <Draggable draggableId="item" index={0}>
              //  {(provided) => (
              <div
                key={item?.id}
                //   {...provided.draggableProps}
                //   {...provided.dragHandleProps}
                //  ref={provided.innerRef}
                className="border justify-start items-center gap-4 rounded-xl py-1  divide-x border-gray-200 flex"
              >
                {/* <Button
              className="text-xl flex justify-center items-center ml-2 w-8 "

              // variant="filled"
            > */}
                <PlusIcon
                  className="w-5 h-5 cursor-pointer ml-3"
                  onClick={() => onAdd(item)}
                />
                {/* <span className='ml-2' onClick={() => onAdd(item)}>
                +
              </span> */}
                {/* </Button> */}

                <div className="p-2 flex justify-between items-center gap-2">
                  <ChartBarIcon className="w-5 h-5" />
                  {item?.name}
                </div>
              </div>
              //   )}
              // </Draggable>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default DashboardChartList;
