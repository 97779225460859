import React from 'react';
import ViewImageAtom from '../viewImageAtom/ViewImageAtom';
import ViewHeadingAtom from '../viewHeadingAtom/ViewHeadingAtom';
import ViewParagraphAtom from '../viewParagraphAtom/ViewParagraphAtom';

const ViewIHPMolecule = (props) => {
  const { columnData, card } = props;
  return (
    <div
      className={`${
        card && 'ring-1 ring-gray-200 rounded-lg shadow-md p-5'
      } w-full h-full flex flex-col gap-5`}
    >
      <ViewImageAtom columnData={columnData} height={card ? 'h-60' : 'h-80'} />
      <div className="flex flex-col gap-2">
        <ViewHeadingAtom columnData={columnData} />
        <ViewParagraphAtom columnData={columnData} />
      </div>
    </div>
  );
};

export default ViewIHPMolecule;
