import {
  createAllTask,
  createRNRTask,
  getAllTask,
  userTaskAction,
} from 'apiClient/userTask/task';
import { useMutation, useQuery, useQueryClient } from 'react-query';

export const useGetAllTask = (
  id,
  pageSize,
  pageIndex,
  query,
  status,
  getKeyFields,
) =>
  useQuery(
    ['get/allTask', id, pageSize, pageIndex, query, status, getKeyFields],
    async () =>
      getAllTask(id, pageSize, pageIndex, query, status, getKeyFields),
    {
      enabled: !!status,
    },
  );

export const useCreateAllTask = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ['create/allTask'],
    async ({ id, action, payload }) => createAllTask(id, action, payload),
    {
      onSuccess: (res) => {
        if (res?.data) {
          queryClient.invalidateQueries(['get/allTask']);
        }
      },
    },
  );
};

export const useTaskAction = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ['create/taskAction'],
    async (payload) => userTaskAction(payload),
    {
      onSuccess: (res) => {
        if (res?.data) {
          queryClient.invalidateQueries(['get/allTask']);
        }
      },
    },
  );
};

export const useCreateRNRTask = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ['create/rnrTask'],
    async ({ id, action, payload }) => createRNRTask(id, action, payload),
    {
      onSuccess: (res) => {
        if (res?.data) {
          queryClient.invalidateQueries(['get/rnrTask']);
        }
      },
    },
  );
};
