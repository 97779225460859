const ReportsIcon = (props) => (
  <svg
    width={props?.width ? props?.width : '20'}
    height={props?.height ? props?.height : '20'}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.6667 10.4167V5.66675C16.6667 4.26662 16.6667 3.56655 16.3942 3.03177C16.1545 2.56137 15.7721 2.17892 15.3017 1.93923C14.7669 1.66675 14.0668 1.66675 12.6667 1.66675H7.33334C5.93321 1.66675 5.23315 1.66675 4.69837 1.93923C4.22796 2.17892 3.84551 2.56137 3.60583 3.03177C3.33334 3.56655 3.33334 4.26662 3.33334 5.66675V14.3334C3.33334 15.7335 3.33334 16.4336 3.60583 16.9684C3.84551 17.4388 4.22796 17.8212 4.69837 18.0609C5.23315 18.3334 5.93321 18.3334 7.33334 18.3334H10M11.6667 9.16675H6.66668M8.33334 12.5001H6.66668M13.3333 5.83341H6.66668M12.0833 15.8334L13.75 17.5001L17.5 13.7501"
      stroke={props.isActive ? props.activeColor : '#475467'}
      strokeWidth="1.67"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default ReportsIcon;
