const PoliciesLogo = ({ isActive, activeColor, width, height }) => {
  return (
    <svg
      width={width ? width : '20'}
      height={height ? height : '20'}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.6667 1.89124V5.33335C11.6667 5.80006 11.6667 6.03342 11.7575 6.21168C11.8374 6.36848 11.9649 6.49596 12.1217 6.57586C12.2999 6.66669 12.5333 6.66669 13 6.66669H16.4421M13.3333 10.8333H6.66668M13.3333 14.1666H6.66668M8.33334 7.49996H6.66668M11.6667 1.66663H7.33334C5.93321 1.66663 5.23315 1.66663 4.69837 1.93911C4.22796 2.17879 3.84551 2.56124 3.60583 3.03165C3.33334 3.56643 3.33334 4.26649 3.33334 5.66663V14.3333C3.33334 15.7334 3.33334 16.4335 3.60583 16.9683C3.84551 17.4387 4.22796 17.8211 4.69837 18.0608C5.23315 18.3333 5.93321 18.3333 7.33334 18.3333H12.6667C14.0668 18.3333 14.7669 18.3333 15.3017 18.0608C15.7721 17.8211 16.1545 17.4387 16.3942 16.9683C16.6667 16.4335 16.6667 15.7334 16.6667 14.3333V6.66663L11.6667 1.66663Z"
        stroke={isActive ? activeColor : '#475467'}
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PoliciesLogo;
