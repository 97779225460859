import React from 'react';
import VideosAtom from '../../atoms/videoatom/VideoAtom';

const VideoComponent = (props) => {
  const { data } = props;
  const { selectAtomsMolecules, type } = data;
  return (
    <div
      onClick={() => selectAtomsMolecules(type)}
      className={`h-full w-full flex justify-center items-center cursor-pointer p-4`}
    >
      <VideosAtom data={data} />
    </div>
  );
};

export default VideoComponent;
