const SurveysLogo = ({ isActive, activeColor, width, height }) => {
  return (
    <div>
      <svg
        width={width ? width : '18'}
        height={height ? height : '18'}
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.5 5.5C1.5 4.09987 1.5 3.3998 1.77248 2.86502C2.01217 2.39462 2.39462 2.01217 2.86502 1.77248C3.3998 1.5 4.09987 1.5 5.5 1.5H12.5C13.9001 1.5 14.6002 1.5 15.135 1.77248C15.6054 2.01217 15.9878 2.39462 16.2275 2.86502C16.5 3.3998 16.5 4.09987 16.5 5.5V10C16.5 11.4001 16.5 12.1002 16.2275 12.635C15.9878 13.1054 15.6054 13.4878 15.135 13.7275C14.6002 14 13.9001 14 12.5 14H7.06979C6.54975 14 6.28972 14 6.04101 14.051C5.82036 14.0963 5.60683 14.1712 5.40624 14.2737C5.18014 14.3892 4.9771 14.5517 4.57101 14.8765L2.58313 16.4668C2.23639 16.7442 2.06302 16.8829 1.91712 16.8831C1.79022 16.8832 1.67019 16.8255 1.59102 16.7263C1.5 16.6123 1.5 16.3903 1.5 15.9463V5.5Z"
          stroke={isActive ? activeColor : '#475467'}
          strokeWidth="1.67"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default SurveysLogo;
