const ManageWorkflowsIcon = (props) => (
  <svg
    width={props?.width ? props?.width : '20'}
    height={props?.height ? props?.height : '20'}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.1667 16.6667H14C12.5999 16.6667 11.8998 16.6667 11.365 16.3943C10.8946 16.1546 10.5122 15.7721 10.2725 15.3017C10 14.7669 10 14.0669 10 12.6667V7.33342C10 5.93328 10 5.23322 10.2725 4.69844C10.5122 4.22803 10.8946 3.84558 11.365 3.6059C11.8998 3.33341 12.5999 3.33341 14 3.33341H14.1667M14.1667 16.6667C14.1667 17.5872 14.9129 18.3334 15.8333 18.3334C16.7538 18.3334 17.5 17.5872 17.5 16.6667C17.5 15.7463 16.7538 15.0001 15.8333 15.0001C14.9129 15.0001 14.1667 15.7463 14.1667 16.6667ZM14.1667 3.33341C14.1667 4.25389 14.9129 5.00008 15.8333 5.00008C16.7538 5.00008 17.5 4.25389 17.5 3.33341C17.5 2.41294 16.7538 1.66675 15.8333 1.66675C14.9129 1.66675 14.1667 2.41294 14.1667 3.33341ZM5.83333 10.0001L14.1667 10.0001M5.83333 10.0001C5.83333 10.9206 5.08714 11.6667 4.16667 11.6667C3.24619 11.6667 2.5 10.9206 2.5 10.0001C2.5 9.07961 3.24619 8.33342 4.16667 8.33342C5.08714 8.33342 5.83333 9.07961 5.83333 10.0001ZM14.1667 10.0001C14.1667 10.9206 14.9129 11.6667 15.8333 11.6667C16.7538 11.6667 17.5 10.9206 17.5 10.0001C17.5 9.07961 16.7538 8.33342 15.8333 8.33342C14.9129 8.33342 14.1667 9.07961 14.1667 10.0001Z"
      stroke={
        props.isActive
          ? props.activeColor
          : props.isHovered
          ? props.hoveredColor
          : '#475467'
      }
      strokeWidth="1.67"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default ManageWorkflowsIcon;
