import { ArrowSmallLeftIcon } from '@heroicons/react/24/outline';
import KebabIcon from 'assets/svg/kebabIcon';
import Tabs from 'components/molecules/tabs/Tabs';
import React, { useState } from 'react';
import BenchmarkingTo from './benchMarkingTo/BenchmarkingTo';
import MarketData from './marketData/MarketData';
import VersionHistory from './versionHistory/VersionHistory';

export const tabs = [
  // {
  //   id: '1',
  //   tabLabel: 'Benchmarked to',
  // },
  {
    id: '1',
    tabLabel: 'Market Data',
  },
  {
    id: '2',
    tabLabel: 'Version History',
  },
];

const ViewDetailsPopUp = ({
  mappingRes,
  isViewDetailsPopUp,
  rangeFormTypeId,
  defaultTab,
  setIsViewDetailsPopUp,
  data,
  columns,
}) => {
  const [selectedTab, setSelectedTab] = useState(
    defaultTab ?? tabs?.[0]?.tabLabel,
  );
  return (
    <div className="fixed z-[53] inset-0 bg-backdrop overflow-y-auto w-full">
      <div
        className={`absolute top-14 right-0 w-3/5 bg-white min-h-screen overflow-y-auto custom-scrollbar`}
      >
        <div className="flex items-center bg-white justify-between w-full gap-4 shadow-md border-b p-2 px-4 fixed top-[54px]">
          <div className="flex flex-row items-center gap-3 justify-start">
            <div
              className="p-2 rounded-lg border-2 cursor-pointer"
              onClick={() => {
                setIsViewDetailsPopUp(null);
                // setDefaultTabSelected && setDefaultTabSelected('');
              }}
            >
              <ArrowSmallLeftIcon className="w-4 h-4" />
            </div>
            <div className=" flex items-center justify center text-xl font-semibold">
              Source Data Details
            </div>
          </div>
        </div>
        <div className="flex w-full justify-between p-6 mt-[54px]">
          <p className="font-figtree font-semibold text-base">
            {
              data?.[0]?.data?.[
                columns.findIndex((obj) => obj.name === 'Pay Range Title') !==
                -1
                  ? columns.findIndex((obj) => obj.name === 'Pay Range Title')
                  : 1
              ]
            }
            |
            {
              data?.[0]?.data?.[
                columns.findIndex((obj) => obj.name === 'Pay Range Code') !== -1
                  ? columns.findIndex((obj) => obj.name === 'Pay Range Code')
                  : 2
              ]
            }
          </p>
          <KebabIcon className="cursor-pointer" />
        </div>
        <div className="space-y-5 px-6">
          <Tabs
            tabs={tabs}
            selectedTab={selectedTab}
            handleTab={(e) => setSelectedTab(e.tabLabel)}
          />
          {/* {selectedTab === tabs?.[0]?.tabLabel && <BenchmarkingTo />} */}
          {selectedTab === tabs?.[0]?.tabLabel && (
            <MarketData data={data} columns={columns} />
          )}
          {selectedTab === tabs?.[1]?.tabLabel && (
            <VersionHistory
              mappingRes={mappingRes}
              rangeFormTypeId={rangeFormTypeId}
              rangeDataId={isViewDetailsPopUp?.id}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ViewDetailsPopUp;
