export const GradientLinkIcon = (props) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_19_75855)">
        <path
          d="M5.0001 6.49998C5.21483 6.78705 5.48878 7.02457 5.80337 7.19645C6.11797 7.36833 6.46585 7.47054 6.82342 7.49615C7.181 7.52176 7.53989 7.47017 7.87577 7.34487C8.21165 7.21958 8.51666 7.02352 8.7701 6.76998L10.2701 5.26998C10.7255 4.79848 10.9775 4.16697 10.9718 3.51148C10.9661 2.85599 10.7032 2.22896 10.2396 1.76544C9.77613 1.30192 9.14909 1.03899 8.4936 1.0333C7.83811 1.0276 7.20661 1.27959 6.7351 1.73498L5.8751 2.58998M7.0001 5.49998C6.78537 5.21292 6.51142 4.97539 6.19682 4.80351C5.88223 4.63163 5.53435 4.52942 5.17677 4.50382C4.8192 4.47821 4.46031 4.5298 4.12443 4.65509C3.78855 4.78039 3.48354 4.97645 3.2301 5.22998L1.7301 6.72998C1.2747 7.20149 1.02272 7.833 1.02841 8.48849C1.03411 9.14398 1.29703 9.77101 1.76055 10.2345C2.22407 10.6981 2.8511 10.961 3.5066 10.9667C4.16209 10.9724 4.79359 10.7204 5.2651 10.265L6.1201 9.40998"
          stroke="url(#paint0_linear_19_75855)"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_19_75855"
          x1="1.02832"
          y1="10.9668"
          x2="10.9719"
          y2="10.9668"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="var(--primaryLeft)" />
          <stop offset="1" stopColor="var(--primaryRight)" />
        </linearGradient>
        <clipPath id="clip0_19_75855">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
