import axios from 'axios';
import Button from 'components/atoms/button/Button';
import { onResponse } from 'utils/toastMessages';

export const FileDownload = ({ filePath }) => {
  const handleDownload = async () => {
    try {
      const response = await axios({
        url: filePath,
        method: 'GET',
        responseType: 'blob',
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'downloaded_file');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      onResponse('Some error has occurred!');
    }
  };
  return (
    <div>
      <Button variant="filled" onClick={handleDownload}>
        Download File
      </Button>
    </div>
  );
};
