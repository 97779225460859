import { Input } from 'components/atoms/FormElements/input/Input';
import { PayDetailsTable } from '../PayDetailsTable';

export const Details = ({
  isEditable,
  type,
  pivotedColumns,
  pivotedData,
  detailsTableColumns,
  detailsTableValues,
  addRolePayload,
  setAddRolePayload,
  benchmarkColumns,
}) => {
  return (
    <div className="space-y-5">
      <div className="rounded-lg border border-gray-200 space-y-6 pb-6">
        <p className="py-[10px] px-5 bg-gray-50 font-semibold">Job Details</p>
        <div className="flex items-center flex-wrap gap-8">
          {pivotedColumns?.map((column, index) => {
            return (
              <div key={column} className="w-[21.7%] pl-5 space-y-1">
                <p className="text-xs font-medium text-gray-600">{column}</p>
                {isEditable ? (
                  <Input
                    className="!py-2 !px-[14px]"
                    defaultValue={
                      type ? addRolePayload?.pivotedJson?.[column] : ''
                    }
                    onChange={(event) =>
                      setAddRolePayload({
                        ...addRolePayload,
                        pivotedJson: {
                          ...addRolePayload?.pivotedJson,
                          [column]: event.target.value,
                        },
                      })
                    }
                    placeholder="Enter value.."
                  />
                ) : (
                  <p className="text-sm font-medium text-gray-700">
                    {pivotedData?.[index]}
                  </p>
                )}
              </div>
            );
          })}
        </div>
      </div>
      <PayDetailsTable
        isEditable={isEditable}
        type={type}
        detailsTableColumns={detailsTableColumns}
        detailsTableValues={detailsTableValues}
        addRolePayload={addRolePayload}
        setAddRolePayload={setAddRolePayload}
        benchmarkColumns={benchmarkColumns}
      />
    </div>
  );
};
