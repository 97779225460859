import { configureStore } from '@reduxjs/toolkit';
import nominationReducer from './reducers/nominationSlice';
import userSlice from './reducers/userSlice';
import themeSlice from './reducers/themeSlice';
import postSlice from './reducers/postSlice';
import SurfaceSlice from './reducers/SurfaceSlice';
import recognitionReducer from './reducers/recognitionSlice';
import redemptionReducer from './reducers/redemptionSlice';
import profileReducer from './reducers/profileSlice';
import programsReducer from './reducers/programSlice';
import offersReducer from './reducers/hiringOfferSlice';
import pollsReducer from './reducers/pollsSlice';
import surveyReducer from './reducers/surveySlice';
import eventReducer from './reducers/eventsSlice';
import policyReducer from './reducers/policySlice';
import notificationReducer from './reducers/nominationSlice';
import compensationReducer from './reducers/compensationSlice';
import managePoliciesReducer from './reducers/managePolicySlice';
import compensationProfileReducer from './reducers/compensationProfile';
import manageBenefitsReducer from './reducers/manageBenefitsSlice';
import compPlanSummaryReducer from './reducers/compPlanSummary';
import communitiesSlice from './reducers/communitiesSlice';
import policyApprovalViewReducer from './reducers/policyApprovalViewSlice';
import nominationApprovalSlice from './reducers/nominationApprovalSlice';
import policyApprovalDataSlice from './reducers/policyApprovalDataSlice';
import snapshotSlice from './reducers/snapshotSlice';
import letterGenerationSlice from './reducers/letterGenerationSlice';
import templateBuilderReducer from './reducers/templateBuilderSlice';
// import userTaskSlice, { userTaskReducer } from "./reducers/userTaskSlice";
import taskReducer from './reducers/taskSlice';
import manageProgramsReducer from './reducers/manageProgramsSlice';
import insurance from './reducers/insuranceSlice';
import reportSlice from './reducers/reportSlice';

export const store = configureStore({
  reducer: {
    user: userSlice,
    // userTask: userTaskReducer,
    theme: themeSlice,
    createPost: postSlice,
    surface: SurfaceSlice,
    recognition: recognitionReducer,
    redemption: redemptionReducer,
    profile: profileReducer,
    programs: programsReducer,
    offers: offersReducer,
    nomination: nominationReducer,
    polls: pollsReducer,
    survey: surveyReducer,
    event: eventReducer,
    policy: policyReducer,
    notification: notificationReducer,
    compensation: compensationReducer,
    managePolicies: managePoliciesReducer,
    compensationEmployee: compensationProfileReducer,
    manageBenefits: manageBenefitsReducer,
    compPlanSummary: compPlanSummaryReducer,
    communitySlice: communitiesSlice,
    policyApprovalView: policyApprovalViewReducer,
    nominationApproval: nominationApprovalSlice,
    policyApprovalData: policyApprovalDataSlice,
    snapshot: snapshotSlice,
    letterGeneration: letterGenerationSlice,
    templateBuilder: templateBuilderReducer,
    tasks: taskReducer,
    managePrograms: manageProgramsReducer,
    insurance: insurance,
    reports: reportSlice,
  },
});
