import { TBatchFormulaMap, TDependencyArrayMap } from '../types';
import { formulaConstants } from '../utils/formulaConstants';

export const getDependenciesFromFormula = (formulaString: string) => {
    const dependencySet = new Set<string>();

    while (true) {
        const regexMatchResult = formulaConstants.VARIABLE_REGEX.exec(formulaString);
        if (regexMatchResult === null) break;
        dependencySet.add(regexMatchResult[1]);
    }
    const uniqueDependencies = Array.from(dependencySet);
    return uniqueDependencies;
};

export const getDependencyArrayMap = (formulaMap: TBatchFormulaMap) => {
    const formulaKeys = Object.keys(formulaMap);
    return formulaKeys.reduce(
        (prev, curr) => ({
            ...prev,
            [curr]: getDependenciesFromFormula(formulaMap[curr].formulaString)
        }),
        {} as TDependencyArrayMap
    );
};

const hasCyclicDependency = (
    node: string,
    visited: { [key: string]: boolean },
    stack: { [key: string]: boolean },
    graph: TBatchFormulaMap,
    dependencyArrayMap: TDependencyArrayMap
) => {
    if (!graph[node]) return false;
    if (!visited[node]) {
        visited[node] = true;
        stack[node] = true;
        const dependencies = dependencyArrayMap[node];
        for (const dependency of dependencies) {
            if (
                !visited[dependency] &&
                hasCyclicDependency(dependency, visited, stack, graph, dependencyArrayMap)
            ) {
                return true;
            } else if (stack[dependency]) {
                return true;
            }
        }
    }
    stack[node] = false;
    return false;
};

const hasCyclicDependencyInGraph = (graph: TBatchFormulaMap) => {
    const nodes = Object.keys(graph);
    const visited = {};
    const stack = {};
    const dependencyArrayMap = getDependencyArrayMap(graph);
    for (const node of nodes) {
        if (hasCyclicDependency(node, visited, stack, graph, dependencyArrayMap)) {
            return true;
        }
    }
    return false;
};

export const checkForCyclicDependency = (formulaMap: TBatchFormulaMap) => {
    return hasCyclicDependencyInGraph(formulaMap);
};
