import React from 'react';
import { useDispatch } from 'react-redux';
import { imageURLCheck } from 'utils/ImageUrlCheck';
import ViewTemplatePanel from './viewTemplatePanel/ViewTemplatePanel';

const ViewTemplate = ({ landingPageData }) => {
  //  const { landingPageData } = useSelector(templateBuilderSelector);
  const dispatch = useDispatch();
  return (
    <React.Fragment>
      <div className="relative max-h-full max-w-full overflow-y-scroll">
        <div className="bg-white h-full w-full">
          <div id="banner-image" className="h-72 w-full ring-1 ring-gray-200">
            {/* <Button
              onClick={() => dispatch(setModeLabel('Edit'))}
              className="w-fit z-50 flex px-4 py-2 rounded-xl bg-gray-200 absolute top-2 right-2 cursor-pointer"
            >
              <EditIcon /> Change
            </Button> */}
            <img
              src={imageURLCheck(landingPageData?.banner?.imageUrl)}
              alt="Banner media is not found"
              className="w-full h-full"
            />
          </div>
          <div className="rounded-xl -mt-10 mb-5 h  m-auto">
            <ViewTemplatePanel landingPageData={landingPageData} />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ViewTemplate;
