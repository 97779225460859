const ForecastAndSimulationIcon = ({
  isActive,
  activeColor,
  hoveredColor,
  isHovered,
  width,
  height,
}) => (
  <svg
    width={width ? width : '20'}
    height={height ? height : '20'}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.5356 4.79777C15.4882 6.75039 15.4882 9.91622 13.5356 11.8688M6.46446 11.8688C4.51184 9.91622 4.51184 6.75039 6.46446 4.79777M4.10725 14.2259C0.852881 10.9715 0.852881 5.69516 4.10725 2.4408M15.8927 2.4408C19.1471 5.69516 19.1471 10.9715 15.8927 14.2259M9.99999 10C10.9205 10 11.6667 9.25381 11.6667 8.33333C11.6667 7.41286 10.9205 6.66667 9.99999 6.66667C9.07952 6.66667 8.33332 7.41286 8.33332 8.33333C8.33332 9.25381 9.07952 10 9.99999 10ZM9.99999 10V17.5"
      stroke={isActive ? activeColor : isHovered ? hoveredColor : '#475467'}
      strokeWidth="1.67"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default ForecastAndSimulationIcon;
