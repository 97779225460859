import { v4 } from 'uuid';
export const queryBuilderFormatterForAPI = (query) => {
  if (query?.combinator === 'or' || query?.combinator === 'and') {
    const rules = {
      [query?.combinator?.toUpperCase()]: [],
    };
    query?.rules?.forEach((child) => {
      if (child?.rules) {
        const condition = queryBuilderFormatterForAPI(child);
        if (condition) {
          if (rules[query?.combinator?.toUpperCase()]) {
            rules[query?.combinator?.toUpperCase()].push(condition);
          }
        }
      } else if (child?.field && child?.operator && child?.value) {
        const criteria = child?.operator;

        const operatorCode = child?.operator?.code || child?.operator || '';

        if (criteria) {
          const condition = {
            [operatorCode]: [
              child?.field?.value || child?.field,
              child?.value?.value || child?.value,
            ],
          };
          if (rules[query?.combinator?.toUpperCase()]) {
            rules[query?.combinator?.toUpperCase()].push(condition);
          }
        }
      }
    });
    return rules;
  } else {
    return {};
  }
};
export function queryBuilderFormatterForUI(
  formatObject,
  parsedData,
  fields,
  operators,
) {
  if (!parsedData) {
    return null;
  }

  if (Object.keys(parsedData).length === 0) {
    return {
      combinator: 'and',
      rules: [],
    };
  }

  function getField(fieldId) {
    if (fields?.length > 0) {
      const fieldData = fields.find((ele) => ele.id === fieldId);
      return {
        ...fieldData,
        id: fieldData?.id || null,
        value: fieldData?.id || null,
        label: fieldData?.alias || null,
      };
    }
  }
  function getOperators(fieldId, operator) {
    if (Object.keys(operators)?.length > 0) {
      const fieldData = getField(fieldId);

      const operatorData = operators?.[fieldData?.dataType]?.find(
        (ele) => ele.code === operator,
      );

      if (operatorData) {
        return {
          ...operatorData,
          id: operatorData?.code || null,
          value: operatorData?.code || null,
          label: operatorData?.displayName || null,
        };
      } else {
        return {
          code: 'id-in',
          displayName: 'Id-in',
          id: 'id-in',
          value: 'id-in',
          label: 'Id-in',
        };
      }
    }
  }
  function findObjectByValue(targetValue) {
    const result = Object?.values(formatObject)?.find((obj) => {
      return obj?.id === targetValue;
    });
    if (result) {
      return (
        {
          ...result,
          id: result?.id || null,
          value: result?.id || null,
          isActive: true,
          label: result?.value || null,
        } || null
      );
    } else return targetValue;
  }
  //console.log(formatObject, parsedData);
  // console.log(formatObject);
  const getFormattedData = (object, data) => {
    const type = Object.keys(data)[0]; // Get the type (OR or AND)
    if (type === 'OR' || type === 'AND') {
      const children = data?.[type]?.map((child) => {
        const [keys, values] = Object?.entries(child || {})[0] || [null, null];
        if (keys === 'OR' || keys === 'AND') {
          return getFormattedData(object, child);
        } else {
          const criteriaKey = Object.keys(child)[0];
          // const fieldData = findObjectByValue(object, values[0]);
          return {
            id: v4(),
            field: getField(values[0]),
            operator: getOperators(values[0], criteriaKey),
            value: findObjectByValue(values[1]),
          };
        }
      });
      return {
        id: v4(),
        combinator: type?.toLowerCase(),
        rules: children,
      };
    }
  };

  return getFormattedData(formatObject, parsedData);
}
