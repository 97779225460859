import GetSvgIcon from 'components/molecules/svgCollection/SvgCollection';
import { useNavigate } from 'react-router-dom';

export const AppIconWithPopUp = ({ item, type, handleHoverIn }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (!item?.sublinks?.length) {
      navigate(item?.url?.[0] === '/' ? `${item?.url}` : `/${item?.url}`);
    }
  };

  return (
    <>
      <div
        className={`flex ${
          type ? 'w-14 mx-7' : 'w-1/4 grow pb-3'
        } flex-col justify-start items-center space-y-3`}
      >
        <div
          className="w-14 h-14 rounded-lg bg-gradient hover:bg-gradient-700 flex items-center justify-center cursor-pointer"
          onClick={handleClick}
          onMouseEnter={() => (handleHoverIn ? handleHoverIn(item) : {})}
        >
          <GetSvgIcon
            isActive
            iconName={item?.name}
            width={32}
            height={32}
            activeColor="white"
          />
        </div>
        <p
          className={`text-sm text-center font-semibold hover:bg-gradient-700 hover:text-transparent hover:bg-clip-text `}
        >
          {item?.name}
        </p>
      </div>
    </>
  );
};
