import React, { useState } from 'react';
import Header from './header/Header';
import Sidebar from './sidebar/Sidebar';
//import { useSelector } from 'react-redux';
function Layout({ children, bgColor }) {
  //const theme = useSelector((state) => state.theme);
  const mainContent = React.useRef(null);
  const [isCollapsed, setIsCollapsed] = useState(true);

  return (
    <div className={`${bgColor} flex flex-col h-screen`} ref={mainContent}>
      {/* Header here  */}
      <div className="fixed left-0 top-0 h-14 w-full" style={{ zIndex: '99' }}>
        <Header />
      </div>
      {/* Sidebar start  */}
      <div className="flex mt-16 relative top-0 overflow-y-hidden h-[calc(100vh-4rem)]">
        <div
          onMouseOut={() => setIsCollapsed(true)}
          onMouseOver={() => setIsCollapsed(false)}
          className={`absolute z-[90]   h-screen overflow-y-auto  bg-gray-50 grow-0 transition-all duration-900 hover:w-[266px] w-20
           ease-out   `}
        >
          <Sidebar isCollapsed={isCollapsed} />
        </div>
        {/* Sidebar ends  */}
        {/* main area   */}
        <div
          className={`ml-[5rem] grow  relative overflow-auto   h-[calc(100vh-4rem)] bg-gray-50 `}
        >
          {children}
        </div>
      </div>
    </div>
  );
}

export default Layout;
