import { AxiosError } from 'axios';
import { del, get, post } from '../../apiClient/apiCalls';
import * as CONSTANT from '../api.constant';

export const getQuickLinks = async ({ isAdmin }) => {
  try {
    const res = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_SURFACE_V2}/quick-links/get-quickLinks?isAdmin=${isAdmin}`,
    );
    return res?.data;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error!');
    }
  }
};

export const createQuickLinks = async (payload) => {
  try {
    const res = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_SURFACE_V2}/quick-links/create-quickLinks`,
      payload,
      'multipart/form-data',
    );
    return res?.data;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error!');
    }
  }
};

export const updateQuickLinks = async ({ id, payload }) => {
  try {
    const res = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_SURFACE_V2}/quick-links/update-quickLinks/${id}`,
      payload,
      'multipart/form-data',
    );
    return res?.data;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error!');
    }
  }
};

export const deleteQuickLinks = async (id) => {
  try {
    const res = await del(
      `${CONSTANT.API_URL}/${CONSTANT.API_SURFACE_V2}/quick-links/delete-quickLinks/${id}`,
    );
    return res?.data;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error!');
    }
  }
};

export const getModuleBanner = async ({ module }) => {
  try {
    const res = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_SURFACE_V2}/module-banner/get-moduleBanner?module=${module}`,
    );
    return res?.data;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error!');
    }
  }
};

export const createModuleBanner = async (payload) => {
  try {
    const res = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_SURFACE_V2}/module-banner/create-moduleBanner`,
      payload,
      'multipart/form-data',
    );
    return res?.data;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error!');
    }
  }
};

export const updateModuleBanner = async (payload) => {
  try {
    const res = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_SURFACE_V2}/module-banner/update-moduleBanner`,
      payload,
      'multipart/form-data',
    );
    return res?.data;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error!');
    }
  }
};

export const getYourAppsDropDownValues = async ({ search }) => {
  try {
    const res = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_SURFACE_V2}/quick-links/get-your-apps-drop-down?search=${search}`,
    );
    return res?.data;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error!');
    }
  }
};
