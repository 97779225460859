import { useEffect, useState } from 'react';
/**
 * Component to display a graph indicating employee salary positioning.
 * @param {Object} props - Component props
 * @param {number} props.employeeSalary - Employee's salary
 * @param {Object} props.data - Object containing min and max salary data
 * @param {number} props.averageSalary - Average salary
 * @return {JSX.Element} SalaryPositioningGraph component
 */
const EmployeeSalaryGraph = ({ data, averageSalary }) => {
  const [employeSalaryPosition, setEmployeSalaryPosition] = useState();
  const [avgSalaryIndicator, setAvgSalaryIndicator] = useState();
  useEffect(() => {
    const min = parseFloat(data?.min);
    const max = parseFloat(data?.max);
    // if (
    //   employeeSalary !== undefined &&
    //   min !== undefined &&
    //   max !== undefined
    // ) {
    //   //   setEmployeSalaryPosition(
    //   //     `${
    //   //       employeeSalary < min
    //   //         ? '3'
    //   //         : employeeSalary > max
    //   //         ? '98'
    //   //         : `${Math.min(
    //   //             Math.max(((employeeSalary - min) / (max - min)) * 100, 5),
    //   //             95,
    //   //           )}`
    //   //     }%`,
    //   //   );
    // }
    if (averageSalary !== undefined && min !== undefined && max !== undefined) {
      setAvgSalaryIndicator(
        `${
          averageSalary < min
            ? '3'
            : averageSalary > max
            ? '98'
            : `${Math.min(
                Math.max(((averageSalary - min) / (max - min)) * 100, 5),
                95,
              )}`
        }%`,
      );
    }
  }, [data, averageSalary]);
  return (
    <div className="relative flex items-center h-[10px] bg-gray-100 rounded-lg">
      <div
        className="w-[5%] h-[10px] bg-gray-50 border border-r-0 rounded-l-lg rounded-r-none flex items-center"
        style={{
          borderColor: 'rgba(213, 217, 235, 0.5)',
        }}
      ></div>
      <div
        className="w-[90%] h-[10px] border rounded-lg flex items-center"
        style={{
          backgroundColor: 'rgba(213, 217, 235, 0.5)',
          borderColor: 'rgba(113, 123, 188, 0.5)',
        }}
      >
        <div
          className={`w-[3px] bg-black-400 h-[14px] rounded-lg absolute`}
          style={{ left: avgSalaryIndicator }}
        ></div>
      </div>
      <div className="w-[5%] h-[10px] bg-gray-100 border border-l-0 rounded-r-lg rounded-l-none flex items-center"></div>
    </div>
  );
};

export default EmployeeSalaryGraph;
