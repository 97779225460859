import { DocumentTextIcon } from '@heroicons/react/24/outline';
import CloseIcon from 'assets/svg/closeIcon';
import React from 'react';

const SelectedFile = ({ file, onRemoveImage }) => {
  return (
    <div className="flex items-center gap-4 max-w-[600px]">
      <div className="border rounded-xl p-3 flex items-center justify-center gap-4 min-w-fit bg-gray-50">
        <div className="flex items-center gap-1">
          <DocumentTextIcon className="w-10 h-10" color="gray" />
        </div>
        <div>
          <p
            className="text-sm font-medium text-gray-700"
            title={
              file?.name ||
              file?.file?.name ||
              file?.file?.caption ||
              file?.caption ||
              file?.title
            }
          >
            {`${
              file?.name?.slice(0, 30) ||
              file?.file?.name?.slice(0, 30) ||
              file?.file?.caption?.slice(0, 30) ||
              file?.caption?.slice(0, 30) ||
              file?.title?.slice(0, 30)
            }${
              file?.name?.length > 30 ||
              file?.file?.name?.length > 30 ||
              file?.file?.caption?.length > 30 ||
              file?.caption?.length > 30 ||
              file?.title?.length > 30
                ? '...'
                : ''
            }`}
          </p>
          <p className="text-xs text-gray-600">
            {(file?.size || file?.file?.size) &&
              `${
                file?.size
                  ? Math.floor(file?.size / 1024)
                  : Math.floor(file?.file?.size / 1024)
              } KB`}
          </p>
        </div>
        <div className="self-start">
          <CloseIcon className="cursor-pointer" onClick={onRemoveImage} />
        </div>
      </div>
    </div>
  );
};

export default SelectedFile;
