import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  showSidepanel: false,
  sidePanelType: 'Section',
  selectedSection: null,
  selectedColumn: null,
  sidePanelTitle: 'Add Elements',
  landingPageData: null,
  modeLabel: 'Create',
};

const templateBuilderSlice = createSlice({
  name: 'templateBuilder',
  initialState,
  reducers: {
    toggleShowSidepanel: (state, action) => {
      state.showSidepanel = action.payload;
      return state;
    },
    toggleSidePanelType: (state, action) => {
      state.sidePanelType = action.payload;
      return state;
    },
    setSidePanelTitle: (state, action) => {
      state.sidePanelTitle = action.payload;
      return state;
    },
    setSelectedSection: (state, action) => {
      state.selectedSection = action.payload;
      return state;
    },
    setSelectedColumn: (state, action) => {
      state.selectedColumn = action.payload;
      return state;
    },
    setLandingPageData: (state, action) => {
      state.landingPageData = action.payload;
      return state;
    },
    setModeLabel: (state, action) => {
      state.modeLabel = action.payload;
      return state;
    },
  },
});

export default templateBuilderSlice.reducer;
export const templateBuilderSelector = (state) => state.templateBuilder;
export const {
  toggleShowSidepanel,
  toggleSidePanelType,
  setSelectedColumn,
  setSelectedSection,
  setSidePanelTitle,
  setLandingPageData,
  setModeLabel,
} = templateBuilderSlice.actions;
