const ManageLetterTemplatesIcon = (props) => (
  <svg
    width={props?.width ? props?.width : '20'}
    height={props?.height ? props?.height : '20'}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.6667 1.66675H7.33334C5.93321 1.66675 5.23315 1.66675 4.69837 1.93923C4.22796 2.17892 3.84551 2.56137 3.60583 3.03177C3.33334 3.56655 3.33334 4.26662 3.33334 5.66675V14.3334C3.33334 15.7335 3.33334 16.4336 3.60583 16.9684C3.84551 17.4388 4.22796 17.8212 4.69837 18.0609C5.23315 18.3334 5.93321 18.3334 7.33334 18.3334H12.6667C14.0668 18.3334 14.7669 18.3334 15.3017 18.0609C15.7721 17.8212 16.1545 17.4388 16.3942 16.9684C16.6667 16.4336 16.6667 15.7335 16.6667 14.3334V6.66675M11.6667 1.66675L16.6667 6.66675M11.6667 1.66675V6.66675H16.6667M11.4583 11.6667V10.2084C11.4583 9.403 10.8054 8.75008 10 8.75008C9.19459 8.75008 8.54168 9.403 8.54168 10.2084V11.6667M8.41668 15.0001H11.5833C12.0501 15.0001 12.2834 15.0001 12.4617 14.9093C12.6185 14.8294 12.746 14.7019 12.8258 14.5451C12.9167 14.3668 12.9167 14.1335 12.9167 13.6667V13.0001C12.9167 12.5334 12.9167 12.3 12.8258 12.1218C12.746 11.965 12.6185 11.8375 12.4617 11.7576C12.2834 11.6667 12.0501 11.6667 11.5833 11.6667H8.41668C7.94997 11.6667 7.71661 11.6667 7.53835 11.7576C7.38155 11.8375 7.25407 11.965 7.17417 12.1218C7.08334 12.3 7.08334 12.5334 7.08334 13.0001V13.6667C7.08334 14.1335 7.08334 14.3668 7.17417 14.5451C7.25407 14.7019 7.38155 14.8294 7.53835 14.9093C7.71661 15.0001 7.94997 15.0001 8.41668 15.0001Z"
      stroke={
        props.isActive
          ? props.activeColor
          : props.isHovered
          ? props.hoveredColor
          : '#475467'
      }
      strokeWidth="1.67"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default ManageLetterTemplatesIcon;
