import React from 'react';
import { useFormContext } from 'react-hook-form';
const DataCell = ({ value }) => {
  const { watch } = useFormContext();
  const [showAmountInMillions] = watch(['showAmountInMillions']);
  const decimalPlaces = 2; // Number of decimal places to display

  const convertToMillions = (val) => val / 1000000;
  const convertToLakhs = (val) => val / 100000;

  const displayValue = showAmountInMillions
    ? value
      ? convertToMillions(+value).toString()
      : '0'
    : value
    ? convertToLakhs(+value).toString()
    : '0';

  const truncatedValue = displayValue.includes('.')
    ? displayValue.split('.')[0] +
      '.' +
      displayValue.split('.')[1].slice(0, decimalPlaces)
    : displayValue;

  return (
    <div className="flex items-center justify-center text-gray-600 text-sm">
      {truncatedValue}
    </div>
  );
};

const TableRowSection = ({
  title,
  data,
  dataForComparision,
  selectedSideTab,
  simulationTitle,
  ComparisionTitle,
}) => {
  return (
    <>
      <div className="flex gap-2">
        <div className="w-2/12 p-2 rounded-lg bg-gray-100 border border-gray-200 flex items-center justify-center text-gray-600 text-sm">
          {title}
        </div>
        <div className="flex flex-col gap-2 w-8/12">
          <div className="w-full p-2 rounded-lg bg-white border border-gray-200 grid grid-cols-4 gap-2">
            {data.map((item, index) => (
              <DataCell key={index} value={item} />
            ))}
          </div>
          {selectedSideTab === 'Comparision' && (
            <div className="w-full p-2 rounded-lg bg-gray-50 border border-gray-200 grid grid-cols-4 gap-2">
              {dataForComparision.map((item, index) => (
                <DataCell key={index} value={item} />
              ))}
            </div>
          )}
        </div>
        <div className="flex flex-col gap-2 w-2/12">
          <div className="w-full p-2 rounded-lg bg-white border border-gray-200 flex items-center justify-center text-gray-600 text-sm">
            {simulationTitle}
          </div>
          {selectedSideTab === 'Comparision' && (
            <div className="w-full p-2 rounded-lg bg-gray-50 border border-gray-200 flex items-center justify-center text-gray-600 text-sm">
              {ComparisionTitle}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default TableRowSection;
