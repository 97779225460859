import { RangeBuilder } from 'pages/rangeBuilder/RangeBuilder';
import { BenchmarkPlans } from 'pages/rangeBuilder/benchmarkPlans/BenchmarkPlans';
import { CreatePlan } from 'pages/rangeBuilder/internalRange/createPlan/CreatePlan';

export const RangeBuilderRoutes = [
  {
    type: 'collapse',
    name: 'InternalRange',
    key: 'InternalRange',
    module: 'RangeBuilder',
    route: '/range-builder/plan/:id',
    parentId: 'RangeBuilder',
    component: <RangeBuilder />,
  },
  {
    type: 'collapse',
    name: 'CreatePlan',
    key: 'CreatePlan',
    module: 'RangeBuilder',
    route: '/range-builder/create-plan',
    parentId: 'RangeBuilder',
    component: <CreatePlan />,
  },
  {
    type: 'collapse',
    name: 'EditPlan',
    key: 'EditPlan',
    module: 'RangeBuilder',
    route: '/range-builder/plan/update/:planId',
    parentId: 'RangeBuilder',
    component: <CreatePlan />,
  },
  {
    type: 'collapse',
    name: 'BenchmarkPlans',
    key: 'BenchmarkPlans',
    module: 'RangeBuilder',
    route: '/range-builder/plans',
    parentId: 'RangeBuilder',
    component: <BenchmarkPlans />,
  },
];
