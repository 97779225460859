const { createSlice } = require('@reduxjs/toolkit');

const initialState = {
  polls: {},
};

const pollsSlice = createSlice({
  name: 'polls',
  initialState,
  reducers: {
    addPoll: (state, action) => {
      state.polls = [...state.polls, { ...action.payload.poll }];
    },
    setPolls: (state, action) => {
      state.polls = action.payload.polls;
    },
    updatePoll: (state, action) => {
      console.log(action.payload);
      state.polls = state.polls.map((poll) => {
        if (poll.id === action.payload.poll.id) {
          return { ...action.payload.poll };
        }
        return poll;
      });
    },
  },
});

export const { addPoll, setPolls, updatePoll } = pollsSlice.actions;
export default pollsSlice.reducer;
export const pollsSelector = (state) => state.polls;
