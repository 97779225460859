import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { handleErrorImage } from 'utils/handleErrorImage';

const Carousel = ({
  slides,
  autoSlideInterval = 2000,
  height,
  rounded,
  border,
  title,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const goToPrevious = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const goToNext = () => {
    if (currentIndex < slides.length - 1) {
      setCurrentIndex(currentIndex + 1);
    } else setCurrentIndex(0);
  };

  const goToSlide = (slideIndex) => {
    setCurrentIndex(slideIndex);
  };

  const handleClick = () => {
    navigate(slides[currentIndex]?.thumbnailUrl);
  };

  useEffect(() => {
    const slideInterval = setInterval(goToNext, autoSlideInterval);
    return () => clearInterval(slideInterval);
  });

  const navigate = useNavigate();

  return (
    <div
      className={`relative  w-full ${height ? height : 'h-[187px]'} ${
        border ? 'border-4 border-white' : ''
      } ${rounded ? rounded : ''} shadow-md`}
    >
      <div className="h-full ">
        {/* {slides.length > 1 && (
          <>
            <div
              style={{ transform: "translate(0, -50%)" }}
              onClick={goToPrevious}
              className="absolute z-10 p-1 text-5xl text-white duration-200 cursor-pointer bg-slate-200 hover:bg-white/50 rounded-lg top-2/4 left-2"
            >
              <ChevronLeftIcon className="w-6 h-6 text-orange-500" />
            </div>
            <div
              style={{ transform: "translate(0, -50%)" }}
              onClick={goToNext}
              className="absolute z-10 p-1 text-5xl text-white duration-200 cursor-pointer bg-slate-200 hover:bg-white/50 rounded-lg top-2/4 right-2"
            >
              <ChevronRightIcon className="w-6 h-6 text-orange-500" />{" "}
            </div>
          </>
        )}{" "} */}
        <div
          onClick={handleClick}
          className={`object-contain w-full h-full duration-500 ${
            title ? 'bg-black' : 'bg-gray-200'
          } bg-contain ${
            rounded ? rounded : ''
          } flex justify-center items-center `}
        >
          {slides?.length ? (
            <img
              src={slides[currentIndex]?.url}
              key={currentIndex}
              alt="announcements"
              onError={handleErrorImage}
              className={`h-full object-cover w-full ${
                title ? 'opacity-40' : ''
              }  ${rounded ? rounded : ''}`}
            />
          ) : (
            ' Loading Banners...'
          )}
        </div>
        {title && (
          <p className="absolute w-full text-center top-[4.625rem] text-3xl font-semibold text-white">
            {slides[currentIndex]?.title}
          </p>
        )}
        <div className="absolute left-0 right-0 flex items-center justify-center bottom-4">
          {slides.length > 1 &&
            slides.map(
              (
                slide,
                slideIndex, // not to show carousel dots if slides length is l.
              ) => (
                <div
                  key={slideIndex}
                  className="mx-1 my-0 border border-white bg-gray-200 rounded-full cursor-pointer"
                  onClick={() => {
                    goToSlide(slideIndex);
                  }}
                >
                  <div
                    className={`w-2 h-2 bg-gray-900 rounded-full ${
                      currentIndex === slideIndex ? 'p-[1.5px]' : 'opacity-50'
                    } `}
                  ></div>
                </div>
              ),
            )}
        </div>
      </div>
    </div>
  );
};

export default Carousel;
