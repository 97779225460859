import BasicCard from 'components/layout/cardLayout/CardLayout';
import { useGetMyBadgesById } from 'query/profile/summary';
import { useGetAllAnnouncement } from 'query/surface/announcement';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { imageURLCheck } from 'utils/ImageUrlCheck';
import { handleErrorImage } from 'utils/handleErrorImage';
import { htmlParser, truncateText } from 'utils/jsUtils';
import displayPostTime from 'utils/postTime';

const Announcements = () => {
  const user = useSelector((state) => state.user);
  const { data, isLoading } = useGetAllAnnouncement(3, 0, {});

  return (
    <div className="flex w-full flex-col gap-3 p-4 border rounded-xl shadow-md border-gray-200 bg-white">
      <div className="flex w-full pb-2 justify-between">
        <p className="text-sm/5 font-semibold text-gray-900">Announcements</p>
        <Link
          to={'/surface/announcements/Announcements'}
          className="text-sm font-semibold bg-clip-text bg-gradient-to-br text-transparent from-primaryLeft to-primaryRight"
        >
          See all
        </Link>
      </div>
      <div className="flex flex-col gap-5  items-center ">
        {data?.data?.slice(0, 3)?.map((announcement) => {
          return (
            <Link
              key={announcement?.id}
              to={`/announcement/${announcement?.id}`}
              className="flex w-full"
            >
              <div
                key={announcement?.id}
                className="flex w-full flex-col gap-2 border border-gray-200 rounded-lg  py-4 px-4 "
              >
                <div className="flex flex-col gap-0.5">
                  <div className="flex flex-col gap-0.5">
                    <p className="text-sm/5 font-semibold text-gray-900">
                      {announcement?.title}
                    </p>
                    <p className="text-xs font-normal text-gray-500">
                      {displayPostTime(announcement?.createdDate)}
                    </p>
                  </div>
                  <div className="text-sm/5 font-normal text-gray-500">
                    {htmlParser(truncateText(announcement?.message, 57), {
                      replace: (domNode) => {
                        if (domNode.type === 'tag' && domNode.name === 'a') {
                          const href = domNode.attribs.href;
                          if (
                            href &&
                            !href.startsWith('https://') &&
                            !href.startsWith('http://')
                          ) {
                            domNode.attribs.href = 'https://' + href;
                          }
                          domNode.attribs.style = 'color: blue';
                        }
                        return domNode;
                      },
                    })}
                  </div>
                </div>
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default Announcements;
