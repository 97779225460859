export const initialState = {
  selectedFiles: [],
  finalInputFiles: [],
  errorMessage: '',
  filteredEmployeeData: [],
  searchQuery: '',
};
const postReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_SELECTED_FILES':
      return { ...state, selectedFiles: action.payload };
    case 'SET_FINAL_INPUT_FILES':
      return { ...state, finalInputFiles: action.payload };
    case 'SET_ERROR_MESSAGE':
      return { ...state, errorMessage: action.payload };
    case 'SET_SEARCH_QUERY':
      return { ...state, searchQuery: action.payload };
    case 'SET_FILTERED_EMPLOYEE_DATA':
      return { ...state, filteredEmployeeData: action.payload };
    case 'SET_STATE':
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default postReducer;
