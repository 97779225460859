import {
  ArrowDownTrayIcon,
  ArrowUpTrayIcon,
  XCircleIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import {
  downloadBulkUserInEligibility,
  uploadBulkUserInEligibility,
} from 'apiClient/compensation/compensation';
import CircularLoaderSmall from 'assets/svg/CircularLoaderSmall';
import DeleteFormIcon from 'assets/svg/deleteFormIcon';
import TableIcon from 'assets/svg/tableIcon';
import TickIcon from 'assets/svg/tickIcon';
import { Input } from 'components/atoms/FormElements/input/Input';
import Select from 'components/atoms/FormElements/select/Select';
import Button from 'components/atoms/button/Button';
import ProgressBar from 'components/atoms/progressBar/ProgressBarLoader';
import Timeline from 'components/atoms/timeline/Timeline';
import ModalPopup from 'components/layout/modalPopup/ModalPopup';
import FileUploader from 'pages/policy/create/mainPanel/policySteps/FileUploader';
import { useEffect, useState } from 'react';
import { downloadDataToCsv } from 'utils/downloadDataToCsv';

export default function BulkUserUploadForEligibility({
  addBulkUsers,
  planType,
  planId,
  type,
  value,
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [percentage, setPercentage] = useState(0);
  const [isdownloading, setIsDownloading] = useState({
    isLoading: false,
    data: null,
    error: null,
  });
  const [validation, setValidation] = useState({
    isLoading: false,
    data: null,
    error: null,
  });
  const [data, setData] = useState({
    itemStyle: 'dot',
    bulletDefaultColor: '#D0D5DD',
    bulletActiveColor: 'var(--primaryRight)',
    barColor: '#D0D5DD',
    itemList: [
      {
        id: '1',
        isBulletActive: false,
        title: 'Download Template ',
      },
      {
        id: '2',
        isBulletActive: false,
        title: 'Upload the CSV file for column type upload ',
      },
      {
        id: '3',
        isBulletActive: false,
        title: 'Validating Uploaded File',
      },
    ],
  });

  useEffect(() => {
    if (file) {
      const formData = new FormData();
      formData.append('file', file);
      const totalSize = file.size;
      let uploadedSize = 0;

      const uploadInterval = setInterval(() => {
        if (uploadedSize < totalSize) {
          uploadedSize += 1024;
          const percentage =
            (uploadedSize / totalSize) * 100 > 100
              ? 100
              : (uploadedSize / totalSize).toFixed(2) * 100;
          setPercentage(percentage);
        } else {
          clearInterval(uploadInterval);
        }
      }, 100);
      const updatedItemStatus = data.itemList.map((item) => {
        if (item?.id === '2' || item?.id === '1') {
          return { ...item, isBulletActive: true };
        } else return item;
      });

      setData({ ...data, itemList: updatedItemStatus });
    } else {
      const updatedItemStatus = data.itemList.map((item) => {
        if (item?.id === '2' || item?.id === '3') {
          return { ...item, isBulletActive: false };
        } else return item;
      });

      setData({ ...data, itemList: updatedItemStatus });
    }
  }, [file]);

  useEffect(() => {
    if (percentage === 100) {
      const interval = setInterval(() => {
        clearInterval(interval);
      }, 1000);
    }
  }, [percentage]);
  const handleCondition1Change = () => {};
  const renderRow = () => {
    return (
      <div className="box relative flex items-center ">
        <div className="bg-white border mt-4 p-2 py-3 h-auto min-h-16 max-h-24 shadow rounded-lg w-full">
          <div className="flex items-center justify-between w-full">
            <div className="grid grid-cols-3 w-[95%] pl-3 items-center justify-between gap-6">
              <div>
                <Select
                  options={[{ value: 'user', label: 'Employees' }]}
                  className={`focus:outline-none rounded-lg w-full`}
                  isMulti={false}
                  label=""
                  value={{ value: 'user', label: 'Employees' }}
                  onChange={handleCondition1Change}
                />
              </div>
              <div className="col-span-2">
                <Input
                  className="text-sm font-medium text-gray-900"
                  // label={field?.fieldLabel}
                  placeholder={
                    value?.length > 0
                      ? 'User Ids added successfully.'
                      : "Upload Csv file with Id's ( Employee 1, Employee 2,..)"
                  }
                  type="text"
                  required
                  disabled={true}

                  //  onChange={(event) => handleSystemFieldsChange(event, field)}
                />{' '}
              </div>
            </div>
            <div
              className="p-2 flex justify-center cursor-pointer"
              onClick={() => setIsModalOpen(true)}
            >
              <ArrowUpTrayIcon className="w-5 h-5" />
            </div>
            <div
              className="p-2 flex justify-center cursor-pointer"
              onClick={() => {
                addBulkUsers({ type: type }, true);
              }}
            >
              <XMarkIcon className="w-5 h-5" />
            </div>
          </div>
        </div>
      </div>
    );
  };
  useEffect(() => {
    if (file && percentage === 100) {
      setValidation({
        isLoading: true,
        data: null,
        error: null,
      });
      const formData = new FormData();
      formData.append('excel_file', file);
      formData.append('planId', planId);
      formData.append('planType', planType);
      formData.append('type', type);

      const updatedItemStatus = data.itemList.map((item) => {
        if (item?.id === '3') {
          return { ...item, isBulletActive: true };
        } else return item;
      });

      setData({ ...data, itemList: updatedItemStatus });

      uploadBulkUserInEligibility(formData)
        .then((res) => {
          setValidation({
            isLoading: false,
            data: res,
            error: null,
          });
        })
        .catch((err) => {
          setValidation({
            isLoading: false,
            data: null,
            error: err?.message,
          });
        });
    }
  }, [file, percentage]);
  const downloadFile = () => {
    setIsDownloading({
      isLoading: true,
      data: null,
      error: null,
    });
    // setValidation({
    //   isLoading: false,
    //   data: null,
    //   error: null,
    // });
    const formData = new FormData();
    formData.append('excel_file', file);
    formData.append('planId', planId);
    formData.append('planType', planType);
    formData.append('type', type);
    downloadBulkUserInEligibility(formData)
      .then((res) => {
        const blob = new Blob([res], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `worksheet.csv`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
        setIsDownloading({
          isLoading: false,
          data: res,
          error: null,
        });
      })
      .catch((err) => {
        setIsDownloading({
          isLoading: false,
          data: null,
          error: true,
        });
      });
  };
  const DownloadTemplateUI = (key) => {
    return (
      <div key={key}>
        <div className="h-auto py-4">
          <Button
            onClick={() => {
              const updatedItemStatus = data.itemList.map((item) => {
                if (item?.id === '1') {
                  return { ...item, isBulletActive: true };
                } else return item;
              });

              setData({ ...data, itemList: updatedItemStatus });
              return downloadDataToCsv(
                'employeeId',
                'Bulk User Upload Template',
              );
            }}
            className=" !py-1 !px-2 text-sm !rounded-md "
          >
            <div className="flex gap-2 items-center ">
              <ArrowDownTrayIcon className="w-4 h-4 " />{' '}
              <p>Download Template</p>
            </div>
          </Button>
        </div>
      </div>
    );
  };
  function renderFileUpload() {
    return (
      <div className="py-4">
        {file ? (
          <div>
            {/* <Button type="outline" className="mb-2">
                      Re-Upload
                    </Button> */}
            <div className="flex p-4 gap-4 rounded-lg gradient-border w-full h-full">
              <div className="w-8 h-8 rounded-full bg-gradient flex items-center justify-center p-2">
                <TableIcon />
              </div>
              <div className="w-full">
                <div className="flex items-center justify-between w-full">
                  <p className="font-medium text-sm text-gray-700">
                    {file?.name}
                  </p>

                  <div
                    className="cursor-pointer"
                    onClick={() => {
                      setFile(null);
                      setPercentage(0);
                    }}
                  >
                    <DeleteFormIcon />
                  </div>
                </div>
                <p className="text-sm text-gray-600">{`${
                  file?.size < 1024
                    ? `${file?.size} Bytes`
                    : file?.size < 1024 * 1024
                    ? `${(file?.size / 1024).toFixed(2)} KB`
                    : `${(file?.size / (1024 * 1024)).toFixed(2)} MB`
                }`}</p>
                <div className="flex items-center justify-between w-full gap-3">
                  <div className="w-full">
                    <ProgressBar percentage={percentage} />
                  </div>
                  <p className="font-medium text-sm text-gray-700">{`${percentage}%`}</p>
                </div>
              </div>
            </div>
            {/* 
            {validation?.data && (
              <div>
                <div className="h-0.5 w-full bg-gray-200 my-4"> </div>{' '}
                <div className="flex justify-end gap-4">
                  <Button
                    onClick={() => {
                      addBulkUsers({ ...validation?.data, type: type });
                      // setValue(
                      //   validation?.data?.[
                      //     'includeUserEligibleGroup'
                      //   ]?.['id-in']?.[1] ||
                      //     validation?.data?.[
                      //       'excludeUserEligibleGroup'
                      //     ]?.['id-in']?.[1],
                      // );
                      setIsModalOpen(false);
                      setFile(null);
                      setPercentage(0);
                      setValidation({
                        isLoading: false,
                        data: null,
                        error: null,
                      });
                    }}
                    variant="filled"
                  >
                    Insert
                  </Button>
                </div>
              </div>
            )} */}
          </div>
        ) : (
          <FileUploader
            acceptedTypes={['.csv']}
            maxSize={50 * 1024 * 1024}
            subText="Upload CSV file (max. 6000 rows)"
            onFileSelect={(file) => setFile(file)}
          />
        )}
      </div>
    );
  }
  function validatingFile() {
    return (
      percentage === 100 &&
      file && (
        <div className="flex flex-col p-4 gap-4 rounded-lg gradient-border w-full h-full mt-4 justify-center mx-auto">
          <div className="flex gap-4 items-center  ">
            <div
              className={`w-4 h-4 rounded-full  ${
                validation?.isLoading ? 'bg-gray-400 ' : 'bg-lime-500'
              }  flex items-center justify-center`}
            >
              <TickIcon />
            </div>
            <p>Validating your file...</p>
          </div>

          {!validation?.isLoading && (
            <div>
              <div className="flex gap-4 items-center  ">
                <div
                  className={`w-4 h-4 rounded-full  ${
                    validation?.data ? 'bg-lime-500' : 'bg-red-400 '
                  }  flex items-center justify-center`}
                >
                  <TickIcon />
                </div>

                {validation?.data ? (
                  <p>Validation Completed</p>
                ) : (
                  <p>Validation Failed</p>
                )}
              </div>
              <div className="mt-4">
                {validation?.error &&
                validation?.error?.includes('Headers employeeId') ? (
                  <p>
                    File is missing with cell header{' '}
                    <span className="font-bold text-xl">employeeId </span>
                  </p>
                ) : validation?.error?.includes('empty') ? (
                  <p>Upload file is empty</p>
                ) : (
                  <p></p>
                )}
                {/* {validation?.data?.totalNotMatchIncludeUserId >
                        0 ? ( */}
                <p>
                  <ul>
                    {!validation?.error && (
                      <li className="flex gap-4 items-center mb-3">
                        <div
                          className={`w-4 h-4 rounded-full bg-lime-500 flex items-center justify-center`}
                        >
                          <TickIcon />
                        </div>
                        Valid Ids:
                        {validation?.data?.includeUserEligibleGroup?.[
                          'id-in'
                        ]?.[1]?.length ||
                          validation?.data?.excludeUserEligibleGroup?.[
                            'id-in'
                          ]?.[1]?.length}
                      </li>
                    )}
                    {validation?.data?.totalNotMatchIncludeUserId > 0 ||
                    validation?.data?.totalNotMatchExcludeUserId > 0 ? (
                      <>
                        <li className="flex gap-4 items-center">
                          <div
                            className={`w-4 h-4 rounded-full bg-gradient flex items-center justify-center`}
                          >
                            <XCircleIcon className="w-4 h-4 font-semibold text-white " />
                          </div>
                          Invalid Ids:
                          {validation?.data?.totalNotMatchIncludeUserId ||
                            validation?.data?.totalNotMatchExcludeUserId}
                        </li>
                        <p className="flex gap-2">
                          <span
                            className="underline cursor-pointer pl-2 text-red-400"
                            onClick={() => {
                              downloadFile();
                            }}
                          >
                            Click Here{' '}
                          </span>{' '}
                          to download the report.
                          {isdownloading?.isLoading && (
                            <span className="flex">
                              <CircularLoaderSmall />
                            </span>
                          )}
                        </p>
                      </>
                    ) : null}
                  </ul>
                </p>
              </div>
            </div>
          )}
        </div>
      )
    );
  }
  return (
    <div
      className={` p-4 border w-full   bg-gray-50 border-gray-300 rounded-lg `}
    >
      <div>
        {renderRow()}
        {isModalOpen && (
          <ModalPopup
            title={'Upload Users'}
            isModalOpen={isModalOpen}
            height={800}
            onClose={() => setIsModalOpen(false)}
            body={
              <div className="p-6 w-full flex flex-col justify-center">
                <Timeline
                  data={data}
                  bodyData={[
                    <DownloadTemplateUI key="23" />,
                    renderFileUpload(),
                    validatingFile(),
                  ]}
                />
                <div className="h-0.5 w-full bg-gray-200 mt-2 " />
                <div className="re bottom-0 w-full flex gap-4 justify-end  items-center mt-4  ">
                  <div
                    className="px-3 py-2  text-sm font-semibold hover:cursor-pointer"
                    onClick={() => {
                      document.body.classList.remove('overflow-y-hidden');
                      setIsModalOpen(false);
                    }}
                  >
                    Cancel
                  </div>
                  <Button
                    onClick={() => {
                      addBulkUsers({ ...validation?.data, type: type });
                      // setValue(
                      //   validation?.data?.[
                      //     'includeUserEligibleGroup'
                      //   ]?.['id-in']?.[1] ||
                      //     validation?.data?.[
                      //       'excludeUserEligibleGroup'
                      //     ]?.['id-in']?.[1],
                      // );
                      setIsModalOpen(false);
                      setFile(null);
                      setPercentage(0);
                      setValidation({
                        isLoading: false,
                        data: null,
                        error: null,
                      });
                    }}
                    variant="filled"
                    disabled={
                      validation?.data?.totalNotMatchExcludeUserId > 0 ||
                      !file ||
                      validation?.error
                        ? true
                        : false
                    }
                    // className={`${
                    //   file && percentage === 100
                    //     ? '!bg-gradient'
                    //     : 'bg-gray-400 disabled hover:cursor-not-allowed'
                    // } px-3 py-2 text-white text-sm font-semibold rounded-lg hover:cursor-pointer`}
                  >
                    Insert
                  </Button>
                </div>
              </div>
            }
          />
        )}
      </div>
    </div>
  );
}
