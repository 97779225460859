// import { useState, useEffect } from "react";

export const tabs = [
  {
    id: 1,
    tabLabel: 'Live',
    value: 'live',
  },
  {
    id: 2,
    tabLabel: 'Drafts',
    value: 'drafts',
  },
  {
    id: 2,
    tabLabel: 'Archived',
    value: 'archived',
  },
];

export const modalType = {
  ARCHIVE: 'ARCHIVE',
  RE_LAUNCH: 'RE_LAUNCH',
  DELETE: 'DELETE',
};

export const manageProgramType = {
  STANDARD: 'standard',
  FLEX_AMOUNT: 'flex-amount',
  FLEX_DEDUCTION: 'flex-deduction',
};
