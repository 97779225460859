import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

const ViewButtonAtom = (props) => {
  const { columnData } = props;

  const metaData =
    columnData?.attr?.button?.metaData || columnData?.button?.metaData;
  const flex = columnData?.attr?.flex?.justify || 'end';

  const navigate = useNavigate();

  return (
    <div
      style={{ justifyContent: flex ?? 'end' }}
      className="w-full flex flex-col items-start"
    >
      {metaData?.link ? (
        <button
          onClick={() => navigate('/' + metaData?.link)}
          style={{
            backgroundColor: metaData?.buttonColor,
            borderColor: metaData?.outlineColor,
            color: metaData?.textColor,
          }}
          className={`rounded-lg p-2 border`}
        >
          {columnData?.attr?.button?.value || columnData?.button?.value}
        </button>
      ) : (
        <button
          style={{
            backgroundColor: metaData?.buttonColor,
            borderColor: metaData?.outlineColor,
            color: metaData?.textColor,
          }}
          className={`rounded-lg p-2 border`}
        >
          {columnData?.attr?.button?.value || columnData?.button?.value}
        </button>
      )}
    </div>
  );
};

export default ViewButtonAtom;
