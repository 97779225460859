import { getChartTable } from 'apiClient/dashboardBuilder/DashboardBuilder';
import MoveIcon from 'assets/svg/moveIcon';
import Button from 'components/atoms/button/Button';
import { CheckBoxProgram } from 'components/atoms/FormElements/input/Input';
import Select from 'components/atoms/FormElements/select/Select';
import TableSkeleton from 'components/atoms/skeletonLoaderTypes/TableSkeleton';
import {
  useGetChartData,
  useGetDimensions,
  useGetMeasures,
  useUpdateChart,
} from 'query/dashboard/ManageDashboard';
import { useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { onResponse } from 'utils/toastMessages';

export default function DashboardChartSorting({
  configFor,
  dashboardId,
  layout,
  setLayout,
  setConfigFor,
}) {
  const [sortOptionOpen, setSortOptionOpen] = useState({});
  const [sortList, setSortList] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState({});
  const chartId = configFor?.cardData?.id;
  const [isSaving, setIsSaving] = useState(false);
  const { mutateAsync } = useUpdateChart(configFor?.cardData?.id);

  const {
    data: chartData,
    isLoading: chartLoading,
    isError,
  } = useGetChartData(chartId, dashboardId);

  const getFormattedList = (data) => {
    return data?.map((item) => ({
      columnId: item?.id,
      value: item?.cells[0]?.value,
    }));
  };

  useEffect(() => {
    if (chartData) {
      setSortList([
        ...getFormattedList(chartData?.dimensions),
        ...getFormattedList(chartData?.measures),
      ]);
    }
  }, [chartData]);

  useEffect(() => {
    if (sortList.length > 0 && chartData?.sorting) {
      const columnNameToIdMap = {};
      sortList.forEach((item) => {
        columnNameToIdMap[item?.value] = item?.columnId;
      });

      const initialSelectedOptions = {};
      Object.keys(chartData?.sorting || {})?.forEach((columnName) => {
        const columnId = columnNameToIdMap[columnName];
        if (columnId) {
          initialSelectedOptions[columnId] = chartData?.sorting[columnName];
        }
      });
      setSelectedOptions(initialSelectedOptions);
    }
  }, [sortList, chartData?.sorting]);

  const options = [
    { value: 'asc', label: 'Ascending' },
    { value: 'desc', label: 'Descending' },
  ];

  const handleChartEdit = (key, value) => {
    const updatedChartList = layout?.charts?.map((item) => {
      if (item?.cardData?.id === chartId) {
        return {
          ...item,
          cardData: {
            ...item?.cardData,
            [key]: value,
          },
        };
      }
      return item; // Add this line to return the item if the condition is not met
    });
    setLayout((prev) => ({ ...prev, charts: updatedChartList }));
    setConfigFor((prev) => ({
      ...prev,
      cardData: {
        ...prev?.cardData,
        [key]: value,
      },
    }));
  };

  const fetchChartData = async () => {
    try {
      const res = await getChartTable(chartId, dashboardId, {});
      handleChartEdit('datasets', res);
      return res;
    } catch (err) {
      console.error(err);
    }
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const newSortList = Array.from(sortList);
    const [movedItem] = newSortList.splice(result.source.index, 1);
    newSortList.splice(result.destination.index, 0, movedItem);

    setSortList(newSortList);
  };

  const handleSelectChange = (id, selectedOption) => {
    setSelectedOptions((prevState) => ({
      ...prevState,
      [id]: selectedOption?.value,
    }));
  };

  const handleSave = async () => {
    setIsSaving(true);
    const sortedOrder = sortList?.reduce((acc, item) => {
      const sortOrder = selectedOptions[item?.columnId];
      if (sortOrder) {
        acc[item?.value] = sortOrder;
      }
      return acc;
    }, {});

    const payload = {
      sorting: sortedOrder,
      dashboardId: dashboardId,
    };

    try {
      await mutateAsync(payload);
      try {
        const res = await fetchChartData();
        const newPayload = {
          data: {
            datasets: res,
            i: configFor?.i,
            type: configFor?.cardData?.type,
            x: configFor?.x,
            y: configFor?.y,
            w: configFor?.w,
            h: configFor?.h,
          },
          dashboardId: dashboardId,
        };
        try {
          await mutateAsync(newPayload);
        } catch (err) {
          console.error(err);
        }
      } catch (err) {
        console.error(err);
      }
      // onResponse('Chart Updated Successfully');
    } catch (err) {
      setIsSaving(false);
      onResponse(err?.message);
    }
    setIsSaving(false);
  };

  return (
    <div className="relative h-full flex flex-col">
      <div className="h-full flex flex-col justify-between">
        <div className="h-4/5 overflow-y-auto overflow-x-hidden">
          {chartLoading ? (
            <TableSkeleton count={4} />
          ) : (
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className="flex flex-col gap-3"
                  >
                    {sortList?.map((item, index) => (
                      <Draggable
                        key={item.columnId}
                        draggableId={item.columnId}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            className="border border-gray-200 rounded-md p-3 bg-gray-25 flex flex-col gap-3"
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <div
                              className="flex justify-between items-center"
                              onClick={() =>
                                setSortOptionOpen((prevState) => ({
                                  ...prevState,
                                  [item?.columnId]: !prevState[item?.columnId],
                                }))
                              }
                            >
                              <div className="flex gap-2">
                                <span className="text-primaryActive text-base font-medium">
                                  {index + 1 < 10 ? `0${index + 1}` : index + 1}
                                </span>
                                <span className="text-gray-600 text-base font-medium capitalize">
                                  {item?.value}
                                </span>
                              </div>
                              <div>
                                <MoveIcon />
                              </div>
                            </div>
                            {sortOptionOpen[item?.columnId] && (
                              <div className="flex flex-col justify-between">
                                <div className="flex gap-2 items-center pt-2 pb-3">
                                  <CheckBoxProgram checked={true} />
                                  <span className="text-gray-900 font-medium text-xs">
                                    Sort
                                  </span>
                                </div>
                                <div>
                                  <Select
                                    options={options}
                                    onChange={(option) =>
                                      handleSelectChange(item?.columnId, option)
                                    }
                                    value={
                                      selectedOptions[item?.columnId] === 'desc'
                                        ? options[1]
                                        : selectedOptions[item?.columnId] ===
                                          'asc'
                                        ? options[0]
                                        : null
                                    }
                                    isClearable={true}
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          )}
        </div>
        <div className="h-20 absolute w-full bottom-0 flex flex-col pt-3 gap-4 bg-white">
          <hr className="bg-gray-200" />
          <div className="text-right">
            {isSaving ? (
              <Button>Saving...</Button>
            ) : (
              <Button onClick={handleSave}>Save</Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
