import { post } from 'apiClient/apiCalls';
import { AxiosError } from 'axios';
import * as CONSTANT from '../api.constant';

export const createPlan = async (body) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_VPAY_V3}/api/plan/upsert-plan`,
      body,
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.response?.data?.message);
    } else {
      throw new Error('Error! Create plan failed');
    }
  }
};

export const createEligibility = async (body) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_VPAY_V3}/api/plan/eligibility/update`,
      body,
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.response?.data?.message);
    } else {
      throw new Error('Error! Create eligibility failed');
    }
  }
};

export const getConfig = async (body) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_VPAY_V3}/api/plan-config/get-fields`,
      {
        sourceNames: [
          'PayoutConfiguration',
          'AchievementsConfiguration',
          'EventConfiguration',
        ],
      },
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.response?.data?.message);
    } else {
      throw new Error('Error! Create eligibility failed');
    }
  }
};
