import { createCustomPValues } from 'apiClient/rangeBuilder/rangeBuilder';
import { Input } from 'components/atoms/FormElements/input/Input';
import Button from 'components/atoms/button/Button';
import ModalPopup from 'components/layout/modalPopup/ModalPopup';
import { useState } from 'react';
import { useSelector } from 'react-redux';

export const CreateNewPValueModal = ({ setModalVisible }) => {
  const user = useSelector((state) => state.user);
  const [pValue, setPValue] = useState();
  const [showError, setShowError] = useState(false);

  const handleCreateNew = async () => {
    {
      pValue?.length
        ? (async () => {
            await createCustomPValues({
              name: `P${pValue}`,
              value: Number(pValue / 100).toFixed(2),
              createdBy: user?.userProfileData?.userId?.value,
            });
            setModalVisible(false);
          })()
        : setShowError(true);
    }
  };

  const handleChange = (event) => {
    setPValue(event.target.value);
    if (showError) {
      event.target.value?.length ? setShowError(false) : setShowError(true);
    }
  };

  return (
    <ModalPopup
      onClose={() => setModalVisible(false)}
      title="Create new percentile point"
      width="w-96"
      body={
        <div className="px-4 space-y-6">
          <div>
            <Input
              type="number"
              label="New Percentile"
              placeholder="Enter value"
              onChange={handleChange}
            />
            {showError && (
              <p className="font-sm text-red-500">Please enter a value</p>
            )}
          </div>
          <div className="flex items-center justify-end gap-2">
            <Button
              className="border-0 text-sm font-semibold text-gray-600"
              onClick={() => setModalVisible(false)}
            >
              Cancel
            </Button>
            <Button
              variant="filled"
              className="text-sm font-semibold"
              onClick={handleCreateNew}
            >
              Create
            </Button>
          </div>
        </div>
      }
    />
  );
};
