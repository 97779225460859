import { AxiosError } from 'axios';
import * as CONSTANT from '../api.constant';
import { get, post } from '../apiCalls';

export const saveAsDraftStep1 = async (body, id) => {
  try {
    const url = id
      ? `${CONSTANT.API_URL}/${CONSTANT.API_BENEFITS_V2}/save-as-draft/${id}`
      : `${CONSTANT.API_URL}/${CONSTANT.API_BENEFITS_V2}/save-as-draft`;

    const response = await post(url, body);
    return response;
  } catch (err) {
    if (err.response) {
      const { status, data } = err.response;
      throw new Error(
        `Error: Received status ${status} from server with message: ${data}`,
      );
    } else {
      throw new Error('Error! An error occurred while saving the draft.');
    }
  }
};
export const saveAsDraftStep2 = async (body, type, id) => {
  try {
    const url = id
      ? `${CONSTANT.API_URL}/${CONSTANT.API_BENEFITS_V2}/upload-policy/${id}`
      : `${CONSTANT.API_URL}/${CONSTANT.API_BENEFITS_V2}/upload-policy`;
    const response = await post(url, body, type);
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err);
    } else {
      throw new Error('Error! error in Saving draft');
    }
  }
};
export const saveAsDraftStep3 = async (body, type, id) => {
  try {
    const url = id
      ? `${CONSTANT.API_URL}/${CONSTANT.API_BENEFITS_V2}/additional-info/${id}`
      : `${CONSTANT.API_URL}/${CONSTANT.API_BENEFITS_V2}/additional-info`;
    const response = await post(url, body, type);
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err);
    } else {
      throw new Error('Error! error in Saving draft');
    }
  }
};
export const saveAsDraftStep4 = async (body, id) => {
  try {
    const url = id
      ? `${CONSTANT.API_URL}/${CONSTANT.API_BENEFITS_V2}/policy-approvers/${id}`
      : `${CONSTANT.API_URL}/${CONSTANT.API_BENEFITS_V2}/policy-approvers`;
    const response = await post(url, body);
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err);
    } else {
      throw new Error('Error! error in Saving draft');
    }
  }
};
export const createCategory = async (body) => {
  try {
    const url = `${CONSTANT.API_URL}/${CONSTANT.API_BENEFITS_V2}/categories`;
    const response = await post(url, body);
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! error in creating Category');
    }
  }
};
export const getCountries = async (pageIndex, pageSize) => {
  try {
    // const response = await get(
    //   `${CONSTANT.API_URL}/${CONSTANT.API_BENEFITS_V2}/country/get-all-country?pageIndex=${pageIndex}&pageSize=${pageSize}`
    // );
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_BENEFITS_V2}/country/get-all-country`,
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! error in getting countries');
    }
  }
};
export const getPolicyById = async (policyId) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_BENEFITS_V2}/policy/${policyId}`,
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! error in getting policyDetail');
    }
  }
};
export const getPolicyFAQsById = async (policyId, searchWord) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_BENEFITS_V2}/policy/${policyId}/faqs?s=${searchWord}`,
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! error in getting FAQs');
    }
  }
};
export const sendForApproval = async (policyId) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_BENEFITS_V2}/policy/approval/${policyId}`,
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! error in sending approval');
    }
  }
};
export const getVersionHistory = async (policyId) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_BENEFITS_V2}/policy/history/${policyId}`,
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! error in getting version history');
    }
  }
};
export const getPolicies = async (pageIndex, pageSize, status) => {
  try {
    // const response = await get(
    //   `${CONSTANT.API_URL}/${CONSTANT.API_BENEFITS_V2}/policy?pageIndex=${pageIndex}&pageSize=${pageSize}&status=${status}`
    // );
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_BENEFITS_V2}/policy?pageIndex=${pageIndex}&pageSize=${pageSize}&status=${status}`,
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! error in getting countries');
    }
  }
};
export const getCatagories = async (pageIndex, pageSize) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_BENEFITS_V2}/categories?pageIndex=${pageIndex}&pageSize=${pageSize}`,
      //   `${CONSTANT.API_URL}/${CONSTANT.API_BENEFITS_V2}/categories`
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! error in getting catagories');
    }
  }
};

export const getPoliciesByStatus = async (status, pageIndex, search) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_BENEFITS_V2}/policy?pageIndex=${pageIndex}&pageSize=5&status=${status}&s=${search}`,
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error!');
    }
  }
};

export const archivePolicyAPI = async (id) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_BENEFITS_V2}/policy/archive/${id}`,
    );
    return response?.data;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error!');
    }
  }
};

export const deletePolicyAPI = async (id) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_BENEFITS_V2}/policy/delete/${id}`,
    );
    return response?.data;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error!');
    }
  }
};

export const updatePolicyAPI = async (id) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_BENEFITS_V2}/policy/update/${id}`,
    );
    return response?.data;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error!');
    }
  }
};

export const publishPolicyAPI = async (id) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_BENEFITS_V2}/policy/publish/${id}`,
    );
    return response?.data;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error!');
    }
  }
};

export const relaunchPolicyAPI = async (id) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_BENEFITS_V2}/policy/relaunch/${id}`,
    );
    return response?.data;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error!');
    }
  }
};

export const withdrawPolicyAPI = async (id) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_BENEFITS_V2}/policy/withdraw/${id}`,
    );
    return response?.data;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error!');
    }
  }
};

export const getEditableDocumentAPI = async (id) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_BENEFITS_V2}/policy/download/${id}`,
    );
    return response?.data;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error!');
    }
  }
};

export const downloadFeedbackAPI = async (id) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_BENEFITS_V2}/policy/${id}/feedback`,
    );
    return response?.data;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error!');
    }
  }
};
export const likePolicy = async (id) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_BENEFITS_V2}/policy/like/${id}`,
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error!');
    }
  }
};
export const getPolicyLikes = async (id) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_BENEFITS_V2}/policy/like/get-likes/${id}`,
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error("Error! can't get policy likes");
    }
  }
};
export const postFeedBack = async (payload, id) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_BENEFITS_V2}/policy/${id}/feedback`,
      payload,
    );
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const getEditPolicyIdAPI = async (id) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_BENEFITS_V2}/policy/edit/${id}`,
    );
    return response?.data;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error!');
    }
  }
};

export const fetchTemplatesAPI = async (type, pageIndex, searchedValue) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_BENEFITS_V2}/policy/template?pageIndex=${pageIndex}&pageSize=5&type=${type}&s=${searchedValue}`,
    );
    return response?.data;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error!');
    }
  }
};

export const fetchApproverDetails = async (ids) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_BENEFITS_V2}/policy/workflow-approver-data`,
      { ids },
    );
    return response?.data;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error!');
    }
  }
};
