import { fetchModule } from 'apiClient/templateBuilder/templateBuilderApi';
import { Input } from 'components/atoms/FormElements/input/Input';
import Select from 'components/atoms/FormElements/select/Select';
import Button from 'components/atoms/button/Button';
import {
  Controller,
  useFieldArray,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import { useDispatch } from 'react-redux';
import {
  setSelectedSection,
  setSidePanelTitle,
  toggleShowSidepanel,
  toggleSidePanelType,
} from 'store/reducers/templateBuilderSlice';
import { v4 } from 'uuid';
import BuilderSection from './builderSection/BuilderSection';
import BuilderSectionLayout from './builderSectionLayout/BuilderSectionLayout';

import ButtonAtom from './builderColumn/atoms/buttonAtom/ButtonAtom';

function BuilderMainPanel() {
  const {
    control,
    setValue,
    watch,
    getValues,
    formState: { errors },
  } = useFormContext();
  const { append, remove, swap } = useFieldArray({
    control,
    name: `data`,
  });

  const dispatch = useDispatch();

  const pageStructure = useWatch({
    control,
    name: `data`,
  });

  const handleColumns = (index, columns) => {
    setValue(
      `data[${index}].columns`,
      columns === 2
        ? [{ columnId: v4() }, { columnId: v4() }]
        : columns === 3
        ? [{ columnId: v4() }, { columnId: v4() }, { columnId: v4() }]
        : columns === 4
        ? [
            { columnId: v4() },
            { columnId: v4() },
            { columnId: v4() },
            { columnId: v4() },
          ]
        : [{ columnId: v4() }],
    );
  };

  const onAdd = () => {
    const sectionId = v4();
    append({
      sectionId,
      columns: [],
    });
    dispatch(setSelectedSection(sectionId));
  };

  const modifyMedia = (index) => {
    const columns = watch(`data.${index}.columns`);
    const media = watch(`media`);
    columns.forEach((column) => {
      if (media[column.columnId]) {
        delete media[column.columnId];
      }
    });
    setValue(`media`, media);
  };

  // const onRemove = (index) => {
  //   modifyMedia(index);
  //   const id = watch(`data.${index}.id`);
  //   if (id) {
  //     const rowsToDelete = watch(`rowsToDelete`);
  //     setValue(`rowsToDelete`, [...rowsToDelete, id]);
  //     const columns = watch(`data.${index}.columns`);
  //     columns.forEach((column) => {
  //       if (column?.column?.attr?.mediaId) {
  //         const mediasToDelete = watch(`mediasToDelete`);
  //         setValue(`mediasToDelete`, [
  //           ...mediasToDelete,
  //           column?.column?.attr?.mediaId,
  //         ]);
  //       }
  //     });
  //   }
  //   remove(index);
  // };

  const onRemove = (index) => {
    modifyMedia(index); // Remove media related to the section being deleted

    // Additional logic for handling deletion of associated data
    const id = watch(`data[${index}].id`);
    if (id) {
      // If there's an id associated with the section, mark it for deletion
      const rowsToDelete = watch(`rowsToDelete`);
      setValue(`rowsToDelete`, [...rowsToDelete, id]);
      // Check each column for associated media to delete
      const columns = watch(`data.${index}.columns`);
      columns?.forEach((column) => {
        if (column?.column?.attr?.mediaId) {
          const mediasToDelete = watch(`mediasToDelete`);
          setValue(`mediasToDelete`, [
            ...mediasToDelete,
            column?.column?.attr?.mediaId,
          ]);
        }
      });
    }

    const updatedPageStructure = [...pageStructure];
    updatedPageStructure.splice(index, 1);
    setValue('data', updatedPageStructure);
    // Remove the section itself from form state
    remove(index);
  };

  const onSwapUp = (index) => {
    if (index - 1 >= 0) {
      const updatedPageStructure = [...pageStructure]; // Create a copy of pageStructure
      const temp = updatedPageStructure[index];
      updatedPageStructure[index] = updatedPageStructure[index - 1];
      updatedPageStructure[index - 1] = temp;
      setValue(`data`, updatedPageStructure); // Update state with the new array
    }
  };

  const onSwapDown = (index) => {
    if (index + 1 < pageStructure?.length) {
      const updatedPageStructure = [...pageStructure]; // Create a copy of pageStructure
      const temp = updatedPageStructure[index];
      updatedPageStructure[index] = updatedPageStructure[index + 1];
      updatedPageStructure[index + 1] = temp;
      setValue(`data`, updatedPageStructure); // Update state with the new array
    }
  };

  const onAddClone = (index) => {
    const sectionId = v4();
    const columns = watch(`data.${index}.columns`);
    const columnsCopy = columns?.map((column) => ({
      ...column,
      columnId: v4(),
    }));
    const newData = [...pageStructure];
    // Create the new section to be inserted
    const newSection = {
      sectionId,
      columns: columnsCopy,
    };
    // Insert the new section immediately after the original section
    const updatedSections = [
      ...newData.slice(0, index + 1),
      newSection,
      ...newData.slice(index + 1),
    ];
    setValue(`data`, updatedSections);
    dispatch(setSelectedSection(sectionId));
  };

  const loadOptions = async (query, loadedOptions, { page }) => {
    const response = await fetchModule(query);
    const options = response?.map((ele) => ({
      label: ele.name,
      value: ele.id,
    }));
    return {
      options,
      hasMore: false,
    };
  };
  const selectAtomsMolecules = (type) => {
    dispatch(toggleShowSidepanel(true));
    dispatch(toggleSidePanelType(type));
    dispatch(setSidePanelTitle('Edit Button'));
  };

  const buttonData1 = {
    type: 'Button1',
    path: 'extras.button1',
    errorPath: 'extras.button1',
    selectAtomsMolecules: selectAtomsMolecules,
  };

  const buttonData2 = {
    type: 'Button2',
    path: 'extras.button2',
    errorPath: 'extras.button2',
    selectAtomsMolecules: selectAtomsMolecules,
  };

  return (
    <div>
      <div className="px-8 bg-white shadow-xl ring-1 ring-gray-200 relative rounded-lg h-full -mt-6 mx-6 z-40 h-42 py-8 flex flex-col gap-6">
        <div className=" flex justify-between items-center gap-5 w-full">
          <Controller
            control={control}
            name={`name`}
            rules={{ required: 'Title is required *', maxLength: 30 }}
            render={({ field }) => (
              <div className="flex flex-col w-full">
                <div className="w-full -mx-2.5">
                  <Input
                    {...field}
                    placeholder="Enter your name (upto 30 characters)"
                    className={
                      'max-w-96 w-full break-words text-gray-900 max-h-28 border-none font-medium text-base focus-within:outline-gray-500 capitalize'
                    }
                    maxLength={30}
                  />
                </div>
                {errors.name && (
                  <p className="bg-clip-text bg-gradient-to-br text-transparent from-primaryLeft to-primaryRight">
                    {errors?.name?.message}
                  </p>
                )}
              </div>
            )}
          />
          <div className="flex flex-row justify-end items-center p-0 gap-3 w-3/12 h-9">
            <ButtonAtom data={buttonData1} />
            <ButtonAtom data={buttonData2} />
          </div>
        </div>
        <div className="mt-1 w-1/3">
          <Controller
            name="moduleName"
            control={control}
            render={({ field }) => {
              const { value, onChange, ...rest } = field;
              return (
                <Select
                  label="Select Nav Item"
                  additional={{
                    page: 0,
                  }}
                  async={true}
                  loadOptions={loadOptions}
                  noOptionsMessage={() => 'No results found'}
                  onChange={(e) => onChange(e)}
                  value={value}
                  {...rest}
                />
              );
            }}
          />
          <p className="text-red-600 text-sm">{errors?.changeTo?.message}</p>
        </div>
        {pageStructure?.map((ele, index) => {
          return ele?.columns?.length > 0 ? (
            <BuilderSection
              key={index}
              index={index}
              onRemove={onRemove}
              onSwapUp={onSwapUp}
              onSwapDown={onSwapDown}
              sectionData={ele}
              onAddClone={onAddClone}
            />
          ) : (
            <BuilderSectionLayout
              key={index}
              index={index}
              onClick={handleColumns}
              onRemove={onRemove}
            />
          );
        })}

        <Button
          className=" bg-white w-full flex items-center gap-2 justify-center h-fit text-transparent bg-clip-text bg-gradient-to-br from-primaryLeft to-primaryRight font-medium"
          onClick={() => onAdd()}
        >
          +<span className="text-sm">Add Section</span>
        </Button>
      </div>
    </div>
  );
}

export default BuilderMainPanel;
