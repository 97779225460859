const ImageAtom = () => {
  return (
    <svg
      width="110"
      height="98"
      viewBox="0 0 110 98"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.716797 5.5287C0.737464 2.56427 3.13397 0.165284 6.0984 0.141724H103.835C106.808 0.141724 109.217 2.5558 109.217 5.5287V92.4048C109.196 95.3692 106.8 97.7682 103.835 97.7917H6.0984C3.1255 97.7888 0.716797 95.3779 0.716797 92.4048V5.5287ZM98.3674 10.9919H11.5674V86.9419L61.9765 36.5219C64.0951 34.4042 67.529 34.4042 69.6476 36.5219L98.3674 65.2961V10.9919ZM22.4162 32.6915C22.4162 38.6838 27.2739 43.5415 33.2662 43.5415C39.2585 43.5415 44.1162 38.6838 44.1162 32.6915C44.1162 26.6992 39.2585 21.8415 33.2662 21.8415C27.2739 21.8415 22.4162 26.6992 22.4162 32.6915Z"
        fill="#98A2B3"
      />
    </svg>
  );
};

export default ImageAtom;
