import DatePicker from 'components/atoms/FormElements/datepicker/DatePicker';
import { Input } from 'components/atoms/FormElements/input/Input';
import Select from 'components/atoms/FormElements/select/Select';
import { useEffect, useState } from 'react';
import DropDownWithEmployeSearch from '../dropDowns/dropDownWithEmployeSearch/DropDownWithEmployeSearch';

export default function ValueEditor(props) {
  const {
    field,
    operator,
    value,
    valueSource,
    handleOnChange,
    fields,
    valueOptions,
    dataType,
  } = props;

  const [newValue, setNewValue] = useState(value);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  //console.log('props', props);

  useEffect(() => {
    if (
      props?.operator?.code === 'bw' ||
      props?.operator === 'bw' ||
      props?.operator?.code === 'nbw' ||
      props?.operator === 'nbw'
    ) {
      const [start, end] = value || [];
      setStartDate(start);
      setEndDate(end);
    } else setNewValue(value);
  }, [value]);

  const handleDatePickerChange = (date) => {
    const [start, end] = date || [];
    setStartDate(start);
    setEndDate(end);
    start && end && handleOnChange([start, end]);
  };

  const formatFields = (data) => {
    return data.map((field) => ({
      ...field,
      label: field?.alias || field?.value,
      name: field?.id,
      value: field?.id,
    }));
  };
  const getFormattedList = (list) => {
    return (
      list &&
      list?.map((option) => {
        return {
          value: option?.id || option?.code,
          label:
            option?.value ||
            option?.helpText ||
            option?.description ||
            option?.displayName,
          ...option,
        };
      })
    );
  };

  const formatValueOptions = (data) => {
    return data.map((field) => ({
      ...field,
      label: field?.alias || field?.value,
      value: field?.id,
    }));
  };

  const options = valueOptions?.[field?.label] || [];

  if (valueSource === 'field') {
    return (
      <Select
        value={
          newValue?.label
            ? { label: newValue?.label, value: newValue?.id }
            : null
        }
        options={formatFields(fields)}
        placeholder="Select Value"
        className=" disabled:cursor-not-allowed"
        isDisabled={
          (props?.field?.value || props?.field) &&
          (props?.operator?.value || props?.operator)
            ? false
            : true
        }
        onChange={(e) => handleOnChange(e)}
      />
    );
  } else if (options?.length > 0) {
    return (
      <Select
        value={
          newValue?.label
            ? { label: newValue?.label, value: newValue?.id }
            : null
        }
        options={formatValueOptions(options)}
        placeholder="Select Value"
        className=" disabled:cursor-not-allowed"
        isDisabled={
          (props?.field?.value || props?.field) &&
          (props?.operator?.value || props?.operator)
            ? false
            : true
        }
        onChange={(e) => handleOnChange(e)}
      />
    );
  } else if (
    (props?.field?.dataType === 'Date' || dataType === 'Date') &&
    (props?.operator?.code === 'bw' ||
      props?.operator === 'bw' ||
      props?.operator === 'nbw' ||
      props?.operator?.code === 'nbw')
  ) {
    return (
      <DatePicker
        label=""
        selectsRange
        onChange={handleDatePickerChange}
        placeholder="Select a date range"
        isDisabled={
          (props?.field?.value || props?.field) &&
          (props?.operator?.value || props?.operator)
            ? false
            : true
        }
        startDate={startDate ? new Date(startDate) : null}
        endDate={endDate ? new Date(endDate) : null}
        dateFormat="MMMM d, yyyy"
      />
    );
  } else if (
    props?.operator?.code === 'pd' ||
    props?.operator === 'pd' ||
    props?.operator === 'pm' ||
    props?.operator?.code === 'pm'
  ) {
    return (
      <div className="flex justify-center items-center">
        <Input
          type="number"
          value={startDate}
          placeholder="Enter start value"
          className="bg-white disabled:bg-[#f2f2f2] border-[rgb(209 213 219)] placeholder:text-[#808080] !h-[38px]"
          disabled={
            (props?.field?.value || props?.field) &&
            (props?.operator?.value || props?.operator)
              ? false
              : true
          }
          onBlur={() => {
            const newData = [startDate, endDate];
            startDate?.length > 0 && handleOnChange(newData);
          }}
          onChange={(e) => setStartDate(e.target.value)}
        />
        <Input
          type="text"
          value={endDate}
          placeholder="Enter end value"
          className="bg-white disabled:bg-[#f2f2f2] border-[rgb(209 213 219)] placeholder:text-[#808080] !h-[38px]"
          disabled={
            (props?.field?.value || props?.field) &&
            (props?.operator?.value || props?.operator)
              ? false
              : true
          }
          onBlur={() => {
            const newData = [startDate, endDate];
            endDate?.length > 0 && handleOnChange(newData);
          }}
          onChange={(e) => setEndDate(e.target.value)}
        />
      </div>
    );
  } else if (props?.field?.dataType === 'User') {
    return (
      <div className="w-full">
        <DropDownWithEmployeSearch
          isMulti={false}
          members={props.value}
          setMembers={(e) => {
            handleOnChange(e);
          }}
        />
      </div>
    );
  } else {
    return (
      <Input
        type="text"
        value={newValue}
        placeholder="Enter value"
        className="bg-white disabled:bg-[#f2f2f2] border-[rgb(209 213 219)] placeholder:text-[#808080] !h-[38px]"
        disabled={
          (props?.field?.value || props?.field) &&
          (props?.operator?.value || props?.operator)
            ? false
            : true
        }
        onBlur={() => handleOnChange(newValue)}
        onChange={(e) => setNewValue(e.target.value)}
      />
    );
  }
}
