import {
  CheckBoxProgram,
  RadioButton,
} from 'components/atoms/FormElements/input/Input';
import Select from 'components/atoms/FormElements/select/Select';
import {
  CalculationFieldsEnum,
  ConfigurationKeysEnum,
  EventsSettings,
  PayoutAmountsEnum,
} from 'pages/variablePay/VariablePay.types';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { htmlParser } from 'utils/jsUtils';
import TagInput from './PayoutConfigurations/TagsInput';

type ConfigurationProps = {
  setEventSettings: Dispatch<SetStateAction<EventsSettings>>;
  eventSettings: EventsSettings;
  configuration: { [key: string]: any };
  handleChange?: (key: ConfigurationKeysEnum, value: PayoutAmountsEnum) => void;
};

export const Configuration = ({
  setEventSettings,
  eventSettings,
  configuration,
  handleChange,
}: ConfigurationProps) => {
  const handleChanges = (key: string, value: string) => {
    setEventSettings({ ...eventSettings, [key]: { value } });
  };

  useEffect(() => {
    if (
      eventSettings.vPayPayoutAmounts?.value ===
        PayoutAmountsEnum.LOGIC_BASED_CALCULATION &&
      !eventSettings.vPayCalculationField
    ) {
      setEventSettings({
        ...eventSettings,
        vPayCalculationField: { value: CalculationFieldsEnum.SYSTEM },
      });
    }
  }, [eventSettings]);

  return (
    <div className="border border-gray-200 rounded-xl bg-white p-6 shadow-md space-y-6">
      <div className="border-b pb-2 border-gray-200">
        <p className="font-semibold text-gray-900">{configuration?.name}</p>
        <p className="text-sm text-gray-600">{configuration?.description}</p>
      </div>
      <div className="space-y-5">
        {configuration?.children?.map((config: any, index: number) => {
          return (
            <div key={config?.key} className="space-y-5">
              <div className="flex gap-16" key={config?.id}>
                <div className="w-1/5">
                  <p className="text-sm font-semibold text-gray-700">
                    {config?.name}
                  </p>
                  <p className="text-sm text-gray-600">{config?.description}</p>
                </div>
                <div className="w-1/4 space-y-4">
                  {config?.children?.map((option: any) => {
                    return (
                      <div className="flex items-start" key={option?.id}>
                        {config?.dataType === 'Radio' && (
                          <RadioButton
                            // @ts-ignore
                            id={option?.key}
                            checked={
                              option?.key ===
                              eventSettings?.[
                                config?.key as keyof EventsSettings
                              ]?.value
                            }
                            onChange={() =>
                              handleChange
                                ? handleChange(config?.key, option?.key)
                                : handleChanges(config?.key, option?.key)
                            }
                          />
                        )}
                        {config?.dataType === 'Checkbox' && (
                          <div className="ml-4 mr-3">
                            <CheckBoxProgram
                              //@ts-ignore
                              label=""
                              error=""
                              id={option?.key}
                              checked={true}
                              icon=""
                              disabled={false}
                            />
                          </div>
                        )}
                        <div className="-ml-1 space-y-2">
                          <div>
                            <p className="text-sm font-medium text-gray-900">
                              {option?.name}
                            </p>
                            <p className="text-gray-600 text-sm">
                              {htmlParser(option?.description)}
                            </p>
                          </div>
                          {option?.dataType === 'Tags' && <TagInput />}
                          {option?.dataType === 'Dropdown' && (
                            <Select
                              // @ts-ignore

                              options={option?.metadata?.options?.map(
                                (item: string) => ({
                                  label: item,
                                  value: item,
                                }),
                              )}
                            />
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              {index < configuration?.children?.length - 1 && (
                <div className="border-b border-gray-200"></div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};
