import { CheckBoxProgram } from 'components/atoms/FormElements/input/Input';
import Toggle from 'components/atoms/FormElements/toggle/Toggle';
import { useState } from 'react';

export const CompensationUnitFilter = ({
  isLakhsCompUnit,
  setIsLakhsCompUnit,
}) => {
  return (
    <div className="flex items-center gap-[2px]">
      <div className="flex items-center text-sm font-medium text-gray-600 gap-[2px]">
        <p className="flex px-2">Thousands</p>
        <Toggle
          value={isLakhsCompUnit}
          onClick={(e) => {
            setIsLakhsCompUnit((prev) => !prev);
          }}
        />
        <p className="flex -ml-2">Lakhs</p>
      </div>
    </div>
  );
};
