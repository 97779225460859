import { XMarkIcon } from '@heroicons/react/24/outline';
import { Input } from 'components/atoms/FormElements/input/Input';
import { ChangeEvent, KeyboardEvent, useState } from 'react';

interface Tag {
  id: number;
  text: string;
}

const TagInput = () => {
  const [tags, setTags] = useState<Tag[]>([]);
  const [inputValue, setInputValue] = useState<string>('');

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && inputValue.trim() !== '') {
      setTags([...tags, { id: Date.now(), text: inputValue }]);
      setInputValue('');
    }
  };

  const removeTag = (id: number) => {
    setTags(tags.filter((tag) => tag.id !== id));
  };

  return (
    <div className="border border-gray-300 p-[6px] rounded-lg flex flex-wrap items-center gap-2">
      {tags.map((tag) => (
        <div
          key={tag.id}
          className="flex items-center text-gray-700 py-[2px] px-1 rounded-md border border-gray-200"
        >
          <span className="text-sm font-medium">{tag.text}</span>

          <XMarkIcon
            className="ml-2 cursor-pointer w-3 h-3 text-xs text-gray-400"
            onClick={() => removeTag(tag.id)}
          />
        </div>
      ))}
      <div className="w-1/2">
        <Input
          // @ts-ignore
          type="text"
          placeholder="Press Enter to add a tag"
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleKeyPress}
          className="border-none focus:ring-0 text-sm w-full flex-grow !py-1 !px-0 focus:outline-none"
        />
      </div>
    </div>
  );
};

export default TagInput;
