import React from 'react';

const ViewSpacerAtom = (props) => {
  const { columnData } = props;

  return (
    <div style={{ height: columnData?.attr?.spacer?.height + 'px' }}></div>
  );
};

export default ViewSpacerAtom;
