import React from 'react';

const WellnessLogo = ({ isActive }) => {
  return (
    <svg
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.917 8.58333H12.0837L10.8337 11.0833L9.16699 6.08333L7.91699 8.58333H7.08366M9.99462 3.27985C8.32849 1.332 5.55011 0.808035 3.46257 2.59168C1.37503 4.37532 1.08113 7.35748 2.72049 9.467C3.95873 11.0604 7.4764 14.2591 9.12367 15.7291C9.42648 15.9993 9.57789 16.1344 9.75518 16.1876C9.9091 16.2338 10.0801 16.2338 10.2341 16.1876C10.4113 16.1344 10.5628 15.9993 10.8656 15.7291C12.5128 14.2591 16.0305 11.0604 17.2687 9.467C18.9081 7.35748 18.6501 4.35656 16.5267 2.59168C14.4032 0.826798 11.6607 1.332 9.99462 3.27985Z"
        stroke={`${isActive ? '#fff' : '#475467'}`}
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default WellnessLogo;
