import { InboxArrowDownIcon } from '@heroicons/react/24/outline';
import { DEFAULT_AVATAR_PATH } from 'apiClient/api.constant';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fetchAndUpdatePendingTask } from 'store/reducers/taskSlice';
import { imageURLCheck } from 'utils/ImageUrlCheck';
import { getGradientMidColor, hexToHsl } from 'utils/jsUtils';
import NotificationIconLogo from '../../assets/svg/notificationIconLogo';
import Avatar from './Avatar';
import CompanyProfile from './CompanyProfile';
import NotificationPopUp from './NotificationPopUp';
import Search from './Search';
import UserProfile from './UserProfile';

const Header = () => {
  const ref = useRef();
  const dispatch = useDispatch();
  const theme = useSelector((state) => state.theme);
  const user = useSelector((state) => state.user);
  const [openSearch, setOpenSearch] = useState(false);
  const [openNotification, setOpenNotification] = useState(false);
  const [openProfile, setOpenProfile] = useState(false);
  const [pendingTask, setPendingTask] = useState(0);
  const [primaryColor, setPrimaryColor] = useState('#ff1f71');
  const [secondColor, setSecondColor] = useState('#ff7700');
  const popupRef = useRef(null);
  const handleOutsideClick = (e) => {
    if (popupRef.current && !popupRef.current.contains(e.target)) {
      setOpenProfile(false);
    }
  };
  const saveLayoutToLocalStorage = (key, data) => {
    localStorage.setItem(key, JSON.stringify(data));
  };
  const getLayoutFromLocalStorage = (key) => {
    const savedLayout = localStorage.getItem(key);
    return savedLayout ? JSON.parse(savedLayout) : null;
  };

  function updateColors(primaryLeft, primaryRight) {
    document.documentElement.style.setProperty('--primaryLeft', primaryLeft);
    document.documentElement.style.setProperty('--primaryRight', primaryRight);
    document.documentElement.style.setProperty(
      '--hoveredColor',
      getGradientMidColor(primaryLeft, primaryRight, 0.35),
    );
    document.documentElement.style.setProperty(
      '--midColor',
      getGradientMidColor(primaryLeft, primaryRight, 0.5),
    );
    document.documentElement.style.setProperty(
      '--onHoverPrimaryLeft',
      `hsl(${hexToHsl(primaryLeft).h}, ${hexToHsl(primaryLeft).s}%, ${
        hexToHsl(primaryLeft).l - 10
      }%)`,
    );
    document.documentElement.style.setProperty(
      '--onHoverPrimaryRight',
      `hsl(${hexToHsl(primaryRight).h}, ${hexToHsl(primaryRight).s}%, ${
        hexToHsl(primaryRight).l - 10
      }%)`,
    );
    document.documentElement.style.setProperty(
      '--primaryRight50',
      `hsl(${hexToHsl(primaryRight).h}, ${hexToHsl(primaryRight).s}%, 95%)`,
    );
    document.documentElement.style.setProperty(
      '--primaryLeft50',
      `hsl(${hexToHsl(primaryLeft).h}, ${hexToHsl(primaryLeft).s}%, 95%)`,
    );
    saveLayoutToLocalStorage('primaryLeft', primaryLeft);
    saveLayoutToLocalStorage('primaryRight', primaryRight);
  }
  useEffect(() => {
    const p = getLayoutFromLocalStorage('primaryLeft');
    const s = getLayoutFromLocalStorage('primaryRight');
    if (p?.length > 0) {
      setPrimaryColor(p);
    }
    if (s?.length > 0) {
      setSecondColor(s);
    }
  }, []);
  useEffect(() => {
    updateColors(primaryColor, secondColor);
  }, [primaryColor, secondColor]);

  // Example usage:
  //   useEffect(() => {
  //     const addScript = document.createElement('script');
  //     addScript.setAttribute(
  //       'src',
  //       '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit',
  //     );
  //     document.body.appendChild(addScript);

  //     // Check the user's browser language or region
  //     const userLang = navigator.language || navigator.userLanguage;
  //     window.googleTranslateElementInit = () =>
  //       googleTranslateElementInit(userLang);
  //   }, []);

  //   const googleTranslateElementInit = (userLang) => {
  //     // Map userLang to the Google Translate-supported language if necessary
  //     const supportedLanguages = [
  //       'en',
  //       'ms',
  //       'ta',
  //       'zh-CN',
  //       'hi',
  //       'ar',
  //       'gu',
  //       'de',
  //     ];
  //     const lang = supportedLanguages.includes(userLang) ? userLang : 'de'; // Default to German ('de') if userLang isn't supported

  //     new window.google.translate.TranslateElement(
  //       {
  //         pageLanguage: lang,
  //         includedLanguages: 'en,ms,ta,zh-CN,hi,ar,gu,ms-MY,de',
  //         // eslint-disable-next-line no-undef
  //         layout: google.translate.TranslateElement.InlineLayout.SIMPLE,
  //       },
  //       'google_translate_element',
  //     );
  //   };
  useEffect(() => {
    window.addEventListener('click', handleOutsideClick);

    return () => {
      window.removeEventListener('click', handleOutsideClick);
    };
  }, []);
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (openNotification && ref.current && !ref.current.contains(e.target)) {
        setOpenNotification(false);
      }
      if (openProfile && ref.current && !ref.current.contains(e.target)) {
        setOpenProfile(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [openNotification, openProfile]);

  useEffect(() => {
    const uId = user?.userProfileData?.userId?.value;
    const taskParams = [uId, 10, 0, '', 'Pending', true, 'count'];
    dispatch(fetchAndUpdatePendingTask(taskParams));
    const intervalId = setInterval(() => {
      dispatch(fetchAndUpdatePendingTask(taskParams));
    }, 15 * 60 * 1000);

    return () => clearInterval(intervalId);
  }, [dispatch, user]);

  const taskCount = useSelector((state) => state.tasks.taskCount);
  // console.log(taskCount);
  const navigate = useNavigate();
  return (
    <div
      className="flex items-center justify-between p-0  w-full h-16  isolate bg-white"
      style={{
        boxShadow:
          '0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06)',
      }}
    >
      <div className="flex w-8/12  gap-10">
        {/* <div
          className="flex flex-col justify-center items-start p-2.5 h-14 sm:hidden "
          onClick={() => {
            dispatch(ToggleSidebar(!theme.isSidebarVisible));
          }}
        >
          {theme.isSidebarVisible ? (
            <MenuBarLogo styles="w-6 h-6" />
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          )}
        </div> */}
        {/* <div
          className="fixed top-0 z-[999999999999999]"
          id="google_translate_element"
        >
          {' '}
        </div> */}
        <div className={` ml-6 w-ful h-14 `}>
          <CompanyProfile />
        </div>
        <div
          className={`flex flex-row justify-between items-center py-2.5  pl-8  w-1/2 h-14 sm:block ${
            openSearch ? 'block' : 'hidden'
          }`}
        >
          <Search />
        </div>
      </div>
      <div className="flex flex-row w-1/2 items-center justify-end py-2.5 gap-1 isolate h-14 mr-6 ">
        <div className="flex items-center h-full justify-content">
          <div className=" h-6 w-8 relative hover:cursor-pointer">
            <InboxArrowDownIcon
              className="w-6 h-6 mr-2 absolute text-gray-500"
              onClick={() =>
                navigate(
                  `/task?taskTab=Open&pageIndex=0&pageSize=10&status=Pending`,
                )
              }
            />

            <span
              className={`absolute text-[10px] ${
                taskCount > 99 ? '' : 'h-4 w-4'
              } text-center p-[1px] bg-green rounded-full text-white font-medium top-0 right-0`}
            >
              {taskCount > 99 ? '99+' : taskCount}
            </span>
          </div>
          <div
            onClick={() => {
              setOpenNotification(!openNotification);
              setOpenProfile(false);
            }}
            className="hover:cursor-pointer relative"
          >
            <NotificationIconLogo styles="w-6 h-6 ml-2 mr-3 text-gray-500" />
            {openNotification && (
              <NotificationPopUp
                openNotification={openNotification}
                setOpenNotification={setOpenNotification}
              />
            )}
          </div>
          <div className="border-2 border-gray-200 rounded-md gap-3 flex items-center py-1 px-2">
            <div className="h-4">
              <img
                src="https://talentmonk.com/wp-content/uploads/2024/04/Horizontal-Logo-e1712650848449.jpg"
                alt=""
                className="w-full h-full "
              />
            </div>
            <div
              ref={popupRef}
              className={`flex flex-col relative hover:cursor-pointer items-start justify-center w-10 h-10 `}
              onClick={() => {
                setOpenProfile(!openProfile);
                setOpenNotification(false);
              }}
            >
              {Object.keys(user?.userProfileData)?.length > 0 ? (
                <Avatar
                  image={
                    imageURLCheck(user?.userProfileData?.avatar?.value)
                    // : "https://pixlok.com/wp-content/uploads/2022/02/Profile-Icon-SVG-09856789.png"
                  }
                  userName={user?.userProfileData?.fullName?.value}
                />
              ) : (
                <img
                  src={DEFAULT_AVATAR_PATH}
                  alt="user_avatar"
                  // src="https://pixlok.com/wp-content/uploads/2022/02/Profile-Icon-SVG-09856789.png"
                />
              )}
            </div>
          </div>
          <div>
            {openProfile && <UserProfile setOpenProfile={setOpenProfile} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
