const LifeInsuranceIcon = (props) => (
  <svg
    width={props?.width ? props?.width : '20'}
    height={props?.height ? props?.height : '20'}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_80_16816)">
      <path
        d="M4.99999 16.7393H7.17523C7.45885 16.7393 7.74073 16.773 8.01567 16.8405L10.3141 17.3991C10.8128 17.5206 11.3323 17.5324 11.8362 17.4345L14.3775 16.9401C15.0488 16.8093 15.6663 16.4879 16.1503 16.0171L17.9482 14.2681C18.4617 13.7695 18.4617 12.9604 17.9482 12.4609C17.4859 12.0112 16.7539 11.9606 16.2309 12.3419L14.1355 13.8707C13.8354 14.0901 13.4703 14.2082 13.0947 14.2082H11.0712L12.3592 14.2081C13.0852 14.2081 13.6732 13.6361 13.6732 12.9299V12.6743C13.6732 12.0879 13.263 11.5766 12.6784 11.4349L10.6905 10.9514C10.367 10.873 10.0357 10.8333 9.70261 10.8333C8.8986 10.8333 7.44323 11.499 7.44323 11.499L4.99999 12.5207M1.66666 12.1667L1.66666 17C1.66666 17.4667 1.66666 17.7001 1.75748 17.8783C1.83738 18.0351 1.96486 18.1626 2.12166 18.2425C2.29992 18.3333 2.53328 18.3333 2.99999 18.3333H3.66666C4.13337 18.3333 4.36672 18.3333 4.54498 18.2425C4.70178 18.1626 4.82927 18.0351 4.90916 17.8783C4.99999 17.7001 4.99999 17.4667 4.99999 17V12.1667C4.99999 11.7 4.99999 11.4666 4.90916 11.2883C4.82927 11.1315 4.70178 11.0041 4.54498 10.9242C4.36672 10.8333 4.13337 10.8333 3.66666 10.8333H2.99999C2.53328 10.8333 2.29992 10.8333 2.12166 10.9242C1.96486 11.0041 1.83738 11.1315 1.75748 11.2883C1.66666 11.4666 1.66666 11.7 1.66666 12.1667ZM14.3261 2.99354C13.8288 1.95282 12.6821 1.40148 11.567 1.93364C10.4519 2.46579 9.97682 3.72781 10.4437 4.83568C10.7322 5.52038 11.5589 6.85 12.1484 7.76585C12.3662 8.10425 12.4751 8.27345 12.6342 8.37244C12.7706 8.45734 12.9414 8.5031 13.102 8.49779C13.2892 8.4916 13.4681 8.39952 13.8259 8.21535C14.7944 7.71692 16.1751 6.97879 16.7673 6.5301C17.7256 5.80409 17.963 4.46964 17.2455 3.45519C16.5281 2.44075 15.2772 2.34093 14.3261 2.99354Z"
        stroke={
          props.isActive
            ? props.activeColor
            : props.isHovered
            ? props.hoveredColor
            : '#475467'
        }
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_80_16816">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default LifeInsuranceIcon;
