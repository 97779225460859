import React from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './Editor.styles.scss';
import 'quill/dist/quill.snow.css';
import EditorToolbar from './EditorToolbar';

const Link = Quill.import('formats/link');
Link.sanitize = function (url) {
  // quill by default creates relative links if scheme is missing.
  if (
    !url.startsWith('http://') &&
    !url.startsWith('https://') &&
    !url.startsWith('/')
  ) {
    return `http://${url}`;
  }
  return url;
};
Quill.register(Link, true);

export const RichEditor = React.forwardRef((props, ref) => {
  const {
    placeholder,
    label,
    required,
    // id,
    compId,
    disableImage,
    disableToolbar = false,
    onChange: handleChange,
    ...rest
  } = props;
  // const id = `toolbar-${Math.random().toString(36).substr(2, 9)}`;

  const formats = [
    'bold',
    'italic',
    'underline',
    'align',
    'list',
    'bullet',
    'link',
    // "image",
    'script',
    'blockquote',
  ];

  const myFormats = [...formats];
  if (!disableImage) {
    myFormats.push('image');
  }
  // const modules = {
  // toolbar: {
  //   container: `#${compId}`,
  // },
  // history: {
  //   delay: 500,
  //   maxStack: 100,
  //   userOnly: true,
  // },
  // };
  const modules = {
    toolbar: !disableToolbar
      ? {
          container: [
            ['bold', 'italic', 'underline'],
            [{ align: [] }],
            [{ list: 'ordered' }, { list: 'bullet' }],
            ['link'],
            [{ script: 'sub' }, { script: 'super' }],
            ['blockquote'],
            disableImage ? [] : ['image'],
          ],
        }
      : false,
  };
  return (
    <div className="text-editor">
      {label && (
        <label
          className={`text-sm font-medium ${
            props?.isStep1Program ? 'my-0' : 'my-1'
          } block text-gray-700`}
        >
          {label} {required && <span className="text-red-600">*</span>}
        </label>
      )}
      <ReactQuill
        preserveWhitespace
        {...rest}
        ref={ref}
        placeholder={placeholder || `type anything..`}
        modules={modules}
        formats={myFormats}
        style={{
          background: '#FFFFFF',
          boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
          borderRadius: '10px',
        }}
        onChange={(e) => {
          handleChange(e);
        }}
      />
      {/* <EditorToolbar
        id={compId}
        disableImage={disableImage}
        disableToolbar={disableToolbar}
      /> */}
    </div>
  );
});
RichEditor.displayName = 'RichEditor';
export default RichEditor;
