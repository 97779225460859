import {
  ConfigurationKeysEnum,
  EventsSettings,
  PayoutAmountsEnum,
} from 'pages/variablePay/VariablePay.types';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Configuration } from '../Configuration';

type PayoutConfigurationProps = {
  setEventSettings: Dispatch<SetStateAction<EventsSettings>>;
  eventSettings: EventsSettings;
  configuration: { [key: string]: any };
};

export const PayoutConfiguration = ({
  eventSettings,
  setEventSettings,
  configuration,
}: PayoutConfigurationProps) => {
  const [payoutConfiguration, setPayoutConfiguration] = useState(configuration);

  useEffect(() => {
    if (configuration) {
      if (
        eventSettings?.vPayPayoutAmounts?.value ===
        PayoutAmountsEnum.PRECALCULATED
      ) {
        setPayoutConfiguration({
          ...configuration,
          children: configuration?.children?.filter(
            ({ key }: { key: ConfigurationKeysEnum }) =>
              key !== ConfigurationKeysEnum.vPayCalculationField,
          ),
        });
      } else {
        setPayoutConfiguration(configuration);
      }
    }
  }, [eventSettings, configuration]);

  const handleChange = (key: ConfigurationKeysEnum, value: string) => {
    const updatedSettings = eventSettings;
    if (value === PayoutAmountsEnum.PRECALCULATED) {
      delete updatedSettings?.vPayCalculationField;
      delete updatedSettings?.vPayAchievementMeasurement;
      delete updatedSettings?.vPayAttainmentInput;
      delete updatedSettings?.vPayAttainmentDependency;
      delete updatedSettings?.vPayDependentFields;
    }
    setEventSettings({ ...updatedSettings, [key]: { value } });
  };

  return (
    <Configuration
      eventSettings={eventSettings}
      setEventSettings={setEventSettings}
      configuration={payoutConfiguration}
      handleChange={handleChange}
    />
  );
};
