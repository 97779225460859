import { XMarkIcon } from '@heroicons/react/24/solid';
import {
  getPreviewData,
  updateDetailsOfCsv,
} from 'apiClient/rangeBuilder/rangeBuilder';
import { Input } from 'components/atoms/FormElements/input/Input';
import Button from 'components/atoms/button/Button';
import TableSkeleton from 'components/atoms/skeletonLoaderTypes/TableSkeleton';
import ModalPopup from 'components/layout/modalPopup/ModalPopup';
import React, { useEffect, useRef, useState } from 'react';
import { onResponse } from 'utils/toastMessages';

const PreviewPopUp = ({ refetchData, setIsPreviewPopUp, rangeFormTypeId }) => {
  const [headerData, setHeaderData] = useState([]);
  const [mappingData, setMappingData] = useState({});
  const [rowsData, setRowsData] = useState([]);
  const [associatedCompany, setAssociatedCompany] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [dataName, setDataName] = useState('');
  const [previewLoading, setPreviewLoading] = useState(false);
  const [error, setError] = useState({
    dataName: {
      message: null,
    },
    companies: {
      message: null,
    },
  });
  const [isShowMore, setIsShowMore] = useState(false);

  useEffect(() => {
    setPreviewLoading(true);
    getPreviewData(rangeFormTypeId, 10, 0, [], '')
      .then((res) => {
        const unFilteredHeader = Object.keys(
          res?.data?.data?.map((data) => {
            return data?.uploadedJson;
          })?.[0],
        );
        setHeaderData(
          unFilteredHeader?.filter(
            (header) =>
              res?.data?.mappings?.[header]?.isVisible === true ||
              !res?.data?.mappings?.[header],
          ),
        );
        setRowsData(
          res?.data?.data?.map((data) => {
            return data?.uploadedJson;
          }),
        );
        setMappingData(res?.data?.mappings);
        setPreviewLoading(false);
      })
      .catch((err) => {
        setPreviewLoading(false);
      });
  }, [rangeFormTypeId]);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && inputValue.trim()) {
      setAssociatedCompany([...associatedCompany, inputValue.trim()]);
      setError((prevError) => ({
        ...prevError,
        companies: {
          message: null,
        },
      }));
      setInputValue('');
    }
  };

  const removeCompany = (associatedCompanyToRemove) => {
    const newCompany = associatedCompany.filter(
      (associatedCompany) => associatedCompany !== associatedCompanyToRemove,
    );
    setAssociatedCompany(newCompany);
    if (newCompany?.length) {
      setError((prevError) => ({
        ...prevError,
        companies: {
          message: null,
        },
      }));
    } else {
      setError((prevError) => ({
        ...prevError,
        companies: {
          message: 'Associated company is required.',
        },
      }));
    }
  };

  return (
    <div>
      <ModalPopup
        title={'Upload Data'}
        body={
          <div className="flex flex-col w-full">
            <div className="flex flex-col gap-6">
              <div className="flex px-4 gap-5">
                <div className="flex w-1/2">
                  <Input
                    label={'Data Name'}
                    value={dataName}
                    onChange={(e) => {
                      setDataName(e?.target?.value);
                    }}
                    onBlur={() => {
                      const name = dataName?.trim();
                      if (name?.length === 0) {
                        setError((prevError) => ({
                          ...prevError,
                          dataName: {
                            message: 'Data Name is required.',
                          },
                        }));
                      } else {
                        setError((prevError) => ({
                          ...prevError,
                          dataName: {
                            message: null,
                          },
                        }));
                      }
                    }}
                    labelClassName={'text-sm'}
                    required
                    error={
                      error?.dataName?.message?.length ? error?.dataName : false
                    }
                  />
                </div>
                <div className="flex w-1/2 flex-col gap-1">
                  <p className="text-sm font-medium text-gray-700">
                    Associated Company
                    <span className="text-sm text-error-500">*</span>
                  </p>
                  <div
                    className={`flex min-h-[46px] py-2 px-3 gap-2 items-center border ${
                      error?.companies?.message
                        ? 'border-error-500'
                        : 'border-gray-300'
                    } rounded-lg overflow-hidden`}
                  >
                    <div className="flex w-full items-center overflow-hidden gap-1.5">
                      {associatedCompany?.slice(0, 3).map((company, index) => (
                        <div
                          key={index}
                          className="flex rounded-md py-0.5 pl-1 pr-[5px] items-center gap-[3px] border border-gray-300"
                        >
                          <span className="text-sm font-medium text-gray-700 text-nowrap">
                            {company}
                          </span>
                          <XMarkIcon
                            className="w-3 h-3 stroke-gray-400 fill-gray-400 cursor-pointer"
                            onClick={() => removeCompany(company)}
                          />
                        </div>
                      ))}
                      {associatedCompany.length > 3 && (
                        <div className="flex rounded-md py-0.5 pl-1 pr-[5px] items-center gap-[3px] border border-gray-300">
                          <span
                            className="text-sm font-medium text-gray-700 cursor-pointer text-nowrap"
                            onClick={() => setIsShowMore((prev) => !prev)}
                          >
                            {' '}
                            + {associatedCompany?.length - 3} more
                          </span>
                          {isShowMore && (
                            <div className="absolute bg-white border border-gray-300 top-[132px] rounded-lg shadow-lg py-1 z-10 min-w-[195px]">
                              {associatedCompany
                                ?.slice(3)
                                .map((company, index) => (
                                  <div
                                    key={index}
                                    className="flex w-full py-0.5 px-1.5"
                                  >
                                    <div className="flex w-full justify-between items-center py-2.5 pr-2.5 pl-2 hover:bg-gray-50 rounded-md">
                                      <span className="text-sm font-medium text-gray-700">
                                        {company}
                                      </span>
                                      <XMarkIcon
                                        className="w-3 h-3 stroke-gray-400 fill-gray-400 cursor-pointer"
                                        onClick={() => removeCompany(company)}
                                      />
                                    </div>
                                  </div>
                                ))}
                            </div>
                          )}
                        </div>
                      )}
                      <input
                        type="text"
                        value={inputValue}
                        onChange={handleInputChange}
                        onBlur={() => {
                          if (inputValue.trim()) {
                            setAssociatedCompany([
                              ...associatedCompany,
                              inputValue.trim(),
                            ]);
                            setInputValue('');
                          }
                          if (
                            associatedCompany?.length === 0 &&
                            !inputValue?.trim()
                          ) {
                            setError((prevError) => ({
                              ...prevError,
                              companies: {
                                message: 'Associated company is required.',
                              },
                            }));
                          } else {
                            setError((prevError) => ({
                              ...prevError,
                              companies: {
                                message: null,
                              },
                            }));
                          }
                        }}
                        onKeyPress={handleKeyPress}
                        className="flex-grow w-full p-1 text-sm border-none outline-none min-w-[200px]"
                        placeholder="Enter company name"
                      />
                    </div>
                  </div>
                  {error?.companies?.message?.length !== 0 && (
                    <p className="text-red-500 rounded text-sm">
                      {error?.companies?.message}
                    </p>
                  )}
                </div>
              </div>
              <div className="flex w-full border-t pt-5 px-4 flex-col gap-2">
                <div className="font-figtree font-medium text-gray-900 text-xs">
                  Uploaded Data Preview
                </div>
                <div className="flex w-full overflow-x-auto">
                  {previewLoading ? (
                    <TableSkeleton count={5} />
                  ) : (
                    <table className="w-full">
                      <thead>
                        <tr className="bg-gray-50 h-7 border-t border-b border-gray-300 font-figtree text-xs font-medium text-gray-600">
                          {headerData?.map((data, index) => {
                            return (
                              <th className="px-4 text-start" key={index}>
                                {mappingData?.[data]?.name ?? data}
                              </th>
                            );
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        {rowsData?.map((rowData, index) => {
                          return (
                            <tr
                              key={index}
                              className="border-t border-b border-blue-100 h-7 font-figtree text-xs font-normal text-gray-600"
                            >
                              {headerData?.map((field, index) => {
                                return (
                                  <td className="px-4" key={index}>
                                    {rowData?.[field]}
                                  </td>
                                );
                              })}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            </div>
            <div className="flex pt-5 pb-1 px-6 gap-2.5 justify-end sticky bottom-0 bg-white">
              <Button
                onClick={() => {
                  setIsPreviewPopUp(false);
                }}
              >
                Cancel
              </Button>
              <Button
                variant="outlined"
                disabled={
                  error?.companies?.message ||
                  error?.dataName?.message ||
                  dataName?.length === 0 ||
                  associatedCompany.length === 0
                }
                onClick={() => {
                  const payload = {
                    name: dataName,
                    companies: associatedCompany,
                  };
                  updateDetailsOfCsv(rangeFormTypeId, payload)
                    .then((res) => {
                      setIsPreviewPopUp(false);
                      onResponse('Updated Successfully!');
                      refetchData();
                    })
                    .catch((err) => {
                      onResponse('Error! occurred');
                    });
                }}
              >
                Submit
              </Button>
            </div>
          </div>
        }
        onClose={() => {
          setIsPreviewPopUp(false);
        }}
        width={'w-[1080px]'}
      />
    </div>
  );
};

export default PreviewPopUp;
