const ManageCompensationPlanIcon = ({
  isActive,
  activeColor,
  isHovered,
  hoveredColor,
  width,
  height,
}) => (
  <svg
    width={width ? width : '20'}
    height={height ? height : '20'}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.99999 14.1667C9.99999 16.4679 11.8655 18.3333 14.1667 18.3333C16.4678 18.3333 18.3333 16.4679 18.3333 14.1667C18.3333 11.8655 16.4678 10 14.1667 10C11.8655 10 9.99999 11.8655 9.99999 14.1667ZM9.99999 14.1667C9.99999 13.2285 10.3101 12.3627 10.8333 11.6663V4.16667M9.99999 14.1667C9.99999 14.8545 10.1666 15.5033 10.4618 16.0751C9.75972 16.6681 8.13819 17.0833 6.24999 17.0833C3.71868 17.0833 1.66666 16.3371 1.66666 15.4167V4.16667M10.8333 4.16667C10.8333 5.08714 8.7813 5.83333 6.24999 5.83333C3.71868 5.83333 1.66666 5.08714 1.66666 4.16667M10.8333 4.16667C10.8333 3.24619 8.7813 2.5 6.24999 2.5C3.71868 2.5 1.66666 3.24619 1.66666 4.16667M1.66666 11.6667C1.66666 12.5871 3.71868 13.3333 6.24999 13.3333C8.07416 13.3333 9.64943 12.9458 10.3872 12.3848M10.8333 7.91667C10.8333 8.83714 8.7813 9.58333 6.24999 9.58333C3.71868 9.58333 1.66666 8.83714 1.66666 7.91667"
      stroke={isActive ? activeColor : isHovered ? hoveredColor : '#475467'}
      strokeWidth="1.67"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default ManageCompensationPlanIcon;
