import React from 'react';
import Image from '../../atoms/imageAtom/ImageAtom';

const ImageComponent = (props) => {
  const { data } = props;
  const { selectAtomsMolecules, type } = data;
  return (
    <div
      onClick={() => selectAtomsMolecules(type)}
      className={`h-full  w-full flex justify-center items-center cursor-pointer p-4`}
    >
      <Image data={data} />
    </div>
  );
};

export default ImageComponent;
