import ToastNotify from 'pages/surface/ToastNotify';
import { toast } from 'react-toastify';

const ToastMessages = (props) => {
  switch (props.data.cta2) {
    case 'Approve':
      return `${props.data.title} approved`;
    case 'Accept':
      return `${props.data.title} accepted`;
    case 'Reject':
      return `${props.data.title} rejected`;
    default:
      return 'Success';
  }
};

export const onResponse = (message) => {
  toast(<ToastNotify message={message} close={() => {}} />, {
    onClose: () => {},
    autoClose: 2000,
    className:
      'toast-message absolute w-full h-full py-4 px-[30px] bg-white gap-4 flex flex-col border border-gray-200 shadow-md rounded-lg',
    zIndex: 1000,
  });
};

export default ToastMessages;
