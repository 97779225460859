import React from 'react';
import { useFormContext } from 'react-hook-form';
import Heading from '../../atoms/heading/Heading';

const HeadingComponent = (props) => {
  const { data } = props;
  const { path } = data;
  const { watch } = useFormContext();
  const justifyContent = watch(`${path}.flex.justify`);
  return (
    <div
      style={{ justifyContent: justifyContent ?? 'center' }}
      className={`w-full h-full flex flex-col p-4`}
    >
      <Heading data={data} />
    </div>
  );
};

export default HeadingComponent;
