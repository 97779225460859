import React from 'react';

function Timeline({ data, bodyData }) {
  return (
    <div>
      {data?.itemList?.length > 0 ? (
        <div className="flex flex-col ">
          {data?.itemList?.map((item, index) => {
            return (
              <div key={index} className="flex gap-4">
                <div className="w-6 flex flex-col items-center ">
                  {data?.itemStyle === 'number' ? (
                    <div
                      style={{ backgroundColor: data?.bulletDefaultColor }}
                      className={`w-6  flex justify-center items-center h-6  rounded-full text-white text-xs font-semibold`}
                    >
                      {index + 1}
                    </div>
                  ) : (
                    <div>
                      {' '}
                      <div
                        style={{
                          borderColor: item?.isBulletActive
                            ? data.bulletActiveColor
                            : data?.bulletDefaultColor,
                        }}
                        className={`border rounded-full`}
                      >
                        <div
                          style={{
                            backgroundColor: item?.isBulletActive
                              ? data.bulletActiveColor
                              : data?.bulletDefaultColor,
                          }}
                          className={`rounded-full w-2.5 h-2.5 m-1.5`}
                        ></div>
                      </div>
                    </div>
                  )}

                  {index === data?.itemList?.length - 1 ? null : (
                    <div
                      style={{ backgroundColor: data?.barColor }}
                      className={`w-0.5 h-[calc(100%-28px)] my-1`}
                    ></div>
                  )}
                </div>
                <div className="w-full">
                  <div className="h-6 flex items-center ">
                    <p className="text-sm font-semibold">{item?.title}</p>
                  </div>
                  {/* <div>{item?.body}</div> */}
                  <div>{bodyData[index]}</div>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div>No Items to render.</div>
      )}
    </div>
  );
}

export default Timeline;
