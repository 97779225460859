import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  surveyId: {
    pre: '',
    post: '',
  },
  surveyName: {
    pre: '',
    post: '',
  },
  createSurvey: false,
};

const eventReducer = createSlice({
  name: 'event',
  initialState,
  reducers: {
    setSurveyId(state, action) {
      const { type, id } = action.payload;
      state.surveyId = {
        ...state.surveyId,
        [type]: id,
      };
      state.surveyId = action.payload;
    },
    setSurveyName(state, action) {
      const { type, value } = action.payload;
      state.surveyName = {
        ...state.surveyName,
        [type]: value,
      };
    },
    setCreateSurvey(state, action) {
      state.createSurvey = action.payload;
    },
  },
});

export const { setSurveyId, setCreateSurvey, setSurveyName } =
  eventReducer.actions;
export default eventReducer.reducer;
