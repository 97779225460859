import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from '@headlessui/react';
import { ArrowLeftIcon, ChevronDownIcon } from '@heroicons/react/24/outline';
import CircleIcon from 'assets/svg/CircleIcon';
import Button from 'components/atoms/button/Button';
import { CheckBoxProgram } from 'components/atoms/FormElements/input/Input';
import PopUp from 'components/atoms/popUp/PopUp';
import TableSkeleton from 'components/atoms/skeletonLoaderTypes/TableSkeleton';
import React, { useEffect, useState } from 'react';
import FilterSearch from './SearchFilter';

const ViewFiltersSidePanel = ({
  filters,
  setFilters,
  setSelectedValues,
  filtersList,
  setSelectedColumnId,
  selectedValues,
  handleIndividualSelect,
  selectedColumnId,
  filterValues,
  handleSelectAll,
  filterValuesLoading,
  handleSave,
  setIsFilterPanelOpen,
}) => {
  const [filterValuesData, setFilterValuesData] = useState([]);
  const [initialData, setInitialData] = useState([]);

  useEffect(() => {
    if (filterValues) {
      const newArray = filterValues?.cellValues?.flat();
      setFilterValuesData(newArray);
      setInitialData(newArray);
    }
  }, [filterValues]);

  return (
    <div className="flex flex-col gap-2 bg-white py-4">
      <div className="flex items-center px-2 justify-between ">
        <div className="flex gap-2 items-center">
          <Button className="py-3" onClick={() => setIsFilterPanelOpen(false)}>
            <ArrowLeftIcon className="w-5 h-5 text-gray-900" />
          </Button>
          <span className="text-lg text-gray-900 font-semibold">Filters</span>
        </div>
        {Object.keys(filters)?.length > 0 ? (
          <div
            className="flex gap-2 cursor-pointer px-3"
            onClick={() => {
              setSelectedValues({});
              setFilters({});
            }}
          >
            <span className="text-transparent cursor-pointer bg-clip-text bg-gradient-to-br from-primaryLeft to-primaryRight text-base font-medium">
              X
            </span>
            <span className="text-transparent cursor-pointer bg-clip-text bg-gradient-to-br from-primaryLeft to-primaryRight font-medium text-base">
              Clear All
            </span>
          </div>
        ) : null}
      </div>
      <div className="flex flex-col gap-2 w-full overflow-y-auto h-[calc(100vh-200px)]">
        {filtersList?.map((item, index) => (
          <Disclosure key={index}>
            <DisclosureButton
              className="bg-gray-50 h-full group flex gap-1 px-4 items-center relative justify-between w-full"
              onClick={() => setSelectedColumnId(item?.columnId)}
            >
              <div className="w-20%">
                <CircleIcon />
              </div>
              <div className="flex flex-col cursor-pointer items-start gap-1 pl-2">
                <span className="pt-1.5 pb-0.5 text-gray-900 text-xs font-medium capitalize">
                  {item?.value}
                </span>
                <div className="truncate w-80 flex gap-1">
                  {selectedValues[item?.columnId]?.length > 0 ? (
                    selectedValues[item?.columnId]?.map(
                      (value, index, array) => (
                        <span
                          key={index}
                          className="text-xs text-gray-500 font-medium"
                        >
                          {value}
                          {index !== array?.length - 1 && ','}
                        </span>
                      ),
                    )
                  ) : (
                    <span className="text-xs text-gray-500 font-medium">
                      (All)
                    </span>
                  )}
                </div>
              </div>
              <ChevronDownIcon className="size-5 fill-white/60 group-data-[hover]:fill-white/50 group-data-[open]:rotate-180" />
            </DisclosureButton>
            <DisclosurePanel>
              <div className="flex bg-gray-50 flex-col p-4">
                <FilterSearch
                  filterValuesData={filterValuesData}
                  setFilterValuesData={setFilterValuesData}
                  initialData={initialData}
                />
                <div className="flex gap-2 items-center">
                  <CheckBoxProgram
                    checked={filterValues?.cellValues
                      ?.flat()
                      ?.every((row) =>
                        selectedValues[item?.columnId]?.includes(row?.value),
                      )}
                    onChange={() =>
                      handleSelectAll(
                        item?.columnId,
                        filterValues?.cellValues
                          ?.flat()
                          ?.map((row) => row?.value),
                      )
                    }
                  />
                  <span className="text-sm font-medium text-gray-900">
                    Select All
                  </span>
                </div>
                <div className="flex flex-col gap-2 overflow-auto max-h-52 h-48 py-4 my-2 pr-3">
                  {filterValuesLoading ? (
                    <TableSkeleton />
                  ) : (
                    filterValues?.cellValues?.length > 0 &&
                    filterValuesData?.map((row, index) => (
                      <div
                        className="flex justify-between items-center"
                        key={row?.rowId}
                      >
                        <span className="text-sm font-medium text-gray-900">
                          {row?.value}
                        </span>
                        <CheckBoxProgram
                          checked={selectedValues[item?.columnId]?.includes(
                            row?.value,
                          )}
                          onChange={() =>
                            handleIndividualSelect(item?.columnId, row?.value)
                          }
                        />
                      </div>
                    ))
                  )}
                </div>
              </div>
            </DisclosurePanel>
          </Disclosure>
        ))}
      </div>
      <div className="flex gap-2 justify-end items-center pr-3 pt-1">
        <Button onClick={() => setIsFilterPanelOpen(false)}>Cancel</Button>
        <Button variant="filled" onClick={handleSave}>
          Apply
        </Button>
      </div>
    </div>
  );
};

export default ViewFiltersSidePanel;
