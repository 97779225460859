export const PendingTasksIcon = (props) => {
  return (
    <svg
      width="48"
      height="49"
      viewBox="0 0 48 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="0.75"
        y="1.5625"
        width="46.5"
        height="46.5"
        rx="7.25"
        fill="white"
      />
      <rect
        x="0.75"
        y="1.5625"
        width="46.5"
        height="46.5"
        rx="7.25"
        stroke="url(#paint0_linear_1643_52538)"
        strokeWidth="1.5"
      />
      <path
        d="M28 16.8125C28.93 16.8125 29.395 16.8125 29.7765 16.9147C30.8117 17.1921 31.6204 18.0008 31.8978 19.036C32 19.4175 32 19.8825 32 20.8125V30.0125C32 31.6927 32 32.5327 31.673 33.1745C31.3854 33.739 30.9265 34.1979 30.362 34.4855C29.7202 34.8125 28.8802 34.8125 27.2 34.8125H20.8C19.1198 34.8125 18.2798 34.8125 17.638 34.4855C17.0735 34.1979 16.6146 33.739 16.327 33.1745C16 32.5327 16 31.6927 16 30.0125V20.8125C16 19.8825 16 19.4175 16.1022 19.036C16.3796 18.0008 17.1883 17.1921 18.2235 16.9147C18.605 16.8125 19.07 16.8125 20 16.8125M21 27.8125L23 29.8125L27.5 25.3125M21.6 18.8125H26.4C26.9601 18.8125 27.2401 18.8125 27.454 18.7035C27.6422 18.6076 27.7951 18.4547 27.891 18.2665C28 18.0526 28 17.7726 28 17.2125V16.4125C28 15.8524 28 15.5724 27.891 15.3585C27.7951 15.1703 27.6422 15.0174 27.454 14.9215C27.2401 14.8125 26.9601 14.8125 26.4 14.8125H21.6C21.0399 14.8125 20.7599 14.8125 20.546 14.9215C20.3578 15.0174 20.2049 15.1703 20.109 15.3585C20 15.5724 20 15.8524 20 16.4125V17.2125C20 17.7726 20 18.0526 20.109 18.2665C20.2049 18.4547 20.3578 18.6076 20.546 18.7035C20.7599 18.8125 21.0399 18.8125 21.6 18.8125Z"
        stroke="url(#paint1_linear_1643_52538)"
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1643_52538"
          x1="0"
          y1="48.8125"
          x2="48"
          y2="48.8125"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="var(--primaryLeft)" />
          <stop offset="1" stopColor="var(--primaryRight)" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1643_52538"
          x1="16"
          y1="34.8125"
          x2="32"
          y2="34.8125"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="var(--primaryLeft)" />
          <stop offset="1" stopColor="var(--primaryRight)" />
        </linearGradient>
      </defs>
    </svg>
  );
};
