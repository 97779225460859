export const links = [
  { label: 'Surface home', value: 'surface' },
  { label: 'My compensation', value: 'compensation/rewards-philosophy' },
  { label: 'ESOP', value: '/profile/Esop' },
  { label: 'Benefits dashboard', value: 'benefits/benefits-dashboard' },
  { label: 'Recognition summary', value: 'redeem/rewards' },
  {
    label: 'Profile Summary',
    value: 'profile/5875d55e-4b7e-46fa-bee5-7ff891898053',
  },
  { label: 'Nominees and dependents', value: '/profile/Esop' },
  { label: 'Policies page', value: 'policies/all-policies' },
];

export const headingAlignments = ['start', 'center', 'end', 'justify'];

const generateFontSizeList = (start, end) => {
  const fontSizeList = [];

  for (let i = start; i <= end; i++) {
    fontSizeList.push({ label: i, value: i });
  }

  return fontSizeList;
};

export const fontSize = generateFontSizeList(4, 108);

export const objectFit = [
  { value: 'object-fit', label: 'Fill' },
  { value: 'object-contain', label: 'Contain' },
  { value: 'object-cover', label: 'Cover' },
  { value: 'object-scale-down', label: 'Scale down' },
];

export const fontWeight = [
  { value: 'lighter', label: 'Lighter' },
  { value: 'normal', label: 'Normal' },
  { value: 'bold', label: 'Bold' },
  { value: '100', label: '100' },
  { value: '200', label: '200' },
  { value: '300', label: '300' },
  { value: '400', label: '400' },
  { value: '500', label: '500' },
  { value: '600', label: '600' },
  { value: '700', label: '700' },
  { value: '800', label: '800' },
  { value: '900', label: '900' },
];

export const fontStyle = [
  { value: 'normal', label: 'Normal' },
  { value: 'italic', label: 'Italic' },
];

export const fontPadding = generateFontSizeList(4, 50);
