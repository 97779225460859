import {
  CloudArrowDownIcon,
  DocumentCheckIcon,
} from '@heroicons/react/24/outline';
import Button from 'components/atoms/button/Button';
import ModalDialogue from 'components/atoms/modalDialogue/ModalDialogue';
import React, { useEffect, useState } from 'react';
import ValidateModal from './ValidateModal';
import { useGetPreviewSampleData } from 'query/dashboard/ManageDashboard';
import TableSkeleton from 'components/atoms/skeletonLoaderTypes/TableSkeleton';
import Select from 'components/atoms/FormElements/select/Select';
import { set } from 'lodash';
import {
  downloadValidateFile,
  validateFile,
} from 'apiClient/dashboardBuilder/DashboardBuilder';
import { onResponse } from 'utils/toastMessages';
import { PreviewCustomStyles } from './PreviewCustomStyles';
import { downloadDataToCsv } from 'utils/downloadDataToCsv';

const PreviewModal = ({
  setIsPreviewSelected,
  isPreviewSelected,
  dashboardId,
  previewDataId,
}) => {
  const [isValidateSelected, setIsValidateSelected] = useState(false);
  const [previewData, setPreviewData] = useState([]);
  const [validateData, setValidateData] = useState([]);
  const [selectedValue, setSelectedValue] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [isValidateLoading, setIsValidateLoading] = useState(false);

  const handleValidateData = async () => {
    const missingTypes = validateData?.find((data) => data?.type === undefined);
    if (
      validateData?.length !== previewData?.rows[0]?.cells?.length ||
      missingTypes
    ) {
      setErrorMessage('Match all the fields in order to proceed');
      return;
    }
    setErrorMessage('');
    setIsValidateLoading(true);
    try {
      await validateFile(validateData, previewDataId, dashboardId);
      setIsValidateSelected(true);
      setErrorMessage('');
    } catch (err) {
      setIsValidateLoading(false);
      onResponse(err?.message, 'error');
    }
    setIsValidateLoading(false);
  };

  const {
    data,
    isLoading,
    isError,
    refetch: getPreviewTable,
  } = useGetPreviewSampleData(previewDataId, dashboardId);

  useEffect(() => {
    if (data) {
      setPreviewData(data);
      setSelectedValue(
        data?.columns?.reduce(
          (acc, item) => ({
            ...acc,
            [item?.id]: item?.type,
          }),
          {},
        ),
      );
      setValidateData(
        data?.columns?.map((item) => ({
          columnId: item?.id,
          type: item?.type,
        })),
      );
    }
  }, [data]);

  const handleDownload = async () => {
    try {
      const res = await downloadValidateFile(previewDataId, dashboardId);
      downloadDataToCsv(res?.csvData, res?.tableName);
    } catch (error) {
      onResponse(error?.message);
    }
  };

  const selectOptions = [
    { value: 'Integer', label: 'Integer' },
    { value: 'Text', label: 'Text' },
    { value: 'Date', label: 'Date' },
    { value: 'Number', label: 'Number' },
    { value: 'Percentage', label: 'Percentage' },
    { value: 'Employee', label: 'Employee' },
  ];

  const handleSelectChange = (event, index) => {
    const newType = event?.value;
    const selectedColumnId = previewData?.rows[0]?.cells[index]?.columnId;
    const newColumn = {
      columnId: selectedColumnId,
      type: newType,
    };

    setValidateData((prevData) => {
      // Check if columnId already exists
      const columnIndex = prevData.findIndex(
        (item) => item?.columnId === selectedColumnId,
      );

      if (columnIndex !== -1) {
        // Update existing column dataType
        const updatedData = [...prevData];
        updatedData[columnIndex] = newColumn;
        return updatedData;
      } else {
        // Add new column
        return [...prevData, newColumn];
      }
    });
    setSelectedValue((prev) => ({
      ...prev,
      [selectedColumnId]: newType,
    }));
  };

  const previewArrayLength = previewData?.rows?.[0]?.cells?.length ?? 0;
  const arrayOfLength = Array.from(
    { length: previewArrayLength },
    (_, index) => index,
  );

  return isValidateSelected ? (
    <ValidateModal
      isValidateSelected={isValidateSelected}
      setIsValidateSelected={setIsValidateSelected}
      dashboardId={dashboardId}
      previewDataId={previewDataId}
      setIsPreviewSelected={setIsPreviewSelected}
    />
  ) : (
    <ModalDialogue
      isModalOpen={isPreviewSelected}
      onClose={() => setIsPreviewSelected(false)}
      width="70%"
    >
      {isLoading ? (
        <div className="w-full">
          <TableSkeleton />
        </div>
      ) : (
        <div className="flex flex-col justify-between gap-5 mt-5">
          <div className="flex justify-between items-center">
            <span className="text-base text-gray-900 font-semibold">
              Data Preview
            </span>
            <div className="flex justify-between items-center gap-2">
              <Button
                className="flex justify-between gap-2"
                onClick={handleDownload}
              >
                <CloudArrowDownIcon className="w-5 h-5 text-gray-900" />
                <span>Download</span>
              </Button>
              {isValidateLoading ? (
                <Button>Validating...</Button>
              ) : (
                <Button
                  className="flex justify-between gap-2"
                  onClick={() => handleValidateData()}
                >
                  <DocumentCheckIcon className="w-5 h-5 text-gray-900" />
                  <span>Validate</span>
                </Button>
              )}
            </div>
          </div>
          <div className="w-full">
            <table className="w-full">
              <thead className=" bg-gray-50">
                <tr>
                  <th className="text-gray-600 font-figtree text-xs font-medium py-3 pl-2.5 pr-4 border border-l-0 border-gray-300 text-left">
                    Uploaded Columns
                  </th>
                  <th className=" text-gray-600 font-figtree text-xs font-medium py-3 pl-2.5 pr-4 border border-l-0 border-gray-300 text-left">
                    Sample Data
                  </th>
                  <th className=" text-gray-600 font-figtree text-xs font-medium py-3 pl-2.5 pr-4 border border-gray-300 border-r-0 border-l-0 text-left">
                    Uploaded DataType
                  </th>
                </tr>
              </thead>
              <tbody>
                {arrayOfLength?.map((data, index) => (
                  <tr key={index}>
                    <td className="text-gray-600 font-figtree text-xs font-normal py-1.5 pl-2.5 pr-4 border border-gray-300  border-l-0">
                      {previewData?.rows[0]?.cells[index]?.value || 'Value 1'}
                    </td>
                    <td className=" text-gray-600 font-figtree text-xs font-medium py-1.5 pl-2.5 pr-4 border border-gray-300 border-l-0">
                      {previewData?.rows[1]?.cells[index]?.value || 'Value 2'}
                    </td>
                    <td className=" text-gray-600 font-figtree text-xs font-medium py-1.5 pl-2.5 pr-4 border border-gray-300 border-r-0 border-l-0">
                      {/* <Select
                        options={selectOptions}
                        onChange={(event) => handleSelectChange(event, index)}
                        // value={
                        //   selectedValue[
                        //     previewData?.rows[0]?.cells[index]?.columnId
                        //   ]
                        // }
                      /> */}
                      <div className="w-28">
                        <Select
                          placeholder={'Select'}
                          isClearable
                          options={selectOptions}
                          onChange={(event) => handleSelectChange(event, index)}
                          customStyling={PreviewCustomStyles}
                          value={selectOptions?.find(
                            (option) =>
                              option?.value ===
                              selectedValue[
                                previewData?.rows[0]?.cells[index]?.columnId
                              ],
                          )}
                        />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {errorMessage?.length > 0 && (
            <span className="text-red-500 text-sm-13 font-figtree font-medium">
              {errorMessage}
            </span>
          )}
        </div>
      )}
    </ModalDialogue>
  );
};

export default PreviewModal;
