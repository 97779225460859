const initialState = {
  standardPrograms: [],
  flexAmountPrograms: [],
  flexDeductionPrograms: [],
};
const { createSlice } = require('@reduxjs/toolkit');

const manageProgramsSlice = createSlice({
  name: 'managePrograms',
  initialState,
  reducers: {
    setStandardPrograms: (state, action) => {
      state.standardPrograms = [...action.payload];
    },
    setFlexAmountPrograms: (state, action) => {
      state.flexAmountPrograms = action.payload;
    },
    setFlexDeductions: (state, action) => {
      state.flexDeductionPrograms = action.payload;
    },
    createFlexAmountProgram: (state, action) => {
      state.flexAmountPrograms = [action.payload, ...state.flexAmountPrograms];
    },
    createStandardProgram: (state, action) => {
      state.standardPrograms = [action.payload, ...state.standardPrograms];
    },
    createFlexDeductionProgram: (state, action) => {
      state.flexDeductionPrograms = [
        action.payload,
        ...state.flexDeductionPrograms,
      ];
    },
    editFlexAmountProgram: (state, action) => {
      state.flexAmountPrograms = state.flexAmountPrograms?.map((program) => {
        if (program?.id === action.payload?.id) {
          return { ...action.payload };
        }
        return program;
      });
    },
    editStandardProgram: (state, action) => {
      state.standardPrograms = state.standardPrograms?.map((program) => {
        if (program?.id === action.payload.id) {
          return { ...action.payload };
        }
        return program;
      });
    },
    editFlexDeductionProgram: (state, action) => {
      state.flexDeductionPrograms = state.flexDeductionPrograms?.map(
        (program) => {
          if (program?.id === action.payload?.id) {
            return { ...action.payload };
          }
          return program;
        },
      );
    },
    clearProgramData: (state, atcion) => {
      state.standardPrograms = [];
      state.flexAmountPrograms = [];
      state.flexDeductionPrograms = [];
    },
    setProgramsData: (state, action) => {
      action.payload?.map((program) => {
        if (program?.programType === 'STANDARD') {
          state.standardPrograms = [...state.standardPrograms, { ...program }];
        } else if (program?.programType === 'FLEX_AMOUNT') {
          state.flexAmountPrograms = [
            ...state.flexAmountPrograms,
            { ...program },
          ];
        } else if (program?.programType === 'FLEX_DEDUCTION') {
          state.flexDeductionPrograms = [
            ...state.flexDeductionPrograms,
            { ...program },
          ];
        }
        return null;
      });
    },
  },
});

export const {
  setFlexDeductions,
  setFlexAmountPrograms,
  setStandardPrograms,
  createFlexAmountProgram,
  createFlexDeductionProgram,
  createStandardProgram,
  editFlexAmountProgram,
  editFlexDeductionProgram,
  editStandardProgram,
  setProgramsData,
  clearProgramData,
} = manageProgramsSlice.actions;
export default manageProgramsSlice.reducer;
export const manageProgramsSelector = (state) => state.managePrograms;
