import ButtonLoader from 'assets/svg/buttonLoader';
import PencilIcon from 'assets/svg/pencilIcon';
import { RefreshGradientIcon } from 'assets/svg/refreshGradienIcon';
import Button from 'components/atoms/button/Button';
import ModalPopup from 'components/layout/modalPopup/ModalPopup';

export const RefreshConfirmModal = ({
  setModalVisible,
  confirmType,
  onClick,
  loading,
}) => {
  let icon;
  let text;
  let descriptionText;
  let buttonText;

  switch (confirmType) {
    case 'UN_ENROL': {
      text = 'Refresh Internal Data';
      descriptionText =
        'This will sync the data with your Masters and will reflect the changes immediately.';
      icon = (
        <div className="border-[1.5px] border-primaryLeft rounded-[10px] p-2 flex items-center justify-center">
          <PencilIcon />
        </div>
      );
      buttonText = loading ? (
        <>
          <ButtonLoader />
          Loading..
        </>
      ) : (
        'Refresh'
      );
      break;
    }
  }

  return (
    <ModalPopup
      onClose={() => setModalVisible(false)}
      title={
        <div className="border-[1.5px] border-primaryActive rounded-[10px] w-12 h-12 flex items-center justify-center">
          <RefreshGradientIcon />
        </div>
      }
      width="max-w-[400px]"
      body={
        <div className="px-6">
          <p className="text-lg font-semibold text-gray-900">
            Refresh Internal Data
          </p>
          <p className="text-sm text-gray-600 mb-5">
            This will sync the data with your Masters and will reflect the
            changes immediately.
          </p>
          <div className="flex items-center justify-end border-t border-gray-200 px-6 py-4 gap-[18px]">
            <button
              className="text-sm font-semibold text-gray-600"
              onClick={() => setModalVisible(false)}
            >
              Cancel
            </button>
            <Button
              variant="filled"
              className="text-sm font-semibold"
              onClick={onClick}
            >
              Refresh
            </Button>
          </div>
        </div>
      }
    />
  );
};
