import { z } from 'zod';
import {
    type TDependencyValueMap,
    ZBatchFormulaInput,
    TBatchFormulaMap,
    TDependencyArrayMap
} from '../types';
import { createServiceWithZod } from '../utils/createService';
import { getDependencyArrayMap } from '../cyclicDependency';
import { atomicFormula } from '../atomic';

export const batchFormula = createServiceWithZod(
    ZBatchFormulaInput,
    z.record(z.string(), z.any()),
    async (input) => {
        try {
            const dependencyArrayMap = getDependencyArrayMap(input.formulaMap);

            const newDependencyValueMap = input.dependencyValueMap;

            const formulaKeys = Object.keys(input.formulaMap);

            const visitedFormulaMap = formulaKeys.reduce(
                (prev, curr) => ({
                    ...prev,
                    [curr]: false
                }),
                {} as { [key: string]: boolean }
            );

            for (const formulaKey of formulaKeys) {
                if (newDependencyValueMap[formulaKey] === undefined) {
                    await recursiveCalculate(
                        formulaKey,
                        newDependencyValueMap,
                        input.formulaMap,
                        dependencyArrayMap,
                        visitedFormulaMap
                    );
                }
            }

            return newDependencyValueMap;
        } catch (error) {
            const newDependencyValueMap = input.dependencyValueMap;

            const formulaKeys = Object.keys(input.formulaMap);
            for (const formulaKey of formulaKeys) {
                if (newDependencyValueMap[formulaKey] === undefined) {
                    newDependencyValueMap[formulaKey] = null;
                }
            }

            return newDependencyValueMap;
        } finally {
        }
    }
);

const recursiveCalculate = async (
    formulaKey: string,
    dependencyValueMap: TDependencyValueMap,
    formulaMap: TBatchFormulaMap,
    dependencyArrayMap: TDependencyArrayMap,
    visitedFormulaMap: { [key: string]: boolean }
) => {
    try {
        if (visitedFormulaMap[formulaKey] === true) {
            if (dependencyValueMap[formulaKey] === undefined) {
                dependencyValueMap[formulaKey] = null;
            }
            return;
        }

        visitedFormulaMap[formulaKey] = true;

        const formulaString = formulaMap[formulaKey].formulaString;

        const allDependencyValueExist = dependencyArrayMap[formulaKey].every(
            (dependency) => dependencyValueMap[dependency] !== undefined
        );

        if (allDependencyValueExist === false) {
            for (const dependencyKey of dependencyArrayMap[formulaKey]) {
                if (dependencyValueMap[dependencyKey] === undefined) {
                    await recursiveCalculate(
                        dependencyKey,
                        dependencyValueMap,
                        formulaMap,
                        dependencyArrayMap,
                        visitedFormulaMap
                    );
                }
            }
        }

        if (dependencyValueMap[formulaKey] === undefined) {
            dependencyValueMap[formulaKey] = await atomicFormula({
                dependencyValueMap,
                formulaString
            });
        }
    } catch {
        dependencyValueMap[formulaKey] = null;
    }
};
