import * as React from 'react';
const QuickLinkIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      stroke={props.isActive ? props.activeColor : '#475467'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.67}
      d="M10.59 15.303 9.41 16.482a4.167 4.167 0 1 1-5.892-5.893l1.178-1.178m10.607 1.178 1.178-1.178a4.167 4.167 0 1 0-5.892-5.893L9.41 4.697m-2.327 8.22 5.833-5.834"
    />
  </svg>
);
export default QuickLinkIcon;
