import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface initialStateType {
  manageInsurance: {
    coverageSelectedRow: Array<number | string>;
    showCreateNewPlanModal: boolean;
  };
}

const initialState: initialStateType = {
  manageInsurance: {
    coverageSelectedRow: [],
    showCreateNewPlanModal: false,
  },
};

export const insuranceSlice = createSlice({
  name: 'insurance',
  initialState: initialState,
  reducers: {
    setCoverageSelectedRow: (
      state,
      action: PayloadAction<Array<number | string>>,
    ) => {
      state.manageInsurance.coverageSelectedRow = action.payload;
      return state;
    },
    setShowCreateNewPlanModal(state, action) {
      state.manageInsurance.showCreateNewPlanModal = action.payload;
      return state;
    },
  },
});

export default insuranceSlice?.reducer;
export const { setCoverageSelectedRow, setShowCreateNewPlanModal } =
  insuranceSlice.actions;
