import { getOtp, verifyOtp } from 'apiClient/hiringOffer/hiringOffer';
import ButtonLoader from 'assets/svg/buttonLoader';
import Button from 'components/atoms/button/Button';
import { Input } from 'components/atoms/FormElements/input/Input';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { imageURLCheck } from 'utils/ImageUrlCheck';

const LoginWithOTP = (props) => {
  const { orgDetails, setShowLogin, value, email, handleResponse } = props;
  const { setValue } = useFormContext();
  const [buttonLoading, setButtonLoading] = useState(false);
  const [isOtpSent, setIsOtpSent] = useState(
    localStorage.getItem('isOtpSent') === 'true',
  );
  const [OTP, setOTP] = useState([]);
  const [timeLeft, setTimeLeft] = useState(0);
  const [canResend, setCanResend] = useState(false);
  const [resendAttempts, setResendAttempts] = useState(
    Number(localStorage.getItem('resendAttempts')) || 0,
  );
  useEffect(() => {
    localStorage.setItem('isOtpSent', isOtpSent);
  }, [isOtpSent]);

  useEffect(() => {
    const storedTime = localStorage.getItem('otpSentTime');
    const lastResendAttempt = Number(localStorage.getItem('lastResendAttempt'));
    if (storedTime) {
      const currentTime = new Date().getTime();
      const timeDiff = currentTime - storedTime;
      const timeRemaining = 60 - Math.floor(timeDiff / 1000);
      if (timeRemaining > 0) {
        setTimeLeft(timeRemaining);
        setCanResend(false);
      } else {
        setTimeLeft(0);
        setCanResend(resendAttempts < 3);
      }
    } else {
      setCanResend(resendAttempts < 3);
    }

    // Check if 15 minutes have passed since the last resend attempt after reaching the limit
    if (resendAttempts >= 3 && lastResendAttempt) {
      const timePassed = (new Date().getTime() - lastResendAttempt) / 1000;
      if (timePassed >= 900) {
        setResendAttempts(0);
        setCanResend(true);
        localStorage.setItem('resendAttempts', '0');
        localStorage.removeItem('lastResendAttempt');
      } else {
        setTimeLeft(Math.ceil(900 - timePassed));
        setCanResend(false);
      }
    }

    const timerInterval = setInterval(() => {
      if (timeLeft > 0) {
        setTimeLeft((prev) => prev - 1);
      } else {
        clearInterval(timerInterval);
        setCanResend(resendAttempts < 3);
      }
    }, 1000);

    return () => clearInterval(timerInterval);
  }, [timeLeft, resendAttempts]);

  const handleButtonClick = (type) => {
    if (isOtpSent && type !== 'resend') {
      const body = {
        value: value,
        otp: Number(OTP?.join('')),
      };
      setButtonLoading(true);
      verifyOtp(body)
        .then((response) => {
          console.log(response);
          handleResponse('OTP verified successfully');
          setShowLogin(false);
          localStorage.setItem('candidateAccess', response?.data?.value);
          localStorage.setItem('accessType', response?.data?.access);
        })
        .catch((error) => {
          handleResponse(error?.message);
        })
        .finally(() => setButtonLoading(false));
    } else {
      const body = {
        value: value,
        organizationName: orgDetails?.orgName,
        organizationLogoUrl: orgDetails?.organizationDetails?.logo,
      };
      getOtp(body)
        .then(() => {
          setIsOtpSent(true);
          setResendAttempts((prev) => prev + 1);
          localStorage.setItem('resendAttempts', resendAttempts + 1);
          if (resendAttempts + 1 >= 3) {
            localStorage.setItem('lastResendAttempt', new Date().getTime());
          }
        })
        .catch((error) => handleResponse(error?.message))
        .finally(() => setButtonLoading(false));
    }
  };

  const handleInputChange = (e, index) => {
    const { value } = e.target;
    if (/^[0-9]$/.test(value) || value === '') {
      const newOTP = [...OTP];
      newOTP[index] = value;
      setOTP(newOTP);

      // Move focus to the next input box
      if (value !== '' && index < 5) {
        document.getElementById(`otp-input-${index + 1}`)?.focus();
      }
    }
  };
  const handleKeyDown = (e, index) => {
    // Handle backspace to move to the previous input box
    if (e.key === 'Backspace' && OTP[index] === '' && index > 0) {
      document.getElementById(`otp-input-${index - 1}`)?.focus();
    }
  };

  useEffect(() => {
    // Function to calculate the time left for resend
    const calculateTimeLeft = () => {
      const storedTime = localStorage.getItem('otpSentTime');
      if (storedTime) {
        const currentTime = new Date().getTime();
        const timeDiff = currentTime - storedTime;
        const timeRemaining = 60 - Math.floor(timeDiff / 1000);
        if (timeRemaining > 0) {
          setTimeLeft(timeRemaining);
          setCanResend(false);
        } else {
          setTimeLeft(0);
          setCanResend(true);
        }
      } else {
        setCanResend(true);
      }
    };

    calculateTimeLeft();

    // Set interval to update the timer every second
    const timerInterval = setInterval(() => {
      calculateTimeLeft();
    }, 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(timerInterval);
  }, []);

  const handleResendClick = () => {
    // Update the timestamp in localStorage
    localStorage.setItem('otpSentTime', new Date().getTime());
    setCanResend(false);
    setTimeLeft(60); // Reset the countdown to 60 seconds

    // Call the function to resend OTP
    handleButtonClick('resend');
  };

  return (
    <div className="flex items-center justify-center w-screen h-screen">
      <div className="flex min-h-full flex-col justify-center gap-4 w-1/3">
        <div className=" flex flex-col gap-2 items-center justify-center">
          <div className="w-12 h-12">
            <img
              alt=""
              src={imageURLCheck(orgDetails?.organizationDetails?.logo)}
              className="object-cover"
            />
          </div>
          <h2 className=" text-center font-bold text-xl">
            Sign in to your account
          </h2>
        </div>
        <div className="flex flex-col gap-2 w-full">
          <Input
            label="Email"
            placeholder={'Enter your email'}
            value={email}
            readOnly={true}
            className={`w-full cursor-not-allowed`}
          />
          <div className={`w-full`}>
            <div className="flex flex-col gap-2">
              {isOtpSent && (
                <div className="flex flex-row text-sm font-medium text-gray-400">
                  <p>We have sent a code to your email.</p>
                </div>
              )}
              {isOtpSent && (
                <div
                  className={`grid grid-cols-6 gap-1 mx-auto place-items-center  w-full `}
                >
                  {[...Array(6)].map((_, index) => (
                    <div className="w-12 h-12 " key={index}>
                      <input
                        id={`otp-input-${index}`}
                        className={`w-full h-full flex flex-col items-center justify-center text-center p-2 outline-none rounded-xl border border-gray-300 text-lg bg-white`}
                        type="text"
                        maxLength="1" // Limit input to one character
                        value={OTP[index]}
                        onChange={(e) => handleInputChange(e, index)}
                        onKeyDown={(e) => handleKeyDown(e, index)}
                        autoComplete="off"
                      />
                    </div>
                  ))}
                </div>
              )}

              <Button
                variant="filled"
                className={`w-full flex items-center`}
                onClick={() => handleButtonClick()}
              >
                {buttonLoading && <ButtonLoader />}
                {isOtpSent ? 'Verify OTP' : 'Send OTP'}
              </Button>
              <>
                {isOtpSent && (
                  <p className="flex items-center justify-center text-gray-600 text-sm gap-1">
                    Didn&apos;t receive OTP?
                    {canResend ? (
                      <span
                        className="text-blue-700 cursor-pointer"
                        onClick={handleResendClick}
                      >
                        Resend OTP
                      </span>
                    ) : (
                      <span className="text-gray-400">
                        {resendAttempts >= 3
                          ? `Please Try again after sometime.`
                          : `Resend OTP in ${timeLeft} seconds`}
                      </span>
                    )}
                  </p>
                )}
              </>
            </div>
          </div>
          {/* <p className="mt-8 text-center text-sm text-gray-500">
            Not received OTP?{' '}
            <a
              href="#"
              className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
            >
              Resend OTP
            </a>
          </p> */}
        </div>
      </div>
    </div>
  );
};

export default LoginWithOTP;
