import {
  CloudArrowDownIcon,
  DocumentTextIcon,
  PencilSquareIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import CircleIcon from 'assets/svg/CircleIcon';
import GradientCloudDownloadIcon from 'assets/svg/GradientCloudDownloadIcon';
import ScalesIcon from 'assets/svg/ScalesIcon';
import Button from 'components/atoms/button/Button';
import { CheckBoxProgram } from 'components/atoms/FormElements/input/Input';
import PopUp from 'components/atoms/popUp/PopUp';
import TableSkeleton from 'components/atoms/skeletonLoaderTypes/TableSkeleton';
import { useGetFilterValues } from 'query/dashboard/ManageDashboard';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ViewFiltersSidePanel from './ViewFiltersSidePanel';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { refreshFile } from 'apiClient/dashboardBuilder/DashboardBuilder';
import { onResponse } from 'utils/toastMessages';

const ViewDashboardHeader = ({
  filtersList,
  filters,
  setFilters,
  dashboardSettings,
  hideGoToReportButton,
  hideEditButton,
  selectedPageId,
  hideDownloadButton,
  dashboardRef,
  hideFilterButton,
  isDownload,
  setIsDownload,
  setIsRefresh,
  isRefresh,
  setCompleteRefresh,
  module,
  completeRefresh,
}) => {
  const reportId = useParams().id;
  const reportName = useParams().name;
  const navigate = useNavigate();
  const [selectedColumnId, setSelectedColumnId] = useState(null);
  const [selectedValues, setSelectedValues] = useState({});
  const [isFilterPanelOpen, setIsFilterPanelOpen] = useState(false);
  const dashboardId = dashboardSettings?.id;

  const {
    data: filterValues,
    isLoading: filterValuesLoading,
    isError: filterValuesError,
    refetch: refetchFilterValuesData,
  } = useGetFilterValues(selectedColumnId);

  useEffect(() => {
    if (selectedColumnId) {
      refetchFilterValuesData();
    }
  }, []);

  const handleSelectAll = (columnId, values) => {
    const isAllSelected = selectedValues[columnId]?.length === values?.length;
    setSelectedValues((prev) => ({
      ...prev,
      [columnId]: isAllSelected ? [] : values,
    }));
  };

  const handleIndividualSelect = (columnId, value) => {
    setSelectedValues((prev) => {
      const currentValues = prev[columnId] || [];
      const isSelected = currentValues.includes(value);
      const updatedValues = isSelected
        ? currentValues?.filter((v) => v !== value)
        : [...currentValues, value];

      return {
        ...prev,
        [columnId]: updatedValues,
      };
    });
  };

  const handleSave = () => {
    const formattedFilters = Object.keys(selectedValues)?.reduce(
      (acc, columnId) => {
        if (selectedValues[columnId]?.length > 0) {
          const columnValue = filtersList?.find(
            (item) => item?.columnId === columnId,
          )?.value;
          if (columnValue) {
            acc[columnValue] = selectedValues[columnId];
          }
        }
        return acc;
      },
      {},
    );
    setFilters(formattedFilters);
    setIsFilterPanelOpen(false);
  };

  const handleDownload = async () => {
    const input = dashboardRef.current;
    await setIsDownload(true);
    html2canvas(input, {
      scale: 2,
      useCORS: true,
      height: input.scrollHeight,
      width: input.scrollWidth,
      windowHeight: input.scrollHeight,
      windowWidth: input.scrollWidth,
      backgroundColor: input.style?.backgroundColor || 'white',
    }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const canvasWidth = canvas.width;
      const canvasHeight = canvas.height + 10;
      const pdf = new jsPDF('l', 'mm', [canvasWidth, canvasHeight]);
      pdf.addImage(imgData, 'PNG', 0, 0, canvasWidth, canvasHeight);
      pdf.save('dashboard.pdf');
      setIsDownload(false);
    });
  };

  const handleRefresh = async () => {
    setIsRefresh(true);
    try {
      await refreshFile(dashboardId, module);
      setCompleteRefresh(!completeRefresh);
    } catch (error) {
      setIsRefresh(false);
      onResponse(error?.message);
    }
    setIsRefresh(false);
  };

  return (
    <div className="flex flex-col bg-white shadow sticky top-0 z-10">
      <div className="px-6 py-3 justify-between flex items-center">
        <span className="text-xl text-gray-900 font-semibold">
          {/* {reportName} */}
        </span>
        <div className="flex justify-between items-center gap-2">
          {isRefresh ? (
            <Button
              variant="outlined"
              className="flex gap-2 text-sm font-semibold"
            >
              Refreshing...
            </Button>
          ) : (
            <Button
              onClick={handleRefresh}
              variant="outlined"
              className="flex gap-2 text-sm font-semibold"
            >
              Refresh
            </Button>
          )}
          {hideFilterButton ? null : (
            <Button
              variant="filled"
              className="flex gap-2 text-sm font-semibold"
              onClick={() => setIsFilterPanelOpen(true)}
            >
              Filters
            </Button>
          )}
          {hideDownloadButton ? null : isDownload ? (
            <Button
              variant="outlined"
              className="flex justify-between gap-2 items-center text-sm font-semibold text-gray-700"
              icon={<GradientCloudDownloadIcon />}
            >
              Downloading...
            </Button>
          ) : (
            <Button
              variant="outlined"
              className="flex justify-between gap-2 items-center"
              icon={<GradientCloudDownloadIcon />}
              onClick={handleDownload}
            >
              <span className="text-sm font-semibold text-gray-700">
                Download
              </span>
            </Button>
          )}
        </div>
      </div>
      {isFilterPanelOpen && (
        <div className="fixed flex z-50 top-16 w-full justify-end right-0">
          <div className="w-[400px] h-full bg-white shadow-lg">
            <ViewFiltersSidePanel
              filters={filters}
              setFilters={setFilters}
              selectedColumnId={selectedColumnId}
              setSelectedValues={setSelectedValues}
              setSelectedColumnId={setSelectedColumnId}
              filtersList={filtersList}
              filterValues={filterValues}
              handleSelectAll={handleSelectAll}
              filterValuesLoading={filterValuesLoading}
              handleIndividualSelect={handleIndividualSelect}
              handleSave={handleSave}
              selectedValues={selectedValues}
              setIsFilterPanelOpen={setIsFilterPanelOpen}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ViewDashboardHeader;
