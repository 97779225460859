import React from 'react';

const TextBox = React.forwardRef((props, ref) => {
  const { label, className, readonly, required, ...rest } = props;
  return (
    <React.Fragment>
      {label && (
        <label className="text-sm font-medium leading-5 text-gray-700">
          {label} {required && <span className="text-red-600">*</span>}
        </label>
      )}
      <textarea
        {...rest}
        ref={ref}
        required={required}
        className={`w-full px-[14px] py-[10px]  mt-[6px] text-gray-500 border  rounded-lg outline-none ${className}`}
        readOnly={readonly}
      />
    </React.Fragment>
  );
});

export default TextBox;
