import { toast } from 'react-toastify';
import ToastNotify from 'pages/surface/ToastNotify';

export const setToastMessage = (message) => {
  toast(<ToastNotify message={message} close={() => {}} />, {
    onClose: () => {},
    autoClose: 2000,
    className:
      'toast-message absolute w-full h-full py-4 px-[30px] bg-white gap-4 flex flex-col border border-gray-200 shadow-md rounded-lg',
    zIndex: 1000,
  });
};
