export const EditPencilIcon = (props) => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0.917198 12.0771C0.947827 11.8015 0.963141 11.6636 1.00485 11.5348C1.04185 11.4205 1.09413 11.3117 1.16026 11.2114C1.23481 11.0984 1.33287 11.0003 1.52899 10.8042L10.3332 2.00004C11.0696 1.26366 12.2635 1.26366 12.9999 2.00004C13.7362 2.73642 13.7362 3.93033 12.9999 4.66671L4.19565 13.4709C3.99953 13.667 3.90147 13.7651 3.78843 13.8396C3.68814 13.9058 3.57936 13.958 3.46507 13.995C3.33624 14.0367 3.19841 14.0521 2.92275 14.0827L0.666504 14.3334L0.917198 12.0771Z"
        stroke="#475467"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
