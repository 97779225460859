import React from 'react';

const PlusButton = (props) => {
  const { onTap } = props;

  return (
    <div
      onClick={() => onTap()}
      className="h-14 w-14 flex justify-center items-center border-[1px] rounded-lg text-3xl text-gray-700 border-gray-300 shadow-lg cursor-pointer"
    >
      +
    </div>
  );
};

export default PlusButton;
