import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid';
import { useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { templateBuilderSelector } from 'store/reducers/templateBuilderSlice';
import ButtonPropertiesSidePanel from './buttonPropertiesSidePanel/ButtonPropertiesSidePanel';
import DividerPropertiesSidePanel from './dividerPropertiesSidePanel/DividerPropertiesSidePanel';
import HeadingPropertiesSidePanel from './headingPropertiesSidePanel/HeadingPropertiesSidePanel';
import ImagePropertiesSidePanel from './imagePropertiesSidePanel/ImagePropertiesSidePanel';
import ParagraphPropertiesSidePanel from './paragraphPropertiesSidePanel/ParagraphPropertiesSidePanel';
import SpacerPropertiesSidePanel from './spacerPropertiesSidePanel/SpacerPropertiesSidePanel';
import VideoPropertiesSidePanel from './videoPropertiesSidePanel/VideoPropertiesSidePanel';

const EditAtomsMoleculesSidePanel = ({ type }) => {
  const { control } = useFormContext();
  const { selectedColumn, selectedSection } = useSelector(
    templateBuilderSelector,
  );
  const sections = useWatch({
    control,
    name: `data`,
  });
  const selectedSectionIndex = sections?.findIndex(
    (section) => section?.sectionId === selectedSection,
  );
  const columns = useWatch({
    control,
    name: `data.${selectedSectionIndex}.columns`,
  });
  const selectedColumnIndex = columns?.findIndex(
    (col) => col?.columnId === selectedColumn,
  );
  var path = `data.${selectedSectionIndex}.columns.${selectedColumnIndex}.column.attr`;
  var subPath = `data.${selectedSectionIndex}.columns.${selectedColumnIndex}.column`;

  const EditProperties = () => {
    switch (type) {
      case 'Heading':
        return <HeadingPropertiesSidePanel path={path} />;
      case 'Paragraph':
        return <ParagraphPropertiesSidePanel control={control} path={path} />;
      case 'Image':
        return (
          <ImagePropertiesSidePanel
            control={control}
            path={path}
            subPath={subPath}
          />
        );
      case 'Video':
        return (
          <VideoPropertiesSidePanel
            control={control}
            path={path}
            subPath={subPath}
          />
        );
      case 'Button':
        return <ButtonPropertiesSidePanel control={control} path={path} />;
      case 'Button1':
        return (
          <ButtonPropertiesSidePanel
            control={control}
            path={'extras.button1'}
          />
        );
      case 'Button2':
        return (
          <ButtonPropertiesSidePanel
            control={control}
            path={'extras.button2'}
          />
        );
      case 'Divider':
        return <DividerPropertiesSidePanel control={control} path={path} />;
      case 'Spacer':
        return <SpacerPropertiesSidePanel control={control} path={path} />;
      default:
        return null;
    }
  };
  const [active, setActive] = useState(false);
  return (
    <div className="ring-1 ring-gray-200 bg-gray-50 rounded-lg">
      <div
        onClick={() => setActive(!active)}
        className="cursor-pointer px-3 py-4 flex justify-between items-center text-gray-500"
      >
        <p className="">{type}</p>
        {active ? (
          <ChevronDownIcon className="h-4" />
        ) : (
          <ChevronUpIcon className="h-4" />
        )}
      </div>
      {active && (
        <div className="px-3 duration-700">
          <hr />
          <div className="py-4">{EditProperties()}</div>
        </div>
      )}
    </div>
  );
};

export default EditAtomsMoleculesSidePanel;
