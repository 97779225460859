import Avatar from 'layouts/header/Avatar';
import { imageURLCheck } from 'utils/ImageUrlCheck';
import { handleErrorImage } from 'utils/handleErrorImage';

function ProfileAwards({ rank, user, medal }) {
  return (
    <div className="grid grid-cols-[1fr_50px] gap-3 py-2">
      <div className="flex w-full gap-2">
        <div className="h-fit">
          <Avatar
            image={imageURLCheck(user?.avatar?.value)}
            alt=""
            className="h-10 w-10 rounded-full"
            userName={user?.fullName?.value}
          />
        </div>
        <div className="">
          <h1 className="text-gray-900 font-semibold text-sm">
            {user?.fullName?.value}
          </h1>
          <p className="text-gray-500 text-sm  ">
            Rank {String(rank)?.length >= 2 ? `${rank}` : `0${rank}`}
          </p>
        </div>
      </div>

      <div className="w-fit h-fit">
        <img
          src={imageURLCheck('/' + medal?.url)}
          onError={handleErrorImage}
          className="h-10 w-10"
        />
      </div>
    </div>
  );
}

export default ProfileAwards;
