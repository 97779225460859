import { rbacFlags } from 'config/rbacFlags';
import { lazy } from 'react';

const Reports = lazy(() => import('pages/admin/reports/Reports'));

const CreateReports = lazy(() =>
  import('pages/admin/reports/createReports/CreateReports'),
);

const UserViewReport = lazy(() =>
  import('pages/admin/reports/reportSection/userViewReport/UserViewReport'),
);

const ReportBuilderRoutes = [
  {
    type: 'collapse',
    name: 'reportCenter',
    key: 'ReportCenter',
    parentId: 'ReportBuilder',
    route: '/reports',
    flagName: rbacFlags.Provideaccesstocreateandeditreports,
    module: 'Admin',
    component: <Reports />,
  },
  {
    type: 'collapse',
    name: 'EditReport',
    key: 'EditReport',
    parentId: 'EditReport',
    route: '/reports/edit/:id',
    flagName: rbacFlags.Provideaccesstocreateandeditreports,
    module: 'Admin',
    component: <CreateReports />,
  },
  {
    type: 'collapse',
    name: 'CreateReport',
    key: 'CreateReport',
    parentId: 'CreateReport',
    route: '/reports/create',
    flagName: rbacFlags.Provideaccesstocreateandeditreports,
    module: 'Admin',
    component: <CreateReports />,
  },
  {
    type: 'collapse',
    name: 'ViewReport',
    key: 'ViewReport',
    parentId: 'ViewReport',
    route: '/reports/view/:name/:id',
    flagName: rbacFlags.Provideaccesstocreateandeditreports,
    module: 'Admin',
    component: <UserViewReport />,
  },
];

export default ReportBuilderRoutes;
