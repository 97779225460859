import BasicCard from 'components/layout/cardLayout/CardLayout';
import { useGetMyBadgesById } from 'query/profile/summary';
import React from 'react';
import { useSelector } from 'react-redux';
import { imageURLCheck } from 'utils/ImageUrlCheck';
import { handleErrorImage } from 'utils/handleErrorImage';

const Badges = () => {
  const user = useSelector((state) => state.user);
  const { isLoading, data: badges } = useGetMyBadgesById(
    user?.userProfileData?.userId?.value,
  );
  return (
    <>
      {badges?.length > 0 && (
        <div className="flex w-full flex-col gap-3 p-4 border rounded-xl shadow-md border-gray-200 bg-white">
          <div className="pb-2 text-sm/5 font-semibold text-gray-900">
            Badges
          </div>
          <div className="flex gap-5  items-center ">
            {badges?.totalBadge?.slice(0, 4)?.map((badge) => {
              return (
                <div key={badge?.id} className=" ">
                  <img
                    className="w-14 h-14"
                    src={imageURLCheck(badge?.award?.badge?.logoUrl)}
                    alt="badges"
                    onError={handleErrorImage}
                  />
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default Badges;
