const EquitySummaryIcon = ({
  isActive,
  activeColor,
  isHovered,
  hoveredColor,
  width,
  height,
}) => (
  <svg
    width={width ? width : '20'}
    height={height ? height : '20'}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1769_1863)">
      <path
        d="M4.16696 18.3333H15.8336M2.08362 6.66663H17.917M8.33362 1.66663L6.66696 6.66663L10.0003 15.4166L13.3336 6.66663L11.667 1.66663M10.4936 15.2907L17.9432 7.09604C18.0816 6.94383 18.1508 6.86772 18.1784 6.78106C18.2027 6.7047 18.2044 6.62295 18.1835 6.54561C18.1596 6.45785 18.0938 6.37883 17.9621 6.2208L14.3669 1.9065C14.2934 1.81833 14.2566 1.77424 14.2116 1.74255C14.1717 1.71447 14.1272 1.69361 14.0801 1.68093C14.0269 1.66663 13.9695 1.66663 13.8547 1.66663H6.14587C6.0311 1.66663 5.97371 1.66663 5.92053 1.68093C5.87341 1.69361 5.82888 1.71447 5.78898 1.74255C5.74394 1.77424 5.7072 1.81833 5.63372 1.9065L2.03848 6.2208C1.90678 6.37883 1.84094 6.45784 1.81712 6.54561C1.79614 6.62295 1.7979 6.7047 1.8222 6.78106C1.84977 6.86772 1.91896 6.94382 2.05733 7.09604L9.507 15.2907C9.67801 15.4788 9.76352 15.5728 9.86401 15.6076C9.95229 15.6382 10.0483 15.6382 10.1366 15.6076C10.2371 15.5728 10.3226 15.4788 10.4936 15.2907Z"
        stroke={isActive ? activeColor : isHovered ? hoveredColor : '#475467'}
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1769_1863">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default EquitySummaryIcon;
