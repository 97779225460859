import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Colors,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import { useEffect, useRef, useState } from 'react';
import { Bar, Bubble, Doughnut, Line, Pie, Scatter } from 'react-chartjs-2';
import {
  areaOptions,
  barOptions,
  columnOptions,
  doughnutOptions,
  formatDataSet,
  formatDataSetForBarChartAndLineChart,
  formatDataSetForBubbleChart,
  formatDataSetForPieDoughnutChart,
  formatDataSetForScatterChart,
  formatDataSetForStackedColumnChart,
  formatDatasSetForAreaChart,
  lineOptions,
  pieOptions,
  stackedOptions,
} from './Datasets';

// Register the components for Chart.js
ChartJS.defaults.font.family = 'Figtree';

const RenderDashboardChartJs = ({
  chartType,
  datasets,
  displayLegend,
  selectedPalete,
}) => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    ArcElement,
    Title,
    Tooltip,
    Legend,
    Filler,
    Colors,
  );

  const [chartData, setChartData] = useState(null);
  const [chartOptions, setChartOptions] = useState({});
  const chartRef = useRef(null);

  useEffect(() => {
    if (
      chartType === 'Bar Chart' ||
      chartType === 'Line Chart' ||
      chartType === 'Column Chart'
    ) {
      const formattedData = formatDataSetForBarChartAndLineChart(
        selectedPalete,
        datasets,
      );
      setChartData(formattedData);
    } else if (chartType === 'Area Chart') {
      const formattedData = formatDatasSetForAreaChart(
        selectedPalete,
        datasets,
      );
      setChartData(formattedData);
    } else if (chartType === 'Pie Chart' || chartType === 'Dounut Chart') {
      const formattedData = formatDataSetForPieDoughnutChart(
        selectedPalete,
        datasets,
      );
      setChartData(formattedData);
    } else if (chartType === 'Stacked Column') {
      const formattedData = formatDataSetForStackedColumnChart(
        selectedPalete,
        datasets,
      );
      setChartData(formattedData);
    } else if (chartType === 'Scatter Plot') {
      const formattedData = formatDataSetForScatterChart(
        selectedPalete,
        datasets,
        displayLegend,
      );
      setChartOptions(formattedData?.options);
      setChartData(formattedData?.formattedData);
    } else if (chartType === 'Bubble Chart') {
      const formattedData = formatDataSetForBubbleChart(
        selectedPalete,
        datasets,
        displayLegend,
      );
      setChartOptions(formattedData?.options);
      setChartData(formattedData?.formattedData);
    } else {
      const formattedData = formatDataSet(selectedPalete, datasets);
      setChartData(formattedData);
    }
  }, [datasets, selectedPalete]);

  // console.log(chartRef);

  if (chartData) {
    return (
      <>
        {chartType === 'Line Chart' && (
          <Line data={chartData} options={lineOptions(displayLegend)} />
        )}
        {chartType === 'Bar Chart' && (
          <Bar data={chartData} options={barOptions(displayLegend)} />
        )}
        {chartType === 'Pie Chart' && (
          <Pie data={chartData} options={pieOptions(displayLegend)} />
        )}
        {chartType === 'Column Chart' && (
          <Bar
            data={chartData}
            options={columnOptions(displayLegend, chartRef)}
            ref={chartRef}
          />
        )}
        {chartType === 'Dounut Chart' && (
          <Doughnut data={chartData} options={doughnutOptions(displayLegend)} />
        )}
        {chartType === 'Bubble Chart' && (
          <Bubble data={chartData} options={chartOptions} />
        )}
        {chartType === 'Scatter Plot' && (
          <Scatter data={chartData} options={chartOptions} />
        )}
        {chartType === 'Stacked Column' && (
          <Bar data={chartData} options={stackedOptions(displayLegend)} />
        )}
        {chartType === 'Area Chart' && (
          <Line data={chartData} options={areaOptions(displayLegend)} />
        )}
      </>
    );
  } else return <div>Loading..</div>;
};

export default RenderDashboardChartJs;
