function DeleteFormIcon(props) {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.3333 4.55599V3.88932C12.3333 2.9559 12.3333 2.48919 12.1517 2.13267C11.9919 1.81907 11.7369 1.5641 11.4233 1.40431C11.0668 1.22266 10.6001 1.22266 9.66667 1.22266H8.33333C7.39991 1.22266 6.9332 1.22266 6.57668 1.40431C6.26308 1.5641 6.00811 1.81907 5.84832 2.13267C5.66667 2.48919 5.66667 2.9559 5.66667 3.88932V4.55599M7.33333 9.13932V13.306M10.6667 9.13932V13.306M1.5 4.55599H16.5M14.8333 4.55599V13.8893C14.8333 15.2895 14.8333 15.9895 14.5608 16.5243C14.3212 16.9947 13.9387 17.3772 13.4683 17.6168C12.9335 17.8893 12.2335 17.8893 10.8333 17.8893H7.16667C5.76654 17.8893 5.06647 17.8893 4.53169 17.6168C4.06129 17.3772 3.67883 16.9947 3.43915 16.5243C3.16667 15.9895 3.16667 15.2895 3.16667 13.8893V4.55599"
        stroke="#475467"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default DeleteFormIcon;
