import { XMarkIcon } from '@heroicons/react/24/solid';
import TextBox from 'components/atoms/FormElements/textBox/TextBox';
import Button from 'components/atoms/button/Button';
import PdfViewer from 'components/atoms/pdfViewer/PDFViewer';
import Tabs from 'components/molecules/tabs/Tabs';
import { useTaskAction } from 'query/task/task';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fetchAndUpdatePendingTask } from 'store/reducers/taskSlice';
import { imageURLCheck } from 'utils/ImageUrlCheck';
import { onResponseBudget } from 'utils/budgetUtils';
import { FileDownload } from '../fileDownload/FileDownload';

export const LifeEventApprovalViewDocPopUp = ({
  data,
  isModalOpen,
  setIsViewDocPopUp,
  taskData,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState(null);
  const [tabs, setTabs] = useState([]);
  const { mutateAsync: approveTask } = useTaskAction();

  const handleTab = (tab) => {
    setSelectedTab(tab);
  };

  const ref = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        if (!event.target.closest('.modal-content')) {
          setIsViewDocPopUp(!isModalOpen);
        }
      }
    };
    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  useEffect(() => {
    if (data) {
      const documents = [data?.lifeEventMedia];
      setTabs(
        documents?.map((doc, index) => {
          return {
            id: doc?.id,
            tabLabel: doc?.caption,
            ...doc,
          };
        }),
      );
      setSelectedTab({
        id: documents[0]?.id,
        tabLabel: documents?.[0]?.caption,
        ...documents[0],
      });
    }
  }, [data]);

  function getFileExtension(url = '') {
    const pathParts = url?.split('.');
    if (pathParts?.length > 1) {
      return pathParts[pathParts?.length - 1];
    }
    return null;
  }
  const user = useSelector((state) => state.user);
  const uId = user?.userProfileData?.userId?.value;
  const taskParams = [uId, 10, 0, '', 'Pending', true, 'count'];

  const handleApproveClick = async () => {
    try {
      const payload = {
        ids: [taskData?.id],
        actions: ['approve'],
      };
      console.log(taskData);

      await approveTask(payload)
        .then((res) => {
          onResponseBudget('Task Successfully Accepted');
          navigate('/task');
          dispatch(fetchAndUpdatePendingTask(taskParams));
        })
        .catch((err) => {
          onResponseBudget('Some error has Occurred');
        });
    } catch (error) {
      onResponseBudget(error?.message);
    }
  };

  const handleRejectClick = async () => {
    try {
      const payload = {
        ids: [taskData?.id],
        actions: ['reject'],
      };

      await approveTask(payload)
        .then((res) => {
          onResponseBudget('Task Successfully Rejected');
          navigate('/task');
          dispatch(fetchAndUpdatePendingTask(taskParams));
        })
        .catch((err) => {
          onResponseBudget('Some error has Occurred');
        });
    } catch (error) {
      onResponseBudget(error?.message);
    }
  };

  return (
    <div
      className={`w-[100vw] h-full bg-black-400 bg-opacity-30 fixed top-0 left-0 z-[999] flex justify-end ${
        !isModalOpen && 'hidden'
      }`}
    >
      <div className="flex w-full h-full justify-end">
        <div
          className={`w-[50%] bg-white opacity-100 h-full right-0 modal-content`}
          data-state={isModalOpen}
          ref={ref}
        >
          <div
            className="px-6 h-14"
            style={{
              boxShadow:
                '0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06)',
            }}
          >
            <div className="py-2.5 flex justify-start items-center gap-3">
              <div
                className="border rounded-md w-fit p-2 cursor-pointer"
                onClick={() => setIsViewDocPopUp(!isModalOpen)}
              >
                <XMarkIcon className="w-4 h-4" />
              </div>
              <p className="text-md text-gray-900">Document</p>
            </div>
          </div>
          <div className="p-5 overflow-y-auto h-full pb-[100px]">
            <div className="border p-4 shadow rounded-xl">
              {tabs?.length ? (
                <div>
                  <Tabs
                    tabs={tabs}
                    selectedTab={selectedTab?.tabLabel}
                    handleTab={handleTab}
                  />
                  <div className="max-h-[700px] overflow-y-scroll custom-scrollbar w-full mt-5 border rounded-md p-2">
                    {getFileExtension(selectedTab?.imageUrl) === 'pdf' ? (
                      <PdfViewer
                        // apiEndPoint={``} // TO Do : put endpoint for api to fetch pdf
                        pdfFile={imageURLCheck(`${selectedTab?.imageUrl}`)}
                        // showAllPage={true}
                        defaultScale={1.5}
                      />
                    ) : getFileExtension(selectedTab?.imageUrl) === 'png' ||
                      getFileExtension(selectedTab?.imageUrl) === 'jpeg' ||
                      getFileExtension(selectedTab?.imageUrl) === 'jpg' ? (
                      <img src={imageURLCheck(selectedTab?.imageUrl)} />
                    ) : (
                      <FileDownload
                        filePath={imageURLCheck(selectedTab?.imageUrl)}
                      />
                    )}
                  </div>
                </div>
              ) : (
                <div className="w-full border rounded-md p-2">
                  <p>No Document Found.</p>
                </div>
              )}

              {data?.policyData?.status ===
                ('Approved' || 'Withdrawn' || 'Publish') && (
                <div className=" mt-5 flex items-center justify-between">
                  <div className="flex gap-3 items-center justify-center shadow-md h-full w-fit">
                    <div
                      className="border rounded-lg p-5 w-fit"
                      style={{ width: '100vh' }}
                    >
                      <h1 className="text-gray-900 text-md font-semibold mb-2">
                        Review
                      </h1>
                      <div className="py-1">
                        <TextBox
                          placeholder="Enter comment"
                          className="h-32 text-sm w-fit"
                        />
                      </div>
                      <div className="flex justify-end space-x-2 pt-5">
                        <Button
                          className="text-sm font-semibold"
                          onClick={handleRejectClick}
                        >
                          Reject
                        </Button>
                        <Button
                          className="text-sm font-semibold"
                          variant="filled"
                          onClick={handleApproveClick}
                        >
                          Approve
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
