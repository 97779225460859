import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchNominates } from 'apiClient/programs/programs';

export const initialState = {
  selectedAward: null,
  loader: [],
  profile: [],
  isProfileEditable: false,
  announcement: {
    userGroup: [],
  },
};

export const getUserGroupThunk = createAsyncThunk(
  'get/userGroup',
  async (payload) => {
    const { pageIndex, pageSize, name } = payload;
    const response = await fetchNominates(pageIndex, pageSize, name);
    return response;
  },
);

const profileReducer = createSlice({
  name: 'Profile',
  initialState,
  reducers: {
    // Form actions
    setSelectedAward(state, action) {
      state.selectedAward = action.payload;
    },
    setProfile(state, action) {
      state.profile = action.payload;
    },
    setIsEditable(state, action) {
      state.isProfileEditable = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getUserGroupThunk.pending, (state, action) => {
      state.loader = [...state.loader, 'USER_GROUP_LOADING'];
      return state;
    });
    builder.addCase(getUserGroupThunk.fulfilled, (state, action) => {
      state.loader = state.loader.filter((ele) => ele !== 'USER_GROUP_LOADING');
      state.announcement.userGroup = action.payload;
      return state;
    });
  },
});

export const { setSelectedAward, setProfile, setIsEditable } =
  profileReducer.actions;
export default profileReducer.reducer;
