import { CloudArrowUpIcon } from '@heroicons/react/24/solid';
import React from 'react';
import { handleErrorImage } from 'utils/handleErrorImage';

const ImageInput = React.forwardRef((props, ref) => {
  const {
    htmlFor,
    description = 'PNG, JPG or GIF (Recommended dimension 592x192px)',
    value,
    height,
    enableRemove,
    onChange,
    accept,
    objectFit,
    ...rest
  } = props;
  return (
    <React.Fragment>
      <div
        className={`border rounded-xl w-full  ${
          height ? `h-${height}` : 'max-h-[192px]'
        } cursor-pointer relative ${value ? '' : 'px-4 py-6'}`}
      >
        <label htmlFor={htmlFor} className="cursor-pointer">
          <div className="flex justify-end w-full"></div>
          {!value ? (
            <div className="flex flex-col justify-center items-center h-full">
              <div className="flex w-10 h-10 bg-[#F2F4F7] border-4 border-[#F9F9F9] items-center justify-center rounded-3xl">
                <CloudArrowUpIcon className="h-5 w-5" />
              </div>
              <p className="text-sm text-gray-600">
                <span className="text-transparent bg-clip-text bg-gradient-to-br from-primaryLeft to-primaryRight font-medium">
                  Click to upload
                </span>{' '}
                or drag and drop
              </p>
              <p className="text-xs text-gray-600">{description}</p>
            </div>
          ) : (
            <React.Fragment>
              <div className="relative border rounded-xl w-full">
                <img
                  src={
                    typeof value === 'string'
                      ? value
                      : URL.createObjectURL(value)
                  }
                  alt="banner"
                  className={`w-[100%] ${
                    height ? `h-${height}` : 'h-[192px]'
                  } ${objectFit ?? 'object-scale-down'}  rounded-xl`}
                  onError={handleErrorImage}
                />
                {/* <label
                  htmlFor={htmlFor}
                  className="w-fit z-50 flex px-4 py-2 rounded-xl bg-gray-400 absolute top-2 right-2 cursor-pointer"
                >
                  <EditIcon /> Change
                </label> */}
              </div>
            </React.Fragment>
          )}
        </label>
      </div>
      <input
        accept={accept}
        type="file"
        id={htmlFor}
        className="hidden"
        ref={ref}
        onChange={onChange}
        {...rest}
      />
      {enableRemove && value && (
        <p
          className="bg-clip-text bg-gradient-to-br text-transparent from-primaryLeft to-primaryRight font-semibold text-sm text-right mt-1 cursor-pointer"
          onClick={() => onChange(null)}
        >
          Remove
        </p>
      )}
    </React.Fragment>
  );
});

export default ImageInput;
