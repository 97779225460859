import { rbacFlags } from 'config/rbacFlags';

function checkRoleAccess(rbac, parentFlag, flagName) {
  let access = false;
  let module;
  if (rbac && rbac.length > 0) {
    module = rbac.filter(
      (item) => item.flagName === parentFlag && item.flagValue === true,
    );
    if (
      (parentFlag === 'Surface' ||
        parentFlag === 'SurfaceSurvey' ||
        parentFlag === 'SurfaceAnnouncement') &&
      (flagName === rbacFlags.ManageCreatesurveys ||
        flagName === rbacFlags.ManageAnnouncement)
    ) {
      module = rbac.filter((item) => item.flagName === 'Surface');
    }
    if (module.length > 0) {
      if (module[0].hierarchy?.length > 0) {
        access = module[0].hierarchy.some(
          (item) => item.flagName === flagName && item.flagValue === true,
        );
      }
    } else access = false;
  }
  return access;
}

function checkModuleAccessSidebar(modules = [], flagName, name, userAccess) {
  const moduleArray = [
    ...modules,
    {
      flagName: 'Admin',
      flagValue: true,
    },
    {
      flagName: 'Task',
      flagValue: true,
    },
    {
      flagName: 'Default',
      flagValue: true,
    },
  ];

  let module = false;
  let access = true;
  if (moduleArray && moduleArray.length > 0) {
    module = moduleArray.some(
      (item) => item.flagName === flagName && item.flagValue === true,
    );

    if (flagName === 'SurfaceCommunities') {
      access = checkRoleAccess(
        userAccess,
        'Surface',
        rbacFlags.ManageCommunities,
      );
    }
    if (flagName === 'Recognition') {
      access =
        checkRoleAccess(
          userAccess,
          'Recognition',
          rbacFlags.Accesstoredeempoints,
        ) ||
        checkRoleAccess(
          userAccess,
          'Recognition',
          rbacFlags.RecognitionChampions,
        ) ||
        checkRoleAccess(
          userAccess,
          'Recognition',
          rbacFlags.Managerecognitionprogramsandawards,
        );
    }
    if (flagName === 'Compensation' && name === 'Total Rewards') {
      access =
        checkRoleAccess(
          userAccess,
          'Compensation',
          rbacFlags.RewardsPhilosophy,
        ) ||
        checkRoleAccess(
          userAccess,
          'Compensation',
          rbacFlags.ManageCompensationBonus,
        );
    }
    if (flagName === 'Compensation' && name === 'Compensation Planning') {
      access =
        checkRoleAccess(
          userAccess,
          'Compensation',
          rbacFlags.CompensationWorksheets,
        ) ||
        checkRoleAccess(
          userAccess,
          'Compensation',
          rbacFlags.CompensationWorksheetsAdmin,
        ) ||
        checkRoleAccess(
          userAccess,
          'Compensation',
          rbacFlags.IncrementPhilosophy,
        ) ||
        checkRoleAccess(
          userAccess,
          'Compensation',
          rbacFlags.Managecompensationplans,
        );
    }
    if (flagName === 'Policy') {
      access =
        checkRoleAccess(userAccess, 'Policy', rbacFlags.ViewCompanyPolicies) ||
        checkRoleAccess(userAccess, 'Policy', rbacFlags.Managecompanypolicies);
    }
    if (flagName === 'Benefit') {
      access =
        checkRoleAccess(
          userAccess,
          'Benefit',
          rbacFlags.Administerandmanagecompanybenefits,
        ) ||
        checkRoleAccess(
          userAccess,
          'Benefit',
          rbacFlags.Accessandviewbenefitsforself,
        );
    }
    if (flagName === 'Esop') {
      access =
        checkRoleAccess(userAccess, 'Profile', rbacFlags.EsopTab) ||
        checkRoleAccess(userAccess, 'Esop', rbacFlags.TransactEquity);
    }
    if (flagName === 'Insurance') {
      access =
        checkRoleAccess(
          userAccess,
          'Insurnace',
          rbacFlags.EnrolAndClaimInsurance,
        ) ||
        checkRoleAccess(
          userAccess,
          'Insurance',
          rbacFlags.ManageInsuranceClaim,
        );
    }
  } else module = false;

  return module && access;
}

function checkModuleAccess(modules = [], flagName) {
  const moduleArray = [
    ...modules,
    {
      flagName: 'Admin',
      flagValue: true,
    },
    {
      flagName: 'Task',
      flagValue: true,
    },
    {
      flagName: 'Default',
      flagValue: true,
    },
  ];

  let module = false;
  if (moduleArray && moduleArray.length > 0) {
    module = moduleArray.some(
      (item) => item.flagName === flagName && item.flagValue === true,
    );
  } else module = false;

  return module;
}

const isSurface = (modules) => {
  return (
    checkModuleAccess(modules, 'Surface') ||
    checkModuleAccess(modules, 'Recognition')
  );
};

const isHome = (modules) => {
  // const adminModule = ['Profile', 'Admin', 'Analytics'];
  // return (
  //   (!checkModuleAccess(modules, 'Surface') &&
  //     !checkModuleAccess(modules, 'Recognition')) ||
  //   (!checkModuleAccess(modules, 'Surface') &&
  //     checkModuleAccess(modules, 'Recognition') &&
  //     modules?.filter(
  //       (module) =>
  //         module?.parent === null &&
  //         module?.flagValue &&
  //         !adminModule?.includes(module?.flagName),
  //     )?.length > 1)
  // );
  return true; // !Warning --> The above code is commented just to show both surface and home with all functionalities in demo
};

export {
  checkModuleAccess,
  checkModuleAccessSidebar,
  checkRoleAccess,
  isHome,
  isSurface,
};
