import { useAuth0 } from '@auth0/auth0-react';
import { handleErrorImage } from 'utils/handleErrorImage';
const loginContainerStyles = {
  display: 'grid',
  gridTemplateRows:
    '[left-start center-start right-start] 1fr [left-end center-end right-end]',
  gridTemplateColumns:
    '[left-start center-start] 1fr [left-end right-start] 1fr [center-end right-end]',
  alignItems: 'center',
  justifyContent: 'center',
  justifyItems: 'center',
  gridColumnGap: '160px',
  MozColumnGap: '160px',
  columnGap: '160px',
  width: '100%',
  padding: '80px',
  minHeight: '100vh',
  MozHeight: '-moz-fit-content',
  height: 'fit-content',
};
function Home() {
  const { loginWithRedirect, user } = useAuth0();
  const handleLogin = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: `${window.location.origin}/surface`,
      },
    });
  };
  return (
    <div
      style={{
        backgroundImage: `url('https://talentmonk.com/wp-content/uploads/2023/12/searching-for-solutions-group-of-three-young-and-2023-11-27-05-32-28-utc-scaled.jpg')`,
        backgroundPosition: '50%',
        backgroundAlignment: 'left',
      }}
      className=" h-fit min-h-screen bg-cover"
    >
      <div style={loginContainerStyles}>
        <div className="bg-white px-12 rounded-[5px] h-[540px] w-[400px] justify-center flex flex-col items-center">
          <div className="w-24 h-24 ">
            <img
              src="/Tallect-Favicon.png"
              className="w-full h-full"
              alt="tallect icon"
              onError={handleErrorImage}
            />
          </div>
          <div className="flex justify-center flex-col items-center gap-3  mt-6 mb-8">
            <h4 className="text-3xl font-semibold text-center text-gray-900">
              {' '}
              Welcome to Tallect
            </h4>
            <p className="text-md font-normal text-center text-gray-600">
              Unlocking Rewards, Benefits, and Compensation Excellence: Your
              Journey Begins with Tallect
            </p>
          </div>
          <div
            className={`${
              user ? '' : ''
            }  w-full border-3 border-white font-semibold text-center text-md rounded-lg hover:cursor-pointer bg-gradient-to-r from-primaryLeft to-primaryRight text-white p-4 hover:-all hover:transition-shadow`}
            onClick={handleLogin}
          >
            Sign In
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
