export const Loader = () => {
  return (
    <div
      className="inline-block h-14 w-14 animate-spin rounded-full border-4 border-solid bg-gradient border-r-transparent align-[-0.125em] text-primary motion-reduce:animate-[spin_1.5s_linear_infinite]"
      role="status"
    >
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-12 h-12 bg-white rounded-full p-1 "></div>
    </div>
  );
};
