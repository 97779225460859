const GroupsLogo = ({ isActive, activeColor, width, height }) => {
  return (
    <div>
      <svg
        width={width ? width : '20'}
        height={height ? height : '20'}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.5 2.04817C11.7108 1.8003 10.871 1.66667 10 1.66667C5.39765 1.66667 1.66669 5.39763 1.66669 10C1.66669 14.6024 5.39765 18.3333 10 18.3333C14.6024 18.3333 18.3334 14.6024 18.3334 10C18.3334 8.57057 17.9734 7.22519 17.3393 6.04954M14.1667 4.79167H14.1709M8.75007 18.2402L8.75015 16.4041C8.75016 16.3047 8.78574 16.2085 8.85046 16.133L10.9219 13.7162C11.0922 13.5176 11.0394 13.213 10.8123 13.0832L8.43213 11.7231C8.36746 11.6862 8.31388 11.6326 8.27697 11.5679L6.7254 8.84886C6.64465 8.70735 6.48883 8.62589 6.32655 8.64035L1.72017 9.05063M17.5 5.00001C17.5 6.84095 15.8334 8.33334 14.1667 10C12.5 8.33334 10.8334 6.84095 10.8334 5.00001C10.8334 3.15906 12.3257 1.66667 14.1667 1.66667C16.0076 1.66667 17.5 3.15906 17.5 5.00001ZM14.375 4.79167C14.375 4.90673 14.2817 5.00001 14.1667 5.00001C14.0516 5.00001 13.9584 4.90673 13.9584 4.79167C13.9584 4.67661 14.0516 4.58334 14.1667 4.58334C14.2817 4.58334 14.375 4.67661 14.375 4.79167Z"
          stroke={isActive ? activeColor : '#475467'}
          strokeWidth="1.67"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default GroupsLogo;
