import React from 'react';

const MarketInrelligence = ({ isActive }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.2629 11.7984C13.0374 12.0535 12.8006 12.3053 12.5531 12.5529C8.97329 16.1327 4.48837 17.4518 2.53575 15.4991C1.19708 14.1605 1.39616 11.6316 2.80722 9.02012M4.74155 6.27096C4.97631 6.00404 5.22326 5.74055 5.48203 5.48178C9.06183 1.90198 13.5468 0.582884 15.4994 2.53551C16.839 3.8751 16.6387 6.40654 15.225 9.01992M12.5531 5.48178C16.1329 9.06159 17.452 13.5465 15.4994 15.4991C13.5468 17.4518 9.06183 16.1327 5.48203 12.5529C1.90222 8.97304 0.583128 4.48813 2.53575 2.5355C4.48837 0.582883 8.97329 1.90198 12.5531 5.48178ZM9.83355 8.99991C9.83355 9.46015 9.46045 9.83325 9.00022 9.83325C8.53998 9.83325 8.16688 9.46015 8.16688 8.99991C8.16688 8.53968 8.53998 8.16658 9.00022 8.16658C9.46045 8.16658 9.83355 8.53968 9.83355 8.99991Z"
        stroke={`${isActive ? '#fff' : '#475467'}`}
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default MarketInrelligence;
