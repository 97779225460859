export const RefreshIcon = (props) => {
  return (
    <svg
      width="16"
      height="18"
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.75 3.84424C13.341 5.00339 14.375 6.88104 14.375 9.0002C14.375 12.521 11.5208 15.3752 8 15.3752H7.625M4.25 14.1561C2.659 12.997 1.625 11.1193 1.625 9.0002C1.625 5.47938 4.47918 2.6252 8 2.6252H8.375M8.75 16.8002L7.25 15.3002L8.75 13.8002M7.25 4.2002L8.75 2.7002L7.25 1.2002"
        stroke="#475467"
        strokeWidth="1.33"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
