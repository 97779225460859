import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedLetterTemp: [],
  letterGeneratedData: [],
  initialLetterGeneratedData: [],
  customUser: null,
};

const letterGenerationSlice = createSlice({
  name: 'letter generation',
  initialState,
  reducers: {
    setSelectedLetter(state, action) {
      state.selectedLetterTemp = action.payload;
      return state;
    },
    setCustomUser(state, action) {
      state.customUser = action.payload;
      return state;
    },
    setLetterGeneratedData(state, action) {
      state.letterGeneratedData = action.payload;
      return state;
    },
    setInitialLetterGeneratedData(state, action) {
      state.initialLetterGeneratedData = action.payload;
      return state;
    },
  },
});

export default letterGenerationSlice.reducer;

export const {
  setSelectedLetter,
  setCustomUser,
  setLetterGeneratedData,
  setInitialLetterGeneratedData,
} = letterGenerationSlice.actions;

export const LetterSelector = (state) => state.letterGeneration;
