import {
  getDepartmentOverall,
  getDepartmentWeek,
  getIndividualOverall,
  getIndividualWeek,
  getLeaderWidgets,
} from 'apiClient/leaderBoard/leaderBoard';
import { useQuery } from 'react-query';

export const useGetIndividualBoard = (tableType) =>
  useQuery(
    ['get/individualBoard', tableType],
    async () => {
      if (tableType === 'lastWeek') {
        return getIndividualWeek();
      }
      return getIndividualOverall();
    },
    {
      enabled: !!tableType,
    },
  );

export const useGetDepartmentBoard = (tableType) =>
  useQuery(
    ['get/departmentBoard', tableType],
    async () => {
      if (tableType === 'lastWeek') {
        return getDepartmentWeek();
      }
      return getDepartmentOverall();
    },
    {
      enabled: !!tableType,
    },
  );

export const useGetLeaderWidgets = (payload) =>
  useQuery(['get/leaderWidgets', payload], async () =>
    getLeaderWidgets(payload),
  );
