import Tabs from 'components/molecules/tabs/Tabs';
import { Dispatch, SetStateAction, useState } from 'react';
import { EligibleGroup } from './EligibleGroup';
import { Settings } from './settings/Settings';

const tabs = [
  { id: 1, tabLabel: 'Settings' },
  { id: 2, tabLabel: 'Eligible Group' },
  { id: 3, tabLabel: 'Manage Data' },
  { id: 4, tabLabel: 'Payout Summary' },
];

type PlanSetUpProps = {
  eligibleUserData: { [key: string]: any };
  setEligibleUserData: Dispatch<SetStateAction<{ [key: string]: any }>>;
};

export const PlanSetUp = ({
  eligibleUserData,
  setEligibleUserData,
}: PlanSetUpProps) => {
  const [selectedTab, setSelectedTab] = useState<string>(tabs[0].tabLabel);

  return (
    <div>
      <div className="px-8">
        <Tabs
          tabs={tabs}
          className="text-sm font-semibold text-gray-600"
          selectedTab={selectedTab}
          handleTab={(e: { tabLabel: string }) => setSelectedTab(e.tabLabel)}
        />
      </div>
      <div className="bg-gray-50">
        {selectedTab === 'Eligible Group' && (
          <EligibleGroup
            setEligibilityPayload={setEligibleUserData}
            eligibilityPayload={eligibleUserData}
          />
        )}
        {selectedTab === 'Settings' && <Settings />}
      </div>
    </div>
  );
};
