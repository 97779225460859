const ManageBenefitsIcon = (props) => (
  <svg
    width={props?.width ? props?.width : '20'}
    height={props?.height ? props?.height : '20'}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.6667 4.16675C16.6667 5.54746 13.6819 6.66675 10 6.66675C6.31811 6.66675 3.33334 5.54746 3.33334 4.16675M16.6667 4.16675C16.6667 2.78604 13.6819 1.66675 10 1.66675C6.31811 1.66675 3.33334 2.78604 3.33334 4.16675M16.6667 4.16675V15.8334C16.6667 17.2141 13.6819 18.3334 10 18.3334C6.31811 18.3334 3.33334 17.2141 3.33334 15.8334V4.16675M16.6667 8.05558C16.6667 9.43629 13.6819 10.5556 10 10.5556C6.31811 10.5556 3.33334 9.43629 3.33334 8.05558M16.6667 11.9417C16.6667 13.3225 13.6819 14.4417 10 14.4417C6.31811 14.4417 3.33334 13.3225 3.33334 11.9417"
      stroke={
        props.isActive
          ? props.activeColor
          : props.isHovered
          ? props.hoveredColor
          : '#475467'
      }
      strokeWidth="1.67"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default ManageBenefitsIcon;
