const ManagePoliciesIcon = (props) => (
  <svg
    width={props?.width ? props?.width : '20'}
    height={props?.height ? props?.height : '20'}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.8333 5.83333L9.90371 3.9741C9.63616 3.439 9.50238 3.17144 9.3028 2.97597C9.12631 2.80311 8.91359 2.67164 8.68005 2.59109C8.41596 2.5 8.11683 2.5 7.51857 2.5H4.33332C3.3999 2.5 2.93319 2.5 2.57667 2.68166C2.26307 2.84144 2.0081 3.09641 1.84831 3.41002C1.66666 3.76654 1.66666 4.23325 1.66666 5.16667V5.83333M1.66666 5.83333H14.3333C15.7335 5.83333 16.4335 5.83333 16.9683 6.10582C17.4387 6.3455 17.8212 6.72795 18.0608 7.19836C18.3333 7.73314 18.3333 8.4332 18.3333 9.83333V13.5C18.3333 14.9001 18.3333 15.6002 18.0608 16.135C17.8212 16.6054 17.4387 16.9878 16.9683 17.2275C16.4335 17.5 15.7335 17.5 14.3333 17.5H5.66666C4.26653 17.5 3.56646 17.5 3.03168 17.2275C2.56128 16.9878 2.17882 16.6054 1.93914 16.135C1.66666 15.6002 1.66666 14.9001 1.66666 13.5V5.83333ZM8.41666 14.5833H11.5833C12.05 14.5833 12.2834 14.5833 12.4616 14.4925C12.6185 14.4126 12.7459 14.2851 12.8258 14.1283C12.9167 13.9501 12.9167 13.7167 12.9167 13.25V12.5833C12.9167 12.1166 12.9167 11.8833 12.8258 11.705C12.7459 11.5482 12.6185 11.4207 12.4616 11.3408C12.2834 11.25 12.05 11.25 11.5833 11.25H8.41666C7.94995 11.25 7.71659 11.25 7.53833 11.3408C7.38153 11.4207 7.25405 11.5482 7.17415 11.705C7.08332 11.8833 7.08332 12.1166 7.08332 12.5833V13.25C7.08332 13.7167 7.08332 13.9501 7.17415 14.1283C7.25405 14.2851 7.38153 14.4126 7.53833 14.4925C7.71659 14.5833 7.94995 14.5833 8.41666 14.5833ZM11.4583 11.25V9.79167C11.4583 8.98625 10.8054 8.33333 9.99999 8.33333C9.19457 8.33333 8.54166 8.98625 8.54166 9.79167V11.25H11.4583Z"
      stroke={
        props.isActive
          ? props.activeColor
          : props.isHovered
          ? props.hoveredColor
          : '#475467'
      }
      strokeWidth="1.67"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default ManagePoliciesIcon;
