import { rbacFlags } from 'config/rbacFlags';
import React from 'react';
const HiringCalculator = React.lazy(() =>
  import('pages/hiringCalculator/HiringCalculator'),
);
const CreateHiringCalculator = React.lazy(() =>
  import(
    'pages/hiringCalculator/createHiringCalculator/CreateHiringCalculator'
  ),
);
const ReviewOffer = React.lazy(() =>
  import('pages/hiringCalculator/reviewOffer/ReviewOffer'),
);
const ReleaseOffer = React.lazy(() =>
  import('pages/hiringCalculator/ReleaseOffer'),
);

const HiringIntelligenceRoutes = [
  // ### Hiring-calculator routes starts from here
  {
    type: 'collapse',
    name: 'hiring-calculator-create',
    key: 'hiring-calculator-create',
    route: '/offer/create',
    parentId: null,
    module: 'HiringIntelligence',
    flagName: rbacFlags.ManageOffer,
    component: <CreateHiringCalculator />,
  },
  {
    type: 'collapse',
    name: 'hiring-calculator-edit',
    key: 'hiring-calculator-edit',
    route: '/offer/edit/:id',
    parentId: null,
    module: 'HiringIntelligence',
    flagName: rbacFlags.ManageOffer,
    component: <CreateHiringCalculator />,
  },
  {
    type: 'collapse',
    name: 'hiring-calculator-view',
    key: 'hiring-calculator-view',
    route: '/offer/view/:id',
    parentId: null,
    module: 'HiringIntelligence',
    flagName: rbacFlags.ManageOffer,
    component: <CreateHiringCalculator />,
  },
  {
    type: 'collapse',
    name: 'hiring-calculator-view',
    key: 'hiring-calculator-view',
    route: '/offer/approver/:id',
    parentId: null,
    module: 'HiringIntelligence',
    flagName: rbacFlags.ManageOffer,
    component: <CreateHiringCalculator />,
  },
  {
    type: 'collapse',
    name: 'hiring-calculator-manage',
    key: 'hiring-calculator-manage',
    route: '/offer',
    parentId: null,
    module: 'HiringIntelligence',
    flagName: rbacFlags.ManageOffer,
    component: <HiringCalculator />,
  },
  {
    type: 'collapse',
    name: 'hiring-calculator-release',
    key: 'hiring-calculator-release',
    route: '/release-offer',
    parentId: null,
    module: 'HiringIntelligence',
    flagName: rbacFlags.ManageOffer,
    component: <ReleaseOffer />,
  },
  {
    type: 'collapse',
    name: 'hiring-calculator-review',
    key: 'hiring-calculator-review',
    route: '/candidate/review-offer',
    parentId: null,
    module: 'HiringIntelligence',
    flagName: rbacFlags.ManageOffer,
    component: <ReviewOffer />,
  },
];
export default HiringIntelligenceRoutes;
