const BenefitsLogo = ({ isActive, activeColor, width, height }) => {
  return (
    <svg
      width={width ? width : '20'}
      height={height ? height : '18'}
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.8337 3.16667C10.8337 4.08714 8.78163 4.83333 6.25033 4.83333C3.71902 4.83333 1.66699 4.08714 1.66699 3.16667M10.8337 3.16667C10.8337 2.24619 8.78163 1.5 6.25033 1.5C3.71902 1.5 1.66699 2.24619 1.66699 3.16667M10.8337 3.16667V4.41667M1.66699 3.16667V13.1667C1.66699 14.0871 3.71902 14.8333 6.25033 14.8333M6.25033 8.16667C6.10987 8.16667 5.97089 8.16437 5.83366 8.15987C3.49762 8.08332 1.66699 7.3694 1.66699 6.5M6.25033 11.5C3.71902 11.5 1.66699 10.7538 1.66699 9.83333M18.3337 8.58333C18.3337 9.50381 16.2816 10.25 13.7503 10.25C11.219 10.25 9.16699 9.50381 9.16699 8.58333M18.3337 8.58333C18.3337 7.66286 16.2816 6.91667 13.7503 6.91667C11.219 6.91667 9.16699 7.66286 9.16699 8.58333M18.3337 8.58333V14.8333C18.3337 15.7538 16.2816 16.5 13.7503 16.5C11.219 16.5 9.16699 15.7538 9.16699 14.8333V8.58333M18.3337 11.7083C18.3337 12.6288 16.2816 13.375 13.7503 13.375C11.219 13.375 9.16699 12.6288 9.16699 11.7083"
        stroke={isActive ? activeColor : '#475467'}
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default BenefitsLogo;
