const sideNav = {
  QuickLink: [
    {
      Name: 'Surface',
      Key: 'Surface',
      Order: '1',
      Link: 'surface',
      newTab: 'false',
      NotificationCount: '2',
      Icon: 'path',
    },
    {
      Name: 'Network',
      Key: 'Network',
      Order: '1',
      Link: 'network',
      newTab: 'false',
      NotificationCount: '2',
      Icon: 'path',
    },
  ],
  WorkTools: [
    {
      Name: 'Surface',
      Key: 'Surface',
      Order: '1',
      Link: 'surface',
      newTab: 'false',
      NotificationCount: '2',
      Icon: 'path',
    },
    {
      Name: 'Network',
      Key: 'Network',
      Order: '1',
      Link: 'network',
      newTab: 'false',
      NotificationCount: '2',
      Icon: 'path',
    },
  ],
};
export default sideNav;
