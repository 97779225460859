import React from 'react';
import { Link } from 'react-router-dom';

const ProfileItem = (props) => {
  return (
    <div className="flex flex-row items-center p-2 gap-3">
      <Link to={props.link}>
        <div className="flex flex-row items-center">
          {props.icon}
          <span className="pl-2">{props.value}</span>
        </div>
      </Link>
    </div>
  );
};

export default ProfileItem;
