import { post } from 'apiClient/apiCalls';
import * as CONSTANT from '../api.constant';

const BASE_URL = `${CONSTANT.API_URL}/${CONSTANT.COMPENSATION_V2}`;

export const validateFormula = async (body) => {
  const response = await post(`${BASE_URL}/formulae/valdiate`, body);
  return response.data;
};
export const getFormulaChain = async (body) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_UTILITY_V3}/data-chain/get`,
      body,
    );
    return response?.data;
  } catch (error) {
    throw new Error(error);
  }
};
export const getFunctionsList = async (body) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_UTILITY_V3}/formulav2/list`,
      body,
    );
    return response?.data;
  } catch (error) {
    throw new Error(error);
  }
};
