const HealthInsuranceIcon = (props) => (
  <svg
    width={props?.width ? props?.width : '20'}
    height={props?.height ? props?.height : '20'}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 12.0834V7.08339M7.50001 9.58339H12.5M16.6667 10.0001C16.6667 14.0904 12.205 17.0654 10.5817 18.0125C10.3972 18.1201 10.3049 18.1739 10.1747 18.2018C10.0737 18.2235 9.92631 18.2235 9.82528 18.2018C9.69509 18.1739 9.60284 18.1201 9.41835 18.0125C7.79498 17.0654 3.33334 14.0904 3.33334 10.0001V6.01472C3.33334 5.34846 3.33334 5.01533 3.44231 4.72898C3.53857 4.47601 3.695 4.25029 3.89806 4.07133C4.12792 3.86875 4.43984 3.75178 5.06368 3.51785L9.53185 1.84228C9.70509 1.77732 9.79171 1.74483 9.88083 1.73196C9.95987 1.72053 10.0401 1.72053 10.1192 1.73196C10.2083 1.74483 10.2949 1.77732 10.4682 1.84228L14.9363 3.51785C15.5602 3.75178 15.8721 3.86875 16.102 4.07133C16.305 4.25029 16.4614 4.47601 16.5577 4.72898C16.6667 5.01533 16.6667 5.34846 16.6667 6.01472V10.0001Z"
      stroke={
        props.isActive
          ? props.activeColor
          : props.isHovered
          ? props.hoveredColor
          : '#475467'
      }
      strokeWidth="1.67"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default HealthInsuranceIcon;
