const { createSlice } = require('@reduxjs/toolkit');

const initialState = {
  policyApprovalView: false,
};

const policyApprovalViewReducer = createSlice({
  name: 'policyApproval',
  initialState,
  reducers: {
    setSelectedPolicyApprovalView(state, action) {
      state.policyApprovalView = action.payload;
    },
  },
});

export const { setSelectedPolicyApprovalView } =
  policyApprovalViewReducer.actions;
export default policyApprovalViewReducer.reducer;
