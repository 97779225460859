export const defaultBarGraphOptions = {
  scales: {
    x: {
      grid: {
        display: false,
      },
    },
  },
  indexAxis: 'x',
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
      position: 'right',
    },
    title: {
      display: false,
      text: 'Chart.js Horizontal Bar Chart',
    },
  },
  borderSkipped: false,
  barPercentage: 0.8,
  categoryPercentage: 0.8,
  maxBarThickness: 20,
};

export const defaultLineGraphOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
      text: 'Chart.js Horizontal Bar Chart',
    },
  },
  pointRadius: 0,
  cubicInterpolationMode: 'monotone',
};

export const colorArray = [
  '#22CCEE',
  '#F63D68',
  '#FAC515',
  '#7A5AF8',
  '#3CCB7F',
];
export const colorArray2 = [
  '#ECFDFF',
  '#FFF1F3',
  '#FEFBE8',
  '#F4F3FF',
  '#EDFCF2',
];
