export const unescapeHtml = (escapedStr) => {
  const textarea = document.createElement('textarea');
  textarea.innerHTML = escapedStr;
  return textarea.value;
};

export const sanitizeHtml = (html) => {
  const div = document.createElement('div');
  div.innerHTML = html;

  // Remove script tags
  const scripts = div.getElementsByTagName('script');
  while (scripts.length > 0) {
    scripts[0].parentNode.removeChild(scripts[0]);
  }

  // Sanitize attributes, allow href in <a> and src in <img>
  const allElements = div.getElementsByTagName('*');
  for (let i = 0; i < allElements.length; i++) {
    const attributes = allElements[i].attributes;
    for (let j = attributes.length - 1; j >= 0; j--) {
      const attrName = attributes[j].name;
      const tagName = allElements[i].tagName.toLowerCase();

      if (tagName === 'a' && attrName === 'href') {
        // Keep only the href attribute
        const hrefValue = attributes[j].value;
        allElements[i].setAttribute(attrName, hrefValue);
      } else if (tagName === 'img' && attrName === 'src') {
        // Keep only the src attribute
        const srcValue = attributes[j].value;
        allElements[i].setAttribute(attrName, srcValue);
      } else if (attrName.startsWith('on')) {
        // Remove all event handler attributes
        allElements[i].removeAttribute(attrName);
      }
    }
  }

  return div.innerHTML;
};
