const HiringIntelligenceIcon = ({ isActive, activeColor, width, height }) => (
  <svg
    width={width ? width : '20'}
    height={height ? height : '20'}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.8333 17.5V12.5M13.3333 15H18.3333M9.99999 12.5H6.66666C5.11352 12.5 4.33695 12.5 3.72438 12.7537C2.90762 13.092 2.2587 13.741 1.92039 14.5577C1.66666 15.1703 1.66666 15.9469 1.66666 17.5M12.9167 2.7423C14.1383 3.23679 15 4.43443 15 5.83333C15 7.23224 14.1383 8.42988 12.9167 8.92437M11.25 5.83333C11.25 7.67428 9.75761 9.16667 7.91666 9.16667C6.07571 9.16667 4.58332 7.67428 4.58332 5.83333C4.58332 3.99238 6.07571 2.5 7.91666 2.5C9.75761 2.5 11.25 3.99238 11.25 5.83333Z"
      stroke={isActive ? activeColor : '#475467'}
      strokeWidth="1.67"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default HiringIntelligenceIcon;
