import { ChevronDownIcon } from '@heroicons/react/24/solid';
import ArrowLeftIcon from 'assets/svg/arrowLeft';
import Button from 'components/atoms/button/Button';
import { Filter } from 'pages/recognition/redemption/Filter';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import FiltersDropDown from './FiltersDropDownPopup';

const CompareCharts = () => {
  const navigate = useNavigate();
  const [isFilterDropDownOpen, setIsFilterDropDownOpen] = useState(false);

  return (
    <div className="bg-white flex flex-col h-full">
      <div className="flex justify-between items-center px-8 py-6">
        <div className="flex flex-row gap-2 items-center">
          <div
            className="p-2.5 rounded-lg border-2"
            onClick={() => navigate('')}
          >
            <ArrowLeftIcon className="w-4 h-4" />
          </div>
          <div>
            <p className="text-gray-900 text-lg font-figtree font-semibold">
              Compare Charts
            </p>
          </div>
        </div>
        <Button>Done</Button>
      </div>
      <hr className="w-full h-1"></hr>
      <div className="flex justify-between gap-2">
        <div className="flex p-4 w-1/2 border-r-2 border-gray-200 h-full">
          <div className="border flex gap-2 bg-white border-gray-200 shadow px-4 py-2 rounded-lg w-full items-center">
            <div
              className="flex border border-gray-300 rounded-lg gap-2 py-2 px-3.5 items-center"
              onClick={() => setIsFilterDropDownOpen(!isFilterDropDownOpen)}
            >
              <span className="text-sm text-gray-700 font-semibold">
                Filters
              </span>
              <ChevronDownIcon className="w-4 h-4 text-gray-700" />
            </div>
            <span className="text-gray-700 font-semibold text-base">:</span>
            <div className="flex gap-2 items-center">
              <div className="flex gap-2 items-center border-partition px-2 py-0.5  rounded-2xl">
                <span className="bg-gradient text-transparent bg-clip-text text-center font-semibold">
                  hello
                </span>
                <span className="bg-gradient text-transparent bg-clip-text text-center font-semibold">
                  X
                </span>
              </div>
            </div>
          </div>
          {isFilterDropDownOpen && <FiltersDropDown />}
          <div className="flex flex-col gap-3"></div>
        </div>
        <div className="flex p-4 w-1/2 border-l-2 border-gray-200 h-full">
          <div className="border flex gap-2 bg-white border-gray-200 shadow px-4 py-2 rounded-lg w-full items-center">
            <div
              className="flex border border-gray-300 rounded-lg gap-2 py-2 px-3.5 items-center"
              onClick={() => setIsFilterDropDownOpen(!isFilterDropDownOpen)}
            >
              <span className="text-sm text-gray-700 font-semibold">
                Filters
              </span>
              <ChevronDownIcon className="w-4 h-4 text-gray-700" />
            </div>
            <span className="text-gray-700 font-semibold text-base">:</span>
            <div className="flex gap-2 items-center">
              <div className="flex gap-2 items-center border-partition px-2 py-0.5  rounded-2xl">
                <span className="bg-gradient text-transparent bg-clip-text text-center font-semibold">
                  hello
                </span>
                <span className="bg-gradient text-transparent bg-clip-text text-center font-semibold">
                  X
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompareCharts;
