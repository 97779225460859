export enum BonusBasedOnEnum {
  Metrics = 'Metrics',
  Events = 'Events',
}

export type CreatePlanPayload = {
  id?: string;
  planName: string;
  startDate: string;
  endDate: string;
  salGroup: string;
  bonusBased: BonusBasedOnEnum;
  eventName?: string;
  workflowId: string;
  planOwnerId: string[];
  planDataManagerId: string[];
  status?: 'Draft' | 'Published' | undefined;
  isActive?: boolean;
};

export type EventsSettings = {
  vPayPayoutAmounts: { value: PayoutAmountsEnum };
  vPayEnableProration: { value: EnableProrationEnum[] };
  vPayEnableClawback: { value: ConfigureClawbackEnum[] };
  vPayCalculationField?: { value: CalculationFieldsEnum };
  vPayAttainmentInput?: { value: AttainmentInputsEnum };
  vPayAttainmentDependency?: { value: AttainmentDependencyEnum };
  vPayAchievementMeasurement?: { value: AchievementMeasurementEnum };
  vPayDependentFields?: { value: DependentFieldsEnum };
  vPaySlabsInclusion?: { value: SlabsInclusionEnum };
  vPayUGConditionSpecificity?: { value: UserGroupConditionSpecificityEnum };
  vPayRangeSpecificity?: { value: RangeOrSpecificValueEnum };
  vPayUGPayoutSpecificity?: { value: UserGroupPayoutSepecificityEnum };
  vPayOccurrence?: { value: EventOccurrenceEnum };
  vPayEndRecurrence?: { value: EndOfRecurrenceEnum };
  vPayPayoutTiming?: { value: PayoutTimingEnum };
};

export enum ConfigurationKeysEnum {
  vPayPayoutAmounts = 'vPayPayoutAmounts',
  vPayCalculationField = 'vPayCalculationField',
  vPayAttainmentInput = 'vPayAttainmentInput',
  vPayAttainmentDependency = 'vPayAttainmentDependency',
  vPayDependentFields = 'vPayDependentFields',
  vPayDependencyCriteria = 'vPayDependencyCriteria',
  vPayAchievementMeasurement = 'vPayAchievementMeasurement',
  vPayEnableProration = 'vPayEnableProration',
  vPayEnableClawback = 'vPayEnableClawback',
  vPaySlabsInclusion = 'vPaySlabsInclusion',
  vPayRangeSpecificity = 'vPayRangeSpecificity',
  vPayUGConditionSpecificity = 'vPayUGConditionSpecificity',
  vPayUGPayoutSpecificity = 'vPayUGPayoutSpecificity',
  vPayOccurrence = 'vPayOccurrence',
  vPayEndRecurrence = 'vPayEndRecurrence',
  vPayPayoutTiming = 'vPayPayoutTiming',
}

export enum PayoutAmountsEnum {
  PRECALCULATED = 'PRECALCULATED',
  LOGIC_BASED_CALCULATION = 'LOGIC_BASED_CALCULATION',
}

export enum CalculationFieldsEnum {
  SYSTEM = 'SYSTEM',
  CUSTOM = 'CUSTOM',
}

export enum AttainmentInputsEnum {
  DATE = 'DATE',
  DAYS = 'DAYS',
  PERCENTAGE = 'PERCENTAGE',
  TEXT = 'TEXT',
}

export enum AchievementMeasurementEnum {
  ATTAINMENT_BASED = 'ATTAINMENT_BASED',
  TARGET_BASED = 'TARGET_BASED',
}

export enum AttainmentDependencyEnum {
  INDEPENDENT = 'INDEPENDENT',
  DEPENDENT = 'DEPENDENT',
}

export enum DependentFieldsEnum {
  SYSTEM_FIELDS = 'SYSTEM_FIELDS',
  CUSTOM_FIELDS = 'CUSTOM_FIELDS',
}

export enum SlabsInclusionEnum {
  NO_SLABS = 'NO_SLABS',
  INCLUDES_SLABS = 'INCLUDES_SLABS',
}

export enum EnableProrationEnum {
  BASED_ON_EMPLOYEE_DATA = 'BASED_ON_EMPLOYEE_DATA',
  PRORATION_OCCURENCE = 'PRORATION_OCCURENCE',
}

export enum ConfigureClawbackEnum {
  CONFIGURE_CLAWBACK = 'CONFIGURE_CLAWBACK',
  CONFIGURE_PRORATION_CLAWBACK = 'CONFIGURE_PRORATION_CLAWBACK',
}

export enum UserGroupConditionSpecificityEnum {
  SAME_FOR_ALL = 'SAME_FOR_ALL',
  DIFFERS_BY_GROUP = 'DIFFERS_BY_GROUP',
}

export enum RangeOrSpecificValueEnum {
  SPECIFIC_VALUE = 'SPECIFIC_VALUE',
  RANGE_VALUE = 'RANGE_VALUE',
}

export enum UserGroupPayoutSepecificityEnum {
  SAME_PAYOUT_FOR_ALL = 'SAME_PAYOUT_FOR_ALL',
  DIFFERS_PAYOUT_BY_GROUP = 'DIFFERS_PAYOUT_BY_GROUP',
}

export enum EventOccurrenceEnum {
  ONE_TIME = 'ONE_TIME',
  RECURRING = 'RECURRING',
}

export enum EndOfRecurrenceEnum {
  NO_END_DATE = 'NO_END_DATE',
  AFTER_SPECIFIC_DATE = 'AFTER_SPECIFIC_DATE',
  AFTER_SPECIFIC_OCCURRENCE = 'AFTER_SPECIFIC_OCCURRENCE',
}

export enum PayoutTimingEnum {
  ON_EVENT_DATE = 'ON_EVENT_DATE',
  AFTER_SET_PERIOD = 'AFTER_SET_PERIOD',
  SPLIT_PAYMENTS = 'SPLIT_PAYMENTS',
}

export type ConfigData = {
  PayoutConfiguration?: { [key: string]: any }[] | [];
  AchievementsConfiguration?: { [key: string]: any }[] | [];
  EventConfiguration?: { [key: string]: any }[] | [];
};
