import ColorPicker from 'components/atoms/FormElements/colorPicker/ColorPicker';
import PropertyInput from 'components/atoms/propertyInput/PropertyInput';
import React from 'react';
import { Controller } from 'react-hook-form';

const DividerPropertiesSidePanel = (props) => {
  const { path, control } = props;
  return (
    <div className="flex flex-col gap-2">
      <Controller
        control={control}
        name={`${path}.divider.height`}
        render={({ field }) => {
          return (
            <PropertyInput
              type={'number'}
              label={'Height'}
              className={'mb-2'}
              {...field}
            />
          );
        }}
      />
      <Controller
        control={control}
        name={`${path}.divider.width`}
        render={({ field }) => {
          return (
            <PropertyInput label={'Width'} className={'mb-2'} {...field} />
          );
        }}
      />
      <Controller
        control={control}
        name={`${path}.divider.color`}
        render={({ field }) => {
          const { value, onChange, ...rest } = field;
          return (
            <ColorPicker
              label={'Background Color'}
              htmlFor={'DividerColor'}
              value={value}
              onChange={(e) => onChange(e.target.value)}
            />
          );
        }}
      />
    </div>
  );
};

export default DividerPropertiesSidePanel;
