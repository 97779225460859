import DownloadIcon from 'assets/svg/DownloadIcon';
import PoliciesLogo from 'assets/svg/policiesLogo';
import ChipsLabels from 'components/atoms/chips-labels/ChipsLabels';
import Table from 'components/atoms/table/Table';
import TableHeader from 'components/atoms/tableHeader/TableHeader';
import { formatDate } from 'pages/recognition/orderHistory/helper';

type VariablePaySubComponentProps = {
  data: { [key: string]: any };
};

export const VariablePaySubComponent = ({
  data,
}: VariablePaySubComponentProps) => {
  const columns = [
    {
      id: '1',
      Header: (
        <TableHeader name="Payout Month" HeaderKey="payoutMonth" sort={true} />
      ),
      Cell: ({ row }: any) => {
        return <div>{formatDate(new Date())}</div>;
      },
    },
    {
      id: '2',
      Header: (
        <TableHeader name="Total Target" HeaderKey="totalTarget" sort={true} />
      ),
      Cell: ({ row }: any) => {
        return (
          <div>{`INR ${Number(
            row.original?.totalTarget,
          ).toLocaleString()}`}</div>
        );
      },
    },
    {
      id: '3',
      Header: (
        <TableHeader name="Total Paid" HeaderKey="totalPaid" sort={true} />
      ),
      Cell: ({ row }: any) => {
        return (
          <div>{`INR ${Number(row.original?.totalPaid).toLocaleString()}`}</div>
        );
      },
    },
    {
      id: '4',
      Header: <TableHeader name="Status" HeaderKey="status" sort={true} />,
      Cell: ({ row }: any) => {
        return (
          <ChipsLabels
            // @ts-ignore
            className="text-sm font-medium"
            label={row.original?.paidStatus}
            status={
              row.original?.paidStatus === 'Paid'
                ? 'accepted'
                : row.original?.paidStatus === 'Payout Pending'
                ? 'InProgress'
                : ''
            }
          />
        );
      },
    },
    {
      id: '5',
      Header: (
        <TableHeader
          name="Cycle Start Date"
          HeaderKey="cycleStartDate"
          sort={true}
        />
      ),
      Cell: ({ row }: any) => {
        return <div>{formatDate(new Date())}</div>;
      },
    },
    {
      id: '6',
      Header: (
        <TableHeader
          name="Cycle End Date"
          HeaderKey="cycleEndDate"
          sort={true}
        />
      ),
      Cell: ({ row }: any) => {
        return (
          <p className="text-sm text-gray-600">{formatDate(new Date())}</p>
        );
      },
    },
    {
      id: '7',
      width: '15%',
      Header: (
        <TableHeader
          name="Employee Count"
          HeaderKey="employeeCount"
          sort={true}
        />
      ),
      Cell: ({ row }: any) => {
        return (
          <div>
            <p className="text-sm text-gray-900">
              {Number(row.original?.employeeCount).toLocaleString()}
            </p>
            <p className="text-sm text-gray-600">{`${Number(
              row.original?.received,
            ).toLocaleString()} Received | ${Number(
              row.original?.pending,
            ).toLocaleString()} Pending`}</p>
          </div>
        );
      },
    },
    {
      id: '8',
      width: '5%',
      Header: <TableHeader name="" HeaderKey="" />,
      Cell: ({ row }: any) => {
        return (
          <div className="flex items-center justify-center gap-5">
            <DownloadIcon />
            <PoliciesLogo
              isActive={undefined}
              activeColor={undefined}
              width={undefined}
              height={undefined}
            />
          </div>
        );
      },
    },
  ];

  return (
    <div className="-pb-8">
      <Table data={data} columns={columns} />
    </div>
  );
};
