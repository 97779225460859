const UpArrowIcon = (props) => {
  return (
    <div {...props}>
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="cursor-pointer"
      >
        <path
          d="M12.3334 8.99984L9.00008 5.6665M9.00008 5.6665L5.66675 8.99984M9.00008 5.6665V12.3332M17.3334 8.99984C17.3334 13.6022 13.6025 17.3332 9.00008 17.3332C4.39771 17.3332 0.666748 13.6022 0.666748 8.99984C0.666748 4.39746 4.39771 0.666504 9.00008 0.666504C13.6025 0.666504 17.3334 4.39746 17.3334 8.99984Z"
          stroke="#667085"
          strokeWidth="1.33"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default UpArrowIcon;
