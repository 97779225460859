import SearchIconInput from 'assets/svg/searchIconInput';
import { Input } from 'components/atoms/FormElements/input/Input';
import React, { useState } from 'react';

const FilterSearch = ({
  filterValuesData,
  setFilterValuesData,
  initialData,
}) => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);
    if (value === '') {
      setFilterValuesData(initialData);
    } else {
      const filteredData = initialData?.filter((item) =>
        item?.value.toLowerCase().includes(value),
      );
      setFilterValuesData(filteredData);
    }
  };

  return (
    <div className="flex gap-2 border rounded-lg px-2 w-full mb-4 border-gray-300 items-center">
      <SearchIconInput />
      <Input
        className={'border-none w-full bg-inherit p-0'}
        placeholder="Search"
        value={searchTerm}
        onChange={handleSearch}
      />
    </div>
  );
};

export default FilterSearch;
