export const priceFilters = [
  {
    id: 1,
    name: 'Select Range',
    range: {
      min: 100,
      max: 10000,
    },
  },
  {
    id: 2,
    name: 'Fit to my budget',
  },
];

export const walletsFilter = [
  {
    id: 1,
    name: 'Learning',
    points: 1000,
  },
  {
    id: 2,
    name: 'Health',
    points: 2000,
  },
  {
    id: 3,
    name: 'Points',
    points: 12000,
  },
  {
    id: 4,
    name: 'Tenure',
    points: 1200,
  },
];

export const categoriesFilter = [
  {
    id: 1,
    name: 'Travel and stays',
  },
  {
    id: 2,
    name: 'Auto and accessories',
  },
  {
    id: 3,
    name: 'Baby & kids',
  },
  {
    id: 4,
    name: 'Cash & pre-paid cards',
  },
  {
    id: 5,
    name: 'Charity',
  },
  {
    id: 6,
    name: 'Electronics',
  },
  {
    id: 7,
    name: 'Fashion & Lifestyle',
  },
  {
    id: 8,
    name: 'Food',
  },
];

export const brandFilters = [
  {
    id: 1,
    name: 'Swiggy',
  },
  {
    id: 2,
    name: 'Auto and accessories',
  },
  {
    id: 3,
    name: 'Baby & kids',
  },
];
