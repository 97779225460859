const MastersIcon = (props) => (
  // <svg
  //   xmlns="http://www.w3.org/2000/svg"
  //   width={20}
  //   height={20}
  //   fill="none"
  //   {...props}
  // >
  //   <path
  //     stroke={props.isActive ? props.activeColor : '#475467'}
  //     strokeLinecap="round"
  //     strokeLinejoin="round"
  //     strokeWidth={1.67}
  //     d="m5 5 3.75 3.75M5 5H2.5l-.833-2.5.833-.833L5 2.5V5Zm11.05-2.716-2.19 2.19c-.33.33-.496.495-.557.685a.833.833 0 0 0 0 .515c.061.19.226.356.556.686l.198.197c.33.33.495.495.685.557a.833.833 0 0 0 .515 0c.19-.062.356-.227.686-.557L17.99 4.51a4.583 4.583 0 0 1-5.133 6.238c-.405-.08-.608-.12-.73-.108a.697.697 0 0 0-.31.095c-.11.058-.219.167-.437.385l-5.964 5.964a1.768 1.768 0 1 1-2.5-2.5L8.88 8.62c.218-.218.327-.327.386-.436a.697.697 0 0 0 .094-.31c.012-.123-.028-.326-.108-.731a4.583 4.583 0 0 1 6.796-4.858ZM10 12.5l4.583 4.583a1.768 1.768 0 1 0 2.5-2.5l-3.77-3.77a4.565 4.565 0 0 1-.779-.143c-.324-.089-.68-.024-.918.214L10 12.5Z"
  //   />
  // </svg>
  <svg
    width={props?.width ? props?.width : '20'}
    height={props?.height ? props?.height : '20'}
    viewBox="0 0 42 43"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.5 11.3125L18.375 19.1875M10.5 11.3125H5.25L3.5 6.0625L5.25 4.3125L10.5 6.0625V11.3125ZM33.7032 5.60927L29.1049 10.2076C28.4119 10.9006 28.0654 11.2471 27.9355 11.6467C27.8213 11.9982 27.8213 12.3768 27.9355 12.7283C28.0654 13.1279 28.4119 13.4744 29.1049 14.1674L29.5201 14.5826C30.2131 15.2756 30.5596 15.6221 30.9592 15.752C31.3107 15.8662 31.6893 15.8662 32.0408 15.752C32.4404 15.6221 32.7869 15.2756 33.4799 14.5826L37.7812 10.2813C38.2445 11.4085 38.5 12.6432 38.5 13.9375C38.5 19.2532 34.1907 23.5625 28.875 23.5625C28.2341 23.5625 27.6079 23.4999 27.0021 23.3804C26.1513 23.2126 25.726 23.1287 25.4681 23.1544C25.1939 23.1817 25.0588 23.2228 24.8159 23.3528C24.5874 23.4751 24.3582 23.7043 23.8998 24.1627L11.375 36.6875C9.92525 38.1372 7.57475 38.1372 6.125 36.6875C4.67525 35.2377 4.67525 32.8872 6.125 31.4375L18.6498 18.9127C19.1082 18.4543 19.3374 18.2251 19.4597 17.9966C19.5897 17.7537 19.6308 17.6186 19.6581 17.3444C19.6838 17.0865 19.5999 16.6612 19.4321 15.8104C19.3126 15.2046 19.25 14.5784 19.25 13.9375C19.25 8.62176 23.5593 4.3125 28.875 4.3125C30.6346 4.3125 32.284 4.78469 33.7032 5.60927ZM21.0001 27.0624L30.625 36.6873C32.0747 38.1371 34.4252 38.1371 35.875 36.6873C37.3247 35.2376 37.3247 32.887 35.875 31.4373L27.9568 23.5193C27.3963 23.4662 26.8497 23.3651 26.3214 23.2201C25.6405 23.0332 24.8937 23.1688 24.3944 23.6681L21.0001 27.0624Z"
      stroke={props.isActive ? props.activeColor : '#475467'}
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default MastersIcon;
