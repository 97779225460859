import React from 'react';

const AcceptTickIcon = () => {
  return (
    <svg
      width="52"
      height="52"
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_780_120757)">
        <rect x="2" y="1" width="48" height="48" rx="10" fill="white" />
        <rect
          x="2.75"
          y="1.75"
          width="46.5"
          height="46.5"
          rx="9.25"
          stroke="url(#paint0_linear_780_120757)"
          strokeWidth="1.5"
        />
        <path
          d="M34 19L23 30L18 25"
          stroke="url(#paint1_linear_780_120757)"
          strokeWidth="1.67"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_780_120757"
          x="0"
          y="0"
          width="52"
          height="52"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_780_120757"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_780_120757"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_780_120757"
          x1="2"
          y1="49"
          x2="50"
          y2="49"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="var(--primaryLeft)" />
          <stop offset="1" stopColor="var(--primaryRight)" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_780_120757"
          x1="18"
          y1="30"
          x2="34"
          y2="30"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="var(--primaryLeft)" />
          <stop offset="1" stopColor="var(--primaryRight)" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default AcceptTickIcon;
