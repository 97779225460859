const ArchiveIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <path
      stroke="var(--midColor)"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.67}
      d="M16 19.997a2.292 2.292 0 0 1-.39-.035 2 2 0 0 1-1.572-1.572C14 18.197 14 17.965 14 17.5s0-.697.038-.89a2 2 0 0 1 1.572-1.572c.193-.038.425-.038.89-.038h15c.465 0 .697 0 .89.038a2 2 0 0 1 1.572 1.572c.038.193.038.425.038.89s0 .697-.038.89a2 2 0 0 1-1.572 1.572c-.107.02-.226.03-.39.035M22 25h4m-10-5h16v8.2c0 1.68 0 2.52-.327 3.162a3 3 0 0 1-1.311 1.311C29.72 33 28.88 33 27.2 33h-6.4c-1.68 0-2.52 0-3.162-.327a3 3 0 0 1-1.311-1.311C16 30.72 16 29.88 16 28.2V20Z"
    />
    <rect
      width={46.5}
      height={46.5}
      x={0.75}
      y={0.75}
      stroke="var(--midColor)"
      strokeWidth={1.5}
      rx={9.25}
    />
  </svg>
);
export default ArchiveIcon;
