import React from 'react';
import ViewHeadingAtom from '../viewHeadingAtom/ViewHeadingAtom';
import ViewParagraphAtom from '../viewParagraphAtom/ViewParagraphAtom';

const ViewHPMolecule = (props) => {
  const { columnData, card } = props;
  return (
    <div
      style={{ justifyContent: columnData?.attr?.flex?.justify ?? 'center' }}
      className={`${
        card && 'ring-1 ring-gray-200 rounded-lg shadow-md p-5'
      } w-full h-full flex flex-col gap-5`}
    >
      <ViewHeadingAtom columnData={columnData} />
      <ViewParagraphAtom columnData={columnData} />
    </div>
  );
};

export default ViewHPMolecule;
