import { fetchMedia } from 'apiClient/templateBuilder/templateBuilderApi';
import { useEffect, useState } from 'react';
import { imageURLCheck } from 'utils/ImageUrlCheck';
import { onResponse } from 'utils/toastMessages';

const ViewVideoAtom = (props) => {
  const { columnData, height } = props;
  const [videoUrl, setVideoUrl] = useState();
  const getVideoUrl = async () => {
    try {
      const response = await fetchMedia(columnData.attr.mediaId);
      setVideoUrl(response.imageUrl);
    } catch (error) {
      onResponse(error.message);
    }
  };
  useEffect(() => {
    getVideoUrl();
  }, []);
  const metaData = columnData?.attr?.video.metaData;
  return (
    <video
      src={imageURLCheck(videoUrl)}
      autoPlay={metaData?.autoPlay ?? false}
      muted={metaData?.muted ?? false}
      controls={true}
      className={`${height ?? 'max-h-96'}  w-full rounded-lg bg-black`}
    ></video>
  );
};

export default ViewVideoAtom;
