import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  createOffer,
  getHiringOffers,
  getHiringProposals,
  getHiringVersion,
} from 'apiClient/hiringOffer/hiringOffer';
export const initialState = {
  selectedField: '',
  sidePanelType: 'OFFER',
  selectedFieldMetaData: {},
  loader: [],
  offerSubData: {},
  offerSubLoader: [],
  versionSubData: {},
  versionSubLoader: [],
  offers: {
    versions: [],
    proposals: [],
    list: [],
    total: 0,
  },
};
export const createOfferTemplateThunk = createAsyncThunk(
  'create/offer',
  async (payload) => {
    const response = await createOffer(payload);
    return response;
  },
);
export const getOffersThunk = createAsyncThunk(
  'get/offers',
  async (payload) => {
    const response = await getHiringOffers(payload);
    return response;
  },
);
export const getVersionsByOfferTemplateThunk = createAsyncThunk(
  'get/versions',
  async (id) => {
    const response = await getHiringVersion(id);
    return response;
  },
);
export const getProposalsByVersionTemplateThunk = createAsyncThunk(
  'get/proposals',
  async ({ id, hiringId }) => {
    const response = await getHiringProposals(id, hiringId);
    return response;
  },
);
const offersReducer = createSlice({
  name: 'offers',
  initialState,
  reducers: {
    setSelectedField(state, action) {
      state.selectedField = action.payload;
    },
    setSidePanelType(state, action) {
      state.sidePanelType = action.payload;
    },
    setOfferSubData(state, action) {
      const { id, data } = action.payload;
      state.offerSubData[id] = data;
      return state;
    },
    setAddSubOfferId(state, action) {
      state.offerSubLoader?.push(action.payload);
      return state;
    },
    setRemoveSubOfferId(state, action) {
      state.offerSubLoader = state.offerSubLoader?.filter(
        (ele) => ele !== action.payload,
      );
      return state;
    },
    setVersionSubData(state, action) {
      const { id, data } = action.payload;
      state.versionSubData[id] = data;
      return state;
    },
    setAddSubVersionId(state, action) {
      state.versionSubLoader?.push(action.payload);
      return state;
    },
    setRemoveSubVersionId(state, action) {
      state.versionSubLoader = state.versionSubLoader?.filter(
        (ele) => ele !== action.payload,
      );
      return state;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getOffersThunk.pending, (state, action) => {
      state.loader.push('OFFERS_LOADING');
    });
    builder.addCase(getOffersThunk.fulfilled, (state, action) => {
      state.loader = state.loader.filter((ele) => ele !== 'OFFERS_LOADING');
      state.offers.list = action.payload?.data?.hiringApplication || [];
      state.offers.total = action.payload?.data?.pagination?.totalCount || 0;
    });
    builder.addCase(
      getVersionsByOfferTemplateThunk.fulfilled,
      (state, action) => {
        state.loader = state.loader.filter((ele) => ele !== 'VERSIONS_LOADING');
        state.offers.versions = action.payload?.data;
        return state;
      },
    );
    builder.addCase(
      getProposalsByVersionTemplateThunk.fulfilled,
      (state, action) => {
        state.loader = state.loader.filter(
          (ele) => ele !== 'PROPOSALS_LOADING',
        );
        state.offers.proposals = action.payload?.data;
        return state;
      },
    );
  },
});
export const {
  setSelectedField,
  setSidePanelType,
  setOfferSubData,
  setAddSubOfferId,
  setRemoveSubOfferId,
  setVersionSubData,
  setAddSubVersionId,
  setRemoveSubVersionId,
} = offersReducer.actions;
export default offersReducer.reducer;
