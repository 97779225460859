import React from 'react';

const ClockRewind = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        d="M13.2416 7.875L12.0753 6.70833L10.9083 7.875M12.25 7C12.25 9.8995 9.8995 12.25 7 12.25C4.10051 12.25 1.75 9.8995 1.75 7C1.75 4.10051 4.10051 1.75 7 1.75C8.92612 1.75 10.61 2.78725 11.5235 4.33364M7 4.08333V7L8.75 8.16667"
        stroke="#475467"
        strokeWidth="1.16667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ClockRewind;
