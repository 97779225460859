import { DEBOUNCE_SEARCH_TIME } from 'apiClient/api.constant';
import { fetchEmployeeSearch } from 'apiClient/header/header';
import { createPlan } from 'apiClient/variablePay/variablePay';
import PlusIconGradient from 'assets/svg/plusIconGradient';
import Button from 'components/atoms/button/Button';
import DatePicker from 'components/atoms/FormElements/datepicker/DatePicker';
import { Input } from 'components/atoms/FormElements/input/Input';
import Select from 'components/atoms/FormElements/select/Select';
import { customStyles } from 'components/atoms/linkSelect/propertySelectCss';
import ModalPopup from 'components/layout/modalPopup/ModalPopup';
import { endOfMonth } from 'date-fns';
import Avatar from 'layouts/header/Avatar';
import { setToastMessage } from 'pages/recognition/redemption/helper';
import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { BonusBasedOnEnum, CreatePlanPayload } from './VariablePay.types';

type CreatePlanPopUpProps = {
  setCreatePlanPopUpOpen: Dispatch<SetStateAction<boolean>>;
  workflows: any[];
};

const bonusBasedOn = [
  { id: 'Events', value: BonusBasedOnEnum.Events, label: 'Event(s)' },
  { id: 'Metrics', value: BonusBasedOnEnum.Metrics, label: 'Metric(s)' },
];

const salaryGroupOptions = [{ label: 'Variable Pay', value: 'VariablePay' }];

export const CreatePlanPopUp = ({
  setCreatePlanPopUpOpen,
  workflows,
}: CreatePlanPopUpProps) => {
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(endOfMonth(new Date()));
  const [loading, setLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const navigate = useNavigate();
  const [selectedDataManagers, setSelectedDataManagers] = useState([]);
  const [selectedPloicyOwners, setSelectedPolicyOwners] = useState([]);
  const [planDetails, setPlanDetails] = useState<CreatePlanPayload>({
    planName: '',
    startDate: new Date().toISOString(),
    endDate: endOfMonth(new Date()).toISOString(),
    salGroup: salaryGroupOptions[0].value,
    bonusBased: bonusBasedOn[0].value,
    workflowId: workflows?.[0]?.id,
    planOwnerId: [],
    planDataManagerId: [],
  });

  // Function to load options asynchronously for dropdowns
  const loadOptions = async (
    query: string,
    loadedOptions: any[],
    { page }: { page: number },
  ) => {
    const response = await fetchEmployeeSearch({
      pageSize: 10,
      pageIndex: page,
      displayName: query,
      department: query,
      lastName: query,
      fullName: query,
      middleName: query,
      businessEmail: query,
      subDepartment: query,
      id: query,
      firstName: query,
      jobTitle: query,
    });

    const options = response?.EmployeeDetails?.map((option: any) => ({
      value: option?.userId?.value,
      label: option?.fullName?.value,
      ...option,
    }));

    return {
      options,
      hasMore: query ? false : true,
      additional: {
        page: query ? 0 : page + 1,
      },
    };
  };

  // Validation Logic
  const validateForm = () => {
    const validationErrors: { [key: string]: string } = {};

    if (!planDetails.planName.trim()) {
      validationErrors.planName = 'Plan name is required';
    }

    if (
      planDetails.bonusBased === BonusBasedOnEnum.Events &&
      !planDetails.eventName?.trim()
    ) {
      validationErrors.eventName = 'Event name is required';
    }

    if (!startDate || !endDate) {
      validationErrors.dates = 'Start and end date are required';
    }

    if (selectedPloicyOwners.length === 0) {
      validationErrors.planOwnerId = 'At least one Plan Owner is required';
    }

    if (selectedDataManagers.length === 0) {
      validationErrors.planDataManagerId =
        'At least one Data Manager is required';
    }

    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };

  const handleCreate = async () => {
    if (!validateForm()) return; // Return if form is invalid

    try {
      setLoading(true);
      const response = await createPlan(planDetails);
      navigate(`/variable-pay/plan/${response?.data?.planId}`);
      setToastMessage('Plan created successfully!');
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setToastMessage('Something went wrong!');
    }
  };

  const handleChange = (key: string, value: string | BonusBasedOnEnum) => {
    setPlanDetails({ ...planDetails, [key]: value });
    setErrors({ ...errors, [key]: '' }); // Clear errors on input change
  };

  useEffect(() => {
    if (startDate && endDate) {
      setPlanDetails({
        ...planDetails,
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
      });
      setErrors({ ...errors, dates: '' }); // Clear date errors
    }
  }, [startDate, endDate]);

  useEffect(() => {
    setPlanDetails({
      ...planDetails,
      planOwnerId: selectedPloicyOwners?.map(({ value }) => value),
      planDataManagerId: selectedDataManagers?.map(({ value }) => value),
    });
  }, [selectedPloicyOwners, selectedDataManagers]);

  return (
    <ModalPopup
      onClose={() => setCreatePlanPopUpOpen(false)}
      title={
        <div className="gradient-border-and-no-text mx-2 w-12 h-12 before:border-2 before:rounded-[10px] flex items-center justify-center">
          <PlusIconGradient />
        </div>
      }
      body={
        <div>
          <div className="px-6 space-y-5 pb-5">
            <div className="space-y-1">
              <p className="text-lg font-medium text-gray-900">Create New</p>
              <p className="text-sm text-gray-600">Create a new Bonus Plan</p>
            </div>
            <div className="gap-x-8 gap-y-4 flex items-center flex-wrap">
              <div className="w-[47.5%]">
                <Input
                  // @ts-ignore
                  label="Plan Name"
                  className="!py-2 text-sm"
                  placeholder="Enter plan name"
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    handleChange('planName', event.target.value)
                  }
                />
                {errors.planName && (
                  <p className="text-red-500 text-sm mt-1">{errors.planName}</p>
                )}
              </div>
              {planDetails.bonusBased === BonusBasedOnEnum.Events && (
                <div className="w-[47.5%]">
                  <Input
                    // @ts-ignore
                    label="Event Name"
                    className="!py-2 text-sm"
                    placeholder="Enter event name"
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                      handleChange('eventName', event.target.value)
                    }
                  />
                  {errors.eventName && (
                    <p className="text-red-500 text-sm mt-1">
                      {errors.eventName}
                    </p>
                  )}
                </div>
              )}
              <div className="w-[47.5%]">
                <Select
                  // @ts-ignore
                  placeholder="Select salary group"
                  label="Salary Group under Total Rewards"
                  options={salaryGroupOptions}
                  value={salaryGroupOptions?.filter(
                    (option) => option?.value === planDetails.salGroup,
                  )}
                  onChange={(option: { label: string; value: string }) =>
                    handleChange('salGroup', option?.value)
                  }
                />
              </div>
              <div className="w-[47.5%]">
                <Select
                  // @ts-ignore
                  placeholder="Select workflow"
                  label="Workflow"
                  options={workflows}
                  value={workflows?.filter(
                    (option) => option?.value === planDetails.workflowId,
                  )}
                  onChange={(option: { label: string; value: string }) =>
                    handleChange('workflowId', option?.value)
                  }
                />
              </div>
              <div className="w-[47.5%]">
                <DatePicker
                  // @ts-ignore
                  label="Start and End Date"
                  selectsRange
                  startDate={startDate}
                  endDate={endDate}
                  onChange={(range: Date[]) => {
                    setStartDate(range[0]);
                    setEndDate(range[1]);
                  }}
                />
                {errors.dates && (
                  <p className="text-red-500 text-sm mt-1">{errors.dates}</p>
                )}
              </div>
              <div className="w-[47.5%]">
                <Select
                  // @ts-ignore
                  formatOptionLabel={(option) => (
                    <div className="flex items-center w-full gap-1">
                      <Avatar
                        // @ts-ignore
                        image={option?.avatar?.value}
                        userName={option?.fullName?.value}
                        imageSize={16}
                      />
                      <p>{option?.fullName?.value}</p>
                    </div>
                  )}
                  placeholder="Select Plan Owner(s)"
                  label="Plan Owner(s)"
                  isMulti
                  className="bg-gray-50 focus:outline-none rounded-lg z-1 text-sm sm:block"
                  async={true}
                  loadOptions={loadOptions}
                  debounceTimeout={DEBOUNCE_SEARCH_TIME}
                  noOptionsMessage={() => 'No results found'}
                  isSearchable={true}
                  styles={customStyles}
                  additional={{
                    page: 0,
                  }}
                  onChange={(options: any) => setSelectedPolicyOwners(options)}
                />
                {errors.planOwnerId && !selectedPloicyOwners?.length && (
                  <p className="text-red-500 text-sm mt-1">
                    {errors.planOwnerId}
                  </p>
                )}
              </div>
              <div className="w-[47.5%]">
                <Select
                  //@ts-ignore
                  placeholder=""
                  options={bonusBasedOn}
                  label="Bonus Based On"
                  value={bonusBasedOn?.filter(
                    (option) => option?.value === planDetails.bonusBased,
                  )}
                  onChange={(option: { label: string; value: string }) =>
                    handleChange('bonusBased', option?.value)
                  }
                />
              </div>
              <div className="w-[47.5%]">
                <Select
                  // @ts-ignore
                  formatOptionLabel={(option) => (
                    <div className="flex items-center w-full gap-1">
                      <Avatar
                        // @ts-ignore
                        image={option?.avatar?.value}
                        userName={option?.fullName?.value}
                        imageSize={16}
                      />
                      <p>{option?.fullName?.value}</p>
                    </div>
                  )}
                  placeholder="Select Data Manager(s)"
                  label="Data Manager(s)"
                  isMulti
                  className="bg-gray-50 focus:outline-none rounded-lg z-1 text-sm sm:block"
                  async={true}
                  loadOptions={loadOptions}
                  debounceTimeout={DEBOUNCE_SEARCH_TIME}
                  noOptionsMessage={() => 'No results found'}
                  isSearchable={true}
                  styles={customStyles}
                  additional={{
                    page: 0,
                  }}
                  onChange={(options: any) => setSelectedDataManagers(options)}
                />
                {errors.planDataManagerId && !selectedDataManagers?.length && (
                  <p className="text-red-500 text-sm mt-1">
                    {errors.planDataManagerId}
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="flex items-center justify-end gap-5 px-6 pt-4 bg-white border-t border-gray-200 sticky bottom-0">
            <button
              className="text-sm font-semibold text-gray-600"
              onClick={() => setCreatePlanPopUpOpen(false)}
            >
              Cancel
            </button>
            <Button
              className="text-sm font-semibold"
              variant="filled"
              onClick={handleCreate}
            >
              {loading ? 'Creating...' : 'Create'}
            </Button>
          </div>
        </div>
      }
      height="35rem"
      width="w-[49rem]"
    />
  );
};
