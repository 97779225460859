import { get, post, put } from 'apiClient/apiCalls';
import { AxiosError } from 'axios';
import { setToastMessage } from 'pages/recognition/redemption/helper';
import * as CONSTANT from '../api.constant';

export const fetchBanners = async () => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_RNR_V2}/voucher/get-all-banners`,
    );

    return response.data.banner;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! Banners fetch failed');
    }
  }
};

export const fetchWalletPoints = async () => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_RNR_V2}/voucher/get-wallet-points`,
    );
    return response.data.walletEmployee;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! Wallet points fetch failed');
    }
  }
};

export const fetchVouchers = async (currentPage) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_RNR_V2}/voucher/get-all-vouchers?pageSize=5&pageIndex=${currentPage}`,
    );
    return response.data.vouchers;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! Vouchers fetch failed');
    }
  }
};

export const fetchFilterFields = async (walletId) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_RNR_V2}/voucher/get-vouchers-filter-fields?walletId=${walletId}`,
    );
    return response.data;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! Filter fetch failed');
    }
  }
};

export const fetchSearchedVouchers = async (searchedValue) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_RNR_V2}/voucher/get-search-value`,
      {
        search: searchedValue,
      },
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! Search voucher fetch failed');
    }
  }
};

export const fetchFilteredVouchers = async (
  selectedFilter,
  searchedValue,
  currentPage,
) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_RNR_V2}/voucher/get-filter-vouchers`,
      {
        view: selectedFilter,
        search: searchedValue,
        pageSize: 5,
        pageIndex: currentPage,
      },
    );
    return {
      vouchers: response.data.productFilter,
      totalProducts: response.data.totalProduct,
    };
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! Search voucher fetch failed');
    }
  }
};

export const fetchTotalProducts = async (selectedFilter, searchedValue) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_RNR_V2}/voucher/get-filter-vouchers`,
      { view: selectedFilter, search: searchedValue },
    );
    return response.data.totalProduct;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! Search voucher fetch failed');
    }
  }
};

export const addVoucherToCart = async (voucher, walletTypeName) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_RNR_V2}/voucher/add-cart-voucher`,
      {
        productId: voucher.id,
        points: voucher.points,
        voucherQuantity: voucher.voucherQuantity,
        walletTypeName,
        amount: voucher.amount,
        skuNumber: voucher.skuNumber,
        status: 'Added',
      },
    );

    if (response?.data?.isAdded) {
      return response?.data || [];
    }
    if (!response?.data?.isAdded) {
      setToastMessage('Error! Please try another voucher.');
    }
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! Search voucher fetch failed');
    }
  }
};

export const fetchVouchersInCart = async (walletTypeName) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_RNR_V2}/voucher/get-cart-voucher?walletTypeName=${walletTypeName}`,
    );
    return response?.data || [];
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! Search voucher fetch failed');
    }
  }
};

export const editCartQuantity = async (voucher, walletTypeName) => {
  try {
    const response = await put(
      `${CONSTANT.API_URL}/${CONSTANT.API_RNR_V2}/voucher/edit-cart-voucher`,
      {
        id: voucher.id,
        voucherQuantity: voucher.voucherQuantity,
        status: 'Added',
        walletTypeName,
      },
    );
    if (response?.data?.isUpdated) {
      return response?.data || [];
    }
    if (!response?.data?.isUpdated) {
      setToastMessage('Error! Quantity not updated.');
    }
  } catch (err) {
    if (err) {
      console.error(err, 'err');
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! Search voucher fetch failed');
    }
  }
};

export const removeFromCartAPI = async (voucherId, walletTypeName) => {
  try {
    const response = await put(
      `${CONSTANT.API_URL}/${CONSTANT.API_RNR_V2}/voucher/edit-cart-voucher`,
      {
        id: voucherId,
        voucherRemove: true,
        walletTypeName,
      },
    );
    if (response?.data?.isUpdated) {
      return response?.data || [];
    }
    if (!response?.data?.isUpdated) {
      setToastMessage('Error! Something went wrong.');
    }
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! Search voucher fetch failed');
    }
  }
};

export const clearCartAPI = async (walletTypeName) => {
  try {
    const response = await put(
      `${CONSTANT.API_URL}/${CONSTANT.API_RNR_V2}/voucher/clear-cart-voucher?walletTypeName=${walletTypeName}`,
      {},
    );
    if (response.statusCode === 200 || response.statusCode === 201) {
      return { cartWallet: [] };
    }
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! Clear cart failed');
    }
  }
};

export const createOrderTransactionAPI = async ({
  vouchers,
  walletRedeemId,
  pointRedeemed,
  totalAmount,
  conversionRate,
  currencyId,
}) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_RNR_V2}/voucher/create-order-transaction`,
      {
        orderTransaction: {
          vouchers,
          walletRedeemId,
          pointRedeemed: Math.round(pointRedeemed),
          currencyId,
          comments: 'Voucher',
          conversionRate,
          totalAmount,
        },
      },
    );
    if (response) {
      return response;
    }
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! Order transaction failed');
    }
  }
};
