/* eslint-disable array-callback-return */
import UserData from 'components/molecules/userData/UserData';
import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { fetchEmployeeData } from '../../apiClient/header/header';
import SearchIconLogo from '../../assets/svg/searchIconLogo';
import LoadingCircle from '../../components/molecules/loadingCircle/LoadingCircle';
const Search = () => {
  const ref = useRef();
  const [searchResult, setSearchResult] = useState([]);
  const [searchedWord, setSearchedWord] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [searchFocus, setSearchFocus] = useState(false);
  const fetchSearchResult = async () => {
    try {
      const userArray = await fetchEmployeeData(searchedWord, 0, 10);
      setIsLoading(false);
      setSearchResult(userArray);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (searchFocus && ref.current && !ref.current.contains(e.target)) {
        setSearchFocus(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  });
  useEffect(() => {
    if (searchedWord.length >= 3) {
      setIsLoading(true);
      fetchSearchResult();
    } else {
      setSearchResult([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchedWord]);

  return (
    <div
      ref={ref}
      className="flex flex-col items-start p-0  w-full h-10 relative"
    >
      <div
        className={`flex flex-row items-center justify-center w-[250px] sm:w-full my-auto pl-2  ${
          searchFocus ? 'border-partition' : 'border-2 gradient-border'
        } rounded-lg `}
      >
        <SearchIconLogo styles="w-5 h-5 left-0 text-gray-500" />
        <input
          type="text"
          className="w-full bg-white focus:outline-none px-2 mx-1 py-1 text-base sm:block"
          placeholder="Search"
          onChange={(e) => {
            setSearchedWord(e.target.value);
          }}
          onFocus={() => {
            setSearchFocus(true);
          }}
        />
      </div>
      {searchedWord !== '' && searchFocus && (
        <div
          className="flex flex-col py-1 px-0 items-start absolute top-[123%] left-[1 bg-gray-50 overflow-y-scroll custom-scrollbar w-full max-h-[400px] text-center border-2 rounded-lg "
          style={{
            boxShadow:
              '0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)',
            zIndex: 100,
          }}
        >
          {!isLoading ? (
            <div className="flex flex-col py-0 px-4 w-full top-6 bg-white">
              <div className="flex flex-row items-start w-full py-2">
                <span className="font-bold text-sm text-left">Employees</span>
                {/**<span className="w-1/4 text-transparent font-semibold text-xs bg-clip-text bg-gradient-to-r from-[var(--primaryLeft)] to-[var(--primaryRight)] underline">
                See all
              </span> */}
              </div>
              <div className="flex flex-col items-start p-0">
                {searchResult?.EmployeeDetails?.length > 0 ? (
                  <ul className="text-left w-full">
                    {searchResult?.EmployeeDetails?.map((item, index) => (
                      <li
                        key={index}
                        onClick={() => {
                          setSearchFocus(false);
                        }}
                      >
                        <Link to={`/profile/${item?.userId?.value}`}>
                          <div className="flex flex-row items-center py-0.5 px-1.5 w-full hover:bg-gray-100 bg-white rounded-lg h-16">
                            <div className="flex flex-col items-start">
                              <UserData userData={item} />
                            </div>
                          </div>
                        </Link>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p>No results found</p>
                )}
              </div>
            </div>
          ) : (
            <div className="flex flex-col items-center justify-center w-full h-full p-8 bg-white">
              <LoadingCircle />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Search;
