import CalendarAnniversary from 'assets/svg/calendarJoin';
import React, { forwardRef } from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './DatePicker.styles.scss';
import moment from 'moment';

const DatePicker = React.forwardRef((props, ref) => {
  const { label, required, isDisabled, error, className, dateFormat } = props;
  const CustomInput = forwardRef(({ value, onClick }, ref) => {
    return (
      <div
        className={`rounded-lg text-sm focus:outline-none focus:ring-1 focus:ring-gray-300 text-gray-500 bg-white w-full px-6 py-2.5 border border-gray-300 flex items-center gap-2.5 ${
          isDisabled && '!bg-gray-100'
        } ${className}  ${error ? 'border-red-600' : 'border-gray-300'}`}
        onClick={onClick}
        ref={ref}
        tabIndex={0}
      >
        <CalendarAnniversary className="text-gray-500" />
        {value ? value : <span className="text-gray-400">Select date</span>}
      </div>
    );
  });
  CustomInput.displayName = 'CustomInput';
  return (
    <React.Fragment>
      {label && (
        <label className={`text-sm my-1 block text-gray-700 font-medium`}>
          {label} {required && <span className="text-red-600">*</span>}
        </label>
      )}
      <ReactDatePicker
        disabled={isDisabled}
        ref={(el) => {
          if (ref?.current) {
            ref.current = el;
          }
        }}
        dropdownMode="select"
        showMonthDropdown
        showYearDropdown
        customInput={<CustomInput />}
        dateFormat={dateFormat}
        {...props}
      />
      {error && <p className=" text-red-500 text-sm mt-.5">{error?.message}</p>}
    </React.Fragment>
  );
});
DatePicker.displayName = 'DatePicker';
export default DatePicker;
