import { toInteger } from 'lodash';
import { colorArrayShade2, colorArrayShade4 } from 'utils/colorsArray';

export const formatDataSet = (dataObj) => {
  if (!dataObj) return { labels: [], datasets: [] };
  const dimensionEntries =
    Object.values(dataObj?.dimensions || {})?.flat(2) || [];
  const measureEntries = Object.values(dataObj?.measures || {})?.flat(2) || [];

  let data = {
    labels: dimensionEntries,
    datasets: [
      { data: measureEntries, backgroundColor: ['#F63D68'], borderWidth: 1 },
    ],
  };
  return data;
};

const selectedColorPalatefromLocalStorage = JSON.parse(
  localStorage.getItem('selectedColorPalate'),
);

const getColor = (chartTheme, index) => chartTheme[index] || '#98A2B3';
const getColorBorder = (index) => colorArrayShade2[index] || '#98A2B3';

export const formatDataSetForBarChartAndLineChart = (
  chartTheme,
  dataObj,
  card,
) => {
  if (!dataObj) return { labels: [], datasets: [] };
  const dimensionKeys = Object.keys(dataObj?.dimensions || {});

  const dimensionValues = dimensionKeys?.map(
    (key) => dataObj?.dimensions[key][0],
  );

  const labels = dimensionValues?.[0]?.map((_, index) =>
    dimensionKeys
      ?.map((key) => dataObj?.dimensions[key][0][index])
      ?.join(' - '),
  );

  const measureKeys = Object.keys(dataObj?.measures || {});

  const datasets = measureKeys?.map((key, index) => ({
    label: key, // Use measure key as label
    data: (dataObj?.measures[key] || [])?.flat(2),
    backgroundColor: getColor(chartTheme, index), // Function to generate random color or you can use specific colors
    borderColor: getColor(chartTheme, index), // Border color
    borderRadius: 6,
  }));

  const formattedData = {
    labels: labels,
    datasets: datasets,
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: card?.legend?.isVisible,
        position: card?.legend?.position?.value,
        labels: {
          boxWidth: 10,
          boxHeight: 10,
        },
      },
      datalabels: {
        display: card?.label?.showLabel, // Enable the datalabels plugin
        align: card?.label?.showLabelPosition?.value,
        color: '#000000', // Align the labels within the bar (start, end, center)
      },
    },
    scales: {
      x: {
        title: {
          display: card?.axis?.showXAxisLabel,
          text: dimensionKeys,
        },
        grid: {
          display: card?.grid?.showHorizontalGrid,
          color: card?.grid?.horizontalGridColor || '#EDEDED',
          lineWidth: toInteger(card?.grid?.horizontalGridWidth),
        },
        display: card?.axis?.showXAxisLine,
      },
      y: {
        title: {
          display: card?.axis?.showYAxisLabel,
          text: measureKeys,
        },
        grid: {
          display: card?.grid?.showVerticalGrid,
          color: card?.grid?.verticalGridColor || '#EDEDED',
          lineWidth: toInteger(card?.grid?.verticalGridWidth),
        },
        display: card?.axis?.showYAxisLine,
      },
    },
    ...(card?.cardData?.type === 'Bar Chart' ? { indexAxis: 'y' } : {}),
  };

  return { formattedData: formattedData, options: options };
};

function hexToRgba(hex, opacity = 0.15) {
  // Remove the leading # if present
  hex = hex.replace('#', '');

  // Convert 3-digit hex to 6-digit
  if (hex.length === 3) {
    hex = hex
      .split('')
      .map((char) => char + char)
      .join('');
  }

  // Extract red, green, and blue components
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  // Return the RGBA string
  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}
export const formatDatasSetForAreaChart = (chartTheme, dataObj, card) => {
  if (!dataObj) return { labels: [], datasets: [] };

  const dimensionKeys = Object.keys(dataObj?.dimensions || {});

  const dimensionValues = dimensionKeys?.map(
    (key) => dataObj?.dimensions[key][0],
  );

  const labels = dimensionValues?.[0]?.map((_, index) =>
    dimensionKeys
      ?.map((key) => dataObj?.dimensions[key][0][index])
      ?.join(' - '),
  );
  const measureKeys = Object.keys(dataObj?.measures || {});
  const datasets = measureKeys?.map((key, index) => ({
    label: key, // Use measure key as label
    data: (dataObj?.measures[key] || [])?.flat(2),
    backgroundColor: hexToRgba(getColor(chartTheme, index)), // Function to generate random color or you can use specific colors
    borderColor: getColor(chartTheme, index), // Border color
    fill: true,
  }));
  const formattedData = {
    labels: labels,
    datasets: datasets,
  };
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: card?.legend?.isVisible,
        position: card?.legend?.position?.value,
        labels: {
          boxWidth: 10,
          boxHeight: 10,
        },
      },
      datalabels: {
        display: card?.label?.showLabel, // Enable the datalabels plugin
        align: card?.label?.showLabelPosition?.value,
        color: '#000000', // Align the labels within the bar (start, end, center)
      },
    },
    scales: {
      x: {
        title: {
          display: card?.axis?.showXAxisLabel,
          text: dimensionKeys,
        },
        grid: {
          display: card?.grid?.showHorizontalGrid,
          color: card?.grid?.horizontalGridColor || '#EDEDED',
          lineWidth: toInteger(card?.grid?.horizontalGridWidth),
        },
        display: card?.axis?.showXAxisLine,
      },
      y: {
        title: {
          display: card?.axis?.showYAxisLabel,
          text: measureKeys,
        },
        grid: {
          display: card?.grid?.showVerticalGrid,
          color: card?.grid?.verticalGridColor || '#EDEDED',
          lineWidth: toInteger(card?.grid?.verticalGridWidth),
        },
        display: card?.axis?.showYAxisLine,
      },
    },
  };

  return { formattedData: formattedData, options: options };
};

export const formatDataSetForPieDoughnutChart = (chartTheme, dataObj, card) => {
  if (!dataObj) return { labels: [], datasets: [] };

  const dimensionKeys = Object.keys(dataObj?.dimensions || {});
  const flattenedDimensions = dimensionKeys?.map(
    (key) => dataObj?.dimensions[key][0],
  );

  const measureKey = Object.keys(dataObj?.measures || {})?.[0];
  const measureEntries = (dataObj?.measures?.[measureKey] || [])?.flat(2);

  const labels = flattenedDimensions[0]?.map((_, index) =>
    dimensionKeys
      ?.map((key) => dataObj?.dimensions[key][0][index])
      ?.join(' - '),
  );

  const dataset = {
    label: measureKey, // Use the measure key as the label
    data: measureEntries,
    backgroundColor: measureEntries.map((_, index) =>
      getColor(chartTheme, index),
    ), // Apply the randomly generated colors
    borderWidth: 1,
  };

  const formattedData = {
    labels: labels,
    datasets: [dataset],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: card?.legend?.isVisible,
        position: card?.legend?.position?.value,

        labels: {
          boxWidth: 10,
          boxHeight: 10,
        },
      },
      datalabels: {
        display: card?.label?.showLabel, // Enable the datalabels plugin
        align: card?.label?.showLabelPosition?.value,
        color: '#000000', // Align the labels within the bar (start, end, center)
      },
    },
  };

  return { formattedData: formattedData, options: options };
};

export const formatDataSetForStackedColumnChart = (
  chartTheme,
  dataObj,
  card,
) => {
  if (!dataObj) return { labels: [], datasets: [] };
  const dimensionKeys = Object.keys(dataObj?.dimensions || {});

  const dimensionValues = dimensionKeys?.map(
    (key) => dataObj?.dimensions[key][0],
  );
  const labels = dimensionValues[0]?.map((_, index) =>
    dimensionKeys
      ?.map((key) => dataObj?.dimensions[key][0][index])
      ?.join(' - '),
  );

  const measureKeys = Object.keys(dataObj?.measures || {});
  const datasets = measureKeys?.map((key, index) => ({
    label: key, // Use measure key as label
    data: (dataObj?.measures[key] || [])?.flat(2),
    backgroundColor: getColor(chartTheme, index), // Function to generate random color or you can use specific colors
    stack: 'stack', // Ensure stacking
    borderRadius:
      index === measureKeys?.length - 1 ? { topLeft: 6, topRight: 6 } : 0,
  }));
  const formattedData = {
    labels: labels,
    datasets: datasets,
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: card?.legend?.isVisible,
        position: card?.legend?.position?.value,
        labels: {
          boxWidth: 10,
          boxHeight: 10,
        },
      },
      datalabels: {
        display: card?.label?.showLabel, // Enable the datalabels plugin
        align: card?.label?.showLabelPosition?.value,
        color: '#000000', // Align the labels within the bar (start, end, center)
      },
    },
    scales: {
      x: {
        title: {
          display: card?.axis?.showXAxisLabel,
          text: dimensionKeys,
        },
        grid: {
          display: card?.grid?.showHorizontalGrid,
          color: card?.grid?.horizontalGridColor || '#EDEDED',
          lineWidth: toInteger(card?.grid?.horizontalGridWidth),
        },
        display: card?.axis?.showXAxisLine,
      },
      y: {
        title: {
          display: card?.axis?.showYAxisLabel,
          text: measureKeys,
        },
        grid: {
          display: card?.grid?.showVerticalGrid,
          color: card?.grid?.verticalGridColor || '#EDEDED',
          lineWidth: toInteger(card?.grid?.verticalGridWidth),
        },
        display: card?.axis?.showYAxisLine,
      },
    },
  };

  return { formattedData: formattedData, options: options };
};

export const formatDataSetForScatterChart = (chartTheme, dataObj, card) => {
  const dimensionKey = Object.keys(dataObj?.dimensions || {})[0];
  const measureKeys = Object.keys(dataObj?.measures || {});
  const xMeasureKey = Object.keys(dataObj?.measures || {})?.[0]; // x-axis measure
  const yMeasureKey = Object.keys(dataObj?.measures || {})?.[1]; // y-axis measure
  if (!dataObj || !dimensionKey || measureKeys?.length < 2)
    return { datasets: [] };
  const dimensionValues = dataObj?.dimensions[dimensionKey]?.[0] || [];
  const xMeasureValues = dataObj?.measures[measureKeys[0]]?.[0] || [];
  const yMeasureValues = dataObj?.measures[measureKeys[1]]?.[0] || [];

  // Create dataset
  const dataset = dimensionValues?.map((dimValue, index) => ({
    label: dimValue, // Use dimension value as the label
    data: [
      {
        x: xMeasureValues[index], // x coordinate from first measure
        y: yMeasureValues[index], // y coordinate from second measure
      },
    ],
    backgroundColor: getColor(chartTheme, index),
    borderWidth: 1,
  }));

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        title: {
          display: card?.axis?.showXAxisLabel,
          text: xMeasureKey,
        },
        grid: {
          display: card?.grid?.showHorizontalGrid,
          color: card?.grid?.horizontalGridColor || '#EDEDED',
          lineWidth: toInteger(card?.grid?.horizontalGridWidth),
        },
        display: card?.axis?.showXAxisLine,
      },
      y: {
        title: {
          display: card?.axis?.showYAxisLabel,
          text: yMeasureKey,
        },
        grid: {
          display: card?.grid?.showVerticalGrid,
          color: card?.grid?.verticalGridColor || '#EDEDED',
          lineWidth: toInteger(card?.grid?.verticalGridWidth),
        },
        display: card?.axis?.showYAxisLine,
      },
    },
    plugins: {
      legend: {
        display: card?.legend?.isVisible,
        position: card?.legend?.position?.value,
      },
      datalabels: {
        display: card?.label?.showLabel || false, // Enable the datalabels plugin
        align: card?.label?.showLabelPosition?.value,
        color: '#000000', // Align the labels within the bar (start, end, center)
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            const dataPoint = tooltipItem.raw;
            return [
              `X:${xMeasureKey}: ${dataPoint?.x}`,
              `Y:${yMeasureKey}: ${dataPoint?.y}`,
            ];
          },
        },
      },
    },
  };

  return { formattedData: { datasets: dataset }, options: options };
};

export const formatDataSetForBubbleChart = (chartTheme, dataObj, card) => {
  const dimensionKey = Object.keys(dataObj?.dimensions || {})[0];
  const measureKeys = Object.keys(dataObj?.measures || {});
  const xMeasureKey = Object.keys(dataObj?.measures || {})?.[0]; // x-axis measure
  const yMeasureKey = Object.keys(dataObj?.measures || {})?.[1]; // y-axis measure
  const rMeasureKey = Object.keys(dataObj?.measures || {})?.[2]; // radius measure

  if (!dataObj || !dimensionKey || measureKeys?.length < 3)
    return { datasets: [] };

  // Extract and flatten dimension values
  const dimensionValues = dataObj?.dimensions?.[dimensionKey]?.[0] || [];

  // Get the measure values for x, y, and radius
  const xMeasureValues = dataObj?.measures?.[measureKeys[0]]?.[0] || [];
  const yMeasureValues = dataObj?.measures?.[measureKeys[1]]?.[0] || [];
  const radiusMeasureValues = dataObj?.measures?.[measureKeys[2]]?.[0] || [];

  const getColorBorder = (index) => colorArrayShade4[index] || '#98A2B3';

  const dataset = dimensionValues?.map((dimValue, index) => ({
    label: dimValue, // Use dimension value as the label
    data: [
      {
        x: xMeasureValues[index], // x coordinate from first measure
        y: yMeasureValues[index], // y coordinate from second measure
        r:
          radiusMeasureValues[index] <= 0
            ? 0
            : parseFloat((Math.log(radiusMeasureValues[index]) + 1).toFixed(2)),
        originalR: radiusMeasureValues[index],
      },
    ],
    backgroundColor: getColor(chartTheme, index),
    borderColor: getColor(chartTheme, index),
    borderWidth: 1,
  }));

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        title: {
          display: card?.axis?.showXAxisLabel,
          text: xMeasureKey,
        },
        grid: {
          display: card?.grid?.showHorizontalGrid,
          color: card?.grid?.horizontalGridColor || '#EDEDED',
          lineWidth: toInteger(card?.grid?.horizontalGridWidth),
        },
        display: card?.axis?.showXAxisLine,
      },
      y: {
        title: {
          display: card?.axis?.showYAxisLabel,
          text: yMeasureKey,
        },
        grid: {
          display: card?.grid?.showVerticalGrid,
          color: card?.grid?.verticalGridColor || '#EDEDED',
          lineWidth: toInteger(card?.grid?.verticalGridWidth),
        },
        display: card?.axis?.showYAxisLine,
      },
    },
    plugins: {
      legend: {
        display: card?.legend?.isVisible,
        position: card?.legend?.position?.value,
      },
      datalabels: {
        display: card?.label?.showLabel || false, // Enable the datalabels plugin
        align: card?.label?.showLabelPosition?.value,
        color: '#000000', // Align the labels within the bar (start, end, center)
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            const dataPoint = tooltipItem.raw;
            return [
              `X:${xMeasureKey}: ${dataPoint?.x}`,
              `Y:${yMeasureKey}: ${dataPoint?.y}`,
              `Radius:${rMeasureKey}: ${dataPoint?.originalR}`,
            ];
          },
        },
      },
    },
  };

  return { formattedData: { datasets: dataset }, options: options };
};

export const formatDataSetForHeatmapChart = (dataObj) => {
  // Extract measure keys and dimension keys
  const measureKey = Object.keys(dataObj?.measures || {})[0];
  const dimensionKeys = Object.keys(dataObj?.dimensions || {});

  if (!dataObj || !measureKey || dimensionKeys?.length < 2) {
    return { data: [], keys: [] };
  }

  // Extract the first and second dimension key
  const dimensionKey1 = dimensionKeys[0];
  const dimensionKey2 = dimensionKeys[1];

  // Get the values for both dimensions
  const dimensionValues1 = dataObj?.dimensions[dimensionKey1]?.flat() || [];
  const dimensionValues2 = dataObj?.dimensions[dimensionKey2]?.flat() || [];

  // Get the measure values
  const measureValues = dataObj?.measures[measureKey]?.flat() || [];

  const uniqueD2Values = [...new Set(dimensionValues2)];

  const combinedArray = dimensionValues1?.map((item, index) => ({
    id: item,
    x: dimensionValues2[index],
    y: measureValues[index],
  }));

  const grouped = {};

  combinedArray?.forEach(({ id, x, y }) => {
    if (!grouped[id]) {
      grouped[id] = {};
    }
    grouped[id][x] = y;
    // Store m value for each d2 under the corresponding d1
  });

  const formattedData = Object.keys(grouped)?.map((key) => ({
    id: key,
    data: uniqueD2Values?.map((x) => ({
      x,
      y: grouped[key][x] || 0,
      // Use 0 if the d2 value is not present for this d1
    })),
  }));

  return {
    data: formattedData,
    keys: dimensionValues2,
  };
};

export const formatDataSetForGeoMapChart = (dataObj) => {
  const countryNames = Object.values(dataObj?.dimensions || {})?.flat(2);
  const measureValues = Object.values(dataObj?.measures || {})?.flat(2);
  const data = countryNames?.map((country, index) => ({
    id: country,
    value: measureValues[index],
  }));
  return data;
};

export const formatDataSetForTable = (dataObj) => {
  const measureKey = Object.keys(dataObj?.measures || {})[0];
  const dimensionKeys = Object.keys(dataObj?.dimensions || {});

  if (!dataObj || !measureKey) {
    return [];
  }

  // Extract the first and second dimension key
  const dimensionKey1 = dimensionKeys[0];
  const dimensionKey2 = dimensionKeys[1];

  // Get the values for both dimensions
  const dimensionValues1 = dataObj?.dimensions[dimensionKey1]?.flat() || [];
  const dimensionValues2 = dataObj?.dimensions[dimensionKey2]?.flat() || [];

  // Get the measure values
  const measureValues = dataObj?.measures[measureKey]?.flat() || [];

  const uniqueD2Values = [...new Set(dimensionValues2)];

  const combinedArray = dimensionValues1?.map((item, index) => ({
    id: item,
    x: dimensionValues2[index],
    y: measureValues[index],
  }));

  const grouped = {};

  combinedArray?.forEach(({ id, x, y }) => {
    if (!grouped[id]) {
      grouped[id] = {};
    }
    grouped[id][x] = y;
    // Store m value for each d2 under the corresponding d1
  });
  return combinedArray;
  // console.log('combinedArray','grouped', combinedArray, grouped);
};
