import { fetchLandingPage } from 'apiClient/templateBuilder/templateBuilderApi';
import ViewTemplate from 'pages/admin/templateBuilder/viewTemplate/ViewTemplate';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

export default function PreviewLandingPage() {
  const [landingPageData, setLandingPageData] = useState(null);
  const [error, setError] = useState(null);
  // ##id from the url
  const { id } = useParams();
  useEffect(() => {
    setLandingPageData(null);
    fetchLandingPage(id)
      .then((res) => {
        setLandingPageData(res);
      })
      .catch((err) => {
        setError(true);
      });
  }, [id]);
  if (error) {
    return (
      <div className="flex justify-center h-screen bg-white items-center">
        Something went Wrong! Unable to generate the preview.
      </div>
    );
  }

  if (landingPageData) {
    return (
      <div>
        <ViewTemplate landingPageData={landingPageData} />
      </div>
    );
  } else
    return (
      <div className="flex justify-center h-screen bg-white items-center">
        {' '}
        Loading the Preview.
      </div>
    );
}
