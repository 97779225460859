import { getFinalRangesGraphData } from 'apiClient/rangeBuilder/rangeBuilder';
import { useQuery } from 'react-query';

export const useGetFinalRangesGraphData = (
  planId,
  pageSize,
  pageIndex,
  query,
) =>
  useQuery(['get/finalRangesGraphData', planId], async () => {
    const res = await getFinalRangesGraphData(
      planId,
      pageSize,
      pageIndex,
      query,
    );
    return res?.data;
  });
