const GradientDocumentIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={40}
    height={40}
    fill="none"
    {...props}
  >
    <rect width={38} height={38} x={1} y={1} fill="#fff" rx={19} />
    <path
      stroke="url(#gradient_document_icon_01)"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.667}
      d="M21.667 11.891v3.442c0 .467 0 .7.09.879.08.156.208.284.365.364.178.09.411.09.878.09h3.442m.225 1.657v6.01c0 1.4 0 2.1-.273 2.635a2.5 2.5 0 0 1-1.092 1.093c-.535.272-1.235.272-2.635.272h-5.334c-1.4 0-2.1 0-2.635-.272a2.5 2.5 0 0 1-1.092-1.093c-.273-.535-.273-1.235-.273-2.635v-8.666c0-1.4 0-2.1.273-2.635a2.5 2.5 0 0 1 1.092-1.093c.535-.272 1.235-.272 2.635-.272h2.677c.611 0 .917 0 1.205.069.255.06.499.162.722.299.253.155.469.37.901.803l2.657 2.657c.432.432.649.649.803.9.137.225.238.468.3.724.069.287.069.593.069 1.204Z"
    />
    <rect
      width={38}
      height={38}
      x={1}
      y={1}
      stroke="url(#gradient_document_icon_02)"
      strokeWidth={2}
      rx={19}
    />
    <defs>
      <linearGradient
        id="gradient_document_icon_01"
        x1={13.333}
        x2={26.667}
        y1={28.333}
        y2={28.333}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="var(--primaryLeft)" />
        <stop offset={1} stopColor="#F70" />
      </linearGradient>
      <linearGradient
        id="gradient_document_icon_02"
        x1={0}
        x2={40}
        y1={40}
        y2={40}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="var(--primaryLeft)" />
        <stop offset={1} stopColor="#F70" />
      </linearGradient>
    </defs>
  </svg>
);
export default GradientDocumentIcon;
