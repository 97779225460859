import { createSlice } from '@reduxjs/toolkit';
import { formatSummaryData } from 'utils/compPlanSummaryUtils';

const initialState = {
  data: {
    initial: {},
  },
  plannerData: {
    initial: [],
  },
  approverData: {
    initail: [],
  },
  budgetData: {
    initail: [],
  },
  actionData: {
    initial: [],
  },
  currentFetching: 'initial',
  expandedRow: null,
  expanded: [],
};

const compPlanSummarySlice = createSlice({
  name: 'compensation',
  initialState,
  reducers: {
    setInitialData(state, action) {
      state.data.initial = action.payload;
      const { planner, approver, budget, actions } = formatSummaryData(
        action.payload,
      );
      state.plannerData.initial = [planner];
      state.approverData.initail = [approver];
      state.budgetData.initail = budget;
      state.actionData.initial = [actions];
      return state;
    },
    setDataById(state, action) {
      const { userId, plannerData } = action.payload;
      const { planner, approver, budget, actions } =
        formatSummaryData(plannerData);
      state.plannerData[userId] = planner;
      state.approverData[userId] = approver;
      state.budgetData[userId] = budget;
      state.actionData[userId] = actions;
    },
    setExpanded(state, action) {
      state.expandedRow = action.payload;
      return state;
    },
    toggleExpand(state, action) {
      if (state.expanded.includes(action.payload)) {
        state.expanded = state.expanded.filter((ele) => ele !== action.payload);
      } else {
        state.expanded.push(action.payload);
      }
      return state;
    },
  },
});

export default compPlanSummarySlice.reducer;

export const { setInitialData, setExpanded, toggleExpand, setDataById } =
  compPlanSummarySlice.actions;
