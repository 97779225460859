import { rbacFlags } from 'config/rbacFlags';
import React from 'react';

const OrderHistory = React.lazy(() =>
  import('pages/recognition/orderHistory/OrderHistory'),
);
const Redemption = React.lazy(() =>
  import('pages/recognition/redemption/Redemption'),
);
const CreateNewProgram = React.lazy(() =>
  import('pages/recognition/program/createNewProgram/CreateNewProgram'),
);
const ManageRecognition = React.lazy(() =>
  import('pages/recognition/program/ManageRecognition'),
);
const LeaderBoard = React.lazy(() =>
  import('pages/recognition/leaderBoard/LeaderBoard'),
);
const ManageForm = React.lazy(() =>
  import('pages/recognition/manageForm/ManageForm'),
);
const Forms = React.lazy(() => import('pages/recognition/forms/forms'));

const RecognitionRoutes = [
  {
    type: 'collapse',
    name: 'RecognitionFormManagement',
    key: 'FormManagement',
    route: '/redeem/manage-forms',
    flagName: rbacFlags.Managerecognitionprogramsandawards,
    parentId: 'Recognition',
    module: 'Recognition',
    component: <ManageForm />,
  },
  {
    type: 'collapse',
    name: 'RecognitionCreateForm',
    key: 'CreateForm',
    route: '/redeem/manage-forms/create',
    flagName: rbacFlags.Managerecognitionprogramsandawards,
    module: 'Recognition',
    component: <Forms />,
  },
  {
    type: 'collapse',
    name: 'RecognitionUpdateForm',
    key: 'UpdateForm',
    route: '/redeem/manage-forms/update/:id',
    flagName: rbacFlags.Managerecognitionprogramsandawards,
    parentId: 'Recognition',
    module: 'Recognition',
    component: <Forms />,
  },
  {
    type: 'collapse',
    name: 'leaderBoard',
    key: 'leaderBoard',
    route: '/redeem/recognition-champions/:type/:tableType',
    flagName: rbacFlags.RecognitionChampions,
    module: 'Recognition',
    component: <LeaderBoard />,
  },
  {
    type: 'collapse',
    name: 'redemption',
    key: 'redemption',
    route: '/redeem/rewards',
    parentId: 'Recognition',
    flagName: rbacFlags.Accesstoredeempoints,
    module: 'Recognition',
    component: <Redemption />,
  },
  {
    type: 'collapse',
    name: 'order-history',
    key: 'order-history',
    route: '/redeem/rewards/order-history',
    flagName: rbacFlags.Accesstoredeempoints,
    parentId: 'Recognition',
    module: 'Recognition',
    component: <OrderHistory />,
    // remove after demo
  },
  {
    type: 'collapse',
    name: 'recognitionCreateNewProgram',
    key: 'createNewProgram',
    route: '/redeem/manage-program/create',
    parentId: 'Recognition',
    module: 'Recognition',
    component: <CreateNewProgram />,
  },
  {
    type: 'collapse',
    name: 'recognitionUpdateProgram',
    key: 'updateProgram',
    route: '/redeem/manage-program/update/:id',
    parentId: 'Recognition',
    module: 'Recognition',
    component: <CreateNewProgram />,
  },
  {
    type: 'collapse',
    name: 'manageRecognition',
    key: 'manageRecognition',
    route: '/redeem/manage-recognitions',
    parentId: 'Recognition',
    flagName: rbacFlags.Managerecognitionprogramsandawards,
    module: 'Recognition',
    component: <ManageRecognition />,
  },
];

export default RecognitionRoutes;
