import { AxiosError } from 'axios';
import * as CONSTANT from '../api.constant';
import { del, get, patch, post } from '../apiCalls';

export const createProgram = async (data) => {
  try {
    const res = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_RNR_V2}/${CONSTANT.API_PROGRAM}/create-program`,
      data,
    );

    return res;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! could not create program');
    }
  }
};
export const createBudget = async (data) => {
  try {
    const res = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_RNR_V2}/${CONSTANT.API_BUDGET}/create-budget`,
      data,
    );

    return res;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! could not create budget');
    }
  }
};
export const createBadge = async (data, type) => {
  try {
    const res = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_RNR_V2}/${CONSTANT.API_BADGE}/create-badge`,
      data,
      type,
    );

    return res;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! could not create budget');
    }
  }
};
export const createAward = async (data) => {
  try {
    const res = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_RNR_V2}/${CONSTANT.API_AWARD}/create-award`,
      data,
    );

    return res;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! could not create award');
    }
  }
};
export const publishProgram = async (data) => {
  try {
    const res = await patch(
      `${CONSTANT.API_URL}/${CONSTANT.API_RNR_V2}/${CONSTANT.API_PROGRAM}/publish-program`,
      data,
    );

    return res;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! could not publish program');
    }
  }
};
export const fetchNewProgramBadges = async () => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_RNR_V2}/${CONSTANT.API_BADGE}/get-all-badges`,
    );
    const resultData = response?.data;
    return resultData;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! badges fetch failed');
    }
  }
};
export const fetchQuestionSets = async (type, pageSize, pageIndex, search) => {
  // export const fetchQuestionSets = async ({ type, pageSize, pageIndex, search }) => {
  // add search in query
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_RNR_V2}/${CONSTANT.API_FORM}/get-form-template?type=${type}`,
      // `${CONSTANT.API_URL}/${CONSTANT.API_RNR_V2}/${CONSTANT.API_FORM}/get-form-template?search=${search}type=${type}&pageIndex=${pageIndex}&pageSize=${pageSize}`
    );
    const resultData = response?.data;
    return resultData;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! questions fetch failed');
    }
  }
};

export const fetchNominates = async (pageIndex, pageSize, name) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_RNR_V2}/${CONSTANT.API_NOMINATE}/search-nomination?pageIndex=${pageIndex}&pageSize=${pageSize}&name=${name}&targetName=${name}`,
    );
    const resultData = response?.data;
    return resultData;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! nominates fetch failed');
    }
  }
};
export const fetchProgramOwner = async (name, pageIndex, pageSize) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_RNR_V2}/${CONSTANT.API_PROGRAM}/search-program-owner?pageIndex=${pageIndex}&pageSize=${pageSize}&name=${name}`,
    );
    const resultData = response?.data;
    return resultData;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! program owner fetch failed');
    }
  }
};

export const fetchNewProgramWorkflow = async () => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_RNR_V2}/${CONSTANT.API_WORKFLOW}/get-workflow`,
    );
    const resultData = response?.data;
    return resultData;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! workflow fetch failed');
    }
  }
};
export const fetchProgramWorkflows = async (category, view) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_WORKFLOW_V2}/${
        CONSTANT.API_WORKFLOW
      }/get-all-workflows?${category ? `&category=${category}` : ''}${
        view ? `&view=${view}` : ''
      }`,
    );
    const resultData = response?.data;
    return resultData;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! workflow fetch failed');
    }
  }
};
export const fetchNewProgramWalletTypes = async () => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_RNR_V2}/${CONSTANT.API_WALLET}/get-all-wallet-type`,
    );
    const resultData = response?.data;
    return resultData;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! wallet type fetch failed');
    }
  }
};
export const fetchInputFields = async () => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_UTILITY_V3}/master/employee-fields`,
    );
    const resultData = response?.data;
    return resultData;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! input field fetch failed');
    }
  }
};
export const fetchBudgetAllocationType = async () => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_RNR_V2}/${CONSTANT.API_BUDGET}/get-budget-allocation-type`,
    );
    const resultData = response?.data;
    return resultData;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! allocation type fetch failed');
    }
  }
};
export const getFieldValues = async (fieldId) => {
  console.log(fieldId);
  try {
    const res = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_UTILITY_V3}/master/employee-field-dropdown/${fieldId}`,
    );
    return res;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! could not get field values');
    }
  }
};
export const getEmployeeCount = async (data) => {
  try {
    const res = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_RNR_V2}/${CONSTANT.API_BUDGET}/get-employee-count`,
      data,
    );
    return res;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! could not get employee count');
    }
  }
};
export const fetchNewProgramECards = async (type) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_RNR_V2}/${CONSTANT.API_CARD}/search-cards?pageIndex=0&pageSize=10&name=&type=${type}`,
    );
    const resultData = response?.data;
    return resultData;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! e-cards fetch failed');
    }
  }
};
export const fetchNewProgramCertificates = async () => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_RNR_V2}/${CONSTANT.API_CERTIFICATE}/get-all-certificates-template`,
    );
    const resultData = response?.data;
    return resultData;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! certificates fetch failed');
    }
  }
};
export const getProgram = async (id) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_RNR_V2}/${CONSTANT.API_PROGRAM}/get-program-by-id/${id}`,
    );
    const resultData = response?.data;
    return resultData;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! program fetch failed');
    }
  }
};
export const fetchInputFieldById = async (id) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_UTILITY_V3}/master/employee-fields-by-id/${id}`,
    );
    const resultData = response?.data;
    return resultData;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! field value fetch failed');
    }
  }
};
export const fetchFieldValue = async (id) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_UTILITY_V3}/master/employee-field-dropdown-by-id/${id}`,
    );
    const resultData = response?.data;
    return resultData;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! field value fetch failed');
    }
  }
};
export const getAward = async (id) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_RNR_V2}/${CONSTANT.API_AWARD}/get-awards-by-program/${id}`,
    );
    const resultData = response?.data;
    return resultData;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! award fetch failed');
    }
  }
};

export const getPrograms = async (data) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_RNR_V2}/${CONSTANT.API_PROGRAM}/get-all-programs`,
      data,
    );
    const resultData = response;
    return resultData;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! programs fetch failed');
    }
  }
};

export const editProgram = async (payload) => {
  try {
    const res = await patch(
      `${CONSTANT.API_URL}/${CONSTANT.API_RNR_V2}/${CONSTANT.API_PROGRAM}/update-program`,
      payload,
    );
    return res;
  } catch (err) {
    throw new Error(err?.response?.data?.message);
  }
};
export const editBudget = async (payload) => {
  try {
    const res = await patch(
      `${CONSTANT.API_URL}/${CONSTANT.API_RNR_V2}/${CONSTANT.API_BUDGET}/update-budget`,
      payload,
    );

    return res;
  } catch (err) {
    if (err) {
      throw new AxiosError(err?.response?.data?.message ?? err?.message);
    } else {
      throw new Error('Error! could not update budget');
    }
  }
};
export const editAward = async (payload) => {
  try {
    const res = await patch(
      `${CONSTANT.API_URL}/${CONSTANT.API_RNR_V2}/${CONSTANT.API_AWARD}/update-award`,
      payload,
    );

    return res;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! could not update award');
    }
  }
};
export const deleteAward = async (payload) => {
  try {
    const res = await del(
      `${CONSTANT.API_URL}/${CONSTANT.API_RNR_V2}/${CONSTANT.API_AWARD}/delete-award`,
      payload,
    );

    return res;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! could not delete award');
    }
  }
};

export const deleteProgram = async (id) => {};

export const duplicateProgram = async (payload) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_RNR_V2}/${CONSTANT.API_PROGRAM}/duplicate-program`,
      payload,
    );
    const resultData = response;
    return resultData;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! program duplication failed');
    }
  }
};

export const getProgramMetaData = async () => {};
