export default function NoCommunityIcon() {
  return (
    <div>
      <svg
        width="200"
        height="152"
        viewBox="0 0 200 152"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M165.143 0H34.8571C28.8609 0 24 4.86091 24 10.8571V141.143C24 147.139 28.8609 152 34.8571 152H165.143C171.139 152 176 147.139 176 141.143V10.8571C176 4.86091 171.139 0 165.143 0Z"
          fill="#EAECF0"
        />
        <g filter="url(#filter0_dd_13437_59247)">
          <path
            d="M54 11.25H189C191.623 11.25 193.75 13.3766 193.75 16V41C193.75 43.6234 191.623 45.75 189 45.75H54C51.3766 45.75 49.25 43.6234 49.25 41V16C49.25 13.3766 51.3766 11.25 54 11.25Z"
            fill="white"
            stroke="#F9F9F9"
            strokeWidth="0.5"
          />
        </g>
        <path
          d="M116 19H90C88.3431 19 87 20.3431 87 22C87 23.6569 88.3431 25 90 25H116C117.657 25 119 23.6569 119 22C119 20.3431 117.657 19 116 19Z"
          fill="#D0D5DD"
        />
        <path
          d="M134 32H90C88.3431 32 87 33.3431 87 35C87 36.6569 88.3431 38 90 38H134C135.657 38 137 36.6569 137 35C137 33.3431 135.657 32 134 32Z"
          fill="#EAECF0"
        />
        <path
          d="M76.3793 18H62.6207C60.621 18 59 19.621 59 21.6207V35.3793C59 37.379 60.621 39 62.6207 39H76.3793C78.379 39 80 37.379 80 35.3793V21.6207C80 19.621 78.379 18 76.3793 18Z"
          fill="#D0D5DD"
        />
        <g filter="url(#filter1_dd_13437_59247)">
          <path
            d="M11 54.25H146C148.623 54.25 150.75 56.3766 150.75 59V136C150.75 138.623 148.623 140.75 146 140.75H11C8.37665 140.75 6.25 138.623 6.25 136V59C6.25 56.3766 8.37665 54.25 11 54.25Z"
            fill="white"
            stroke="#F9F9F9"
            strokeWidth="0.5"
          />
        </g>
        <path
          d="M73 62H47C45.3431 62 44 63.3431 44 65C44 66.6569 45.3431 68 47 68H73C74.6569 68 76 66.6569 76 65C76 63.3431 74.6569 62 73 62Z"
          fill="#D0D5DD"
        />
        <path
          d="M91 75H47C45.3431 75 44 76.3431 44 78C44 79.6569 45.3431 81 47 81H91C92.6569 81 94 79.6569 94 78C94 76.3431 92.6569 75 91 75Z"
          fill="#EAECF0"
        />
        <path
          d="M33.3793 61H19.6207C17.621 61 16 62.621 16 64.6207V78.3793C16 80.379 17.621 82 19.6207 82H33.3793C35.379 82 37 80.379 37 78.3793V64.6207C37 62.621 35.379 61 33.3793 61Z"
          fill="#D0D5DD"
        />
        <path
          d="M73 89H19C17.3431 89 16 90.3431 16 92C16 93.6569 17.3431 95 19 95H73C74.6569 95 76 93.6569 76 92C76 90.3431 74.6569 89 73 89Z"
          fill="#D0D5DD"
        />
        <path
          d="M91 102H19C17.3431 102 16 103.343 16 105C16 106.657 17.3431 108 19 108H91C92.6569 108 94 106.657 94 105C94 103.343 92.6569 102 91 102Z"
          fill="#EAECF0"
        />
        <path
          d="M73 116H19C17.3431 116 16 117.343 16 119C16 120.657 17.3431 122 19 122H73C74.6569 122 76 120.657 76 119C76 117.343 74.6569 116 73 116Z"
          fill="#D0D5DD"
        />
        <path
          d="M91 129H19C17.3431 129 16 130.343 16 132C16 133.657 17.3431 135 19 135H91C92.6569 135 94 133.657 94 132C94 130.343 92.6569 129 91 129Z"
          fill="#EAECF0"
        />
        <defs>
          <filter
            id="filter0_dd_13437_59247"
            x="43"
            y="9"
            width="157"
            height="47"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feMorphology
              radius="2"
              operator="erode"
              in="SourceAlpha"
              result="effect1_dropShadow_13437_59247"
            />
            <feOffset dy="2" />
            <feGaussianBlur stdDeviation="2" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.06 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_13437_59247"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feMorphology
              radius="2"
              operator="erode"
              in="SourceAlpha"
              result="effect2_dropShadow_13437_59247"
            />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="4" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.1 0"
            />
            <feBlend
              mode="normal"
              in2="effect1_dropShadow_13437_59247"
              result="effect2_dropShadow_13437_59247"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect2_dropShadow_13437_59247"
              result="shape"
            />
          </filter>
          <filter
            id="filter1_dd_13437_59247"
            x="0"
            y="52"
            width="157"
            height="99"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feMorphology
              radius="2"
              operator="erode"
              in="SourceAlpha"
              result="effect1_dropShadow_13437_59247"
            />
            <feOffset dy="2" />
            <feGaussianBlur stdDeviation="2" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.06 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_13437_59247"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feMorphology
              radius="2"
              operator="erode"
              in="SourceAlpha"
              result="effect2_dropShadow_13437_59247"
            />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="4" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.1 0"
            />
            <feBlend
              mode="normal"
              in2="effect1_dropShadow_13437_59247"
              result="effect2_dropShadow_13437_59247"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect2_dropShadow_13437_59247"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    </div>
  );
}
