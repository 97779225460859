import {
  fetchInAppUnreadNotificationAPI,
  updateInAppUserNotificationAPI,
} from 'apiClient/notification/notification';
import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import NotificationItem from './NotificationItem';

const NotificationPopUp = ({ setOpenNotification, openNotification }) => {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const response = await fetchInAppUnreadNotificationAPI();
        // if (!response.length) {
        // const responseRead = await fetchInAppReadNotificationAPI();
        setNotifications(response.notifications);
        // } else {
        //   setNotifications(response.notifications);
        // }
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
        setNotifications([]);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        if (openNotification) {
          const notificationIds = notifications.map(
            (notification) => notification.id,
          );
          const response = await updateInAppUserNotificationAPI(
            notificationIds,
          );
        }
      } catch (error) {
        console.error(error);
      }
    })();
  }, [openNotification, notifications]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setOpenNotification(false);
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return (
    <div
      className="flex flex-col py-6 px-0 items-start absolute top-[120%] right-0 bg-gray-50 w-[300px] h-[400px] text-center border-2 rounded-lg z-10"
      style={{
        boxShadow:
          '0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)',
      }}
      ref={ref}
    >
      <div className="flex flex-col items-center py-0 px-4 gap-4 w-full top-6">
        <div className="flex flex-row justify-between items-start w-full">
          <span className="font-bold text-left w-3/4">Notifications</span>
          <Link to="/notifications" onClick={() => setOpenNotification(false)}>
            <span className="w-1/4 text-transparent font-semibold text-xs bg-clip-text bg-gradient-to-r from-[var(--primaryLeft)] to-[var(--primaryRight)] underline">
              See all
            </span>
          </Link>
        </div>
      </div>
      {notifications.length && !loading ? (
        <div className="flex flex-col items-center overflow-y-scroll custom-scrollbar">
          {notifications.map((notification) => {
            return (
              <NotificationItem
                setOpenNotification={setOpenNotification}
                notification={notification}
                key={notification.id}
              />
            );
          })}
        </div>
      ) : (
        <>
          {!loading && !notifications.length ? (
            <p className="font-semibold text sm text-center w-full text-gray-400 py-6">
              No notification..
            </p>
          ) : (
            <></>
          )}
        </>
      )}
      {loading && (
        <div className="space-y-2 p-2 mt-4 w-full">
          <div className="animate-pulse bg-slate-200 rounded-xl h-7"></div>
          <div className="animate-pulse bg-slate-200 rounded-xl h-7"></div>
          <div className="animate-pulse bg-slate-200 rounded-xl h-7"></div>
        </div>
      )}
    </div>
  );
};

export default NotificationPopUp;
