import Button from 'components/atoms/button/Button';
import { Input } from 'components/atoms/FormElements/input/Input';
import ModalDialogue from 'components/atoms/modalDialogue/ModalDialogue';
import TableSkeleton from 'components/atoms/skeletonLoaderTypes/TableSkeleton';
import { useGetValidatedData } from 'query/dashboard/ManageDashboard';
import React from 'react';

const ValidateModal = ({
  isValidateSelected,
  setIsValidateSelected,
  dashboardId,
  previewDataId,
  setIsPreviewSelected,
}) => {
  const {
    data: validatedData,
    isLoading,
    isError,
  } = useGetValidatedData(previewDataId, dashboardId);

  const columnNames = Object.values(validatedData?.csvData[0]?.data || {});

  const rows = validatedData?.csvData?.slice(1).map((row) => {
    const rowData = row?.data;
    return Object.keys(rowData).reduce((acc, key, index) => {
      acc[columnNames[index]] = rowData[key];
      return acc;
    }, {});
  });

  return (
    <ModalDialogue
      isModalOpen={isValidateSelected}
      title={
        <span className="text-base text-gray-900 font-semibold">
          Upload Data
        </span>
      }
      onClose={() => setIsValidateSelected(false)}
      width="70%"
    >
      {isLoading ? (
        <TableSkeleton />
      ) : (
        <div className="flex flex-col gap-6">
          <div className="flex flex-col w-1/2">
            <Input label="Data Name" value={validatedData?.tableName} />
          </div>
          <hr className="w-full h-1"></hr>
          <div className="flex flex-col gap-2 -mt-2">
            <span className="text-gray-900 text-xs font-medium leading-7">
              Uploaded Data Preview
            </span>
            <div className="h-72 overflow-auto">
              <table className="w-full">
                <thead className=" bg-gray-50">
                  <tr>
                    {columnNames?.map((item, index) => (
                      <th
                        key={index}
                        className="text-gray-600 font-figtree text-xs font-medium py-2 px-4 border border-l-0 border-r-0 border-gray-300 text-left"
                      >
                        {item}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {rows?.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                      {columnNames?.map((colName, colIndex) => (
                        <td
                          key={colIndex}
                          className="text-gray-900 font-figtree text-sm-13 font-normal py-2 px-4 border border-r-0 border-gray-300  border-l-0"
                        >
                          {row[colName]}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="flex items-center justify-end gap-2">
            <Button onClick={() => setIsValidateSelected(false)}>Cancel</Button>
            <Button
              variant="filled"
              onClick={() => {
                setIsValidateSelected(false);
                setIsPreviewSelected(false);
              }}
            >
              Submit
            </Button>
          </div>
        </div>
      )}
    </ModalDialogue>
  );
};

export default ValidateModal;
