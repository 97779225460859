import { useAuth0 } from '@auth0/auth0-react';
import InfinityLoader from 'assets/svg/InfinityLoader/InfinityLoader';
import Button from 'components/atoms/button/Button';
import { useEffect, useState } from 'react';
import { pdfjs } from 'react-pdf';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  getOrgSideNav,
  getUserAccess,
} from './apiClient/orgDetails/orgDetails';
import { fetchEmployeeByAuth } from './apiClient/userByAuth/userByAuth';
import Toast from './components/atoms/toast/Toast';
import ThemeProvider from './layouts/Layout';
import DefaultHome from './pages/admin/homePage/HomePage';
import NotFound from './pages/genericPages/pageNotFound/PageNotFound';
import routes from './routes';

import {
  checkModuleAccess,
  checkRoleAccess,
  isHome,
  isSurface,
} from 'utils/accessControl';
import {
  setOrgDetials,
  setSideNav,
  updateUserAccess,
} from './store/reducers/themeSlice';
import {
  setToken,
  setUserAuthentication,
  setUserData,
  setUserProfileData,
} from './store/reducers/userSlice';
import ReviewOffer from 'pages/hiringCalculator/reviewOffer/ReviewOffer';
// import ProgressBarLoader from 'components/atoms/progressBar/ProgressBarLoader';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

function App({ orgDetails }) {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user);
  const navigate = useNavigate();
  const {
    isAuthenticated,
    user,
    getAccessTokenSilently,
    getIdTokenClaims,
    loginWithRedirect,
    isLoading,
    error,
    logout,
  } = useAuth0();

  const [isError, setError] = useState(false);
  const [isFirstLoad, setIsFirstLoad] = useState(true); // for not showing infinity loader on token refresh
  const modules = useSelector((state) => state.theme?.rbac);
  const userAccess = useSelector((state) => state.theme?.userAccess);
  const [progress, setProgress] = useState(0);
  const [tokens, setTokens] = useState({ xAccess: null, xIdAccess: null });
  const [isVisible, setIsVisible] = useState(true);

  const getRoutes = (allRoutes) => {
    const routes = allRoutes.map((route, index) => {
      if (
        checkModuleAccess(modules, route?.module) ||
        (route?.route === '/surface' && isSurface(modules)) ||
        (route?.route === '/home' && isHome(modules))
      ) {
        if (
          route?.flagName &&
          !checkRoleAccess(userAccess, route?.module, route.flagName)
        ) {
          return null;
        } else
          return (
            <>
              <Route
                exact
                path={route?.route}
                element={route.component}
                key={route.key}
              />
            </>
          );
      } else
        return (
          <>
            <Route path={route?.route} element={<Navigate to="/not-found" />} />
          </>
        );
    });

    //   // conditionally rendering routes based on wether surface module have access or not
    //   // if (checkModuleAccess(modules, 'Surface')) {
    //   //   routes.push(<Route path="/" element={<Navigate to="/surface" />} />);
    //   // } else routes.push(<Route path="/" element={<AlternateScreen />} />);
    return routes;
  };

  //   const getRoutes = (allRoutes) => {
  //     return allRoutes.map((route) => {
  //       return (
  //         <Route
  //           exact
  //           path={route?.route}
  //           element={route.component}
  //           key={route.key}
  //         />
  //       );
  //     });
  //   };

  const fetchTokens = async () => {
    await getAccessTokenSilently()
      .then((token) => {
        //    console.log('Fetching tokens');
        if (token.length > 0) {
          localStorage.setItem('accessToken', token);
          setTokens({ ...tokens, xAccess: token });
          dispatch(setToken(token));
        }
      })
      .catch((err) => {
        loginWithRedirect({
          appState: {
            returnTo: `${window.location.origin}`,
          },
        });
        console.log(err);
      });
    await getIdTokenClaims()
      .then((claims) => {
        //console.log('Fetching tokens id');
        if (claims.__raw.length > 0) {
          setTokens({ ...tokens, xIdAccess: claims.__raw });
          localStorage.setItem('idAccessToken', claims.__raw);
        }
      })
      .catch((err) => {
        console.log('Something went wrong', err);
      });
  };
  const fetchData = async () => {
    if (tokens?.xAccess || tokens?.xIdAccess) {
      try {
        setError(false);
        // Fetch organization side nav
        setProgress(25);
        const orgSideNav = await getOrgSideNav();
        setProgress(50);
        dispatch(setSideNav(orgSideNav));

        // Fetch employee data
        // const employeeData = await fetchEmployeeByAuth(user?.sub);
        // const storedEmployeeDetails = JSON.parse(localStorage.getItem("employeeDetails"));

        // if (!storedEmployeeDetails) {
        const employeeData = await fetchEmployeeByAuth(user?.sub);
        //   localStorage.setItem("employeeDetails", JSON.stringify(employeeData));
        // } else {
        //   employeeData = storedEmployeeDetails;
        // }
        setProgress(80);
        dispatch(setUserProfileData(employeeData));

        // Fetch user access
        const userAccess = await getUserAccess();
        setProgress(95);
        dispatch(updateUserAccess(userAccess));

        setProgress(99);
        //  await new Promise((resolve) => setTimeout(resolve, 2000));
        setProgress(100);
      } catch (error) {
        if (error.message.includes('401')) {
          logout({
            logoutParams: {
              returnTo: window.location.origin,
            },
          });
        }
        setError(true);
        setProgress(100);
        console.error('Error fetching data:', error);
      }
    } else {
      fetchTokens();
    }
  };
  //   const setupWebSocketConnection = () => {
  //     var xid = localStorage.getItem('idAccessToken');
  //     var access = localStorage.getItem('accessToken');
  //     const socket = new WebSocket(
  //       `wss://dev2-api.talentmonk.com/ws?xat=${access}&xid=${xid}`,
  //     );

  //     socket.onopen = () => {
  //       console.log('WebSocket connection established');
  //       // Perform any necessary actions after the connection is established
  //     };
  //     const reconnect = () => {
  //       setupWebSocketConnection(); // Attempt to reconnect
  //     };
  //     socket.onmessage = (event) => {
  //       console.log('Received message:', event.data);
  //       // Handle the received message
  //     };

  //     socket.onclose = () => {
  //       console.log('WebSocket connection closed');
  //       reconnect();
  //       // Perform any necessary actions after the connection is closed
  //     };

  //     socket.onerror = (error) => {
  //       console.error('WebSocket error:', error);
  //       // Handle the error
  //     };
  //   };

  useEffect(() => {
    const interval = setInterval(() => {
      fetchTokens();
    }, 45 * 60 * 1000); // 45 minutes timeout
    return () => clearInterval(interval);
  }, []);
  useEffect(() => {
    if (isAuthenticated) {
      setIsFirstLoad(false);
    }
  }, [isAuthenticated]);
  useEffect(() => {
    if (
      !isAuthenticated &&
      !isLoading &&
      !isError &&
      !error &&
      !window?.location?.pathname.includes('/candidate/review-offer')
    ) {
      const pathWithParams = window.location.pathname + window.location.search;
      localStorage.setItem('redirectRoute', pathWithParams);

      // localStorage.setItem('redirectRoute', window.location.href);
      //   loginWithRedirect({
      //     appState: {
      //       returnTo: `${window.location.origin}`,
      //     },
      //   });

      loginWithRedirect({ appState: { targetUrl: window.location.pathname } });
    }
  }, [error, isAuthenticated, isError, isLoading, loginWithRedirect]);
  useEffect(() => {
    if (window?.location?.pathname.includes('/candidate/review-offer')) {
      setProgress(100);
      setError(false);
    }
  }, [window?.location?.pathname]);

  useEffect(() => {
    if (orgDetails) {
      dispatch(setOrgDetials(orgDetails));
    }
    if (isAuthenticated) {
      const storedRoute = localStorage.getItem('redirectRoute');
      const redirectPath = storedRoute;
      localStorage.removeItem('redirectRoute');
      if (!window?.location?.pathname.includes('/candidate/review-offer')) {
        fetchData();
      }
      if (redirectPath) {
        // navigate(redirectPath);
      }
    }
    // setupWebSocketConnection();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, tokens.xIdAccess]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsVisible(!document.hidden);
    };
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  // Call the function to setup the WebSocket connection

  useEffect(() => {
    if (isVisible && !isFirstLoad) {
      fetchTokens();
    }
  }, [isVisible]);

  useEffect(() => {
    dispatch(setUserAuthentication(isAuthenticated));
    dispatch(setUserData(JSON.parse(localStorage.getItem('user'))));
  }, [dispatch, isAuthenticated, user]);

  if (isLoading || progress < 100) {
    return (
      <div className="flex te flex-col justify-center items-center h-screen w-full">
        <div className="w-64">
          {/* <p className="text-md"> Loading...</p> */}
          <InfinityLoader />
          {/* <ProgressBarLoader percentage={progress} /> */}
        </div>
      </div>
    );
  }
  if (isError || error) {
    return (
      <div className="flex te flex-col justify-center  items-center h-screen w-full">
        <p className="mb-4">Ops! Something went wrong.</p>
        <Button
          onClick={() => window.location.reload()}
          variant="outlined"
          className=""
        >
          Retry Now
        </Button>
      </div>
    );
  }
  if (window?.location?.pathname.includes('/candidate/review-offer')) {
    return (
      <>
        <ToastContainer
          position="top-right"
          hideProgressBar={true}
          pauseOnHover={true}
          draggable={false}
          newestOnTop={false}
          closeButton={false}
          style={{ zIndex: '1000' }}
          toastStyle={{ top: '60px', right: '10px' }}
        />
        <Routes>
          <Route path="/candidate/review-offer" element={<ReviewOffer />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </>
    );
  }

  if (isAuthenticated && !isLoading && !isError && !error) {
    return (
      <>
        <div className="">
          <Toast />
          <ToastContainer
            position="top-right"
            hideProgressBar={true}
            pauseOnHover={true}
            draggable={false}
            newestOnTop={false}
            closeButton={false}
            style={{ zIndex: '1000' }}
            toastStyle={{ top: '60px', right: '10px' }}
          />
          {/* {isAuthenticated ? ( */}
          <ThemeProvider bgColor="">
            <Routes>
              {getRoutes(routes)}
              <Route
                path="/"
                element={
                  <Navigate to={`${isHome(modules) ? '/home' : '/surface'}`} />
                }
              />
              <Route path="*" element={<NotFound />} />
              <Route component={<NotFound />} />
              <Route
                path="/redeem/my-recognitions"
                element={
                  <Navigate
                    to={`/profile/${userData?.userProfileData?.userId?.value}/Recognition`}
                  />
                }
              />
              <Route path="/*" element={<Navigate to="/not-found" />} />
              <Route
                path="/compensation/my-compensation"
                element={
                  <Navigate
                    to={`/profile/${userData?.userProfileData?.userId?.value}/Compensation?subTab=Total Rewards Summary`}
                  />
                }
              />
              <Route
                path="/compensation/my-compensation/statements"
                element={
                  <Navigate
                    to={`/profile/${userData?.userProfileData?.userId?.value}/Compensation?subTab=Statements`}
                  />
                }
              />
              <Route
                path="/compensation/my-compensation/forecast-and-simulation"
                element={
                  <Navigate
                    to={`/profile/${userData?.userProfileData?.userId?.value}/Compensation?subTab=Forecast and Simulation`}
                  />
                }
              />
              <Route
                path="/compensation/my-compensation/off-cycle-reward-decisions"
                element={
                  <Navigate
                    to={`/profile/${userData?.userProfileData?.userId?.value}/Compensation?subTab=Off-Cycle Reward Decisions`}
                  />
                }
              />
              <Route
                path="/compensation/my-equity"
                element={
                  <Navigate
                    to={`/profile/${userData?.userProfileData?.userId?.value}/ESOP`}
                  />
                }
              />
              <Route
                path="/insurance/dashboard"
                element={<Navigate to={`/insurance/manage-insurance`} />}
              />
              <Route
                path="/insurance/health-insurance"
                element={<Navigate to={`/insurance/enroll-insurance`} />}
              />
              <Route
                path="/insurance/accidental"
                element={<Navigate to={`/insurance/enroll-insurance`} />}
              />{' '}
              <Route
                path="/insurance/life-insurance"
                element={<Navigate to={`/insurance/enroll-insurance`} />}
              />
            </Routes>
          </ThemeProvider>
          {/* // ) : (
          //   <div className="bg-gray-100 h-full">
          //     <Routes>
          //       {/* <Route path="/signin" element={<DefaultHome />} />
          //       <Route path="/" element={<DefaultHome />} />
          //       {/* <Route path="/manage-program" element={<ManagePrograms />} /> */}

          {/* <Route path="/*" element={<DefaultHome />} />
          //       <Route path="/*" element={<Navigate to="/not-found" />} />
          //     </Routes>
          //   </div>
          // )} */}
        </div>
      </>
    );
  }
  if (!isAuthenticated && !isLoading) {
    return <DefaultHome />;
  }
}

export default App;
