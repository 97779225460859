import React from 'react';

function GradientFileIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M11.6668 1.89111V5.33323C11.6668 5.79994 11.6668 6.0333 11.7577 6.21156C11.8376 6.36836 11.965 6.49584 12.1218 6.57574C12.3001 6.66656 12.5335 6.66656 13.0002 6.66656H16.4423M13.3335 10.8332H6.66683M13.3335 14.1665H6.66683M8.3335 7.49984H6.66683M11.6668 1.6665H7.3335C5.93336 1.6665 5.2333 1.6665 4.69852 1.93899C4.22811 2.17867 3.84566 2.56112 3.60598 3.03153C3.3335 3.56631 3.3335 4.26637 3.3335 5.6665V14.3332C3.3335 15.7333 3.3335 16.4334 3.60598 16.9681C3.84566 17.4386 4.22811 17.821 4.69852 18.0607C5.2333 18.3332 5.93336 18.3332 7.3335 18.3332H12.6668C14.067 18.3332 14.767 18.3332 15.3018 18.0607C15.7722 17.821 16.1547 17.4386 16.3943 16.9681C16.6668 16.4334 16.6668 15.7333 16.6668 14.3332V6.6665L11.6668 1.6665Z"
        stroke="url(#paint0_linear_1555_7978)"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1555_7978"
          x1="3.3335"
          y1="18.3332"
          x2="16.6668"
          y2="18.3332"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="var(--primaryLeft)" />
          <stop offset="1" stopColor="var(--primaryRight)" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default GradientFileIcon;
