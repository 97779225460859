import PropertyInput from 'components/atoms/propertyInput/PropertyInput';
import React from 'react';
import { Controller } from 'react-hook-form';

const SpacerPropertiesSidePanel = (props) => {
  const { control, path } = props;
  return (
    <div>
      <Controller
        control={control}
        name={`${path}.spacer.height`}
        render={({ field }) => {
          return (
            <PropertyInput
              type={'number'}
              label={'Height'}
              className={'mb-2'}
              {...field}
            />
          );
        }}
      />
    </div>
  );
};

export default SpacerPropertiesSidePanel;
