import * as React from 'react';

export default function BasicCard(props) {
  return (
    <div
      className={` w-full h-auto rounded-xl p-4 mb-6 shadow-md border border-gray-200`}
    >
      <div className="flex justify-between mb-3">
        <div className="font-semibold text-lg">{props.cardTitle}</div>
        <div
          onClick={() => {
            props.onClickCTA();
          }}
          className="hover:cursor-pointer font-semibold text-sm text-gray-500"
        >
          {props.ctaText}
        </div>
      </div>
      {props.children}
    </div>
  );
}
