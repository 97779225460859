export const NoRoleIcon = (props) => {
  return (
    <svg
      width="242"
      height="152"
      viewBox="0 0 242 152"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M186.143 0H55.8571C49.8609 0 45 4.86091 45 10.8571V141.143C45 147.139 49.8609 152 55.8571 152H186.143C192.139 152 197 147.139 197 141.143V10.8571C197 4.86091 192.139 0 186.143 0Z"
        fill="#EAECF0"
      />
      <g filter="url(#filter0_dd_9_7252)">
        <rect x="69.25" y="17" width="168" height="24" rx="4" fill="white" />
        <rect
          x="69.25"
          y="17"
          width="168"
          height="24"
          rx="4"
          stroke="#F9F9F9"
          strokeWidth="0.7"
        />
        <rect x="77.25" y="25" width="48" height="8" rx="2.5" fill="#D0D5DD" />
        <rect x="133.25" y="25" width="40" height="8" rx="2.5" fill="#EAECF0" />
        <rect x="181.25" y="25" width="48" height="8" rx="2.5" fill="#EAECF0" />
      </g>
      <g filter="url(#filter1_dd_9_7252)">
        <rect x="17" y="48" width="168" height="24" rx="4" fill="white" />
        <rect
          x="17"
          y="48"
          width="168"
          height="24"
          rx="4"
          stroke="#F9F9F9"
          strokeWidth="0.7"
        />
        <rect x="25" y="56" width="48" height="8" rx="2.5" fill="#D0D5DD" />
        <rect x="81" y="56" width="40" height="8" rx="2.5" fill="#EAECF0" />
        <rect x="129" y="56" width="48" height="8" rx="2.5" fill="#EAECF0" />
      </g>
      <g filter="url(#filter2_dd_9_7252)">
        <rect x="4.75" y="110" width="168" height="24" rx="4" fill="white" />
        <rect
          x="4.75"
          y="110"
          width="168"
          height="24"
          rx="4"
          stroke="#F9F9F9"
          strokeWidth="0.7"
        />
        <rect x="12.75" y="118" width="48" height="8" rx="2.5" fill="#D0D5DD" />
        <rect x="68.75" y="118" width="40" height="8" rx="2.5" fill="#EAECF0" />
        <rect
          x="116.75"
          y="118"
          width="48"
          height="8"
          rx="2.5"
          fill="#EAECF0"
        />
      </g>
      <g filter="url(#filter3_dd_9_7252)">
        <rect x="69.25" y="79" width="168" height="24" rx="4" fill="white" />
        <rect
          x="69.25"
          y="79"
          width="168"
          height="24"
          rx="4"
          stroke="#F9F9F9"
          strokeWidth="0.7"
        />
        <rect x="77.25" y="87" width="48" height="8" rx="2.5" fill="#D0D5DD" />
        <rect x="133.25" y="87" width="40" height="8" rx="2.5" fill="#EAECF0" />
        <rect x="181.25" y="87" width="48" height="8" rx="2.5" fill="#EAECF0" />
      </g>
      <defs>
        <filter
          id="filter0_dd_9_7252"
          x="64.7673"
          y="15.2723"
          width="176.965"
          height="32.9657"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="1.37759"
            operator="erode"
            in="SourceAlpha"
            result="effect1_dropShadow_9_7252"
          />
          <feOffset dy="1.37759" />
          <feGaussianBlur stdDeviation="1.37759" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.06 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_9_7252"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="1.37759"
            operator="erode"
            in="SourceAlpha"
            result="effect2_dropShadow_9_7252"
          />
          <feOffset dy="2.75517" />
          <feGaussianBlur stdDeviation="2.75517" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_9_7252"
            result="effect2_dropShadow_9_7252"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_9_7252"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_dd_9_7252"
          x="12.5173"
          y="46.2723"
          width="176.965"
          height="32.9657"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="1.37759"
            operator="erode"
            in="SourceAlpha"
            result="effect1_dropShadow_9_7252"
          />
          <feOffset dy="1.37759" />
          <feGaussianBlur stdDeviation="1.37759" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.06 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_9_7252"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="1.37759"
            operator="erode"
            in="SourceAlpha"
            result="effect2_dropShadow_9_7252"
          />
          <feOffset dy="2.75517" />
          <feGaussianBlur stdDeviation="2.75517" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_9_7252"
            result="effect2_dropShadow_9_7252"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_9_7252"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_dd_9_7252"
          x="0.267266"
          y="108.272"
          width="176.965"
          height="32.9657"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="1.37759"
            operator="erode"
            in="SourceAlpha"
            result="effect1_dropShadow_9_7252"
          />
          <feOffset dy="1.37759" />
          <feGaussianBlur stdDeviation="1.37759" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.06 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_9_7252"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="1.37759"
            operator="erode"
            in="SourceAlpha"
            result="effect2_dropShadow_9_7252"
          />
          <feOffset dy="2.75517" />
          <feGaussianBlur stdDeviation="2.75517" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_9_7252"
            result="effect2_dropShadow_9_7252"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_9_7252"
            result="shape"
          />
        </filter>
        <filter
          id="filter3_dd_9_7252"
          x="64.7673"
          y="77.2723"
          width="176.965"
          height="32.9657"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="1.37759"
            operator="erode"
            in="SourceAlpha"
            result="effect1_dropShadow_9_7252"
          />
          <feOffset dy="1.37759" />
          <feGaussianBlur stdDeviation="1.37759" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.06 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_9_7252"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="1.37759"
            operator="erode"
            in="SourceAlpha"
            result="effect2_dropShadow_9_7252"
          />
          <feOffset dy="2.75517" />
          <feGaussianBlur stdDeviation="2.75517" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_9_7252"
            result="effect2_dropShadow_9_7252"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_9_7252"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
