const InsightsIcon = (props) => (
  <svg
    width={props?.width ? props?.width : '20'}
    height={props?.height ? props?.height : '20'}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1769_1845)">
      <path
        d="M8.33332 14.7156V16.6667C8.33332 17.5872 9.07952 18.3334 9.99999 18.3334C10.9205 18.3334 11.6667 17.5872 11.6667 16.6667V14.7156M9.99999 1.66675V2.50008M2.49999 10.0001H1.66666M4.58332 4.58341L4.08324 4.08333M15.4167 4.58341L15.9169 4.08333M18.3333 10.0001H17.5M15 10.0001C15 12.7615 12.7614 15.0001 9.99999 15.0001C7.23857 15.0001 4.99999 12.7615 4.99999 10.0001C4.99999 7.23866 7.23857 5.00008 9.99999 5.00008C12.7614 5.00008 15 7.23866 15 10.0001Z"
        stroke={props.isActive ? props.activeColor : '#475467'}
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1769_1845">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default InsightsIcon;
