import { get, post, put } from 'apiClient/apiCalls';
import * as CONSTANT from '../api.constant';

export const getFormMetaData = async () => {
  try {
    const apiResponse = await get(`${CONSTANT.RECOG_API}/form/get-all-forms`);
    const response = apiResponse.data;
    // const response = {
    //   id: "fb7ab38d-37da-42e6-8e4f-c34d1f9e3a10",
    //   name: "FormTemplate",
    //   tabs: {
    //     basic: {
    //       tabId: "645d398d-d5e7-4fbb-b35d-80b610e0d730",
    //       tabLabel: "Basic",
    //       order: 1,
    //       fields: [
    //         {
    //           id: "e54465fe-1af2-44e3-ad09-5a1f0d89efd8",
    //           label: "Description",
    //           placeholder: "Enter a description",
    //           order: "2",
    //           fieldType: "Textarea",
    //           key: "description",
    //         },
    //         {
    //           id: "15c1e399-3514-4bb4-ab0c-6963014b58c7",
    //           label: "Success Message Title",
    //           placeholder: "Thank you",
    //           order: "3",
    //           fieldType: "Textbox",
    //           key: "successTitle",
    //         },
    //         {
    //           id: "707748d5-a54e-4e59-b6a8-33e8d3c66866",
    //           label: "Success Message",
    //           placeholder: "Your Response has been submitted",
    //           order: "4",
    //           fieldType: "Textarea",
    //           key: "successMessage",
    //         },
    //         {
    //           id: "b502fcec-cd9a-434b-a990-ff6563dc2bf7",
    //           label: "Form Type",
    //           order: "1",
    //           fieldType: "Dropdown",
    //           value: "Insurance",
    //           key: "formType",
    //           options: [
    //             {
    //               id: "e6c91f8c-bfde-4cf9-8bc1-8f40d968de28",
    //               value: "Rewards and Recoginition",
    //             },
    //             {
    //               id: "3d211e3f-02a0-4c15-a63c-9ff7071c8ff5",
    //               value: "Insurance",
    //             },
    //             {
    //               id: "763b8371-7d6f-49a3-88e7-382a95c9d2a8",
    //               value: "Benifits",
    //             },
    //           ],
    //         },
    //       ],
    //     },
    //     advanced: {
    //       tabId: "645d398d-d5e7-4fbb-b35d-80b610e0d730",
    //       tabLabel: "Advanced",
    //       order: 2,
    //     },
    //     connections: {
    //       tabId: "645d398d-d5e7-4fbb-b35d-80b610e0d730",
    //       tabLabel: "Connections",
    //       order: 3,
    //     },
    //     versionHistory: {
    //       tabId: "645d398d-d5e7-4fbb-b35d-80b610e0d730",
    //       tabLabel: "Version History",
    //       order: 4,
    //     },
    //   },
    // };
    return response;
  } catch (err) {
    throw new Error(err);
  }
};

export const getFieldMetaData = async (type) => {
  try {
    // const responseShortAnswer = {
    //   id: "2323ccba-46ad-46ef-8d15-aae59bbf1323",
    //   type: "Short Answer",
    //   fieldLabel: "Short Answer",
    //   fieldValue: "Enter Field Value",
    //   order: "1",
    //   tabs: {
    //     settings: {
    //       tabLabel: "Settings",
    //       order: "1",
    //       properties: [
    //         {
    //           id: "b2f8ddbe-38f8-46e2-ac9e-ed0a34d8463a",
    //           label: "Help Text",
    //           placeholder: "Enter Help Text",
    //           order: "2",
    //           fieldType: "Textarea",
    //           key: "helpText",
    //         },
    //         // {
    //         //   id: "5b3c4756-0e58-4ba0-97ab-e5735c5331dd",
    //         //   label: "Mandatory",
    //         //   value: true,
    //         //   order: "1",
    //         //   fieldType: "Toggle",
    //         //   key: "mandatory",
    //         // },
    //       ],
    //     },
    //     validations: {
    //       tabLabel: "Validations",
    //       order: "2",
    //       properties: [],
    //     },
    //     permissions: {
    //       tabLabel: "Permissions",
    //       order: "3",
    //       properties: [],
    //     },
    //     formulas: {
    //       tabLabel: "Formulas",
    //       order: "4",
    //       properties: [],
    //     },
    //   },
    // };

    // const responseLargeAnswer = {
    //   id: "2323ccba-46ad-46ef-8d15-aae59bbf1323",
    //   type: "Long Answer",
    //   fieldLabel: "Long Answer",
    //   fieldValue: "Enter Field Value",
    //   order: "2",
    //   tabs: {
    //     settings: {
    //       tabLabel: "Settings",
    //       order: "1",
    //       properties: [
    //         {
    //           id: "b2f8ddbe-38f8-46e2-ac9e-ed0a34d8463a",
    //           label: "Help Text",
    //           placeholder: "Enter Help Text",
    //           order: "1",
    //           fieldType: "Textbox",
    //           key: "helpText",
    //         },
    //         {
    //           id: "b2f8ddbe-38f8-46e2-ac9e-ed0a34d8463a",
    //           label: "Enable Formatting",
    //           value: true,
    //           order: "2",
    //           fieldType: "Toggle",
    //           key: "enableFormatting",
    //         },
    //         // {
    //         //   id: "5b3c4756-0e58-4ba0-97ab-e5735c5331dd",
    //         //   label: "Mandatory",
    //         //   value: true,
    //         //   order: "1",
    //         //   fieldType: "Toggle",
    //         //   key: "mandatory",
    //         // },
    //       ],
    //     },
    //     validations: {
    //       tabLabel: "Validations",
    //       order: "2",
    //       properties: [],
    //     },
    //     permissions: {
    //       tabLabel: "Permissions",
    //       order: "3",
    //       properties: [],
    //     },
    //     formulas: {
    //       tabLabel: "Formulas",
    //       order: "4",
    //       properties: [],
    //     },
    //   },
    // };

    // const responseDropdown = {
    //   id: "2323ccba-46ad-46ef-8d15-aae59bbf1323",
    //   type: "Dropdown",
    //   fieldLabel: "Dropdown",
    //   fieldValue: "Enter Field Value",
    //   order: "3",
    //   tabs: {
    //     settings: {
    //       tabLabel: "Settings",
    //       order: "1",
    //       properties: [
    //         {
    //           id: "b2f8ddbe-38f8-46e2-ac9e-ed0a34d8463a",
    //           label: "Multi Choice",
    //           value: true,
    //           order: "3",
    //           fieldType: "Toggle",
    //           key: "multiChoice",
    //         },
    //         {
    //           id: "b2f8ddbe-38f8-46e2-ac9e-ed0a34d8463a",
    //           label: 'Add "other" as an option',
    //           value: true,
    //           order: "3",
    //           fieldType: "Toggle",
    //           key: "otherOption",
    //         },
    //         {
    //           id: "b2f8ddbe-38f8-46e2-ac9e-ed0a34d8463a",
    //           label: "Let user type an answer",
    //           value: true,
    //           order: "3",
    //           fieldType: "Toggle",
    //           key: "typeAnswer",
    //         },
    //         {
    //           id: "b2f8ddbe-38f8-46e2-ac9e-ed0a34d8463a",
    //           label: "Add User's response to the option list",
    //           value: true,
    //           order: "3",
    //           fieldType: "Toggle",
    //           key: "addOption",
    //         },
    //         {
    //           id: "b2f8ddbe-38f8-46e2-ac9e-ed0a34d8463a",
    //           label: "Data Source",
    //           placeholder: "Select data source",
    //           order: "2",
    //           fieldType: "Dropdown",
    //           key: "dataSource",
    //         },
    //         {
    //           id: "b2f8ddbe-38f8-46e2-ac9e-ed0a34d8463a",
    //           label: "Help Text",
    //           placeholder: "Enter Help Text",
    //           order: "1",
    //           fieldType: "Textbox",
    //           key: "helpText",
    //         },

    //         // {
    //         //   id: "5b3c4756-0e58-4ba0-97ab-e5735c5331dd",
    //         //   label: "Mandatory",
    //         //   value: true,
    //         //   order: "1",
    //         //   fieldType: "Toggle",
    //         //   key: "mandatory",
    //         // },
    //       ],
    //     },
    //     validations: {
    //       tabLabel: "Validations",
    //       order: "2",
    //       properties: [],
    //     },
    //     permissions: {
    //       tabLabel: "Permissions",
    //       order: "3",
    //       properties: [],
    //     },
    //     formulas: {
    //       tabLabel: "Formulas",
    //       order: "4",
    //       properties: [],
    //     },
    //   },
    // };

    // const responseNumber = {
    //   id: "2323ccba-46ad-46ef-8d15-aae59bbf1323",
    //   type: "Number",
    //   fieldLabel: "Number",
    //   fieldValue: "Enter Field Value",
    //   order: "3",
    //   tabs: {
    //     settings: {
    //       tabLabel: "Settings",
    //       order: "1",
    //       properties: [
    //         {
    //           id: "b2f8ddbe-38f8-46e2-ac9e-ed0a34d8463a",
    //           label: "Help Text",
    //           placeholder: "Enter Help Text",
    //           order: "1",
    //           fieldType: "Textbox",
    //           key: "helpText",
    //         },
    //         {
    //           id: "b2f8ddbe-38f8-46e2-ac9e-ed0a34d8463a",
    //           label: "No. of Decimals Points",
    //           placeholder: "Select no. of decimals point",
    //           order: "2",
    //           fieldType: "Dropdown",
    //           key: "noOfDeciamls",
    //           options: [
    //             {
    //               id: "e6c91f8c-bfde-4cf9-8bc1-8f40d968de28",
    //               value: "Max. 1 Places",
    //             },
    //             {
    //               id: "3d211e3f-02a0-4c15-a63c-9ff7071c8ff5",
    //               value: "Max. 2 Places",
    //             },
    //             {
    //               id: "763b8371-7d6f-49a3-88e7-382a95c9d2a8",
    //               value: "Max. 3 Places",
    //             },
    //             {
    //               id: "e6c91f8c-bfde-4cf9-8bc1-8f40d968de28",
    //               value: "Max. 4 Places",
    //             },
    //             {
    //               id: "3d211e3f-02a0-4c15-a63c-9ff7071c8ff5",
    //               value: "Max. 5 Places",
    //             },
    //             {
    //               id: "763b8371-7d6f-49a3-88e7-382a95c9d2a8",
    //               value: "Max. 6 Places",
    //             },
    //             {
    //               id: "e6c91f8c-bfde-4cf9-8bc1-8f40d968de28",
    //               value: "Max. 7 Places",
    //             },
    //             {
    //               id: "3d211e3f-02a0-4c15-a63c-9ff7071c8ff5",
    //               value: "Max. 8 Places",
    //             },
    //             {
    //               id: "763b8371-7d6f-49a3-88e7-382a95c9d2a8",
    //               value: "Max. 9 Places",
    //             },
    //             {
    //               id: "e6c91f8c-bfde-4cf9-8bc1-8f40d968de28",
    //               value: "Max. 10 Places",
    //             },
    //           ],
    //         },
    //         {
    //           id: "b2f8ddbe-38f8-46e2-ac9e-ed0a34d8463a",
    //           label: "Calculated Field",
    //           value: true,
    //           order: "3",
    //           fieldType: "Toggle",
    //           key: "calculatedField",
    //         },
    //         // {
    //         //   id: "5b3c4756-0e58-4ba0-97ab-e5735c5331dd",
    //         //   label: "Mandatory",
    //         //   value: true,
    //         //   order: "1",
    //         //   fieldType: "Toggle",
    //         //   key: "mandatory",
    //         // },
    //       ],
    //     },
    //     validations: {
    //       tabLabel: "Validations",
    //       order: "2",
    //       properties: [],
    //     },
    //     permissions: {
    //       tabLabel: "Permissions",
    //       order: "3",
    //       properties: [],
    //     },
    //     formulas: {
    //       tabLabel: "Formulas",
    //       order: "4",
    //       properties: [],
    //     },
    //   },
    // };

    // const resposneRating = {
    //   id: "2323ccba-46ad-46ef-8d15-aae59bbf1323",
    //   type: "Rating",
    //   fieldLabel: "Rating",
    //   fieldValue: "Enter Field Value",
    //   order: "3",
    //   tabs: {
    //     settings: {
    //       tabLabel: "Settings",
    //       order: "1",
    //       properties: [
    //         {
    //           id: "b2f8ddbe-38f8-46e2-ac9e-ed0a34d8463a",
    //           label: "Help Text",
    //           placeholder: "Enter Help Text",
    //           order: "1",
    //           fieldType: "Textbox",
    //           key: "helpText",
    //         },
    //         {
    //           id: "b2f8ddbe-38f8-46e2-ac9e-ed0a34d8463a",
    //           label: "Rating Type",
    //           placeholder: "Select rating type",
    //           order: "2",
    //           fieldType: "Dropdown",
    //           key: "ratingType",
    //         },
    //         {
    //           id: "b2f8ddbe-38f8-46e2-ac9e-ed0a34d8463a",
    //           label: "Levels",
    //           order: "3",
    //           fieldType: "Dropdown",
    //           key: "noOfLevels",
    //           options: [
    //             {
    //               id: "e6c91f8c-bfde-4cf9-8bc1-8f40d968de28",
    //               value: "1",
    //             },
    //             {
    //               id: "3d211e3f-02a0-4c15-a63c-9ff7071c8ff5",
    //               value: "2",
    //             },
    //             {
    //               id: "763b8371-7d6f-49a3-88e7-382a95c9d2a8",
    //               value: "3",
    //             },
    //             {
    //               id: "e6c91f8c-bfde-4cf9-8bc1-8f40d968de28",
    //               value: "4",
    //             },
    //             {
    //               id: "3d211e3f-02a0-4c15-a63c-9ff7071c8ff5",
    //               value: "5",
    //             },
    //             {
    //               id: "763b8371-7d6f-49a3-88e7-382a95c9d2a8",
    //               value: "6",
    //             },
    //             {
    //               id: "e6c91f8c-bfde-4cf9-8bc1-8f40d968de28",
    //               value: "7",
    //             },
    //             {
    //               id: "3d211e3f-02a0-4c15-a63c-9ff7071c8ff5",
    //               value: "8",
    //             },
    //             {
    //               id: "763b8371-7d6f-49a3-88e7-382a95c9d2a8",
    //               value: "9",
    //             },
    //             {
    //               id: "e6c91f8c-bfde-4cf9-8bc1-8f40d968de28",
    //               value: "10",
    //             },
    //           ],
    //         },
    //         // {
    //         //   id: "5b3c4756-0e58-4ba0-97ab-e5735c5331dd",
    //         //   label: "Mandatory",
    //         //   value: true,
    //         //   order: "1",
    //         //   fieldType: "Toggle",
    //         //   key: "mandatory",
    //         // },
    //       ],
    //     },
    //     validations: {
    //       tabLabel: "Validations",
    //       order: "2",
    //       properties: [],
    //     },
    //     permissions: {
    //       tabLabel: "Permissions",
    //       order: "3",
    //       properties: [],
    //     },
    //     formulas: {
    //       tabLabel: "Formulas",
    //       order: "4",
    //       properties: [],
    //     },
    //   },
    // };

    // switch (type) {
    //   case "Short Answer": {
    //     response = await get(`${CONSTANT.RECOG_API}/form/get-form-field?type=shortanswer`);
    //     break;
    //   }
    //   case "Long Answer": {
    //     response = await get(`${CONSTANT.RECOG_API}/form/get-form-field?type=longanswer`);
    //     break;
    //   }
    //   case "Number": {
    //     response = await get(`${CONSTANT.RECOG_API}/form/get-form-field?type=number`);
    //     break;
    //   }
    //   case "Dropdown": {
    //     response = await get(`${CONSTANT.RECOG_API}/form/get-form-field?type=dropdown`);
    //     break;
    //   }
    //   case "Rating": {
    //     response = await get(`${CONSTANT.RECOG_API}/form/get-form-field?type=rating`);
    //     break;
    //   }
    //   default: {
    //     response = await get(`${CONSTANT.RECOG_API}/form/get-form-field?type=shortanswer`);
    //     break;
    //   }
    // }
    const response = await get(
      `${CONSTANT.RECOG_API}/form/get-form-field?type=${type}`,
    );
    return response.data.metadata;
  } catch (err) {
    throw new Error(err);
  }
};

export const getForm = async (id) => {
  const response = await get(
    `${CONSTANT.RECOG_API}/form/get-form-template?id=${id}`,
  );
  return response;
};

export const getForms = async (payload) => {
  const response = await post(
    `${CONSTANT.RECOG_API}/form/get-all-form-templates`,
    payload,
  );
  return {
    forms: response.data.formTemplate,
    total: response.data.totalCountResult,
  };
};

export const createForm = async (payload) => {
  const response = await post(
    `${CONSTANT.RECOG_API}/form/create-form-template`,
    payload,
  );
  return response;
};

export const editForm = async (payload) => {
  const response = await put(
    `${CONSTANT.RECOG_API}/form/edit-form-template`,
    payload,
  );
  return response;
};

export const deleteForm = async (id) => {
  const response = await put(
    `${CONSTANT.RECOG_API}/form/delete-form-template?id=${id}`,
    {},
  );
  return response;
};

export const duplicateForm = async (payload) => {
  const response = await post(
    `${CONSTANT.RECOG_API}/form/duplicate-form-template`,
    payload,
  );
  return response;
};

export const getFieldType = async (type) => {
  let URL = `${CONSTANT.RECOG_API}/form/get-form-fieldId?`;
  if (type) {
    URL += `type=${type}`;
  }
  const response = await get(URL);
  return response;
};

export const downloadBulkEditPointsTemplate = async () => {
  const url = `${CONSTANT.RECOG_API}/points/download-template`;
  const response = await get(url);
  return response.data;
};

export const BulkEditPointsByAdmin = async (payload) => {
  const url = `${CONSTANT.RECOG_API}/points/add-remove-points-by-admin`;
  const response = await post(url, payload, 'multipart/form-data');
  return response.data;
};
