import ArrowLeftIcon from 'assets/svg/arrowLeft';
import PropTypes from 'prop-types';
import Button from '../Button';

const BackButton = (props) => {
  const { onClick } = props;
  return (
    <Button onClick={() => onClick()}>
      <ArrowLeftIcon className="my-2" stroke="var(--primaryLeft)" />
    </Button>
  );
};

BackButton.propTypes = {
  onClick: PropTypes.func,
};

export default BackButton;
