import {
  ArrowSmallLeftIcon,
  ArrowSmallRightIcon,
} from '@heroicons/react/24/solid';
import React from 'react';
import ReactPaginate from 'react-paginate';
import { useSelector } from 'react-redux';
import './Pagination.styles.scss';

const Pagination = (props) => {
  const {
    onPageChanged,
    pageCount,
    currentPage,
    hidePrevNext,
    isFixed = true,
    showNavigationIconsOnly = false,
  } = props;
  const { isSidebarVisible } = useSelector((state) => state.theme);

  return (
    <React.Fragment>
      {!!pageCount && pageCount != 0 && !isNaN(pageCount) && (
        <div
          className={`w-full border  ${
            isFixed ? 'fixed bottom-0 z-30' : ''
          } bg-white py-2.5 flex justify-center items-center w-full ${
            hidePrevNext ? '!border-0  !bg-inherit' : ''
          }`}
          // style={{
          //    maxWidth: isSidebarVisible
          //       ? 'calc(100vw - 112px)'
          //       : 'calc(100vw - 256px)',
          // }}
        >
          <ReactPaginate
            className="container"
            activeClassName={'item active'}
            breakClassName={'item break-me'}
            nextClassName={` ${!hidePrevNext ? 'item next' : ''} `}
            containerClassName={'pagination'}
            disabledClassName={'disabled-page'}
            pageClassName={'item pagination-page'}
            previousClassName={` ${!hidePrevNext ? 'item previous' : ''} `}
            breakLabel="..."
            nextLabel={
              !hidePrevNext && (
                <span className="flex gap-1 items-center">
                  {!showNavigationIconsOnly && <span>Next</span>}
                  <ArrowSmallRightIcon className="h-5 w-5" />
                </span>
              )
            }
            previousLabel={
              !hidePrevNext && (
                <button
                  className="flex gap-1 items-center border-0 bg-transparent"
                  disabled={!pageCount}
                >
                  <ArrowSmallLeftIcon className="h-5 w-5" />
                  {!showNavigationIconsOnly && <span>Previous</span>}
                </button>
              )
            }
            onPageChange={onPageChanged}
            pageRangeDisplayed={1}
            pageCount={pageCount}
            renderOnZeroPageCount={null}
            forcePage={currentPage}
          />
        </div>
      )}
    </React.Fragment>
  );
};

export default Pagination;
