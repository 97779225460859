import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedType: 'survey',
  sectionId: '',
  fieldId: '',
  selectedFieldId: '',
  isFieldSelected: false,
};

const surveySlice = createSlice({
  name: 'survey',
  initialState,
  reducers: {
    setSelectedType(state, action) {
      const { type, sectionId, fieldId } = action.payload;
      state.selectedType = type;
      state.fieldId = fieldId;
      state.sectionId = sectionId;
      return state;
    },
    setIsFieldSelected(state, action) {
      const { isFieldSelected, fieldId, sectionId, selectedFieldId } =
        action.payload;
      state.isFieldSelected = isFieldSelected;
      state.selectedFieldId = selectedFieldId;
      state.fieldId = fieldId;
      state.sectionId = sectionId;
    },
  },
});

export default surveySlice.reducer;

export const { setSelectedType, setIsFieldSelected } = surveySlice.actions;
