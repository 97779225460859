import { getEligibleUserCount } from 'apiClient/benefit/benefits';
import FilterForEligibleUsers from 'pages/compensation/compPlanning/createPlanning/primaryTabs/planSetup/eligibleGroup/FilterForEligibleUsers';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

export const EligibleGroup = ({
  setEligibilityPayload,
  eligibilityPayload,
}: any) => {
  const [eligibleUserData, setEligibleUserData] = useState<any>({});
  const [savedData, setSavedData] = useState<{ [key: string]: any }>({});
  const { id } = useParams();

  const fetchUserData = async () => {
    try {
      const body = {};
      // @ts-ignore
      body.query = {
        eligibilityRule:
          eligibleUserData?.criteriaInclusion ||
          eligibleUserData?.criteriaInclusion ||
          {},
        eligibilityExclusionRule:
          eligibleUserData?.criteriaExclusion ||
          eligibleUserData?.criteriaExclusion ||
          {},
      };
      const res = await getEligibleUserCount(body);
      return {
        data: res?.data,
        total: res?.total,
      };
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (eligibleUserData && Object.keys(eligibleUserData || {}).length !== 0) {
      setSavedData((prev: any) => ({
        ...prev,
        criteriaInclusion: eligibleUserData?.eligibilityRule || {},
        criteriaExclusion: eligibleUserData?.eligibilityExclusionRule || {},
      }));
    } else {
      setSavedData((prev: any) => ({
        ...prev,
        criteriaInclusion: {},
        criteriaExclusion: {},
      }));
    }
  }, []);

  // useEffect(() => {
  //   (async () => {
  //     await createEligibility({
  //       planId: id,
  //       criteriaInclusion: savedData?.criteriaInclusion,
  //       criteriaExclusion: savedData?.criteriaExclusion,
  //     });
  //   })();
  // });

  useEffect(() => {
    const body: any = {
      planId: id,
    };
    if (eligibleUserData) {
      body.criteriaInclusion = eligibleUserData?.criteriaInclusion;
      body.criteriaExclusion = eligibleUserData?.criteriaExclusion;
    }
    setEligibilityPayload(body);
  }, [eligibleUserData]);

  console.log(eligibilityPayload, eligibleUserData, 'saved');

  return (
    <div className="pt-6 px-8 bg-white">
      <FilterForEligibleUsers
        saveApiCall={(body: any) => setEligibleUserData(body)}
        fetchEligibleUser={fetchUserData}
        formatIndex={{}}
        savedData={savedData}
        selectedPrimaryTab=""
        userList={[]}
        valueStroreInParent={true}
        isLoading={false}
        title="Users"
        isSaving={false}
        planId={id}
        viewMode={false}
        downloadEligibleGroupUsers={undefined}
        hideBulkUpload={true}
        showSaveButton={false}
      />
    </div>
  );
};
function async(arg0: () => void) {
  throw new Error('Function not implemented.');
}
