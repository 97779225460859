import { CloudArrowUpIcon } from '@heroicons/react/24/outline';
import ToastNotify from 'pages/surface/ToastNotify';
import React, { useRef } from 'react';
import { toast } from 'react-toastify';

const FileUploader = ({
  acceptedTypes,
  maxSize,
  subText,
  onFileSelect,
  isMulti,
}) => {
  const fileInputRef = useRef(null);

  const onResponse = (message) => {
    toast(<ToastNotify message={message} close={() => {}} />, {
      onClose: () => {},
      autoCloase: 2000,
      className:
        'toast-message absolute w-full h-full py-4 px-[30px] bg-white gap-4 flex flex-col border border-gray-200 shadow-md rounded-lg',
      zIndex: 1000,
    });
  };

  const handleFileInputChange = (e) => {
    const files = e.target.files;
    if (files.length) {
      if (isMulti) {
        onFileSelect(files); // Handle multiple files
      } else if (isFileValid(files[0])) {
        onFileSelect(files[0]); // Handle single file
      } else {
        onResponse('Invalid file. Please upload a valid file.');
      }
    }
  };

  const handleFileDrop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    if (files.length) {
      if (isMulti) {
        onFileSelect(files); // Handle multiple files
      } else if (isFileValid(files[0])) {
        onFileSelect(files[0]); // Handle single file
      } else {
        onResponse('Invalid file. Please upload a valid file.');
      }
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const isFileValid = (file) => {
    const fileType = '.' + file.name.split('.').pop().toLowerCase();
    return acceptedTypes.includes(fileType) && file.size <= maxSize;
  };

  return (
    <div
      className="flex flex-col items-center py-4 px-6 gap-4 rounded-lg gradient-border w-full h-full"
      onClick={() => {
        fileInputRef.current.click();
      }}
      onDrop={handleFileDrop}
      onDragOver={handleDragOver}
    >
      <div className="flex flex-col items-center p-0 gap-2 isolate w-full h-full cursor-pointer">
        <div className="flex w-10 h-10 bg-[#F2F4F7] border-4 border-[#F9F9F9] items-center justify-center rounded-3xl">
          <CloudArrowUpIcon className="h-5 w-5" />
        </div>
        <div className="flex flex-col items-center p-0 gap-1 h-full font-semibold">
          <p className="text-base">
            <span className="font-semibold text-transparent bg-clip-text bg-gradient-to-br from-primaryLeft to-primaryRight">
              Click to upload
            </span>{' '}
            or drag and drop
          </p>
          <p className="text-xs text-gray-600 text-center">{subText}</p>
        </div>

        <input
          ref={fileInputRef}
          type="file"
          accept={acceptedTypes.join(', ')}
          style={{ display: 'none' }}
          onChange={handleFileInputChange}
          {...(isMulti ? { multiple: true } : {})}
        />
      </div>
    </div>
  );
};

export default FileUploader;
