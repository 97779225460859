import { ArrowSmallLeftIcon } from '@heroicons/react/24/outline';
import { fetchProgramWorkflows } from 'apiClient/benefit/benefits';
import { BarChartGradientIcon } from 'assets/svg/barChartGradientIcon';
import PlusIcon from 'assets/svg/plusIcon';
import Button from 'components/atoms/button/Button';
import Tabs from 'components/molecules/tabs/Tabs';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CreatePlanPopUp } from './CreatePlanPopUp';
import { ManageVariablePayPlans } from './managePlans/ManageVariablePayPlans';

const tabs = [
  { id: 1, tabLabel: 'Plans' },
  { id: 2, tabLabel: 'Employees' },
];

export const VariablePay = () => {
  const navigate = useNavigate();
  const [createPlanPopUpOpen, setCreatePlanPopUpOpen] =
    useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState<string>(tabs[0].tabLabel);
  const [workflows, setWorkflows] = useState([]);
  const [hovered, setHovered] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      try {
        const response = await fetchProgramWorkflows();
        setWorkflows(
          response?.map((workflow: any) => ({
            ...workflow,
            label: workflow?.name,
            value: workflow?.id,
          })),
        );
      } catch (error) {
        setWorkflows([]);
      }
    })();
  }, []);

  return (
    <div className="pt-6 px-8 bg-white h-full">
      <div className="space-y-6">
        <div className="flex items-center justify-between">
          <div className="flex flex-row items-center gap-3 justify-start">
            <div
              className="p-2 rounded-lg border-2 cursor-pointer"
              onClick={() => navigate(-1)}
            >
              <ArrowSmallLeftIcon className="w-5 h-5" />
            </div>
            <div className=" flex items-center justify center text-2xl font-semibold">
              Manage Variable Pay
            </div>
          </div>
          <div className="flex items-center gap-3">
            <Button
              className="text-sm font-semibold flex items-center justify-center gap-2"
              variant="outlined"
              onMouseEnter={() => setHovered(true)}
              onMouseLeave={() => setHovered(false)}
            >
              <BarChartGradientIcon hovered={hovered} />
              Dashboard
            </Button>
            <Button
              variant="filled"
              className="text-sm font-semibold flex items-center justify-center gap-2"
              onClick={() => setCreatePlanPopUpOpen(true)}
            >
              <PlusIcon />
              Create New
            </Button>
          </div>
        </div>
        <div className="space-y-4">
          <Tabs
            tabs={tabs}
            className="text-sm font-semibold text-gray-600"
            selectedTab={selectedTab}
            handleTab={(e: { tabLabel: string }) => setSelectedTab(e.tabLabel)}
          />
          {selectedTab === 'Plans' && <ManageVariablePayPlans />}
        </div>
      </div>
      {createPlanPopUpOpen && (
        <CreatePlanPopUp
          setCreatePlanPopUpOpen={setCreatePlanPopUpOpen}
          workflows={workflows}
        />
      )}
    </div>
  );
};
