import DeleteIcon from 'assets/svg/deleteIcon';
import React from 'react';

const BuilderSectionLayout = (props) => {
  const { onClick, index, onRemove } = props;

  return (
    <div className="group">
      <div className=" xl:h-[456px] max-xl:py-10 px-2 w-full rounded-xl ring-1 flex flex-col justify-center items-center gap-8 ring-gray-300">
        <p className="text-sm font-semibold">Select Section Layout</p>
        <div className="grid max-2xl:grid-cols-2 max-lg:grid-cols-1 grid-cols-4 gap-12">
          <div
            onClick={() => onClick(index, 1)}
            className="w-56 h-28 cursor-pointer ring-1 ring-gray-400"
          >
            <SectionBox />
          </div>
          <div
            onClick={() => onClick(index, 2)}
            className="w-56 h-28 cursor-pointer ring-1 ring-gray-400 grid grid-cols-2 gap-2"
          >
            <SectionBox />
            <SectionBox />
          </div>
          <div
            onClick={() => onClick(index, 3)}
            className="w-w-56 h-28 cursor-pointer  ring-1 ring-gray-400 grid grid-cols-3 gap-2"
          >
            <SectionBox />
            <SectionBox />
            <SectionBox />
          </div>
          <div
            onClick={() => onClick(index, 4)}
            className="w-w-56 h-28 cursor-pointer ring-1 ring-gray-400 grid grid-cols-4 gap-2"
          >
            <SectionBox />
            <SectionBox />
            <SectionBox />
            <SectionBox />
          </div>
        </div>
      </div>
      <div className="group-hover:block hidden">
        <div className="p-4 h-14 ring-1 ring-gray-200 rounded-lg flex justify-end mt-3">
          <DeleteIcon onClick={() => onRemove(index)} />
        </div>
      </div>
    </div>
  );
};

export default BuilderSectionLayout;

const SectionBox = () => {
  return <div className="bg-gray-200 w-full h-full"></div>;
};
