import React from 'react';
import '../marketData/MarketData.css';

const MarketData = ({ data, columns }) => {
  return (
    <div className="flex w-full overflow-x-auto custom-scrollbar">
      <table className="table-auto pb-2 marketData-table">
        <thead>
          <tr>
            {columns?.map((column, index) => {
              return (
                <th key={index}>
                  <p className="font-figtree text-sm font-medium text-gray-600 text-nowrap text-start">
                    {column?.name}
                  </p>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {data?.map((row, index) => {
            return (
              <tr key={index}>
                {row?.data?.map((item, ind) => {
                  return (
                    <td key={ind}>
                      <p
                        className={`font-figtree text-sm ${
                          ind === 0
                            ? 'font-medium text-gray-900'
                            : 'font-normal text-gray-600'
                        }`}
                      >
                        {item}
                      </p>
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default MarketData;
