const { createSlice } = require('@reduxjs/toolkit');

const initialState = {
  livePolicies: [],
  draftsPolicies: [],
  archivedPolicies: [],
};

const managePoliciesSlice = createSlice({
  name: 'managePolicies',
  initialState,
  reducers: {
    setLivePolicies: (state, action) => {
      state.livePolicies = action.payload;
    },
    setDraftsPolicies: (state, action) => {
      state.draftsPolicies = action.payload;
    },
    setArchivedPolicies: (state, action) => {
      state.archivedPolicies = action.payload;
    },
    deletePolicy: (state, action) => {
      state.draftsPolicies = state.draftsPolicies.filter(
        (policy) => policy.id !== action.payload,
      );
    },
    archivePolicy: (state, action) => {
      state.livePolicies = state.livePolicies.filter(
        (policy) => policy.id !== action.payload,
      );
    },
    publishPolicy: (state, action) => {
      state.draftsPolicies = state.draftsPolicies.filter(
        (policy) => policy.id !== action.payload,
      );
    },
  },
});

export const {
  setArchivedPolicies,
  setDraftsPolicies,
  setLivePolicies,
  deletePolicy,
  archivePolicy,
  publishPolicy,
} = managePoliciesSlice.actions;
export default managePoliciesSlice.reducer;
export const managePoliciesSelector = (state) => state.managePolicies;
