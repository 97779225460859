import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/24/solid';
import VerticalEclipse from 'assets/svg/verticalEclipse';
import Button from 'components/atoms/button/Button';
import ChipsLabels from 'components/atoms/chips-labels/ChipsLabels';
import Table from 'components/atoms/table/Table';
import TableHeader from 'components/atoms/tableHeader/TableHeader';
import { managePlans } from 'config/variablePay';
import { tabs } from 'pages/benefits/constants';
import { formatDate } from 'pages/recognition/orderHistory/helper';
import { useCallback, useState } from 'react';
import { VariablePaySubComponent } from './VariablePaySubComponent';

export const ManageVariablePayPlans = () => {
  const [selectedTab, setSelectedTab] = useState(tabs[0].tabLabel);
  const columns = [
    {
      id: '1',
      Header: <TableHeader name="Plan Name" HeaderKey="planName" sort={true} />,
      Cell: ({ row }: any) => {
        const [planIdOpen, setPlanIdOpen] = useState<string>('');
        const [planDetailsOpen, setPlanDetailsOpen] = useState<boolean>(false);
        const rowProps = row?.getToggleRowExpandedProps();
        return (
          <div className="flex items-center gap-3">
            {planDetailsOpen && planIdOpen === row.original?.id ? (
              <ChevronDownIcon
                onClick={() => {
                  rowProps.onClick();
                  setPlanDetailsOpen(false);
                  setPlanIdOpen('');
                }}
                className="w-4 h-4 text-gray-600 cursor-pointer"
              />
            ) : (
              <ChevronRightIcon
                onClick={() => {
                  rowProps.onClick();
                  setPlanDetailsOpen(true);
                  setPlanIdOpen(row.original?.id);
                }}
                className="w-4 h-4 text-gray-600 cursor-pointer"
              />
            )}

            <div>
              <p className="text-sm font-medium text-gray-900">
                {row.original?.planName}
              </p>
              <p className="text-sm text-gray-600">ID 12345</p>
            </div>
          </div>
        );
      },
    },
    {
      id: '2',
      Header: <TableHeader name="Type" HeaderKey="type" sort={true} />,
      Cell: ({ row }: any) => {
        return <p className="text-sm text-gray-600">{row.original?.type}</p>;
      },
    },
    {
      id: '3',
      Header: (
        <TableHeader
          name="Payout Frequency"
          HeaderKey="payoutFrequency"
          sort={true}
        />
      ),
      Cell: ({ row }: any) => {
        return (
          <p className="text-sm text-gray-600">
            {row.original?.payoutFrequency}
          </p>
        );
      },
    },
    {
      id: '4',
      Header: <TableHeader name="Status" HeaderKey="status" sort={true} />,
      Cell: ({ row }: any) => {
        return (
          <ChipsLabels
            // @ts-ignore
            className="text-sm font-medium"
            label={row.original?.status}
            status={row.original?.status === 'Active' ? 'accepted' : ''}
          />
        );
      },
    },
    {
      id: '5',
      Header: (
        <TableHeader
          name="Last Modified"
          HeaderKey="lastModified"
          sort={true}
        />
      ),
      Cell: ({ row }: any) => {
        return (
          <p className="text-sm text-gray-600">{formatDate(new Date())}</p>
        );
      },
    },
    {
      id: '6',
      Header: (
        <TableHeader
          name="Plan Start Date"
          HeaderKey="planStartDate"
          sort={true}
        />
      ),
      Cell: ({ row }: any) => {
        return (
          <p className="text-sm text-gray-600">
            {formatDate(row.original?.planStartDate)}
          </p>
        );
      },
    },
    {
      id: '7',
      Header: (
        <TableHeader name="Plan End Date" HeaderKey="planEndDate" sort={true} />
      ),
      Cell: ({ row }: any) => {
        return (
          <p className="text-sm text-gray-600">
            {formatDate(row.original?.planEndDate)}
          </p>
        );
      },
    },
    {
      id: '8',
      width: '3%',
      Header: <TableHeader name="" HeaderKey="" sort={true} />,
      Cell: ({ row }: any) => {
        return <VerticalEclipse />;
      },
    },
  ];

  const renderRowSubComponent = useCallback(({ row }: any) => {
    return <VariablePaySubComponent data={[row.original]} />;
  }, []);

  return (
    <div>
      <div className="flex items-center gap-2">
        {tabs.map((tab) => {
          return (
            <Button
              key={tab.id}
              variant={selectedTab === tab.tabLabel ? 'outlined' : ''}
              onClick={() => setSelectedTab(tab.tabLabel)}
              className="font-semibold text-gray-600"
            >
              {tab.tabLabel}
            </Button>
          );
        })}
      </div>
      <Table
        headerBackgroundColor="bg-gray-50"
        data={managePlans}
        columns={columns}
        isExpandable={true}
        renderRowSubComponent={renderRowSubComponent}
      />
    </div>
  );
};
