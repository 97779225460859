const StatementsIcon = ({
  isActive,
  activeColor,
  isHovered,
  hoveredColor,
  width,
  height,
}) => (
  <svg
    width={width ? width : '20'}
    height={height ? height : '20'}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.6667 1.89124V5.33335C11.6667 5.80006 11.6667 6.03342 11.7575 6.21168C11.8374 6.36848 11.9649 6.49596 12.1217 6.57586C12.2999 6.66669 12.5333 6.66669 13 6.66669H16.4421M11.6667 14.1666H6.66668M13.3333 10.8333H6.66668M16.6667 8.32348V14.3333C16.6667 15.7334 16.6667 16.4335 16.3942 16.9683C16.1545 17.4387 15.7721 17.8211 15.3017 18.0608C14.7669 18.3333 14.0668 18.3333 12.6667 18.3333H7.33334C5.93321 18.3333 5.23315 18.3333 4.69837 18.0608C4.22796 17.8211 3.84551 17.4387 3.60583 16.9683C3.33334 16.4335 3.33334 15.7334 3.33334 14.3333V5.66663C3.33334 4.26649 3.33334 3.56643 3.60583 3.03165C3.84551 2.56124 4.22796 2.17879 4.69837 1.93911C5.23315 1.66663 5.93321 1.66663 7.33334 1.66663H10.0098C10.6213 1.66663 10.927 1.66663 11.2148 1.7357C11.4698 1.79694 11.7137 1.89795 11.9374 2.03503C12.1897 2.18963 12.4059 2.40582 12.8382 2.8382L15.4951 5.49505C15.9275 5.92743 16.1437 6.14362 16.2983 6.39591C16.4353 6.61959 16.5364 6.86346 16.5976 7.11855C16.6667 7.40627 16.6667 7.712 16.6667 8.32348Z"
      stroke={isActive ? activeColor : isHovered ? hoveredColor : '#475467'}
      strokeWidth="1.67"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default StatementsIcon;
