import React from 'react';
import { useFormContext } from 'react-hook-form';

const DividerComponent = (props) => {
  const { path, selectAtomsMolecules, type } = props.data;
  const { watch } = useFormContext();
  const [dHeight, dWidth, bgDivider] = watch([
    `${path}.divider.height`,
    `${path}.divider.width`,
    `${path}.divider.color`,
  ]);
  return (
    <div
      style={{
        height: dHeight ? dHeight + 'px' : '1px',
        width: dWidth ? dWidth + 'px' : '100%',
        backgroundColor: bgDivider ? bgDivider : '',
      }}
      onClick={() => selectAtomsMolecules(type)}
      className={`bg-primaryLeft-700 w-full cursor-pointer`}
    ></div>
  );
};

export default DividerComponent;
