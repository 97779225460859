import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import './multiRangeSlider.css';
import {
  redemptionSelector,
  setSelectedFilters,
} from 'store/reducers/redemptionSlice';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

const MultiRangeSlider = ({
  min,
  max,
  disabled = false,
  handleApply,
  filterReset,
  setFilterReset,
  setMin,
  setMax,
  getValuesInParent,
}) => {
  const [minVal, setMinVal] = useState(min);
  const [maxVal, setMaxVal] = useState(max);
  const minValRef = useRef(min);
  const maxValRef = useRef(max);
  const range = useRef(null);
  const { selectedFilters, searchedValue } = useSelector(redemptionSelector);
  const dispatch = useDispatch();

  // Convert to percentage
  const getPercent = useCallback(
    (value) => Math.round(((value - min) / (max - min)) * 100),
    [min, max, minVal, maxVal],
  );

  // Set width of the range to decrease from the left side
  useEffect(() => {
    const minPercent = getPercent(minVal);
    const maxPercent = getPercent(maxValRef.current);

    if (range.current) {
      range.current.style.left = `${minPercent}%`;
      range.current.style.width = `${maxPercent - minPercent}%`;
    }
  }, [minVal, getPercent]);

  // Set width of the range to decrease from the right side
  useEffect(() => {
    const minPercent = getPercent(minValRef.current);
    const maxPercent = getPercent(maxVal);

    if (range.current) {
      range.current.style.width = `${maxPercent - minPercent}%`;
    }
  }, [maxVal, getPercent]);

  useEffect(() => {
    if (filterReset) {
      setMinVal(0);
      setMaxVal(10000);
      minValRef.current = 0;
      maxValRef.current = 10000;
      setMin(0);
      setMax(10000);
      setFilterReset(false);
    }
  }, [filterReset]);
  const minValValue = useMemo(() => minVal, [minVal]);
  const maxValValue = useMemo(() => maxVal, [maxVal]);

  useEffect(() => {
    if (minValValue && getValuesInParent) {
      setMin(minValValue);
    }
    if (maxValValue && getValuesInParent) {
      setMax(maxValValue);
    }
  }, [minValValue, maxValValue, getValuesInParent]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      dispatch(
        setSelectedFilters({
          ...selectedFilters,
          maxPoints: maxVal,
          fitToMyBudget: false,
        }),
      );
      // handleApply(
      //   {
      //     ...selectedFilters,
      //     maxPoints: filterReset ? 10000 : maxVal,
      //     fitToMyBudget: false,
      //   },
      //   searchedValue
      // );
    }, 300);
    return () => clearTimeout(timeoutId);
  }, [maxVal]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      dispatch(
        setSelectedFilters({
          ...selectedFilters,
          minPoints: minVal,
          fitToMyBudget: false,
        }),
      );
      // handleApply(
      //   {
      //     ...selectedFilters,
      //     minPoints: filterReset ? 100 : minVal,
      //     fitToMyBudget: false,
      //   },
      //   searchedValue
      // );
    }, 300);
    return () => clearTimeout(timeoutId);
  }, [minVal]);

  return (
    <div className="container">
      <input
        type="range"
        min={min}
        max={max}
        value={minVal}
        disabled={disabled}
        onChange={(event) => {
          const value = Math.min(Number(event.target.value), maxVal - 1);
          setMinVal(value);
          minValRef.current = value;
        }}
        className={`thumb thumb--left ${
          disabled ? 'cursor-not-allowed' : 'cursor-pointer'
        }`}
        style={{ zIndex: minVal > max - 100 && '5' }}
      />
      <input
        type="range"
        min={min}
        max={max}
        value={maxVal}
        disabled={disabled}
        onChange={(event) => {
          const value = Math.max(Number(event.target.value), minVal + 1);
          setMaxVal(value);
          maxValRef.current = value;
        }}
        className={`thumb thumb--right ${
          disabled ? 'cursor-not-allowed' : 'cursor-pointer'
        }`}
      />

      <div className="slider">
        <div className="slider__track" />
        <div
          ref={range}
          className={`${disabled ? 'bg-slateGray z-20' : 'slider__range'}`}
        />
        <div className="slider__left-value">{minVal}</div>
        <div className="slider__right-value">{maxVal}</div>
      </div>
    </div>
  );
};

MultiRangeSlider.propTypes = {
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
};

export default MultiRangeSlider;
