const IncrementPhilosophyIcon = ({
  isActive,
  activeColor,
  isHovered,
  hoveredColor,
  width,
  height,
}) => (
  // <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none">
  //   <path
  //     stroke={isActive ? activeColor : isHovered ? hoveredColor : '#475467'}
  //     strokeLinecap="round"
  //     strokeLinejoin="round"
  //     strokeWidth={1.67}
  //     d="M10 16.667H4.333c-.933 0-1.4 0-1.756-.182a1.667 1.667 0 0 1-.729-.728c-.181-.357-.181-.823-.181-1.757V6c0-.933 0-1.4.181-1.757.16-.313.415-.568.729-.728.356-.182.823-.182 1.756-.182h.334c1.867 0 2.8 0 3.513.364.627.32 1.137.83 1.457 1.456C10 5.866 10 6.8 10 8.667m0 8v-8m0 8h5.667c.933 0 1.4 0 1.756-.182.314-.16.569-.415.729-.728.181-.357.181-.823.181-1.757V6c0-.933 0-1.4-.181-1.757a1.667 1.667 0 0 0-.729-.728c-.356-.182-.823-.182-1.756-.182h-.334c-1.867 0-2.8 0-3.513.364-.627.32-1.137.83-1.457 1.456C10 5.866 10 6.8 10 8.667"
  //   />
  // </svg>
  <svg
    width={width ? width : '20'}
    height={height ? height : '20'}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.99999 16.6667H4.33332C3.3999 16.6667 2.93319 16.6667 2.57667 16.4851C2.26307 16.3253 2.0081 16.0703 1.84831 15.7567C1.66666 15.4002 1.66666 14.9335 1.66666 14V6.00004C1.66666 5.06662 1.66666 4.59991 1.84831 4.24339C2.0081 3.92979 2.26307 3.67482 2.57667 3.51503C2.93319 3.33337 3.3999 3.33337 4.33332 3.33337H4.66666C6.5335 3.33337 7.46692 3.33337 8.17996 3.69669C8.80717 4.01626 9.3171 4.5262 9.63668 5.15341C9.99999 5.86644 9.99999 6.79987 9.99999 8.66671M9.99999 16.6667V8.66671M9.99999 16.6667H15.6667C16.6001 16.6667 17.0668 16.6667 17.4233 16.4851C17.7369 16.3253 17.9919 16.0703 18.1517 15.7567C18.3333 15.4002 18.3333 14.9335 18.3333 14V6.00004C18.3333 5.06662 18.3333 4.59991 18.1517 4.24339C17.9919 3.92979 17.7369 3.67482 17.4233 3.51503C17.0668 3.33337 16.6001 3.33337 15.6667 3.33337H15.3333C13.4665 3.33337 12.5331 3.33337 11.82 3.69669C11.1928 4.01626 10.6829 4.5262 10.3633 5.15341C9.99999 5.86644 9.99999 6.79987 9.99999 8.66671"
      stroke={isActive ? activeColor : isHovered ? hoveredColor : '#475467'}
      strokeWidth="1.67"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default IncrementPhilosophyIcon;
