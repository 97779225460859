import PlusIconGradient from 'assets/svg/plusIconGradient';
import { Input } from 'components/atoms/FormElements/input/Input';
import Select from 'components/atoms/FormElements/select/Select';
import Button from 'components/atoms/button/Button';
import ModalPopup from 'components/layout/modalPopup/ModalPopup';

export const AddColumModal = ({ setAddColumnVisible }) => {
  return (
    <ModalPopup
      onClose={() => setAddColumnVisible(false)}
      title={
        <div className="border-[1.5px] w-12 rounded-[10px] flex items-center justify-center h-12 border-primaryActive">
          <PlusIconGradient />
        </div>
      }
      width="w-[25rem]"
      body={
        <div className="">
          <div className="space-y-1 px-6">
            <p className="text-lg font-semibold text-gray-900">Add Column</p>
            <p className="text-sm text-gray-600">Column Description</p>
          </div>
          <div className="space-y-5 my-6 px-6">
            <Input label="Column Name" placeholder="Enter column name.." />
            <Select label="Section" options={[]} />
          </div>
          <div className="border-t border-gray-200 pt-4 px-6 flex items-center justify-end gap-4">
            <button
              className="text-sm font-semibold text-gray-600"
              onClick={() => setAddColumnVisible(false)}
            >
              Cancel
            </button>
            <Button className="text-sm font-semibold" variant="filled">
              Update
            </Button>
          </div>
        </div>
      }
    />
  );
};
