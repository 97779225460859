import axios from 'axios';

// const apiCall =  () => {
async function apiCalls(method, url, data = null, type) {
  //  const { logout } =

  //  useAuth0();
  const config = {
    method,
    url,
    headers: {
      'x-access-token': localStorage.getItem('accessToken'),
      'x-id-token': localStorage.getItem('idAccessToken'),
      'Content-Type': type || 'application/json',
    },
    // data: data ? JSON.stringify(data) : null,
    ...(data ? { data } : {}),
  };

  try {
    const response = await axios(config);
    return response.data;
  } catch (error) {
    //console.log(error);

    // const auth0 = await createAuth0Client({
    //   domain: process.env.REACT_APP_AUTH0_DOMAIN,
    //   client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
    //   redirect_uri: "/", // Change to your redirect URI
    // });

    if (error.response && error.response.status === 401) {
      localStorage.clear();

      // auth0.logout({
      //   returnTo: "/",
      // });
      // logout({ returnTo: "/" })
      // window.location.reload();
    }
    // if (error.response && error.response.status === 502) {

    //   //logout({ returnTo: "/" })
    //   // window.location.reload();
    // }
    throw error;
  }
}
async function fetchBlobMedia(method, url, data = null, type) {
  try {
    const response = await fetch(url, {
      method: method,
      headers: {
        'Content-Type':
          method === 'POST' ? `application/json` : 'application/pdf',
        'x-access-token': localStorage.getItem('accessToken'),
        'x-id-token': localStorage.getItem('idAccessToken'),
      },
      body: data && method === 'POST' ? JSON.stringify(data) : undefined,
    });
    return response;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      localStorage.clear();
      throw error;
    }
  }
}

export default apiCalls;

export const get = (url, queryParams = {}) => apiCalls('GET', url, queryParams);
export const getBlobMedia = (url, queryParams = {}) =>
  fetchBlobMedia('GET', url, queryParams);

export const post = (url, data, type) => apiCalls('POST', url, data, type);
export const postBlobMedia = (url, data, type) => {
  return fetchBlobMedia('POST', url, data, type);
};

export const put = (url, data, type) => apiCalls('PUT', url, data, type);

export const patch = (url, data, type) => apiCalls('PATCH', url, data, type);

export const del = (url, data) => apiCalls('DELETE', url, data);

// import axios from 'axios';
// import { useAuth0 } from '@auth0/auth0-react';
// import { useState, useCallback } from 'react';

// // Create an axios instance with default configurations
// const axiosInstance = axios.create({
//   baseURL: 'https://api.example.com', // Replace with your base API URL
//   timeout: 10000, // Timeout after 10 seconds
//   headers: {
//     'Content-Type': 'application/json',
//   },
// });

// API wrapper function with retry logic and Auth0 logout
// const apiCall = async (method, url, data = null, params = null, retries = 3, logout = null) => {
//   for (let attempt = 1; attempt <= retries; attempt++) {
//     try {
//       const response = await axiosInstance({
//         method,
//         url,
//         data,
//         params,
//       });
//       return response.data;
//     } catch (error) {
//       if (error.response && error.response.status === 401) {
//         console.warn('Unauthorized access - logging out');
//         if (logout) {
//           logout({ returnTo: window.location.origin });
//         }
//         return;
//       }

//       if (attempt === retries) {
//         console.error('API call error after multiple attempts:', error);
//         throw error; // Rethrow error after final attempt
//       }
//       console.warn(`API call failed, retrying attempt ${attempt} of ${retries}...`);
//     }
//   }
// };

// export const useApiCall = () => {
//   const { logout } = useAuth0();
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState(null);

//   const callApi = useCallback(async (method, url, data = null, params = null) => {
//     setLoading(true);
//     setError(null);
//     try {
//       const result = await apiCall(method, url, data, params, 3, logout);
//       return result;
//     } catch (err) {
//       setError(err.message);
//       throw err;
//     } finally {
//       setLoading(false);
//     }
//   }, [logout]);

//   return { callApi, loading, error };
// };
