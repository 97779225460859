import { fetchPayStructureColumns } from 'apiClient/rangeBuilder/rangeBuilder';
import { CheckBoxProgram } from 'components/atoms/FormElements/input/Input';
import { setToastMessage } from 'pages/recognition/redemption/helper';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

/*
Components being reused are:-
    1. CheckBoxProgram
*/

export const PayStructure = ({ planDetails, setPlanDetails }) => {
  const [payStructureData, setPayStructureData] = useState([]);
  const [compensationTypesSelected, setCompensationTypesSelected] = useState();
  const location = useLocation();

  const getPayStructureColumns = async () => {
    try {
      const response = await fetchPayStructureColumns();
      setPayStructureData(response?.data);

      !location.pathname.includes('update') &&
        setCompensationTypesSelected([response?.data?.[0]?.id]);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    location.pathname.includes('update') &&
      setCompensationTypesSelected(planDetails?.compensationType);
  }, [planDetails, payStructureData]);

  useEffect(() => {
    (async () => {
      await getPayStructureColumns();
    })();
  }, []);

  const handleCompensationTypeSelect = (compensationType) => {
    if (compensationTypesSelected?.includes(compensationType)) {
      setCompensationTypesSelected(
        compensationTypesSelected?.filter((type) => type !== compensationType),
      );
    } else {
      setCompensationTypesSelected([
        ...compensationTypesSelected,
        compensationType,
      ]);
    }
  };

  useEffect(() => {
    setPlanDetails({
      ...planDetails,
      compensationType: compensationTypesSelected,
    });
  }, [compensationTypesSelected]);

  return (
    <div className="px-5 pt-6 pb-5 space-y-5 bg-white rounded-xl shadow-md">
      <p className="text-sm font-semibold text-gray-900 pb-[10px]">
        Pay Structure
      </p>
      <div className="flex flex-wrap items-center gap-8">
        {payStructureData?.map((compensationType) => {
          return (
            <div
              key={compensationType?.id}
              className="text-sm font-medium text-gray-600 w-[23.4%]"
            >
              <CheckBoxProgram
                label={compensationType?.columnName}
                checked={compensationTypesSelected?.includes(
                  compensationType?.id,
                )}
                onChange={() =>
                  planDetails?.compensationType?.length > 1 ||
                  !compensationTypesSelected?.includes(compensationType?.id)
                    ? handleCompensationTypeSelect(compensationType?.id)
                    : setToastMessage('One compensation type must be selected')
                }
              />
              <p className="ml-7 !text-gray-500 !font-normal">
                {compensationType?.description}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
};
