const PermissionRolesIcon = (props) => (
  <svg
    width={props?.width ? props?.width : '20'}
    height={props?.height ? props?.height : '20'}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.66666 6.9811C1.66666 6.68917 1.66666 6.5432 1.67884 6.42026C1.79632 5.23447 2.73438 4.29642 3.92017 4.17893C4.04311 4.16675 4.19695 4.16675 4.50464 4.16675C4.6232 4.16675 4.68248 4.16675 4.73281 4.1637C5.3755 4.12478 5.93828 3.71915 6.17844 3.12175C6.19725 3.07496 6.21483 3.02222 6.24999 2.91675C6.28515 2.81127 6.30273 2.75853 6.32154 2.71175C6.5617 2.11435 7.12448 1.70872 7.76717 1.6698C7.8175 1.66675 7.87309 1.66675 7.98427 1.66675H12.0157C12.1269 1.66675 12.1825 1.66675 12.2328 1.6698C12.8755 1.70872 13.4383 2.11435 13.6784 2.71175C13.6973 2.75853 13.7148 2.81127 13.75 2.91675C13.7851 3.02222 13.8027 3.07496 13.8215 3.12175C14.0617 3.71915 14.6245 4.12478 15.2672 4.1637C15.3175 4.16675 15.3768 4.16675 15.4953 4.16675C15.803 4.16675 15.9569 4.16675 16.0798 4.17893C17.2656 4.29642 18.2037 5.23447 18.3211 6.42026C18.3333 6.5432 18.3333 6.68917 18.3333 6.9811V13.5001C18.3333 14.9002 18.3333 15.6003 18.0608 16.1351C17.8212 16.6055 17.4387 16.9879 16.9683 17.2276C16.4335 17.5001 15.7335 17.5001 14.3333 17.5001H5.66666C4.26653 17.5001 3.56646 17.5001 3.03168 17.2276C2.56128 16.9879 2.17882 16.6055 1.93914 16.1351C1.66666 15.6003 1.66666 14.9002 1.66666 13.5001V6.9811Z"
      stroke={
        props.isActive
          ? props.activeColor
          : props.isHovered
          ? props.hoveredColor
          : '#475467'
      }
      strokeWidth="1.67"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.99999 13.7501C11.8409 13.7501 13.3333 12.2577 13.3333 10.4167C13.3333 8.5758 11.8409 7.08341 9.99999 7.08341C8.15904 7.08341 6.66666 8.5758 6.66666 10.4167C6.66666 12.2577 8.15904 13.7501 9.99999 13.7501Z"
      stroke={
        props.isActive
          ? props.activeColor
          : props.isHovered
          ? props.hoveredColor
          : '#475467'
      }
      strokeWidth="1.67"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default PermissionRolesIcon;
