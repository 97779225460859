import { useEffect, useState } from 'react';
import CreateDashboard from './createDashboard/CreateDashboard';
import ViewDashboard from './viewDashboard/ViewDashboard';
import {
  useCreateDashboard,
  useGetDashboardData,
} from 'query/dashboard/ManageDashboard';
import { useParams } from 'react-router-dom';
import InfinityLoader from 'assets/svg/InfinityLoader/InfinityLoader';
import Button from 'components/atoms/button/Button';
import EyeIcon from 'assets/svg/eyeIcon';
import GradientCloudDownloadIcon from 'assets/svg/GradientCloudDownloadIcon';
import { refreshFile } from 'apiClient/dashboardBuilder/DashboardBuilder';
import { onResponse } from 'utils/toastMessages';
import LoadingCircle from 'components/molecules/loadingCircle/LoadingCircle';

export default function Dashboard({
  module = 'default',
  containerHeight,
  getFilterFields,
  mode,
  reportId,
  hideEditButton,
  hideGoToReportButton,
  hideDownloadButton,
  hideFilterButton,
}) {
  const viewPayload = {
    sourceId: reportId,
    sourceName: module,
  };

  const {
    data,
    isLoading,
    isError,
    refetch: refetchDashboardData,
  } = useGetDashboardData(viewPayload);

  const [dashboardSettings, setDashboardSettings] = useState({});
  const [pageList, setPageList] = useState([]);
  const [isRefresh, setIsRefresh] = useState(false);
  const [refreshError, setRefreshError] = useState('');
  const dashboardId = data?.dashboard?.id;

  // useEffect(() => {
  //   const refresh = async () => {
  //     setIsRefresh(true);
  //     try {
  //       if (dashboardId) {
  //         await refreshFile(dashboardId, module);
  //       }
  //     } catch (error) {
  //       setIsRefresh(false);
  //       setRefreshError(error?.message);
  //     }
  //     setIsRefresh(false);
  //   };
  //   refresh();
  // }, [dashboardId, module]);

  useEffect(() => {
    if (data) {
      setDashboardSettings(data?.dashboard);
      setPageList(data?.pages);
    }
  }, [data]);

  if (isError) {
    return (
      <div className="flex flex-col items-center justify-center h-96">
        Oops...something went wrong
      </div>
    );
  }

  return (
    <>
      {isLoading ? (
        <div className="flex justify-center items-center h-96">
          <InfinityLoader />
        </div>
      ) : (
        <>
          <div className="p-1 w-full h-full">
            {mode === 'create' ? (
              <CreateDashboard
                module={module}
                containerHeight={containerHeight}
                dashboardSettings={dashboardSettings || {}}
                setDashboardSettings={setDashboardSettings}
                refetchDashboardData={refetchDashboardData}
                getFilterFields={getFilterFields}
                pageList={pageList}
                reportId={reportId}
              />
            ) : (
              <ViewDashboard
                module={module}
                dashboardSettings={dashboardSettings || {}}
                pageList={pageList || []}
                hideEditButton={hideEditButton}
                hideGoToReportButton={hideGoToReportButton}
                hideDownloadButton={hideDownloadButton}
                hideFilterButton={hideFilterButton}
              />
            )}
          </div>
        </>
      )}
    </>
  );
}
