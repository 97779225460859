import React from 'react';

const KebabIcon = () => {
  return (
    <div>
      <svg
        width="4"
        height="16"
        viewBox="0 0 4 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.00002 8.83334C2.46026 8.83334 2.83335 8.46025 2.83335 8.00001C2.83335 7.53977 2.46026 7.16668 2.00002 7.16668C1.53978 7.16668 1.16669 7.53977 1.16669 8.00001C1.16669 8.46025 1.53978 8.83334 2.00002 8.83334Z"
          stroke="#667085"
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.00002 3.00001C2.46026 3.00001 2.83335 2.62691 2.83335 2.16668C2.83335 1.70644 2.46026 1.33334 2.00002 1.33334C1.53978 1.33334 1.16669 1.70644 1.16669 2.16668C1.16669 2.62691 1.53978 3.00001 2.00002 3.00001Z"
          stroke="#667085"
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.00002 14.6667C2.46026 14.6667 2.83335 14.2936 2.83335 13.8333C2.83335 13.3731 2.46026 13 2.00002 13C1.53978 13 1.16669 13.3731 1.16669 13.8333C1.16669 14.2936 1.53978 14.6667 2.00002 14.6667Z"
          stroke="#667085"
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default KebabIcon;
