import * as CONSTANT from '../api.constant';
import { post } from '../apiCalls';

export const getIndividualWeek = async () => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_SURFACE_V2}/leaderboard/getIndividualByWeek`,
      {},
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

export const getIndividualOverall = async () => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_SURFACE_V2}/leaderboard/getIndividualOverAll`,
      {},
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

export const getDepartmentWeek = async () => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_SURFACE_V2}/leaderboard/getDepartmentByWeek`,
      {},
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

export const getDepartmentOverall = async () => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_SURFACE_V2}/leaderboard/getDepartmentOverAll`,
      {},
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

export const getLeaderWidgets = async (payload) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_SURFACE_V2}/leaderboard/getWidgets`,
      payload,
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};
