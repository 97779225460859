const ArrowLeftIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={14}
    height={14}
    fill="none"
    {...props}
  >
    <path
      stroke={props.stroke || '#344054'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.667}
      d="M12.833 7H1.167m0 0L7 12.834M1.167 7 7 1.167"
    />
  </svg>
);
export default ArrowLeftIcon;
