import { ArchiveBoxIcon } from '@heroicons/react/24/outline';
import { archiveBenchmarkPlan } from 'apiClient/rangeBuilder/rangeBuilder';
import PopUp from 'components/atoms/popUp/PopUp';
import { setToastMessage } from 'pages/recognition/redemption/helper';
import { useState } from 'react';
import ConfirmModalPopup from '../ConfirmModalPopUp';

export const PlanPopUp = ({ setPopUpVisible, activePopUpId, getAllPlans }) => {
  const [archivePopUpVisible, setArchivePopUpVisible] = useState(false);
  const [archiveLoading, setArchiveLoading] = useState(false);

  const handleArchive = async () => {
    try {
      setArchiveLoading(true);
      await archiveBenchmarkPlan(activePopUpId);
      await getAllPlans('');
      setToastMessage('Plan archived successfully!');
      setArchiveLoading(false);
      setArchivePopUpVisible(false);
    } catch (error) {
      console.error(error);
      setArchiveLoading(false);
      setArchivePopUpVisible(false);
      setToastMessage('Something went wrong!');
    }
  };

  return (
    <PopUp right="5" closePopup={() => setPopUpVisible(false)}>
      <div className="w-64 p-2 px-4 space-y-2">
        <p
          className="cursor-pointer flex items-center text-sm font-medium text-gray-700 gap-2 py-1"
          onClick={() => setArchivePopUpVisible(true)}
        >
          <ArchiveBoxIcon className="w-5 h-5" />
          Archive
        </p>
      </div>
      {archivePopUpVisible && (
        <ConfirmModalPopup
          onClick={handleArchive}
          setModalVisible={setArchivePopUpVisible}
          loading={archiveLoading}
          confirmType="ARCHIVE"
          type="plan"
        />
      )}
    </PopUp>
  );
};
