import React from 'react';
import ViewHeadingAtom from '../viewHeadingAtom/ViewHeadingAtom';
import ViewParagraphAtom from '../viewParagraphAtom/ViewParagraphAtom';
import ViewButtonAtom from '../viewButtonAtom/ViewButtonAtom';

const ViewHPBMolecule = (props) => {
  const { columnData, card } = props;
  return (
    <div
      style={{ justifyContent: columnData?.attr?.flex?.justify ?? 'center' }}
      className={
        card &&
        `ring-1 ring-gray-200 rounded-lg shadow-md p-5 h-full flex flex-col gap-3`
      }
    >
      <ViewHeadingAtom columnData={columnData} />
      <ViewParagraphAtom columnData={columnData} />
      <ViewButtonAtom columnData={columnData} />
    </div>
  );
};

export default ViewHPBMolecule;
