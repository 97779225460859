import RenderDashboardChartJs from './chartjsCharts/RenderDashboardChartJs';
import RenderNivoCharts from './nivoCharts/RenderNivoCharts';

const RenderDashboardCharts = ({
  chartType,
  datasets,
  displayLegend,
  selectedPalete,
}) => {
  switch (chartType) {
    case 'Heatmap':
      return (
        <RenderNivoCharts
          datasets={datasets || {}}
          chartType={chartType}
          displayLegend={displayLegend}
        />
      );
    case 'Geomap':
      return (
        <RenderNivoCharts
          datasets={datasets || {}}
          chartType={chartType}
          displayLegend={displayLegend}
        />
      );
    case 'Treemap':
      return (
        <RenderNivoCharts datasets={datasets || {}} chartType={chartType} />
      );
    default:
      return (
        <RenderDashboardChartJs
          selectedPalete={selectedPalete}
          datasets={datasets || {}}
          chartType={chartType}
          displayLegend={displayLegend}
        />
      );
  }
};

export default RenderDashboardCharts;
