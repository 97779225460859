import { RadioButton } from 'components/atoms/FormElements/input/Input';
import React from 'react';

const BenchmarkFilter = ({
  benchmarkList,
  selectedBenchmark,
  setSelectedBenchmark,
}) => {
  return (
    <div className="flex items-center gap-2">
      <p className="text-sm font-medium text-gray-600">Benchmarks:</p>
      <div className="flex items-center text-sm font-medium text-gray-600 gap-[2px]">
        {benchmarkList?.map((benchmark) => {
          return (
            <RadioButton
              id={benchmark?.value}
              key={benchmark?.value}
              checked={selectedBenchmark?.value === benchmark?.value}
              label={benchmark?.label}
              onChange={() => {
                if (selectedBenchmark?.value === benchmark?.value) {
                  setSelectedBenchmark(null);
                } else {
                  setSelectedBenchmark(benchmark);
                }
              }}
            />
          );
        })}
      </div>
    </div>
  );
};

export default BenchmarkFilter;
