import * as CONSTANT from '../api.constant';
import { get, put } from '../apiCalls';

export const getNotificationCategory = async () => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_SURFACE_V2}/notification/get-notification-category`,
    );
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getCategoryDetails = async (id) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_SURFACE_V2}/notification/get-user-notification-category-details/${id}?relation=notificationSubCategories`,
    );
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchInAppUnreadNotificationAPI = async () => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_SURFACE_V2}/notification/get-in-app-notification?status=UNREAD`,
    );
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchInAppReadNotificationAPI = async () => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_SURFACE_V2}/notification/get-in-app-notification?status=READ`,
    );
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchInAppNotificationAPI = async () => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_SURFACE_V2}/notification/get-in-app-notification?status=UNREAD,READ`,
    );
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const updateUserNotificationPreferenceAPI = async ({
  disabledChannels,
  notificationSubCategoryId,
  type,
}) => {
  try {
    const response = await put(
      `${CONSTANT.API_URL}/${CONSTANT.API_SURFACE_V2}/notification/update-user-notification-preference`,
      {
        disabledChannels,
        notificationSubCategoryId,
        type,
      },
    );
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const updateBulkUserNotificationPreferenceAPI = async ({
  disabledChannels,
  notificationCategoryId,
}) => {
  try {
    const response = await put(
      `${CONSTANT.API_URL}/${CONSTANT.API_SURFACE_V2}/notification/update-bulk-user-notification-preference`,
      {
        disabledChannels,
        notificationCategoryId,
      },
    );
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const updateInAppUserNotificationAPI = async (notificationIds) => {
  try {
    const response = await put(
      `${CONSTANT.API_URL}/${CONSTANT.API_SURFACE_V2}/notification/update-in-app-notification`,
      {
        notificationIds,
      },
    );
    return response;
  } catch (error) {
    throw new Error(error);
  }
};
