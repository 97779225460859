function UserNameAvatar({
  userName,
  imageSize = 40,
  className: parentClass,
  ...rest
}) {
  return (
    <div
      className={`rounded-full border bg-gradient-to-br from-primaryLeft to-primaryRight text-white font-semibold text-sm cursor-default  flex justify-center items-center ${parentClass}`}
      {...rest}
      style={{ width: imageSize + 'px', height: imageSize + 'px' }}
    >
      {userName?.split(' ')?.[0]?.[0]}
      {userName?.split(' ')?.[1]?.[0]}
    </div>
  );
}

export default UserNameAvatar;
