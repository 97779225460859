import { filterInitialState } from 'pages/recognition/redemption/constants';

const { createSlice } = require('@reduxjs/toolkit');

const initialState = {
  orders: [],
  vouchers: [],
  selectedFilters: filterInitialState,
  searchedValue: '',
  wallets: [],
  transactions: [],
  totalProducts: 0,
  banners: [],
  filterFields: [],
};

const redemptionSlice = createSlice({
  name: 'marketplace',
  initialState,
  reducers: {
    addToCart: (state, action) => {
      state.orders = [...state.orders, { ...action.payload.order }];
    },
    removeFromCart: (state, action) => {
      state.orders = state.orders.filter((order) =>
        order.id !== action.payload.order.id
          ? true
          : order.amount !== action.payload.order.amount,
      );
    },
    updateQuantity: (state, action) => {
      state.orders = state.orders.map((order) => {
        if (
          order.id === action.payload.order.id &&
          order.amount === action.payload.order.amount
        ) {
          return { ...action.payload.order };
        }
        return order;
      });
    },
    setVouchers: (state, action) => {
      state.vouchers = [...action.payload.vouchers];
    },
    setCart: (state, action) => {
      state.orders = [...action.payload.orders];
    },
    setSelectedFilters: (state, action) => {
      state.selectedFilters = action.payload;
    },
    setSearchedValue: (state, action) => {
      state.searchedValue = action.payload;
    },
    setWallets: (state, action) => {
      state.wallets = action.payload;
    },
    setTransaction: (state, action) => {
      state.transactions = action.payload;
    },
    setTotalProducts: (state, action) => {
      state.totalProducts = action.payload;
    },
    setBanners: (state, action) => {
      state.banners = action.payload;
    },
    setFilterFields: (state, action) => {
      state.filterFields = action.payload;
    },
  },
});

export const {
  addToCart,
  removeFromCart,
  updateQuantity,
  setVouchers,
  setCart,
  setSelectedFilters,
  setSearchedValue,
  setTransaction,
  setTotalProducts,
  setWallets,
  setBanners,
  setFilterFields,
} = redemptionSlice.actions;
export default redemptionSlice.reducer;
export const redemptionSelector = (state) => state.redemption;
