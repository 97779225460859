import { Disclosure } from '@headlessui/react';
import {
  ArrowUpTrayIcon,
  ChevronDownIcon,
  EyeIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import ClockRewind from 'assets/svg/ClockRewind';
import UploadCloud from 'assets/svg/UploadCloud';
import DatePicker from 'components/atoms/FormElements/datepicker/DatePicker';
import { Input } from 'components/atoms/FormElements/input/Input';
import Select from 'components/atoms/FormElements/select/Select';
import Button from 'components/atoms/button/Button';
import FileUploader from 'pages/policy/create/mainPanel/policySteps/FileUploader';
import { useEffect, useState } from 'react';
import PreviewModal from './sourceActions/PreviewModal';
import {
  useDeleteFile,
  useGetAllFIles,
  useGetReversonHistory,
} from 'query/dashboard/ManageDashboard';
import moment from 'moment';
import {
  addFiles,
  deleteFile,
  refreshFile,
  updateFile,
} from 'apiClient/dashboardBuilder/DashboardBuilder';
import { onResponse } from 'utils/toastMessages';
import CircularLoaderSmall from 'assets/svg/CircularLoaderSmall';
import TableSkeleton from 'components/atoms/skeletonLoaderTypes/TableSkeleton';
import FileUploadDownloadPopup from 'components/molecules/FileUpload_Download_Popup/FileUploadDownloadPopup';
import { Form } from 'react-router-dom';
import { RefreshIcon } from 'assets/svg/refreshIcon';

export default function DashboardSource({
  dashboardId,
  setVersionModalOpen,
  selectedPage,
  setSelectedFileId,
  selectedVersionId,
  module,
}) {
  const [isPreviewSelected, setIsPreviewSelected] = useState(false);
  const [isDeleting, setIsDeleting] = useState([]);
  const [sourceFiles, setSourceFiles] = useState([]);
  const [previewDataId, setPreviewDataId] = useState(null);
  const [isUpdateFile, setIsUpdateFile] = useState({});
  const [isRefresh, setIsRefresh] = useState(false);

  const {
    data,
    isLoading,
    isError,
    refetch: getFiles,
  } = useGetAllFIles(dashboardId);

  useEffect(() => {
    if (data) {
      setSourceFiles(data);
    }
  }, [data]);

  const handleFileInput = (files) => {
    setSourceFiles((prev) => {
      const updatedFiles = [...prev, files];
      handleSave(updatedFiles);
      return updatedFiles;
    });
  };

  const handleSave = async (updatedSourceFiles) => {
    const formData = new FormData();
    let newFiles = [];

    updatedSourceFiles?.forEach((file) => {
      if (!file?.id) {
        formData.append('media', file);
        newFiles.push(file?.name);
      }
    });

    formData.append(
      'filesMetadata',
      JSON.stringify(
        updatedSourceFiles
          ?.filter((file) => !file?.id)
          ?.map((file) => ({ name: file?.name })),
      ),
    );

    try {
      const res = await addFiles(dashboardId, formData);
      const updatedFiles = await getFiles();
      const newFile = updatedFiles?.data?.find((file) =>
        newFiles?.some((newFile) => newFile === file?.name),
      );

      if (newFile) {
        setIsPreviewSelected(true);
        setPreviewDataId(newFile?.id);
      }
    } catch (err) {
      onResponse(err?.message);
    }
  };

  const handleRemoveFile = async (file, index) => {
    setIsDeleting((prev) => {
      const updatedDeleting = [...prev];
      updatedDeleting[index] = true;
      return updatedDeleting;
    });
    if (file?.id) {
      await deleteFile(file?.id, dashboardId);
      setSourceFiles((prev) => prev.filter((item) => item?.id !== file?.id));
      onResponse('File deleted successfully');
    } else {
      setSourceFiles((prev) =>
        prev.filter((item) => item?.name !== file?.name),
      );
    }
    setIsDeleting((prev) => {
      const updatedDeleting = [...prev];
      updatedDeleting[index] = false;
      return updatedDeleting;
    });
  };

  const updateFileData = async (fileId, formData) => {
    try {
      await updateFile(fileId, dashboardId, formData);
    } catch (err) {
      // onResponse(err?.message);
    }
  };

  // const handleRefresh = async () => {
  //   setIsRefresh(true);
  //   try {
  //     await refreshFile(dashboardId, module);
  //   } catch (err) {
  //     setIsRefresh(false);
  //     onResponse(err?.message);
  //   }
  //   setIsRefresh(false);
  // };

  return (
    <>
      <div className="flex flex-col min-h-screen">
        <div className="flex flex-col gap-4 flex-grow -mr-4 pb-2">
          <FileUploader
            acceptedTypes={['.csv']}
            maxSize={50 * 1024 * 1024}
            subText="CSV ( Max size up to 5MB )"
            onFileSelect={(file) => handleFileInput(file)}
          />
          {/* <div className="flex items-center gap-2">
            <span className="text-xs text-gray-700"> Refresh Data </span>
            {isRefresh ? (
              // <div className="-mr-3">
              <CircularLoaderSmall />
            ) : (
              // </div>
              <RefreshIcon onClick={handleRefresh} className="cursor-pointer" />
            )}
          </div> */}
          {isLoading ? (
            <TableSkeleton />
          ) : (
            sourceFiles?.map((file, index) => (
              <div
                key={index}
                className="w-full p-4 bg-gray-50 border rounded-lg border-gray-200"
              >
                <Disclosure>
                  <Disclosure.Button className="group flex w-full items-center justify-between">
                    <span className="text-gray-900 text-base font-semibold">
                      External Data {index + 1}
                    </span>
                    <ChevronDownIcon className="w-5 group-data-[open]:rotate-180" />
                  </Disclosure.Button>
                  <Disclosure.Panel className="flex flex-col gap-2 mt-3">
                    <Input
                      label="File Name"
                      value={file?.name}
                      onChange={(e) =>
                        setSourceFiles((prev) => [
                          ...prev.slice(0, index),
                          { ...prev[index], name: e.target.value },
                          ...prev.slice(index + 1),
                        ])
                      }
                    />
                    <div className="w-full">
                      <DatePicker
                        label="Upload Date"
                        value={
                          moment(file?.createdDate).format('YYYY-MM-DD') ||
                          new Date()
                        }
                        readOnly
                      />
                    </div>
                    <div className="flex justify-between items-center">
                      <div className="flex items-center gap-2">
                        <UploadCloud
                          onClick={() =>
                            setIsUpdateFile({
                              ...isUpdateFile,
                              [file?.id]: true,
                            })
                          }
                          className="cursor-pointer"
                        />
                        <div
                          onClick={() => {
                            setIsPreviewSelected(true);
                            setPreviewDataId(file?.id);
                          }}
                        >
                          <EyeIcon className="w-3.5 h-3.5 text-gray-600 cursor-pointer" />
                        </div>
                        <div
                          onClick={() => {
                            setSelectedFileId(file?.id);
                            setVersionModalOpen(true);
                          }}
                          className="cursor-pointer"
                        >
                          <ClockRewind />
                        </div>
                      </div>
                      {isDeleting[index] ? (
                        <div className="-mr-3">
                          <CircularLoaderSmall />
                        </div>
                      ) : (
                        <TrashIcon
                          className="w-3.5 h-3.5 text-gray-600 cursor-pointer"
                          onClick={() => handleRemoveFile(file, index)}
                        />
                      )}
                    </div>
                    {isUpdateFile[file?.id] && (
                      <FileUploadDownloadPopup
                        title={
                          <div>
                            <div className=" rounded-lg p-2 border-2 border-partition">
                              <ArrowUpTrayIcon className="w-6 h-6 text-red-400 " />
                            </div>
                          </div>
                        }
                        description={
                          <div className="pb-4">
                            <div className="text-lg font-semibold">
                              Update File Data
                            </div>
                          </div>
                        }
                        refreshData={getFiles}
                        setPopupVisible={setIsUpdateFile}
                        // downloadTemplateApi={() => downloadFile(table?.value)}
                        downloadFileTemplateName={file?.name}
                        uploadApi={(formData) => {
                          const fileData = formData.get('excel_file');
                          console.log(fileData);
                          const newFormData = new FormData();
                          newFormData.append('media', fileData);
                          newFormData.append('name', fileData?.name);
                          return updateFileData(file?.id, newFormData);
                        }}
                        onUploadSuccess={() => {
                          getFiles();
                          onResponse('File uploaded successfully');
                          setIsUpdateFile({
                            ...isUpdateFile,
                            [file?.id]: false,
                          });
                        }}
                      />
                    )}
                  </Disclosure.Panel>
                </Disclosure>
              </div>
            ))
          )}
        </div>
        {/* <div className=" sticky bottom-0 flex flex-col pt-3 gap-4 pr-6 bg-white -mr-4">
          <hr className="bg-gray-200" />
          <div className="text-right">
            <Button onClick={handleSave}>Save</Button>
          </div>
        </div> */}
        {isPreviewSelected && (
          <PreviewModal
            setIsPreviewSelected={setIsPreviewSelected}
            isPreviewSelected={isPreviewSelected}
            dashboardId={dashboardId}
            previewDataId={previewDataId}
          />
        )}
      </div>
    </>
  );
}
