import {
  getEmployeesData,
  getEmployeesDataByCategory,
  getEmployeesDataFilter,
} from 'apiClient/rangeBuilder/rangeBuilder';
import { useQuery } from 'react-query';

export const useGetEmployeesData = (
  planId,
  pageSize,
  pageIndex,
  status,
  query,
  category,
) =>
  useQuery(
    ['get/employeesData', planId, pageSize, pageIndex, status, query, category],
    async () => {
      const res = await getEmployeesData(
        planId,
        pageSize,
        pageIndex,
        status,
        query,
      );
      return res?.data;
    },
    {
      enabled: !category,
    },
  );

export const useGetEmployeesDataFilter = (planId, benchmarkId) =>
  useQuery(
    ['get/employeesDataFilter', planId, benchmarkId],
    async () => {
      const res = await getEmployeesDataFilter(planId, benchmarkId);
      return res?.data;
    },
    {
      enabled: !!benchmarkId,
    },
  );

export const useGetEmployeesDataByCategory = (
  planId,
  benchmarkId,
  pageSize,
  pageIndex,
  category,
  query,
) =>
  useQuery(
    [
      'get/employeesDataByCategory',
      planId,
      benchmarkId,
      pageSize,
      pageIndex,
      category,
      query,
    ],
    async () => {
      const res = await getEmployeesDataByCategory(
        planId,
        benchmarkId,
        pageSize,
        pageIndex,
        category,
        query,
      );
      return res?.data;
    },
    {
      enabled: !!benchmarkId && !!category,
    },
  );
