import { getConfig } from 'apiClient/variablePay/variablePay';
import {
  ConfigData,
  ConfigureClawbackEnum,
  EnableProrationEnum,
  EventsSettings,
  PayoutAmountsEnum,
} from 'pages/variablePay/VariablePay.types';
import { useEffect, useState } from 'react';
import { AchievementsConfiguration } from './AchievementsConfiguration/AchievementsConfiguration';
import { EventConfiguration } from './EventConfiguration/EventConfiguration';
import { PayoutConfiguration } from './PayoutConfigurations/PayoutConfiguration';

export const Events = () => {
  const [configData, setConfigData] = useState<ConfigData>({
    PayoutConfiguration: [],
    AchievementsConfiguration: [],
  });

  const [eventSettings, setEventSettings] = useState<EventsSettings>({
    vPayPayoutAmounts: { value: PayoutAmountsEnum.PRECALCULATED },
    vPayEnableProration: {
      value: [
        EnableProrationEnum.BASED_ON_EMPLOYEE_DATA,
        EnableProrationEnum.PRORATION_OCCURENCE,
      ],
    },
    vPayEnableClawback: {
      value: [
        ConfigureClawbackEnum.CONFIGURE_CLAWBACK,
        ConfigureClawbackEnum.CONFIGURE_PRORATION_CLAWBACK,
      ],
    },
  });

  useEffect(() => {
    (async () => {
      try {
        const response = await getConfig();
        setConfigData(response?.data);
      } catch (error) {
        setConfigData({});
      }
    })();
  }, []);

  return (
    <div className="pt-6 px-8">
      <div className="pt-5 pb-6 px-6 bg-white rounded-xl shadow-md border border-gray-200 space-y-5">
        <p className="text-lg text-gray-900 font-semibold">Events</p>
        <div className="border border-gray-200 rounded-xl bg-gray-50 p-5 space-y-4">
          <PayoutConfiguration
            eventSettings={eventSettings}
            setEventSettings={setEventSettings}
            configuration={
              configData?.PayoutConfiguration?.length
                ? configData?.PayoutConfiguration?.[0]
                : {}
            }
          />
          {eventSettings?.vPayPayoutAmounts?.value ===
            PayoutAmountsEnum.LOGIC_BASED_CALCULATION && (
            <AchievementsConfiguration
              eventSettings={eventSettings}
              setEventSettings={setEventSettings}
              configuration={
                configData?.AchievementsConfiguration?.length
                  ? configData?.AchievementsConfiguration?.[0]
                  : {}
              }
            />
          )}
          <EventConfiguration
            eventSettings={eventSettings}
            setEventSettings={setEventSettings}
            configuration={
              configData?.EventConfiguration?.length
                ? configData?.EventConfiguration?.[0]
                : {}
            }
          />
        </div>
      </div>
    </div>
  );
};
