const PermissionControlsIcon = (props) => (
  <svg
    width={props?.width ? props?.width : '20'}
    height={props?.height ? props?.height : '20'}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.3333 17.4999V16.4999C13.3333 15.0998 13.3333 14.3997 13.0608 13.8649C12.8212 13.3945 12.4387 13.0121 11.9683 12.7724C11.4335 12.4999 10.7335 12.4999 9.33332 12.4999H5.66666C4.26653 12.4999 3.56646 12.4999 3.03168 12.7724C2.56128 13.0121 2.17882 13.3945 1.93914 13.8649C1.66666 14.3997 1.66666 15.0998 1.66666 16.4999V17.4999M13.3333 4.99992L15 6.66659L18.3333 3.33325M10.4167 6.24992C10.4167 7.86075 9.11082 9.16659 7.49999 9.16659C5.88916 9.16659 4.58332 7.86075 4.58332 6.24992C4.58332 4.63909 5.88916 3.33325 7.49999 3.33325C9.11082 3.33325 10.4167 4.63909 10.4167 6.24992Z"
      stroke={props.isActive ? props.activeColor : '#475467'}
      strokeWidth="1.67"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default PermissionControlsIcon;
