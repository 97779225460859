// import React from 'react';
// import { htmlParser } from 'utils/jsUtils';

// const ViewParagraphAtom = (props) => {
//   const { columnData } = props;

//   const flex = columnData?.attr?.flex?.justify;
//   const unescapeHtml = (escapedStr) => {
//     const textarea = document.createElement('textarea');
//     textarea.innerHTML = escapedStr;
//     return textarea.value;
//   };
//   const escapedHtmlString = columnData?.attr?.para?.value;
//   const unescapedHtmlString = unescapeHtml(escapedHtmlString);
//   return (
//     <div
//       style={{ justifyContent: flex }}
//       className={'flex flex-col break-words pb-4'}
//     >
//       <div dangerouslySetInnerHTML={{ __html: unescapedHtmlString }} />
//     </div>
//   );
// };

// export default ViewParagraphAtom;

import React from 'react';
import { sanitizeHtml, unescapeHtml } from 'utils/unescapeAndSanitizeHtml';

const ViewParagraphAtom = (props) => {
  const { columnData } = props;

  const flex = columnData?.attr?.flex?.justify;

  const escapedHtmlString = columnData?.attr?.para?.value;
  const unescapedHtmlString = unescapeHtml(escapedHtmlString);
  const sanitizedHtmlString = sanitizeHtml(unescapedHtmlString);

  return (
    <div
      style={{ justifyContent: flex }}
      className={'flex flex-col break-words pb-4'}
    >
      <div dangerouslySetInnerHTML={{ __html: sanitizedHtmlString }} />
    </div>
  );
};

export default ViewParagraphAtom;
