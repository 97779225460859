/**
 * Component to display a chip with a label and status color.
 * @param {Object} props - Component props
 * @param {string} props.variant - Variant of the chip (e.g., 'Large', 'Medium', 'Small', 'ExtraSmall')
 * @param {string} props.status - Status value (e.g., 'rejected', 'accepted', 'InProgress')
 * @param {string} props.label - Label to display on the chip
 * @return {JSX.Element} ChipsLabels component
 */
const ChipsLabels = ({ variant, status, label, className }) => {
  let variantClass;
  let colorClass;
  switch (status) {
    case 'rejected':
    case 'Rejected':
    case 'Pending':
    case 'In Active':
    case 'OfferDeclind':
    case 'Unenrolled':
      colorClass = 'text-[#D92D20] bg-[#FEF3F2]'; //color for red
      break;
    case 'accepted':
    case 'Approved':
    case 'Completed':
    case 'Active':
    case 'OfferAccepted':
    case 'OfferReleased':
    case 'Enrolled':
    case 'Accepted':
    case 'Paid':
      colorClass = 'text-[#039855] bg-[#E3FCEC]'; //color for green
      break;
    case 'InProgress':
    case 'In Progress':
    case 'PendingReview':
    case 'PendingApproval':
    case 'Partly Vested':
    case 'Payout Pending':
      colorClass = 'text-[#EA9326] bg-[#FEF4E2]'; //color for yellow
      break;
    case 'Vested':
      colorClass = 'text-[#F63D68] bg-[#FFF1F3]';
      break;
    case 'Exercised':
      colorClass = 'text-[##22CCEE] bg-[#ECFDFF]';
      break;
    // case '':
    //   colorClass = 'text-[#475467] bg-[#F0F1F4]'; //color for gray
    //   break;

    default:
      colorClass = 'text-[#475467] bg-[#F0F1F4]';
      break;
  }
  // font-size hardcoding as per the figma designs
  switch (variant) {
    case 'Large':
      variantClass = 'py-0.5 px-3 text-sm'; // padding for large variant
      break;
    case 'Medium':
      variantClass = 'py-0.5 px-3 text-[13px]'; // padding for medium variant
      break;
    case 'Small':
      variantClass = 'py-0.5 px-2 text-[13px]'; // padding for small variant
      break;
    case 'ExtraSmall':
      variantClass = 'py-0.5 px-2 text-xs'; // padding for extra small variant
      break;
    default:
      variantClass = 'py-0.5 px-2 text-xs'; // Default padding
  }
  return (
    <span
      className={`rounded-lg ${variantClass} w-auto ${colorClass} ${className} `}
    >
      {label}
    </span>
  );
};

export default ChipsLabels;
