import { Input } from 'components/atoms/FormElements/input/Input';
import Select from 'components/atoms/FormElements/select/Select';
import Toggle from 'components/atoms/FormElements/toggle/Toggle';
import Button from 'components/atoms/button/Button';
import ModalPopup from 'components/layout/modalPopup/ModalPopup';
import { customStyles } from 'config/rangeBuilderData';
import React, { useEffect, useState } from 'react';

const ColumnsMappingPopUp = ({
  data,
  setIsColumnsMappingPopUp,
  onNextClick,
}) => {
  const [tableData, setTableData] = useState([]);
  const [mappingDropDownValue, setMappingDropDownValue] = useState([]);
  const [errorRowData, setErrorRowData] = useState([]);
  useEffect(() => {
    setTableData(
      data?.templateColumns?.map((templateColumn) => {
        return {
          templateColumn: templateColumn,
          uploadedColumn: null,
          sampleData: null,
          isToggle: templateColumn?.isMandatory,
        };
      }),
    );
    setMappingDropDownValue(
      data?.headers?.map((header) => {
        return {
          label: header,
          value: header,
        };
      }),
    );
  }, [data]);

  const handleSelectChange = (index, selectedOption) => {
    if (tableData?.[index]?.templateColumn?.type === 'PayComponent') {
      if (selectedOption) {
        const newTableData = tableData.map((row, rowIndex) => {
          if (rowIndex === index) {
            return {
              ...row,
              uploadedColumn: selectedOption,
              sampleData: data?.firstRow?.[selectedOption?.value],
              isToggle: selectedOption ? true : false,
              templateColumn: {
                ...row?.templateColumn,
              },
            };
          } else {
            return {
              ...row,
              templateColumn: {
                ...row?.templateColumn,
                isMandatory:
                  row?.templateColumn?.id?.includes(
                    tableData?.[index]?.templateColumn?.id?.split('_')?.[1],
                  ) && row?.templateColumn?.type === 'PayComponent'
                    ? true
                    : row?.templateColumn?.isMandatory,
              },
            };
          }
        });
        setTableData(newTableData);
      } else {
        const cnd1Array = tableData?.filter((row, rowIndex) => {
          if (
            row?.templateColumn?.id?.includes(
              tableData?.[index]?.templateColumn?.id?.split('_')?.[1],
            ) &&
            row?.templateColumn?.type === 'PayComponent' &&
            row?.uploadedColumn
          ) {
            return true;
          } else {
            return false;
          }
        });
        if (cnd1Array?.length > 1) {
          const newTableData = tableData.map((row, rowIndex) => {
            if (rowIndex === index) {
              return {
                ...row,
                uploadedColumn: selectedOption,
                sampleData: data?.firstRow?.[selectedOption?.value],
                isToggle: selectedOption ? true : false,
                templateColumn: {
                  ...row?.templateColumn,
                },
              };
            } else {
              return {
                ...row,
                templateColumn: {
                  ...row?.templateColumn,
                  isMandatory:
                    row?.templateColumn?.id?.includes(
                      tableData?.[index]?.templateColumn?.id?.split('_')?.[1],
                    ) && row?.templateColumn?.type === 'PayComponent'
                      ? true
                      : row?.templateColumn?.isMandatory,
                },
              };
            }
          });
          setTableData(newTableData);
        } else {
          const newTableData = tableData.map((row, rowIndex) => {
            if (rowIndex === index) {
              return {
                ...row,
                uploadedColumn: selectedOption,
                sampleData: data?.firstRow?.[selectedOption?.value],
                isToggle: selectedOption ? true : false,
                templateColumn: {
                  ...row?.templateColumn,
                },
              };
            } else {
              return {
                ...row,
                templateColumn: {
                  ...row?.templateColumn,
                  isMandatory:
                    row?.templateColumn?.id?.includes(
                      tableData?.[index]?.templateColumn?.id?.split('_')?.[1],
                    ) && row?.templateColumn?.type === 'PayComponent'
                      ? false
                      : row?.templateColumn?.isMandatory,
                },
              };
            }
          });
          setTableData(newTableData);
        }
      }
    } else {
      setTableData((prevTableData) => {
        return prevTableData.map((row, rowIndex) => {
          return rowIndex === index
            ? {
                ...row,
                uploadedColumn: selectedOption,
                sampleData: data?.firstRow?.[selectedOption?.value],
                isToggle: selectedOption ? true : false,
              }
            : row;
        });
      });
    }
  };

  const handleToggleChange = (index, value) => {
    console.log(value);
    setTableData((prevTableData) =>
      prevTableData.map((row, rowIndex) =>
        rowIndex === index ? { ...row, isToggle: value } : row,
      ),
    );
  };

  const getFilteredOptions = (index) => {
    const selectedValues = tableData
      .map((row) => row.uploadedColumn?.value)
      .filter(Boolean);
    return mappingDropDownValue.filter(
      (option) =>
        !selectedValues.includes(option.value) ||
        option.value === tableData[index]?.uploadedColumn?.value,
    );
  };

  return (
    <div>
      <ModalPopup
        title={'Map Columns'}
        body={
          <div className="flex flex-col w-full px-4">
            <div className="flex w-full">
              <table className="w-full">
                <thead>
                  <tr className="bg-gray-50 h-7 py-3">
                    <th className="px-4 text-xs text-gray-600 text-start border-r border-blue-100 border-t border-b border-t-gray-300 border-b-gray-300">
                      Template Columns
                    </th>
                    <th className="px-4 text-xs text-gray-600 text-start border-r border-blue-100 border-t border-b border-t-gray-300 border-b-gray-300">
                      Uploaded Columns
                    </th>
                    <th className="px-4 text-xs text-gray-600 text-start border-t border-b border-t-gray-300 border-b-gray-300">
                      Sample Data
                    </th>
                    <th className="px-4 w-11 border-t border-b border-gray-300"></th>
                  </tr>
                </thead>
                <tbody>
                  {tableData?.map((data, index) => {
                    return (
                      <tr
                        key={index}
                        className="border-t border-b border-blue-100 h-7"
                      >
                        <td className="px-4 border-r border-r-blue-100">
                          <p className="text-xs font-normal">
                            {data?.templateColumn?.name}
                            {data?.templateColumn?.isMandatory && (
                              <span className="text-error-500">*</span>
                            )}
                          </p>
                        </td>
                        <td className="px-4 border-r border-r-blue-100">
                          <div className="w-[180px]">
                            <Select
                              placeholder={'Unmapped'}
                              isClearable
                              options={getFilteredOptions(index)}
                              value={data?.uploadedColumn}
                              onChange={(e) => {
                                handleSelectChange(index, e);
                              }}
                              customStyling={customStyles}
                            />
                          </div>
                        </td>
                        <td className="px-4 ">
                          <p className="text-xs font-normal">
                            {data?.sampleData}
                          </p>
                        </td>
                        <td className="">
                          <div className="flex h-full items-center">
                            <Toggle
                              checked={data?.isToggle}
                              disabled={
                                !data?.uploadedColumn ||
                                data?.templateColumn?.isMandatory
                              }
                              height={'h-4'}
                              width={'w-7'}
                              afterClass={'after:h-3 after:w-3'}
                              onChange={(e) => {
                                handleToggleChange(index, e?.target?.checked);
                              }}
                            />
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="flex justify-between py-3 sticky bottom-0 bg-white">
              <div className="flex flex-col w-full gap-3">
                {tableData?.filter(
                  (data) =>
                    data?.templateColumn?.isMandatory && !data?.uploadedColumn,
                )?.length !== 0 && (
                  <p className="font-figtree text-sm text-error-500 ">
                    {
                      tableData?.filter(
                        (data) =>
                          data?.templateColumn?.isMandatory &&
                          !data?.uploadedColumn,
                      )?.length
                    }{' '}
                    field(s) required to match. Match these fields in order to
                    proceed.
                  </p>
                )}
                <div className="flex gap-2">
                  {tableData
                    ?.filter(
                      (data) =>
                        data?.templateColumn?.isMandatory &&
                        !data?.uploadedColumn,
                    )
                    ?.map((data, index) => (
                      <div
                        className="border rounded-2xl py-0.5 px-2 h-[19px] text-xs font-normal text-gray-500 text-center"
                        key={index}
                      >
                        {data?.templateColumn?.name}
                      </div>
                    ))}
                </div>
              </div>
              <div className="flex gap-3">
                <Button onClick={() => setIsColumnsMappingPopUp(false)}>
                  Cancel
                </Button>
                <Button
                  variant="outlined"
                  disabled={
                    tableData?.filter(
                      (data) =>
                        data?.templateColumn?.isMandatory &&
                        !data?.uploadedColumn,
                    )?.length !== 0
                  }
                  onClick={() => {
                    const filteredOptions = getFilteredOptions();
                    onNextClick(tableData, filteredOptions);
                  }}
                >
                  Next
                </Button>
              </div>
            </div>
          </div>
        }
        onClose={() => {
          setIsColumnsMappingPopUp(false);
        }}
        width={'w-[1080px]'}
      />
    </div>
  );
};

export default ColumnsMappingPopUp;
