const CompensationPlanningIcon = ({
  isActive,
  activeColor,
  width,
  height,
  isHovered,
  hoveredColor,
}) => (
  <svg
    width={width ? width : '20'}
    height={height ? height : '20'}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.6667 7.91671V6.00004C16.6667 5.06662 16.6667 4.59991 16.485 4.24339C16.3252 3.92979 16.0703 3.67482 15.7567 3.51503C15.4002 3.33338 14.9334 3.33338 14 3.33337H4.33335C3.39993 3.33337 2.93322 3.33337 2.5767 3.51503C2.2631 3.67482 2.00813 3.92979 1.84834 4.24339C1.66669 4.59991 1.66669 5.06662 1.66669 6.00004V14C1.66669 14.9335 1.66669 15.4002 1.84834 15.7567C2.00813 16.0703 2.2631 16.3253 2.5767 16.485C2.93322 16.6667 3.39993 16.6667 4.33335 16.6667L14 16.6667C14.9334 16.6667 15.4002 16.6667 15.7567 16.4851C16.0703 16.3253 16.3252 16.0703 16.485 15.7567C16.6667 15.4002 16.6667 14.9335 16.6667 14V12.0834M12.5 10C12.5 9.61283 12.5 9.41922 12.532 9.25822C12.6636 8.59707 13.1804 8.08024 13.8415 7.94873C14.0025 7.91671 14.1961 7.91671 14.5834 7.91671H16.25C16.6372 7.91671 16.8308 7.91671 16.9918 7.94873C17.653 8.08024 18.1698 8.59707 18.3013 9.25822C18.3334 9.41922 18.3334 9.61283 18.3334 10C18.3334 10.3873 18.3334 10.5809 18.3013 10.7419C18.1698 11.403 17.653 11.9198 16.9918 12.0513C16.8308 12.0834 16.6372 12.0834 16.25 12.0834H14.5834C14.1961 12.0834 14.0025 12.0834 13.8415 12.0513C13.1804 11.9198 12.6636 11.403 12.532 10.7419C12.5 10.5809 12.5 10.3873 12.5 10Z"
      stroke={isActive ? activeColor : isHovered ? hoveredColor : '#475467'}
      strokeWidth="1.67"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default CompensationPlanningIcon;
