import {
  AchievementMeasurementEnum,
  AttainmentDependencyEnum,
  AttainmentInputsEnum,
  ConfigurationKeysEnum,
  DependentFieldsEnum,
  EventsSettings,
  PayoutAmountsEnum,
} from 'pages/variablePay/VariablePay.types';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Configuration } from '../Configuration';

type AchievementsConfigurationProps = {
  setEventSettings: Dispatch<SetStateAction<EventsSettings>>;
  eventSettings: EventsSettings;
  configuration: { [key: string]: any };
};

export const AchievementsConfiguration = ({
  eventSettings,
  setEventSettings,
  configuration,
}: AchievementsConfigurationProps) => {
  const [achievementsConfiguration, setAchievementsConfiguration] =
    useState(configuration);

  useEffect(() => {
    if (
      eventSettings?.vPayPayoutAmounts?.value ===
        PayoutAmountsEnum.LOGIC_BASED_CALCULATION &&
      !eventSettings?.vPayAttainmentInput
    ) {
      setEventSettings({
        ...eventSettings,
        vPayAttainmentInput: {
          value: AttainmentInputsEnum.PERCENTAGE,
        },
        vPayAttainmentDependency: {
          value: AttainmentDependencyEnum.INDEPENDENT,
        },
        vPayAchievementMeasurement: {
          value: AchievementMeasurementEnum.ATTAINMENT_BASED,
        },
      });
    }
  }, [eventSettings]);

  useEffect(() => {
    if (
      eventSettings?.vPayAttainmentDependency?.value ===
        AttainmentDependencyEnum.DEPENDENT &&
      !eventSettings?.vPayDependentFields
    ) {
      setEventSettings({
        ...eventSettings,
        vPayDependentFields: { value: DependentFieldsEnum.SYSTEM_FIELDS },
      });
    }
  }, [eventSettings]);

  useEffect(() => {
    if (configuration) {
      if (
        eventSettings?.vPayPayoutAmounts?.value ===
          PayoutAmountsEnum.LOGIC_BASED_CALCULATION &&
        eventSettings?.vPayAttainmentDependency?.value !==
          AttainmentDependencyEnum.DEPENDENT
      ) {
        setAchievementsConfiguration({
          ...configuration,
          children: configuration?.children?.filter(
            ({ key }: { key: ConfigurationKeysEnum }) =>
              key !== ConfigurationKeysEnum.vPayDependencyCriteria &&
              key !== ConfigurationKeysEnum.vPayDependentFields,
          ),
        });
      } else {
        setAchievementsConfiguration(configuration);
      }
    }
  }, [eventSettings, configuration]);

  const handleChange = (key: ConfigurationKeysEnum, value: string) => {
    const updatedSettings = eventSettings;
    if (value === AttainmentDependencyEnum.INDEPENDENT) {
      delete updatedSettings?.vPayDependentFields;
    }
    setEventSettings({ ...updatedSettings, [key]: { value } });
  };

  return (
    <Configuration
      eventSettings={eventSettings}
      setEventSettings={setEventSettings}
      configuration={achievementsConfiguration}
      handleChange={handleChange}
    />
  );
};
