export const MarketBenchmarkingIcon = (props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.6666 1.8916V5.33372C11.6666 5.80043 11.6666 6.03378 11.7574 6.21204C11.8373 6.36885 11.9648 6.49633 12.1216 6.57622C12.2999 6.66705 12.5332 6.66705 12.9999 6.66705H16.442M6.66659 12.5003V15.0003M13.3333 10.8337V15.0003M9.99992 8.75033V15.0003M16.6666 8.32385V14.3337C16.6666 15.7338 16.6666 16.4339 16.3941 16.9686C16.1544 17.439 15.772 17.8215 15.3016 18.0612C14.7668 18.3337 14.0667 18.3337 12.6666 18.3337H7.33325C5.93312 18.3337 5.23306 18.3337 4.69828 18.0612C4.22787 17.8215 3.84542 17.439 3.60574 16.9686C3.33325 16.4339 3.33325 15.7338 3.33325 14.3337V5.66699C3.33325 4.26686 3.33325 3.5668 3.60574 3.03202C3.84542 2.56161 4.22787 2.17916 4.69828 1.93948C5.23306 1.66699 5.93312 1.66699 7.33325 1.66699H10.0097C10.6212 1.66699 10.9269 1.66699 11.2147 1.73607C11.4698 1.79731 11.7136 1.89832 11.9373 2.03539C12.1896 2.19 12.4058 2.40619 12.8382 2.83857L15.495 5.49542C15.9274 5.9278 16.1436 6.14399 16.2982 6.39628C16.4353 6.61996 16.5363 6.86382 16.5975 7.11891C16.6666 7.40663 16.6666 7.71237 16.6666 8.32385Z"
        stroke={props.isActive ? props.activeColor : '#475467'}
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
