import { useGetQuickLinks } from 'query/surface/manageHome';
import React from 'react';
import { imageURLCheck } from 'utils/ImageUrlCheck';

const QuickLinks = () => {
  const { data: quickLinks, isLoading: quickLinksLoading } = useGetQuickLinks({
    isAdmin: false,
  });
  return (
    <>
      {quickLinks?.quickLinks?.length > 0 && (
        <div className="space-y-4">
          <p className="text-gray-900 text-lg font-semibold border-b border-gray-200">
            Quick Links
          </p>
          <div className="flex gap-4 w-full h-full items-start flex-wrap">
            {quickLinks?.quickLinks?.map((ql, index) => (
              <div
                key={index + 897}
                className="flex flex-col justify-center gap-3 w-[110px] max-h-[120px] cursor-pointer"
                onClick={() => {
                  window.open(ql?.url, '_blank');
                }}
              >
                <div className="flex max-w-[110px] justify-center">
                  <div className="flex p-2.5 rounded-lg border border-gray-200 shadow-sm w-14 h-14 justify-center">
                    <img
                      src={imageURLCheck(ql?.icon?.url)}
                      alt="Icon"
                      className="w-8 h-8"
                    />
                  </div>
                </div>
                <p className="text-sm font-semibold text-gray-600 text-center w-full">
                  {ql?.name}
                </p>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default QuickLinks;
