import { rbacFlags } from 'config/rbacFlags';
import { AlternateScreenV2 } from 'pages/genericPages/alternateScreenV2/AlternateScreenV2';
import { lazy } from 'react';

const IndividualAnnouncement = lazy(() =>
  import('pages/surface/announcement/Announcement'),
);
const Announcements = lazy(() =>
  import('pages/surface/announcements/Announcements'),
);
const Celebrate = lazy(() => import('pages/surface/celebrate/Celebrate'));
const CreateNewEvent = lazy(() =>
  import('pages/surface/events/CreateNewEvent'),
);
const Events = lazy(() => import('pages/surface/events/Events'));
const IndividualEvent = lazy(() =>
  import('pages/surface/events/IndividualEvent'),
);
const IndvidualFeedCard = lazy(() => import('pages/surface/post/Post'));
const Surface = lazy(() => import('pages/surface/Surface'));
const SavedPosts = lazy(() => import('pages/surface/savedPosts/SavedPosts'));
const Tempo = lazy(() => import('pages/surface/surveys/Tempo'));
const SurveyResponse = lazy(() =>
  import('pages/surface/surveys/surveyResponse/SurveyResponse'),
);
const CommunityListing = lazy(() =>
  import('pages/surface/communities/communities/Communities'),
);
const Surveys = lazy(() => import('pages/surface/surveys/Surveys'));
const MyCommunity = lazy(() =>
  import('pages/surface/communities/myCommunities/MyCommunities'),
);
const CreateSurvey = lazy(() =>
  import('pages/surface/surveys/CreateSurvey/CreateSurvey'),
);

const SurfaceRoutes = [
  {
    type: 'collapse',
    name: 'Surface',
    key: 'Surface',
    route: '/surface',
    module: 'Surface',
    parentId: null,
    component: <Surface />,
  },
  {
    type: 'collapse',
    name: 'allevents',
    key: 'allEvents',
    route: '/events',
    module: 'Surface',
    subModule: 'Events',
    parentId: 'Surface',
    component: <Events />,
  },
  {
    type: 'collapse',
    name: 'individualEvent',
    key: 'individualEvent',
    route: 'events/event/:id',
    module: 'Surface',
    subModule: 'Events',
    parentId: 'Surface',
    component: <IndividualEvent />,
  },
  {
    type: 'collapse',
    name: 'eventsCreateNew',
    key: 'createNewEvent',
    route: '/events/create',
    module: 'Surface',
    subModule: 'Events',
    parentId: 'Surface',
    component: <CreateNewEvent />,
  },
  {
    type: 'collapse',
    name: 'post',
    key: 'post/:id',
    route: '/post/:id',
    module: 'Surface',
    parentId: 'SurfacePost',
    component: <IndvidualFeedCard />,
  },
  {
    type: 'collapse',
    name: 'celebrate',
    key: 'celebrate/:params',
    route: '/celebrate/:params',
    parentId: 'SurfaceCelebrate',
    module: 'Surface',
    component: <Celebrate />,
  },
  {
    type: 'collapse',
    name: 'announcement',
    key: 'announcement/:id',
    route: '/announcement/:id',
    module: 'SurfaceAnnouncement',
    subModule: 'Announcement',
    parentId: 'SurfaceAnnoucement',
    component: <IndividualAnnouncement />,
  },
  {
    type: 'collapse',
    name: 'announcements',
    key: 'announcement',
    module: 'SurfaceAnnouncement',
    subModule: 'Announcement',
    route: '/surface/announcements/:type',
    parentId: 'SurfaceAnnoucement',
    component: <Announcements />,
  },
  {
    type: 'collapse',
    name: 'tempo',
    key: 'tempo',
    parentId: 'ManageCreatesurveys',
    flagName: rbacFlags.ManageCreatesurveys,
    module: 'SurfaceSurvey',
    subModule: 'Survey',
    route: '/surveys',
    component: <Tempo />,
  },
  {
    type: 'collapse',
    name: 'survey',
    key: 'surveys',
    parentId: 'ManageCreatesurveys',
    flagName: rbacFlags.ManageCreatesurveys,
    module: 'SurfaceSurvey',
    subModule: 'Survey',
    route: '/surveys/:type',
    component: <Surveys />,
  },
  {
    type: 'collapse',
    name: 'createSurvey',
    key: 'createSurvey',
    parentId: 'ManageCreatesurveys',
    flagName: rbacFlags.ManageCreatesurveys,
    module: 'SurfaceSurvey',
    subModule: 'Survey',
    route: '/surveys/create',
    component: <CreateSurvey />,
  },
  {
    type: 'collapse',
    name: 'updateSurvey',
    parentId: 'ManageCreatesurveys',
    flagName: rbacFlags.ManageCreatesurveys,
    key: 'updateSurvey',
    module: 'SurfaceSurvey',
    subModule: 'Survey',
    route: '/surveys/update/:id',
    component: <CreateSurvey />,
  },
  {
    type: 'collapse',
    name: 'surveyResponse',
    module: 'SurfaceSurvey',
    // subModule: 'Survey',
    key: 'SurveyResponse',
    parentId: 'ManageCreatesurveys',
    // flagName: 'ManageCreatesurveys',
    route: '/surveys/response/:id',
    component: <SurveyResponse />,
  },
  {
    type: 'collapse',
    name: 'Communities',
    key: 'groups-communities',
    route: '/communities',
    module: 'Surface',
    subModule: 'Communities',
    parentId: 'ManageCommunities',
    flagName: rbacFlags.ManageCommunities,
    component: <MyCommunity />,
  },
  {
    type: 'collapse',
    name: 'Communities',
    key: 'groups-communities',
    module: 'Surface',
    subModule: 'Communities',
    route: '/communities/:id',
    parentId: 'ManageCommunities',
    flagName: rbacFlags.ManageCommunities,
    component: <MyCommunity />,
  },
  {
    type: 'collapse',
    name: 'AllCommunities',
    key: 'communityLisitng',
    module: 'Surface',
    subModule: 'Communities',
    parentId: 'ManageCommunities',
    flagName: rbacFlags.ManageCommunities,
    route: '/communities/all-communities',
    component: <CommunityListing />,
  },
  {
    type: 'collapse',
    name: 'SavedPosts',
    key: 'SavedPosts',
    route: '/saved-posts',
    module: 'Task',
    component: <SavedPosts />,
  },

  {
    type: 'collapse',
    name: 'SavedPosts',
    key: 'SavedPosts',
    route: '/alternate',
    module: 'Task',
    component: <AlternateScreenV2 />,
  },
  {
    type: 'collapse',
    name: 'home',
    key: 'home',
    route: '/home',
    component: <AlternateScreenV2 />,
  },
];

export default SurfaceRoutes;
