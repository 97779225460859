import { lazy } from 'react';

const Profile = lazy(() => import('pages/profile/Profile'));

const ProfileRoutes = [
  {
    type: 'collapse',
    name: 'Profile',
    key: 'Profile',
    // icon: <Icon fontSize="small">assignment</Icon>,
    route: '/profile/:id',
    component: <Profile />,
    module: 'Profile',
    parentId: null,
    children: [
      {
        type: 'summary',
        name: 'Summary',
        key: 'Summary',
        route: 'summary',
      },
    ],
  },
  {
    type: 'collapse',
    name: 'Profile',
    key: 'Profile',
    parentId: null,
    module: 'Profile',
    // icon: <Icon fontSize="small">assignment</Icon>,
    route: '/profile/:id/:tab',
    component: <Profile />,
  },
];
export default ProfileRoutes;
