import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

const ViewHeadingAtom = (props) => {
  const { columnData } = props;
  const metaData = columnData?.attr?.heading?.metaData;
  const navigate = useNavigate();
  return (
    <div
      style={{ justifyContent: columnData?.attr?.flex?.justify ?? 'center' }}
    >
      {metaData?.link ? (
        <div onClick={() => navigate('/' + metaData?.link)}>
          <h1
            style={{
              fontSize: metaData?.fontSize ?? 28,
              color: metaData?.color,
              textAlign: metaData?.alignment ?? 'start',
              fontWeight: metaData?.fontWeight ?? 'normal',
              fontStyle: metaData?.fontStyle ?? 'normal',
            }}
            className="leading-8"
          >
            {columnData?.attr?.heading?.value}
          </h1>
        </div>
      ) : (
        <h1
          style={{
            fontSize: metaData?.fontSize ?? 28,
            color: metaData?.color,
            textAlign: metaData?.alignment ?? 'start',
            fontWeight: metaData?.fontWeight ?? 'normal',
            fontStyle: metaData?.fontStyle ?? 'normal',
          }}
          className="leading-8"
        >
          {columnData?.attr?.heading?.value}
        </h1>
      )}
    </div>
  );
};

export default ViewHeadingAtom;
