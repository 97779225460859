import React, { useCallback, useEffect, useState } from 'react';
import { useExpanded, useTable } from 'react-table';
import './Table.styles.scss';

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    );
  },
);

const Table = (props) => {
  const {
    data,
    columns,
    updateMyData,
    isExpandable,
    isSingleRowExpand,
    renderRowSubComponent,
    isSelectable,
    headerBackgroundColor,
    footer,
    myRow,
    headerClassName,
    rowProps = () => ({}),
    ...rest
  } = props;

  const [expandedRowIndex, setExpandedRowIndex] = useState(null);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    visibleColumns,
    footerGroups,
  } = useTable(
    {
      data,
      columns,
      updateMyData,
    },
    isExpandable && useExpanded,
  );

  const toggleRowExpanded = useCallback((rowIndex) => {
    setExpandedRowIndex((prevIndex) =>
      prevIndex === rowIndex ? null : rowIndex,
    );
  }, []);

  useEffect(() => {
    if (isExpandable && isSingleRowExpand) {
      rows.forEach((row, index) => {
        if (index === expandedRowIndex) {
          row.toggleRowExpanded(true);
        } else {
          row.toggleRowExpanded(false);
        }
      });
    }
  }, [expandedRowIndex, rows, isExpandable, isSingleRowExpand]);

  return (
    <React.Fragment>
      <table {...getTableProps()} className="w-full table pb-8">
        <thead className={headerClassName}>
          {headerGroups.map((headerGroup, index) => {
            const { key, ...restHeaderGroupProps } =
              headerGroup.getHeaderGroupProps();
            return (
              <tr key={key || index} {...restHeaderGroupProps}>
                {headerGroup.headers.map((column, i) => {
                  const { key: columnKey, ...restColumnProps } =
                    column.getHeaderProps();
                  return (
                    <th
                      key={columnKey || i}
                      className={`${headerBackgroundColor || ''}`}
                      {...restColumnProps}
                      style={{
                        minWidth: column.minWidth || 'auto',
                        width: column.width || 'auto',
                        borderRight: column.borderRight || 'auto',
                        borderLeft: column.borderLeft || 'auto',
                      }}
                    >
                      {column.render('Header')}
                    </th>
                  );
                })}
              </tr>
            );
          })}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            const { key, ...restRowProps } = row.getRowProps(rowProps(row));
            return (
              <React.Fragment key={i}>
                <tr key={key || i} {...restRowProps}>
                  {row.cells.map((cell, index) => {
                    const { key: cellKey, ...restCellProps } =
                      cell.getCellProps();
                    return (
                      <td
                        key={cellKey || index}
                        {...restCellProps}
                        style={{
                          minWidth: cell.column.minWidth || 'auto',
                          width: cell.column.width || 'auto',
                          borderRight: cell.column.borderRight || 'auto',
                          borderLeft: cell.column.borderLeft || 'auto',
                        }}
                      >
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
                {row.isExpanded ? (
                  <tr className="childComp">
                    <td colSpan={visibleColumns.length}>
                      {renderRowSubComponent({ row })}
                    </td>
                  </tr>
                ) : null}
              </React.Fragment>
            );
          })}
        </tbody>
        {footer && (
          <tfoot>
            {footerGroups?.map((footerGroup, index) => {
              const { key, ...restFooterGroupProps } =
                footerGroup.getFooterGroupProps();
              return (
                <tr key={key || index} {...restFooterGroupProps}>
                  {footerGroup?.headers?.map((column, i) => {
                    const { key: columnKey, ...restColumnProps } =
                      column.getFooterProps();
                    return (
                      <td key={columnKey || i} {...restColumnProps}>
                        {column.render('Footer')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tfoot>
        )}
      </table>
    </React.Fragment>
  );
};

export default Table;
