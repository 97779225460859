import { PendingTasksIcon } from 'assets/svg/pendingTasksIcon';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export const PendingTasks = () => {
  const taskCount = useSelector((state) => state.tasks.taskCount);
  const navigate = useNavigate();

  return (
    <div className="py-5 px-6 bg-white shadow-md border border-gray-200 rounded-xl flex justify-between w-full gap-5">
      <div className="gap-5 flex items-center">
        <PendingTasksIcon />
        <div>
          <p className="text-2xl text-gray-900 font-semibold">{taskCount}</p>
          <p className="font-medium text-sm text-gray-600">Pending Tasks</p>
        </div>
      </div>
      <p
        className="cursor-pointer bg-gradient text-transparent bg-clip-text text-sm font-semibold"
        onClick={() =>
          navigate(`/task?taskTab=Open&pageIndex=0&pageSize=10&status=Pending`)
        }
      >
        View All
      </p>
    </div>
  );
};
