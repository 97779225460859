import React from 'react';

const CalenderPlus02 = () => {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.75 9.09473V7.06973C15.75 5.80961 15.75 5.17955 15.5048 4.69825C15.289 4.27488 14.9448 3.93068 14.5215 3.71496C14.0402 3.46973 13.4101 3.46973 12.15 3.46973H5.85C4.58988 3.46973 3.95982 3.46973 3.47852 3.71496C3.05516 3.93068 2.71095 4.27488 2.49524 4.69825C2.25 5.17955 2.25 5.80961 2.25 7.06973V13.3697C2.25 14.6298 2.25 15.2599 2.49524 15.7412C2.71095 16.1646 3.05516 16.5088 3.47852 16.7245C3.95982 16.9697 5.33988 16.9697 6.6 16.9697M15.75 7.96973H2.25M12 1.96973V4.96973M6 1.96973V4.96973"
        stroke="#475467"
        strokeWidth="1.33"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.6779 12.0081V13.6476L12.5768 14.5465M15.1683 13.6476C15.1683 15.5753 13.6056 17.138 11.6779 17.138C9.7502 17.138 8.1875 15.5753 8.1875 13.6476C8.1875 11.7199 9.7502 10.1572 11.6779 10.1572C13.6056 10.1572 15.1683 11.7199 15.1683 13.6476Z"
        stroke="#475467"
        strokeWidth="1.33"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CalenderPlus02;
