import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  apiData: {},
};

const policyApprovalDataSlice = createSlice({
  name: 'policyApprovalData',
  initialState,
  reducers: {
    setPolicyData(state, action) {
      state.apiData = action.payload;
      return state;
    },
  },
});

export const { setPolicyData } = policyApprovalDataSlice.actions;
export default policyApprovalDataSlice.reducer;
