import HeatmapChart from 'components/molecules/nivo/charts/HeatMapChart';
import React from 'react';
import { formatDataSetForHeatmapChart } from '../chartjsCharts/Datasets';
import Choropleth from 'components/molecules/nivo/charts/GeoChart/Choropleth';
import { formatDataSetForGeoMapChart } from 'pages/dashboard_v2/dashboardCharts/chartjsCharts/Datasets';

const RenderNivoCharts = ({ datasets, chartType, displayLegend }) => {
  switch (chartType) {
    case 'Heatmap':
      return (
        <HeatmapChart
          data={formatDataSetForHeatmapChart(datasets)?.data}
          keys={formatDataSetForHeatmapChart(datasets)?.keys}
          displayLegend={displayLegend}
        />
      );
    case 'Geomap':
      return <Choropleth data={formatDataSetForGeoMapChart(datasets)} />;
    default:
      return <div>Invalid chart type</div>;
  }
};

export default RenderNivoCharts;
