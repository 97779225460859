import React from 'react';
import { useFormContext } from 'react-hook-form';

const ButtonAtom = (props) => {
  const { path, selectAtomsMolecules } = props.data;
  const { watch } = useFormContext();
  const [buttonText, fontSize, textColor, outlineColor, buttonColor] = watch([
    `${path}.button.value`,
    `${path}.button.metaData.fontSize`,
    `${path}.button.metaData.textColor`,
    `${path}.button.metaData.outlineColor`,
    `${path}.button.metaData.buttonColor`,
  ]);

  return (
    <button
      style={{
        color: textColor,
        backgroundColor: buttonColor,
        fontSize: fontSize,
        borderColor: outlineColor,
      }}
      onClick={() => selectAtomsMolecules(props?.data?.type)}
      className={`p-2 rounded-lg border-[1px] border-gray-300`}
      type="button"
    >
      {buttonText ?? 'Button CTA'}
    </button>
  );
};

export default ButtonAtom;
