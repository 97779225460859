import React from 'react';

const ViewProfileIcon = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.3334 13C12.3334 12.0696 12.3334 11.6044 12.2186 11.2259C11.9601 10.3736 11.2931 9.70669 10.4408 9.44816C10.0623 9.33333 9.59712 9.33333 8.66675 9.33333H5.33342C4.40304 9.33333 3.93785 9.33333 3.55932 9.44816C2.70705 9.70669 2.04011 10.3736 1.78157 11.2259C1.66675 11.6044 1.66675 12.0696 1.66675 13M10.0001 4C10.0001 5.65685 8.65694 7 7.00008 7C5.34323 7 4.00008 5.65685 4.00008 4C4.00008 2.34315 5.34323 1 7.00008 1C8.65694 1 10.0001 2.34315 10.0001 4Z"
        stroke="#344054"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ViewProfileIcon;
