import { cloneElement, isValidElement, useState } from 'react';
import './Button.styles.scss';

/**
 * Button component.
 *
 * component
 * param {Object} [props] - The props object.
 * param {ReactNode} [props.children] - The content of the button.
 * param {string} [props.className] - Additional CSS class for the button.
 * param {string} [props.variant] - The variant of the button ('outlined', 'filled', or undefined).
 

* param {ReactNode} [props.icon] - 
The icon element to be displayed inside the button, it needs to be an icon with conditional stroke logic based on isHovered prop, e.g., stopColor={`${props.isHovered ? '#fff' : 'var(--primaryRight)'}`}  .



* param {Object} [props.restProps] - Additional props to be spread on the button element.
 * return {JSX.Element} The rendered Button component.
 */

export default function Button(props) {
  const { children, variant, icon, ...restProps } = props;
  const parentClassName = props.className;
  const parentType = props.type;
  let type;
  const [isHovered, setIsHovered] = useState(false);

  switch (variant) {
    case 'outlined': {
      type = `bg-clip-text bg-gradient-to-br text-transparent from-primaryLeft to-primaryRight outlinedBtn`;
      break;
    }
    case 'filled': {
      type =
        'bg-gradient-to-br from-primaryLeft to-primaryRight text-white disable';
      break;
    }
    default: {
      type = 'border border-slate-300 rounded-xl';
    }
  }

  return (
    <div
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <button
        {...restProps}
        type={parentType ? parentType : 'button'}
        className={`flex items-center justify-center py-2 px-4 rounded-lg disabled:opacity-70 disabled:cursor-not-allowed ${type} ${parentClassName}`}
      >
        {' '}
        {icon && isValidElement(icon)
          ? cloneElement(icon, { isHovered })
          : icon}
        {children}
      </button>
    </div>
  );
}
