import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedCommunity: null,
  joinedList: [],
  relatedList: [],
  communityDetails: [],
  communityAdmins: [],
  communityMembers: [],
  isAdmin: false,
};
const communitySlice = createSlice({
  name: 'communitySlice',
  initialState,
  reducers: {
    setSelectedCommunity(state, action) {
      state.selectedCommunity = action.payload;
    },
    setState(state, action) {
      state[action.payload.type] = action.payload.value;
    },
  },
});

export const { setSelectedCommunity, setState } = communitySlice.actions;
export default communitySlice.reducer;
