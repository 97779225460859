import React, { useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';
import {
  templateBuilderSelector,
  toggleSidePanelType,
  setSidePanelTitle,
} from 'store/reducers/templateBuilderSlice';
import { useDispatch } from 'react-redux';
import { fetchAllLandingPageTemplates } from 'apiClient/templateBuilder/templateBuilderApi';
import AtomsMolecules from 'assets/svg/atomsMolecules';

const AtomsMoleculesSidePanel = () => {
  const { setValue, control } = useFormContext();
  const [templates, setTemplates] = useState();
  const dispatch = useDispatch();
  const { selectedColumn, selectedSection } = useSelector(
    templateBuilderSelector,
  );

  const sections = useWatch({
    control,
    name: `data`,
  });

  const sectionIndex = sections.findIndex(
    (section) => section?.sectionId === selectedSection,
  );

  const columns = useWatch({
    control,
    name: `data.${sectionIndex}.columns`,
  });

  const columnIndex = columns.findIndex(
    (column) => column?.columnId === selectedColumn,
  );

  const onTemplateClick = (template, category) => {
    setValue(`data.${sectionIndex}.columns.${columnIndex}`, {
      columnId: selectedColumn,
      type: template.name,
      column: {
        landingPageComponentId: template.id,
      },
    });
    dispatch(toggleSidePanelType(template.name));
    dispatch(setSidePanelTitle(`Edit ${category}`));
  };

  const fetchTemplates = async () => {
    const data = await fetchAllLandingPageTemplates();
    setTemplates(data);
  };

  useEffect(() => {
    fetchTemplates();
  }, []);

  return (
    <div>
      <div className="">
        <p className="text-sm text-gray-600">Atoms</p>
        <div className="grid grid-cols-2 my-4 gap-4">
          {templates?.map(
            (template, index) =>
              template?.metadata?.type === 'atom' && (
                <button
                  key={index}
                  className="ring-1 ring-gray-200 p-4"
                  onClick={() => onTemplateClick(template, 'Atom')}
                >
                  <div className="h-16 flex flex-col justify-between items-center">
                    <AtomsMolecules name={template?.name} />
                    <p className="text-gray-400">{template?.name}</p>
                  </div>
                </button>
              ),
          )}
        </div>
      </div>
      <div className="">
        <p className="text-sm text-gray-600">Molecules</p>
        <div className="grid grid-cols-2 my-4 gap-4">
          {templates?.map(
            (template, index) =>
              template?.metadata?.type === 'molecule' && (
                <button
                  key={index}
                  className="ring-1 ring-gray-200 p-4 flex justify-center"
                  onClick={() => onTemplateClick(template, 'Molecule')}
                >
                  <AtomsMolecules name={template?.name} />
                </button>
              ),
          )}
        </div>
      </div>
    </div>
  );
};

export default AtomsMoleculesSidePanel;
