import ImageAtom from 'assets/svg/imageAtom';
import React from 'react';
import { useFormContext } from 'react-hook-form';

const Image = (props) => {
  const { errorPath, path } = props.data;
  const { register, watch } = useFormContext();
  const [imgUrl, objectFit] = watch([
    `${path}.image.src`,
    `${path}.image.metaData.objectFit`,
  ]);

  const getImageSrc = () => {
    if (typeof imgUrl === 'string') {
      return imgUrl; // If imgUrl is already a string, use it directly
    } else if (imgUrl instanceof File || imgUrl instanceof Blob) {
      return URL.createObjectURL(imgUrl); // Create object URL if imgUrl is a File or Blob
    } else {
      return ''; // Handle other cases gracefully, e.g., imgUrl is null or undefined
    }
  };

  return imgUrl && imgUrl?.length !== 0 ? (
    <img
      // src={
      //   typeof imgUrl === 'string'
      //     ? imgUrl
      //     : imgUrl && URL.createObjectURL(imgUrl)
      // }
      src={getImageSrc()}
      alt="Media not found"
      className={`h-full w-full ${
        objectFit ? objectFit : 'object-cover'
      } rounded-lg`}
    />
  ) : (
    <div className="relative h-full w-full flex justify-center items-center">
      <ImageAtom />
      <input
        className="hidden"
        {...register(`${path}.image.src`, {
          required: 'This Field is required',
        })}
      />
      {errorPath?.image?.src && (
        <p className="absolute left-0 bottom-0 bg-clip-text bg-gradient-to-br text-transparent from-primaryLeft to-primaryRight">
          {errorPath?.image?.src?.message + '*'}
        </p>
      )}
    </div>
  );
};

export default Image;
