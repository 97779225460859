type BarChartProps = {
  hovered: boolean;
};

export const BarChartGradientIcon = ({ hovered }: BarChartProps) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 16.6673V8.33398M10 16.6673V3.33398M5 16.6673V11.6673"
        stroke="url(#paint0_linear_7431_36583)"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_7431_36583"
          x1="5"
          y1="16.6673"
          x2="15"
          y2="16.6673"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={hovered ? '#FFFFFF' : 'var(--primaryLeft)'} />
          <stop
            offset="1"
            stopColor={hovered ? '#FFFFFF' : 'var(--primaryRight)'}
          />
        </linearGradient>
      </defs>
    </svg>
  );
};
