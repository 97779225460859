import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { updateChart } from 'apiClient/dashboardBuilder/DashboardBuilder';
import SearchIconInput from 'assets/svg/searchIconInput';
import Button from 'components/atoms/button/Button';
import Select from 'components/atoms/FormElements/select/Select';
import ToolTipComponent from 'components/atoms/tooltip/ToolTipComponent';
import { set } from 'lodash';
import {
  useGetDimensions,
  useUpdateChart,
} from 'query/dashboard/ManageDashboard';
import React, { useEffect, useState } from 'react';
import { onResponse } from 'utils/toastMessages';

const DashboardAdminFilters = ({ dashboardId, dashboardCards }) => {
  const [selectedDimensions, setSelectedDimensions] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [chartId, setChartId] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  console.log('dashboardCards', dashboardCards);

  const {
    data: dimensionsData,
    isLoading: dimensionsLoading,
    error: errorDimension,
  } = useGetDimensions(dashboardId);

  // useEffect(() => {
  //   setSelectedDimensions(
  //     configFor?.cardData?.filters?.map((filter) => {
  //       const dimension = dimensionsData?.find(
  //         (d) => d?.columnId === filter?.id,
  //       );
  //       return dimension?.value;
  //     }) || [],
  //   );
  //   setChartId(configFor?.cardData?.id);
  // }, [configFor]);

  // const { mutateAsync } = useUpdateChart(chartId);

  const getFormattedList = (list) => {
    return (
      list &&
      list?.map((option) => {
        return {
          value: option?.columnId,
          label: option?.value,
          ...option,
        };
      })
    );
  };

  const handleChange = (selected) => {
    const selectedDimensions = selected?.map((option) => option?.value);
    const selectedOptions = selected?.map((option) => option?.columnId);
    setSelectedDimensions((prev) => {
      const newDimensions = [...new Set([...prev, ...selectedDimensions])];
      return newDimensions;
    });
    setSelectedFilters((prev) => {
      const newFilters = [...new Set([...prev, ...selectedOptions])];
      return newFilters;
    });
  };

  const handleRemoveDimension = (dimensionToRemove) => {
    const dimensionToRemoveId = dimensionsData?.find(
      (dimension) => dimension?.value === dimensionToRemove,
    )?.columnId;
    setSelectedDimensions((prev) =>
      prev.filter((dimension) => dimension !== dimensionToRemove),
    );
    setSelectedFilters((prev) =>
      prev.filter((filter) => filter !== dimensionToRemoveId),
    );
  };

  const handleSave = async () => {
    const selectedDimensionIds = selectedDimensions.map(
      (dimension) =>
        dimensionsData?.find((d) => d?.value === dimension)?.columnId,
    );

    // Extract the filter IDs from configFor?.cardData?.filters
    // const originalFilterIds = configFor?.cardData?.filters?.map(
    //   (filter) => filter,
    // );

    // // Find the IDs that are in originalFilterIds but not in selectedDimensionIds
    // const disconnectedFilters = originalFilterIds?.filter(
    //   (id) => !selectedDimensionIds.includes(id),
    // );

    setIsLoading(true);
    const payload = {
      filters: selectedFilters,
      dashboardId: dashboardId,
      disconnectedFilters: [],
    };
    try {
      await Promise.all(
        dashboardCards?.charts?.map((chart) => {
          console.log('Chart ID:', chart?.cardData?.id);
          return updateChart(payload, chart?.cardData?.id);
        }),
      );
      onResponse('Charts Updated Successfully');
    } catch (err) {
      setIsLoading(false);
      onResponse(err?.message);
    }
    setIsLoading(false);
  };

  return (
    <div className="relative h-full flex flex-col">
      <div className="h-full flex flex-col justify-between">
        <div className="h-4/5 overflow-y-auto">
          <div className="border border-gray-200 bg-gray-25 p-4 rounded-lg ">
            <Disclosure>
              <Disclosure.Button className="group flex w-full items-center justify-between">
                <span className="text-gray-600 text-base font-semibold">
                  Filter
                </span>
                <ChevronDownIcon className="w-5 group-data-[open]:rotate-180" />
              </Disclosure.Button>
              <Disclosure.Panel className="flex flex-col gap-2 mt-3">
                <hr className="bg-gray-200"></hr>
                <div className="flex flex-col gap-3">
                  <div className="flex flex-col gap-2">
                    <span className="text-xs text-gray-700">Field</span>
                    <div className="flex border rounded-lg border-gray-300 bg-white items-center">
                      <div className="pl-2">
                        <SearchIconInput />
                      </div>
                      <Select
                        className="w-15 border-none"
                        placeholder="Select"
                        id="remove-border-select"
                        options={getFormattedList(dimensionsData || [])}
                        onChange={handleChange}
                        value={[]}
                        isMulti={true}
                      />
                    </div>
                  </div>
                  {selectedDimensions?.length > 0 && (
                    <div className="flex flex-col gap-2">
                      <span className="text-xs text-gray-700">Value</span>
                      <div className="border px-3 py-2 rounded-lg border-partition flex gap-2 w-full items-center">
                        {selectedDimensions
                          ?.slice(0, 1)
                          ?.map((dimension, index) => (
                            <div
                              key={index}
                              style={{
                                background:
                                  'linear-gradient(89.75deg, var(--primaryLeft50) 0%, var(--primaryRight50) 98.02%)',
                              }}
                              className="py-[2px] px-[8px] rounded-2xl flex justify-between items-center gap-2"
                            >
                              <p className="bg-gradient text-transparent bg-clip-text text-center">
                                {dimension}
                              </p>
                              <span
                                className="bg-gradient text-transparent bg-clip-text text-center cursor-pointer"
                                onClick={() => handleRemoveDimension(dimension)}
                              >
                                X
                              </span>
                            </div>
                          ))}
                        {selectedDimensions?.length > 1 && (
                          <div>
                            <div
                              style={{
                                background:
                                  'linear-gradient(89.75deg, var(--primaryLeft50) 0%, var(--primaryRight50) 98.02%)',
                              }}
                              className="py-[2px] px-[8px] rounded-2xl cursor-pointer"
                              data-tooltip-id={`dashboard-builder`}
                            >
                              <p className="bg-gradient text-transparent bg-clip-text text-center">
                                +{selectedDimensions?.length - 1} more
                              </p>
                            </div>
                            <ToolTipComponent
                              id={`dashboard-builder`}
                              place={'bottom'}
                            >
                              <div className=" flex flex-col gap-2 px-2">
                                <ul className="text-white text-xs list-disc rounded-sm border-1 capitalize ">
                                  {selectedDimensions
                                    ?.slice(1)
                                    ?.map((dimension, index) => (
                                      <li key={index}>{dimension}</li>
                                    ))}
                                </ul>
                              </div>
                            </ToolTipComponent>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </Disclosure.Panel>
            </Disclosure>
          </div>
        </div>
        <div className=" h-20 absolute w-full bottom-0 flex flex-col pt-3 gap-4 bg-white ">
          <hr className="bg-gray-200" />
          <div className="text-right">
            {isLoading ? (
              <Button>Saving...</Button>
            ) : (
              <Button onClick={handleSave}>Save</Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardAdminFilters;
