import React from 'react';
import { useFormContext } from 'react-hook-form';

const SpacerComponent = (props) => {
  const { path, selectAtomsMolecules, type } = props.data;
  const { watch } = useFormContext();
  const spacerHeight = watch(`${path}.spacer.height`);
  return (
    <div
      style={{ height: spacerHeight + 'px' }}
      onClick={() => selectAtomsMolecules(type)}
      className="cursor-pointer w-full border border-dashed border-primaryLeft-700 rounded-lg"
    ></div>
  );
};

export default SpacerComponent;
