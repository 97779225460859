import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  userProfileData: {},
  userData: {},
  isAuthenticated: false,
  accessToken: localStorage.getItem('accessToken') || null,
  idAccessToken: localStorage.getItem('idAccessToken') || null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserProfileData(state, action) {
      state.userProfileData = action.payload;
    },
    setUserData(state, action) {
      state.userData = action.payload;
    },
    setToken(state, action) {
      state.accessToken = action.payload;
    },
    setUserAuthentication(state, action) {
      state.isAuthenticated = action.payload;
    },
  },
});

export const {
  setUserProfileData,
  setUserData,
  setUserAuthentication,
  setToken,
} = userSlice.actions;
export default userSlice.reducer;
