const TableFilterIcon = (props) => {
  const { className, iconColor, ...rest } = props;
  return (
    <svg
      width="14"
      height="10"
      viewBox="0 0 14 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`cursor-pointer ${className} `}
      {...rest}
    >
      <path
        d="M3 5H11M1 1H13M5 9H9"
        stroke={iconColor || '#475467'}
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default TableFilterIcon;
