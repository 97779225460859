import React from 'react';

const ReviewCompensationData = (props) => {
  const { compDataList } = props;
  return (
    <>
      <div className="p-4 w-4/12">
        <div className="bg-white p-4 flex flex-col gap-2 border shadow-md rounded-lg w-full">
          <p className="text-xl font-semibold">Compensation Details</p>
          <div className=" p-4 flex items-center gap-4 justify-between bg-gray-50 border rounded-lg w-full">
            <div className=" flex items-center justify-start w-1/2">
              <p className="font-semibold text-start">Pay Component</p>
            </div>
            <div className=" flex items-center justify-start w-1/2">
              <p className="font-semibold text-start">Amount/Year</p>
            </div>
          </div>
          {compDataList && compDataList?.length > 0 ? (
            compDataList?.map(
              (item, index) =>
                item?.total > 0 && (
                  <div
                    className=" p-4 flex flex-col bg-gray-50 border rounded-lg gap-2 w-full"
                    key={index}
                  >
                    <div className="flex items-center gap-4 justify-between w-full">
                      <div className=" flex items-center justify-start w-1/2">
                        <p className="font-semibold text-start">{item?.name}</p>
                      </div>
                      <div className=" flex items-center justify-start w-1/2">
                        <p className="font-semibold text-start">
                          {item?.total}
                        </p>
                      </div>
                    </div>
                    {item?.value &&
                      item?.value?.length > 0 &&
                      item?.value?.map(
                        (dataItem, index) =>
                          dataItem?.value > 0 && (
                            <div
                              className="p-4 border rounded-lg bg-white flex flex-col gap-2"
                              key={index}
                            >
                              <div className="flex items-center gap-4 justify-between w-full">
                                <div className=" flex items-center justify-start w-1/2">
                                  <p className=" text-start">
                                    {dataItem?.componentname ||
                                      item?.header?.value[index]?.option
                                        ?.name ||
                                      '-'}
                                  </p>
                                </div>
                                <div className=" flex items-center justify-start w-1/2">
                                  <p className=" text-start">
                                    {' '}
                                    {dataItem?.value}
                                  </p>
                                </div>
                              </div>
                            </div>
                          ),
                      )}
                  </div>
                ),
            )
          ) : (
            <p className="flex items-center justify-center">
              No Compensation Data
            </p>
          )}
        </div>
      </div>
    </>
  );
};

export default ReviewCompensationData;
