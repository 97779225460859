import {
  deleteJobMatchingData,
  downloadJobMatchingData,
  getAgingConfig,
  getAgingFrequencyDropDownValue,
  getJobMatchingData,
  getJobMatchingFilter,
  getJobMatchingVersionHistory,
  individualAgingUpload,
} from 'apiClient/rangeBuilder/rangeBuilder';
import { useMutation, useQuery } from 'react-query';

export const useGetJobMatchingData = (
  planId,
  pageSize,
  pageIndex,
  filters,
  query,
) =>
  useQuery(
    ['get/jobMatchingData', planId],
    async () => {
      const res = await getJobMatchingData(
        planId,
        pageSize,
        pageIndex,
        filters,
        query,
      );
      return res;
    },
    {
      retry: 0,
    },
  );

export const useGetJobMatchingDataFilters = (planId) =>
  useQuery(['get/jobMatchingFilter', planId], async () => {
    const res = await getJobMatchingFilter(planId);
    return res;
  });

export const useDownloadJobMatchingData = (planId) =>
  useQuery(
    ['download/externalData', planId],
    async () => {
      const res = await downloadJobMatchingData(planId);
      return res;
    },
    {
      enabled: false,
    },
  );

export const useGetJobMatchVersionHistory = (rangeFormTypeId, rangeDataId) =>
  useQuery(
    ['get/versionHistoryJobMatchingData', rangeFormTypeId, rangeDataId],
    async () => {
      const res = await getJobMatchingVersionHistory(
        rangeFormTypeId,
        rangeDataId,
      );
      return res?.data;
    },
  );

export const useDeleteJobMatchingRow = () =>
  useMutation(['delete/externalData'], async (data) => {
    const res = await deleteJobMatchingData(
      data?.rangeFormTypeId,
      data?.payload,
    );
    return res?.data;
  });

export const useGetAgingFrequencyDropDown = () =>
  useQuery(['get/agingFrequencyDropDown'], async () => {
    const res = await getAgingFrequencyDropDownValue();
    return res;
  });

export const useGetAgingConfig = (planId, rangeDataId) =>
  useQuery(['get/agingConfig', planId, rangeDataId], async () => {
    const res = await getAgingConfig(planId, rangeDataId);
    return res?.data;
  });

export const useIndividualAgingUpload = () =>
  useMutation(['post/individualAgingUpload'], async (data) => {
    const res = await individualAgingUpload(data?.planId, data?.payload);
    return res?.data;
  });
