const { createSlice } = require('@reduxjs/toolkit');

const initialState = {
  liveBenefits: [],
  draftsBenefits: [],
  archivedBenefits: [],
};

const manageBenefitsSlice = createSlice({
  name: 'manageBenefits',
  initialState,
  reducers: {
    setLiveBenefits: (state, action) => {
      state.liveBenefits = action.payload;
    },
    setDraftsBenefits: (state, action) => {
      state.draftsBenefits = action.payload;
    },
    setArchivedBenefits: (state, action) => {
      state.archivedBenefits = action.payload;
    },
    // deletePolicy: (state, action) => {
    //   state.draftsPolicies = state.draftsPolicies.filter((policy) => policy.id !== action.payload);
    // },
    // archivePolicy: (state, action) => {
    //   state.livePolicies = state.livePolicies.filter((policy) => policy.id !== action.payload);
    // },
    // publishPolicy: (state, action) => {
    //   state.draftsPolicies = state.draftsPolicies.filter((policy) => policy.id !== action.payload);
    // },
  },
});

export const {
  setArchivedBenefits,
  setDraftsBenefits,
  setLiveBenefits,
  //   deletePolicy,
  //   archivePolicy,
  //   publishPolicy,
} = manageBenefitsSlice.actions;
export default manageBenefitsSlice.reducer;
export const manageBenefitsSelector = (state) => state.manageBenefits;
