const TransactNowIcon = ({
  isActive,
  activeColor,
  isHovered,
  hoveredColor,
  width,
  height,
}) => (
  <svg
    width={width ? width : '20'}
    height={height ? height : '20'}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.99999 9.16671V12.5M15 7.50004V10.8334M14.1667 3.33337C16.2072 3.33337 17.3109 3.64567 17.8601 3.88791C17.9332 3.92017 17.9698 3.9363 18.0753 4.03702C18.1386 4.09739 18.2541 4.27454 18.2838 4.35678C18.3333 4.49399 18.3333 4.56899 18.3333 4.71899V13.676C18.3333 14.4333 18.3333 14.812 18.2198 15.0066C18.1042 15.2046 17.9928 15.2966 17.7766 15.3727C17.564 15.4475 17.135 15.3651 16.2768 15.2002C15.6761 15.0848 14.9638 15 14.1667 15C11.6667 15 9.16666 16.6667 5.83332 16.6667C3.79273 16.6667 2.68905 16.3544 2.13989 16.1122C2.06676 16.0799 2.03019 16.0638 1.92466 15.9631C1.8614 15.9027 1.74592 15.7255 1.71621 15.6433C1.66666 15.5061 1.66666 15.4311 1.66666 15.2811L1.66666 6.32409C1.66666 5.56678 1.66666 5.18813 1.78022 4.99349C1.89574 4.79551 2.00715 4.70347 2.22337 4.62738C2.43594 4.55258 2.86502 4.63503 3.72318 4.79991C4.32384 4.91532 5.03622 5.00004 5.83332 5.00004C8.33332 5.00004 10.8333 3.33337 14.1667 3.33337ZM12.0833 10C12.0833 11.1506 11.1506 12.0834 9.99999 12.0834C8.8494 12.0834 7.91666 11.1506 7.91666 10C7.91666 8.84945 8.8494 7.91671 9.99999 7.91671C11.1506 7.91671 12.0833 8.84945 12.0833 10Z"
      stroke={isActive ? activeColor : isHovered ? hoveredColor : '#475467'}
      strokeWidth="1.67"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default TransactNowIcon;
