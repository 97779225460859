import { Input } from '../input/Input';
import './ColorPicker.styles.scss';

function ColorPicker({ value, onChange, htmlFor, label }) {
  const colorValue = '#000000';
  return (
    <div>
      <p>{label}</p>
      <div className="relative flex items-center w-full ">
        <Input
          placeholder={colorValue}
          className="!py-2 text-sm z-30 focus:border-primaryLeft-700 w-full "
          value={value}
          onChange={onChange}
        />
        <div className="w-12 absolute my-2 right-0 z-50">
          {/* <label htmlFor={htmlFor}>
            <span
              className={`h-5 w-5 absolute top-1/2 right-2 rounded-md border cursor-pointer`}
              style={{
                backgroundColor: value ?? colorValue,
              }}
            ></span>
          </label> */}
          <input
            className="w-8 h-8  border-none border-opacity-0 "
            type="color"
            id={htmlFor}
            value={value}
            onChange={onChange}
          />
        </div>
      </div>
    </div>
  );
}

export default ColorPicker;
