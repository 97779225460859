import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  masterDuplicateModal: false,
  planData: null,
  sidePanelType: 'non',
  historyPanel: false,
  inputFields: [],
  inputCriteria: [
    {
      id: '1',
      criteria: 'equals',
      description: 'equals',
      isNumeric: true,
      isActive: true,
      precedence: 2,
      type: 'Text',
    },
    {
      id: '2',
      criteria: 'notEquals',
      description: 'not equals',
      isNumeric: true,
      isActive: true,
      precedence: 2,
      type: 'Text',
    },
    {
      id: '3',
      criteria: 'contains',
      description: 'contains',
      isNumeric: true,
      isActive: true,
      precedence: 2,
      type: 'Text',
    },
    {
      id: '4',
      criteria: 'notContains',
      description: 'not contains',
      isNumeric: true,
      isActive: true,
      precedence: 2,
      type: 'Text',
    },
    {
      id: '5',
      criteria: 'startsWith',
      description: 'starts with',
      isNumeric: true,
      isActive: true,
      precedence: 2,
      type: 'Text',
    },
    {
      id: '6',
      criteria: 'endsWith',
      description: 'ends with',
      isNumeric: true,
      isActive: true,
      precedence: 2,
      type: 'Text',
    },
    {
      id: '7',
      criteria: 'range',
      description: 'range',
      isNumeric: true,
      isActive: true,
      precedence: 2,
      type: 'Text',
    },
    {
      id: '8',
      criteria: 'greater',
      description: 'greater',
      isNumeric: true,
      isActive: true,
      precedence: 2,
      type: 'Text',
    },
    {
      id: '9',
      criteria: 'lessThan',
      description: 'less than',
      isNumeric: true,
      isActive: true,
      precedence: 2,
      type: 'Text',
    },
  ],
  selectedRow: [],
  selectedField: [],
  selectedRowData: {},
  masterTableData: [],
  columnData: [],
  masterTableRules: [],
  selectedTable: {},
  settingData: {},
  selectedHistoryId: '',
  selectedColumnSetting: [],
  manageForms: {
    selectedRow: [],
    selectedUser: [],
    worksheets: [],
  },
  status: 'Saved!',
  guidelines: {
    subOptions: {},
  },
  eligibilityLimites: {
    subOptions: {},
  },
  letterGeneration: {
    subOptions: {},
  },
  planningSubData: {},
  planningSubLoader: [],
};

const surveySlice = createSlice({
  name: 'compensation',
  initialState,
  reducers: {
    setSidePanelType(state, action) {
      state.sidePanelType = action.payload;
      return state;
    },
    setHistoryPanel(state, action) {
      state.historyPanel = action.payload;
      return state;
    },
    setMasterTableData(state, action) {
      state.masterTableData = action.payload;
      return state;
    },
    setInputData(state, action) {
      state[action.payload.type] = action.payload.value;

      return state;
    },
    setPlan(state, action) {
      state.planData = action.payload;
      return state;
    },
    setSelectedRow(state, action) {
      state.selectedRow = action.payload;
      return state;
    },
    setSelectedField(state, action) {
      state.selectedField = action.payload;
      return state;
    },
    setSelectedData(state, action) {
      state.selectedRowData = action.payload;
      return state;
    },
    setColumnData(state, action) {
      state.columnData = action.payload;
      return state;
    },
    setSettingData(state, action) {
      state.settingData = action.payload;
      return state;
    },
    setMasterTableRules(state, action) {
      state.masterTableRules = action.payload;
      return state;
    },
    setSelectedTable(state, action) {
      state.selectedTable = action.payload;
      return state;
    },
    setSelectedHistoryId(state, action) {
      state.selectedHistoryId = action.payload;
      return state;
    },
    setSelectedColumnSetting(state, action) {
      state.selectedColumnSetting = action.payload;
    },
    setStatus(state, action) {
      state.status = action.payload;
    },
    setManageFormSelectedRows(state, action) {
      state.manageForms.selectedRow = action.payload;
      return state;
    },
    setSelectedUser(state, action) {
      state.manageForms.selectedUser = action.payload;
      return state;
    },
    resetState(state) {
      state.selectedRow = [];
      state.selectedField = [];
      state.historyPanel = false;
      state.sidePanelType = 'non';
      return state;
    },
    setGuidelineRowOptions(state, action) {
      const { fieldId, options } = action.payload;
      state.guidelines.subOptions[fieldId] = options;
      return state;
    },
    setLetterSubOptions(state, action) {
      const { fieldId, options } = action.payload;
      state.letterGeneration.subOptions[fieldId] = options;
      return state;
    },
    setEligibilityRowOptions(state, action) {
      const { fieldId, options } = action.payload;
      state.eligibilityLimites.subOptions[fieldId] = options;
      return state;
    },
    setPlanningSubDataById(state, action) {
      const { id, data } = action.payload;
      state.planningSubData[id] = data;
      return state;
    },
    setAddPlanningSubIdLoader(state, action) {
      state.planningSubLoader.push(action.payload);
      return state;
    },
    setRemovePlanningSubIdLoader(state, action) {
      state.planningSubLoader = state.planningSubLoader.filter(
        (ele) => ele !== action.payload,
      );
      return state;
    },
    setMasterDuplicateModal(state, action) {
      state.masterDuplicateModal = action.payload;
      return state;
    },
    setWorksheets(state, action) {
      state.manageForms.worksheets = action.payload;
      return state;
    },
  },
});

export default surveySlice.reducer;

export const {
  setMasterDuplicateModal,
  setSidePanelType,
  setHistoryPanel,
  setSelectedRow,
  setSelectedField,
  resetState,
  setSelectedData,
  setColumnData,
  setSettingData,
  setSelectedTable,
  setSelectedHistoryId,
  setSelectedColumnSetting,
  setMasterTableData,
  setManageFormSelectedRows,
  setSelectedUser,
  setGuidelineRowOptions,
  setEligibilityRowOptions,
  setStatus,
  setPlan,
  setInputData,
  setPlanningSubDataById,
  setAddPlanningSubIdLoader,
  setRemovePlanningSubIdLoader,
  setLetterSubOptions,
  setMasterTableRules,
  setWorksheets,
} = surveySlice.actions;
