const RedeemRewardsIcon = ({
  isActive,
  activeColor,
  isHovered,
  hoveredColor,
  width,
  height,
}) => (
  <svg
    width={width ? width : '20'}
    height={height ? height : '20'}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.39688 10.9128L3.91769 6.56379C3.70111 6.29307 3.59282 6.1577 3.51582 6.00697C3.4475 5.87324 3.39756 5.73089 3.36737 5.58379C3.33334 5.41798 3.33334 5.24463 3.33334 4.89794V4.33335C3.33334 3.39993 3.33334 2.93322 3.515 2.5767C3.67479 2.2631 3.92976 2.00813 4.24336 1.84834C4.59988 1.66669 5.06659 1.66669 6.00001 1.66669H14C14.9334 1.66669 15.4001 1.66669 15.7567 1.84834C16.0703 2.00813 16.3252 2.2631 16.485 2.5767C16.6667 2.93322 16.6667 3.39993 16.6667 4.33335V4.89794C16.6667 5.24463 16.6667 5.41798 16.6326 5.58379C16.6025 5.73089 16.5525 5.87324 16.4842 6.00697C16.4072 6.1577 16.2989 6.29307 16.0823 6.56379L12.6031 10.9128M4.16672 2.50002L10.0001 10L15.8333 2.50002M12.9463 11.2204C14.5735 12.8476 14.5735 15.4858 12.9463 17.113C11.3191 18.7402 8.68092 18.7402 7.05373 17.113C5.42655 15.4858 5.42655 12.8476 7.05373 11.2204C8.68091 9.59323 11.3191 9.59323 12.9463 11.2204Z"
      stroke={isActive ? activeColor : isHovered ? hoveredColor : '#475467'}
      strokeWidth="1.67"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default RedeemRewardsIcon;
