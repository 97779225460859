import { fetchLandingPage } from 'apiClient/templateBuilder/templateBuilderApi';
import TemplateBuilder from 'pages/admin/templateBuilder/TemplateBuilder';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  setLandingPageData,
  setModeLabel,
} from 'store/reducers/templateBuilderSlice';

export default function EditLandingPage() {
  const [landingPageDetails, setLandingPageDetails] = useState(null);
  const dispatch = useDispatch();
  // ##id from the url
  const { id } = useParams();
  useEffect(() => {
    dispatch(setModeLabel('Edit'));
    fetchLandingPage(id)
      .then((res) => {
        dispatch(setLandingPageData(res));

        setLandingPageDetails(res);
      })
      .catch((err) => console.log(err));
  }, [id]);

  if (landingPageDetails) {
    return (
      <div>
        <TemplateBuilder modeLabel={'Edit'} />
      </div>
    );
  } else
    return (
      <div className="flex justify-center h-screen bg-white items-center">
        {' '}
        Loading data...
      </div>
    );
}
