import { XMarkIcon } from '@heroicons/react/24/solid';

const ModalPopup = ({ onClose, title, body, height, width }) => {
  return (
    <div className="fixed z-[999] inset-0 bg-backdrop ">
      <div className="flex items-center justify-center min-h-screen px-4 w-full">
        <div
          className={`bg-white rounded-lg shadow-md relative   pb-4 ${
            width ? width : 'w-[600px] max-w-[600px]'
          }`}
        >
          <div className="flex p-4 font-semibold w-full">
            <p className="flex items-center w-[95%]">{title}</p>
            <button
              className="flex justify-end w-auto items-end top-0 right-0 p-2 text-gray-500 hover:text-gray-700"
              onClick={onClose}
            >
              <XMarkIcon className="w-5 h-5" />
            </button>
          </div>
          <div
            className={`${
              height ? `max-h-[${height}]` : 'max-h-[400px]'
            } overflow-y-auto custom-scrollbar`}
          >
            {body}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalPopup;
