const SummaryIcon = (props) => (
  <svg
    width={props?.width ? props?.width : '20'}
    height={props?.height ? props?.height : '20'}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.6667 1.8913V5.33342C11.6667 5.80013 11.6667 6.03348 11.7575 6.21174C11.8374 6.36854 11.9649 6.49603 12.1217 6.57592C12.2999 6.66675 12.5333 6.66675 13 6.66675H16.4421M16.6667 8.3236V14.3334C16.6667 15.7335 16.6667 16.4336 16.3942 16.9684C16.1545 17.4388 15.7721 17.8212 15.3017 18.0609C14.7669 18.3334 14.0668 18.3334 12.6667 18.3334H7.33334C5.93321 18.3334 5.23315 18.3334 4.69837 18.0609C4.22796 17.8212 3.84551 17.4388 3.60583 16.9684C3.33334 16.4336 3.33334 15.7335 3.33334 14.3334V5.66675C3.33334 4.26662 3.33334 3.56655 3.60583 3.03177C3.84551 2.56137 4.22796 2.17892 4.69837 1.93923C5.23315 1.66675 5.93321 1.66675 7.33334 1.66675H10.0098C10.6213 1.66675 10.927 1.66675 11.2148 1.73582C11.4698 1.79707 11.7137 1.89808 11.9374 2.03515C12.1897 2.18975 12.4059 2.40594 12.8382 2.83832L15.4951 5.49517C15.9275 5.92755 16.1437 6.14374 16.2983 6.39604C16.4353 6.61972 16.5364 6.86358 16.5976 7.11867C16.6667 7.40639 16.6667 7.71213 16.6667 8.3236Z"
      stroke={
        props.isActive
          ? props.activeColor
          : props.isHovered
          ? props.hoveredColor
          : '#475467'
      }
      strokeWidth="1.67"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SummaryIcon;
