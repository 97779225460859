import React from 'react';
import CreatableSelect from 'react-select/creatable';
import ReactSelect from 'react-select';
import { AsyncPaginate } from 'react-select-async-paginate';
import { customStyles } from './propertySelectCss';
import DatabaseIcon from 'assets/svg/databaseIcon';
import { CheckIcon } from '@heroicons/react/24/outline';

const DropdownIndicator = () => {
  return (
    <div className="pr-2">
      <DatabaseIcon />
    </div>
  );
};
const TickIcon = () => {
  return <CheckIcon />; // Adjust size and color as needed
};

const PropertySelect = React.forwardRef((props, ref) => {
  const { label, error, value, async, required, creatable, ...rest } = props;

  const customComponents = {
    DropdownIndicator: DropdownIndicator,
    MultiValueLabel: ({ children, ...innerProps }) => (
      <div {...innerProps}>
        {children}
        <TickIcon />
      </div>
    ),
  };

  return (
    <React.Fragment>
      {label && (
        <label
          className={`text-sm font-medium ${
            props?.isStep1Program ? 'my-0' : 'my-1'
          } block text-gray-700`}
        >
          {label} {required && <span className="text-red-600">*</span>}
        </label>
      )}

      {async ? (
        <AsyncPaginate
          ref={ref}
          styles={customStyles}
          id={props?.id}
          className={`${props?.className}`}
          menuPosition="fixed"
          maxMenuHeight={150}
          menuPortalTarget={document.body}
          components={customComponents}
          {...rest}
        />
      ) : creatable ? (
        <CreatableSelect
          ref={ref}
          styles={customStyles}
          id={props?.id}
          value={value}
          isClearable
          components={customComponents}
          {...rest}
          className={props?.className}
          menuPosition="fixed"
          maxMenuHeight={150}
          menuPortalTarget={document.body}
        />
      ) : (
        <ReactSelect
          ref={ref}
          styles={customStyles}
          id={props?.id}
          value={value}
          {...rest}
          className={props?.className}
          menuPosition="fixed"
          maxMenuHeight={150}
          menuPortalTarget={document.body}
        />
      )}

      {error && <p className="text-red-500 text-sm -mt-.5">{error?.message}</p>}
    </React.Fragment>
  );
});

export default PropertySelect;
