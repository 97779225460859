import React from 'react';

const TableSkeleton = ({ count }) => {
  return (
    <div className="animate-pulse w-full">
      <div className="h-10 bg-slate-300 rounded"></div>
      <div className="grid gap-2 mt-3">
        {(count ? [...Array(count)] : [...Array(7)]).map((_, index) => (
          <div key={index} className="h-14 bg-slate-200 rounded"></div>
        ))}
      </div>
      {/* <thead>
      </thead>
      <tbody>
        
      </tbody> */}
    </div>
  );
};

export default TableSkeleton;
