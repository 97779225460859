import { AxiosError } from 'axios';
import * as CONSTANT from '../api.constant';
import { del, get, patch, post } from '../apiCalls';
// get feeds
export const getFeeds = async (data) => {
  // data.pageIndex =
  try {
    const res = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_SURFACE_V2}/${CONSTANT.API_FEED}/${CONSTANT.GET_MY_FEED}`,
      data,
    );

    return res.data;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! card result fetch failed');
    }
  }
};

export const getSocial = async (body) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_SURFACE_V2}/${CONSTANT.API_SOCIAL}/get-all-socials-by-source`,
      body,
    );
    return response.data;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! failed to get social');
    }
  }
};

export const linkedinShare = async (body) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_SURFACE_V2}/post/share-media-post`,
      body,
    );
    return response.data;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! failed to get social');
    }
  }
};
export const fetchCardResult = async (type, searchDuration) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_SURFACE_V2}/${CONSTANT.API_EMP}/${type}?val=${searchDuration}`,
    );
    const resultData = response.data;
    return resultData;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! card result fetch failed');
    }
  }
};
export const saveEmotionToday = async (gifId) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_SURFACE_V2}/${CONSTANT.API_EMOTION}/save-emotion/${gifId}`,
      {},
    );

    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! feeling today not saved');
    }
  }
};

export const fetchFeelingEmotions = async (type) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_SURFACE_V2}/${CONSTANT.API_EMOTION}/get-all-emotions?type=${type}`,
    );
    const resultData = response?.data;
    return resultData;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! failure in fetching emotions');
    }
  }
};
export const fetchTrendingPost = async () => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_SURFACE_V2}/${CONSTANT.API_FEED}/get-trending`,
    );
    const resultData = response.data;
    return resultData;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! failure in fetching trending posts');
    }
  }
};

export const fetchPostById = async (postId) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_SURFACE_V2}/${CONSTANT.API_POST}/get-post-by-id/${postId}`,
    );
    const resultData = response.data;
    return resultData;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! failure in fetching post details');
    }
  }
};
export const sharePostById = async (postId) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_POST}/share-post/${postId}`,
    );
    const resultData = response.data;
    return resultData;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! failure in sharing post details');
    }
  }
};

export const fetchAnnouncementDetails = async (pageSize, pageIndex) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_SURFACE_V2}/${CONSTANT.API_ANNOUNCEMENT}/get-all-announcements?pageSize=${pageSize}&pageIndex=${pageIndex}`,
      {},
    );
    const resultData = response.data;
    return resultData;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! failure in fetching announcements');
    }
  }
};

export const createPost = async (reqData, type) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_SURFACE_V2}/${CONSTANT.API_POST}/create-post`,
      reqData,
      type,
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! post not posted');
    }
  }
};
export const editPost = async (reqData, type) => {
  try {
    const response = await patch(
      `${CONSTANT.API_URL}/${CONSTANT.API_SURFACE_V2}/${CONSTANT.API_POST}/update-post`,
      reqData,
      type,
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! post not posted');
    }
  }
};
export const deletePost = async (postId) => {
  try {
    const response = await del(
      `${CONSTANT.API_URL}/${CONSTANT.API_SURFACE_V2}/${CONSTANT.API_POST}/delete-post/${postId}`,
      {},
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! post not deleted');
    }
  }
};

export const deleteCelebration = async (id) => {
  try {
    const response = await del(
      `${CONSTANT.API_URL}/${CONSTANT.API_SURFACE_V2}/${CONSTANT.API_CELEBRATE}/delete-celebration/${id}`,
      {},
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! post not deleted');
    }
  }
};

export const pinPost = async (body) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_SURFACE_V2}/social/pin`,
      body,
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! post not deleted');
    }
  }
};

export const report = async (reqData, id) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_SURFACE_V2}/${CONSTANT.API_FLAG}/set-flag/${id}`,
      reqData,
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! post was not reported');
    }
  }
};
export const reportComment = async (reqData, commentId) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_SOCIAL}/flag-comment/${commentId}`,
      reqData,
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! comment was not reported');
    }
  }
};

export const addComment = async (reqBody) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_SURFACE_V2}/${CONSTANT.API_SOCIAL}/create-comment`,
      reqBody,
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! blog not posted');
    }
  }
};
// Delete comment
export const deleteSocial = async (id) => {
  try {
    const response = await del(
      `${CONSTANT.API_URL}/${CONSTANT.API_SURFACE_V2}/${CONSTANT.API_SOCIAL}/delete-comment/${id}`,
      {},
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! social not deleted');
    }
  }
};
// like and unlike
export const likeUnlike = async (reqBody) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_SURFACE_V2}/${CONSTANT.API_SOCIAL}/like-unlike`,
      reqBody,
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! Post not like/unlike');
    }
  }
};

export const savePost = async (body) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_SURFACE_V2}/social/save`,
      body,
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! blog not posted');
    }
  }
};
export const getTaskInfo = async (id, sourceId, sourceName, status) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_SURFACE_V2}/user-task/${id}?sourceId=${sourceId}&sourceName=${sourceName}&status=${status}`,
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! failure in getting task info');
    }
  }
};
export const getUserTaskComments = async (id) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_SURFACE_V2}/user-tasks-comment/${id}`,
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! failure in getting task comment');
    }
  }
};
export const createUserTaskComment = async (payload) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_SURFACE_V2}/user-tasks-comment/create`,
      payload,
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! failure in creating task comment');
    }
  }
};

export const getPostComments = async ({ pageParam, queryKey }) => {
  const payload = {
    sourceType: pageParam?.sourceType || queryKey[2],
    sourceId: pageParam?.sourceId || queryKey[1],
    parentId: '',
    view: {
      likes: false,
      comments: true,
    },
    likes: {
      pageIndex: 0,
      pageSize: 0,
    },
    comments: {
      pageIndex: pageParam?.pageIndex || 0,
      pageSize: 2,
    },
  };

  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_SURFACE_V2}/${CONSTANT.API_SOCIAL}/get-all-socials-by-source`,
      payload,
    );
    return response?.data;
  } catch (error) {
    throw new AxiosError(error);
  }
};
