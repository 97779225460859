export const rbacFlags = {
  BenefitsWorkflowAdmin: 'BenefitsWorkflowAdmin',
  ProvideAccessToDownloadReports: 'ProvideAccessToDownloadReports',
  PolicyWorkflowAdmin: 'PolicyWorkflowAdmin',
  ExternalSalaryPositioning: 'ExternalSalaryPositioning',
  CompensationWorksheetTransferBudget: 'CompensationWorksheetTransferBudget',
  ExternalTeamMembersInJob: 'ExternalTeamMembersInJob',
  PendingNomination: 'PendingNomination',
  Provideaccesstomanagecomapnysettings: 'Provideaccesstomanagecomapnysettings',
  CompensationWorkflowAdmin: 'CompensationWorkflowAdmin',
  InternalSalaryPositioning: 'InternalSalaryPositioning',
  RecognitionHistory: 'RecognitionHistory',
  InternalTeamMembersInJob: 'InternalTeamMembersInJob',
  ProvideaccesstoreportsofPolicyreports:
    'ProvideaccesstoreportsofPolicyreports',
  RecognitionChampions: 'RecognitionChampions',
  ManagePlannerAndRecommender: 'ManagePlannerAndRecommender',
  RecognitionCertificate: 'RecognitionCertificate',
  EsopTab: 'EsopTab',
  ManagePostSettings: 'ManagePostSettings',
  CompensationWorksheets: 'CompensationWorksheets',
  PerformanceAndJobHistory: 'PerformanceAndJobHistory',
  IncrementCycleHistory: 'IncrementCycleHistory',
  Managerecognitionprogramsandawards: 'Managerecognitionprogramsandawards',
  ManagePost: 'ManagePost',
  ManageAnnouncement: 'ManageAnnouncement',
  ViewCompanyPolicies: 'ViewCompanyPolicies',
  Manageorganisationwallets: 'Manageorganisationwallets',
  ManageEvents: 'ManageEvents',
  RewardsPhilosophy: 'RewardsPhilosophy',
  ManageCommunities: 'ManageCommunities',
  ManageCreateCommunities: 'ManageCreateCommunities',
  SurfaceContentModerator: 'SurfaceContentModerator',
  Manageadhocrevisions: 'Manageadhocrevisions',
  RecognitionWorkflowAdmin: 'RecognitionWorkflowAdmin',
  Provideaccesstocreateandeditreports: 'Provideaccesstocreateandeditreports',
  ManagePayRangeBuilder: 'ManagePayRangeBuilder',
  PayRangeBuilderAdmin: 'PayRangeBuilderAdmin',
  ManageCreatesurveys: 'ManageCreatesurveys',
  ManageCompensationBonus: 'ManageCompensationBonus',
  IncrementPhilosophy: 'IncrementPhilosophy',
  CompensationWorksheetsAdmin: 'CompensationWorksheetsAdmin',
  SeekCompRecommendation: 'SeekCompRecommendation',
  ProvideaccesstoreportsofCompensationreports:
    'ProvideaccesstoreportsofCompensationreports',
  Viewinsights: 'Viewinsights',
  Futuredatecomp: 'Futuredatecomp',
  ManageLetterTemplate: 'ManageLetterTemplate',
  EnrolAndClaimInsurance: 'EnrolAndClaimInsurance',
  ManageInsuranceClaim: 'ManageInsuranceClaim',
  ManageOffer: 'ManageOffer',
  ViewEvents: 'ViewEvents',
  TransactEquity: 'TransactEquity',
  Accesstoredeempoints: 'Accesstoredeempoints',
  Accessandviewbenefitsforself: 'Accessandviewbenefitsforself',
  ManageBenefitsTab: 'ManageBenefitsTab',
  Managecompensationplans: 'Managecompensationplans',
  ManagePolls: 'ManagePolls',
  SummaryTab: 'SummaryTab',
  ManageComments: 'ManageComments',
  ManageCompensation: 'ManageCompensation',
  Downloadreportsforvariousmodules: 'Downloadreportsforvariousmodules',
  ManageLike: 'ManageLike',
  ManageShare: 'ManageShare',
  ManageTrendingwidget: 'ManageTrendingwidget',
  ManageMoodscore: 'ManageMoodscore',
  ManageCelebrate: 'ManageCelebrate',
  ManageCompFlags: 'ManageCompFlags',
  ManageCompComments: 'ManageCompComments',
  ManageTotalRewardsSummary: 'ManageTotalRewardsSummary',
  'ManageNominees&dependentstab': 'ManageNominees&dependentstab',
  Administerandmanagecompanybenefits: 'Administerandmanagecompanybenefits',
  ManageCompensationHistory: 'ManageCompensationHistory',
  Viewpaymixforemployee: 'Viewpaymixforemployee',
  ManageSalaryPositioning: 'ManageSalaryPositioning',
  ManageLongTermincentives: 'ManageLongTermincentives',
  ManageRecommendation: 'ManageRecommendation',
  Nominateothersforawards: 'Nominateothersforawards',
  Praiseothers: 'Praiseothers',
  ProvideaccesstoreportsofESOPreports: 'ProvideaccesstoreportsofESOPreports',
  ProvideaccesstoreportsofBenefitsreports:
    'ProvideaccesstoreportsofBenefitsreports',
  ProvideaccesstoreportsofProfilereports:
    'ProvideaccesstoreportsofProfilereports',
  ProvideaccesstoreportsofRecognitionreports:
    'ProvideaccesstoreportsofRecognitionreports',
  ProvideaccesstoreportsofSurfaceReports:
    'ProvideaccesstoreportsofSurfaceReports',
  ManageForecastsandsimulations: 'ManageForecastsandsimulations',
  ManageStatements: 'ManageStatements',
  Managecompanypolicies: 'Managecompanypolicies',
  Viewaddandeditmastertables: 'Viewaddandeditmastertables',
  Provideaccesstomanagepermissionsforotherusers:
    'Provideaccesstomanagepermissionsforotherusers',
  ProvideaccesstoreportsofAdminreports: 'ProvideaccesstoreportsofAdminreports',
  ManageRecognition: 'ManageRecognition',
  MobileNumber: 'MobileNumber',
  EmailAddress: 'EmailAddress',
};
