const SettingIcon = (props) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.82936 15.1424L7.3164 16.2378C7.46118 16.5639 7.69747 16.8409 7.99659 17.0353C8.29571 17.2298 8.64483 17.3332 9.00159 17.3332C9.35835 17.3332 9.70746 17.2298 10.0066 17.0353C10.3057 16.8409 10.542 16.5639 10.6868 16.2378L11.1738 15.1424C11.3472 14.7538 11.6388 14.4297 12.0071 14.2165C12.3778 14.0027 12.8066 13.9116 13.2321 13.9563L14.4238 14.0832C14.7785 14.1207 15.1365 14.0545 15.4544 13.8926C15.7722 13.7307 16.0363 13.4801 16.2145 13.1711C16.393 12.8623 16.4781 12.5084 16.4593 12.1522C16.4406 11.7961 16.3189 11.453 16.109 11.1647L15.4034 10.1952C15.1522 9.84745 15.018 9.42885 15.0201 8.99984C15.02 8.57199 15.1555 8.15513 15.4071 7.8091L16.1127 6.83965C16.3226 6.5513 16.4443 6.20823 16.463 5.85207C16.4818 5.4959 16.3967 5.14195 16.2183 4.83317C16.04 4.5242 15.7759 4.27358 15.4581 4.11169C15.1402 3.94981 14.7822 3.88361 14.4275 3.92113L13.2358 4.04799C12.8103 4.09268 12.3815 4.00161 12.0108 3.7878C11.6418 3.57338 11.3501 3.24764 11.1775 2.85725L10.6868 1.76187C10.542 1.43581 10.3057 1.15877 10.0066 0.964336C9.70746 0.769906 9.35835 0.666446 9.00159 0.666504C8.64483 0.666446 8.29571 0.769906 7.99659 0.964336C7.69747 1.15877 7.46118 1.43581 7.3164 1.76187L6.82936 2.85725C6.6568 3.24764 6.36509 3.57338 5.99603 3.7878C5.62538 4.00161 5.19659 4.09268 4.77103 4.04799L3.57566 3.92113C3.22094 3.88361 2.86294 3.94981 2.54509 4.11169C2.22724 4.27358 1.96317 4.5242 1.78492 4.83317C1.60644 5.14195 1.52141 5.4959 1.54014 5.85207C1.55888 6.20823 1.68058 6.5513 1.89048 6.83965L2.59603 7.8091C2.84765 8.15513 2.98315 8.57199 2.98307 8.99984C2.98315 9.42768 2.84765 9.84455 2.59603 10.1906L1.89048 11.16C1.68058 11.4484 1.55888 11.7914 1.54014 12.1476C1.52141 12.5038 1.60644 12.8577 1.78492 13.1665C1.96335 13.4753 2.22744 13.7258 2.54525 13.8877C2.86306 14.0495 3.22096 14.1158 3.57566 14.0785L4.76733 13.9517C5.19288 13.907 5.62167 13.9981 5.99233 14.2119C6.36277 14.4257 6.65583 14.7515 6.82936 15.1424Z"
        stroke={`${props?.isActive ? '#fff' : '#475467'}`}
        strokeWidth="1.33"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.0001 11.4998C10.3808 11.4998 11.5001 10.3805 11.5001 8.99984C11.5001 7.61913 10.3808 6.49984 9.0001 6.49984C7.61939 6.49984 6.5001 7.61913 6.5001 8.99984C6.5001 10.3805 7.61939 11.4998 9.0001 11.4998Z"
        stroke={`${props?.isActive ? '#fff' : '#475467'}`}
        strokeWidth="1.33"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SettingIcon;
