import PopUp from 'components/atoms/popUp/PopUp';
import { rbacFlags } from 'config/rbacFlags';
import { conditions, subItemsConditions } from 'config/sideNavConfig';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  checkModuleAccess,
  checkModuleAccessSidebar,
  checkRoleAccess,
  isSurface,
} from 'utils/accessControl';
import { v4 } from 'uuid';
import { AppIconWithPopUp } from './AppIconWithPopUp';

export const AppsList = ({
  navItems,
  label,
  setPopUpVisible,
  popUpVisible,
  type,
  activePopUpId,
  setActivePopUpId,
  setAccess,
  access,
}) => {
  const modules = useSelector((state) => state.theme?.rbac);
  const userAccess = useSelector((state) => state.theme?.userAccess);
  const hoverTimeout = useRef(null);
  const announcementItem = {
    id: v4(),
    name: 'Announcement',
    key: 'SurfaceAnnouncement',
    url: 'surface/announcements/Announcements',
    sublinks: [],
    groupType: 'AdminControl',
    module: 'SurfaceAnnouncement',
  };

  const [appsList, setAppsList] = useState([]);

  useEffect(() => {
    const data = [
      ...(navItems?.primaryTabs || []),
      ...(navItems?.about || []),
      ...(navItems?.workTools || []),
      ...(navItems?.admin || []),
    ];
    if (type === 'AdminControl') {
      setAppsList([...data].sort((a, b) => a.name.localeCompare(b.name)));
    }
    if (type === 'TeamApps') {
      setAppsList([...data].sort((a, b) => a.name.localeCompare(b.name)));
    }
    if (type === 'MyApps') {
      setAppsList(
        data
          ?.map((item) => {
            if (item?.name === 'Recognition') {
              return {
                ...item,
                sublinks: [
                  {
                    id: v4(),
                    name: 'Recognize',
                    groupType: 'MyApps',
                    url: 'surface',
                    sublinks: [],
                  },
                  ...(item?.sublinks || []),
                ],
              };
            }
            return item;
          })
          .filter(
            (item) =>
              item?.name !== 'Home' && item?.name !== 'Hiring Intelligence',
          )
          .sort((a, b) => a.name.localeCompare(b.name)),
      );
    }
  }, [type]);

  const handleHoverIn = (item) => {
    hoverTimeout.current = setTimeout(() => {
      if (item?.sublinks?.length) {
        setPopUpVisible(true);
        setActivePopUpId(`${type}${item?.id}`);
      }
    }, 200);
  };

  const handleHoverOut = (item) => {
    if (hoverTimeout.current) {
      clearTimeout(hoverTimeout.current);
      hoverTimeout.current = null;
    }
    if (item?.sublinks?.length) {
      setPopUpVisible(false);
      setActivePopUpId('');
    }
  };

  return (
    <div className="space-y-6">
      {access && (
        <p className="text-gray-900 text-lg font-semibold border-b border-gray-200 pb-3">
          {label}
        </p>
      )}
      <div className="flex flex-wrap w-full pb-4 gap-3">
        {checkModuleAccess(modules, 'SurfaceAnnouncement') &&
          type === 'AdminControl' && (
            <AppIconWithPopUp item={announcementItem} type={type} />
          )}
        {appsList?.map((item) => {
          if (
            checkModuleAccessSidebar(
              modules,
              item?.module,
              item?.name,
              userAccess,
            ) ||
            !item?.module ||
            (item?.module === 'Surface' && isSurface(modules))
          ) {
            if (
              conditions.some(
                (cond) =>
                  cond.name === item.name &&
                  !checkRoleAccess(
                    userAccess,
                    cond?.moduleName,
                    cond.roleAccess,
                  ),
              )
            ) {
              return null;
            }
            if (
              item?.groupType === 'AdminControl' ||
              item?.sublinks?.filter(
                (item) => item?.groupType === 'AdminControl',
              )?.length > 0 ||
              (!item?.sublinks?.length && item?.groupType === 'AdminControl') ||
              checkModuleAccess(modules, 'SurfaceAnnouncement')
            ) {
              type === 'AdminControl' && setAccess(true);
            }
            if (
              item?.groupType === 'MyApps' ||
              item?.sublinks?.filter((item) => item?.groupType === 'MyApps')
                ?.length > 0 ||
              (!item?.sublinks?.length && item?.groupType === 'MyApps')
            ) {
              type === 'MyApps' && setAccess(true);
            }
            if (
              item?.groupType === 'TeamApps' ||
              item?.sublinks?.filter((item) => item?.groupType === 'TeamApps')
                ?.length > 0 ||
              (!item?.sublinks?.length && item?.groupType === 'TeamApps')
            ) {
              type === 'TeamApps' && setAccess(true);
            }
            return (
              <>
                {((item?.name !== 'Hiring Intelligence' &&
                  item?.sublinks?.filter((item) => item?.groupType === type)
                    ?.length > 0) ||
                  (!item?.sublinks?.length && item?.groupType === type) ||
                  (item?.name === 'Hiring Intelligence' &&
                    item?.sublinks?.filter(
                      (sublink) =>
                        sublink?.name !== 'Create Offer' &&
                        sublink?.name !== 'Letter Templates' &&
                        sublink?.groupType === type,
                    )?.length > 0)) &&
                  item?.name !== 'Home' && (
                    <div
                      className="flex flex-wrap items-start gap-3"
                      key={item?.id}
                      // onMouseEnter={() => handleHoverIn(item)}
                      onMouseLeave={() => handleHoverOut(item)}
                    >
                      <AppIconWithPopUp
                        item={item}
                        type={type}
                        handleHoverIn={handleHoverIn}
                      />
                      {popUpVisible &&
                        activePopUpId === `${type}${item?.id}` &&
                        item?.sublinks?.length > 0 && (
                          <div className="absolute">
                            <PopUp
                              className={`rounded-xl top-[-0.5rem] left-24 ${
                                item?.sublinks?.filter(
                                  (sublink) => sublink?.groupType === type,
                                )?.length >= 3
                                  ? 'w-96'
                                  : 'w-72'
                              }`}
                              closePopup={() => setPopUpVisible(false)}
                            >
                              <div
                                style={{
                                  background:
                                    'linear-gradient(89.75deg, var(--primaryLeft50) 0%, var(--primaryRight50) 98.02%)',
                                }}
                                className="p-4 rounded-xl space-y-2"
                              >
                                <p className="font-semibold text-gray-900">
                                  {item?.name}
                                </p>
                                <div className="flex items-start flex-wrap gap-3 p-2">
                                  {item?.sublinks?.map((sublink) => {
                                    if (
                                      item.name !== 'Compensation Planning' &&
                                      item?.name !== 'Announcements' &&
                                      item?.name !== 'Recognize' &&
                                      subItemsConditions.some(
                                        (nav) =>
                                          nav.name === item.name &&
                                          !checkRoleAccess(
                                            userAccess,
                                            nav.moduleName,
                                            nav?.roleAccess,
                                          ),
                                      )
                                    ) {
                                      return null;
                                    } else if (
                                      item.name === 'Compensation Planning'
                                    ) {
                                      if (
                                        checkRoleAccess(
                                          userAccess,
                                          'Compensation',
                                          rbacFlags.CompensationWorksheets,
                                        ) ||
                                        checkRoleAccess(
                                          userAccess,
                                          'Compensation',
                                          rbacFlags.CompensationWorksheetsAdmin,
                                        )
                                      ) {
                                        return (
                                          <>
                                            {sublink?.groupType === type && (
                                              <AppIconWithPopUp
                                                key={sublink?.id}
                                                item={sublink}
                                              />
                                            )}
                                          </>
                                        );
                                      } else {
                                        return null;
                                      }
                                    } else {
                                      return (
                                        <>
                                          {sublink?.groupType === type && (
                                            <AppIconWithPopUp
                                              key={sublink?.id}
                                              item={sublink}
                                            />
                                          )}
                                        </>
                                      );
                                    }
                                  })}
                                </div>
                              </div>
                            </PopUp>
                          </div>
                        )}
                    </div>
                  )}
              </>
            );
          }
        })}
      </div>
    </div>
  );
};
