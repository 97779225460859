const RecognitionLogo = ({ isActive, activeColor, width, height }) => {
  return (
    <div>
      <svg
        width={width ? width : '16'}
        height={height ? height : '20'}
        viewBox="0 0 16 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.83333 12.5752V18.3333L7.75241 16.7657C7.84387 16.7291 7.88961 16.7108 7.93683 16.7036C7.9787 16.6972 8.0213 16.6972 8.06317 16.7036C8.11039 16.7108 8.15613 16.7291 8.24759 16.7657L12.1667 18.3333V12.5752M14.25 7.91667C14.25 11.3684 11.4518 14.1667 8 14.1667C4.54822 14.1667 1.75 11.3684 1.75 7.91667C1.75 4.46489 4.54822 1.66667 8 1.66667C11.4518 1.66667 14.25 4.46489 14.25 7.91667Z"
          stroke={isActive ? activeColor : '#475467'}
          strokeWidth="1.67"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default RecognitionLogo;
