import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  apiData: {},
};

const nominationApprovalSlice = createSlice({
  name: 'nominationApproval',
  initialState,
  reducers: {
    setData(state, action) {
      state.apiData = action.payload;
      return state;
    },
  },
});

export const { setData } = nominationApprovalSlice.actions;
export default nominationApprovalSlice.reducer;
