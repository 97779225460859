const DashboardIcon = (props) => (
  <svg
    width={props?.width ? props?.width : '20'}
    height={props?.height ? props?.height : '20'}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.3333 3.33341C14.1083 3.33341 14.4958 3.33341 14.8137 3.4186C15.6765 3.64977 16.3503 4.32364 16.5815 5.18637C16.6667 5.50428 16.6667 5.89177 16.6667 6.66675V14.3334C16.6667 15.7335 16.6667 16.4336 16.3942 16.9684C16.1545 17.4388 15.7721 17.8212 15.3017 18.0609C14.7669 18.3334 14.0668 18.3334 12.6667 18.3334H7.33334C5.93321 18.3334 5.23315 18.3334 4.69837 18.0609C4.22796 17.8212 3.84551 17.4388 3.60583 16.9684C3.33334 16.4336 3.33334 15.7335 3.33334 14.3334V6.66675C3.33334 5.89177 3.33334 5.50428 3.41853 5.18637C3.6497 4.32364 4.32357 3.64977 5.1863 3.4186C5.50421 3.33341 5.8917 3.33341 6.66668 3.33341M8.00001 5.00008H12C12.4667 5.00008 12.7001 5.00008 12.8783 4.90925C13.0351 4.82936 13.1626 4.70188 13.2425 4.54507C13.3333 4.36681 13.3333 4.13346 13.3333 3.66675V3.00008C13.3333 2.53337 13.3333 2.30002 13.2425 2.12176C13.1626 1.96495 13.0351 1.83747 12.8783 1.75758C12.7001 1.66675 12.4667 1.66675 12 1.66675H8.00001C7.5333 1.66675 7.29994 1.66675 7.12168 1.75758C6.96488 1.83747 6.8374 1.96495 6.7575 2.12176C6.66668 2.30002 6.66668 2.53337 6.66668 3.00008V3.66675C6.66668 4.13346 6.66668 4.36681 6.7575 4.54507C6.8374 4.70188 6.96488 4.82936 7.12168 4.90925C7.29994 5.00008 7.5333 5.00008 8.00001 5.00008Z"
      stroke={
        props.isActive
          ? props.activeColor
          : props.isHovered
          ? props.hoveredColor
          : '#475467'
      }
      strokeWidth="1.67"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default DashboardIcon;
