import Avatar from 'layouts/header/Avatar';
import ToastNotify from 'pages/surface/ToastNotify';
import { toast } from 'react-toastify';
import { v4 } from 'uuid';
import { imageURLCheck } from './ImageUrlCheck';

export const formatRow = (currentTabValue) => {
  // const keyConfig = {
  //   source: 'sourceColumnId',
  //   holdBudget: 'holdBudget',
  //   value: 'valueOfPercent',
  // };
  const rows = [];
  currentTabValue?.rows?.forEach((row, index) => {
    for (const key in row) {
      if (key === 'formulaDetails') {
        rows[index] = {
          ...rows?.[index],
          [key]: row[key] || {
            formulaDetails: '',
            formulaString: '',
            // databaseDependencies: {},
            dependencyChainMap: {},
          },
        };
      } else if (
        currentTabValue?.aditionalBudget &&
        key.includes('add_budget')
      ) {
        if (!rows[index].worklowStepBudgets && row[key]?.displayFormula) {
          rows[index].worklowStepBudgets = [
            {
              stepIndex: +key.split('_')[2],
              formulaDetails: row[key] || {},
            },
          ];
        } else if (row[key]?.displayFormula) {
          rows[index].worklowStepBudgets.push({
            stepIndex: +key.split('_')[2],
            formulaDetails: row[key],
          });
        }
      } else {
        const columnId = currentTabValue?.columns?.find(
          (ele) => ele.fieldId === key,
        );
        if (columnId?.name?.id) {
          rows[index] = {
            ...rows[index],
            columns: {
              ...rows?.[index]?.columns,
              [Array.isArray(row[key]) ? 'user' : columnId?.name?.id]:
                Array.isArray(row[key])
                  ? {
                      users: row[key]?.map((ele) => ele?.id),
                    }
                  : {
                      value:
                        typeof row[key] === 'object' && row[key] !== null
                          ? row[key]?.value
                          : row?.[key],
                    },
            },
          };
        }
      }
    }
  });
  return rows
    ?.filter((ele) => ele)
    ?.map((ele, index) => ({
      ...ele,
      order: index + 1,
    }));
};

export const formatColumns = (currentTabValue) => {
  const header = [];
  currentTabValue?.columns?.forEach(({ name }, index) => {
    if (name) {
      header.push({
        order: index + 1,
        columnId: name?.id,
        alias: name?.label,
      });
    }
  });
  return header;
};

export const generatePlanTypeColumn = (status, workflowLevelCount) => {
  const id = v4();
  return {
    rows: [
      {
        [id]: '',
        formulaDetails: '',
        add_budget_1: '',
        add_budget_2: '',
        add_budget_3: '',
        add_budget_4: '',
      },
    ],
    columns: [{ name: null, fieldId: id }],
    status,
    workflowLevelCount,
    additionalBudget: true,
  };
};

export const formatOptionLabelBudget = ({ label, avatar, displayName, id }) => (
  <div className="rounded-lg flex gap-2 items-center !p-0">
    <Avatar userName={label} image={imageURLCheck(avatar)} />
    {/* <img
      className="h-4 w-4 rounded-full"
      src={imageURLCheck(avatar)}
      alt="avatar"
    /> */}
    <div>
      <p className="text-xs">{label}</p>
    </div>
  </div>
);

export const onResponseBudget = (message) => {
  toast(<ToastNotify message={message} close={() => {}} />, {
    onClose: () => {},
    autoCloase: 2000,
    className:
      'toast-message absolute w-full h-full py-4 px-[30px] bg-white gap-4 flex flex-col border border-gray-200 shadow-md rounded-lg',
    zIndex: 1000,
  });
};

export const filterOptionsBudget = (rowOptions, currentTabValue) =>
  rowOptions?.filter((option) => {
    if (option.value === 'employee' || option.value === 'planner') {
      if (!currentTabValue?.columns?.[0]?.name) {
        return true;
      }
      if (
        currentTabValue?.columns?.[0]?.name?.value !== 'employee' &&
        currentTabValue?.columns?.[0]?.name?.value !== 'planner'
      ) {
        return false;
      }
      return true;
    }
    if (
      currentTabValue?.columns?.some((ele) => ele?.name?.value === option.value)
    ) {
      return false;
    }
    return true;
  });

export const getCurrentSubTypeValueBudget = (
  budgetRules,
  formatResult,
  budgetType,
) => {
  //   const sidePanelConfig = {
  //     sourceColumnId: 'source',
  //     holdBudget: 'holdBudget',
  //     valueOfPercent: 'value',
  //   };
  const columns = [];
  const rows = [];

  const fieldId = v4();

  if (budgetRules?.header?.[0]?.columnId?.toLowerCase() === 'users') {
    columns.push({
      fieldId,
      name: {
        id: 'users',
        label: budgetType === 'EmployeeLevel' ? 'Employee' : 'Planner',
        value: budgetType === 'EmployeeLevel' ? 'Employee' : 'Planner',
      },
    });
    budgetRules?.rows?.forEach((row) => {
      const newRow = {};
      Object.keys(row)?.forEach((rowKey) => {
        if (rowKey === 'formulaDetails') {
          newRow.formulaDetails = row?.[rowKey];
        } else if (rowKey === 'worklowStepBudgets') {
          row?.[rowKey]?.forEach((step) => {
            newRow[`add_budget_${step.stepIndex}`] = step?.formulaDetails;
          });
        }
      });
      rows.push({
        [fieldId]: row?.columns?.user?.users?.map((ele) => ({
          displayName: formatResult?.[ele]?.displayName,
          avatar: formatResult?.[ele]?.avatar,
          label: formatResult?.[ele]?.fullName,
          value: formatResult?.[ele]?.id,
          id: formatResult?.[ele]?.id,
        })),
        ...newRow,
        // source: {
        //   id: formatResult[row?.sourceColumnId]?.id,
        //   label: formatResult[row?.sourceColumnId]?.value,
        //   value: formatResult[row?.sourceColumnId]?.value,
        // },
        // holdBudget: row?.holdBudget,
        // value: row?.valueOfPercent,
      });
    });
    return {
      columns,
      rows,
    };
  }
  budgetRules?.header?.forEach((ele) => {
    const fieldId = v4();
    columns.push({
      fieldId,
      name: {
        id: ele?.columnId,
        label: formatResult[ele?.columnId]?.value,
        value: formatResult[ele?.columnId]?.value,
        dataType: formatResult[ele?.columnId]?.dataType,
      },
    });
  });

  budgetRules?.rows?.forEach((ele) => {
    const row = {};
    Object.keys(ele.columns)?.forEach((key) => {
      const rowKeyId = columns?.find(({ name }) => key === name?.id);
      row[rowKeyId?.fieldId] =
        rowKeyId?.name?.dataType === 'Text' ||
        rowKeyId?.name?.dataType === 'TextArea' ||
        rowKeyId?.name?.dataType === 'Date' ||
        rowKeyId?.name?.dataType === 'Number' ||
        rowKeyId?.name?.dataType === 'Integer' ||
        rowKeyId?.name?.dataType === 'Amount'
          ? ele?.columns?.[key]?.value
          : {
              id: ele?.columns?.[key]?.value,
              label: ele?.columns?.[key]?.value,
              value: ele?.columns?.[key]?.value,
            };
    });
    Object.keys(ele)?.forEach((rowKey) => {
      if (rowKey === 'formulaDetails') {
        row.formulaDetails = ele?.[rowKey];
      } else if (rowKey === 'worklowStepBudgets') {
        ele?.[rowKey]?.forEach((step) => {
          row[`add_budget_${step.stepIndex}`] = step?.formulaDetails;
        });
      }
    });
    rows.push(row);
  });
  return {
    rows,
    columns,
    exceedBudget: budgetRules?.exceedBudget,
  };
};

export const calculateTotalBudgetType = (
  data,
  tableType,
  budgetType,
  total,
) => {
  // calculate total budget for each table (budget type)
  if (!data?.length) {
    return total;
  }
  return data?.reduce((preValue, row) => {
    let currentTotal = 0;
    Object.keys(row?.budget?.[tableType] || {}).forEach((key) => {
      currentTotal += Number(
        row?.budget?.[tableType]?.[key]?.mainBudget?.[budgetType] || 0,
      );
    });
    return calculateTotalBudgetType(
      row?.next,
      tableType,
      budgetType,
      currentTotal + preValue,
    );
  }, total);
};

export const calculateTotalBudgetsubType = (
  data,
  tableType,
  tableSubType,
  budgetType,
  total,
) => {
  // calculate total budget for each table (budget sub type)
  if (!data?.length) {
    return total;
  }
  return data?.reduce((preValue, row) => {
    const currentTotal = Number(
      row?.budget?.[tableType]?.[tableSubType]?.mainBudget?.[budgetType] || 0,
    );
    return calculateTotalBudgetsubType(
      row?.next,
      tableType,
      tableSubType,
      budgetType,
      currentTotal + preValue,
    );
  }, total);
};

export const calculateTotalBudgetAdditional = (
  data,
  tableType,
  budgetType,
  additionalBudget,
  total,
) => {
  // calculate total aditional budget for each table (budget type)
  if (!data?.length) {
    return total;
  }
  return data?.reduce((preValue, row) => {
    let currentTotal = 0;
    Object.keys(row?.budget?.[tableType] || {}).forEach((key) => {
      currentTotal += Number(
        additionalBudget?.[`${row?.userId} - ${tableType} - ${key}`] || 0,
      );
    });
    return calculateTotalBudgetType(
      row?.next,
      tableType,
      budgetType,
      currentTotal + preValue,
    );
  }, total);
};

export const calculateTotalBudgetSubTypeAdditional = (
  data,
  tableType,
  tableSubType,
  budgetType,
  additionalBudget,
  total,
) => {
  // calculate total aditional budget for each table (budget sub type)
  if (!data?.length) {
    return total;
  }
  return data?.reduce((preValue, row) => {
    const currentTotal = Number(
      additionalBudget?.[`${row?.userId} - ${tableType} - ${tableSubType}`] ||
        0,
    );
    return calculateTotalBudgetSubTypeAdditional(
      row?.next,
      tableType,
      tableSubType,
      budgetType,
      additionalBudget,
      currentTotal + preValue,
    );
  }, total);
};

export const checkDecimalFour = (amount) => {
  return String(amount).split('.')?.[1]?.length > 4;
};
