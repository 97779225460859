import { getWorkflowHistory } from 'apiClient/userTask/task';
import TickIcon from 'assets/svg/tickIcon';
import TextBox from 'components/atoms/FormElements/textBox/TextBox';
import Button from 'components/atoms/button/Button';
import { formatDate } from 'pages/recognition/orderHistory/helper';
import { useTaskAction } from 'query/task/task';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fetchAndUpdatePendingTask } from 'store/reducers/taskSlice';
import { onResponseBudget } from 'utils/budgetUtils';

export const Reviews = ({ data, taskData }) => {
  const [taskHistoryData, setTaskHistoryData] = useState(null);
  const navigate = useNavigate();
  // const userTask = useSelector((state) => state.userTask);

  useEffect(() => {
    if (data?.workflowId) {
      getWorkflowHistory(data?.workflowId)
        .then((res) => {
          setTaskHistoryData(res?.history);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [data]);

  // const tasks = userTask?.apiData?.tasks ?? [];

  // const matchingTask = tasks.find((task) => task.sourceId === data?.policyData?.id);

  // const matchingTaskId = matchingTask ? matchingTask.id : null;

  const { mutateAsync: approveTask } = useTaskAction();

  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);
  const uId = user?.userProfileData?.userId?.value;
  const taskParams = [uId, 10, 0, '', 'Pending', true, 'count'];

  const handleApproveClick = async () => {
    try {
      const payload = {
        ids: [taskData?.id],
        actions: ['approve'],
      };
      await approveTask(payload)
        .then((res) => {
          onResponseBudget('Task Successfully Accepted');
          navigate('/task');
          dispatch(fetchAndUpdatePendingTask(taskParams));
        })
        .catch((err) => {
          onResponseBudget('Some error has Occurred');
        });
    } catch (error) {
      onResponseBudget(error?.message);
    }
  };

  const handleRejectClick = async () => {
    try {
      const payload = {
        ids: [taskData?.id],
        actions: ['reject'],
      };

      await approveTask(payload)
        .then((res) => {
          onResponseBudget('Task Successfully Rejected');
          navigate('/task');
          dispatch(fetchAndUpdatePendingTask(taskParams));
        })
        .catch((err) => {
          onResponseBudget('Some error has Occurred');
        });
    } catch (error) {
      onResponseBudget(error?.message);
    }
  };

  const isCompleted =
    taskData?.status === 'Approved' || taskData?.status === 'Rejected';

  return (
    <div>
      {isCompleted ? (
        <div className="flex justify-between border rounded-lg p-5">
          <h1 className="text-xs text-gray-600">Action taken : </h1>
          <p className="text-gray-900 text-xs font-semibold justify-end ">
            {taskData?.status}
          </p>
        </div>
      ) : (
        <div className="border rounded-lg p-5">
          <h1 className="text-gray-900 text-md font-semibold">Review</h1>
          <div className="py-1">
            <TextBox placeholder="Enter comment" className="h-32 text-sm" />
          </div>
          <div className="flex justify-end space-x-2 pt-5">
            <Button
              className="text-sm font-semibold"
              onClick={handleRejectClick}
            >
              Reject
            </Button>
            <Button
              className="text-sm font-semibold"
              variant="filled"
              onClick={handleApproveClick}
            >
              Approve
            </Button>
          </div>
        </div>
      )}

      <div className="mt-4">
        <div className=" border p-5 rounded-lg ">
          <div>
            <h1 className="font-semibold text-md text-gray-900">
              Basic Details
            </h1>
          </div>
          <div className="flex justify-between mt-3">
            <div>
              <h1 className="text-xs text-gray-600">Submitted By</h1>
              <h1 className="text-gray-900 text-xs font-semibold">
                {data?.submittedBy?.fullName?.value}
              </h1>
            </div>
            <div>
              <h1 className="text-xs text-gray-600">Submitted on</h1>
              <h1 className="text-gray-900 text-xs font-semibold">
                {formatDate(data?.submittedOn)}
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-5">
        <div className="flex flex-col gap-5 p-5 shadow-md border rounded-xl">
          <div className="flex">
            <p className="font-semibold font-figtree text-lg text-gray-900">
              Task History
            </p>
          </div>
          <div className="">
            <div className="border rounded-lg flex w-full p-5 bg-gray-100">
              <h1 className="w-1/2 text-xs text-gray-600">Employee</h1>
              <h1 className="w-1/2 text-xs text-gray-600">Comment</h1>
            </div>
            <div className="border-l">
              {taskHistoryData?.approvers?.map((approver, index) => {
                return (
                  <div
                    key={index}
                    className="relative flex justify-between mt-5"
                  >
                    {approver?.approved ? (
                      <span className="bg-gradient-to-br from-primaryLeft to-primaryRight text-white border w-5 h-5 absolute -left-2.5 top-0 rounded-full flex justify-center items-center">
                        <TickIcon />
                      </span>
                    ) : (
                      <div>
                        <span className="text-white border w-5 h-5 absolute -left-2.5 top-0 rounded-full flex justify-center items-center">
                          <span className="w-2.5 h-2.5 bg-gray-300 rounded-full" />
                        </span>
                      </div>
                    )}
                    <div className="ml-4 border p-4 rounded-lg w-full flex">
                      <div className="w-1/2">
                        <h1 className=" text-xs text-gray-900 ">
                          {approver?.approverDetails?.fullName?.value}
                        </h1>
                        <h1 className=" text-xs text-gray-900 ">
                          {approver?.approvedDate
                            ? formatDate(approver?.approvedDate)
                            : 'Not Approved'}
                        </h1>
                      </div>
                      <div className="text-sm w-1/2 text-gray-900">
                        <h1>-</h1>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
