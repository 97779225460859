import {
  createModuleBanner,
  createQuickLinks,
  deleteQuickLinks,
  getModuleBanner,
  getQuickLinks,
  getYourAppsDropDownValues,
  updateModuleBanner,
  updateQuickLinks,
} from 'apiClient/manageHome/manageHome';
import { useMutation, useQuery, useQueryClient } from 'react-query';

export const useGetQuickLinks = ({ isAdmin }) => {
  return useQuery(['get/quickLinks', isAdmin], async () => {
    return getQuickLinks({ isAdmin });
  });
};

export const useCreateQuickLinks = ({ onSuccess }) => {
  return useMutation(
    ['create/quickLinks'],
    async (payload) => {
      return createQuickLinks(payload);
    },
    {
      onSuccess: (res) => {
        onSuccess();
      },
    },
  );
};

export const useUpdateQuickLink = ({ onSuccess }) => {
  const queryClient = useQueryClient();
  return useMutation(
    ['update/quickLink'],
    async ({ id, payload }) => {
      return updateQuickLinks({ id, payload });
    },
    {
      onSuccess: (res) => {
        onSuccess();
        queryClient.invalidateQueries(['get/quickLinks']);
      },
    },
  );
};

export const useDeleteQuickLinks = ({ onDeleteSuccess }) => {
  const queryClient = useQueryClient();
  return useMutation(
    ['delete/quickLinks'],
    async (quickLinksId) => {
      return deleteQuickLinks(quickLinksId);
    },
    {
      onSuccess: (res) => {
        onDeleteSuccess();
        queryClient.invalidateQueries(['get/quickLinks']);
      },
    },
  );
};

export const useGetModuleBanner = ({ module }) => {
  return useQuery(['get/moduleBanner'], async () => {
    return getModuleBanner({ module });
  });
};

export const useCreateModuleBanner = () => {
  return useMutation(['create/moduleBanner'], async (payload) => {
    return createModuleBanner(payload);
  });
};
export const useUpdateModuleBanner = () => {
  return useMutation(['update/moduleBanner'], async (payload) => {
    return updateModuleBanner(payload);
  });
};

export const useGetYourAppsDropDown = ({ search }) => {
  return useQuery(['get/yourAppsDropDown'], async () => {
    return getYourAppsDropDownValues({ search });
  });
};
