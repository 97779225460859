import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  policyData: {},
  reApproval: false,
};

const eventReducer = createSlice({
  name: 'policy',
  initialState,
  reducers: {
    setPolicyData(state, action) {
      state.policyData = action.payload;
    },
    setPolicyReApproval(state, action) {
      state.reApproval = action.payload;
    },
  },
});

export const { setPolicyData, setPolicyReApproval } = eventReducer.actions;
export default eventReducer.reducer;
