import rightArrow from 'assets/svg/rightArrow.svg';
import search from 'assets/svg/search.svg';
import { imageURLCheck } from 'utils/ImageUrlCheck';
import { handleErrorImage } from 'utils/handleErrorImage';

const Error = () => {
  return (
    <div className="h-screen overflow-auto md:overflow-hidden md:flex">
      <div className="flex justify-center flex-1 mx-4 my-16 md:my-6 lg:my-10">
        <div className="flex flex-col justify-center">
          <span className="text-base font-semibold leading-6 text-transparent bg-clip-text bg-gradient-to-br from-primaryLeft to-primaryRight font-figtree">
            404 error
          </span>

          <h1 className="text-4xl lg:text-6xl mt-3 font-figtree font-semibold leading-[44px] tracking-tight pb-2 mb-4">
            We lost this page
          </h1>

          <p className="mb-8 text-lg font-normal text-gray-600 lg:text-xl font-figtree">
            Sorry, the page you are looking for doesn&apos;t exist.
          </p>

          <div className="flex flex-col gap-4 mb-8 lg:flex-row ">
            <div className="flex items-center w-full pl-4 bg-white border border-gray-300 rounded-lg sm:w-96">
              <img src={search} alt="search-icon" />
              <input
                type="text"
                placeholder="Search our site"
                className="w-full p-2 outline-none lg:p-3"
              />
            </div>

            <button
              type="button"
              className="flex items-center justify-center px-5 py-2 font-semibold text-white rounded-lg lg:py-3text-base bg-gradient-to-r from-primaryLeft to-primaryRight font-figtree "
            >
              Search
            </button>
          </div>

          <div className="">
            <div className="mb-6">
              <div className="flex items-center gap-3 mb-1">
                <p className="text-base font-semibold text-transparent cursor-pointer bg-clip-text bg-gradient-to-r from-primaryLeft to-primaryRight">
                  Documentation
                </p>
                <img
                  src={rightArrow}
                  alt="arrow-icon"
                  className="cursor-pointer"
                />
              </div>

              <p className="text-base font-normal text-gray-600 font-figtree">
                Dive in to learn all about our product.
              </p>
            </div>

            <div className="mb-6">
              <div className="flex items-center gap-3 mb-1">
                <p className="text-base font-semibold text-transparent cursor-pointer bg-clip-text bg-gradient-to-r from-primaryLeft to-primaryRight">
                  Our blog
                </p>
                <img
                  src={rightArrow}
                  alt="arrow-icon"
                  className="cursor-pointer"
                />
              </div>

              <p className="text-base font-normal text-gray-600 font-figtree">
                Read the latest posts on our blog.
              </p>
            </div>

            <div>
              <div className="flex items-center gap-3 mb-1">
                <span className="text-base font-semibold text-transparent cursor-pointer bg-clip-text bg-gradient-to-r from-primaryLeft to-primaryRight">
                  Chat to support
                </span>
                <img
                  src={rightArrow}
                  alt="arrow-icon"
                  className="cursor-pointer"
                />
              </div>

              <p className="text-base font-normal text-gray-600 font-figtree">
                Our friendly team is here to help.
              </p>
            </div>
            <div>
              <a href="/">
                <span className="text-base flex mt-4 cursor-pointer justify-center w-full font-semibold leading-6 text-transparent bg-clip-text bg-gradient-to-br from-primaryLeft to-primaryRight font-figtree">
                  Goto Home page
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="flex-1 mx-4 my-16 md:m-0 lg:m-0">
        <img
          src={imageURLCheck('/assets/pageNotFound.png')}
          alt="Img"
          className="object-cover w-full h-60 sm:h-full md:"
          onError={handleErrorImage}
        />
      </div>
    </div>
  );
};

export default Error;
