import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  createAnnouncement,
  fetchAnnouncements,
  fetchAnnouncement,
  updateAnnouncement,
  deleteAnnouncement,
  likeAnnouncement,
  commentAnnouncement,
  getSocialDetails,
} from 'apiClient/announcement/annoucement';
import { fetchFeelingEmotions } from 'apiClient/surface/surface';

const initialState = {
  feedCards: [],
  isLoading: true,
  pageCount: 0,
  pageLimit: 10,
  loadMoreLoader: false,
  loader: [],
  feelings: [],
  announcementList: [],
  announcement: {},
  announcementDetails: {},
  total: 0,
};

export const getFeelingsThunk = createAsyncThunk(
  'get/feelings',
  async (type) => {
    const response = await fetchFeelingEmotions(type);
    return response;
  },
);

export const createAnnouncementThunk = createAsyncThunk(
  'post/announcement',
  async (formData) => {
    const response = await createAnnouncement(formData, 'multipart/form-data');
    return response;
  },
);

export const getAnnouncementsThunk = createAsyncThunk(
  'get/announcements',
  async ({ pageSize, currentPage, payload }) => {
    const response = await fetchAnnouncements(pageSize, currentPage, payload);
    return response.data;
  },
);

export const getAnnouncementThunk = createAsyncThunk(
  'get/announcement',
  async (id) => {
    const response = await fetchAnnouncement(id);
    return response;
  },
);

export const updateAnnouncementThunk = createAsyncThunk(
  'update/announcement',
  async ({ id, payload }) => {
    const response = await updateAnnouncement(
      id,
      payload,
      'multipart/form-data',
    );
    return response;
  },
);

export const deleteAnnouncementThunk = createAsyncThunk(
  'delete/announcement',
  async ({ id, attachments }) => {
    const response = await deleteAnnouncement(id, attachments);
    return response;
  },
);

export const likeAnnouncementThunk = createAsyncThunk(
  'like/announcement',
  async (payload) => {
    const response = await likeAnnouncement(payload);
    return response;
  },
);

export const commentAnnouncementThunk = createAsyncThunk(
  'comment/announcement',
  async (payload) => {
    const response = await commentAnnouncement(payload);
    return response;
  },
);

export const getAnnouncementDetailsThunk = createAsyncThunk(
  'Details/announcement',
  async (payload) => {
    const response = await getSocialDetails(payload);
    return response;
  },
);

const surfaceSlice = createSlice({
  name: 'surfaceSlice',
  initialState,
  reducers: {
    setFeedCards(state, action) {
      if (state.pageCount > 0) {
        state.feedCards = [...state.feedCards, ...action.payload];
      } else {
        state.feedCards = action.payload;
      }
    },
    setFeedCardAfterPostCreated(state, action) {
      state.feedCards = [action.payload, ...state.feedCards];
    },
    updateFeedAfterEdit(state, action) {
      state.feedCards = state.feedCards.filter(
        (item) => item?.id !== action.payload,
      );
    },

    setFeedCardAfterPostDeleted(state, action) {
      state.feedCards = state.feedCards.filter(
        (item) => item?.id !== action.payload,
      );
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setPageCount(state, action) {
      state.pageCount = action.payload;
    },
    setLoadMoreLoader(state, action) {
      state.loadMoreLoader = action.payload;
    },
    setAnnouncementList(state, action) {
      const newList = [...state.announcementList];
      state.announcementList = newList.map((ele) => {
        if (ele.id === action.payload.id) {
          return action.payload;
        }
        return ele;
      });
      return state;
    },
    getFeedCards: (state, action) => {
      state.feedCards = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getFeelingsThunk.pending, (state, action) => {
      state.loader = [...state.loader, 'FEELING_LOADING'];
      return state;
    });
    builder.addCase(getFeelingsThunk.fulfilled, (state, action) => {
      state.loader = state.loader.filter((ele) => ele !== 'FEELING_LOADING');
      state.feelings = action.payload.emotions?.sort(
        (a, b) => a.order - b.order,
      );
      return state;
    });
    builder.addCase(createAnnouncementThunk.pending, (state, action) => {
      state.loader = [...state.loader, 'CREATE_ANNOUNCEMENT_LOADING'];
      return state;
    });
    builder.addCase(createAnnouncementThunk.fulfilled, (state, action) => {
      state.loader = state.loader.filter(
        (ele) => ele !== 'CREATE_ANNOUNCEMENT_LOADING',
      );
      return state;
    });
    builder.addCase(createAnnouncementThunk.rejected, (state, action) => {
      state.loader = state.loader.filter(
        (ele) => ele !== 'CREATE_ANNOUNCEMENT_LOADING',
      );
      return state;
    });
    builder.addCase(updateAnnouncementThunk.pending, (state, action) => {
      state.loader = [...state.loader, 'UPDATE_ANNOUNCEMENT_LOADING'];
      return state;
    });
    builder.addCase(updateAnnouncementThunk.fulfilled, (state, action) => {
      state.loader = state.loader.filter(
        (ele) => ele !== 'UPDATE_ANNOUNCEMENT_LOADING',
      );
      return state;
    });
    builder.addCase(updateAnnouncementThunk.rejected, (state, action) => {
      state.loader = state.loader.filter(
        (ele) => ele !== 'UPDATE_ANNOUNCEMENT_LOADING',
      );
      return state;
    });
    builder.addCase(getAnnouncementsThunk.pending, (state, action) => {
      state.loader = [...state.loader, 'LOADING_ANNOUNCEMENT'];
      return state;
    });
    builder.addCase(getAnnouncementsThunk.fulfilled, (state, action) => {
      state.loader = state.loader.filter(
        (ele) => ele !== 'LOADING_ANNOUNCEMENT',
      );
      state.announcementList = action.payload;
      state.total = action?.payload[0]?.total || 0;
      return state;
    });
    builder.addCase(getAnnouncementThunk.pending, (state, action) => {
      state.loader = [...state.loader, 'LOADING_INDI_ANNOUNCEMENT'];
      return state;
    });
    builder.addCase(getAnnouncementThunk.fulfilled, (state, action) => {
      state.loader = state.loader.filter(
        (ele) => ele !== 'LOADING_INDI_ANNOUNCEMENT',
      );
      state.announcement = action.payload;
      return state;
    });
    builder.addCase(getAnnouncementThunk.rejected, (state, action) => {
      state.loader = state.loader.filter(
        (ele) => ele !== 'LOADING_INDI_ANNOUNCEMENT',
      );
      return state;
    });
    builder.addCase(deleteAnnouncementThunk.pending, (state, action) => {
      state.loader = [...state.loader, 'DELETE_ANNOUNCEMENT'];
      return state;
    });
    builder.addCase(deleteAnnouncementThunk.fulfilled, (state, action) => {
      state.loader = state.loader.filter(
        (ele) => ele !== 'DELETE_ANNOUNCEMENT',
      );
      return state;
    });
    builder.addCase(deleteAnnouncementThunk.rejected, (state, action) => {
      state.loader = state.loader.filter(
        (ele) => ele !== 'DELETE_ANNOUNCEMENT',
      );
      return state;
    });
    builder.addCase(likeAnnouncementThunk.pending, (state) => {
      state.loader = [...state.loader, 'LIKE_ANNOUNCEMENT'];
      return state;
    });
    builder.addCase(likeAnnouncementThunk.fulfilled, (state) => {
      state.loader = state.loader.filter((ele) => ele !== 'LIKE_ANNOUNCEMENT');
      return state;
    });
    builder.addCase(commentAnnouncementThunk.pending, (state) => {
      state.loader = [...state.loader, 'COMMENT_ANNOUNCEMENT'];
      return state;
    });
    builder.addCase(commentAnnouncementThunk.fulfilled, (state) => {
      state.loader = state.loader.filter(
        (ele) => ele !== 'COMMENT_ANNOUNCEMENT',
      );
      return state;
    });
    builder.addCase(getAnnouncementDetailsThunk.pending, (state) => {
      state.loader = [...state.loader, 'DETAILS_ANNOUNCEMENT'];
      return state;
    });
    builder.addCase(getAnnouncementDetailsThunk.fulfilled, (state, action) => {
      state.loader = state.loader.filter(
        (ele) => ele !== 'DETAILS_ANNOUNCEMENT',
      );
      state.announcementDetails = action.payload;
      return state;
    });
  },
});

export const {
  setFeedCards,
  setFeedCardAfterPostCreated,
  setFeedCardAfterPostDeleted,
  setIsLoading,
  setPageCount,
  setLoadMoreLoader,
  updateFeedAfterEdit,
  setAnnouncementList,
  getFeedCards,
} = surfaceSlice.actions;
export default surfaceSlice.reducer;
