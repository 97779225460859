import React, { forwardRef } from 'react';

const Toggle = forwardRef((props, ref) => {
  const { label, disabled, width, height, afterClass, ...rest } = props;
  return (
    <label className="relative inline-flex items-center mr-5 cursor-pointer">
      <input
        type="checkbox"
        className="sr-only peer"
        disabled={disabled}
        {...rest}
        ref={ref}
      />
      <div
        className={`${width ? width : 'w-11'} ${
          height ? height : ' h-6'
        } bg-gray-300 rounded-full peer peer-focus:ring-0 peer-focus:ring-red-300 dark:peer-focus:ring-red-800 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full
        ${
          afterClass ? afterClass : 'after:h-5 after:w-5'
        } after:transition-all dark:border-gray-100 peer-checked:bg-gradient-to-br from-primaryLeft to-primaryRight ${
          disabled && 'cursor-not-allowed opacity-70'
        }`}
      ></div>
      {label && <label className="ml-3 text-sm text-gray-700">{label}</label>}
    </label>
  );
});

export default Toggle;
