import React from 'react';

const PropertyInput = React.forwardRef(
  (
    {
      label,
      error,
      type,
      required,
      placeholder,
      className,
      readonly,
      min,
      max,
      labelClassName,
      ...props
    },
    ref,
  ) => (
    <div className="w-full">
      {label && (
        <label
          htmlFor={props.id}
          className={`text-sm font-medium leading-5 text-gray-700 my-1 mb-1.5 ${labelClassName}`}
        >
          {label} {required && <span className="text-red-600">*</span>}
        </label>
      )}
      {label === 'Height' || label === 'Width' ? (
        <div
          className={`rounded-lg border flex ${
            error ? 'border-red-600' : 'border-gray-300'
          } ${className} items-center justify-center`}
        >
          <input
            className={`w-[90%] px-3 py-1.5 focus:border-primaryLeft-700  text-gray-500 bg-transparent  outline-none `}
            placeholder={placeholder}
            ref={ref}
            readOnly={readonly ? readonly : false}
            type={type}
            onWheel={(event) => event.target.blur()}
            min={min}
            max={max}
            {...props}
          />
          <div className="text-sm font-medium leading-5 text-gray-500 w-[10%] h-full text-center border-l-2  border-l-gray-300">
            px
          </div>
        </div>
      ) : (
        <input
          className={`w-full px-3 py-1.5  text-gray-500 border ${
            error ? 'border-red-600' : 'border-gray-300'
          } rounded-lg outline-none ${className} focus:border-primaryLeft-700`}
          placeholder={placeholder}
          ref={ref}
          readOnly={readonly ? readonly : false}
          type={type}
          onWheel={(event) => event.target.blur()}
          min={min}
          max={max}
          {...props}
        />
      )}
      {error && (
        <p className="mt-1 text-red-500 rounded text-sm">{error?.message}</p>
      )}
    </div>
  ),
);

export default PropertyInput;
