import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

const ToolTipComponent = ({
  children,
  text,
  className,

  tooltipId,
  id,
  type = 'hover',
  offset = 10,
  place = 'top',
  style,
  variant = 'dark',
}) => {
  // type - click or hover, default is hover
  // offset - is distance you want to show tooltip from the element like 10,20,30,40,....
  //place = 'top', 'top-start', 'top-end', 'right', 'right-start', 'right-end', 'bottom', 'bottom-start', 'bottom-end', 'left', 'left-start', 'left-end'
  // style can be passed as normal inline style
  //variant = 'dark' | 'light' | 'success' | 'warning' | 'error' | 'info'

  //    data-tooltip-id="QuestionPerPage"
  return (
    <Tooltip
      id={tooltipId || id}
      events={[type]}
      offset={offset}
      place={place}
      className={className}
      style={{ ...style, zIndex: '99999999999' }}
      variant={variant}
    >
      {children}

      {text}
    </Tooltip>
  );
};

export default ToolTipComponent;
