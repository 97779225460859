import { CloudArrowUpIcon } from '@heroicons/react/24/solid';
import EditIcon from 'assets/svg/editIcon';
import ImageUploadIcon from 'assets/svg/imageUploadIcon';
import UploadVideo from 'assets/svg/uploadVideo';
import React from 'react';

const VideoField = React.forwardRef((props, ref) => {
  const {
    htmlFor,
    description = 'PNG, JPG or GIF (Recommended dimension 592x192px)',
    value,
    height,
    ...rest
  } = props;
  return (
    <React.Fragment>
      <div
        className={`border rounded-xl w-full  ${
          height ? `h-${height}` : 'h-44'
        } cursor-pointer relative ${value ? '' : 'px-4 py-6'}`}
      >
        <label htmlFor={htmlFor} className="cursor-pointer">
          <div className="flex justify-end w-full"></div>
          {!value ? (
            <div className="flex flex-col justify-center items-center h-full w-full">
              <div className="flex w-10 h-10 bg-gray-50 border-4 border-gray-100 items-center justify-center rounded-3xl">
                <UploadVideo className="h-5 w-5" />
              </div>
              <p className="text-sm text-gray-600">
                <span className="text-transparent bg-clip-text bg-gradient-to-br from-primaryLeft to-primaryRight font-medium">
                  Click to upload
                </span>{' '}
                or drag and drop
              </p>
              <p className="text-xs text-gray-600 text-center">{description}</p>
            </div>
          ) : (
            <React.Fragment>
              <div className="relative border rounded-xl w-full">
                <video
                  src={
                    typeof value === 'string'
                      ? value
                      : URL.createObjectURL(value)
                  }
                  alt="banner"
                  className={`w-full ${
                    height ? `h-${height}` : 'h-44'
                  } object-fill rounded-xl`}
                />
                <label
                  htmlFor={htmlFor}
                  className="w-fit z-50 flex px-4 py-2 rounded-xl bg-gray-400 absolute top-2 right-2 cursor-pointer"
                >
                  <EditIcon /> Change
                </label>
              </div>
            </React.Fragment>
          )}
        </label>
      </div>
      <input
        type="file"
        accept=".mp4,.mov"
        id={htmlFor}
        className="hidden"
        ref={ref}
        {...rest}
      />
    </React.Fragment>
  );
});

export default VideoField;
