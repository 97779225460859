const AboutCompanyIcon = (props) => (
  <svg
    width={props?.width ? props?.width : '20'}
    height={props?.height ? props?.height : '20'}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1963_1928)">
      <path
        d="M9.99999 13.3334V10.0001M9.99999 6.66675H10.0083M18.3333 10.0001C18.3333 14.6025 14.6024 18.3334 9.99999 18.3334C5.39762 18.3334 1.66666 14.6025 1.66666 10.0001C1.66666 5.39771 5.39762 1.66675 9.99999 1.66675C14.6024 1.66675 18.3333 5.39771 18.3333 10.0001Z"
        stroke={props.isActive ? props.activeColor : '#475467'}
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1963_1928">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default AboutCompanyIcon;
