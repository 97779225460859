const AccidentalIcon = (props) => (
  <svg
    width={props?.width ? props?.width : '20'}
    height={props?.height ? props?.height : '20'}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.9167 9.58333H12.0833L10.8333 12.0833L9.16666 7.08333L7.91666 9.58333H7.08332M9.99428 4.27985C8.32816 2.332 5.54978 1.80804 3.46224 3.59168C1.37469 5.37532 1.0808 8.35748 2.72015 10.467C3.9584 12.0604 7.47607 15.2591 9.12333 16.7291C9.42615 16.9993 9.57755 17.1344 9.75485 17.1876C9.90876 17.2338 10.0798 17.2338 10.2337 17.1876C10.411 17.1344 10.5624 16.9993 10.8652 16.7291C12.5125 15.2591 16.0302 12.0604 17.2684 10.467C18.9078 8.35748 18.6498 5.35656 16.5263 3.59168C14.4029 1.8268 11.6604 2.332 9.99428 4.27985Z"
      stroke={
        props.isActive
          ? props.activeColor
          : props.isHovered
          ? props.hoveredColor
          : '#475467'
      }
      strokeWidth="1.67"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default AccidentalIcon;
