import Button from 'components/atoms/button/Button';
import { allowedLifeEventsOptions } from 'pages/benefits/createBenefit/enrolmentBenefit/generalBenefit/constant';
import { formatDate } from 'pages/recognition/orderHistory/helper';
import { useRef, useState } from 'react';
import { LifeEventApprovalViewDocPopUp } from './LifeEventApprovalViewDocPopUp';

export const Details = ({ data, taskData }) => {
  const popupRef = useRef(null);
  const [isViewDocPopUp, setIsViewDocPopUp] = useState(false);

  let linkIndex = 1;

  return (
    <div className="border rounded-lg shadow-md">
      <div className="flex justify-between items-center border-b p-5 ">
        <h1 className="text-gray-900 text-md font-semibold">
          Approval Details
        </h1>
        <div>
          <Button
            variant="outlined"
            className="flex border-solid 2px text-sm font-semibold"
            onClick={() => setIsViewDocPopUp(!isViewDocPopUp)}
          >
            View Document
          </Button>
        </div>
      </div>
      <div className="">
        <div className="p-6 flex flex-col gap-4">
          <div className="flex gap-10 items-center border-b pb-4">
            <p className="text-gray-900 font-semibold text-sm  w-1/6">
              Country
            </p>
            <p className="text-sm text-gray-600 w-5/6">
              {data?.submittedBy?.country?.sourceValue?.value}
            </p>
          </div>
          <div className="flex gap-10 items-center border-b pb-4">
            <p className="text-gray-900 font-semibold text-sm  w-1/6">
              Life Event
            </p>
            <p className="text-sm text-gray-600 w-5/6">
              {
                allowedLifeEventsOptions?.filter(
                  (lifeEvent) => lifeEvent?.value === data?.lifeEvent,
                )?.[0]?.label
              }
            </p>
          </div>
          <div className="flex gap-10 items-center border-b pb-4">
            <p className="text-gray-900 font-semibold text-sm  w-1/6">Date</p>
            <p className="text-sm text-gray-600 w-5/6">
              {formatDate(new Date(data?.lifeEventDate))}
            </p>
          </div>
        </div>
      </div>

      {isViewDocPopUp && (
        <div ref={popupRef}>
          <LifeEventApprovalViewDocPopUp
            data={data}
            taskData={taskData}
            isModalOpen={isViewDocPopUp}
            setIsViewDocPopUp={setIsViewDocPopUp}
          />
        </div>
      )}
    </div>
  );
};
