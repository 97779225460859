import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  createForm,
  editForm,
  getFieldMetaData,
  getFormMetaData,
  getForms,
  getForm,
  deleteForm,
  duplicateForm,
  getFieldType,
} from 'apiClient/recognition/recognition';

export const initialState = {
  selectedField: '',
  sidePanelType: 'FORM',
  selectedFieldMetaData: {},
  formMetaData: {},
  loader: [],
  forms: {
    list: [],
    total: 0,
  },
};

export const getFormMetaDataThunk = createAsyncThunk(
  'get/formMetaData',
  async () => {
    const response = await getFormMetaData();
    return response;
  },
);

export const getFieldMetaDataThunk = createAsyncThunk(
  'get/fieldTemplate',
  async (type) => {
    const resposne = await getFieldMetaData(type);
    return resposne;
  },
);

export const getFormsThunk = createAsyncThunk('get/forms', async (payload) => {
  const response = await getForms(payload);
  return response;
});

export const createFormTemplateThunk = createAsyncThunk(
  'create/form',
  async (payload) => {
    const response = await createForm(payload);
    return response;
  },
);

export const editFormTemplateThunk = createAsyncThunk(
  'edit/form',
  async (payload) => {
    const response = await editForm(payload);
    return response;
  },
);

export const getFormTemplateThunk = createAsyncThunk('get/form', async (id) => {
  const response = await getForm(id);
  return response;
});

export const deleteFormTemplateThunk = createAsyncThunk(
  'delete/form',
  async (id) => {
    const response = await deleteForm(id);
    return response;
  },
);

export const duplicateFormTemplateThunk = createAsyncThunk(
  'duplicate/form',
  async (payload) => {
    const response = await duplicateForm(payload);
    return response;
  },
);

export const getFieldTypeIdThunk = createAsyncThunk('get/fieldId', async () => {
  const response = await getFieldType();
  return response;
});

const recognitionReducer = createSlice({
  name: 'recognition',
  initialState,
  reducers: {
    // Form actions
    setSelectedField(state, action) {
      state.selectedField = action.payload;
    },
    setSidePanelType(state, action) {
      state.sidePanelType = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getFormMetaDataThunk.pending, (state, action) => {
      state.loader = [...state.loader, 'FORM_METADATA_LOADING'];
      return state;
    });
    builder.addCase(getFormMetaDataThunk.fulfilled, (state, action) => {
      state.loader = state.loader.filter(
        (ele) => ele !== 'FORM_METADATA_LOADING',
      );
      state.formMetaData = action.payload;
      return state;
    });
    builder.addCase(getFieldMetaDataThunk.pending, (state, action) => {
      state.loader = [...state.loader, 'FIELD_METADATA_LOADING'];
      return state;
    });
    builder.addCase(getFieldMetaDataThunk.fulfilled, (state, action) => {
      state.loader = state.loader.filter(
        (ele) => ele !== 'FIELD_METADATA_LOADING',
      );
      state.selectedFieldMetaData = action.payload;
      return state;
    });
    builder.addCase(getFormsThunk.pending, (state, action) => {
      state.loader = [...state.loader, 'FORMS_LOADING'];
      return state;
    });
    builder.addCase(getFormsThunk.fulfilled, (state, action) => {
      state.loader = state.loader.filter((ele) => ele !== 'FORMS_LOADING');
      state.forms.list = action.payload.forms;
      state.forms.total = action.payload.total;
      return state;
    });

    builder.addCase(createFormTemplateThunk.pending, (state) => {
      state.loader = [...state.loader, 'CREATE_FORM'];
      return state;
    });

    builder.addCase(createFormTemplateThunk.fulfilled, (state) => {
      state.loader = state.loader.filter((ele) => ele !== 'CREATE_FORM');
      return state;
    });
    builder.addCase(editFormTemplateThunk.pending, (state) => {
      state.loader = [...state.loader, 'EDIT_FORM'];
      return state;
    });

    builder.addCase(editFormTemplateThunk.fulfilled, (state) => {
      state.loader = state.loader.filter((ele) => ele !== 'EDIT_FORM');
      return state;
    });

    builder.addCase(deleteFormTemplateThunk.pending, (state) => {
      state.loader = [...state.loader, 'DELETE_FORM'];
      return state;
    });

    builder.addCase(deleteFormTemplateThunk.fulfilled, (state) => {
      state.loader = state.loader.filter((ele) => ele !== 'DELETE_FORM');
      return state;
    });

    builder.addCase(duplicateFormTemplateThunk.pending, (state) => {
      state.loader = [...state.loader, 'DUPLICATE_FORM'];
      return state;
    });

    builder.addCase(duplicateFormTemplateThunk.fulfilled, (state) => {
      state.loader = state.loader.filter((ele) => ele !== 'DUPLICATE_FORM');
      return state;
    });

    builder.addCase(getFieldTypeIdThunk.pending, (state) => {
      state.loader = [...state.loader, 'FIELD_TYPE_ID'];
      return state;
    });

    builder.addCase(getFieldTypeIdThunk.fulfilled, (state) => {
      state.loader = state.loader.filter((ele) => ele !== 'FIELD_TYPE_ID');
      return state;
    });

    builder.addCase(getFieldTypeIdThunk.rejected, (state) => {
      state.loader = state.loader.filter((ele) => ele !== 'FIELD_TYPE_ID');
      return state;
    });
  },
});

export const { setSelectedField, setSidePanelType } =
  recognitionReducer.actions;
export default recognitionReducer.reducer;
