import Avatar from 'layouts/header/Avatar';
import { Link, useLocation } from 'react-router-dom/dist';
import { imageURLCheck } from 'utils/ImageUrlCheck';

const AvatarWithUsernameAndDescription = ({
  userData,
  redirectPath = null,
  descriptionText,
}) => {
  const location = useLocation();

  const linkTo = redirectPath
    ? redirectPath
    : location.pathname + location.search;

  return (
    <div className="flex gap-4 w-full items-center">
      <div className={`w-10 h-10  `}>
        <Link to={location}>
          <Avatar
            image={imageURLCheck(userData?.avatar?.value)}
            alt=""
            className={`w-10 h-10 object-cover rounded-full border ${
              redirectPath ? 'cursor-pointer' : '!cursor-default'
            }`}
            userName={userData?.fullName?.value}
          />
        </Link>
      </div>
      <div className="flex flex-col">
        <Link to={location}>
          <p
            className={`font-semibold ${
              redirectPath ? 'cursor-pointer' : '!cursor-default'
            }`}
          >
            {userData?.fullName?.value ? userData?.fullName?.value : 'N/A'}{' '}
          </p>
        </Link>
        <div className="text-gray-600">{descriptionText}</div>
      </div>
    </div>
  );
};

export default AvatarWithUsernameAndDescription;
