const separator = ';';

const cid = (sourceName, dataType, ...args) => {
  return [sourceName, dataType, ...args].join(separator);
};
export const buildChainIdentifier = (chainObject) => {
  const separator = ';';

  const cid = (sourceName, dataType, ...args) => {
    return [sourceName, dataType, ...args].join(separator);
  };

  try {
    const sourceName = chainObject.info.sourceName;

    if (sourceName === 'employee') {
      const sourceData = chainObject.data;

      return cid(sourceName, sourceData.dataType, sourceData.fieldId);
    } else if (sourceName === 'employee-employee') {
      const sourceData = chainObject.data;

      return cid(
        sourceName,
        sourceData.dataType,
        sourceData.primaryFieldId,
        sourceData.secondaryFieldId,
      );
    } else if (
      sourceName === 'employee-master' ||
      sourceName === 'employee-master-col'
    ) {
      const sourceData = chainObject.data;

      return cid(
        sourceName,
        sourceData.dataType,
        sourceData.fieldId,
        ...sourceData.columnChain.map((x) => x.selectedColumnId),
      );
    } else if (sourceName === 'compensation') {
      const sourceData = chainObject.data;
      return cid(sourceName, sourceData.dataType, sourceData.type);
    } else if (
      sourceName === 'comp-master' ||
      (sourceName === 'time' && chainObject.data?.timeSource === 'comp-master')
    ) {
      const sourceData = chainObject.data;

      return cid(
        sourceName,
        sourceData.dataType,
        sourceData.timeSource,
        sourceData.timeType,
        sourceData.rowId,
        sourceData.timeSubType,
      );
    } else if (
      sourceName === 'payrange-internal' ||
      sourceName === 'payrange-external'
    ) {
      const sourceData = chainObject.data;
      return cid(
        sourceName,
        sourceData.dataType,
        sourceData.compMasterId,
        sourceData.columnId,
      );
    } else if (sourceName === 'cycle') {
      const sourceData = chainObject.data;
      return cid(sourceName, 'Text', sourceData.cycleValueId, sourceData.type);
    } else if (sourceName === 'long-term-incentives') {
      const sourceData = chainObject.data;
      return cid(sourceName, sourceData.dataType, sourceData.type);
    } else if (sourceName === 'master-rows-table') {
      const sourceData = chainObject.data;
      return cid(sourceName, sourceData.dataType, sourceData.codeValueId);
    } else if (sourceName === 'master-cols-table') {
      const sourceData = chainObject.data;
      if (sourceData.selectedRowId)
        return cid(
          sourceName,
          'Text',
          'row',
          sourceData.masterId,
          sourceData.columnId,
          sourceData.selectedRowId,
        );

      return cid(
        sourceName,
        'Text',
        'lookup',
        sourceData.masterId,
        sourceData.columnId,
      );
    } else if (sourceName === 'plan-worksheet-cols') {
      const sourceData = chainObject.data;
      return cid(
        sourceName,
        sourceData.dataType ?? 'Text',
        sourceData.planId,
        sourceData.groupId,
        sourceData.columnId,
      );
    } else if (sourceName === 'static-tables') {
      const sourceData = chainObject.data;
      return cid(sourceName, 'Text', sourceData.tableId);
    } else if (sourceName === 'static-table-cols') {
      const sourceData = chainObject.data;
      return cid(
        sourceName,
        sourceData.dataType,
        sourceData.tableId,
        sourceData.columnId,
      );
    } else if (sourceName === 'flag') {
      const sourceData = chainObject.data;
      return cid(sourceName, 'Text', sourceData.rowId);
    } else if (sourceName === 'time') {
      const sourceData = chainObject.data;
      if (
        sourceData.timeSource === 'compensation' &&
        sourceData.type === 'comp-offcycle'
      ) {
        return cid(
          sourceName,
          sourceData.dataType,
          sourceData.timeSource,
          sourceData.type,
          sourceData.timeType,
          sourceData.timeSubType,
        );
      }
      return null;
    } else if (sourceName === 'hiring-tables') {
      const sourceData = chainObject.data;

      return cid(
        sourceName,
        sourceData.dataType,
        sourceData.type,
        sourceData.fieldId,
      );
    } else if (sourceName === 'rb-pay-structure-cols') {
      const sourceData = chainObject.data;

      return cid(sourceName, 'Text', sourceData.columnName);
    } else if (sourceName === 'forms') {
      const sourceData = chainObject.data;
      // TODO: Implement @NishitGupta
      return null;
    }

    return null;
  } catch (error) {
    return null;
  }
};
