import React from 'react';

const MarketplaceLogo = ({ isActive }) => {
  return (
    <div>
      <svg
        width="18"
        height="20"
        viewBox="0 0 18 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.3337 7.50001V5.00001C12.3337 3.15906 10.8413 1.66667 9.00033 1.66667C7.15938 1.66667 5.66699 3.15906 5.66699 5.00001V7.5M1.99366 8.62664L1.49366 13.96C1.3515 15.4764 1.28042 16.2346 1.53204 16.8202C1.75308 17.3347 2.14043 17.7601 2.63201 18.0282C3.1916 18.3333 3.95313 18.3333 5.4762 18.3333H12.5245C14.0475 18.3333 14.8091 18.3333 15.3686 18.0282C15.8602 17.7601 16.2476 17.3347 16.4686 16.8202C16.7202 16.2346 16.6492 15.4764 16.507 13.96L16.007 8.62664C15.8869 7.34613 15.8269 6.70587 15.5389 6.22181C15.2853 5.79549 14.9106 5.45426 14.4625 5.24154C13.9537 5.00001 13.3106 5.00001 12.0245 5.00001L5.9762 5.00001C4.69007 5.00001 4.047 5.00001 3.53818 5.24154C3.09005 5.45426 2.71533 5.79549 2.46171 6.22181C2.17374 6.70587 2.11371 7.34613 1.99366 8.62664Z"
          stroke={`${isActive ? '#fff' : '#475467'}`}
          strokeWidth="1.67"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default MarketplaceLogo;
