import React from 'react';

const EsopLogo = ({ isActive }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.2749 6.95996C10.7979 7.30004 10.2141 7.50008 9.58366 7.50008C7.97283 7.50008 6.66699 6.19425 6.66699 4.58341C6.66699 2.97258 7.97283 1.66675 9.58366 1.66675C10.6278 1.66675 11.5438 2.21543 12.0591 3.0402M5.00033 16.7394H7.17557C7.45918 16.7394 7.74106 16.7731 8.016 16.8406L10.3144 17.3991C10.8131 17.5206 11.3326 17.5325 11.8366 17.4346L14.3778 16.9402C15.0491 16.8094 15.6666 16.488 16.1506 16.0172L17.9486 14.2682C18.462 13.7695 18.462 12.9604 17.9486 12.461C17.4863 12.0113 16.7543 11.9607 16.2313 12.342L14.1358 13.8708C13.8357 14.0902 13.4706 14.2083 13.095 14.2083H11.0716L12.3595 14.2082C13.0855 14.2082 13.6735 13.6362 13.6735 12.93V12.6744C13.6735 12.088 13.2633 11.5767 12.6787 11.435L10.6908 10.9515C10.3673 10.8731 10.036 10.8334 9.70294 10.8334C8.89894 10.8334 7.44357 11.4991 7.44357 11.4991L5.00033 12.5208M16.667 5.41675C16.667 7.02758 15.3612 8.33342 13.7503 8.33342C12.1395 8.33342 10.8337 7.02758 10.8337 5.41675C10.8337 3.80592 12.1395 2.50008 13.7503 2.50008C15.3612 2.50008 16.667 3.80592 16.667 5.41675ZM1.66699 12.1667L1.66699 17.0001C1.66699 17.4668 1.66699 17.7001 1.75782 17.8784C1.83771 18.0352 1.9652 18.1627 2.122 18.2426C2.30026 18.3334 2.53361 18.3334 3.00033 18.3334H3.66699C4.1337 18.3334 4.36706 18.3334 4.54532 18.2426C4.70212 18.1627 4.8296 18.0352 4.9095 17.8784C5.00033 17.7001 5.00033 17.4668 5.00033 17.0001V12.1667C5.00033 11.7 5.00033 11.4667 4.9095 11.2884C4.8296 11.1316 4.70212 11.0041 4.54532 10.9242C4.36706 10.8334 4.1337 10.8334 3.66699 10.8334L3.00033 10.8334C2.53362 10.8334 2.30026 10.8334 2.122 10.9242C1.9652 11.0041 1.83771 11.1316 1.75782 11.2884C1.66699 11.4667 1.66699 11.7 1.66699 12.1667Z"
        stroke={`${isActive ? '#fff' : '#475467'}`}
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default EsopLogo;
