const MyRecognitionIcon = ({
  isActive,
  activeColor,
  isHovered,
  hoveredColor,
  width,
  height,
}) => (
  // <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none">
  //   <path
  //     stroke={isActive ? activeColor : isHovered ? hoveredColor : '#475467'}
  //     strokeLinecap="round"
  //     strokeLinejoin="round"
  //     strokeWidth={1.67}
  //     d="M6.667 11.667s1.25 1.666 3.333 1.666 3.333-1.666 3.333-1.666M12.5 7.5h.008M7.5 7.5h.008M18.333 10a8.333 8.333 0 1 1-16.666 0 8.333 8.333 0 0 1 16.666 0Zm-5.416-2.5a.417.417 0 1 1-.834 0 .417.417 0 0 1 .834 0Zm-5 0a.417.417 0 1 1-.834 0 .417.417 0 0 1 .834 0Z"
  //   />
  // </svg>
  <svg
    width={width ? width : '20'}
    height={height ? height : '20'}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1769_3579)">
      <path
        d="M6.66666 11.6667C6.66666 11.6667 7.91666 13.3334 9.99999 13.3334C12.0833 13.3334 13.3333 11.6667 13.3333 11.6667M12.5 7.50002H12.5083M7.49999 7.50002H7.50832M18.3333 10C18.3333 14.6024 14.6024 18.3334 9.99999 18.3334C5.39762 18.3334 1.66666 14.6024 1.66666 10C1.66666 5.39765 5.39762 1.66669 9.99999 1.66669C14.6024 1.66669 18.3333 5.39765 18.3333 10ZM12.9167 7.50002C12.9167 7.73014 12.7301 7.91669 12.5 7.91669C12.2699 7.91669 12.0833 7.73014 12.0833 7.50002C12.0833 7.2699 12.2699 7.08335 12.5 7.08335C12.7301 7.08335 12.9167 7.2699 12.9167 7.50002ZM7.91666 7.50002C7.91666 7.73014 7.73011 7.91669 7.49999 7.91669C7.26987 7.91669 7.08332 7.73014 7.08332 7.50002C7.08332 7.2699 7.26987 7.08335 7.49999 7.08335C7.73011 7.08335 7.91666 7.2699 7.91666 7.50002Z"
        stroke={isActive ? activeColor : isHovered ? hoveredColor : '#475467'}
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1769_3579">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default MyRecognitionIcon;
