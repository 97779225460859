import { get, getBlobMedia, post } from 'apiClient/apiCalls';
import { AxiosError } from 'axios';
import * as CONSTANT from '../api.constant';

export const createDashboard = async (payload) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_DASHBOARD_V3}/create-dashboard`,
      payload,
    );
    return response?.data;
  } catch (err) {
    if (err) {
      throw new AxiosError(err?.message);
    } else {
      throw new Error('Error! Create dashboard failed');
    }
  }
};

export const viewDashboardData = async (payload) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_DASHBOARD_V3}/view-dashboard`,
      payload,
    );
    return response?.data;
  } catch (err) {
    if (err) {
      throw new AxiosError(err?.message);
    } else {
      throw new Error('Error! View dashboard failed');
    }
  }
};

export const updateDashboard = async (payload, id) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_DASHBOARD_V3}/update-dashboard/${id}`,
      payload,
    );
    return response?.data;
  } catch (err) {
    if (err) {
      throw new AxiosError(err?.message);
    } else {
      throw new Error('Error! Update dashboard failed');
    }
  }
};

export const createPage = async (payload) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_DASHBOARD_V3}/create-page`,
      payload,
    );
    return response?.data;
  } catch (err) {
    if (err) {
      throw new AxiosError(err?.message);
    } else {
      throw new Error('Error! Create page failed');
    }
  }
};

export const updatePage = async (payload, id) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_DASHBOARD_V3}/update-page/${id}`,
      payload,
    );
    return response?.data;
  } catch (err) {
    if (err) {
      throw new AxiosError(err?.message);
    } else {
      throw new Error('Error! Update page failed');
    }
  }
};

export const getPage = async (dashboardId, pageId) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_DASHBOARD_V3}/get-page-v2/${pageId}?id=${dashboardId}`,
    );
    return response?.data;
  } catch (err) {
    if (err) {
      throw new AxiosError(err?.message);
    } else {
      throw new Error('Error! Get page failed');
    }
  }
};

export const getPageDataFromDashboard = async (
  edit,
  dashboardId,
  pageId,
  body,
) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_DASHBOARD_V3}/get-page-v2/${pageId}?id=${dashboardId}`,
      { filters: body, adminView: edit },
    );
    return response?.data;
  } catch (err) {
    if (err) {
      throw new AxiosError(err?.message);
    } else {
      throw new Error('Error! Get page failed');
    }
  }
};
export const getAllFiles = async (id) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_DASHBOARD_V3}/get-all-files/${id}`,
    );
    return response?.data;
  } catch (err) {
    if (err) {
      throw new AxiosError(err?.message);
    } else {
      throw new Error('Error! Get all files failed');
    }
  }
};

export const getChartTypes = async (id) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_DASHBOARD_V3}/get-chart-types?id=${id}`,
    );
    return response?.data;
  } catch (err) {
    if (err) {
      throw new AxiosError(err?.message);
    } else {
      throw new Error('Error! Get charts list failed');
    }
  }
};

export const addFiles = async (dashboardId, formData) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_DASHBOARD_V3}/upload-file?id=${dashboardId}`,
      formData,
      'multipart/form-data',
    );
    return response?.data;
  } catch (err) {
    if (err) {
      throw new AxiosError(err?.message);
    } else {
      throw new Error('Error! Add files failed');
    }
  }
};

export const deleteFile = async (fileId, dashboardId) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_DASHBOARD_V3}/delete-table/${fileId}?id=${dashboardId}`,
    );
    return response?.data;
  } catch (err) {
    if (err) {
      throw new AxiosError(err?.message);
    } else {
      throw new Error('Error! Delete file failed');
    }
  }
};

export const getVersionHistory = async (fileId, dashboardId) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_DASHBOARD_V3}/get-version-history/${fileId}?id=${dashboardId}`,
    );
    return response?.data;
  } catch (err) {
    if (err) {
      throw new AxiosError(err?.message);
    } else {
      throw new Error('Error! Get version history failed');
    }
  }
};

export const getPreviewSampleData = async (fileId, dashboardId) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_DASHBOARD_V3}/view-sample/${fileId}`,
    );
    return response?.data;
  } catch (err) {
    if (err) {
      throw new AxiosError(err?.message);
    } else {
      throw new Error('Error! Get preview table data failed');
    }
  }
};

export const validateFile = async (payload, fileId, dashboardId) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_DASHBOARD_V3}/validate-file/${fileId}?id=${dashboardId}`,
      payload,
    );
    return response?.data;
  } catch (err) {
    if (err) {
      throw new AxiosError(err?.message);
    } else {
      throw new Error('Error! Validate data failed');
    }
  }
};

export const getDimensions = async (dashboardId) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_DASHBOARD_V3}/get-dimensions/${dashboardId}`,
    );
    return response?.data;
  } catch (err) {
    if (err) {
      throw new AxiosError(err?.message);
    } else {
      throw new Error('Error! Get dimensions failed');
    }
  }
};
export const getMeasureDimensions = async (dashboardId) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_DASHBOARD_V3}/dimensions-measures/${dashboardId}`,
    );
    return response?.data;
  } catch (err) {
    if (err) {
      throw new AxiosError(err?.message);
    } else {
      throw new Error('Error! Get dimensions failed');
    }
  }
};
export const getMeasures = async (dashboardId, payload) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_DASHBOARD_V3}/get-measures/${dashboardId}`,
      payload,
    );
    return response?.data;
  } catch (err) {
    if (err) {
      throw new AxiosError(err?.message);
    } else {
      throw new Error('Error! Get measures failed');
    }
  }
};

export const getValidatedData = async (fileId, dashboardId) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_DASHBOARD_V3}/get-validated-table/${fileId}?id=${dashboardId}`,
    );
    return response?.data;
  } catch (err) {
    if (err) {
      throw new AxiosError(err?.message);
    } else {
      throw new Error('Error! Get validated data failed');
    }
  }
};

export const getRevertHistory = async (dashboardId, versionId) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_DASHBOARD_V3}/revert-history/${versionId}?id=${dashboardId}`,
    );
    return response?.data;
  } catch (err) {
    if (err) {
      throw new AxiosError(err?.message);
    } else {
      throw new Error('Error! Revert history failed');
    }
  }
};

export const updateChart = async (payload, chartId) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_DASHBOARD_V3}/update-chart/${chartId}`,
      payload,
    );
    return response?.data;
  } catch (err) {
    if (err) {
      throw new AxiosError(err?.message);
    } else {
      throw new Error('Error! Update chart failed');
    }
  }
};

export const deleteChart = async (chartId, dashboardId) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_DASHBOARD_V3}/delete-chart/${chartId}?id=${dashboardId}`,
    );
    return response?.data;
  } catch (err) {
    if (err) {
      throw new AxiosError(err?.message);
    } else {
      throw new Error('Error! Delete chart failed');
    }
  }
};

export const getChartTable = async (chartId, dashboardId, payload) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_DASHBOARD_V3}/get-chart-table/${chartId}?dashboardId=${dashboardId}`,
      payload,
    );
    return response?.data;
  } catch (err) {
    if (err) {
      throw new AxiosError(err?.message);
    } else {
      throw new Error('Error! Delete chart failed');
    }
  }
};
export const getChartDataset = async (dashboardId, payload) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_DASHBOARD_V3}/get-chart-table/${dashboardId}`,
      payload,
    );
    return response?.data;
  } catch (err) {
    if (err) {
      throw new AxiosError(err?.message);
    } else {
      throw new Error('Error! Delete chart failed');
    }
  }
};

export const getChartData = async (chartId, dashboardId) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_DASHBOARD_V3}/get-chart-data/${chartId}?id=${dashboardId}`,
    );
    return response?.data;
  } catch (err) {
    if (err) {
      throw new AxiosError(err?.message);
    } else {
      throw new Error('Error! Delete chart failed');
    }
  }
};

export const updateFile = async (fileId, dashboardId, formData) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_DASHBOARD_V3}/update-table/${fileId}?id=${dashboardId}`,
      formData,
      'multipart/form-data',
    );
    return response?.data;
  } catch (err) {
    if (err) {
      throw new AxiosError(err?.message);
    } else {
      throw new Error('Error! Update file failed');
    }
  }
};

export const downloadDashboard = async (pageId) => {
  try {
    const response = await getBlobMedia(
      `${CONSTANT.API_URL}/${CONSTANT.API_DASHBOARD_V3}/download-dashboard-pdf/${pageId}`,
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err?.message);
    } else {
      throw new Error('Error! Download dashboard failed');
    }
  }
};

export const refreshFile = async (dashboardId, sourceRefresh) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_DASHBOARD_V3}/refresh-file/${dashboardId}/${sourceRefresh}`,
    );
    return response?.data;
  } catch (err) {
    if (err) {
      throw new AxiosError(err?.message);
    } else {
      throw new Error('Error! Refresh file failed');
    }
  }
};

export const downloadValidateFile = async (fileId, dashboardId) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_DASHBOARD_V3}/download-validated-table//${fileId}?id=${dashboardId}`,
    );
    return response?.data;
  } catch (err) {
    if (err) {
      throw new AxiosError(err?.message);
    } else {
      throw new Error('Error! Download validated file failed');
    }
  }
};

export const getFiltersColumnValues = async (dashboardId) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_DASHBOARD_V3}/get-filters/${dashboardId}`,
    );
    return response?.data;
  } catch (err) {
    if (err) {
      throw new AxiosError(err?.message);
    } else {
      throw new Error('Error! Get filters column values failed');
    }
  }
};

export const getFilterValues = async (columnId) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_DASHBOARD_V3}/get-filter-value/${columnId}`,
    );
    return response?.data;
  } catch (err) {
    if (err) {
      throw new AxiosError(err?.message);
    } else {
      throw new Error('Error! Get filter values failed');
    }
  }
};
