export const PreviewCustomStyles = {
  control: (base, state) => {
    const isSelectNewProgram =
      state.selectProps.id === 'react-select-new-program';
    const isBudgetAllocationField =
      state.selectProps.id === 'budget-allocation-field';
    const shouldRemoveBorder =
      state.selectProps.id === 'remove-border-select' ||
      state.selectProps.id === 'react-select-letter-header';

    return {
      ...base,
      '&:hover': {},
      boxShadow: state.isFocused ? null : null, // borderImage: state.isFocused // ? "linear-gradient(to right, var(--primaryLeft), var(--primaryRight)) 1" //  : "rgb(229 231 235);",
      borderImageSlice: 1,
      borderColor: shouldRemoveBorder
        ? 'transparent'
        : state.isFocused
        ? '#888'
        : 'rgb(209 213 219);',
      borderWidth: shouldRemoveBorder ? 0 : 1,
      borderRadius: '1rem',
      fontSize: '13px',
      fontWeight: 500,
      color: '#101828',
      fontFamily: 'Figtree',
      outline: state.isFocused ? null : null,
      backgroundClip: 'padding-box, border-box',
      height: '22px',
      minHeight: '22px',
      marginTop: 0,
      width: isSelectNewProgram ? '100%' : base.width,
    };
  },
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isDisabled
      ? '#F9F9F9'
      : state.isSelected
      ? '#F9F9F9'
      : state.isFocused
      ? '#eee'
      : undefined,

    color: state.isDisabled ? '#98A2B3' : '#101828',
    fontWeight: 500,
    fontSize: '13px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontFamily: 'Figtree',
    position: 'relative',
    cursor: state.isDisabled ? 'not-allowed' : 'pointer',
    // "&:hover": { backgroundColor: "#white" },
    '&::after': {
      width: '10px',
      content: state?.isSelected ? '"✔️"' : '""', // Unicode checkmark symbol
      position: 'absolute',
      top: '0.5rem',
      right: '1rem',
    },
  }),

  menu: (provided, state) => {
    const isSelectNewProgram =
      state.selectProps.id === 'react-select-new-program';
    return {
      ...provided,
      boxShadow:
        '0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)',
      width: isSelectNewProgram ? '100%' : provided.width,
      zIndex: 999,
    };
  },
  menuList: (provided, state) => ({
    ...provided,
    maxHeight: '300px',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      width: '8px',
      visibility: 'hidden',
    },
    '&:hover::-webkit-scrollbar': {
      visibility: 'visible',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#888',
      borderRadius: '1rem',
      visibility: 'hidden',
    },
    '&:hover::-webkit-scrollbar-thumb': {
      background: '#d0d5dd',
      visibility: 'visible',
    },
  }),

  menuPortal: (provided, state) => ({
    ...provided,
    zIndex: 99999,
  }),

  indicatorSeparator: () => ({
    display: 'none',
  }), // dropdownIndicator: () => ({ //  display: "none", // }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: 'white',
    borderRadius: '20px',
    display: 'inline-flex',
    margin: '2px',
    padding: '2px',
    background:
      'linear-gradient(#fff 0 0) padding-box, linear-gradient(to right, var(--primaryLeft), var(--primaryRight)) border-box',
    color: 'black',
    border: '2px solid transparent',
    '& #react-select-hide': {
      display: 'none',
    },
    '& #e-card-image': {
      height: '25px',
      width: '40px',
    },
    '& #e-card-title': {
      fontSize: '12px',
    },
    '& #e-card-description': {
      display: 'none',
    },
  }),

  multiValueLabel: (provided) => ({
    ...provided,
    color: 'black',
  }),

  multiValueRemove: (provided) => ({
    ...provided,
    color: 'black',
    paddingLeft: '4px',
    paddingRight: '4px',
    borderRadius: '50%',
    backgroundColor: 'white',
    ':hover': {
      backgroundColor: 'white',
    },
  }),

  singleValue: (provided) => ({
    ...provided,
    fontSize: '13px',
    color: '#667085',
    fontFamily: 'Figtree',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: '0',
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    height: '19px',
  }),
  input: (provided) => ({
    ...provided,
    margin: '0',
    padding: '0',
    fontSize: '13px',
  }),
  valueContainer: (provided) => ({
    ...provided,
    height: '16px',
    fontSize: '13px',
    padding: '0 8px',
  }),
};
