import Tabs from 'components/molecules/tabs/Tabs';
import UserData from 'components/molecules/userData/UserData';
import {
  useGetMYPeersById,
  useGetMyReporteesById,
} from 'query/profile/summary';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
const tabs = [
  {
    tabLabel: 'My Team',
    value: 'My Team',
  },
  {
    tabLabel: 'Peers',
    value: 'Peers',
  },
];

const People = ({ isMyTeamActive }) => {
  const user = useSelector((state) => state.user);
  const { isLoading: peersLoading, data: peersData } = useGetMYPeersById(
    user?.userProfileData?.userId?.value,
  );
  const { isLoading: reporteesLoading, data: reporteesData } =
    useGetMyReporteesById(user?.userProfileData?.userId?.value);
  const [selectedTab, setSelectedTab] = useState(tabs?.[0].value);
  useEffect(() => {
    if (!isMyTeamActive) {
      setSelectedTab(null);
    } else {
      setSelectedTab(tabs?.[0]?.value);
    }
  }, [isMyTeamActive]);

  const renderLoader = () => {
    return (
      <>
        {Array(4)
          .fill()
          ?.map((data, ind) => {
            return (
              <div
                key={ind}
                className="w-full h-10 bg-gray-300 rounded-lg animate-pulse"
              ></div>
            );
          })}
      </>
    );
  };

  return (
    <div className="flex w-full flex-col gap-3 p-4 border rounded-xl shadow-md border-gray-200 bg-white">
      <div
        className={`${
          !isMyTeamActive ? 'pb-4 border-b border-gray-200' : 'pb-2'
        } text-sm/5 font-semibold text-gray-900`}
      >
        {isMyTeamActive ? 'People' : 'Peers'}
      </div>
      {isMyTeamActive && (
        <div>
          <Tabs
            tabs={tabs}
            selectedTab={selectedTab}
            handleTab={(e) => {
              setSelectedTab(e?.value);
            }}
          />
        </div>
      )}
      {selectedTab === tabs[0].value && (
        <div className="flex w-full flex-col gap-4 max-h-[192px] overflow-y-auto">
          {peersLoading ? (
            <>{renderLoader()}</>
          ) : (
            <>
              {reporteesData?.data?.length > 0 ? (
                <>
                  {reporteesData?.data?.map((user, ind) => {
                    const { department, jobTitle, ...rest } = user;
                    return <UserData userData={rest} isClickable key={ind} />;
                  })}
                </>
              ) : (
                <div className="text-sm font-normal text-gray-700">
                  No reportees to show.
                </div>
              )}
            </>
          )}
        </div>
      )}
      {(selectedTab === tabs[1].value || !isMyTeamActive) && (
        <div className="flex w-full flex-col gap-4 max-h-[192px] overflow-y-auto">
          {reporteesLoading ? (
            <>{renderLoader()}</>
          ) : (
            <>
              {peersData?.data?.length > 0 ? (
                <>
                  {peersData?.data?.map((user, ind) => {
                    const { department, jobTitle, ...rest } = user;
                    return <UserData userData={rest} isClickable key={ind} />;
                  })}
                </>
              ) : (
                <div className="text-sm font-normal text-gray-700">
                  No peers to show.
                </div>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default People;
