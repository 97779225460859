const PlusIconGradient = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={14}
    height={14}
    fill="none"
    {...props}
  >
    <path
      stroke="url(#plus_icon)"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.667}
      d="M6.997 1.167v11.667M1.164 7h11.667"
      className="hover:stroke-white"
    />
    <defs>
      <linearGradient
        id="plus_icon"
        x1={1.164}
        x2={12.831}
        y1={12.834}
        y2={12.834}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="var(--primaryLeft)" />
        <stop offset={1} stopColor="#F70" />
      </linearGradient>
    </defs>
  </svg>
);
export default PlusIconGradient;
