import {
  fetchMyBadges,
  fetchMyPeersById,
  fetchMyReportessById,
} from 'apiClient/profile/Profile';
import { useQuery } from 'react-query';

export const useGetMYPeersById = (id, pageIndex, pageSize) =>
  useQuery(['get/peers', id, pageIndex, pageSize], async () =>
    fetchMyPeersById(id, pageIndex, pageSize),
  );

export const useGetMyReporteesById = (id, pageIndex, pageSize) =>
  useQuery(['get/reportees', id, pageIndex, pageSize], async () =>
    fetchMyReportessById(id, pageIndex, pageSize),
  );

export const useGetMyBadgesById = (id) =>
  useQuery(['get/badges', id], async () => fetchMyBadges(id));
