import { ArrowSmallLeftIcon } from '@heroicons/react/24/outline';
import { DEBOUNCE_SEARCH_TIME } from 'apiClient/api.constant';
import { fetchAllPlans } from 'apiClient/rangeBuilder/rangeBuilder';
import PlusIcon from 'assets/svg/plusIcon';
import Button from 'components/atoms/button/Button';
import Pagination from 'components/atoms/pagination/Pagination';
import { useDebounce } from 'hooks/useDebounce';
import { Search } from 'pages/benefits/manageBenefits/Search';
import { setToastMessage } from 'pages/recognition/redemption/helper';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { benchmarkPlansTabs } from '../../../config/rangeBuilderData';
import { BenchmarkPlansTable } from './BenchmarkPlansTable';

export const BenchmarkPlans = () => {
  const [selectedTab, setSelectedTab] = useState('All');
  const [searchedValue, setSearchedValue] = useState('');
  const [planTableData, setPlanTableData] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [totalPlans, setTotalPlans] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);

  const handlePageClick = async (event) => {
    setCurrentPage(event.selected);
  };

  // useEffect(() => {
  //   (async () => {
  //     try {
  //       setLoading(true);
  //       await getAllPlans('');
  //       setClaimHistory(response?.data?.empClaimData);
  //       setTotalClaims(response?.data?.count);
  //       setLoading(false);
  //     } catch (error) {
  //       setLoading(false);
  //       console.error(error);
  //     }
  //   })();
  // }, [currentPage]);

  const debouncedValue = useDebounce(searchedValue, DEBOUNCE_SEARCH_TIME);

  const getAllPlans = async (type) => {
    try {
      setLoading(true);
      const response = await fetchAllPlans(type, debouncedValue, currentPage);
      setPlanTableData(
        response?.data?.planData?.map((plan) => {
          return {
            ...plan,
            noOfRoles: response?.data?.countdata[plan?.id],
            userDetails: response?.data?.userDetails?.filter(
              (user) => user?.userId === plan?.histories?.[0]?.createdBy,
            )?.[0],
          };
        }),
      );
      setTotalPlans(response?.data?.totalCount);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
      setPlanTableData([]);
      setToastMessage('Something went wrong!');
    }
  };

  useEffect(() => {
    (async () => {
      await getAllPlans(
        selectedTab === 'All'
          ? ''
          : selectedTab === 'Live'
          ? 'Live'
          : selectedTab === 'Inactive'
          ? 'Inactive'
          : 'InProgress',
        debouncedValue,
      );
    })();
  }, [selectedTab, debouncedValue, currentPage]);

  return (
    <div className="bg-white pt-6 px-8 h-full space-y-4">
      <div className="space-y-6">
        <div className="flex flex-row items-center gap-3 justify-start">
          <div
            className="p-2 rounded-lg border-2 cursor-pointer"
            onClick={() => navigate(-1)}
          >
            <ArrowSmallLeftIcon className="w-5 h-5" />
          </div>
          <div className=" flex items-center justify center text-2xl font-semibold">
            Benchmark Plans
          </div>
        </div>
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            {benchmarkPlansTabs?.map((tab) => {
              return (
                <Button
                  key={tab?.id}
                  variant={selectedTab === tab?.label ? 'outlined' : ''}
                  onClick={() => setSelectedTab(tab?.label)}
                  className="text-sm font-semibold text-gray-600"
                >
                  {tab?.label}
                </Button>
              );
            })}
          </div>
          <div className="flex items-center gap-4">
            <Search
              searchedValue={searchedValue}
              setSearchedValue={setSearchedValue}
              className="!py-2 !px-[14px]"
            />
            {/* <Button className="text-sm font-semibold gap-2" variant="outlined">
              <TableFilterGradientIcon />
              Filter
            </Button> */}
            <Button
              className="text-sm font-semibold gap-2 flex items-center justify-center"
              variant="filled"
              onClick={() => navigate('/range-builder/create-plan')}
            >
              <PlusIcon />
              Add New
            </Button>
          </div>
        </div>
      </div>
      <BenchmarkPlansTable
        getAllPlans={getAllPlans}
        loading={loading}
        data={planTableData}
      />
      {!!planTableData?.length && !loading && (
        <div className="flex justify-center items-center my-4">
          <Pagination
            onPageChanged={handlePageClick}
            pageCount={Math.ceil(totalPlans / 10)}
            currentPage={currentPage}
          />
        </div>
      )}
    </div>
  );
};
