import {
  ArchiveBoxIcon,
  ArrowPathIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import DeleteIcon from 'assets/svg/deleteIcon';
import PopUp from 'components/atoms/popUp/PopUp';
import { ACTION_TYPE } from '../ExternalDataTable';

export const ExternalActionPopUp = ({
  id,
  setActionPopUp,
  performAction,
  setIsRowToDelete,
}) => {
  return (
    <PopUp right="5" closePopup={() => setActionPopUp(null)}>
      <div className="w-64 p-2 px-4 space-y-2">
        <p
          className="cursor-pointer flex items-center text-sm font-medium text-gray-700 gap-2 py-1"
          onClick={() => {
            performAction(ACTION_TYPE.Update, id);
          }}
        >
          <ArchiveBoxIcon className="w-5 h-5" />
          Update
        </p>
        <p
          className="cursor-pointer flex items-center text-sm font-medium text-gray-700 gap-2 py-1"
          onClick={() => {
            performAction(ACTION_TYPE.Replace, id);
          }}
        >
          <ArrowPathIcon className="w-5 h-5" />
          Replace
        </p>
        <p
          className="cursor-pointer flex items-center text-sm font-medium text-gray-700 gap-2 py-1"
          onClick={() => {
            setIsRowToDelete(id);
          }}
        >
          <TrashIcon className="w-5 h-5" />
          Delete
        </p>
      </div>
    </PopUp>
  );
};
