const BorderXmarkIcon = () => {
  return (
    <svg
      width="48"
      height="49"
      viewBox="0 0 48 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.75"
        y="1.25"
        width="46.5"
        height="46.5"
        rx="9.25"
        stroke="#D92D20"
        strokeWidth="1.5"
      />
      <path
        d="M30 18.5L18 30.5M18 18.5L30 30.5"
        stroke="#D92D20"
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default BorderXmarkIcon;
