import PopUp from 'components/atoms/popUp/PopUp';
import React, { useEffect, useRef } from 'react';

const FiltersPopUp = ({
  options,
  handleSelectedFilter,
  column,
  setFiltersPopUp,
}) => {
  const ref = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setFiltersPopUp(null);
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);
  return (
    <div
      className="absolute left-0 top-4 bg-white w-fit min-w-40 max-h-[300px] overflow-y-auto p-2 rounded-md shadow-md"
      ref={ref}
    >
      {options?.map((option) => {
        return (
          <p
            className="cursor-pointer flex items-center text-sm font-medium text-gray-700 gap-2 py-1 px-2 rounded-md w-full hover:bg-gray-100"
            onClick={() => {
              handleSelectedFilter(option, column?.value ?? column);
              setFiltersPopUp(null);
            }}
            key={option?.value}
          >
            {option?.value}
          </p>
        );
      })}
    </div>
  );
};

export default FiltersPopUp;
