import React from 'react';

const CircleIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 10 10"
      fill="none"
    >
      <circle cx="5" cy="5" r="5" fill="url(#paint0_linear_1102_26561)" />
      <defs>
        <linearGradient
          id="paint0_linear_1102_26561"
          x1="0"
          y1="10"
          x2="10"
          y2="10"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="var(--primaryLeft)" />
          <stop offset="1" stopColor="var(--primaryRight)" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default CircleIcon;
