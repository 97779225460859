import { VariablePay } from 'pages/variablePay/VariablePay';
import { VariablePayPlan } from 'pages/variablePay/VariablePayPlan';

const VariablePayRoutes = [
  {
    type: 'collapse',
    name: 'variable-pay',
    key: 'variable-pay',
    route: '/variable-pay/plans',
    parentId: 'Task',
    module: 'Task',
    component: <VariablePay />,
  },
  {
    type: 'collapse',
    name: 'variable-pay-plan',
    key: 'variable-pay-plan',
    route: '/variable-pay/plan/:id',
    parentId: 'Task',
    module: 'Task',
    component: <VariablePayPlan />,
  },
];

export default VariablePayRoutes;
