import PropertyInput from 'components/atoms/propertyInput/PropertyInput';
import React from 'react';
import { Controller } from 'react-hook-form';
import ColorPicker from 'components/atoms/FormElements/colorPicker/ColorPicker';
import PropertySelect from 'components/atoms/linkSelect/PropertySelect';
import { fontSize, links } from 'config/templateBuilderConfig';

const ButtonPropertiesSidePanel = (props) => {
  const { control, path } = props;

  return (
    <div className="flex flex-col">
      <Controller
        control={control}
        name={`${path}.button.value`}
        render={({ field }) => {
          return (
            <PropertyInput
              label={'Text'}
              placeholder={'Characters only up to 25 '}
              className={'mb-2'}
              maxlength={25}
              {...field}
            />
          );
        }}
      />
      <div className="my-2">
        <Controller
          control={control}
          name={`${path}.button.metaData.link`}
          render={({ field }) => {
            const { onChange } = field;
            return (
              <PropertySelect
                options={links}
                label={'Link'}
                creatable={true}
                onChange={(e) => onChange(e?.value)}
              />
            );
          }}
        />
      </div>
      <div className="my-2">
        <Controller
          control={control}
          name={`${path}.button.metaData.fontSize`}
          render={({ field }) => {
            const { onChange } = field;
            return (
              <PropertySelect
                options={fontSize}
                label={'Font Size'}
                onChange={(e) => onChange(e?.value)}
              />
            );
          }}
        />
      </div>
      <div className="my-2">
        <Controller
          control={control}
          name={`${path}.button.metaData.textColor`}
          render={({ field }) => {
            const { value, onChange, ...rest } = field;
            return (
              <ColorPicker
                value={value}
                label={'Text Color'}
                htmlFor={'textColor'}
                onChange={(e) => onChange(e.target.value)}
              />
            );
          }}
        />
      </div>
      <div className="my-2">
        <Controller
          control={control}
          name={`${path}.button.metaData.outlineColor`}
          render={({ field }) => {
            const { value, onChange, ...rest } = field;
            return (
              <ColorPicker
                value={value}
                label={'Outline Color'}
                htmlFor={'outlineColor'}
                onChange={(e) => onChange(e.target.value)}
              />
            );
          }}
        />
      </div>
      <div className="my-2">
        <Controller
          control={control}
          name={`${path}.button.metaData.buttonColor`}
          render={({ field }) => {
            const { value, onChange, ...rest } = field;
            return (
              <ColorPicker
                htmlFor={'buttonColor'}
                label={'Button Color'}
                value={value}
                onChange={(e) => onChange(e.target.value)}
              />
            );
          }}
        />
      </div>
    </div>
  );
};

export default ButtonPropertiesSidePanel;
