import { ArrowSmallLeftIcon } from '@heroicons/react/24/outline';
import {
  createEnrolmentByAdminAPI,
  getClaimFormByIdAdmin,
  getEmployeeEnrolmentDetailsAdminAPI,
  getEnrolmentFormByIdAdmin,
  saveEmployeeClaimByAdminAPI,
} from 'apiClient/benefitsDashboard/benefitsDashboard';
import Button from 'components/atoms/button/Button';
import { setToastMessage } from 'pages/recognition/redemption/helper';
import { useEffect, useState } from 'react';
import { v4 } from 'uuid';
import { BenefitFormEmployee } from './BenefitFormEmployee';

export const SidePopUp = ({
  selectedOperation,
  setViewDetails,
  selectedBenefitId,
  selectedUserId,
  setIsEditable,
  isEditable,
  eligibleUsers,
}) => {
  const [formDetails, setFormDetails] = useState();
  const [answers, setAnswers] = useState([]);
  const [isSubmittable, setIsSubmittable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [claimId, setClaimId] = useState('');

  const fetchBenefitForm = async () => {
    try {
      const response =
        selectedOperation === 'enrolment'
          ? await getEnrolmentFormByIdAdmin(selectedBenefitId)
          : await getClaimFormByIdAdmin(selectedBenefitId);
      if (selectedOperation === 'claim') {
        setClaimId(response?.data?.claimId);
      }
      setFormDetails(Object.values(response?.data?.value));
    } catch (error) {
      setFormDetails([]);
    }
  };

  const fetchEmployeeEnrolmentDetails = async () => {
    try {
      const response = await getEmployeeEnrolmentDetailsAdminAPI(
        selectedBenefitId,
        selectedUserId,
      );
      if (response) {
        let data = {};

        Object.values(response?.data?.formDetails?.[0] || {})?.[0]?.map(
          (field) => {
            console.log(field, 'dd');
            data = {
              ...data,
              [field?.fieldID]:
                field?.dataType === 'Date'
                  ? new Date(field?.value)
                  : field?.value,
            };
          },
        );
        setAnswers(data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchBenefitForm();
  }, []);

  useEffect(() => {
    if (selectedBenefitId && selectedUserId) {
      fetchEmployeeEnrolmentDetails();
    }
  }, [selectedBenefitId, selectedUserId]);

  const handleSave = async () => {
    const formData = new FormData();

    const updatedFormData = formDetails?.map((field) =>
      answers?.[field?.fieldID]
        ? {
            ...field,
            value: answers?.[field?.fieldID],
          }
        : field,
    );

    const isSubmittable = updatedFormData?.filter(
      (field) => field?.settings?.mandatory && !field?.value,
    );

    const fileFields = updatedFormData?.filter(
      (field) => field?.settings?.dataType === 'File',
    );

    if (selectedOperation === 'enrolment') {
      if (!isSubmittable?.length) {
        const employeeEnrolmentData = fileFields?.filter(
          (field) => field?.value?.file?.name,
        )?.length
          ? {
              benefitId: selectedBenefitId,
              userId: selectedUserId,
              formDetails: [{ [v4()]: updatedFormData }],
              status: 'CLOSED',
              media: {
                fileSettings: fileFields
                  .map((field, index) => {
                    if (field?.value?.file) {
                      return {
                        order: index + 1,
                        caption: field?.fieldID,
                      };
                    }
                  })
                  .filter((field) => field?.order),
              },
            }
          : {
              benefitId: selectedBenefitId,
              userId: selectedUserId,
              formDetails: [{ [v4()]: updatedFormData }],
              status: 'CLOSED',
            };

        formData.append(
          'employeeEnrollmentData',
          JSON.stringify(employeeEnrolmentData),
        );

        fileFields.forEach((field) => {
          if (field?.value?.file?.name) {
            formData.append('media', field?.value?.file);
          }
        });
        const response = await createEnrolmentByAdminAPI(formData);
      } else {
        setToastMessage('Fill all madatory fields!');
      }
    } else {
      if (!isSubmittable?.length) {
        const employeeClaimData = fileFields?.filter(
          (field) => field?.value?.file?.name,
        )?.length
          ? {
              formDetails: [{ [v4()]: updatedFormData }],
              benefitId: selectedBenefitId,
              userId: selectedUserId,
              media: {
                fileSettings: fileFields
                  .map((field, index) => {
                    if (field?.value?.file) {
                      return {
                        order: index + 1,
                        caption: field?.fieldID,
                      };
                    }
                  })
                  .filter((field) => field?.order),
              },
            }
          : {
              formDetails: [{ [v4()]: updatedFormData }],
              benefitId: selectedBenefitId,
              userId: selectedUserId,
            };

        formData.append('employeeClaimData', JSON.stringify(employeeClaimData));

        fileFields?.forEach((field) => {
          if (field?.value?.file?.name) {
            formData.append('media', field?.value?.file);
          }
        });
        const response = await saveEmployeeClaimByAdminAPI(formData, claimId);
      } else {
        setToastMessage('Fill all madatory fields!');
      }
    }
  };

  return (
    <div className="fixed z-[53] inset-0 bg-backdrop overflow-y-auto w-full">
      <div className={`absolute top-14 right-0 w-1/2 bg-white`}>
        <div className="flex items-center bg-white justify-between w-full gap-4 shadow-md border-b-2 p-2 pt-4 px-4 fixed top-[54px]">
          <div className="flex flex-row items-center gap-3 justify-start">
            <div
              className="p-2 rounded-lg border-2 cursor-pointer"
              onClick={() => setViewDetails(false)}
            >
              <ArrowSmallLeftIcon className="w-4 h-4" />
            </div>
            <div className=" flex items-center justify center text-xl font-semibold">
              {selectedOperation === 'enrolment'
                ? 'Enrolment Details'
                : 'Claim Details'}
            </div>
          </div>
        </div>
        <div className="mt-[54px] p-6 space-y-5 pb-20 mb-5">
          <div className="border border-gray-200 rounded-lg space-y-5">
            <p className="py-3 px-6 border-b border-gray-200 text-sm font-semibold text-gray-900">
              Form Details
            </p>
            <div className="px-5 pb-6 h-[30rem] overflow-y-scroll custom-scrollbar">
              <BenefitFormEmployee
                formDetails={formDetails}
                setAnswers={setAnswers}
                answers={answers}
                setIsEditable={setIsEditable}
                isEditable={isEditable}
              />
            </div>
          </div>
          {isEditable && (
            <div className="border-t border-gray-200 flex items-center justify-between pt-5 fixed bottom-0 right-0 w-1/2 bg-white p-6 h-20">
              <p className="text-gray-600 text-sm font-semibold">
                Please check the above filled details before submission
              </p>
              <div className="flex items-center gap-3">
                <Button
                  className="text-sm font-semibold text-gray-700"
                  onClick={() => setViewDetails(false)}
                >
                  Cancel
                </Button>
                {eligibleUsers?.filter(
                  (user) => user?.userId?.value === selectedUserId,
                )?.[0]?.enrollmentDetails &&
                  eligibleUsers?.filter(
                    (user) => user?.userId?.value === selectedUserId,
                  )?.[0]?.enrollmentDetails?.status !== 'NEW' &&
                  eligibleUsers?.filter(
                    (user) => user?.userId?.value === selectedUserId,
                  )?.[0]?.enrollmentDetails?.status !== 'REJECTED' && (
                    <Button
                      className="text-sm font-semibold"
                      variant="outlined"
                      onClick={handleSave}
                    >
                      Un-enroll
                    </Button>
                  )}
                <Button
                  className="text-sm font-semibold"
                  variant="filled"
                  onClick={handleSave}
                >
                  {selectedOperation === 'enrolment'
                    ? eligibleUsers?.filter(
                        (user) => user?.userId?.value === selectedUserId,
                      )?.[0]?.enrollmentDetails &&
                      eligibleUsers?.filter(
                        (user) => user?.userId?.value === selectedUserId,
                      )?.[0]?.enrollmentDetails?.status !== 'NEW' &&
                      eligibleUsers?.filter(
                        (user) => user?.userId?.value === selectedUserId,
                      )?.[0]?.enrollmentDetails?.status !== 'REJECTED'
                      ? 'Update'
                      : 'Enroll'
                    : 'Claim'}
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
