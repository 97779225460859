import { useFormContext } from 'react-hook-form';

const Paragraph = (props) => {
  const { selectAtomsMolecules, errorPath, path, type } = props.data;
  const { content } = props;

  var quillHtml = content;

  var tempElement = document.createElement('div');
  tempElement.innerHTML = quillHtml;

  var contentAvailable = tempElement.querySelector('*');

  var textContent = contentAvailable ? tempElement.textContent.trim() : '';
  const { register, control } = useFormContext();
  return (
    <>
      <textarea
        // control={control}
        value={textContent}
        onClick={() => selectAtomsMolecules(type)}
        {...register(`${path}.para.value`, {
          required: 'This Field is required',
        })}
        placeholder="Click here to edit text"
        type="text"
        rows={1}
        className="outline-none placeholder:text-black max-h-80 text-gray-600 w-full  leading-6"
      />
      {errorPath?.para?.value && (
        <p className="bg-clip-text bg-gradient-to-br text-transparent from-primaryLeft to-primaryRight">
          {errorPath?.para?.value?.message + '*'}
        </p>
      )}
    </>
  );
};

export default Paragraph;
