const TotalRewardsSummaryIcon = ({
  isActive,
  activeColor,
  isHovered,
  hoveredColor,
  width,
  height,
}) => (
  <svg
    width={width ? width : '20'}
    height={height ? height : '20'}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.6667 8.33329V5.66663C16.6667 4.26649 16.6667 3.56643 16.3942 3.03165C16.1545 2.56124 15.7721 2.17879 15.3017 1.93911C14.7669 1.66663 14.0668 1.66663 12.6667 1.66663H7.33334C5.93321 1.66663 5.23315 1.66663 4.69837 1.93911C4.22796 2.17879 3.84551 2.56124 3.60583 3.03165C3.33334 3.56643 3.33334 4.26649 3.33334 5.66663V14.3333C3.33334 15.7334 3.33334 16.4335 3.60583 16.9683C3.84551 17.4387 4.22796 17.8211 4.69837 18.0608C5.23315 18.3333 5.93321 18.3333 7.33334 18.3333H10M10.4167 9.16663H6.66668M7.50001 12.5H6.66668M13.3333 5.83329H6.66668M14.1644 12.3588C13.4979 11.6013 12.3866 11.3975 11.5516 12.0912C10.7166 12.7848 10.599 13.9445 11.2547 14.7649C11.9105 15.5853 14.1644 17.5 14.1644 17.5C14.1644 17.5 16.4183 15.5853 17.074 14.7649C17.7298 13.9445 17.6266 12.7775 16.7772 12.0912C15.9278 11.4048 14.8308 11.6013 14.1644 12.3588Z"
      stroke={isActive ? activeColor : isHovered ? hoveredColor : '#475467'}
      strokeWidth="1.67"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default TotalRewardsSummaryIcon;
