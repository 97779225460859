import { timeAgo } from 'pages/notification/helper';
import { useNavigate } from 'react-router';
import { imageURLCheck } from 'utils/ImageUrlCheck';
import { handleErrorImage } from 'utils/handleErrorImage';

const NotificationItem = ({ notification, setOpenNotification }) => {
  const navigate = useNavigate();
  // const notificationTimestamp = new Date(${notification timestamp from data} * 1000);
  // const notificationTimestamp = new Date(1677653293827 * 1000);
  // const currentTime = Date.now();

  // const timeDifferenceInMinutes = Math.floor((currentTime - notificationTimestamp) / (1000 * 60));

  // if (timeDifferenceInMinutes < 1) {

  // } else if (timeDifferenceInMinutes === 1) {

  // } else {

  // }
  // {
  // notification.status === "READ" ? "bg-white" : ""
  // }
  console.log(notification);
  return (
    <div className={`w-full flex gap-2 p-2 px-5 cursor-pointer`}>
      <div>
        <div className="rounded-full  w-10 h-10">
          {/* <Avatar /> */}

          <img
            src={imageURLCheck(
              notification.payload.inAppNotificationPayload.iconUrl,
            )}
            className="w-full h-full rounded-full object-cover"
            alt="notificaion icon"
            onError={handleErrorImage}
          />
        </div>
      </div>

      <div className="flex flex-col text-left ">
        <p className="font-normal text-sm text-left">
          {notification.payload.inAppNotificationPayload.text}
        </p>

        <span className=" text-xs text-gray-500">
          {timeAgo(new Date(notification.payload.createdAt))}
        </span>
        {/* <p className="text-xs text-gray-500">
          Invited{" "}
          <span className=" text-transparent font-semibold bg-clip-text bg-gradient-to-r from-[var(--primaryLeft)] to-[var(--primaryRight)] ">
            Lana Steiner
          </span>{" "}
          to the team
        </p> */}
        {/* <p className="text-xs text-gray-500 truncate w-1/4 whitespace-nowrap text-left">
          {notification.payload.inAppNotificationPayload.text}
        </p> */}
        <div className="space-x-3">
          {notification.payload.inAppNotificationPayload.action?.secondary && (
            <button
              className="text-sm font-semibold text-gray-600"
              onClick={() => {
                navigate(
                  notification.payload.inAppNotificationPayload.action.secondary
                    .url,
                );
                setOpenNotification(false);
              }}
            >
              {
                notification.payload.inAppNotificationPayload.action.secondary
                  .name
              }
            </button>
          )}
          {notification.payload.inAppNotificationPayload.action?.primary && (
            <button
              className="text-sm font-semibold bg-gradient bg-clip-text text-transparent"
              onClick={() => {
                navigate(
                  notification.payload.inAppNotificationPayload.action.primary
                    .url,
                );
                setOpenNotification(false);
              }}
            >
              {
                notification.payload.inAppNotificationPayload.action.primary
                  .name
              }
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default NotificationItem;
