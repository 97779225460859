import React from 'react';

function UnsecuredPage() {
  return (
    <div>
      <h1 className=" text-error-500">
        If you see this page, Webb App link you have clicked on is under
        Clickjacking security attack. Please inform team with the reference of
        the application from where you clicked this link.
      </h1>
      <h2>
        Click{' '}
        <a
          href={window.self.location.href}
          title="Web Application"
          target="blank"
          className="text-blue"
        >
          here
        </a>{' '}
        to access WebApp safely.
      </h2>
    </div>
  );
}

export default UnsecuredPage;
