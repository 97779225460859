import { v4 } from 'uuid';

export const formatSummaryData = (data) => {
  const id = v4();
  let employeeCount = 0;
  Object.keys(data?.employeeCount).forEach((key) => {
    employeeCount += data?.employeeCount?.[key];
  });
  const planner = {
    id,
    employeeCount,
    userInfo: data?.plannerUserData,
    nextPlannersIds: data?.nextPlannersIds,
  };

  const approver = {
    id,
    status: data?.status,
    nextPlannersIds: data?.nextPlannersIds,
    pendingUsers: data?.workflow?.steps
      ?.map((ele) => {
        if (ele?.tasks?.length) {
          return ele.approvals?.[0]?.user?.userData;
        } else return null;
      })
      ?.filter((ele) => ele),
  };

  const budget = [];
  Object.keys(data?.worksheet?.data).forEach((key) => {
    const table = {
      tableName: key,
      nextPlannersIds: data?.nextPlannersIds,
      data: [
        {
          ...data?.worksheet?.data[key].total,
          id,
          tableName: key,
          nextPlannersIds: data?.nextPlannersIds,
        },
      ],
    };
    budget.push(table);
  });

  const actions = {
    id,
    nextPlannersIds: data?.nextPlannersIds,
  };

  return {
    planner,
    approver,
    budget,
    actions,
  };
};

export const convertHeirarchyIntoArr = (arr, obj) => {};
