import SearchIconLogo from 'assets/svg/searchIconLogo';

export const Search = ({ setSearchedValue, searchedValue, className }) => {
  return (
    <div
      className={`flex flex-row items-center justify-center my-auto border-2 gradient-border rounded-lg 2xl:px-[14px] 2xl:py-[10px] py-2 ${className}`}
    >
      <SearchIconLogo styles="w-5 h-5 ml-1 text-gray-500" />
      <input
        type="text"
        className="bg-white focus:outline-none w-11/12 pl-2 placeholder:text-sm text-gray-500 mx-1 text-sm sm:block"
        value={searchedValue}
        placeholder="Search"
        onChange={(event) => setSearchedValue(event.target.value)}
      />
    </div>
  );
};
