import { DEFAULT_IMAGE_PATH } from 'apiClient/api.constant';
import { useState } from 'react';
import { imageURLCheck } from 'utils/ImageUrlCheck';
import { handleErrorImage } from 'utils/handleErrorImage';

const Image = (props) => {
  const [hasError, setHasError] = useState(false);
  return (
    <>
      {hasError ? (
        <>
          {props?.fallbackComponent ? ( // this props can we used in can you want render component other that default image
            <>{props?.fallbackComponent}</>
          ) : (
            <img
              alt={props.alt}
              src={DEFAULT_IMAGE_PATH}
              className={`${props.imgStyles} object-cover`}
            />
          )}
        </>
      ) : (
        <img
          src={imageURLCheck(props.src)}
          alt={props.alt}
          className={`${props.imgStyles} object-cover`}
          onError={() => {
            setHasError(true);
          }}
          // onError={(e) => {
          //   e.target.src = "https://cdn-icons-png.flaticon.com/512/8004/8004581.png";
          // }}
        />
      )}
    </>
  );
};

export default Image;
