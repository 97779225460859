export const colorArrayShade1 = [
  '#06AED4',
  '#F63D68',
  '#EAAA08',
  '#7A5AF8',
  '#16B364',
  '#FF692E',
  '#2970FF',
  '#15B79E',
  '#F670C7',
  '#98A2B3',
];

export const colorArrayShade2 = [
  '#22CCEE',
  '#F63D68',
  '#FAC415',
  '#7A5AF8',
  '#3CCB7F',
  '#FF692E',
  '#2970FF',
  '#2ED3B7',
  '#F670C7',
  '#475467',
];

export const colorArrayShade4 = [
  '#F5FEFF',
  '#FFF5F6',
  '#FEFDF0',
  '#FAFAFF',
  '#F6FEF9',
  '#FFF9F5',
  '#F5F8FF',
  '#F6FEFC',
  '#FEF6FB',
  '#FFFFFF',
];
