import RichEditor from 'components/atoms/FormElements/editor/Editor';
import React from 'react';
import { Controller } from 'react-hook-form';

const ParagraphPropertiesSidePanel = (props) => {
  const { path, control } = props;

  return (
    <div>
      <Controller
        name={`${path}.para.value`}
        control={control}
        render={({ field }) => {
          return (
            <RichEditor
              className="w-full h-28"
              placeholder="Add new heading..."
              label="Text Editor"
              {...field}
            />
          );
        }}
      />
    </div>
  );
};

export default ParagraphPropertiesSidePanel;
