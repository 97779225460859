import {
  getBenefitDropdown,
  getEligibleUserForBenefit,
} from 'apiClient/benefit/benefits';
import { getCountries } from 'apiClient/policy/policy';
import Select from 'components/atoms/FormElements/select/Select';
import Pagination from 'components/atoms/pagination/Pagination';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { SidePopUp } from './SidePopUp';
import { UsersTable } from './UsersTable';

const options = [
  { label: 'Enrolment', value: 'enrolment' },
  { label: 'Claim', value: 'claim' },
];

export const BulkEnrolmentsAndClaims = () => {
  const [countries, setCountries] = useState([]);
  const userData = useSelector((state) => state?.user);
  const [countryId, setCountryId] = useState(
    userData?.userProfileData?.country?.sourceValue?.id,
  );
  const [benefits, setBenefits] = useState([]);
  const [selectedBenefit, setSelectedBenefit] = useState('');
  const [operationOptions, setOperationOptions] = useState(options);
  const [selectedOperation, setSelectedOperation] = useState('');
  const [statusOptions, setStatusOptions] = useState([
    { label: 'All', value: 'All' },
    { label: 'Unenrolled', value: 'Unenrolled' },
    { label: 'Enrolled', value: 'Enrolled' },
  ]);
  const [selectedStatus, setSelectedStatus] = useState(
    statusOptions?.[0]?.value,
  );
  const [eligibleUsers, setEligibleUsers] = useState([]);
  const [totalUsersCount, setTotalUsersCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [viewDetails, setViewDetails] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState('');
  const [currentPage, setCurrentPage] = useState(0);

  const handlePageClick = async (event) => {
    setCurrentPage(event.selected);
  };

  const fetchCountries = async () => {
    try {
      const response = await getCountries();
      setCountries(
        response?.data?.map((country) => ({
          ...country,
          label: country?.name,
          value: country?.id,
        })),
      );
    } catch (error) {
      setCountries([]);
    }
  };

  const fetchBenefitDropdown = async () => {
    try {
      const response = await getBenefitDropdown(countryId);

      setBenefits(
        response?.data?.map((benefit) => ({
          ...benefit,
          label: benefit.benefit?.benefitName,
          value: benefit?.id,
        })),
      );
    } catch (error) {
      setBenefits([]);
    }
  };

  const fetchEligibleUsers = async () => {
    try {
      setLoading(true);
      const response =
        selectedBenefit?.benefit?.benefitSubType === 'CLAIM_ONLY'
          ? await getEligibleUserForBenefit(
              selectedBenefit?.benefit?.id,
              'All',
              currentPage,
            )
          : await getEligibleUserForBenefit(
              selectedBenefit?.benefit?.id,
              selectedStatus,
              currentPage,
            );
      setEligibleUsers(response?.data?.eligibleUsersData);
      setTotalUsersCount(response?.data?.pagination?.totalCount);
      setLoading(false);
    } catch (error) {
      setEligibleUsers([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCountries();
  }, []);

  useEffect(() => {
    setSelectedBenefit(benefits?.[0]);
  }, [benefits]);

  useEffect(() => {
    setSelectedBenefit('');
    fetchBenefitDropdown();
  }, [countryId]);

  useEffect(() => {
    setSelectedOperation(operationOptions?.[0]?.value);
  }, [operationOptions]);

  useEffect(() => {
    if (selectedBenefit) {
      if (
        selectedBenefit?.benefit?.benefitSubType === 'MANUAL_ENROLL' ||
        selectedBenefit?.benefit?.benefitSubType === 'AUTO_ENROLL'
      ) {
        setOperationOptions(
          options?.filter((option) => option?.value === 'enrolment'),
        );
      } else if (selectedBenefit?.benefit?.benefitSubType === 'CLAIM_ONLY') {
        setOperationOptions(
          options?.filter((option) => option?.value === 'claim'),
        );
      } else {
        setOperationOptions(options);
      }
    }
  }, [selectedBenefit]);

  useEffect(() => {
    if (selectedBenefit) {
      fetchEligibleUsers();
    }
  }, [selectedStatus, selectedBenefit, currentPage]);

  return (
    <div className="pt-6 px-6 bg-white h-full">
      <div className="space-y-6">
        <p className="text-3xl pb-5 border-b border-gray-200 font-semibold text-gray-900">
          Bulk Enrolment & Claims
        </p>
        <div className="pb-5 border-b border-gray-200">
          <div className="flex items-center w-3/5 justify-between gap-6 ">
            <div className="space-y-[6px] w-full">
              <p className="text-xs font-medium text-gray-700">Country</p>
              <Select
                options={countries}
                value={
                  countries?.filter((count) => count?.value === countryId)[0]
                }
                onChange={(option) => setCountryId(option?.value)}
              />
            </div>
            <div className="space-y-[6px] w-full">
              <p className="text-xs font-medium text-gray-700">Benefit</p>
              <Select
                options={benefits}
                value={
                  benefits?.filter(
                    (benefit) => benefit?.value === selectedBenefit?.value,
                  )?.[0]
                }
                onChange={(option) => setSelectedBenefit(option)}
              />
            </div>
            {selectedBenefit && (
              <div className="space-y-[6px] w-full">
                <p className="text-xs font-medium text-gray-700">Operation</p>
                <Select
                  options={operationOptions}
                  value={
                    operationOptions?.filter(
                      (option) => option?.value === selectedOperation,
                    )?.[0]
                  }
                  onChange={(option) => setSelectedOperation(option?.value)}
                />
              </div>
            )}
            {selectedOperation === 'enrolment' && (
              <div className="space-y-[6px] w-full">
                <p className="text-xs font-medium text-gray-700">Status</p>
                <Select
                  options={statusOptions}
                  value={
                    statusOptions?.filter(
                      (status) => status?.value === selectedStatus,
                    )?.[0]
                  }
                  onChange={(option) => setSelectedStatus(option?.value)}
                />
              </div>
            )}
          </div>
        </div>
        <div>
          {!loading && (
            <p className="text-lg font-medium text-gray-700">{`${totalUsersCount.toLocaleString()} Employees`}</p>
          )}
          <UsersTable
            eligibleUsers={eligibleUsers}
            loading={loading}
            selectedOperation={selectedOperation}
            setViewDetails={setViewDetails}
            setSelectedUserId={setSelectedUserId}
            setIsEditable={setIsEditable}
            isEditable={isEditable}
          />
          {!!totalUsersCount && !loading && (
            <div className="flex justify-center items-center my-4">
              <Pagination
                onPageChanged={handlePageClick}
                pageCount={Math.ceil(totalUsersCount / 10)}
                currentPage={currentPage}
              />
            </div>
          )}
        </div>
      </div>
      {viewDetails && (
        <SidePopUp
          selectedOperation={selectedOperation}
          setViewDetails={setViewDetails}
          selectedBenefitId={selectedBenefit?.benefit?.id}
          selectedUserId={selectedUserId}
          setIsEditable={setIsEditable}
          isEditable={isEditable}
          eligibleUsers={eligibleUsers}
        />
      )}
    </div>
  );
};
