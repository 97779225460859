import React from 'react';

const Classified = ({ isActive }) => {
  return (
    <svg
      width="16"
      height="20"
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.83301 6.25006L6.33301 8.75006L9.66634 10.4167L7.16634 12.9167M14.6663 10.0001C14.6663 14.0904 10.2047 17.0654 8.58133 18.0125C8.39684 18.1201 8.30459 18.1739 8.17441 18.2018C8.07338 18.2235 7.92597 18.2235 7.82494 18.2018C7.69475 18.1739 7.60251 18.1201 7.41802 18.0125C5.79464 17.0654 1.33301 14.0904 1.33301 10.0001V6.01472C1.33301 5.34846 1.33301 5.01533 1.44197 4.72898C1.53824 4.47601 1.69466 4.25029 1.89772 4.07133C2.12759 3.86875 2.43951 3.75178 3.06334 3.51785L7.53151 1.84228C7.70476 1.77732 7.79138 1.74483 7.88049 1.73196C7.95954 1.72053 8.03981 1.72053 8.11885 1.73196C8.20797 1.74483 8.29459 1.77732 8.46784 1.84228L12.936 3.51785C13.5598 3.75178 13.8718 3.86875 14.1016 4.07133C14.3047 4.25029 14.4611 4.47601 14.5574 4.72898C14.6663 5.01533 14.6663 5.34846 14.6663 6.01472V10.0001Z"
        stroke={`${isActive ? '#fff' : '#475467'}`}
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Classified;
