import { CheckBoxProgram } from 'components/atoms/FormElements/input/Input';
import TableSkeleton from 'components/atoms/skeletonLoaderTypes/TableSkeleton';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

export default function DashboardFilters({ getFilterFields }) {
  const reportId = useParams()?.id;

  const [filters, setFilters] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchFilters = async () => {
      setIsLoading(true);
      try {
        const response = await getFilterFields(reportId);
        setFilters(response?.fields);
      } catch (error) {
        setIsLoading(false);
        console.error(error);
      }
      setIsLoading(false);
    };
    fetchFilters();
  }, []);

  return (
    <div className="flex flex-col gap-2">
      {isLoading ? (
        <TableSkeleton count={4} />
      ) : (
        filters
          ?.filter((filter) => filter?.isFilterable)
          ?.map((item, index) => (
            <div className="flex gap-2 items-center" key={index}>
              <CheckBoxProgram checked={true} />
              <span>{item?.name}</span>
            </div>
          ))
      )}
    </div>
  );
}
