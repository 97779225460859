import React from 'react';

const VideoAtom = () => {
  return (
    <svg
      width="127"
      height="82"
      viewBox="0 0 127 82"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M81.875 26.3997L117.806 5.72525C122.562 2.9375 126.5 4.90625 126.5 10.6497V71.681C126.5 77.2617 122.562 79.556 117.806 76.7682L81.875 55.931V71.3555C81.875 77.099 77.2812 81.6927 71.5378 81.6927H10.5065C4.92575 81.6927 0.5 77.099 0.5 71.3555V10.487C0.5 4.7435 4.931 0.3125 10.5065 0.3125H71.5378C77.2812 0.3125 81.875 4.7435 81.875 10.487V26.3997Z"
        fill="#98A2B3"
      />
    </svg>
  );
};

export default VideoAtom;
