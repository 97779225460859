import {
  ArrowTopRightOnSquareIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import { deleteChart } from 'apiClient/dashboardBuilder/DashboardBuilder';
import CircularLoaderSmall from 'assets/svg/CircularLoaderSmall';
import InfinityLoader from 'assets/svg/InfinityLoader/InfinityLoader';
import MoveIcon from 'assets/svg/moveIcon';
import ModalPopup from 'components/layout/modalPopup/ModalPopup';
import {
  useGetChartData,
  useUpdatePage,
} from 'query/dashboard/ManageDashboard';
import { useEffect, useState } from 'react';
import GridLayout from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import { onResponse } from 'utils/toastMessages';
import RenderDashboardCharts from '../dashboardCharts/RenderDashboardCharts';

export default function DashboardCanvas({
  layout,
  setLayout,
  dashboardSettings,
  configFor,
  setConfigFor,
  setSidePanelOpen,
  refetchPageData,
  showLegend,
  chartRefs,
  dashboardRef,
  isDownload,
  selectedPalete,
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedChart, setSelectedChart] = useState(null);
  const [isDeleteCard, setIsDeleteCard] = useState({});
  const [layoutData, setLayoutData] = useState([]);
  const dashboardId = dashboardSettings?.id;
  const { mutateAsync } = useUpdatePage(layout?.id);
  const [selectedChartId, setSelectedChartId] = useState(null);
  const { data: chartData, refetch: refetchChartData } = useGetChartData(
    selectedChartId,
    dashboardId,
  );
  const [loadingStates, setLoadingStates] = useState({});
  const [errorStates, setErrorStates] = useState({});
  const [chartTableData, setChartTableData] = useState({});
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setConfigFor(getFormattedData(chartData));
  }, [chartData, setConfigFor]);

  useEffect(() => {
    if (configFor?.i) {
      setSidePanelOpen(true);
    }
  }, [configFor]);

  useEffect(() => {
    const fetchLayoutData = async () => {
      setLoader(true);
      await setLayoutData(layout?.charts || []);
      setLoader(false);
    };
    fetchLayoutData();
  }, [layout]);

  // useEffect(() => {
  //   const fetchDataForCharts = async () => {
  //     if (!layoutData || layoutData?.length === 0) return;
  //     setLoadingStates((prev) => {
  //       const newLoadingStates = {};
  //       layoutData?.forEach((card) => {
  //         newLoadingStates[card?.cardData?.id] = true;
  //       });
  //       return { ...prev, ...newLoadingStates };
  //     });

  //     try {
  //       const chartDataResponses = await Promise.all(
  //         layoutData?.map(async (card) => {
  //           try {
  //             const response = await getChartTable(
  //               card?.cardData?.id,
  //               dashboardId,
  //               {},
  //             );
  //             return {
  //               chartId: card?.cardData?.id,
  //               datasets: response,
  //               error: null,
  //             };
  //           } catch (error) {
  //             console.error(error);
  //             return {
  //               chartId: card?.cardData?.id,
  //               datasets: null,
  //               error: error,
  //             };
  //           }
  //         }),
  //       );

  //       const newChartData = {};
  //       const newErrorStates = {};

  //       chartDataResponses?.forEach(({ chartId, datasets, error }) => {
  //         if (error) {
  //           newErrorStates[chartId] = true; // Set error for the specific chart
  //         } else {
  //           newChartData[chartId] = datasets;
  //           newErrorStates[chartId] = false; // No error for the specific chart
  //         }
  //       });

  //       setChartTableData(newChartData);
  //       setErrorStates((prev) => ({ ...prev, ...newErrorStates }));
  //     } finally {
  //       setLoadingStates((prev) => {
  //         const newLoadingStates = {};
  //         Object.keys(prev)?.forEach((key) => {
  //           newLoadingStates[key] = false;
  //         });
  //         return { ...prev, ...newLoadingStates };
  //       });
  //     }
  //   };

  //   fetchDataForCharts();
  // }, [layoutData]);

  const updateParentData = (newData) => {
    setLayout((prev) => ({
      ...prev,
      charts: newData,
    }));
  };

  const handleLayoutChange = (newLayout) => {
    const updatedLayout = newLayout?.map((item) => {
      const originalItem = layoutData?.find((orig) => orig?.i === item?.i);
      return {
        ...item,
        cardData: originalItem ? originalItem?.cardData : null,
      };
    });
    setLayoutData(updatedLayout);
    updateParentData(updatedLayout);
    handleResize();
  };

  const onDeleteCard = async (id, event) => {
    event.stopPropagation();
    setIsDeleteCard({ ...isDeleteCard, [id]: true });
    try {
      await deleteChart(id, dashboardId);
      const updatedLayout = layoutData?.filter((item) => item?.id !== id);
      refetchPageData();
      setSidePanelOpen(false);
      setConfigFor('default');
      onResponse('Chart Deleted Successfully');
    } catch (error) {
      onResponse(error?.message);
      setIsDeleteCard({ ...isDeleteCard, [id]: false });
    }
    setIsDeleteCard({ ...isDeleteCard, [id]: false });
  };

  const getFormattedData = (item) => {
    return {
      i: item?.data?.i,
      x: item?.data?.x,
      y: item?.data?.y,
      w: item?.data?.w,
      h: item?.data?.h,
      minW: item?.data?.minW,
      minH: item?.data?.minH,
      cardData: {
        id: item?.id,
        name: item?.name,
        description: item?.description,
        type: item?.data?.type,
        measures: item?.measures?.map((item) => item?.id),
        dimensions: item?.dimensions?.map((item) => item?.id),
        filters: item?.filters,
        sorting: item?.sorting,
        aggregation: item?.aggregation,
        datasets: item?.data?.datasets,
      },
    };
  };

  const handleCardClick = (card, event) => {
    event.stopPropagation(); // Prevent the event from bubbling up to the main canvas
    setSelectedChartId(card?.cardData?.id);
    if (selectedChartId && dashboardId) {
      refetchChartData();
    }
    // setSidePanelOpen(true);
  };

  const handleOpenModal = (card, event) => {
    event.stopPropagation();
    setSelectedChart(card);
    setIsModalOpen(true);
  };

  useEffect(() => {
    const resizeHandles = document.querySelectorAll(
      '.react-resizable-handle-se, .drag-handle',
    );

    resizeHandles.forEach((handle) => {
      handle.addEventListener('click', (event) => {
        event.stopPropagation();
      });
    });

    return () => {
      resizeHandles.forEach((handle) => {
        handle.removeEventListener('click', (event) => {
          event.stopPropagation();
        });
      });
    };
  }, [layout]);

  useEffect(() => {
    setSelectedChart(selectedChart);
  }, [selectedChart]);

  const renderCards = () => {
    return layoutData?.map((card, index) => {
      const ref = (el) => {
        if (el) {
          chartRefs.current[card?.i] = el; // Store the ref
        }
      };
      const cardChartData = chartTableData[card?.cardData?.id];
      const isLoading = loadingStates[card?.cardData?.id];
      const hasError = errorStates[card?.cardData?.id];
      if (card?.cardData?.type === 'separator') {
        return (
          <div
            key={card?.i}
            onClick={(event) => {
              event.stopPropagation();
              setConfigFor(card);
            }}
            className={`rounded h-full flex flex-col justify-between w-full p-2 ${
              configFor?.i === card?.i
                ? 'border-2 border-black bg-white shadow min-h-20'
                : 'border-none'
            }`}
          >
            {configFor?.i === card?.i ? (
              <div className="flex items-center gap-2.5 self-end pb-2">
                <div className="drag-handle cursor-move">
                  <MoveIcon />
                </div>
                <div
                  className="cursor-pointer"
                  // onClick={(event) => onDeleteCard(card?.i, event)}
                >
                  <TrashIcon className="w-6 h-6 text-gray-400" />
                </div>
              </div>
            ) : null}
            <div className=" bg-black h-1 w-full"></div>
          </div>
        );
      } else if (card?.cardData?.type === 'text') {
        return (
          <div
            key={card?.i}
            onClick={(event) => {
              event.stopPropagation();
              setConfigFor(card);
              setSidePanelOpen(true);
            }}
            className={`rounded flex h-full w-full flex-col justify-between p-2 min-w-24 ${
              configFor?.i === card?.i
                ? 'border-2 border-black bg-white shadow min-h-16 '
                : 'border-none'
            }`}
          >
            {configFor?.i === card?.i ? (
              <div className="flex items-center gap-2.5 self-end">
                <div className="drag-handle cursor-move">
                  <MoveIcon />
                </div>
                <div
                  className="cursor-pointer"
                  // onClick={(event) => onDeleteCard(card?.i, event)}
                >
                  <TrashIcon className="w-6 h-6 text-gray-400" />
                </div>
              </div>
            ) : null}
            <div
              className="break-words text-wrap"
              style={{
                color: card?.data?.fontColor ? card?.data?.fontColor : 'black',
                fontSize: card?.data?.fontSize
                  ? `${card?.data?.fontSize}px`
                  : '16px',
                fontFamily: card?.data?.fontFamily
                  ? card?.data?.fontFamily
                  : 'Arial',
              }}
            >
              {card?.data?.description || 'Edit Text'}
            </div>
          </div>
        );
      } else {
        return (
          <div
            key={card?.i}
            onClick={(event) => handleCardClick(card, event)}
            className={`flex flex-col bg-white shadow rounded ${
              configFor?.i === card?.i
                ? 'border-2 border-black '
                : 'border-none'
            }`}
          >
            <div className="px-4 pt-4 pb-1 flex items-center justify-between">
              <span className="text-base font-semibold text-black break-words w-3/4">
                {card?.cardData?.name}
              </span>
              {isDownload ? null : (
                <div className="flex justify-between items-center gap-2">
                  <div className="drag-handle cursor-move">
                    <MoveIcon />
                  </div>
                  <div
                    className="cursor-pointer"
                    onClick={(event) => {
                      event.stopPropagation();
                      handleOpenModal(card, event);
                    }}
                  >
                    <ArrowTopRightOnSquareIcon className="w-5 h-5 text-gray-400" />
                  </div>
                  {isDeleteCard[card?.cardData?.id] ? (
                    <div className="-mr-3">
                      <CircularLoaderSmall />
                    </div>
                  ) : (
                    <div
                      className="cursor-pointer"
                      onClick={(e) => onDeleteCard(card?.cardData?.id, e)}
                    >
                      <TrashIcon className="w-5 h-5 text-gray-400" />
                    </div>
                  )}
                </div>
              )}
            </div>
            <span className="text-sm font-medium text-gray-500 px-4 pb-4 break-words">
              {card?.cardData?.description}
            </span>
            {card?.cardData?.datasets && (
              <div
                className={`px-4 pb-4 h-full w-full inline-block `}
                ref={ref}
              >
                <RenderDashboardCharts
                  selectedPalete={selectedPalete}
                  chartType={card?.cardData?.type}
                  datasets={card?.cardData?.datasets || {}}
                  displayLegend={showLegend[card?.cardData?.id]}
                />
              </div>
            )}
            {/* {isLoading ? (
              <GraphLoader />
            ) : hasError ? (
              <div className="h-full p-4 w-full inline-block text-red-500 text-center">
                No Table Available For This Chart
              </div>
            ) : (
              cardChartData && (
                <div className="h-full p-4 w-full inline-block">
                  <RenderDashboardCharts
                    displayLegend={true}
                    chartType={card?.cardData?.type}
                    datasets={cardChartData}
                  />
                </div>
              )
            )} */}
          </div>
        );
      }
    });
  };

  const handleResize = () => {
    const gridItems = document.querySelectorAll('.react-grid-item');

    gridItems.forEach((item) => {
      const contentElement = item.querySelector('.chart-container'); // Adjust if necessary
      if (contentElement) {
        item.style.height = `${contentElement.scrollHeight}px`; // Adjust height based on content
      }
    });
  };

  const onResize = (layout, oldLayoutItem, layoutItem, placeholder) => {
    // Ensure that the chartRefs is properly initialized and populated
    const chartElement = chartRefs.current[layoutItem.i];

    if (chartElement) {
      // Access the canvas element directly
      const canvasElement = chartElement.querySelector('canvas');

      if (canvasElement) {
        const chartHeight = canvasElement.clientHeight / 20;
        // const chartWidth = canvasElement.clientWidth;

        // Set minimum height and width based on canvas dimensions
        layoutItem.minH = 10;
        layoutItem.h = chartHeight + 1;
        placeholder.h = chartHeight + 1;
        // layoutItem.minW = chartWidth;
        // layoutItem.minH = 0;
        // layoutItem.minW = 0;
        layoutItem.maxH = 26;

        if (layoutItem.h < layoutItem.minH) {
          layoutItem.h = layoutItem.minH;
          placeholder.h = layoutItem.minH;
        }

        if (layoutItem.h > layoutItem.maxH) {
          layoutItem.h = layoutItem.maxH;
          placeholder.h = layoutItem.maxH;
        }

        // if (layoutItem.w < layoutItem.minW) {
        //   layoutItem.w = layoutItem.minW;
        //   placeholder.w = layoutItem.minW;
        // }
      }
    }
  };

  useEffect(() => {
    if (layoutData?.length > 0) {
      handleResize(); // Adjust layout heights on data change
    }
  }, [layoutData]);

  useEffect(() => {
    // Initial check on mount
    handleResize();

    // Add resize event listener
    window.addEventListener('resize', handleResize);

    // Clean up the event listener
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return loader ? (
    <InfinityLoader />
  ) : (
    <div
      onClick={() => {
        setConfigFor('default');
        setSelectedChartId(null);
      }}
      style={{
        backgroundColor: dashboardSettings?.settings?.backgroundColor,
      }}
      className={`min-h-full h-max pl-8 py-4 pr-10 w-full flex flex-col gap-1`}
      ref={dashboardRef}
    >
      <div
        className={`bg-white text-gray-900 border text-xl font-semibold py-2 rounded flex border-gray-400 w-full items-center justify-center`}
      >
        {layout?.name}
      </div>
      <div className="w-full">
        <GridLayout
          className="layout"
          layout={layoutData}
          cols={12}
          rowHeight={20}
          margin={[
            dashboardSettings?.settings?.spacing || 8,
            dashboardSettings?.settings?.spacing || 8,
          ]}
          width={document.documentElement.clientWidth - 245}
          height="100%"
          onLayoutChange={handleLayoutChange}
          draggableHandle={isDownload ? '' : '.drag-handle'}
          resizeHandles={isDownload ? [] : ['se']}
          autoSize={true}
          onResizeStop={handleResize}
          onResize={onResize}
        >
          {renderCards()}
        </GridLayout>
      </div>
      {selectedChart && (
        <ModalPopup
          title={selectedChart?.cardData?.name}
          onClose={() => {
            setIsModalOpen(false);
            setSelectedChart(null);
          }}
          isModalOpen={isModalOpen}
          body={
            selectedChart?.cardData?.datasets && (
              <div className={`px-4 pb-4 h-[500px] w-[800px] inline-block `}>
                <RenderDashboardCharts
                  chartType={selectedChart?.cardData?.type}
                  datasets={selectedChart?.cardData?.datasets || {}}
                  displayLegend={showLegend[selectedChart?.cardData?.id]}
                />
              </div>
            )
          }
          width="1000px"
          height="1000px"
        />
      )}
    </div>
  );
}
