const EyeIcon = () => {
  return (
    <svg
      width="20"
      height="14"
      viewBox="0 0 20 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.01677 7.59415C1.90328 7.41445 1.84654 7.3246 1.81477 7.18602C1.79091 7.08192 1.79091 6.91775 1.81477 6.81366C1.84654 6.67507 1.90328 6.58522 2.01677 6.40552C2.95461 4.92054 5.74617 1.1665 10.0003 1.1665C14.2545 1.1665 17.0461 4.92054 17.9839 6.40552C18.0974 6.58522 18.1541 6.67507 18.1859 6.81366C18.2098 6.91775 18.2098 7.08192 18.1859 7.18602C18.1541 7.3246 18.0974 7.41445 17.9839 7.59415C17.0461 9.07914 14.2545 12.8332 10.0003 12.8332C5.74617 12.8332 2.95461 9.07914 2.01677 7.59415Z"
        stroke="#667085"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.0003 9.49984C11.381 9.49984 12.5003 8.38055 12.5003 6.99984C12.5003 5.61913 11.381 4.49984 10.0003 4.49984C8.61962 4.49984 7.50034 5.61913 7.50034 6.99984C7.50034 8.38055 8.61962 9.49984 10.0003 9.49984Z"
        stroke="#667085"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default EyeIcon;
