import { onResponse } from './toastMessages';

export const handleImageChange = (file, size) => {
  if (file) {
    const fileSize = file.size;

    const maxFileSize = size * 1024 * 1024;

    if (file?.type?.startsWith('image/') && fileSize > maxFileSize) {
      onResponse(`Image size can't be larger than ${size} MB`);
      return false;
    }
    return true;
  }
};
