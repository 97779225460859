import { fetchLifeEventById } from 'apiClient/benefitsDashboard/benefitsDashboard';
import BackButton from 'components/atoms/button/backButton/BackButton';
import { Loader } from 'components/atoms/loader/Loader';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Details } from './approvalDetails/Details';
import { Reviews } from './review/Reviews';

export const TaskLifeEventApproval = (props) => {
  const { state } = useLocation();
  const taskData = state?.data;
  const { id } = useParams();
  const [lifeEventDetails, setLifeEventDetails] = useState();
  const [loading, setLoading] = useState(false);

  const getLifeEvent = async () => {
    try {
      setLoading(true);
      const response = await fetchLifeEventById(id);
      console.log(response, 'res');
      setLifeEventDetails(response?.data?.lifeEventDetails);
      setLoading(false);
    } catch (error) {
      setLifeEventDetails({});
      setLoading(false);
    }
  };

  const navigate = useNavigate();

  useEffect(() => {
    getLifeEvent();
  }, []);

  return (
    <div className="flex w-full gap-5 flex-col p-6 bg-white">
      {loading ? (
        <div className="flex justify-center item-center">
          <Loader />
        </div>
      ) : (
        <>
          <div className="flex max-w-full gap-4 items-center">
            <BackButton
              className="w-[40px]"
              onClick={() => navigate(`/task/`)}
            />
            <h1 className="text-gray-900 text-md font-semibold text-2xl break-words max-w-[80%]">
              Life Event Approval
            </h1>
          </div>
          <div className="flex gap-8">
            <div className="w-full border-solid">
              <Details data={lifeEventDetails} taskData={taskData} />
            </div>
            <div className="w-[392px]">
              <Reviews data={lifeEventDetails} taskData={taskData} />
            </div>
          </div>
        </>
      )}
    </div>
  );
};
