import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedUser: {},
};

const snapshotSlice = createSlice({
  name: 'snapshot',
  initialState,
  reducers: {
    setSelectedUser: (state, action) => {
      state.selectedUser = action.payload;
      return state;
    },
  },
});

export const { setSelectedUser } = snapshotSlice.actions;
export default snapshotSlice.reducer;
export const snapshotSelector = (state) => state.snapshot;
