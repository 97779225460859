import React from 'react';

const ViewDividerAtom = (props) => {
  const { columnData } = props;
  const metaData = columnData?.attr?.divider;
  const colorValue = '#555555';
  const fullWidth = '100%';
  return (
    <div
      style={{
        height: metaData?.height ?? 2,
        backgroundColor: metaData?.color ?? colorValue,
        width: metaData?.width ?? fullWidth,
      }}
    ></div>
  );
};

export default ViewDividerAtom;
