import * as CONSTANT from '../api.constant';
import { get, post } from '../apiCalls';

export const getAllTask = async (
  id,
  pageSize,
  pageIndex,
  query,
  status,
  getKeyFields,
  detailType,
) => {
  try {
    const queryParams = `${pageSize ? `&pageSize=${pageSize}` : ''}${
      pageIndex ? `&pageIndex=${pageIndex}` : ''
    }${status ? `&status=${status}` : ''}${
      getKeyFields ? `&getKeyFields=${getKeyFields}` : ''
    }${detailType ? `&detailType=${detailType}` : ''}${
      query ? `&query=${query}` : ''
    }`;

    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_SURFACE_V2}/user-task/${id}?${queryParams}`,
    );
    return response?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const createAllTask = async (id, action, payload) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_SURFACE_V2}/user-task/${id}/${action}`,
      payload,
    );
    return response;
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
};

export const userTaskAction = async (payload) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_SURFACE_V2}/user-task/action`,
      payload,
    );
    return response;
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
};

export const createRNRTask = async (id, action, payload) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_SURFACE_V2}/user-task/${id}/${action}`,
      payload,
    );
    return response;
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
};

export const getWorkflowHistory = async (id) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_SURFACE_V2}/user-task/workflow-history/${id}`,
    );
    console.log('response', response);
    return response?.data;
  } catch (error) {
    console.log('Err', error);
    throw new Error(error);
  }
};
